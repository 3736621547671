import React from "react";
import "../OnboardingDetails/styles.css";
import { moduleSuccess } from '../assets'

const ComplateSuccess = (props: any) => {

   const handleClickOutside = () => {
      props.closeModal(false)
   }


   return (
      <div>
         <div className="modalContainer" onClick={(e) => { handleClickOutside() }}>
            <div className="modalContent" onClick={(e) => { e.stopPropagation() }}>
               <div className="modalImage">
                  <img src={moduleSuccess} alt="modal image" />
                  <div className="modalImageText">
                     <span>Congratulations! </span>
                     You have successfully completed the Moderator Orientation Module.
                     We look forward to welcoming you as a Moderator soon.
                  </div>
               </div>

               <div className="modalButtons">
                  <div className="modalCoutunieButton1" onClick={() => { handleClickOutside() }}>Continue</div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default ComplateSuccess;

