export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const mainLogo = require("../assets/main_logo.png");
export const logo = require("../assets/logo.svg");
export const celebrationImage = require('../assets/celebration_image.png')
export const celebrationImage2 = require('../assets/celebration_image2.png')
export const wallet_image = require('../assets/wallet_image.png')
export const wallet_image2 = require('../assets/wallet_image2.png')
export const profile_image = require('../assets/profile_image.png')
export const profile_imageIcon = require('../assets/profile_image.png')
export const otpLogo = require('../assets/otp_logo.png')
export const cancel_logo = require('../assets/cancel_logo.png')
export const successfulImg = require('../assets/successfulImg.png')
export const thankYou = require('../assets/thankYou.png')
export const welcomeImg = require('../assets/welcomeImg.png')
export const newHearth = require('../assets/newHearth.png')
export const imageChat = require('../assets/imageChat.png')
export const imagePerson = require('../assets/imagePerson.png')
