// Customizable Area Start
import React, { useEffect, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Hidden,
  Box,
  Slide,
} from "@material-ui/core";
import Draggable, { DraggableEvent, DraggableData } from "react-draggable";

import {
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core/styles";

import {
  ArrowForwardIos as ArrowForwardIosIcon,
  ExpandLess as ExpandLessIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: theme.spacing(6),
    },
    accordion: {
      marginBottom: theme.spacing(3),
    },
    accordionContent: {
      flexDirection: "column",
    },
    expandIcon: {
      color: "grey",
      fontSize: "16px",
    },
    heading: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightMedium,
    },
    content: {
      color: "#727272",
      marginBottom: "16px",
      textDecoration: "underline",
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightMedium,
      "&:hover": {
        color: "#444444",
      },
    },
    contentActive: {
      color: "#27272A",
    },
  })
);

type Props = RouteComponentProps<{}> & {
  step: number;
  counter: number;
  isOrientationCompleted: boolean;
  setStep: (step: number) => void;
  setCounter: (counter: number) => void;
  markOrientationAs: (completed: boolean) => void;
  toggleExpandedItem: (item: number, action: "ADD" | "REMOVE") => void;
};

const counterToIndex = [
  { min: 0, max: 1 },
  { min: 2, max: 6 },
  { min: 7, max: 10 },
  { min: 11, max: 14 },
  { min: 15, max: 16 },
];

const Sidebar = (props: Props) => {
  const {
    step,
    toggleExpandedItem,
    counter,
    setStep,
    setCounter,
    isOrientationCompleted,
    markOrientationAs,
    location: { hash },
  } = props;

  const [expanded, setExpanded] = useState<number | false>(step + 1);

  const theme = useTheme();
  const classes = useStyles();

  const handleChange =
    (panel: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    setExpanded(step + 1);
  }, [step]);

  return (
    <>
      <Hidden xsDown>
        <div className={classes.root}>
          {menuData.map((item) => (
            <Accordion
              key={item.id}
              className={classes.accordion}
              style={
                step === item.id - 1
                  ? {
                      border: `1.5px solid ${theme.palette.primary.main}`,
                      background: "#FFFEF5",
                    }
                  : {
                      background: "#F5F5F5",
                    }
              }
              expanded={item.id !== 3 && expanded === item.id}
              onChange={item.id !== 3 ? handleChange(item.id) : undefined}
              onClick={item.id === 3 ? () => setStep(2) : undefined}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowForwardIosIcon className={classes.expandIcon} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {item.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionContent}>
                {item.links?.map((link, index) => {
                  let active = false;
                  if (
                    step === 0 &&
                    item.id === 1 &&
                    hash === `#section-${index + 1}`
                  )
                    active = true;
                  else if (step === 1 && item.id === 2) {
                    if (
                      (!isOrientationCompleted &&
                        counter <= counterToIndex[index]?.max &&
                        counter >= counterToIndex[index]?.min) ||
                      (isOrientationCompleted && index === 5)
                    )
                      active = true;
                  }
                  return (
                    <Typography
                      key={index}
                      component={Link}
                      onClick={() => {
                        setStep(item.id - 1);
                        if (item.id === 1) toggleExpandedItem(index, "ADD");
                        else if (item.id === 2) {
                          if (index !== 5) {
                            markOrientationAs(false);
                            setCounter(counterToIndex[index].min);
                          } else markOrientationAs(true);
                        }
                        return null;
                      }}
                      to={item.id === 1 ? `#section-${index + 1}` : "#"}
                      className={`${classes.content} ${
                        active ? classes.contentActive : ""
                      }`}
                    >
                      {link}
                    </Typography>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </Hidden>
    </>
  );
};

export default withRouter(Sidebar);

const Bottombar = withRouter((props: Props) => {
  const {
    step,
    toggleExpandedItem,
    counter,
    setStep,
    setCounter,
    isOrientationCompleted,
    markOrientationAs,
    location: { hash },
  } = props;

  const [expanded, setExpanded] = useState<number | false>(step + 1);
  const [selectedStepId, setSelectedStepId] = useState<number>(0);
  const [defaultPosition, setDefaultPosition] = useState({ x: 0, y: 0 });

  const theme = useTheme();
  const classes = useStyles();

  const handleChange =
    (panel: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleDrag = (e: DraggableEvent, data: DraggableData) => {
    if (data.y >= 50) {
      // Change this value to control the minimum distance to close the slider.
      setSelectedStepId(0);
    } else {
      setDefaultPosition({ x: 0, y: 0 });
    }
  };

  useEffect(() => {
    setExpanded(step + 1);
  }, [step]);

  return (
    <>
      {/* filters for mobile view */}
      <Hidden smUp>
        <Box
          id="bottomComponent"
          style={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            zIndex: 1200,
          }}
          sx={{
            bgcolor: !selectedStepId ? "#fff" : "rgba(0, 0, 0, 0)",
            border: "1px solid #E0E0E0",
            boxShadow: !selectedStepId
              ? "0px 0px 10px rgba(0, 0, 0, 0.25)"
              : "none",
          }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {selectedStepId === 0 && (
            <>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  borderRight: "1px solid #E0E0E0",
                  padding: "1.2rem .7rem",
                }}
                onClick={() => setSelectedStepId(1)}
              >
                <Typography
                  style={{
                    color: "#27272A",
                    fontSize: ".9rem",
                    fontWeight: 500,
                  }}
                >
                  Criteria and Benefits
                </Typography>
                <ExpandLessIcon style={{ fontSize: 15 }} />
              </Box>

              <Box
                display="flex"
                alignItems="center"
                sx={{
                  borderRight: "1px solid #E0E0E0",
                  padding: "1.2rem .7rem",
                }}
                onClick={() => setSelectedStepId(2)}
              >
                <Typography
                  style={{
                    color: "#27272A",
                    fontSize: ".9rem",
                    fontWeight: 500,
                  }}
                >
                  Moderator Orientation
                </Typography>
                <ExpandLessIcon style={{ fontSize: 15 }} />
              </Box>

              <Box
                display="flex"
                alignItems="center"
                sx={{ padding: "1.2rem .7rem" }}
                onClick={() => setStep(2)}
              >
                <Typography
                  style={{
                    color: "#27272A",
                    fontSize: ".9rem",
                    fontWeight: 500,
                  }}
                >
                  Fill in additional details
                </Typography>
                <ExpandLessIcon style={{ fontSize: 15 }} />
              </Box>
            </>
          )}

          {selectedStepId > 0 && (
            <>
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100dvh",
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  zIndex: 1200,
                  backdropFilter: "blur(4px)",
                }}
                onClick={() => setSelectedStepId(0)}
              />
              <Draggable
                axis="y"
                bounds={{ top: 0 }}
                onStop={handleDrag}
                position={defaultPosition}
                handle=".drag-handle"
              >
                <Slide direction="up" in={!!selectedStepId}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    // height={selectedStepId === "categories" ? "80vh" : "auto"}
                    zIndex={1300}
                    flex={1}
                    bgcolor="#fff"
                    style={{
                      borderTopLeftRadius: 20,
                      borderTopRightRadius: 20,
                    }}
                  >
                    <div style={{ height: "90%", width: "100%" }}>
                      <AccordionDetails className={classes.accordionContent}>
                        {menuData
                          .find((item) => item.id === selectedStepId)
                          ?.links?.map((link, index) => {
                            let active = false;
                            if (
                              step === 0 &&
                              selectedStepId === 1 &&
                              hash === `#section-${index + 1}`
                            )
                              active = true;
                            else if (step === 1 && selectedStepId === 2) {
                              if (
                                (!isOrientationCompleted &&
                                  counter <= counterToIndex[index]?.max &&
                                  counter >= counterToIndex[index]?.min) ||
                                (isOrientationCompleted && index === 5)
                              )
                                active = true;
                            }
                            return (
                              <Typography
                                key={index}
                                component={Link}
                                onClick={() => {
                                  setSelectedStepId(0);
                                  setStep(selectedStepId - 1);
                                  if (selectedStepId === 1)
                                    toggleExpandedItem(index, "ADD");
                                  else if (selectedStepId === 2) {
                                    if (index !== 5) {
                                      markOrientationAs(false);
                                      setCounter(counterToIndex[index].min);
                                    } else markOrientationAs(true);
                                  }
                                  return null;
                                }}
                                to={
                                  selectedStepId === 1
                                    ? `#section-${index + 1}`
                                    : "#"
                                }
                                className={`${classes.content} ${
                                  active ? classes.contentActive : ""
                                }`}
                              >
                                {link}
                              </Typography>
                            );
                          })}
                      </AccordionDetails>
                    </div>
                  </Box>
                </Slide>
              </Draggable>
            </>
          )}
        </Box>
      </Hidden>
    </>
  );
});

export { Bottombar };

const menuData = [
  {
    id: 1,
    title: "Criteria and Benefits",
    links: [
      "Criteria to become a Moderator or a Superhost",
      "Retaining the Moderator or Superhost status",
      "Benefits of becoming a Superhost",
      "Benefits of becoming a Moderator",
    ],
  },
  {
    id: 2,
    title: "Moderator Orientation",
    links: [
      "Introduction",
      "Module 1: Being a welcoming host",
      "Module 2: Holding a safe space for the guests",
      "Module 3: Running the conversation efficiently",
      "Module 4: Not offering advice or counselling",
      "Quiz",
    ],
  },
  {
    id: 3,
    title: "Fill in additional details",
  },
];
// Customizable Area End
