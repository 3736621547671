// Customizable Area Start
import React, { useEffect, useState, useRef } from 'react'
import IconButton from "@material-ui/core/IconButton";
import { RouteComponentProps } from 'react-router-dom';

const BlankUserIcon = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdDXECBv76wa78obNrJNqayP3o7cy4RaZNg_l_YuhSzP6qoWuHr6BTtn8JgNuHFVmSaf4&usqp=CAU';

interface Props {
   history: RouteComponentProps["history"];
   handleLogout: () => void;
}

const ProfileDropdown = (props: Props) => {
   const [isOpen, setIsOpen] = useState(false);
   const toggle = (isOpen: any) => setIsOpen(!isOpen);
   const [userImg, setUserImg] = useState('');
   const [userName, setUserName] = useState('');

   const menuRef = useRef(null)

   const [listening, setListening] = useState(false)

   const listenForOutsideClick = (
      listening: any,
      setListening: any,
      menuRef: any,
      setIsOpen: any,
   ) => {
      return () => {
         if (listening) return
         if (!menuRef.current) return
         setListening(true);
         [`click`, `touchstart`].forEach((type) => {
            document.addEventListener(`click`, (evt) => {
               const cur = menuRef.current
               const node = evt.target
               if (cur && cur.contains(node)) return
               setIsOpen(false)
            })
         })
      }
   }

   useEffect(listenForOutsideClick(listening, setListening, menuRef, setIsOpen));

   const handleStroageChange = () => {
      const profile_image = localStorage.getItem("profile_image") as string;
      const user_name = localStorage.getItem("userName") as string;
      setUserImg(profile_image);
      setUserName(user_name);
   };

   // track storage change
   useEffect(() => {
      window.addEventListener("storage", handleStroageChange, false);

      return () => {
         window.removeEventListener("stroage", handleStroageChange, false);
      };
   }, []);

   // set image on initial load
   useEffect(() => {
      handleStroageChange();
   }, []);

   return (
      <IconButton ref={menuRef} style={{ position: "relative" }} onClick={() => {
         toggle(isOpen)
      }}>
         <img
            className="icon-style"
            width={35}
            height={35}
            style={{ borderRadius: "50%", objectFit: "cover", cursor: "pointer" }}
            src={userImg && userImg !== "false" ? userImg : BlankUserIcon}
            alt="user_profile_picture"
         />
         {isOpen ? (
            <div className="popup arrow-top main noP">
               <span className="arrow" />
               <div className="popup-wrapper">
                  <div className="top-heading-popup">Hello! {userName}</div>
                  <p onClick={() => {
                     props.history.push("/UserPersonalProfile");
                  }
                  }>My Profile</p>
                  <p onClick={() => {
                     props.history.push("/Rooms?type=my");
                  }}>My Rooms</p>
                  <p onClick={() => {
                     props.history.push("/ConnectionList");
                  }}>Connections</p>
                  <p onClick={() => {
                     props.history.push("/Setting");
                  }}>Settings</p>
                  <p onClick={props.handleLogout}>Log Out</p>
               </div>
            </div>
         ) : (
            ''
         )}
      </IconButton>
   )
}
export default ProfileDropdown;
// Customizable Area End