import React from "react";
// Customizable Area Start
import RoomController from "../../src/MyRooms/RoomsController";
import {
  fam,
  standard,
  moderated,
  premium,
  onetoone,
} from "../assets";
import { Link, withRouter } from "react-router-dom";
import { Box } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import RandomImages from "./RandomImages";

const ICONS = [standard, moderated, premium, onetoone];

class RoomTypes extends RoomController {
  constructor(props: any) {
    super(props);
  }

  async componentDidMount() {
    const roomTypes = JSON.parse(sessionStorage.getItem("roomTypes") as string) || [];
    if (!roomTypes.length) {
      this.getRoomTypes();
    } else {
      this.setState({ getRoomsType: roomTypes, isRoomTypeLoading: false });
    }

    this.getHomePageSettings();

    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  async componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const { getRoomsType, getHomePageSettings } = this.state;
    const isModerator = JSON.parse(localStorage.getItem("isModerator") as string) || false;
    const { toggleAvailable, roomType, handleRoomType } = this.props;
    const images: string[] = getHomePageSettings?.sub_category_images?.data
      .map((each: any) => each.attributes.portrait);

    return (
      <>
        <section className={`room-types ${!this.state.isMobile ? 'shadow' : ''}`}>
          <h4 className="fs18 fw500 mt0 mb0 room-head bBottom mt0 drag-handle">Room Types</h4>

          {this.state.isRoomTypeLoading && (
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" sx={{ p: 1, mt: -3 }}>
              {Array(4).fill(0).map((_, i) => <Skeleton key={i} animation="wave" width="100%" height={55} />)}
            </Box>
          )}

          {!!getRoomsType.length && getRoomsType.map((type: any, indx: number) => {
            return (
              <div className="daf room-head bBottom" key={indx}
              >
                <label
                  htmlFor={`room-type-checkbox-${indx}`}
                  className="fs15 fw500 dFlexAlignCenter"
                >
                  <img src={ICONS[indx]} alt={`icon_${indx}`} className="w32 mr10" />
                  {type?.attributes?.name}
                </label>
                <input
                  type="checkbox"
                  name=""
                  id={`room-type-checkbox-${indx}`}
                  checked={roomType.includes(type?.id)}
                  onChange={() => handleRoomType(type?.id)} />
              </div>
            )
          })}
        </section>

        {!this.state.isMobile && (
          <>
            <section className="border radius15 mb30 p15 shadow">
              <div className="daf">
                <span className="fs14 colorBlue fw500">
                  Available Rooms Only
                </span>
                <label className="switch">
                  <input type="checkbox"
                    onChange={toggleAvailable} />
                  <span className="slider round"
                  />
                </label>
              </div>
            </section>

            <section className="border radius15 mb30 p15 shadow">
              <Link
                to="/ModeratorList"
                className="fs14 colorBlue fw500 textDecorationNone"
              >
                Moderators
              </Link>
            </section>

            {!isModerator &&
              (<section className="border radius15 mb30 p15 shadow">
                <span
                  style={{ cursor: "pointer" }}
                  className="fs14 fw500 colorBlue"
                  onClick={() => { this.props.history.push("ModuleOrientation") }}
                >
                  Become a Moderator
                </span>
              </section>
              )
            }
            <h3 className="fs22 fw500 colorBlue mb20">{getHomePageSettings?.setting?.heading}</h3>
            <section className="todayImage mb20">
              {images?.length > 0 ? <RandomImages images={images} /> :
                <>
                  <Skeleton variant="rect" className="w-100" height={50} style={{ margin: '1rem 0', borderRadius: '.5rem' }} />
                  <Skeleton variant="rect" className="w-100" height={500} style={{ borderRadius: '.5rem' }} />
                </>
              }
            </section>
          </>
        )}
      </>
    );
  }
}

export default withRouter(React.memo(RoomTypes));
