// App.js - WEB
import React, { Component } from 'react';
import actionCable from 'actioncable';
const x = require('../../framework/src/config');
import { Helmet } from 'react-helmet';

import { Message } from '../../framework/src/Message';
import { BlockComponent } from '../../framework/src/BlockComponent';
import MessageEnum, { getName } from '../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../framework/src/RunEngine';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { isAuthenticated } from '../../components/src/utils';
import { ModalContainer } from 'react-router-modal';
import { StateProvider } from './globalState';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import Chat from '../../blocks/Referrals/src/Chat/Chat.web';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import VisualAnalytics from '../../blocks/visualanalytics/src/VisualAnalytics';
import AnonymousMode from '../../blocks/AnonymousMode/src/AnonymousMode';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import MobileAccountLoginBlock from '../../blocks/mobile-account-login/src/MobileAccountLoginBlock';
import AdvancedSearch from '../../blocks/advancedsearch/src/AdvancedSearch';
import PunishmentAlerts from '../../blocks/PunishmentAlerts/src/PunishmentAlerts';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import RolesPermissions2 from '../../blocks/RolesPermissions2/src/RolesPermissions2';
import Payments from '../../blocks/Payments/src/Payments';
import ConversationHistory2 from '../../blocks/ConversationHistory2/src/ConversationHistory2';
import PaymentAdmin2 from '../../blocks/PaymentAdmin2/src/PaymentAdmin2';
import Customform from '../../blocks/customform/src/Customform';
import TabletSupport65 from '../../blocks/TabletSupport65/src/TabletSupport65';
import CfPayoutBankApi from '../../blocks/CfPayoutBankApi/src/CfPayoutBankApi';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications';
import PointSystemhearts from '../../blocks/PointSystemhearts/src/PointSystemhearts';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import DeepLinking from '../../blocks/DeepLinking/src/DeepLinking';
import Notifications from '../../blocks/notifications/src/Notifications';
import Analytics from '../../blocks/analytics/src/Analytics';
import GiveHearts from '../../blocks/GiveHearts/src/GiveHearts';
import LinkShare from '../../blocks/LinkShare/src/LinkShare';
import ConnectionList from '../../blocks/user-profile-basic/src/UserPersonalProfile/ConnectionList.web';
import RefundManagement from '../../blocks/RefundManagement/src/RefundManagement';
import DailyTimeLimit from '../../blocks/DailyTimeLimit/src/DailyTimeLimit';
import CancellationOfCall from '../../blocks/CancellationOfCall/src/CancellationOfCall';
import History5 from '../../blocks/History5/src/History5';
import Referrals from '../../blocks/Referrals/src/Referrals';
import AdminConsole3 from '../../blocks/AdminConsole3/src/AdminConsole3';
import ThirdPartyAudioAndVideoIntergration from '../../blocks/ThirdPartyAudioAndVideoIntergration/src/ThirdPartyAudioAndVideoIntergration';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import UserPersonalProfile from '../../blocks/user-profile-basic/src/UserPersonalProfile/UserPersonalProfile';
import Feedback from '../../blocks/Feedback/src/Feedback';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import CertificationTracking from '../../blocks/CertificationTracking/src/CertificationTracking';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import ReviewAndApproval from '../../blocks/ReviewAndApproval/src/ReviewAndApproval';
import ConnectedAccounts from '../../blocks/ConnectedAccounts/src/ConnectedAccounts';
import Followers from '../../blocks/Followers/src/Followers';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import Chat9 from '../../blocks/Chat9/src/Chat9';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import EmailAccountRegistrationWeb from '../../blocks/email-account-registration/src/EmailRegister/EmailRegister.web';
import RoomDetails from '../../blocks/DifferentRooms/src/RoomDetails/RoomDetails.web';
import UpcomingRooms from '../../blocks/DifferentRooms/src/UpcomingRooms/UpcomingRooms.web';
import OtpVerificationWeb from '../../blocks/email-account-registration/src/EmailRegister/otpVerification.web';
import Splashscreen from '../../blocks/splashscreen/src/Splashscreen';
import ReportUser from '../../blocks/ReportUser/src/ReportUser';
import GoogleCalendarSync from '../../blocks/GoogleCalendarSync/src/GoogleCalendarSync';
import DuplicateRooms2 from '../../blocks/DuplicateRooms2/src/DuplicateRooms2';
import DifferentRooms from '../../blocks/DifferentRooms/src/DifferentRooms';
import YourLaneTextOrLinks from '../../blocks/YourLaneTextOrLinks/src/YourLaneTextOrLinks';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
import QuestionBank from '../../blocks/QuestionBank/src/QuestionBank';
import LikeAPost from '../../blocks/LikeAPost/src/LikeAPost';
import InvoiceBilling from '../../blocks/InvoiceBilling/src/InvoiceBilling';
import CfConnect4SocialMediaAccounts4 from '../../blocks/CfConnect4SocialMediaAccounts4/src/CfConnect4SocialMediaAccounts4';
import Interactivefaqs from '../../blocks/interactivefaqs/src/Interactivefaqs';
import AddInteractivefaqs from '../../blocks/interactivefaqs/src/AddInteractivefaqs';
import ContentManagement from '../../blocks/ContentManagement/src/ContentManagement';
import CfTabletSupport7 from '../../blocks/CfTabletSupport7/src/CfTabletSupport7';
import Contactus from '../../blocks/contactus/src/Contactus';
import AddContactus from '../../blocks/contactus/src/AddContactus';
import EducationalUserProfile from '../../blocks/educational-user-profile/src/EducationalUserProfile';
import Dashboard from '../../blocks/dashboard/src/Dashboard';
import Onboardingguide from '../../blocks/onboardingguide/src/Onboardingguide';
import OnboardingSuccessful from '../../blocks/onboardingguide/src/OnboardingSuccessful';
import OnboardingDetails from '../../blocks/onboardingguide/src/OnboardingDetails/OnboardingDetails';
import ConfirmModerator from '../../blocks/onboardingguide/src/ConfirmModerator';
import ProductTutorial from '../../blocks/ProductTutorial/src/ProductTutorial';
import Settings5 from '../../blocks/Settings5/src/Settings5';
import UploadMedia2 from '../../blocks/UploadMedia2/src/UploadMedia2';
import LandingPage from '../../blocks/landingpage/src/LandingPage';
import Setting from '../../blocks/Settings5/src/settings/setting.web';
import ModeratorList from '../../blocks/DifferentRooms/src/ModeratorList/ModeratorList.web';
import Home from '../../blocks/landingpage/src/LandingPage/Home.web';
import MyProfileWeb from '../../blocks/Settings5/src/settings/MyProfile.web';
import TermsAndConditions from '../../blocks/Settings5/src/settings/Terms.web';
import PrivacyPolicyData from '../../blocks/Settings5/src/settings/PrivacyPolicy.web';
import { Switch, withRouter } from 'react-router-dom';
import CategoriesWeb from '../../blocks/categoriessubcategories/src/categories/Category.web';
import ModeratorOrientation from '../../../packages/blocks/onboardingguide/src/ModeratorOrientation';
import UserProfile from '../../blocks/user-profile-basic/src/UserPublicProfile/UserProfile.web';
import comingSoon from '../../blocks/onboardingguide/src/comingSoon.web';
import Room from '../../blocks/ThirdPartyAudioAndVideoIntergration/src/room/Room.web';

import RefillWallet from '../../blocks/Payments/src/refillwallet/RefillWallet.web';
import MyWallet from '../../blocks/Payments/src/Wallet/MyWallet.web';
import NotFound from '../../blocks/email-account-registration/src/EmailRegister/NotFound.web';
import ModeratorTerms from '../../blocks/onboardingguide/src/ModeratorTermsAndPolicy.web';
import RoomFeedbackWeb from '../../blocks/Feedback/src/RoomFeedback.web';
import HomePage from '../../blocks/Referrals/src/HomePage/HomePage.web';
import MaintenancePage from '../../blocks/Referrals/src/MaintenancePage/MaintenancePage.web';
import IncorrectTime from '../../blocks/Referrals/src/IncorrectTime';
import AboutPage from '../../blocks/Referrals/src/AboutPage/AboutPage.web';
import FaqPage from '../../blocks/Referrals/src/FaqPage/FaqPage.web';
import ContactUsPage from '../../blocks/Referrals/src/ContactUsPage/ContactUsPage.web';
import HeaderWeb from '../../blocks/Settings5/src/settings/header.web';
import UserEditProfile from '../../blocks/user-profile-basic/src/UserEditProfile/UserEditProfile.web';
import FollowerList from '../../blocks/user-profile-basic/src/UserPersonalProfile/FollowerList.web';
import TouchPoints from '../../blocks/user-profile-basic/src/UserPersonalProfile/TouchPoints.web';
import AdjustVideo from '../../blocks/ThirdPartyAudioAndVideoIntergration/src/room/VideoChatScreen/AdjustVideo.web';
import FirebaseCloudMessage from './FCM';

import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill';
import firebase from 'firebase';

const routeMap = {
  Room: {
    component: Room,
    path: '/Room/:id'
  },
  Settings5: {
    component: Settings5,
    path: '/Settings5'
  },
  RoomFeedbackWeb: {
    component: RoomFeedbackWeb,
    path: '/RoomFeedbackWeb'
  },

  comingSoon: {
    component: comingSoon,
    path: '/comingSoon'
  },
  UserProfile: {
    component: UserProfile,
    path: '/UserProfile'
  },
  UploadMedia2: {
    component: UploadMedia2,
    path: '/UploadMedia2'
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage'
  },

  VisualAnalytics: {
    component: VisualAnalytics,
    path: '/VisualAnalytics'
  },
  QuestionBank: {
    component: QuestionBank,
    path: '/QuestionBank'
  },
  LikeAPost: {
    component: LikeAPost,
    path: '/LikeAPost'
  },

  InvoiceBilling: {
    component: InvoiceBilling,
    path: '/InvoiceBilling'
  },
  CfPayoutBankApi: {
    component: CfPayoutBankApi,
    path: '/CfPayoutBankApi'
  },
  CfConnect4SocialMediaAccounts4: {
    component: CfConnect4SocialMediaAccounts4,
    path: '/CfConnect4SocialMediaAccounts4'
  },
  Interactivefaqs: {
    component: Interactivefaqs,
    path: '/Interactivefaqs'
  },
  HomePage: {
    component: HomePage,
    path: '/HomePage/:step?',
    normalizedPath: '/HomePage',
    guest: true
  },
  HomePagePublic: {
    component: HomePage,
    path: '/HomePagePublic/:step'
  },
  AboutPage: {
    component: AboutPage,
    path: '/AboutPage',
    guest: true
  },
  FaqPage: {
    component: FaqPage,
    path: '/FaqPage',
    guest: true
  },
  ContactUsPage: {
    component: ContactUsPage,
    path: '/ContactUsPage',
    guest: true
  },
  AddInteractivefaqs: {
    component: AddInteractivefaqs,
    path: '/AddInteractivefaqs'
  },
  ContentManagement: {
    component: ContentManagement,
    path: '/ContentManagement'
  },
  Chat: {
    component: Chat,
    path: '/Chat'
  },
  ChatMessages: {
    component: Chat,
    path: '/Chat/:id'
  },
  CfTabletSupport7: {
    component: CfTabletSupport7,
    path: '/CfTabletSupport7'
  },
  Contactus: {
    component: Contactus,
    path: '/Contactus'
  },
  AddContactus: {
    component: AddContactus,
    path: '/AddContactus'
  },
  ConnectionList: {
    component: ConnectionList,
    path: '/ConnectionList'
  },
  FollowerList: {
    component: FollowerList,
    path: '/FollowerList'
  },
  TouchPointList: {
    component: TouchPoints,
    path: '/TouchPointList'
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: '/EducationalUserProfile'
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard'
  },
  Onboardingguide: {
    component: Onboardingguide,
    path: '/Onboardingguide'
  },
  ConfirmModerator: {
    component: ConfirmModerator,
    path: '/ConfirmModerator'
  },
  OnboardingSuccessful: {
    component: OnboardingSuccessful,
    path: '/OnboardingSuccessful'
  },
  OnboardingDetails: {
    component: OnboardingDetails,
    path: '/OnboardingDetails'
  },
  ModuleOrientation: {
    component: ModeratorOrientation,
    path: '/ModuleOrientation/:step?'
  },
  ProductTutorial: {
    component: ProductTutorial,
    path: '/ProductTutorial'
  },
  AnonymousMode: {
    component: AnonymousMode,
    path: '/AnonymousMode'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: '/MobileAccountLoginBlock'
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/AdvancedSearch'
  },
  PunishmentAlerts: {
    component: PunishmentAlerts,
    path: '/PunishmentAlerts'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: '/RolesPermissions2'
  },
  Payments: {
    component: Payments,
    path: '/Payments'
  },
  ConversationHistory2: {
    component: ConversationHistory2,
    path: '/ConversationHistory2'
  },
  PaymentAdmin2: {
    component: PaymentAdmin2,
    path: '/PaymentAdmin2'
  },
  Customform: {
    component: Customform,
    path: '/Customform'
  },
  TabletSupport65: {
    component: TabletSupport65,
    path: '/TabletSupport65'
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  PointSystemhearts: {
    component: PointSystemhearts,
    path: '/PointSystemhearts'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  DeepLinking: {
    component: DeepLinking,
    path: '/DeepLinking'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics'
  },
  GiveHearts: {
    component: GiveHearts,
    path: '/GiveHearts'
  },
  LinkShare: {
    component: LinkShare,
    path: '/LinkShare'
  },
  RefundManagement: {
    component: RefundManagement,
    path: '/RefundManagement'
  },
  DailyTimeLimit: {
    component: DailyTimeLimit,
    path: '/DailyTimeLimit'
  },
  CancellationOfCall: {
    component: CancellationOfCall,
    path: '/CancellationOfCall'
  },
  History5: {
    component: History5,
    path: '/History5'
  },
  Referrals: {
    component: Referrals,
    path: '/Referrals'
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: '/AdminConsole3'
  },
  ThirdPartyAudioAndVideoIntergration: {
    component: ThirdPartyAudioAndVideoIntergration,
    path: '/ThirdPartyAudioAndVideoIntergration'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  UserPersonalProfile: {
    component: UserPersonalProfile,
    path: '/UserPersonalProfile'
  },
  Feedback: {
    component: Feedback,
    path: '/Feedback'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  CategoriessubcategoriesWeb: {
    component: CategoriesWeb,
    exact: false,
    path: '/CategoriesWeb',
    public: true
  },
  SubcategoriesTopic: {
    component: CategoriesWeb,
    exact: false,
    path: '/CategoriesWeb/category/:id/subcategory/:sub_id',
    public: true
  },
  TopicRooms: {
    component: CategoriesWeb,
    exact: false,
    path: '/CategoriesWeb/category/:id/subcategory/:sub_id/rooms/:topic_id'
  },
  RefillWallet: {
    component: RefillWallet,
    exact: true,
    path: '/RefillWallet'
    // modal: true
  },
  MyWallet: {
    component: MyWallet,
    exact: true,
    path: '/MyWallet'
  },
  EditProfile: {
    component: UserEditProfile,
    path: '/EditProfile'
  },
  CertificationTracking: {
    component: CertificationTracking,
    path: '/CertificationTracking'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  ReviewAndApproval: {
    component: ReviewAndApproval,
    path: '/ReviewAndApproval'
  },
  ConnectedAccounts: {
    component: ConnectedAccounts,
    path: '/ConnectedAccounts'
  },
  Followers: {
    component: Followers,
    path: '/Followers'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  Chat9: {
    component: Chat9,
    path: '/Chat9'
  },

  registration: {
    component: EmailAccountRegistrationWeb,
    path: '/registration'
  },

  MyProfileWeb: {
    component: MyProfileWeb,
    path: '/MyProfileWeb'
  },

  RoomDetails: {
    expect: true,
    component: RoomDetails,
    path: '/RoomDetailsWeb/:id'
  },

  UpcomingRooms: {
    component: UpcomingRooms,
    path: '/Rooms'
  },

  otpVerification: {
    component: OtpVerificationWeb,
    path: '/OtpVerification'
  },

  Setting: {
    component: Setting,
    path: '/Setting'
  },
  // HomeWeb: {
  //   component: HomeWeb,
  //   path: '/Homeweb'
  // },

  ModeratorList: {
    component: ModeratorList,
    path: '/ModeratorList'
  },

  TermsAndConditions: {
    component: TermsAndConditions,
    path: '/TermsAndConditions',
    guest: true
  },

  ModeratorTerms: {
    component: ModeratorTerms,
    path: '/ModeratorTerms'
  },

  PrivacyPolicyData: {
    component: PrivacyPolicyData,
    path: '/PrivacyPolicy',
    guest: true
  },

  Splashscreen: {
    component: Splashscreen,
    path: '/Splashscreen'
  },
  ReportUser: {
    component: ReportUser,
    path: '/ReportUser'
  },
  GoogleCalendarSync: {
    component: GoogleCalendarSync,
    path: '/GoogleCalendarSync'
  },
  DuplicateRooms2: {
    component: DuplicateRooms2,
    path: '/DuplicateRooms2'
  },
  DifferentRooms: {
    component: DifferentRooms,
    path: '/DifferentRooms'
  },
  YourLaneTextOrLinks: {
    component: YourLaneTextOrLinks,
    path: '/YourLaneTextOrLinks'
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications'
  },
  Home: {
    component: Home,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  IncorrectTime: {
    component: IncorrectTime,
    path: '/incorrect-time',
    public: true
  },
  AdjustSettings: {
    component: AdjustVideo,
    path: '/adjust-settings/:id'
  },
  // AlertWeb: {
  //   component: AlertBlock,
  //   path: '*/AlertWeb',
  //   modal: true
  // },
  NotFound: {
    component: NotFound,
    path: '*'
    // exact: true
  }
};

class App extends BlockComponent {
  fetchMaintenanceId;
  verifyLocalTimeId;
  verifyCurrentTime;

  constructor(props) {
    super(props);
    this.previousLocation = null;

    this.state = {
      isMaintenanceOn: false
    };

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage)
    ];
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  componentDidMount() {
    polyfillCountryFlagEmojis();

    // Call Maintenance api to get current status
    this.fetchMaintenanceData();

    // Connect to Maintenances Channel to get update about Maintenance
    const cable = actionCable.createConsumer(`${x.baseURL}/cable`);
    cable.subscriptions.create(
      {
        channel: 'MaintenancesChannel'
      },
      {
        connected: () => {
          // console.log("CONNECTED");
        },
        disconnected: () => {
          // console.log("DISCONNECTED");
        },
        received: data => {
          this.setState({
            isMaintenanceOn: data?.maintenances_on === '1'
          });
          // console.log(data?.maintenances_on === "1")
        }
      }
    );

    this.unlisten = this.props.history.listen((location, action) => {
      if (location.pathname === '/incorrect-time') return;
      if (action === 'PUSH' || action === 'REPLACE' || action === 'POP') {
        if (location.pathname !== this.previousLocation?.pathname) {
          this.verifyLocalTime();
        }
        this.previousLocation = location;
      }
    });

    this.verifyLocalTime();
    window.addEventListener('focus', this.verifyLocalTime);
    sessionStorage.removeItem('notification_type');

    // Check if service worker is available
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('message', this.handleRedirect);

      navigator.serviceWorker.onmessage = event => {
        setTimeout(() => {
          const notificationType = sessionStorage.getItem('notification_type');
          if (notificationType === 'foreground') {
            sessionStorage.removeItem('notification_type');
            return;
          }

          const redirect_url = event?.data?.notification?.click_action;

          if (redirect_url) {
            this.props.history.push(redirect_url);
          }
        }, 1000);
        // if the message has a URL, navigate to it
      };
    }
  }

  async componentWillUnmount() {
    this.unlisten();
    window.removeEventListener('focus', this.verifyLocalTime);

    if (navigator.serviceWorker) {
      navigator.serviceWorker.removeEventListener(
        'message',
        this.handleRedirect
      );
    }
  }

  handleFetchMaintenanceId = (apiRequestCallId, responseJson) => {
    if (apiRequestCallId === this.fetchMaintenanceId) {
      this.setState({
        isMaintenanceOn:
          responseJson.data?.find(item => item.platform === 'WEB')
            ?.maintenances_on ?? false
      });
    }
  };

  handleVerifyLocalTimeId = (apiRequestCallId, responseJson) => {
    if (apiRequestCallId === this.verifyLocalTimeId) {
      const pathname = this.props.history.location.pathname;
      const isTimeZoneWrong = JSON.parse(
        sessionStorage.getItem('isTimeZoneWrong')
      );
      if (responseJson?.errors) {
        if (isTimeZoneWrong) return;
        this.getCurrentTime();

        if (pathname !== '/incorrect-time') {
          let redirectRoute = this.props.history.location.pathname;
          if (this.props.history.location.search) {
            redirectRoute += this.props.history.location.search;
          }
          sessionStorage.setItem('redirect-route', redirectRoute);
        }
        this.props.history.replace('/incorrect-time', {
          state: {
            actualDT: new Date(responseJson.actual_time).toLocaleString()
          }
        });
      } else {
        if (isTimeZoneWrong) {
          sessionStorage.removeItem('isTimeZoneWrong');
        }

        if (pathname === '/incorrect-time') {
          const redirect_to = sessionStorage.getItem('redirect-route');
          if (!redirect_to) {
            this.props.history.replace('/Rooms');
            return;
          }

          this.props.history.replace(redirect_to, {
            ...this.previousLocation?.state
          });
        }
      }
    }
  };

  handleVerifyCurrentTime = (apiRequestCallId, responseJson) => {
    if (this.verifyCurrentTime === apiRequestCallId) {
      if (responseJson?.abbreviation) {
        localStorage.setItem('abbreviation', responseJson?.abbreviation);
      }
    }
  };

  async receive(from, message) {
    runEngine.debugLog('Message Recived', message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.handleFetchMaintenanceId(apiRequestCallId, responseJson);
        this.handleVerifyLocalTimeId(apiRequestCallId, responseJson);
        this.handleVerifyCurrentTime(apiRequestCallId, responseJson);
      }
    }
  }

  fetchMaintenanceData = () => {
    const headers = {
      'Content-Type': 'application/json'
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchMaintenanceId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/onboarding_questions/version_managment'
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  getCurrentTime = () => {
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verifyCurrentTime = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `https://worldtimeapi.org/api/timezone/${
        Intl.DateTimeFormat().resolvedOptions().timeZone
      }`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  verifyLocalTime = () => {
    const headers = {
      'Content-Type': 'application/json',
      TZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      UTime: Math.floor(new Date().getTime() / 1000).toString()
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verifyLocalTimeId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_settings/home_page_settings/verify_request'
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  updateFCMToken = (oldToken, newToken) => {
    const headers = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('token')
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/accounts/update_device_token'
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );

    // body
    const payload = { last_device_id: oldToken, new_device_id: newToken };

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  // if tab is already open, and user receives a notification
  // focus and redirect the user to the page.
  handleRedirect = event => {
    const redirectURL = event.data.url;

    if (redirectURL) {
      const url = new URL(redirectURL);
      const path = url.pathname + url.search;

      this.props.history.push(path);
    }
  };

  render() {
    const NoHeader = () => {
      const pathname = this.props.location.pathname;
      if (
        pathname?.toLowerCase().includes('/categoriesweb') &&
        !pathname.includes('/rooms/')
      )
        return !isAuthenticated();
      switch (pathname?.toLowerCase()) {
        case '/homepage/registration':
          return true;
        case '/registration':
          return true;
        case '/onboardingguide':
          return true;
        case '/':
          return true;
        case '/onboardingsuccessful':
          return true;
        case '/termsandconditions':
          return true;
        case '/privacypolicy':
          return true;
        case '/otpverification':
          return true;
        case '/':
          return true;
        case '/confirmmoderator':
          return true;
        case '/aboutpage':
          return true;
        case '/homepage':
          return true;
        case '/faqpage':
          return true;
        case '/contactuspage':
          return true;
        case '/homepagepublic/4':
          return true;
        case '/moderatorterms':
          return true;
        case '/incorrect-time':
          return true;
      }
    };

    return (
      <div
        id="main-view"
        style={{
          width: '100vw',
          overflowY:
            window.location.pathname?.toLowerCase() === '/rooms'
              ? 'hidden'
              : 'auto'
        }}
      >
        <>
          {/* In iPhone Safari browser FCM is not supported. */}
          {firebase.messaging.isSupported() && (
            <FirebaseCloudMessage updateFCMToken={this.updateFCMToken} />
          )}
          {process.env.NODE_ENV === 'production' && (
            <Helmet>
              <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=G-XN6NLTEYJT"
              />
              <script>
                {`
                    window.dataLayer = window.dataLayer || []; 
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date()); 
                    gtag('config', 'G-XN6NLTEYJT');
                `}
              </script>
              <script type="text/javascript">
                {`
                    (function(c,l,a,r,i,t,y){ 
                        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; 
                        t=l.createElement(r);
                        t.async=1;
                        t.src="https://www.clarity.ms/tag/"+i; 
                        y=l.getElementsByTagName(r)[0];
                        y.parentNode.insertBefore(t,y); 
                    })(window, document, "clarity", "script", "i294h6xjs1"); 
                `}
              </script>
              <script>
                {`
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '226751626785492');
                    fbq('track', 'PageView');
                `}
              </script>
            </Helmet>
          )}

          <StateProvider
            children={
              <>
                {this.state.isMaintenanceOn ? (
                  <MaintenancePage />
                ) : (
                  <>
                    {NoHeader() ? (
                      <></>
                    ) : (
                      <HeaderWeb appProps={this.props} routeMap={routeMap} />
                    )}
                    <Switch>
                      {WebRoutesGenerator({ routeMap })}
                      <ModalContainer />
                    </Switch>
                  </>
                )}
              </>
            }
          />
        </>
      </div>
    );
  }
}

export default withRouter(App);
