import React, { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";

interface PropTypes {
   navigate: any,
   when: any,
   shouldBlockNavigation: any
}

export const RouteLeavePrompt = ({ navigate, when, shouldBlockNavigation }: PropTypes) => {
   const [modalVisible, updateModalVisible] = useState(false);
   const [lastLocation, updateLastLocation] = useState<any>();
   const [confirmedNavigation, updateConfirmedNavigation] = useState(false);

   const showModal = (location: any) => {
      updateModalVisible(true);
      updateLastLocation(location);
   };

   const closeModal = (cb: any) => {
      updateModalVisible(false);
      if (cb) {
         cb();
      }
   };

   const handleBlockedNavigation = (nextLocation: any) => {
      if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
         showModal(nextLocation);
         return false;
      }
      return true;
   };
   const handleConfirmNavigationClick = () => {
      closeModal(() => {
         if (lastLocation) {
            updateConfirmedNavigation(true);
         }
      });
   };

   useEffect(() => {
      if (confirmedNavigation) {
         navigate(lastLocation.pathname);
         updateConfirmedNavigation(false);
      }
   }, [confirmedNavigation]);

   return (
      <>
         <Prompt when={when} message={handleBlockedNavigation} />
         {modalVisible &&
            <div className="areYouSureModal">
               <div className="backgroundDiv">
                  <p className="mainText">
                     You have <span className="boldColored">unsaved changes</span> that will be lost if you decide to contuniue.
                  </p>
                  <p className="mainText">
                     Are you sure you want to leave this page?
                  </p>
                  <div className="yesNoBox">
                     <button className="no" onClick={closeModal}>No</button>
                     <button className="yes" onClick={handleConfirmNavigationClick}>Yes</button>
                  </div>
               </div>
            </div>
         }
      </>
   );
};

export default RouteLeavePrompt;