import { Animated, Keyboard } from "react-native";
import { RESULTS } from "react-native-permissions";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import React from 'react'
import MessageEnum, {
   getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { AccountBalanceWallet, CreditCard } from '@material-ui/icons';
export const configJSON = require("./config");

export interface Props {
   navigation: any;
   id: string;
   // Customizable Area Start
   // Customizable Area End
}

interface S {
   // Customizable Area Start
   userProfile: any,
   userHistory: Array<any>,
   walletBalance: string,
   accountStatementData: any
   isDetailsOpen: boolean,
   isLoading: boolean,
   expanded: boolean
   pageCount: number
   pageCountAccount: number
   isMorePage: boolean
   isMorePageAccount: boolean
   transactionLoading: boolean
   accountLoading: boolean
   touchPoints: number
   moderated_details: any
   bankDetails: any
   isUserTab: string;
   pageOrder: number;
   // Customizable Area End

}

interface SS {
   // Customizable Area Start
   id: any;
   // Customizable Area End
}

export default class WalletWebController extends BlockComponent<
   Props,
   S,
   SS
> {
   getWalletBalanceApiCall: any;
   getAccountStatementApiCall: any;
   getRedeemCoinsAPICallID: any;
   fetchTouchPointsId: string;
   [x: string]: any;
   constructor(props: Props) {
      // Customizable Area Start
      super(props);

      this.subScribedMessages = [
         getName(MessageEnum.SessionResponseMessage),
         getName(MessageEnum.RestAPIResponceMessage)
      ];

      this.state = {
         userProfile: {},
         userHistory: [],
         walletBalance: '',
         accountStatementData: [],
         isDetailsOpen: false,
         isLoading: false,
         expanded: false,
         pageCount: 1,
         pageCountAccount: 1,
         moderated_details: undefined,
         isMorePage: false,
         isMorePageAccount: false,
         transactionLoading: false,
         accountLoading: false,
         touchPoints: 0,
         bankDetails: {},
         pageOrder: 0,
         isUserTab: 'User Wallet'
      }
      // Customizable Area End
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
   }

   async componentDidMount(): Promise<void> {
      this.getWalletBalance()
      this.getAccountStatement()
      this.getTouchPoints();
   }

   async receive(from: string, message: Message) {
      // Customizable Area Start
      const responseMessage = getName(MessageEnum.RestAPIResponceMessage)
      if (responseMessage === message.id) {
         const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
         );

         const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
         );

         const errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
         );

         switch (apiRequestCallId) {
            case this.fetchTouchPointsId:
               this.handleFetchTouchPoints(responseJson);
               break;
            case this.getWalletBalanceApiCall:
               this.handleGetWalletBalance(responseJson);
               break;
            case this.getRedeemCoinsAPICallID:
               this.handleRedeemCoins(responseJson);
               break;
            case this.getAccountStatementApiCall:
               this.handleAccountStatement(responseJson);
               break;
            default:
               break;
         }

         if(!responseJson) {
            if (apiRequestCallId === this.getRedeemCoinsAPICallID) {
               this.handleAlert("Something went wrong!")
            }
            if (this.state.isLoading || this.state.accountLoading) {
               setTimeout(() => {
                  this.setState({ isLoading: false, accountLoading: false})
               }, 2000)
            }
         }
      }
      // Customizable Area End
   }

   handleFetchTouchPoints(responseJson:any) {
      if (responseJson) {
         this.setState({ touchPoints: responseJson?.touchpoints_count || 0 });
      }
   }
   
   handleGetWalletBalance(responseJson:any) {
      if (responseJson) {
         this.setState({
            userProfile: responseJson.user_details,
            userHistory: this.state.userHistory.concat(responseJson?.data),
            isLoading: false,
            // isUserTab: responseJson?.user_details?.is_moderator ? 'Moderator Wallet' : 'User Wallet'
         });
   
         if (responseJson.data && responseJson.data?.length > 9) {
            this.setState({ pageCount: this.state.pageCount + 1, transactionLoading: false });
            setTimeout(() => {
               this.setState({ isMorePage: true });
            }, 500);
         } else {
            this.setState({ isMorePage: false, transactionLoading: false });
         }
      }
   }
   
   handleRedeemCoins(responseJson:any) {
      this.setState({ expanded: !this.state.expanded });
   }
   
   handleAccountStatement(responseJson:any) {
      if (this.state.pageCountAccount === 1) {
         this.setState({
            accountStatementData: responseJson,
            isLoading: false,
            pageCountAccount: this.state.pageCountAccount + 1,
            bankDetails: responseJson.moderator_details.bank_details,
            moderated_details: responseJson.moderator_details
         });
   
         if (responseJson.moderated_rooms?.data && responseJson.moderated_rooms.data.length > 9) {
            setTimeout(() => {
               this.setState({ isMorePageAccount: true, accountLoading: false });
            }, 500);
         } else {
            setTimeout(() => {
               this.setState({ accountLoading: false });
            }, 500);
         }
      } else {
         if (responseJson.moderated_rooms?.data && responseJson.moderated_rooms.data.length > 9) {
            this.setState((prevState) => ({
               accountStatementData: {
                  ...prevState.accountStatementData,
                  moderated_rooms: {
                     ...prevState.accountStatementData.moderated_rooms,
                     data: [...prevState.accountStatementData.moderated_rooms.data, ...responseJson.moderated_rooms.data]
                  }
               },
               pageCountAccount: prevState.pageCountAccount + 1
            }));
            setTimeout(() => {
               this.setState({ isMorePageAccount: true, accountLoading: false });
            }, 500);
         } else {
            this.setState({ isMorePageAccount: false, accountLoading: false });
         }
      }
   }

   getWalletBalance = () => {
      if (this.state.userHistory.length === 0) {
         this.setState({ isLoading: true })
      }
      this.setState({ isMorePage: false, transactionLoading: true })
      const header = {
         "Content-Type": configJSON.ApiContentType,
         // eslint-disable-next-line no-undef
         token: localStorage.getItem("token")
      };
      const requestMessage = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getWalletBalanceApiCall = requestMessage.messageId;

      requestMessage.addData(
         getName(MessageEnum.RestAPIResponceEndPointMessage),
         configJSON.getYcoins + this.state.pageCount
      );
      requestMessage.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(header)
      );

      requestMessage.addData(
         getName(MessageEnum.RestAPIRequestMethodMessage),
         configJSON.httpGetType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
   }

   getAccountStatement = () => {
      if (this.state.pageCountAccount === 1) {
         this.setState({ isLoading: true })
      }

      this.setState({ isMorePageAccount: false, accountLoading: true })
      const header = {
         "Content-Type": configJSON.ApiContentType,
         // eslint-disable-next-line no-undef
         token: localStorage.getItem("token")
      };
      const requestMessage = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getAccountStatementApiCall = requestMessage.messageId;

      requestMessage.addData(
         getName(MessageEnum.RestAPIResponceEndPointMessage),
         configJSON.getAccountStatement + this.state.pageCountAccount + '&per_page=10'
      );
      requestMessage.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(header)
      );

      requestMessage.addData(
         getName(MessageEnum.RestAPIRequestMethodMessage),
         configJSON.httpGetType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
   }


   getRedeemCoins = () => {
      const header = {
         "Content-Type": configJSON.ApiContentType,
         // eslint-disable-next-line no-undef
         token: localStorage.getItem("token")
      };
      const requestMessage = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getRedeemCoinsAPICallID = requestMessage.messageId;

      requestMessage.addData(
         getName(MessageEnum.RestAPIResponceEndPointMessage),
         configJSON.redeemCoins
      );
      requestMessage.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(header)
      );

      requestMessage.addData(
         getName(MessageEnum.RestAPIRequestMethodMessage),
         configJSON.httpPostType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
   }

   walletSuccess = (value: number) => {
      let data = {
         issue_type: 'Purchased',
         ycoins: value,
         created_at: new Date()
      }
      let arr = this.state.userHistory
      arr.unshift(data)
      let user: any = this.state.userProfile
      user.ycoins += value
      this.setState({ userHistory: arr, userProfile: user })
   }

   changeIsDetailsOpen = (data: any) => {
      this.setState({ isDetailsOpen: !this.state.isDetailsOpen })
      if (data && data.data && data.data.attributes.moderator_profile) {
         let bankDetail = data.data.attributes.moderator_profile[0].bank_details[0]
         this.setState({ bankDetails: bankDetail })
      }
   }

   getTouchPoints = () => {
      const userId = localStorage.getItem("userId");
      const page = 1;
      const perPage = 1;

      const getValidationsMsg = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
      );

      this.fetchTouchPointsId = getValidationsMsg.messageId;

      getValidationsMsg.addData(
         getName(MessageEnum.RestAPIResponceEndPointMessage),
         `${configJSON.fetchTouchPointsApiEndPoint}?id=${userId}&page=${page}&per_page=${perPage}`
      );

      getValidationsMsg.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify({
            "Content-Type": configJSON.ApiContentType,
            "token": localStorage.getItem("token")
         })
      );
      getValidationsMsg.addData(
         getName(MessageEnum.RestAPIRequestMethodMessage),
         configJSON.httpGetType
      );

      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
   };
}