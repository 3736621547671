export const imgPasswordVisible = require('../assets/ic_password_visible.png');
export const imgPasswordInVisible = require('../assets/ic_password_invisible.png');
export const Heart = require('../assets/yellowheart.png');
export const cancel_logo = require('../assets/cancel_logo.png');
export const YlanesLogo = require('../assets/ylanesLogo.png');
export const sadEmoji = require('../assets/sad.png');
export const happyEmoji = require('../assets/Happy.png');
export const lovedItEmoji = require('../assets/Lovedit.png');
export const FlowerImage = require('../assets/Flower.png');
export const NoProfileImg = require('../assets/No_Profile_Pic.jpeg');
export { ReactComponent as UserWarningIcon } from "../assets/user_warning.svg";
export { ReactComponent as PeopleGroupIcon } from "../assets/people_group.svg";
export { ReactComponent as Connected } from "../assets/connected.svg";
export { ReactComponent as SendRequest } from "../assets/send-request.svg";
export { ReactComponent as PendingRequest } from "../assets/pending.svg";
export { ReactComponent as Circle } from "../assets/circle.svg";
export { ReactComponent as FullCircle } from "../assets/fill_circle.svg";
