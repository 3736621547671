import React, {useState, useEffect} from 'react'
import {addIcon} from "../../assets";
import './EditTake.scss'
import { Button, TextField, Container, Grid } from '@material-ui/core';
import ClearIcon from "@material-ui/icons/Clear";
import CreateIcon from "@material-ui/icons/Create";
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import CheckIcon from '@material-ui/icons/Check';

export const useResourceMapWrapper = (setFunction: React.Dispatch<React.SetStateAction<any[]>>, state: any[]) => {   
   const [resourceText, setResourceText] = useState<string>('');
   const [errorText, setErrorText] = useState<string>('');
   const [tempText, SetTempText] = useState<string>('')
   const [addNew, setAddNew] = useState<boolean>(false);
   const [blankError, setBlankError] = useState<boolean>(false)
   const [isEditing, setIsEditing] = useState<boolean[]>([])
   const pattern =/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    
   useEffect(() => {
      if (state.length > 0) {
         setIsEditing(new Array(state.length).fill(false))
      }
   }, [state])

   const handleAddResource = () => {
      if (!addNew)  {
         setAddNew(true)
      } else {
         setBlankError(true) 
         setErrorText('')
      }
   };
   
   const handleAcceptResource = () => {
      let result = pattern.test(resourceText.trimStart());
      if (resourceText.length > 0 && result) {
         setErrorText('')
         setFunction([...state, { url: 'https://' + resourceText }]);
         setResourceText('');
      } else {
         setErrorText('*Please enter valid URL')
         setBlankError(false)
      }
   }

   const handleChange = (text: string, index:number) => {
      const updatedState: any = [...state];
      updatedState[index].url = text;
      state = updatedState
   }

   const handleChangeTemp = (value: string) => {
      SetTempText(value)
   }

   const handleEditResource = (index:number) => {
      let result = pattern.test(tempText.trimStart())
      if (tempText.length > 0 && result) {
         setErrorText('')
         const updatedState: any = [...state];
         updatedState[index].url = tempText;
         setFunction(updatedState);
      } else  {
         setErrorText('*Please enter the valid URL')
      }
   }

   const handleDelete = (index:number) => {
      const updatedState: any = [...state];
      updatedState[index] = { ...updatedState[index], _destroy: 1 };
      setFunction(updatedState);
      //  setFunction(state.filter((_, i) => i !== index))
   }

   const changeEditState = (index: number) => {
    SetTempText(state[index].url)
    setIsEditing(prevIsEditing => prevIsEditing.map((value, idx) => idx === index ? !value : value))
   }

    return (
      <div className="resourcesOutside">
      <div className="resourcesTop">
        <span>Resources</span>
        <img src={addIcon} onClick={handleAddResource} />
        </div>
        <div className="resourceList">
          <div className="resources">
            {state.map((resource: any, i: number) => {
              const { url } = resource;
              const fullUrl = url?.includes("//") ? url : `//${url}`;
               if (resource?._destroy) return ''
               return (
                  <div className="resourceWrappeer" key={i}>
                  {isEditing[i] ? 
                     <>
                        <div className="addNewResource">
                           <input
                              type="text"
                              className="textBox"
                              value={tempText}
                              placeholder="Resource Link"
                              onChange={(e) => {
                                 if (errorText?.length > 0) setErrorText('')
                                 handleChangeTemp(e.target.value)
                              }}
                           />
                           <CheckIcon onClick={() => handleEditResource(i)} />
                           {/* <img src={CheckIcon} onClick={() => handleEditResource(i)} /> */}
                        </div>
                        <div className="errorBox">{errorText.length > 0 && errorText}</div>
                     </>
                     :
                     <div className="resourceText">{fullUrl}</div>
                  }
                  <div className="buttons">
                     {!isEditing[i] &&
                        <CreateIcon style={{cursor:'pointer'}} onClick={() => changeEditState(i)} />
                     }
                     <ClearIcon className="clearIcon" onClick={() => {!isEditing[i] ? handleDelete(i) : changeEditState(i)}}/>
                  </div>
                  </div>
               );
            })}
            {addNew &&
               <>
                  <div className="addNewResource">
                  <input
                     type="text"
                     className="textBox"
                     value={resourceText}
                     placeholder="Resource Link"
                     onChange={(e) => {
                        if (errorText?.length > 0) setErrorText('')
                        if (blankError) setBlankError(false)
                        setResourceText(e.target.value)
                     }}
                  />
                  <CheckIcon onClick={() => handleAcceptResource()} />
                  </div>
                  {blankError && <div className="errorBox">*Please Enter URL First</div>}
                  {errorText.length > 0 && <div className="errorBox">{errorText}</div>}
               </>
            }
          </div>
        </div>
      </div>
    );
  };
 
 // switch style input
 export const switchInput = (text1: string, text2: string, setFunction: React.Dispatch<React.SetStateAction<string>>, state: string, disabled?: boolean) => {
       const handleButtonClick = (text: string) => {
          setFunction(text);
        };
       return (
          <div className="sliderBase">
             <div className={`insideButtons ${state === text1 ? 'active' : ''}`} onClick={() => {!disabled && handleButtonClick(text1)}}>
                {text1}
             </div>
             <div className={`insideButtons ${state === text2 ? 'active' : ''}`} onClick={() => {!disabled && handleButtonClick(text2)}}>
                {text2}
             </div>
          </div>
       )
 }

 // switch style input
 export const SwitchInputForClass = ({text1,text2,setFunction,state,disabled = false,}:
    {text1: string;text2: string;setFunction: (value: string) => void;state: string;disabled?: boolean; }) => {
   const handleButtonClick = (text: string) => {
      setFunction(text);
    };
   return (
      <div className="sliderBase">
         <div className={`insideButtons ${state === text1 ? 'active' : ''}`} onClick={() => {!disabled && handleButtonClick(text1)}}>
            {text1}
         </div>
         <div className={`insideButtons ${state === text2 ? 'active' : ''}`} onClick={() => {!disabled && handleButtonClick(text2)}}>
            {text2}
         </div>
      </div>
   )
}
 
    
 // WarningBox Wrapper
 export const warningBox = (text:string) => {
    return (
       <div className="warningBoxWrapper">
          <PriorityHighIcon className="warningIconWrapper" />
          <span>{text}</span>
       </div>
    )
 }
 
 // basic small textbox
 export const basicTextbox = (value:string, label: string, setFunction: React.Dispatch<React.SetStateAction<string>>, textLimit?: number) => {
   return (
      <div className="smallTextArea">
         <span className="headerText">{label}</span> 
            <TextField
               className="textArea"
               placeholder="Your answer"
               multiline
               name="textArea"
               variant="outlined"
               value={value}
               onChange={(e) => {
               e.target.value = e.target.value?.trimStart();
               setFunction(e.target.value)
               }}
               inputProps={{
                  maxLength: textLimit ?? 200,
               }}
               helperText={`${(textLimit ?? 200) - value?.length} Characters Remaning`}
            />
         </div>
   )
 }

 // textBox with label
 export const textAreaBox = (value: string, label: string, setFunction: React.Dispatch<React.SetStateAction<string>>, textLimit?: number, state?: any) => {
    return (
       <div className="textArea">
          <span className="headerText">{label}</span> 
             <TextField
                className="text-area"
                placeholder="Your answer"
                multiline
                name="textArea"
                variant="outlined"
                value={value}
                onChange={(e) => {
                e.target.value = e.target.value?.trimStart();
                setFunction(e.target.value)
                }}
                inputProps={{
                   maxLength: textLimit ?? 200,
                }}
                helperText={`${(textLimit ?? 200) - value?.length} Characters Remaning`}
             />
          </div>
    )
 }
 