// Customizable Area Start
import React, { useEffect, useState } from 'react'
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded'
import { Typography, Grid, Button, Box, TextField, FormControl, Select, Chip, CircularProgress, ListItemText, MenuItem, ListItemIcon, Checkbox } from '@material-ui/core';
import CancelIcon from "@material-ui/icons/Cancel";
import DropDown from '../Dropdown.web';
import '../styles.css'
import ConfirmationModal from '../../../../onboardingguide/src/OnboardingDetails/ConfirmationModal.web';
import { InstagramPng, LinkedInPng, FacebookPng, TwitterPng, cross, ples } from '../../assets';

interface PropTypes {
   userData: {}
   subcategories: any[]
   checker: boolean
   update: any
   setError: (e: boolean, index: number) => void
   handleAlert: (msg: string, success: boolean) => void
   changeForm: () => void
}

interface StateTypes {
   type: string,
   bio: string,
   moderator_sub_categories: any[],
   personal_exp: string,
   training: any[],
}

type LoginChoices = "Instagram" | "Facebook" | "Twitter" | "LinkedIn";

interface SocialConfirm {
   isOpen: boolean;
   type: LoginChoices | null;
}

interface ScTypes {
   links: any[];
   added: any[];
   count: number;
}

interface ErrorList {
   moderator_sub_categories: {
      max: boolean,
      min: boolean,
      required: boolean,
   }
   bio: {
      max: boolean,
      min: boolean,
      required: boolean,
   }
   socialMedia: {
      facebook: boolean
      instagram: boolean
      linkedIn: boolean
      twitter: boolean
   }
}

const ModeratorInformation = ({ userData, subcategories, checker, update, changeForm, setError, handleAlert }: PropTypes) => {

   const [isLoad, setIsLoad] = useState(false)
   const [isChanged, setChanged] = useState<boolean>(false)
   const [hasErrorIn, setHasErrorIn] = useState<boolean>(true)
   const [errorList, setErrorList] = useState<ErrorList>({
      moderator_sub_categories: {
         max: false,
         min: false,
         required: false,
      },
      bio: {
         max: false,
         min: false,
         required: false,
      },
      socialMedia: {
         facebook: false,
         instagram: false,
         linkedIn: false,
         twitter: false
      }
   })
   const [deleteList, setDeleteList] = useState<any>([])
   const [socialConfirm, setSocialConfirm] = useState<SocialConfirm>(
      {
         isOpen: false,
         type: null
      }
   )
   const [socialMedia, setSocialMedia] = useState<ScTypes>({
      links: ['Twitter', 'LinkedIn', 'Facebook', 'Instagram'],
      added: [],
      count: 0
   })
   const [moderatorData, setModeratorData] = useState<StateTypes>({
      type: "moderator_profile_data",
      bio: "",
      moderator_sub_categories: [],
      personal_exp: "",
      training: [
         {
            institute_name: "",
            passingYear: "",
            course: "",
            documents: []
         }
      ]
   })

   const year = (new Date()).getFullYear();
   const years = Array.from(new Array(100), (val, index) => year - index)


   const setErrorFunction = (type: string, inputType: keyof ErrorList, state?: boolean) => {
      if (type === 'reset') {
         setErrorList({
            ...errorList,
            [inputType]: {
               max: false,
               min: false,
               required: false,
            },
         });
      } else {
         setErrorList({
            ...errorList,
            [inputType]: {
               ...errorList[inputType],
               [type]: state,
            },
         });
      }
   };

   const handleChange = (e: any, index?: any, docIndex?: any) => {
      if (!isChanged) {
         setChanged(true)
         changeForm()
      }
      if (index > -1) {
         handleTrainingInputChange(e, index, docIndex)
      } else {
         if (e.target.name === 'moderator_sub_categories') {
            handleCategoryInputChange(e)
         } else {
            setModeratorData({ ...moderatorData, [e.target.name]: e.target.value })
         }
      }
   }

   const handleTrainingInputChange = (e: any, index?: any, docIndex?: any) => {
      let moder = { ...moderatorData.training[index] }
      // handle files
      if (e.target.files) {
         if (docIndex) moder[e.target.name][docIndex] = e.target.files[0]
         else moder[e.target.name][0] = e.target.files[0]
      } else if (e.target.deleteFile) {
         if (moder[e.target.name][docIndex].name || moder[e.target.name][docIndex].length > 0) {
            moder[e.target.name][docIndex] = ''
         } else {
            moder[e.target.name].splice(docIndex, 1)
         }
      } else {
         moder[e.target.name] = e.target.value
      }
      let newD = moderatorData
      newD.training[index] = moder
      setModeratorData({ ...moderatorData, training: newD.training })
   }

   const socialMediaCheck = (text: string, name: keyof ErrorList['socialMedia']) => {
      const urlPattern = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?$/;
      if(urlPattern.test(text)) {
         if (errorList.socialMedia[name] === true) {
            setErrorList({
               ...errorList,
               socialMedia: {
                  ...errorList.socialMedia,
                  [name]: false,
               },
            });
         } 
      } else {
         if (errorList.socialMedia[name] === false) {
            setErrorList({
               ...errorList,
               socialMedia: {
                  ...errorList.socialMedia,
                  [name]: true,
               },
            });
         }
      }
   }

   const handleCategoryInputChange = (e: any) => {
      if (e.target.value.length > 3) {
         setErrorFunction('max', 'moderator_sub_categories', true)
      } else if (e.target.value.length < 3) {
         setErrorFunction('min', 'moderator_sub_categories', true)
      } else if (e.target.value.length === 3) {
         setErrorFunction('reset', 'moderator_sub_categories')
      }
      setModeratorData({ ...moderatorData, [e.target.name]: e.target.value })
   }

   const removeSocialLogin = (index: number, type:keyof ErrorList['socialMedia']) => {
      const tempArr = [...socialMedia.added];
      const newSocialMedia = { ...errorList.socialMedia };
      delete newSocialMedia[type];
      setErrorList({...errorList,socialMedia: newSocialMedia});
      tempArr.splice(index, 1);
      setSocialMedia({ ...socialMedia, count: socialMedia.count - 1, added: tempArr });
   }

   const addDocument = (index: any) => {
      if (!moderatorData.training[index].documents) {
         let changed = moderatorData.training
         changed[index].documents = ['']
         setModeratorData({ ...moderatorData, training: changed })
      } else if (moderatorData?.training[index].documents?.length < 4) {
         let changed = moderatorData.training
         changed[index].documents.push('')
         setModeratorData({ ...moderatorData, training: changed })
      }
   }

   const addRemove = (add: boolean, item?: any) => {
      if (!isChanged) {
         setChanged(true)
         changeForm()
      }
      if (add) {
         let dAdd = {
            institute_name: "",
            passingYear: "",
            course: "",
            documents: []
         }
         setModeratorData({ ...moderatorData, training: moderatorData.training.concat(dAdd) })
      } else {
         if (item.id) {
            setDeleteList([...deleteList, item.id])
         }
         setModeratorData({ ...moderatorData, training: moderatorData.training.filter(el => el !== item) })
      }
   }

   const addSocialLogin = () => {
      if (!isChanged) {
         setChanged(true)
         changeForm()
      }
      if (socialMedia.added.length === socialMedia.count) {
         setSocialMedia({ ...socialMedia, count: socialMedia.count + 1 });
      } else {
         handleAlert("Please add a social media handle first; after that, you can add another one.", false)
      }
   }

   const openSocialMedia = (type: any, index: number) => {
      if (!isChanged) {
         setChanged(true)
         changeForm()
      }
      const tempArr: any = [...socialMedia.added];
      setErrorList({
            ...errorList,
            socialMedia: {
               ...errorList.socialMedia,
               [type.toLowerCase()]: false,
            },
      }); 
      tempArr[index] = { type, value: "" };
      setSocialMedia({ ...socialMedia, added: tempArr })
      // if (type.toLowerCase() !== 'facebook' && type.toLowerCase() !== 'linkedin') {
      //    setSocialConfirm({ type, isOpen: true })
      // }
   }

   // disabled instead of connect acccounts turned to text box with web url check
   const updateToken = (type: LoginChoices, details: any) => {
      // if (hasError) setHasError("");

      localStorage.setItem(`${type}`, JSON.stringify(details));

      const tempArr = [...socialMedia.added];
      const index = tempArr.findIndex((each) => each.type === type);
      tempArr[index] = { type, value: details };
      setSocialMedia({ ...socialMedia, added: tempArr })
      closeSocialMedia(type, "done");
   }
   // disabled
   const closeSocialMedia = (method: LoginChoices, type: "cancel" | "done") => {
      if (type === "cancel") {
         const indx = socialMedia.added.findIndex((each) => each?.type === method);
         if (indx > -1) {
            const newRecords = [...socialMedia.added];
            newRecords.splice(indx, 1);
            setSocialMedia({ ...socialMedia, added: newRecords })
         }
      }
      setSocialConfirm({ ...socialConfirm, isOpen: false })
   }

   const getImgType = (type: "Twitter" | "LinkedIn" | "Instagram" | "Facebook") => {
      const res = {
         Twitter: TwitterPng,
         LinkedIn: LinkedInPng,
         Instagram: InstagramPng,
         Facebook: FacebookPng
      }

      return res[type];
   }

   const imgSelector = (imgName: any): any => {
      let type: any = getImgType(imgName);

      if (imgName === 'Facebook') {
         return (
            <img className="facebook" src={type} />
         )
      } else if (type) {
         return (
            <img src={type} />
         )
      } else {
         ''
      }
   }


   // set data to state from api
   useEffect(() => {
      if (userData) {
         let uData: any = userData
         let trainingList: any = []
         if (uData && Object.keys(uData).length > 0 && uData.moderator_profile && uData.moderator_profile.length > 0) {
            uData.moderator_profile[0].educational_qualifications?.forEach((item: any) => {
               let data = {
                  id: item.id,
                  institute_name: item.institute_name,
                  passingYear: item.passing_year,
                  course: item.courses[0]?.name,
                  documents: [] as any
               }
               item.courses.forEach((element: any) => {
                  if (element.document.length > 0) {
                     const doc: any = element.document[0]
                     data.documents.push(doc)
                  }
               });
               trainingList.push(data)
            })

            const modData = {
               type: "moderator_profile_data",
               bio: uData.moderator_profile[0].bio ?? '',
               moderator_sub_categories: uData.moderator_user_sub_categories.map((item: any) => item.sub_category_id),
               personal_exp: uData.moderator_profile[0]?.personal_exp ?? '',
               training: trainingList?.length > 0 ? trainingList : [{
                  institute_name: "",
                  passingYear: "",
                  course: "",
                  documents: [],
               }]
            }
            
            if (modData.moderator_sub_categories.length < 3) {
               setErrorFunction('min', 'moderator_sub_categories', true)
            }

            let socialList: any = []
            uData.moderator_profile[0].social_accounts?.forEach((item: any) => {
               let dataSocial = {
                  type: item.social_type,
                  value: item.social_url
               }
               socialList.push(dataSocial)
               setErrorList({
                  ...errorList,
                  socialMedia: {
                     ...errorList.socialMedia,
                     [item.social_type.toLowerCase()]: false,
                  },
               }); 
            });
            setSocialMedia({ ...socialMedia, added: socialList, count: socialList.length })
            setModeratorData(modData)
         }
         setIsLoad(true)
      }
   }, [userData])


   // package up to data for request
   useEffect(() => {
      if (checker) {
         let copyArr: any = { ...moderatorData }
         let changer = JSON.stringify(moderatorData.moderator_sub_categories)
         let inst = JSON.stringify(moderatorData.training)
         copyArr.moderator_sub_categories = changer
         copyArr.training = inst
         const formData = new FormData()

         formData.append("type", "moderator_profile_data")
         formData.append("attributes[bio]", moderatorData.bio)
         formData.append("attributes[personal_exp]", moderatorData.personal_exp)
         moderatorData.moderator_sub_categories.forEach((item) => {
            formData.append("attributes[moderator_sub_categories][]", JSON.stringify(item))
         })
         if (socialMedia.count > 0) {
            socialMedia.added.forEach((item) => {
               formData.append("attributes[social_accounts][][social_type]", item.type)
               formData.append("attributes[social_accounts][][social_url]", item.value.userLink || item.value.accessToken || item.value)
            })
         }
         moderatorData?.training.forEach((item) => {
            if (item.id) formData.append("attributes[training][][id]", item.id)
            formData.append("attributes[training][][institute_name]", item.institute_name)
            formData.append("attributes[training][][passing_year]", item.passingYear)
            formData.append("attributes[training][][courses][][name]", item.course)
            item.documents && item.documents.length > 0 && item.documents.map((doc: any) => {
               formData.append("attributes[training][][courses][][documents]", doc)
            })
         })
         deleteList?.map((item: any) => {
            formData.append('attributes[training][][id]', item)
            formData.append('attributes[training][][delete]', 'true')
         })

         update(formData)
      }
   }, [checker])

   // Check is this component has any errors if not, then send to parent compoenent.
   useEffect(() => {
      let isError = Object.values(errorList).every((value) => Object.values(value).every((item) => item === false)) 
      isError ? setHasErrorIn(false) : setHasErrorIn(true)
      if (!isError) {
         setError(true, 2)
         // setHasErrorIn(true)
      } else if (isError) {
         setError(false, 2)
         // setHasErrorIn(false)
      }
   }, [errorList])

   return (
      <Grid xs={12} container justifyContent="space-around">
         <ConfirmationModal
            isOpen={socialConfirm.isOpen}
            handleClose={closeSocialMedia}
            userChoice={socialConfirm.type}
            updateToken={updateToken}
         />
         <Grid xs={12} md={8} lg={8} className="PerosnalInformationFormInside">
            {isLoad ?
               <form className="fullForm">
                  <Grid container xs={12} >
                     {/* Bio field */}
                     <Grid className="fieldGrid" xs={12}>
                        <div className="pInfoLabel">
                           Bio
                        </div>
                        <TextField
                           className='text-area' minRows="4"
                           placeholder="Your answer" multiline
                           name='bio'
                           variant="outlined"
                           value={moderatorData.bio}
                           onChange={(e) => {
                              e.target.value = e.target.value && e.target.value.trimStart();
                              handleChange(e)
                           }}
                           inputProps={{
                              maxLength: 100
                           }}
                           helperText={`${100 - moderatorData.bio?.length} Characters`}
                           margin="normal"
                        />

                     </Grid>

                     <Grid className="fieldGrid" style={{ border: '0.5px solid #EBEBEB', width: '100%' }} />

                     {/* Preferred Subcategories */}
                     <Grid className="fieldGrid" >
                        <div className="pInfoLabel">
                           Preferred Subcategories for Moderation
                        </div>
                        <FormControl fullWidth variant="outlined">
                           <Select
                              IconComponent={KeyboardArrowDownRoundedIcon}
                              displayEmpty={true}
                              style={{ width: '100%', borderRadius: '8px', background: "#fff", opacity: 1 }}
                              labelId="mutiple-select-label"
                              multiple
                              className="blur-placeholder-adtn-moderator"
                              value={moderatorData.moderator_sub_categories}
                              name='moderator_sub_categories'
                              onChange={handleChange}
                              MenuProps={{
                                 PaperProps: {
                                    style: {
                                       maxHeight: 48 * 4.5 + 8,
                                       width: 250
                                    }
                                 },
                                 getContentAnchorEl: null,
                                 anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "center"
                                 },
                                 transformOrigin: {
                                    vertical: "top",
                                    horizontal: "center"
                                 },
                                 variant: "menu"
                              }}
                              renderValue={(selected: any) => moderatorData.moderator_sub_categories.length ? <div style={{
                                 display: 'flex',
                                 flexWrap: 'wrap',
                                 opacity: 1
                              }}>
                                 {(moderatorData.moderator_sub_categories).map((value: any, indx: number) => {
                                    let finded = subcategories.find(item => item[1] === value)
                                    if (finded) {
                                       let sub: any = {
                                          id: finded[1],
                                          attributes: {
                                             id: finded[1],
                                             name: finded[0]
                                          }
                                       }
                                       return (
                                          < Chip key={indx}
                                             label={sub.attributes.name}
                                             onClick={(e) => e.stopPropagation()}
                                             // deleteIcon={
                                             //    <CancelIcon
                                             //       onMouseDown={(event) => event.stopPropagation()}
                                             //       style={{ color: "#fff" }}
                                             //    />
                                             // }
                                             // onDelete={(e) => {
                                             //    e.stopPropagation();
                                             // }}
                                             style={{ margin: 2, color: "white", backgroundColor: "#101038" }}
                                          />
                                       )
                                    }

                                 })}
                              </div>
                                 : "Choose up to 3 subcategories to moderate"
                              }
                           >

                              {subcategories && subcategories.map((item: any) => {
                                 if (item[1] && item[0]) {
                                    let option: any = {
                                       id: item[1],
                                       attributes: {
                                          id: item[1],
                                          name: item[0]
                                       }
                                    }
                                    return (
                                       <MenuItem
                                          key={option.id} value={option.attributes.id}
                                       >
                                          <ListItemText primary={option.attributes.name} />
                                          <ListItemIcon>
                                             {/* @ts-ignore*/}
                                             <Checkbox checked={moderatorData.moderator_sub_categories.find(item => item == option.attributes.id) ? true : false} />
                                          </ListItemIcon>
                                       </MenuItem>


                                    )
                                 }
                              })}
                           </Select>

                        </FormControl>
                        {hasErrorIn && (() => {
                           const [key] = Object.entries(errorList.moderator_sub_categories).find(([key, value]) => value === true) || [];
                           let string = '';
                           switch (key) {
                              case 'max':
                                 string = 'A maximum of 3 subcategories can be selected';
                                 break;
                              case 'min':
                                 string = 'A minimum of 3 subcategories can be selected';
                                 break;
                              case 'required':
                                 string = 'Please select 3 subcategories';
                                 break;
                              default:
                                 break;
                           }
                           return (
                              <span style={{ display: "block", color: "red", fontSize: "16px", marginTop: "10px" }}>{string}</span>
                           );
                        })()
                        }
                     </Grid>
                     {/* Personal Experience */}
                     <Grid className="fieldGrid" >
                        <div className="pInfoLabel">
                           Personal Experience
                        </div>
                        <FormControl fullWidth variant="outlined">
                           <input
                              name="personal_exp"
                              value={moderatorData.personal_exp}
                              placeholder='Add your experience'
                              maxLength={2000}
                              minLength={300}
                              className="input-field"
                              onChange={(e) => {
                                 e.target.value = e.target.value && e.target.value.trimStart()
                                 handleChange(e)
                              }}
                           />

                        </FormControl>
                     </Grid>

                     <Typography
                        style={{
                           paddingLeft: '50%', marginTop: '20px', fontWeight: 600,
                           color: '#08063D'
                        }}>
                        Or
                     </Typography>

                     {/* Relevant Training/training */}
                     <Grid className="fieldGrid" >
                        <div className="pInfoLabel">
                           -Relevant Training/Certifications
                        </div>
                        <FormControl style={{ width: '100%' }}>
                           <Box style={{ width: '100%' }} >
                              {moderatorData?.training?.length > 0 &&
                                 moderatorData.training.map((item, index) => (
                                    <Box className="row" key={index}>
                                       <div className="addSpace"></div>
                                       <Box className="colModerator" mt={4} >
                                          <div className="pInfoLabel">
                                             Institute
                                          </div>
                                          {index > 0 ? (
                                             <div
                                                className="moderatorAdd cross"
                                                onClick={() => {
                                                   addRemove(false, item)
                                                }}
                                             >
                                                <img src={cross} />
                                             </div>
                                          )
                                             :
                                             (
                                                <div
                                                   className="moderatorAdd"
                                                   onClick={() => {
                                                      addRemove(true)
                                                   }}
                                                >
                                                   <img src={ples} />
                                                </div>
                                             )
                                          }
                                          <FormControl fullWidth variant="outlined">
                                             <input
                                                name="institute_name"
                                                value={item.institute_name}
                                                placeholder='Add your experience'
                                                className="input-field"
                                                maxLength={70}
                                                onChange={(e) => {
                                                   handleChange(e, index)
                                                }}
                                             />

                                          </FormControl>

                                       </Box>
                                       <Box className="col" mt={4}>
                                          <div className="pInfoLabel">
                                             Year of passing
                                          </div>
                                          <DropDown style={{ background: "#fff" }}
                                             name="passingYear"
                                             list={years && years.map((item: any) => item.toString())}
                                             value={item.passingYear}
                                             onChange={(e) => {
                                                handleChange(e, index)
                                             }}
                                             className='MuiOutlinedInput-root year-select'
                                             defaultText="Select year "
                                          />

                                       </Box>

                                       <Box className="col" mt={4}>
                                          <div className="pInfoLabel">
                                             Certification/Course
                                          </div>
                                          <input
                                             name="course"
                                             value={item.course}
                                             placeholder='Add training/course details'
                                             className="input-field"
                                             // maxLength={30}
                                             onChange={(e) => {
                                                handleChange(e, index)
                                             }}
                                          />

                                       </Box>

                                       <Box className="col" mt={4}>
                                          <div className="pInfoLabel">
                                             Documents
                                             {item.documents.length < 3 &&
                                                <div
                                                   className="documentAdd"
                                                   onClick={() => {
                                                      addDocument(index)
                                                   }}>
                                                   <img src={ples} />
                                                </div>
                                             }
                                          </div>
                                          {item.documents && item.documents.map((doc: any, docIndex: number) => {
                                             return (
                                                <div key={docIndex} className="uploadFileDiv">
                                                   <Box key={docIndex} style={{ height: "auto", padding: 0 }} className="inputFull doc">
                                                      <Button component="label" style={{ width: '100%', overflow: "hidden" }} >
                                                         <Box style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            textAlign: 'center',
                                                            padding: "10px",
                                                            opacity: "0.8"
                                                         }} >
                                                            {(doc && doc.length > 0) || doc.name ?
                                                               <div className="upload-file-adtn-moderator">
                                                                  {/* @ts-ignore */}
                                                                  <span> {doc.name ? doc.name : doc?.split("/").slice(-1)[0]}</span>
                                                                  {/* <CancelIcon onClick={(e) => {
                                                                     e.preventDefault()
                                                                     e.stopPropagation()
                                                                     let x = {
                                                                        target: {
                                                                           deleteFile: true,
                                                                           value: undefined,
                                                                           name: 'documents'
                                                                        }
                                                                     }
                                                                     handleChange(x, index, docIndex)
                                                                     // setFieldValue(`training.${index}.files`, null)
                                                                  }}
                                                                     style={{ margin: "0px 5px" }} /> */}
                                                               </div>
                                                               :
                                                               <>
                                                                  <Typography style={{ marginTop: "0px" }}>{"+    Upload documents"}</Typography>
                                                                  {/* <Typography style={{ fontSize: "12px", marginTop: "0px" }} ><i>(or connect Linkedin profile below)</i> </Typography> */}
                                                               </>
                                                            }
                                                         </Box>
                                                         <input
                                                            name="documents"
                                                            hidden
                                                            // multiple 
                                                            accept="application/pdf"
                                                            type="file"
                                                            onChange={(e: any) => {
                                                               // setFieldValue(, e.target.files[0])
                                                               if (e.target.files[0]?.type === 'application/pdf') {
                                                                  if (e.target.files[0].size < 10485760) {
                                                                     if (!moderatorData.training[index].documents.find((item: any) => {
                                                                        if (item.name) {
                                                                           if (item.name === e.target.files[0].name) return true
                                                                        } else {
                                                                           if (item?.split("/").slice(-1)[0] === e.target.files[0].name) return true
                                                                        }
                                                                        item === e.target.files[0].name
                                                                     })) {
                                                                        handleChange(e, index, docIndex)
                                                                        handleAlert(" File Uploaded Successfully", true)

                                                                     } else {
                                                                        handleAlert("File already attached", false)
                                                                     }
                                                                  } else {
                                                                     handleAlert("File size must be smaller than 10 megabytes", false)
                                                                  }
                                                               } else {
                                                                  handleAlert(" You can only upload PDF type file", false)
                                                               }
                                                            }
                                                            }
                                                         />
                                                      </Button>
                                                   </Box>
                                                   <div className="cancelDiv">
                                                      <CancelIcon onClick={(e) => {
                                                         e.preventDefault()
                                                         e.stopPropagation()
                                                         let x = {
                                                            target: {
                                                               deleteFile: true,
                                                               value: undefined,
                                                               name: 'documents'
                                                            }
                                                         }
                                                         handleChange(x, index, docIndex)
                                                         // setFieldValue(`training.${index}.files`, null)
                                                      }}
                                                         style={{ margin: "0px 5px" }} />
                                                   </div>
                                                </div>

                                             )
                                          })}
                                       </Box>
                                    </Box>
                                 ))}

                           </Box>
                        </FormControl>
                     </Grid>

                     <Grid className="fieldGrid" style={{ border: '0.5px solid #EBEBEB', width: '100%' }} />

                     {/* Social Media */}
                     <Grid className="fieldGrid" >



                        <FormControl style={{ width: '100%' }}>
                           <Box>
                              <Typography style={{
                                 display: 'block',
                                 fontWeight: 500,
                                 fontSize: '18px',
                                 color: '#08063D',
                                 marginBottom: '20px'
                              }}>
                                 Social Media
                              </Typography>

                              {socialMedia.count < 4 && (
                                 <Button
                                    type="button"
                                    disableRipple
                                    className="addbutton"
                                    onClick={addSocialLogin}
                                 >
                                    <img src={ples} className="add-icon2" />
                                 </Button>
                              )}
                           </Box>

                           {new Array(socialMedia.count).fill(0).map((_, index) => {
                              return (
                                 <React.Fragment key={index}>
                                    {/* Remove Button */}

                                    <Box className="col social" key={index} marginBottom={1}>
                                       <DropDown
                                          name={`socialmedia.${index}.socialMedia`}
                                          list={socialMedia.links &&
                                             socialMedia.links.filter(
                                                (each: any) => !socialMedia.added.find((login) => login?.type === each))
                                                .map((item: any) => item.toString())}
                                          value={socialMedia.added[index]?.type}
                                          onChange={(e) => {
                                             openSocialMedia(e.target.value, index);
                                          }}
                                          className='MuiOutlinedInput-root year-select'
                                          defaultText="Select social media profile"
                                          style={{ background: "white" }}
                                       />

                                       <Box className="colForSocial" mt={4} >
                                          {imgSelector(socialMedia.added[index]?.type)}
                                          <TextField
                                             disabled={!socialMedia.added[index]?.type}
                                             className="inputFull"
                                             name={`socialmedia.${index}.addUrl`}
                                             placeholder="Profile URL"
                                             type="text"
                                             value={socialMedia.added[index]?.value?.userLink || socialMedia.added[index]?.value?.accessToken || socialMedia.added[index]?.value}
                                             onChange={(e) => {
                                                if (!isChanged) {
                                                   setChanged(true)
                                                   changeForm()
                                                }
                                                let d = socialMedia.added
                                                d[index].value = e.target.value
                                                socialMediaCheck(e.target.value, socialMedia.added[index]?.type.toLowerCase())
                                                setSocialMedia({ ...socialMedia, added: d })
                                             }}
                                          // disabled={true}

                                          // value={socialMedia.added[index]?.value?.userLink ||
                                          //    socialMedia.added[index]?.value?.accessToken || ""}
                                          />
                                          <img onClick={() => removeSocialLogin(index, socialMedia.added[index]?.type.toLowerCase())} className="crossIco" src={cross} />
                                       </Box>
                                       <div style={{marginTop: '10px', height: '30px', fontSize: '14px', color: 'red'}}>{errorList.socialMedia[socialMedia.added[index]?.type.toLowerCase() as keyof ErrorList['socialMedia']] ? 'Please enter valid URL' : ''}</div>
                                    </Box>
                                 </React.Fragment>
                              );
                           })}
                        </FormControl>
                     </Grid>
                  </Grid>
               </form>
               :
               <Grid container xs={12} style={{ height: '100%', justifyContent: 'center' }} alignItems="center">
                  <div>
                     <CircularProgress size={80} thickness={4} style={{ color: '#ffcb00' }} />
                  </div>
               </Grid>
            }
         </Grid>
      </Grid >
   )
}

export default ModeratorInformation;
// Customizable Area End
