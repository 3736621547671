import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

class ScrollHandler extends PureComponent<any, any> {
  componentDidMount = () => this.handleScroll();

  componentDidUpdate = (prevProps: any) => {
    const {
      location: { pathname, hash },
    } = this.props;
    if (
      pathname !== prevProps.location.pathname ||
      hash !== prevProps.location.hash
    ) {
      this.handleScroll();
    }
  };

  handleScroll = () => {
    const {
      location: { hash },
    } = this.props;
    const element = document.getElementById(hash.replace('#', ''));

    setTimeout(() => {
      element?.scrollIntoView({
        behavior: element ? 'smooth' : 'auto',
      });
    }, 100);
  };

  render = () => this.props.children;
}

export default withRouter(ScrollHandler);
