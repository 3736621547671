// Customizable Area Start
import React from "react";
import {
   cactus,
   bgg
} from "../../assets";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const CategoryBox = (props: any) => {
   return (
      <>
         {props.category && props.category.attributes?.sub_categories?.data?.map((item: any, index: number) => {
            console.log(item.attributes, 'ATT')
            return (
               <div key={item.id} onClick={() => props.goToSubcategory(props.category, item)} className="categoryOutsideBox">
                  <div className="categoryBox">
                     <div className="categoryImage">{
                        item.attributes.icon && item.attributes.icon.length > 0 ?
                           <LazyLoadImage
                              // effect="blur"
                              placeholderSrc={item.attributes?.thumb}
                              src={item.attributes.icon}
                           />
                           :
                           <img src={cactus} />
                     }
                     </div>
                     <div className="categoryName"><span>{item.attributes.name}</span></div>
                  </div>
               </div>
            )
         })}
      </>
   )
}
export default CategoryBox;
// Customizable Area End