Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";

exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";
exports.subCategoryTopicsAPIEndPoint = "bx_block_categories/topics?sub_category_id=";
exports.subCategoryUpcomingRoomsAPIEndPoint = "/bx_block_different_rooms/rooms/get_upcoming_rooms?topic_id=";
exports.subCategoryNextAvailableRoomsAPIEndPoint = "/bx_block_different_rooms/rooms/get_next_available_room?topic_id=";

exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"


// Customizable Area End