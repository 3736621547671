import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from './assets';
import * as firebase from 'firebase';
// @ts-ignore
import { getFirebaseToken } from "../../../web/src/firebase";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  setStep: (step: number) => void;
  navigation: any;
  activeStep: number;
  id: string;
  // Customizable Area Start
  history: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  otp: string;
  activeStep: number;
  otpVerify: '';
  isMessagePop: boolean;
  selected: any[];
  profileSetupCompleted: boolean;
  onBordingStepper: boolean;
  hideStepper: boolean;
  countryList: any[];
  policyDetails: string;
  subCategories: any[];
  onBoardingQuestions: any[];
  freeYCoins: string;
  otpTimer: number;
  onBoardingData: any;
  onBoardingStep: number;
  additionalYcoins: string;
  policyDetailsSplited: string[];
  hasPreviousAccount: boolean;
  mobileNumber: string;
  // capcha
  recaptchaVerifier: firebase.auth.RecaptchaVerifier | null;
  recaptchaToken: string;
  isChecking: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController<P> extends BlockComponent<
  P & Props,
  S,
  SS
> {
  // Customizable Area Start
  getAdditonalYCoinsId: string;
  onboardingAnswersId: string;
  freeCoinsCreditId: string;
  onBoardingQuestionId: string;
  setupProfileId: string;
  policyDetailsId: string;
  subCategoryListId: string;
  verifyOtpId: string;
  sendOtpId: string;
  countryListId: string;
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = '';

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  msg = '';
  type = '';

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  // Customizable Area End

  constructor(props: P & Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      reTypePassword: '',
      otpAuthToken: '',
      data: [],
      passwordHelperText: '',
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: '',
      phone: '',
      otp: '',
      activeStep: props.activeStep,
      otpVerify: '',
      isMessagePop: false,
      selected: [],
      profileSetupCompleted: false,
      onBordingStepper: false,
      hideStepper: false,
      countryList: [],
      policyDetails: '',
      subCategories: [],
      onBoardingQuestions: [],
      freeYCoins: '',
      otpTimer: 120,
      onBoardingData: {},
      onBoardingStep: 1,
      additionalYcoins: '',
      policyDetailsSplited: [],
      hasPreviousAccount: false,
      recaptchaVerifier: null,
      recaptchaToken: '',
      mobileNumber: '',
      isChecking: false,
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp('\\w+');
    this.emailReg = new RegExp('\\w+');

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData.password_validation_regexp) {
              this.passwordReg = new RegExp(
                regexData.password_validation_regexp
              );
            }

            if (regexData.password_validation_rules) {
              this.setState({
                passwordHelperText: regexData.password_validation_rules,
              });
            }

            if (regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        }
        if (this.countryListId === apiRequestCallId) {
          if (responseJson && responseJson.data)
            this.setState({ countryList: responseJson?.data });
        }
        if (this.sendOtpId === apiRequestCallId) {
          if (responseJson && responseJson.data && responseJson?.meta?.token) {
            sessionStorage.removeItem('number');
            sessionStorage.setItem('token', responseJson?.meta?.token);
            // this.props.history.push("otpVerification")
            this.props.setStep(1);
            this.setState({ otpTimer: 120 }, () => {
              this.timer();
            });
          } else if (responseJson.errors && responseJson.errors[0]) {
            const er = Object.keys(responseJson.errors[0]);
            if (er && er[0]) {
              return this.handleAlert(responseJson.errors[0][er[0]]);
            }
          } else {
            this.handleAlert('Something went wrong!');
          }
        }
        if (this.verifyOtpId === apiRequestCallId) {
          if (responseJson.data) {
            localStorage.setItem('userId', responseJson?.data?.id);
            localStorage.setItem("referral_code", responseJson?.data?.attributes?.referral_code);
            localStorage.setItem("country_code", responseJson?.data?.attributes?.country_code);
            localStorage.setItem("share_link", responseJson?.data?.attributes?.share_link);

            localStorage.setItem(
              'fullName',
              responseJson?.data?.attributes?.full_name
            );
            localStorage.setItem(
              'userName',
              responseJson?.data?.attributes?.user_name
            );
            localStorage.setItem(
              'is_dashboard_training_done',
              responseJson?.data?.attributes?.is_dashboard_training_done ||
              false
            );
            localStorage.setItem(
              'is_profile_training_done',
              responseJson?.data?.attributes?.is_profile_training_done || false
            );
            this.handleAlertSuccess('OTP Verification is Successful');
            if (responseJson.data.type != 'validate_available') {
              sessionStorage.removeItem('token');
              localStorage.setItem('token', responseJson?.meta?.token);
              localStorage.setItem(
                'profile_image',
                responseJson?.data?.attributes?.profile_image
              );
              localStorage.setItem(
                'isModerator',
                responseJson?.data?.attributes?.is_moderator || false
              );
              this.props.history.push('Rooms');
            } else {
              sessionStorage.setItem('token', responseJson?.meta?.token);
              this.setState({ activeStep: this.state.activeStep + 1 });
            }
          } else {
            this.handleAlert('Invalid OTP. Please try again.');
          }
          this.setState({ isChecking: false });
        }
        if (this.setupProfileId === apiRequestCallId) {
          if (responseJson && responseJson.data) {
            console.log('setupProfileId', responseJson.data);

            sessionStorage.setItem('token', responseJson?.meta?.token);
            localStorage.setItem('userId', responseJson?.data?.id);
            localStorage.setItem("referral_code", responseJson?.data?.attributes?.referral_code);
            localStorage.setItem("country_code", responseJson?.data?.attributes?.country_code);
            localStorage.setItem("share_link", responseJson?.data?.attributes?.share_link);

            localStorage.setItem(
              'profile_image',
              responseJson?.data?.attributes?.profile_image
            );
            localStorage.setItem(
              'fullName',
              responseJson?.data?.attributes?.full_name
            );
            localStorage.setItem(
              'userName',
              responseJson?.data?.attributes?.user_name
            );
            this.setState({
              activeStep: this.state.activeStep + 1,
              profileSetupCompleted: true,
              hideStepper: false,
            });
            if (responseJson.data.attributes?.wallet === 0)
              this.setState({
                hasPreviousAccount: true,
              });
            else this.getFreeCoinsCredit();
          } else if (responseJson && responseJson?.errors?.message) {
            this.handleAlert(responseJson.errors.message);
          }
        }
        if (this.policyDetailsId === apiRequestCallId) {
          if (responseJson && responseJson.data) {
            const splited = responseJson.data.constant_value.split('\r\n\r\n');
            this.setState({
              policyDetails: responseJson.data.constant_value,
              policyDetailsSplited: splited,
            });
          }
        }
        if (this.subCategoryListId === apiRequestCallId) {
          if (responseJson && responseJson.data) {
            this.setState({ subCategories: responseJson.data });
          }
        }
        if (this.onBoardingQuestionId === apiRequestCallId) {
          if (responseJson && responseJson.data) {
            this.setState({ onBoardingQuestions: responseJson.data });
          }
        }
        if (this.freeCoinsCreditId === apiRequestCallId) {
          if (responseJson && responseJson.data) {
            this.setState({ freeYCoins: responseJson.data.constant_value });
          }
        }
        if (this.onboardingAnswersId === apiRequestCallId) {
          if (responseJson && responseJson.data) {
            if (this.state.onBoardingStep === 5) {
              this.getAdditionalYCoinsCredit();
              return this.setState({
                onBoardingStep: this.state.onBoardingStep + 1,
                hideStepper: !this.state.hideStepper,
              });
            }
            this.setState({ onBoardingStep: this.state.onBoardingStep + 1 });
          } else if (responseJson && responseJson.errors) {
            if (responseJson.errors.message)
              this.handleAlert(responseJson.errors.message);
            else this.handleAlert('Some error occured please try again later');
          }
        }
        if (this.getAdditonalYCoinsId === apiRequestCallId) {
          if (responseJson && responseJson.data) {
            this.setState({ additionalYcoins: responseJson.data }, () => {
              console.log(this.state?.additionalYcoins);
            });
          }
        }

        if (responseJson.errors) {
          if (responseJson.errors[0]?.token) {
            this.props.history.push('registration');
          }
        } else if (apiRequestCallId === this.createAccountApiCallId) {
          if (!responseJson.errors) {
            const msg: Message = new Message(
              getName(MessageEnum.AccoutResgistrationSuccess)
            );

            msg.addData(
              getName(MessageEnum.NavigationPropsMessage),
              this.props
            );

            this.send(msg);
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog('otpAuthTkn', this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf('+') > 0
              ? selectedCode.split('+')[1]
              : selectedCode,
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      'Content-Type': configJSON.contentTypeApiAddDetail,
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number:
        '+' + this.state.countryCodeSelected + this.state.phone,
    };

    const data = {
      type: 'email_account',
      attributes: attrs,
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      'Content-Type': configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getCountryCode() {
    const headers = {
      'Content-Type': configJSON.validationApiContentType,
      'Access-Control-Allow-Origin': '*',
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryListId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCountryListApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  timer = () => {
    const abc = setInterval(() => {
      // @ts-ignore
      this.setState({
        otpTimer: (function (time: number) {
          if (time === 0) {
            clearInterval(abc);
            return 0;
          } else return time - 1;
        })(this.state.otpTimer),
      });
    }, 1000);
  };

  handleGetFirebaseToken = (value: { mobile: string; otp: string, token: string }) => {
    if (firebase.messaging.isSupported()) {
      // alredy allow
      if (Notification.permission === "granted") {
        getFirebaseToken()
          .then((firebaseToken) => {
            // send firebase token with device ID to BE
            this.exchangeToken(value);
          })
          .catch((err: Error) => alert("Error getting Firebase token."));
      } else {
        // send firebase token with device ID to BE
        this.exchangeToken(value);
      }
    } else {
      this.exchangeToken(value);
    }
  };

  exchangeToken(value: { mobile: string; otp: string, token: string }) {
    const device_id = localStorage.getItem("firebaseToken");

    let payload = {
      full_phone_number: value.mobile,
      device_id,
    };

    const headers = {
      'Content-Type': configJSON.validationApiContentType,
      token: value.token,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.verifyOtpId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyOtpApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePost
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  onboardingAnswers(attributes: any) {
    let payload = {
      data: {
        type: 'onboarding_data',
        attributes,
      },
    };

    const headers = {
      'Content-Type': configJSON.validationApiContentType,
      token: localStorage.getItem('token'),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.onboardingAnswersId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.onBoardingAnswerAPIEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePatch
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  handleAlert(msg = '') {
    this.msg = msg;
    this.type = 'error';
    this.setState({ isMessagePop: true });
  }

  handleAlertSuccess(msg = '') {
    this.msg = msg;
    this.type = 'success';
    this.setState({ isMessagePop: true });
  }

  getPolicyDetails() {
    const headers = {
      'Content-Type': configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.policyDetailsId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPolicyDetails
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  subCategoryList() {
    const headers = {
      'Content-Type': configJSON.validationApiContentType,
      token: sessionStorage.getItem('token'),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.subCategoryListId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllSubcategoriesApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  setupProfileData(value: any) {
    const headers = {
      'Content-Type': undefined,
      token: sessionStorage.getItem('token'),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setupProfileId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setupProfileApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePost
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      value
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  onBoardingQuestion() {
    const headers = {
      'Content-Type': configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.onBoardingQuestionId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.onBoardingQuestionApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getFreeCoinsCredit() {
    const headers = {
      'Content-Type': configJSON.validationApiContentType,
      token: localStorage.getItem('token'),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.freeCoinsCreditId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFreeCoinsCreditedAPIEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getAdditionalYCoinsCredit() {
    const headers = {
      'Content-Type': configJSON.validationApiContentType,
      token: localStorage.getItem('token'),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAdditonalYCoinsId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAddtionalYCoinsApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== 'null' &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = 'Phone ' + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField,
      });
      this.txtInputConfirmPasswordProps.secureTextEntry =
        !this.state.enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnSignUpProps = {
    onPress: () => this.createAccount(),
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy(),
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition(),
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    },
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: 'email-address',
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: 'tel',
    keyboardType: 'phone-pad',
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    },
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    },
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  // capcha & send otp

  sendOTP = (appVerifier: firebase.auth.ApplicationVerifier) => {
    if (!appVerifier) {
      return;
    }

    const phone_number = this.state.mobileNumber || localStorage.getItem('p_no');
    if (!phone_number) return;

    firebase.auth().signInWithPhoneNumber(phone_number, appVerifier)
      .then((confirmationResult) => {
        localStorage.setItem('p_no', phone_number);
        this.props.setStep(1);
        this.setState({ otpTimer: 120 }, () => {
          this.timer();
        });

        (window as any).confirmationResult = confirmationResult;
      }).catch((error: Error) => {
        this.handleAlert(error.message);
        // reset recaptcha
        this.setState({ recaptchaToken: '' });
      });
  };

  verifyCapcha = () => {
    const { recaptchaVerifier, recaptchaToken } = this.state;
    if (recaptchaToken || !recaptchaVerifier) return;

    recaptchaVerifier.verify().then((token: string) => {
      this.setState({ recaptchaToken: token });
      this.sendOTP(recaptchaVerifier);
    }).catch((error: Error) => {
      this.handleAlert(error.message);
    });
  };

  resendOTP = () => {
    this.setState({ recaptchaToken: '' }, () => {
      this.setState({ otpTimer: 120 }, () => {
        this.timer();
        this.verifyCapcha();
      });
    });
  }
  // Customizable Area End
}
