// Customizable Area Start
import { IBlock } from '../../../../framework/src/IBlock';
import { Message } from '../../../../framework/src/Message';
import { BlockComponent } from '../../../../framework/src/BlockComponent';
import MessageEnum, {
    getName,
} from '../../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../../framework/src/RunEngine';

import React, { ReactNode } from "react";

// material-ui components
import { Box, Typography } from "@material-ui/core";
import {
    createStyles, withStyles, WithStyles, createTheme, ThemeProvider
} from "@material-ui/core/styles";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { withRouter, RouteComponentProps } from 'react-router-dom';

// config & assets
const configJSON = require("../config.js");
import { yellowHeart } from "../assets";
import RoomFeedback from '../../../Feedback/src/RoomFeedback.web';

// create styles and theme
export const theme = createTheme({
    palette: {
        primary: {
            main: '#FFCB00',
        },
        secondary: {
            main: '#010039',
        },
    },
});

const styles = () =>
    createStyles({
        btnText: {
            fontWeight: 600,
            cursor: "pointer",
            color: theme.palette.secondary.main,
        }
    });

// alert component
function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// utils function
function isThreeWeeksPast(dateTimeStr: string): boolean {
    const now = new Date();
    const dateTime = new Date(dateTimeStr);
    const timeDiffInMs = now.getTime() - dateTime.getTime();
    const timeDiffInWeeks = timeDiffInMs / (1000 * 60 * 60 * 24 * 7);
    return timeDiffInWeeks >= 3;
}

// interface
interface Props extends WithStyles<typeof styles>, RouteComponentProps {
    feedback: any;
    room_id: string;
    reloadRoom: () => void;
    start_time: string;
    isModerated: boolean;
    isHost: boolean;
    isMobile: boolean;
    allActiveParticipants: any[];
}

interface State {
    isOpen: boolean;
    participants: any[];
}

interface SS {
    id: any;
}

// component
class FeedbackStatus extends BlockComponent<Props, State, SS> {
    roomDetailMessageId: string;
    sendConnectRequestId: string;

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            isOpen: false,
            participants: [],
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.roomDetailMessageId) {
                const participants = responseJson?.participants?.data
                    .map((each: any) => ({ ...each?.attributes, ...each?.attributes?.participant?.data?.attributes }));

                const newParticipants = participants.map((each: any) => {
                    const { hearts, ...rest } = each;
                    return {
                        ...rest,
                        userId: each.account_id,
                        profileImage: each.profile_img
                    };
                });

                this.setState({ participants: newParticipants });
            }
        }
    }

    // call the API for participant details
    getRoomDetails = () => {
        const token = localStorage.getItem('token') as string;

        if (!token) return;

        const headers = {
            'Content-Type': configJSON.validationApiContentType,
            token,
        }

        const requesMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requesMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.roomDetailsEndpoint}/${this.props.room_id}/participants`
        );

        requesMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeGet
        );

        requesMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            headers
        );

        this.roomDetailMessageId = requesMessage.messageId;

        runEngine.sendMessage(requesMessage.id, requesMessage);
    };
    // function to toggle modal
    toggleModal = () => {
        sessionStorage.removeItem("self_leave");

        if (!this.state.isOpen) {
            sessionStorage.setItem("self_leave", JSON.stringify(true));
            this.getRoomDetails();
        }

        this.setState({ isOpen: !this.state.isOpen });
    };

    getCurrentUserDetails = () => {
        const user_id = Number(localStorage.getItem("userId") as string);
        const userDetails = this.props.allActiveParticipants.filter((each) => each.id === user_id);

        return userDetails.length > 0 ? userDetails[0] : {};
    }

    getJoinedUsers = () => {
        const users = this.props.allActiveParticipants.map((each) => each.id);
        return users;
    }

    getNextDay = () => {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 1);
        const nextDate = currentDate.toLocaleString();
        return nextDate;
    }

    sendConnectRequest = (user_id: any) => {
        let payload = {
            data: {
                "account_id": user_id
            }
        }

        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": localStorage.getItem("token")
        };

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.sendConnectRequestId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.sendConnectRequestApiEndPoint
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(payload)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostType
        );

        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }

    // render the component
    render(): ReactNode {
        const { feedback, classes, start_time } = this.props;
        const { participants } = this.state;
        const isString = typeof feedback === "string";
        const isSeeYourFeedback = isString && feedback.includes("See");
        const totalHearts = !isString && feedback.meta.total_hearts || 0;
        const feedbackData: false | any[] = !isString && feedback.data
            .filter((each: any) => each?.attributes.feedback !== undefined && each?.attributes.feedback !== null && each?.attributes.feedback !== '');

        if (isThreeWeeksPast(start_time)) return null;

        return (
            <ThemeProvider theme={theme}>

                {(this.state.isOpen && participants.length > 0) && (
                    <RoomFeedback
                        id={'321'}
                        navigation={null}
                        leave={this.state.isOpen}
                        handleLeave={this.toggleModal}
                        roomId={this.props.room_id}
                        feedbackData={this.state.participants}
                        endTime={this.getNextDay()}
                        allParticipants={this.props.allActiveParticipants}
                        history={this.props.history}
                        isModeratorRoom={this.props.isModerated}
                        isHost={this.props.isHost}
                        joinedUsers={this.getJoinedUsers()}
                        isMobile={this.props.isMobile}
                        currentUser={this.getCurrentUserDetails()}
                        reloadRoom={this.props.reloadRoom}
                        sendConnectionRequest={this.sendConnectRequest}
                        handleDisconnectUser={() => {
                            alert("feature only available on Feedback screen");
                        }}
                        revertConnectionRequest={() => {
                            alert("feature only available on Feedback screen");
                        }}
                    />
                )}

                <Box p={1.8} className='bTop'>
                    {isString &&
                        <Typography
                            className={isSeeYourFeedback ? classes.btnText : ''}
                            onClick={isSeeYourFeedback ? this.toggleModal : () => { }}
                        >
                            {feedback}
                        </Typography>
                    }

                    {!isString && (
                        <>
                            <div className="mb10 d-flex" style={{ alignItems: 'center' }}>
                                <p className="fs15 colorBlue fw500 mr10 mb0">
                                    Your Feedback
                                </p>
                                <img src={yellowHeart} alt="yellow_heart" className="w20" />
                                <span className="fs14 fw500 colorGrey ml5">
                                    {totalHearts}
                                </span>
                            </div>
                            {feedbackData && (
                                <div className="feedback">
                                    {feedbackData.length > 0 && (
                                        feedbackData.map((each: any) => {
                                            return (
                                                <Typography className="fs14 fw500 colorGrey" key={each.id}>
                                                    {each?.attributes.feedback}
                                                </Typography>
                                            )
                                        })
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </Box>
            </ThemeProvider>
        );
    }
}

export default withStyles(styles)(withRouter(FeedbackStatus));
// Customizable Area End