import React, { useState } from 'react';
import { Box, Typography, Grid, Button, Container } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { isAuthenticated } from '../src/utils';
import RegistrationDialog from '../../blocks/Referrals/src/RegistrationDialog';

const logo = require('./logo.png');

const theme = createTheme({
  palette: {
    primary: {
      main: '#FFCB00',
    },
    secondary: {
      main: '#010039',
    },
  },
});

type Props = {
  navigation: any;
  id: string;
};

const HeaderNoLogin = (props: Props) => {
  const { navigation, id } = props;

  const [isOnboardingDialogOpen, setIsOnboardingDialogOpen] = useState(false);

  return (
    <>
      <Box
        style={{
          position: 'sticky',
          top: '0',
          zIndex: 2,
          boxShadow: '0px 0px 18px 5px rgb(0 0 0 / 5%)',
          backgroundColor: 'white',
        }}
      >
        <Container>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ paddingBlock: '8px' }}
          >
            <Grid item>
              <Box
                style={{ cursor: 'pointer' }}
                onClick={() => navigation.navigate('HomePage')}
              >
                <img src={logo} alt="logo" height="56" />
              </Box>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  isAuthenticated()
                    ? navigation.navigate('UpcomingRooms')
                    : setIsOnboardingDialogOpen(true)
                }
              >
                <Typography
                  style={{
                    fontSize: '16px',
                    fontWeight: 500,
                    padding: '4px',
                  }}
                >
                  Enter YLanes
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <RegistrationDialog
        navigation={navigation}
        id={id}
        isOnboardingDialogOpen={isOnboardingDialogOpen}
        handleOnboardingDialogClose={() => setIsOnboardingDialogOpen(false)}
      />
    </>
  );
};

export default HeaderNoLogin;
