// Customizable Area Start

import React from 'react';
import { Button, TextField, Container, Grid } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import './verification.css';
import { Formik } from 'formik';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { celebrationImage, cancel_logo, thankYou } from '../assets';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';

class Onboarding extends React.Component<any, any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      disable: true,
      onBoarding: 2,
      selected: [],
      profileSetupCompleted: false,
      isMessagePop: false,
      inputButton: false,
      onBoardingValues: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    //@ts-ignore
    // this.handleAlert = this.handleAlert.bind(this)
    this.handleClose = this.handleClose.bind(this);
  }

  async componentDidMount() {
    this.props.onBoardingQuestion();
    this.props.topSectonRef?.current?.scrollIntoView();
  }

  componentDidUpdate(prevProps: { step: number }, prevState: any) {
    if (prevProps.step !== this.props.step) {
      this.props.topSectonRef?.current?.scrollIntoView();
    }
  }

  onBoardSchema1 = Yup.object().shape({
    word1: Yup.string()
      .required('Please enter your answer')
      .max(30, ' Maximum 30 word are allowed!'),

    word2: Yup.string()
      .required(' Please enter your answer')
      .max(30, ' Maximum 30 word are allowed!'),
    word3: Yup.string()
      .required('Please enter your answer')
      .max(30, ' Maximum 30 word are allowed!'),
  });

  // text = "Add activity";
  text = '';

  holidayOptions = [
    'Mountains',
    'Relax at home',
    'Read books',
    'Beaches',
    'Party with friends',
    'Adventure',
    'Binge television',
  ];

  handleClose() {
    this.setState({ isMessagePop: false });
  }

  handleChange(value1: any, event: any) {
    this.setState({ [value1]: event.target.value });
  }

  handleSubmit(event: any) {
    const data = new FormData(event.target);
    event.preventDefault();
  }

  inputfocus = (elmnt: any) => {
    if (elmnt.key === 'Delete' || elmnt.key === 'Backspace') {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      console.log('next');

      const next = elmnt.target.tabIndex;
      if (next < 4) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  deleteSelected = (value: any) => {
    const data = this.state.selected.filter((item: any) => item !== value);

    this.setState({ selected: data });
  };

  render() {
    const { onBoardingQuestions } = this.props;
    // const handleBack = () => {
    //   this.setState({ activeStep: this.state.activeStep - 1 });
    // };

    // const handleReset = () => {
    //     this.setState({ activeStep: 0 });
    // };

    return (
      <Container
        maxWidth={this.props.step === 2 || this.props.step === 6 ? 'md' : 'sm'}
        style={{ textAlign: 'center' }}
      >
        {/* ON-BOARDING PAGE == Step 1*/}
        {this.props.step === 1 && (
          <Formik
            initialValues={{
              word1: '',
              word2: '',
              word3: '',
            }}
            validationSchema={this.onBoardSchema1}
            onSubmit={(e) => {
              // e.preventDefault()
              const payload = {
                question_id: onBoardingQuestions[0]?.id,
                data: [e.word3, e.word2, e.word1],
                level: onBoardingQuestions[0]?.level,
              };
              this.props.onBoardingAnswers(payload);
              // this.setState({ onBoarding : 2})
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="onboard-heading">
                    <p style={{ margin: '0' }}>Onboarding</p>
                    <span>This will help others understand you better.</span>
                    <h3 style={{ margin: '16px 0' }}>
                      {onBoardingQuestions[0]?.name}
                    </h3>
                  </div>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        size="small"
                        placeholder="Word 1"
                        variant="outlined"
                        fullWidth
                        name="word1"
                        value={values.word1}
                        onChange={(e) => {
                          e.target.value = e.target.value?.trimStart();
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        inputProps={{ maxLength: 30 }}
                      />
                      {errors.word1 && (
                        <span
                          style={{
                            display: 'block',
                            color: 'red',
                            fontSize: '12px',
                            textAlign: 'start',
                          }}
                        >
                          {errors.word1 && touched.word1 && errors.word1}
                        </span>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        size="small"
                        placeholder="Word 2"
                        variant="outlined"
                        fullWidth
                        name="word2"
                        value={values.word2}
                        inputProps={{ maxLength: 30 }}
                        onChange={(e) => {
                          e.target.value = e.target.value?.trimStart();
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      />
                      {errors.word2 && (
                        <span
                          style={{
                            display: 'block',
                            color: 'red',
                            fontSize: '12px',
                            textAlign: 'start',
                          }}
                        >
                          {errors.word2 && touched.word2 && errors.word2}
                        </span>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        size="small"
                        placeholder="Word 3"
                        variant="outlined"
                        fullWidth
                        name="word3"
                        value={values.word3}
                        inputProps={{ maxLength: 30 }}
                        onChange={(e) => {
                          e.target.value = e.target.value?.trimStart();
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      />
                      {errors.word3 && (
                        <span
                          style={{
                            display: 'block',
                            color: 'red',
                            fontSize: '12px',
                            textAlign: 'start',
                          }}
                        >
                          {errors.word3 && touched.word3 && errors.word3}
                        </span>
                      )}
                    </Grid>
                  </Grid>
                  <div
                    className="submit-button-div"
                    style={{ marginTop: '24px' }}
                  >
                    <Button
                      className="submit-btn-onboard"
                      type="submit"
                      style={{ width: '100%' }}
                      variant="contained"
                      // onClick={(e)=>{this.setState({onBoarding : 2})}}
                    >
                      Next
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        )}

        {/* ON-BOARDING PAGE == Step 2*/}
        {this.props.step === 2 && (
          <Formik
            initialValues={{
              customActivity: '',
              checked: '',
            }}
            validate={(values) => {
              if (!values.checked) {
                return { checked: '* Please select one activity' };
              }
            }}
            onSubmit={(e) => {
              const payload = {
                question_id: onBoardingQuestions[1]?.id,
                data: [e.checked],
                level: onBoardingQuestions[1]?.level,
              };
              this.props.onBoardingAnswers(payload);
              // this.setState({onBoarding:3})
            }}
          >
            {({
              dirty,
              isValid,
              values,
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              errors,
              touched,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="onboard-heading">
                    <p style={{ margin: '0' }}>Onboarding</p>
                    <span>This will help others understand you better.</span>
                    <div className="onHead">
                      <h3 style={{ fontSize: '25px', margin: '16px 0' }}>
                        {onBoardingQuestions[1]?.name}
                      </h3>
                    </div>
                  </div>

                  {/* <div className="buttons-div"> 
                                                {this.holidayOptions.map((item: any) => {
                                                    console.log(values.checked , item)
                                                    return ( 
                                                        <Button 
                                                            className={`btn-multiple ${item == values.checked?"active":null}`} 
                                                            variant='contained'
                                                            onClick ={(e)=>
                                                                {
                                                                    setFieldValue("checked", item )
                                                                }
                                                            }

                                                        >{item} 
                                                        </Button>
                                                    );
                                                    
                                                })}
                                            </div> */}
                  {/* First three holiday suggestion button */}
                  <div className="buttons-div">
                    <Button
                      className={`btn-multiple ${
                        'Mountains' == values.checked ? 'active' : null
                      }`}
                      variant="contained"
                      onClick={(e) => {
                        setFieldValue('checked', 'Mountains');
                      }}
                    >
                      Mountains
                    </Button>

                    <Button
                      className={`btn-multiple ${
                        'Relax at home' == values.checked ? 'active' : null
                      }`}
                      variant="contained"
                      onClick={(e) => {
                        setFieldValue('checked', 'Relax at home');
                      }}
                    >
                      Relax at home
                    </Button>

                    <Button
                      className={`btn-multiple ${
                        'Read Books' == values.checked ? 'active' : null
                      }`}
                      variant="contained"
                      onClick={(e) => {
                        setFieldValue('checked', 'Read Books');
                      }}
                    >
                      Read Books
                    </Button>
                  </div>

                  {/* Second line two holiday suggestion button */}
                  <div className="buttons-div">
                    <Button
                      className={`btn-multiple ${
                        'Beaches' == values.checked ? 'active' : null
                      }`}
                      variant="contained"
                      onClick={(e) => {
                        setFieldValue('checked', 'Beaches');
                      }}
                    >
                      Beaches
                    </Button>

                    <Button
                      className={`btn-multiple ${
                        'Party with friends' == values.checked ? 'active' : null
                      }`}
                      variant="contained"
                      onClick={(e) => {
                        setFieldValue('checked', 'Party with friends');
                      }}
                    >
                      Party with friends
                    </Button>
                  </div>

                  {/* last three holiday suggestion button */}
                  <div className="buttons-div">
                    <Button
                      className={`btn-multiple ${
                        'Adventure' == values.checked ? 'active' : null
                      }`}
                      variant="contained"
                      onClick={(e) => {
                        setFieldValue('checked', 'Adventure');
                      }}
                    >
                      Adventure
                    </Button>

                    <Button
                      className={`btn-multiple ${
                        'Bringe Television' == values.checked ? 'active' : null
                      }`}
                      variant="contained"
                      onClick={(e) => {
                        setFieldValue('checked', 'Bringe Television');
                      }}
                    >
                      Binge Television
                    </Button>

                    <div className="earnPoint-para buttons-div">
                      {!this.state.inputButton ? (
                        <Button
                          className={`btn-multiple ${
                            this.text == values.checked && values.checked
                              ? 'active'
                              : null
                          }`}
                          variant="outlined"
                          id="customInputBtn"
                          onDoubleClickCapture={() => {
                            document.getElementById('customInputBtn')?.focus();
                            this.setState({ inputButton: true }, () => {
                              document
                                .getElementById('customInputBtn')
                                ?.focus();
                            });
                          }}
                          style={{ textTransform: 'none' }}
                          onClick={(e) => {
                            // this.text == "Add activity" ?
                            this.setState({ inputButton: true });
                            // :
                            // setFieldValue("checked", this.text )
                          }}
                        >
                          {this.text == values.checked && values.checked
                            ? this.text
                            : 'Add activity'}
                        </Button>
                      ) : (
                        <TextField
                          className="addtext-input btn-multiple"
                          variant="outlined"
                          style={{ border: 'none' }}
                          placeholder={
                            this.text == values.checked && values.checked
                              ? this.text
                              : 'Add activity'
                          }
                          // value={ this.text }
                          inputProps={{ maxLength: 30 }}
                          onBlur={() => {
                            if (
                              this.text.trim() &&
                              this.text.trim() != 'Add activity'
                            ) {
                              setFieldValue('checked', this.text.trim());
                            } else {
                              // this.text = "Add activity"
                              setFieldValue('checked', '');
                            }
                            this.setState({ inputButton: false });
                          }}
                          onChange={(e) => {
                            if (e.target.value.length <= 30) {
                              this.text = e.target.value.trimStart();
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key == 'Enter') {
                              if (
                                this.text.trim() &&
                                this.text.trim() != 'Add activity'
                              ) {
                                setFieldValue('checked', this.text.trim());
                              } else {
                                // this.text= "Add activity"
                              }
                              this.setState({ inputButton: false });
                            }
                          }}
                        />
                      )}
                    </div>
                  </div>

                  {errors.checked && (
                    <span
                      style={{
                        display: 'block',
                        color: 'red',
                        fontSize: '12px',
                      }}
                    >
                      {errors.checked && touched.checked && errors.checked}
                    </span>
                  )}

                  <div
                    className="submit-button-div"
                    style={{ marginTop: '24px' }}
                  >
                    <Button
                      className="submit-btn-onboard"
                      style={{ width: '50%' }}
                      variant="contained"
                      type="submit"
                    >
                      Next
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        )}

        {/* ON-BOARDING PAGE == Step 3*/}
        {this.props.step === 3 && (
          <Formik
            initialValues={{
              customActivity: '',
              textArea: '',
            }}
            validate={(values) => {
              if (!values.textArea) {
                return { textArea: 'Please enter your answer' };
              }
            }}
            onSubmit={(e) => {
              const payload = {
                question_id: onBoardingQuestions[2]?.id,
                data: [e.textArea],
                level: onBoardingQuestions[2]?.level,
              };
              this.props.onBoardingAnswers(payload);
              //    this.setState({onBoarding: 4})
            }}
          >
            {({
              dirty,
              isValid,
              values,
              errors,
              touched,
              handleSubmit,
              handleChange,
              handleBlur,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="onboard-heading">
                    <p style={{ margin: '0' }}>Onboarding</p>
                    <span>This will help others understand you better.</span>
                    <h3 style={{ margin: '16px 0' }}>
                      {onBoardingQuestions[2]?.name}
                    </h3>
                  </div>

                  <div className="earnPoint-para">
                    <TextField
                      className="text-area"
                      placeholder="Your answer"
                      multiline
                      name="textArea"
                      variant="outlined"
                      value={values.textArea}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        e.target.value = e.target.value?.trimStart();
                        handleChange(e);
                      }}
                      inputProps={{
                        maxLength: 100,
                      }}
                      helperText={`${100 - values.textArea.length} Characters`}
                      margin="normal"
                    />
                  </div>
                  {errors.textArea && (
                    <span
                      style={{
                        color: 'red',
                        fontSize: '12px',
                        marginTop: '15px',
                      }}
                    >
                      {errors.textArea && touched.textArea && errors.textArea}
                    </span>
                  )}

                  <div
                    className="submit-button-div"
                    style={{ marginTop: '24px' }}
                  >
                    <Button
                      className="submit-btn-onboard"
                      variant="contained"
                      type="submit"
                    >
                      Next
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        )}

        {/* ON-BOARDING PAGE == Step 4*/}
        {this.props.step === 4 && (
          <Formik
            initialValues={{
              customActivity: '',
              textArea: '',
            }}
            validate={(values) => {
              if (!values.textArea) {
                return { textArea: 'Please enter your answer.' };
              }
            }}
            onSubmit={(e) => {
              const payload = {
                question_id: onBoardingQuestions[3]?.id,
                data: [e.textArea],
                level: onBoardingQuestions[3]?.level,
              };
              this.props.onBoardingAnswers(payload);
              // this.setState({onBoarding: 5})
            }}
          >
            {({
              dirty,
              isValid,
              errors,
              touched,
              values,
              handleSubmit,
              handleChange,
              handleBlur,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="onboard-heading">
                    <p style={{ margin: '0' }}>Onboarding</p>
                    <span>This will help others understand you better.</span>
                    <h3 style={{ margin: '16px 0' }}>
                      {onBoardingQuestions[3]?.name}
                    </h3>
                  </div>

                  <div className="earnPoint-para">
                    <TextField
                      className="text-area"
                      minRows="4"
                      placeholder="Your answer"
                      multiline
                      name="textArea"
                      variant="outlined"
                      value={values.textArea}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        e.target.value = e.target.value?.trimStart();
                        handleChange(e);
                      }}
                      inputProps={{
                        maxLength: 100,
                      }}
                      helperText={`${100 - values.textArea.length} Characters`}
                      margin="normal"
                    />
                  </div>
                  {errors.textArea && (
                    <span
                      style={{
                        color: 'red',
                        fontSize: '12px',
                        marginTop: '15px',
                      }}
                    >
                      {errors.textArea && touched.textArea && errors.textArea}
                    </span>
                  )}

                  <div
                    className="submit-button-div"
                    style={{ marginTop: '24px' }}
                  >
                    <Button
                      className="submit-btn-onboard"
                      variant="contained"
                      type="submit"
                    >
                      Next
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        )}

        {/* ON-BOARDING PAGE == Step 5*/}
        {this.props.step === 5 && (
          <Formik
            initialValues={{
              customActivity: '',
              textArea: '',
            }}
            validate={(values) => {
              if (!values.textArea) {
                return { textArea: '* Please enter your answer.' };
              }
            }}
            onSubmit={(e) => {
              const payload = {
                question_id: onBoardingQuestions[4]?.id,
                data: [e.textArea],
                level: onBoardingQuestions[4]?.level,
              };
              this.props.onBoardingAnswers(payload);
              //   this.setState({onBoarding: 6});
              //   this.props.hideStepper()
            }}
          >
            {({
              dirty,
              isValid,
              errors,
              touched,
              values,
              handleSubmit,
              handleChange,
              handleBlur,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="onboard-heading">
                    <p style={{ margin: '0' }}>Onboarding</p>
                    <span>This will help others understand you better.</span>
                    <h3 style={{ margin: '16px 0' }}>
                      {onBoardingQuestions[4]?.name}
                    </h3>
                  </div>

                  <div className="earnPoint-para">
                    <TextField
                      className="text-area"
                      minRows="4"
                      placeholder="Your answer"
                      multiline
                      name="textArea"
                      variant="outlined"
                      value={values.textArea}
                      onChange={(e) => {
                        e.target.value = e.target.value?.trimStart();
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      inputProps={{
                        maxLength: 2000,
                      }}
                      helperText={`${2000 - values.textArea.length} Characters`}
                      margin="normal"
                    />
                  </div>
                  {errors.textArea && (
                    <span
                      style={{
                        color: 'red',
                        fontSize: '12px',
                        marginTop: '15px',
                      }}
                    >
                      {errors.textArea && touched.textArea && errors.textArea}
                    </span>
                  )}

                  <div
                    className="submit-button-div"
                    style={{ marginTop: '24px' }}
                  >
                    <Button
                      className="submit-btn-onboard"
                      variant="contained"
                      type="submit"
                    >
                      Done
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        )}

        {/* ON-BOARDING PAGE == Step 6*/}
        {this.props.step === 6 && (
          <>
            <div>
              <div className="onboard-heading">
                <div>
                  <img
                    style={{
                      height: '270px',
                      width: '270px',
                      marginBottom: '10px',
                      borderRadius: '8px',
                      objectFit: 'cover',
                    }}
                    src={thankYou}
                  />
                </div>
                <p style={{ margin: '0' }}>Onboarding Complete</p>
                {!this.props.hasPreviousAccount && (
                  <h3 style={{ color: 'gray', margin: '0' }}>
                    We have credited an additional{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {' '}
                      {this.props?.additionalYcoins?.constant_value} free YCoins{' '}
                    </span>
                    to your account for completing the onboarding questions
                  </h3>
                )}
              </div>

              <div
                className="submit-button-div"
                style={{ marginBlock: '24px' }}
              >
                <Button
                  style={{ width: '50%' }}
                  className="submit-btn-onboard"
                  value="Submit"
                  variant="contained"
                  onClick={() => {
                    this.props.redirectAdditonalModerator();
                    // this.setState({onBoarding: 7});
                    // this.props.hideStepper()
                  }}
                >
                  Done
                </Button>
              </div>
            </div>
          </>
        )}

        {this.props.step !== 6 && (
          <>
            <div className="skip-btn-onboarding" style={{ margin: '24px 0' }}>
              <span onClick={() => this.props.redirectAdditonalModerator()}>
                SKIP
              </span>
            </div>
            <div className="dot-icon" style={{ margin: '32px 0' }}>
              <FiberManualRecordIcon
                className={`${
                  this.props.step === 1 ? 'active-stepper' : 'inactive-stepper'
                }`}
              />
              <FiberManualRecordIcon
                className={`${
                  this.props.step === 2 ? 'active-stepper' : 'inactive-stepper'
                }`}
              />
              <FiberManualRecordIcon
                className={`${
                  this.props.step === 3 ? 'active-stepper' : 'inactive-stepper'
                }`}
              />
              <FiberManualRecordIcon
                className={`${
                  this.props.step === 4 ? 'active-stepper' : 'inactive-stepper'
                }`}
              />
              <FiberManualRecordIcon
                className={`${
                  this.props.step === 5 ? 'active-stepper' : 'inactive-stepper'
                }`}
              />
            </div>
          </>
        )}
      </Container>
    );
  }
}

function QontoStepIcon(props: any) {
  const { active, completed, className } = props;

  return (
    <div>
      {active || completed ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />}
    </div>
  );
}

// @ts-ignore
export default withRouter(Onboarding);

// Customizable Area End
