// @ts-ignore
const google = window.google;
const gapi = window.gapi;

interface RoomData {
  attributes: {
    topic_name: string;
    description: string;
    start_time: string;
    end_time: string;
  };
}

interface eventItemType {
  id: string;
  end: { dateTime: string };
  start: { dateTime: string };
}

function calendarSync(myRoomsData: RoomData[], action: 'ADD' | 'REMOVE') {
  return new Promise((resolve, reject) => {
    const loadLibrary = () => {
      const loadClient = async () => {
        await gapi.client.init({
          apiKey: 'AIzaSyA-su31F4JN5qBYQlEbJcbVvUyVvIUxjrg',
        });
      };
      gapi.load('client', loadClient);
    };

    loadLibrary();

    const client = google.accounts.oauth2.initTokenClient({
      client_id:
        '899494597149-uep1rrche80o0phg9gep8mjt7ioekbd0.apps.googleusercontent.com',
      scope: 'https://www.googleapis.com/auth/calendar',
      callback: async () => {
        gapi.client.load('calendar', 'v3', () => {
          // @ts-ignore
          const eventsRequest = gapi.client.calendar.events.list({
            calendarId: 'primary',
          });
          eventsRequest.execute((events: { items: eventItemType[] }) => {
            let matchedEvents: eventItemType[] = [];
            let filteredMyRoomsData = myRoomsData.filter((roomData) => {
              let returnValue = true;
              for (let i = 0; i < events.items.length; i++) {
                if (
                  new Date(events.items[i].start.dateTime).getTime() ===
                    new Date(roomData.attributes.start_time).getTime() &&
                  new Date(events.items[i].end.dateTime).getTime() ===
                    new Date(roomData.attributes.end_time).getTime()
                ) {
                  console.log('breaked at ', roomData);
                  // store this event cuz its the thing we need to delete if the delete flag
                  matchedEvents.push(events.items[i]);
                  returnValue = false;
                  break;
                }
              }
              return returnValue;
            });
            // @ts-ignore
            const batch = gapi.client.newBatch();
            if (action === 'ADD') {
              filteredMyRoomsData.forEach(({ attributes }) => {
                batch.add(
                  // @ts-ignore
                  gapi.client.calendar.events.insert({
                    calendarId: 'primary',
                    resource: {
                      summary: attributes.topic_name,
                      description: attributes.description,
                      start: {
                        dateTime: attributes.start_time,
                      },
                      end: {
                        dateTime: attributes.end_time,
                      },
                    },
                  })
                );
              });
            } else if (action === 'REMOVE') {
              matchedEvents.forEach(({ id }) => {
                batch.add(
                  // @ts-ignore
                  gapi.client.calendar.events.delete({
                    calendarId: 'primary',
                    eventId: id,
                  })
                );
              });
            }
            if (
              (action === 'ADD' && filteredMyRoomsData.length > 0) ||
              (action === 'REMOVE' && matchedEvents.length > 0)
            ) {
              batch.then();
              resolve(false);
            } else {
              resolve(true);
            }
          });
        });
      },
    });
    client.requestAccessToken();
  });
}

export default calendarSync;
