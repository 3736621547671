// Customizable Area Start
import React, { Component } from 'react';
import { ListItem, Grid } from '@material-ui/core';
import '../SideBar.css';

interface myProps {
	message: string;
	timeStamp: string;
}
export default class YourMessage extends Component<myProps> {
	render() {
		return (
			<ListItem key={2}>
				<Grid direction='row' justifyContent='flex-end' container>
					<Grid item>
						<div className='your-message-body'>
							<div className='your-message-body-detail'>
								<span className='fade'>You</span>
								<span style={{ fontWeight: 500 }}>{this.props.message}</span>
							</div>
							<span className='fade time-stamp'>{this.props.timeStamp}</span>
						</div>
					</Grid>
				</Grid>
			</ListItem>
		);
	}
}
// Customizable Area End
