// Customizable Area Start
import React, { Component, useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import FavoriteOutlinedIcon from '@material-ui/icons/FavoriteOutlined';
import {
    TextField, Box, Typography
} from "@material-ui/core";
import Rating from '@material-ui/lab/Rating';

import './RoomFeedback.css';
import FavoriteIcon from '@material-ui/icons/Favorite';

interface Props {
    user: any,
    handleFeedbackInput: any;
    prevFeedback: string;
    isMobile: boolean;
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#ffcb00', // primary color
        },
    },
});

export default class userInput extends Component<Props>{
    state = { feedbackInput: "" }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        if (prevProps.prevFeedback !== this.props.prevFeedback) {
            if (this.props.prevFeedback.trim().length) {
                this.setState({ feedbackInput: this.props.prevFeedback });
                this.props.handleFeedbackInput(this.props.user.userId, this.props.prevFeedback);
            }
        }
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Box mt={.8}>
                    <TextField
                        onChange={(e) => {
                            this.setState({ feedbackInput: e.target.value })
                            this.props.handleFeedbackInput(this.props.user.userId, e.target.value)
                        }}
                        id="user-feedback-basic"
                        label="Write some feedback"
                        variant="outlined"
                        value={this.state.feedbackInput}
                        style={{
                            width: '100%',
                        }}
                        InputProps={{
                            style: {
                                borderRadius: '0.6rem',
                                boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
                            }
                        }}

                    />
                </Box>
            </ThemeProvider>
        )
    }
}

interface UHProps {
    id: number | string;
    defaultHeartValue: number;
    handleHeartInput: (id: number | string, count: number) => void;
}

export const UserHearts: React.FC<UHProps> = ({ id, defaultHeartValue, handleHeartInput }) => {
    const [count, setCount] = useState(defaultHeartValue);

    let heartColor = '#C2C4CA';
    if (count > 0 && count < 5) heartColor = '#FFCB00';
    else if (count > 4) heartColor = '#5C004D';

    const handleClick = () => {
        setCount(prev => prev === 5 ? 0 : prev + 1);
    }

    useEffect(() => {
        if (defaultHeartValue !== count) {
            setCount(defaultHeartValue);
        }
    }, [defaultHeartValue]);

    useEffect(() => {
        handleHeartInput(id, count);
    }, [count]);

    return (
        <>
            {/* <Box
                id={`heart-${id}`}
                style={{
                    border: '1px solid #E5E5E5',
                    borderRadius: '1rem', padding: '4px 8px',
                    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
                    cursor: 'pointer'
                }}
                display='flex' alignItems='center' justifyContent='space-between'
                mr={1}
                onClick={handleClick}
            >
                <FavoriteOutlinedIcon style={{ width: '1.5rem', height: '1.5rem', marginRight: '.3rem', color: heartColor }} />
                <Typography style={{ color: '#27272A', fontWeight: 500, flex: 1, textAlign: 'right', width: '.5rem' }}>{count}</Typography>
            </Box> */}

            <Rating
                id={`heart-${id}`}
                name={`customized-color-${id}`}
                // defaultValue={this.props.defaultHeartValue}
                getLabelText={(value: number) =>
                    `${value} Heart${value !== 1 ? "s" : ""}`
                }
                value={count}
                onChange={(e: any) => {
                    const count = parseInt(e.target?.value);
                    setCount(count)
                }}
                icon={<FavoriteIcon fontSize="inherit" />}
            />

        </>
    )
}
// Customizable Area End