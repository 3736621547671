// Customizable Area Start
import React, { FC } from 'react';
import { Badge, Button, Divider, Grid, List, Typography } from '@material-ui/core';

import ParticipantVideoCard from './participantVideoCard.web';
import VideocamOffRoundedIcon from '@material-ui/icons/VideocamOffRounded';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import MicOffRoundedIcon from '@material-ui/icons/MicOffRounded';
import MicNoneRoundedIcon from '@material-ui/icons/MicNoneRounded';
import { BsFillDoorOpenFill } from 'react-icons/bs';
import GreenModal from '../Modals/GreenModal';

import AlertModal from '../Modals/alertModal.web';
import AskToLeaveModal from '../Modals/askToLeaveModal.web';
import MustLeaveModal from '../Modals/MustLeaveModal.web';

import Timer from './timer.web';
import './VideoChatScreen.css';
import VideoScreenController from './VideoScreenController';
import MaxAlertModal from '../Modals/maxAlertModal.web';
import AlreadyAskedModal from '../Modals/alreadyAskedModal.web';
import SelfLeaveModal from '../Modals/selfLeaveModal.web';
import RoomFeedback from '../../../../Feedback/src/RoomFeedback.web';
import LeaveRoomModal from "./LeaveRoomModal.web";
import DisableBackButton from './HandleBackBtn';
import RoomDescriptionModal from './RoomDescriptionModal.web';
import CancelRoomModal from './CancelRoomModal.web';

const defaultProfilePic = require('../assets/defaultProfilePic2.png');

interface ModalProps {
	showMaxAlert: boolean;
	showMaxLeave: boolean;
	showGreenModal: boolean;
	selfLeave: boolean;
	retractText: string;
	onClose: () => void;
	isMobile: boolean;
}

const CustomModals: FC<ModalProps> = ({
	showMaxAlert, showMaxLeave, showGreenModal,
	selfLeave, onClose, retractText, isMobile
}) => {
	return (
		<>
			{showMaxAlert && <MaxAlertModal isMobile={isMobile} />}
			{showMaxLeave && <AlreadyAskedModal isMobile={isMobile} />}
			{showGreenModal && <GreenModal retractText={retractText} />}
			{
				selfLeave && (
					<SelfLeaveModal
						onClose={onClose}
						isMobile={isMobile}
					/>
				)
			}
		</>
	)
}

class VideoScreen extends VideoScreenController {
	render() {
		const { isMobile } = this.props;
		const currentUser = this.state?.identityAndId?.get(this.state.d?.identity);
		const owner = this.props.allRoomParticipants.filter(
			(user: any) => user.account_id === this.props.myAccountId);

		return (
			<>
				<CancelRoomModal
					isOpen={this.state.showCancelModal}
					isMobile={isMobile}
					details={{
						title: 'It seems that the room is ended',
						description: 'Sorry, looks like the host couldn\'t make it. You may leave with a full refund.'
					}}
				/>

				<LeaveRoomModal
					isOpen={!this.state.showCancelModal && this.state.leaveModalOpen}
					isMobile={isMobile}
					handleClose={this.toggleLeaveModal}
					leaveCall={this.leaveRoomForUser}
					leaveCallForBothUser={this.leaveCallForBothUser}
					handlRedirect={() => this.props.history.push('/Rooms?type=my')}
					joined_users={this.props.joined_users}
					on_call_users={this.props.on_call_users}
					is_host={owner[0]?.is_host}
					host_id={this.props.hostId}
					room_type={this.props.room_type}
				/>

				<div id='inroom'>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							color: '#150c44',
							margin: '.5rem 0'
						}}
					>
						<Typography
							variant='h5'
							style={{ marginBottom: '0', fontWeight: 500, cursor: 'pointer' }}
							onClick={this.props.toggleRoomDescription}
						>
							{this.props.roomTopic}
						</Typography>
						<Badge
							overlap="rectangular"
							id='time-dot'
							color='primary'
							variant='dot'
							badgeContent=" "
							anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
						/>

						<Timer
							getRoomDetails={this.props.getRoomDetails}
							sendRoomExtensionRequest={this.props.sendRoomExtensionRequest}
							roomPrice={this.props.roomPrice}
							myWalletBalance={this.props.myWalletBalance}
							handleLeave={this.handleLeave}
							startTime={this.props.startTime}
							endTime={this.props.endTime}
							joined_users={this.props.joined_users}
							on_call_users={this.props.on_call_users}
							openModal={this.toggleLeaveModal}
							isRoomOwner={(owner[0]?.is_host && owner[0]?.is_moderator) || false}
							isModeratorRoom={this.props.isModerated}
							earnedYCoins={this.earnedYCoins}
							isMobile={this.props.isMobile}
							currentTime={this.props.currentUnixTime}
						/>
					</div>

					<div id='your-video-feed'>
						{/* show if user pause their video */}
						{(this.state.mainVideoTrackDisabled && this.state.d) &&
							<img style={{
								zIndex: 50, width: "100%", height: "100%",
								position: "absolute", right: "0%", objectFit: "contain", background: "#000"
							}}
								src={currentUser?.is_anonymously ?
									defaultProfilePic :
									currentUser?.profile_img || defaultProfilePic
								}>
							</img>
						}
						{/* highlighed video screen */}
						<div id='local-screen' />
						{/* list of users  */}
						<List className='participant-list'>
							{this.state.allActiveParticipants.map((rp: any, index: number) => {
								let data = this.state.identityAndId.get(rp.identity);

								return (
									<ParticipantVideoCard
										index={index}
										key={data?.account_id || index}
										remoteParticipant={rp}
										id={data?.account_id}
										accountId={data?.account_id}
										fullName={data?.full_name || ''}
										isAnonymously={data?.is_anonymously}
										isHost={data?.is_host}
										isModerator={data?.is_moderator}
										anonymouslyName={data?.anonymously_name}
										profileImg={data?.profile_img}
										selfAlertCount={this.state.selfAlertCount}
										selfLeaveCount={this.state.selfLeaveCount}
										showMaxAlert={this.state.showMaxAlert}
										showMaxLeave={this.state.showMaxLeave}
										handleAlertCount={this.handleAlertCount}
										handleLeaveCount={this.handleLeaveCount}
										handleMaxAlert={this.handleMaxAlert}
										handleMaxLeave={this.handleMaxLeave}
										currentUser={this.state.currentUser}
										isRoomModarated={this.props.isModerated}
										retract_leave_status={data?.retract_leave_status}
										startTime={this.props.startTime}
									/>
								);
							})}
						</List>
					</div>
					<Divider id='video-divider' />
					<div>
						<Grid className='your-video-tool' container>
							<Grid item xs={false} sm={2} />
							<Grid style={{ textAlign: 'center' }} item xs={8} sm={6}>
								<Button
									className='your-video-tool-button'
									variant='outlined'
									onClick={this.handleVideo}
								>
									{this.state.videoStatus ? (
										<VideocamOutlinedIcon />
									) : (
										<VideocamOffRoundedIcon />
									)}
								</Button>
								<Button
									className='your-video-tool-button'
									variant='outlined'
									onClick={this.handleAudio}
								>
									{this.state.audioStatus ? (
										<MicNoneRoundedIcon />
									) : (
										<MicOffRoundedIcon />
									)}
								</Button>
								{/* <Button
									className='your-video-tool-button'
									variant='outlined'
									onClick={this.handleBackground}
								>
									<WidgetsRoundedIcon />
								</Button> */}
							</Grid>
							<Grid
								style={{ display: 'flex', justifyContent: 'flex-end' }}
								item
								xs={3}
								sm={4}
							>
								<Button
									onClick={this.handleLeave}
									id='leave-button'
									variant='outlined'
								>
									LEAVE
								</Button>
							</Grid>
						</Grid>
					</div>
					{
						this.state.icebreakerText.length > 0
						&&
						<span id='ice-breaker'>
							{this.state.icebreakerText}
						</span>
					}
					{
						this.state.showLeaveButton && (
							<div
								onClick={() => {
									/* add this.handleLeave method to allow leave through this button. */
								}}
								style={{ position: 'absolute', top: '4.5rem', left: '2.5rem', zIndex: 100 }}
							>
								<div
									style={{
										display: 'inline-block',
										border: '3.5px solid #FA8072',
										padding: '1.2rem',
										borderRadius: '8px',
										backgroundColor: 'white',
										zIndex: 100
									}}
								>
									<BsFillDoorOpenFill size={25} />
								</div>
							</div>
						)
					}
					{
						this.state.alertNotification && (
							<AlertModal reason={this.state.alertNotificationMessage} isMobile={isMobile} />
						)
					}
					{
						this.state.leaveRequestNotificationMessage && (
							<AskToLeaveModal
								reason={this.state.leaveRequestNotificationMessage}
								isMobile={isMobile}
								handleClose={() => this.setState({ leaveRequestNotificationMessage: '' })}
								handleLeave={() => this.setState({ leaveRequestNotificationMessage: '' }, () => {
									this.handleLeave(null);
								})}
							/>
						)
					}
					{
						((this.state.leaveRequestNotificationCount >= 2) ||
							(this.props.isModerated && this.state.leaveRequestNotificationCount == 1)) &&
						<MustLeaveModal
							feedbackData={this.props.feedbackData}
							history={this.props.history}
							endCallForUser={this.endCallForUser}
							isModerated={this.props.isModerated}
							isMobile={this.props.isMobile}
						/>
					}

					<CustomModals
						selfLeave={this.state.selfLeave}
						showGreenModal={this.state.showGreenModal}
						showMaxAlert={this.state.showMaxAlert}
						showMaxLeave={this.state.showMaxLeave}
						onClose={() => this.setState({ selfLeave: false })}
						retractText={this.state.retractText}
						isMobile={this.props.isMobile}
					/>

					{
						this.state.leave &&
						<RoomFeedback
							id={'123'}
							navigation={'temp'}
							leave={this.state.leave}
							handleLeave={(leave: boolean) => this.setState({ leave })}
							roomId={this.props.history.location.pathname.split('/')[2]}
							feedbackData={this.props.feedbackData}
							endTime={this.props.endTime}
							allParticipants={this.props.allRoomParticipants?.
								filter((each: { account_id: number }) => each.account_id !== this.state?.currentUser?.account_id)
							}
							history={this.props.history}
							isModeratorRoom={this.props.isModerated}
							isHost={this.state?.currentUser?.account_id === this.props.hostId}
							joinedUsers={this.props.joined_users}
							isMobile={isMobile}
							currentUser={this.state?.currentUser}
							sendConnectionRequest={this.props.sendConnectionRequest}
							revertConnectionRequest={this.props.revertConnectionRequest}
							handleDisconnectUser={this.props.handleDisconnectUser}
						/>
					}
				</div>

				{this.props.roomImgLink && (
					<RoomDescriptionModal
						isMobile={this.props.isMobile}
						isOpen={this.props.isRoomDescriptionOpen}
						toggleModal={this.props.toggleRoomDescription}
						roomTopic={this.props.roomTopic}
						roomImgLink={this.props.roomImgLink}
						description={this.props.roomDescription}
					/>
				)}

				<DisableBackButton  {...this.props} history={this.props.history} />
			</>
		);
	}
}
// @ts-ignore
export default VideoScreen;
// Customizable Area End
