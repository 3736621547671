//Customizable Area Start
import React, { Component } from 'react';
import CustomizableModalWindow from './customizableModalWindow.web';
import { Card, Box, Typography } from '@material-ui/core';
import './Modals.css';
import { AlertBottomSheet } from './BottomSheet.web';

interface Props {
	isMobile: boolean;
}

export default class MaxAlertModal extends Component<Props> {
	render() {
		const { isMobile } = this.props;

		return (
			<CustomizableModalWindow id='max-alerts-window' open={true}>
				{!isMobile && (
					<Card className='red-border-card-modal'>
						<span>You have already reached the maximum number of three alerts.</span>
					</Card>
				)}

				{isMobile && (
					<AlertBottomSheet type='alert'>
						<Box mt={1} textAlign='center' display='flex' alignItems='center'>
							<Typography style={{ color: '#ccc' }}>
								You have already reached the maximum number of three alerts.
							</Typography>
						</Box>
					</AlertBottomSheet>
				)}
			</CustomizableModalWindow>
		);
	}
}
// Customizable Area End
