// Customizable Area Start
import React from 'react';
import { Grid, Box } from '@material-ui/core';
import './RulesOfEngagement.scss';
import { hourglass as GlassImg } from "../assets";

interface Props {
    isOpen: boolean;
    handleClose: () => void;
}

export default class LateJoinScreen extends React.PureComponent<Props> {
    constructor(props: any) {
        super(props);
    }

    render() {
        const { handleClose } = this.props;

        return (
            <>
                <Box className='rulesOfEngagement active center'>
                    <Grid
                        className="rulesOfEngagementContent"
                        container
                    >
                        <div className="title">Oops...You are late!</div>

                        <Box className='img-container'>
                            <img src={GlassImg} alt="glass_img" />
                        </Box>

                        <p className="message">
                            We are sorry, the conversation rooms are shut 15 minutes after the scheduled start time.
                            At YLanes, we value the time taken out by our guests for Meaningful Conversations and we believe that a delayed entry could disturb the flow of an on-going conversation.
                        </p>

                        <button onClick={handleClose} className='back-btn'>
                            Back to home
                        </button>
                    </Grid>
                </Box>
            </>
        )
    }

}

// Customizable Area End
