// Customizable Area Start
import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Card, CardContent, CardActions, Button, Box, Typography } from '@material-ui/core';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import './RoomExtension.css';
import RefillWallet from '../../../../Payments/src/refillwallet/RefillWallet.web';
import StateContext, { Props as ContextProps } from '../../../../../web/src/globalState';
import BottomSheet from '../Modals/BottomSheet.web';
import { walletIcon } from '../../assets';

interface Props {
	open: boolean;
	handleClose: () => void;
	handleOpen: () => void;
	roomPrice: number;
	myWalletBalance: number;
	sendRoomExtensionRequest: () => void;
	getRoomDetails: any;
	isFreeExtension: boolean;
	handle1stExtension: (res: boolean) => void;
	isMobile: boolean;
}

function getButton(
	isFreeExtension: boolean,
	roomPrice: number,
	userCoinAmount: number,
	extendRoomBtn: JSX.Element,
	handleRefillOpen: () => void
): JSX.Element {
	if (isFreeExtension || roomPrice <= userCoinAmount) {
		return extendRoomBtn;
	} else {
		return (
			<Button
				onClick={handleRefillOpen}
				className='card-button-modal'
				style={{
					backgroundColor: '#d3d3d3',
					color: '#2d2e5f',
				}}
			>
				Refill Wallet
			</Button>
		);
	}
}

export default class RoomExtensionModal extends Component<Props> {
	//@ts-ignore
	myRef = React.createRef();
	static contextType?: React.Context<ContextProps> = StateContext;

	handleRefillOpen = () => {
		this.props.handleClose();
		// @ts-ignore 
		this.myRef.current.handleOpen()
	}

	handleUpdateWallet = () => {
		this.props.getRoomDetails();
	}

	reduceCoin = (coins: number) => {
		const userCoin = this.context.userCoinAmount;
		if (userCoin <= 0) return;

		const newCoins = userCoin - Number(coins);
		this.context.setCoin(newCoins, false);
	}

	handleExtend = () => {
		if (!this.props.isFreeExtension) {
			this.reduceCoin(this.props.roomPrice);
			this.props.sendRoomExtensionRequest();
		} else {
			this.props.handle1stExtension(true);
		}
		this.props.handleClose();
	}

	handleDontExtend = () => {
		this.props.handle1stExtension(false);
		this.props.handleClose();
	}

	extendRoomBtn = (
		<Button
			onClick={this.handleExtend}
			className='card-button-modal'
			style={{ backgroundColor: '#010039' }}
			disableElevation
			variant='contained'
			color='primary'
		>
			Extend
		</Button>
	)

	render() {
		const button = getButton(this.props.isFreeExtension, this.props.roomPrice, this.context.userCoinAmount, this.extendRoomBtn, this.handleRefillOpen);

		if (this.props.isMobile && this.props.open) {
			return (
				<BottomSheet addBackDrop handleClose={this.handleDontExtend}>
					<Box p={2}>
						{!this.props.isFreeExtension && (
							<Box display='flex' alignItems='center' justifyContent='space-between'>
								<Typography style={{ color: '#262556', fontWeight: 600 }}>
									Price:
									<Typography component='span' style={{ color: '#ffcb00', marginLeft: '.5rem', fontWeight: 600 }}>
										{this.props.roomPrice} Ycoins
									</Typography>
								</Typography>
								<Box
									display='flex'
									alignItems='center'
									style={{ gap: '.5rem' }}
									sx={{
										mt: 2,
										mb: 2,
										border: '1px solid #E0E0E0',
										p: 1,
										borderRadius: 10,
										boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
									}}
								>
									<img style={{ width: '1rem' }} src={walletIcon} alt="wallet_icon" />
									<Typography
										style={{
											color: '#010039',
											fontWeight: 600,
											wordBreak: 'break-word',
										}}
									>
										{this.context.userCoinAmount}
									</Typography>
								</Box>
							</Box>
						)}

						<Box>
							<Box mb={1.5}>
								<Typography style={{ color: '#000', fontWeight: 500 }}>
									Your room ends in 5 minutes. Would you like to extend for 1 hour?
									{(localStorage.getItem('isModerator') === "true" || this.props.isFreeExtension) ? "(it's FREE)" : ''}
								</Typography>
							</Box>

							<Typography>
								If not, please take this time to share one great thing about each
								of your fellow participants.
							</Typography>
						</Box>

						<Box
							display='flex'
							alignItems='center'
							justifyContent='space-between'
							flex={1}
							my={4}
							style={{ gap: '1rem' }}
							onClick={this.handleDontExtend}
						>
							<div style={{
								border: '1px solid #262556',
								padding: '1rem 0',
								borderRadius: '.5rem',
								textAlign: 'center',
								color: '#262556',
								fontWeight: 500,
								flex: 1
							}}>
								Don't Extend
							</div>

							<div
								onClick={this.handleExtend}
								style={{
									border: '1px solid #262556',
									textAlign: 'center',
									padding: '1rem 0',
									background: '#262556',
									color: '#fff',
									borderRadius: '.5rem',
									fontWeight: 500,
									flex: 1
								}}
							>
								Extend
							</div>
						</Box>
					</Box>
				</BottomSheet>
			)
		}

		return (
			<div>
				<Modal
					id='modal-window'
					aria-labelledby='transition-modal-extension-option'
					aria-describedby='transition-modal-extension-option'
					open={this.props.open}
					onClose={this.props.handleClose}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500
					}}
				>
					<Fade in={this.props.open}>
						<div>
							<Card className='modal-card'>
								<CardContent style={{ padding: '8px 16px' }}>
									{!this.props.isFreeExtension && (
										<div className='price-and-wallet'>
											<div>
												<span className='blue-and-bold'>Price : </span>
												<span style={{ color: '#FFCB00' }}>
													{this.props.roomPrice} YCoins
												</span>
											</div>
											<div className='wallet'>
												<AccountBalanceWalletOutlinedIcon
													fontSize='small'
													style={{ color: '#FFCB00' }}
												/>
												<span className='blue-and-bold' style={{ fontSize: '.9rem' }}>
													{this.context.userCoinAmount}
												</span>
											</div>
										</div>
									)}
									<p className='card-text-modal'>{`Your room ends in 5 minutes.Would you like to extend for 1 hour? ${(localStorage.getItem('isModerator') === "true" || this.props.isFreeExtension) ? "(it's FREE)" : ''}`}</p>
									<p className='card-text-modal'>
										If not,please take this time to share one great thing about each
										of your fellow participants.
									</p>
								</CardContent>
								<CardActions>
									<Button
										className='card-button-modal'
										onClick={this.handleDontExtend}
										style={{ color: '#c0bfbf' }}
										variant='outlined'
									>
										Don't Extend
									</Button>
									{button}
								</CardActions>
							</Card>
						</div>
					</Fade>
				</Modal>

				{<RefillWallet
					//@ts-ignore
					ref={this.myRef}
					cb={this.handleUpdateWallet}
					addValue={(_) => { }}
					handleOpen={this.props.handleOpen}
				/>}
			</div>
		);
	}
}
// Customizable Area End
