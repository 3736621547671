// Customizable Area Start
import React, { useState } from 'react'
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import './WalletResponsive.scss';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountStatement from '../AccountStatement'

const MobileModeratorAccordion = (props: any) => {
   const [expanded, setExpanded] = useState<boolean>(false);

   return (
      <div className="mobileAccordion">
         <MuiAccordion style={{ border: 'none !important' }} className="myAccordion" expanded={expanded === true} 
         onChange={() => {
            setExpanded(!expanded)
         }}>
            <MuiAccordionSummary
               expandIcon={<ExpandMoreIcon />}
               aria-controls="panel1d-content"
               id="panel1d-header">
               <div className="accordionHeader">
                  <div className="accordionModel">Account Statement</div>
               </div>
            </MuiAccordionSummary>
            <MuiAccordionDetails className="myAccordionInside">
               <AccountStatement
                  handleAccountStatementScroll={props.handleAccountStatementScroll} 
                  accountLoading={props.accountLoading} 
                  userData={props.context} 
                  accountStatementData={props.accountStatementData} 
                  bankDetails={props.bankDetails} 
                  handleChangeDetail={props.handleChangeDetail}
               />
            </MuiAccordionDetails>
         </MuiAccordion>
      </div>
   )
}

export default MobileModeratorAccordion;
// Customizable Area End