 // Customizable Area Start

 import React, { Component } from 'react';
 import Header from "../../../Settings5/src/settings/header.web";

 
 export default class Home extends Component {
   render() {
     return (
      <>  
        {/* @ts-ignore */}
        <Header navigation={this.props.navigation} id={""} />
        <h1>My Profile</h1>
      </>
     )
   }
 }
 








 // Customizable Area End