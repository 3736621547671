// Customizable Area Start
import React, { Component } from 'react';
import CustomizableModalWindow from './customizableModalWindow.web';
import { Card, Typography, Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import './Modals.css';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { closeIcon } from "../../assets";
import { AlertBottomSheet } from './BottomSheet.web';

interface myProps extends RouteComponentProps {
	onClose: () => void;
	isMobile: boolean;
}

interface State {
	isOpen: boolean;
}

class SelfLeaveModal extends Component<myProps, State> {
	handleClick = () => {
		if (document.getElementById('leave-button'))
			document.getElementById('leave-button')?.click();
	};

	state = {
		isOpen: true
	}

	handleClose = () => {
		this.setState({ isOpen: false });
		this.props.onClose();
	}

	render() {
		const { isMobile } = this.props;

		return (
			<CustomizableModalWindow id='self-leave-window' open={this.state.isOpen}>
				{!isMobile && (
					<Card className='red-card-modal'>
						<Box
							mb={1}
							display="flex"
							justifyContent="flex-end" style={{ cursor: "pointer" }}
							onClick={this.handleClose}
						>
							<img src={closeIcon} alt="close_icon" style={{ width: '1.2rem' }} />
						</Box>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center'
							}}
						>
							<Typography
								style={{ fontSize: '1.1rem' }}
								color='textSecondary'
								variant='h6'
							>
								Are you sure you want to leave the room?If you are leaving while other
								participants are present,you could attract a penalty.
							</Typography>
							<Button id='leave-button-modal' onClick={this.handleClick}>
								Leave
							</Button>
						</div>
					</Card>
				)}

				{isMobile && (
					<AlertBottomSheet type='exit'>
						<Box mt={1} textAlign='center' display='flex' alignItems='center' flexDirection='column'>
							<Typography color='textSecondary'
								variant='body1'
							>
								Are you sure you want to leave the room? If you are leaving while other
								participants are present, you could attract a penalty.
							</Typography>

							<Box width={'90%'} mt={3}>
								<div style={{
									border: '1px solid #FF0100',
									padding: '1rem 3.5rem',
									background: '#FF0100',
									color: '#fff',
									borderRadius: '.5rem',
									fontWeight: 500,
									textAlign: 'center'
								}}
									onClick={this.handleClick}
								>
									Leave
								</div>
							</Box>
						</Box>
					</AlertBottomSheet>
				)}
			</CustomizableModalWindow>
		);
	}
}

export default withRouter(SelfLeaveModal);
// Customizable Area End
