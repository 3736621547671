// Customizable Area Start
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { noUserImg as NoProfileImg } from "../assets";

const useStyles = makeStyles((theme) => ({
    avatarGroup: {
        '& .MuiAvatarGroup-avatar': {
            marginLeft: '-22px !important',
            border: `2px solid ${theme.palette.background.paper}`,
        },
    },
    plus: {
        backgroundColor: '#ffcb00',
        color: '#27272A',
        fontWeight: 500,
        fontSize: 15
    },
    avatar: {
        width: theme.spacing(6),
        height: theme.spacing(6),
    },
}));

type AVATARS = { id: number, alt: string, src: string }[];
interface Props extends RouteComponentProps {
    avatars: AVATARS;
    maxVisibleAvatars?: number;
}

const CustomAvatarGroup: React.FC<Props> = ({ avatars, history, maxVisibleAvatars = 4 }) => {
    const classes = useStyles();

    const extraAvatars = avatars.length - (maxVisibleAvatars - 1);

    const openProfile = (userId: number) => {
        const currentID = Number(localStorage.getItem('userId') as string);

        if (currentID == userId) {
            history.push('/UserPersonalProfile');
            return;
        }

        history.push('/UserProfile', { userId });
    }

    return (
        <AvatarGroup
            max={maxVisibleAvatars}
            className={classes.avatarGroup}
            spacing="small"
            style={{ justifyContent: 'flex-end' }}
        >
            {extraAvatars > 0 &&
                <Avatar
                    className={`${classes.plus} ${classes.avatar}`}
                >
                    +{extraAvatars}
                </Avatar>
            }
            {
                avatars.slice(0, maxVisibleAvatars - (extraAvatars > 0 ? 1 : 0)).map((avatar, index) => (
                    <Avatar
                        key={`${avatar.id}`}
                        alt={avatar.alt}
                        src={avatar.src || NoProfileImg}
                        className={classes.avatar}
                        onClick={() => openProfile(Number(avatar.id))}
                    />
                ))
            }
        </AvatarGroup >
    );
}

export default withRouter(CustomAvatarGroup);
// Customizable Area End