// Customizable Area Start
import React, { useContext } from 'react'
import useRazorpay from 'react-razorpay'
import { Grid } from '@material-ui/core'
export const configJSON = require("./config");

const getRazorPayKey = () => {
   const prodURL = 'https://www.ylanes.com';
   const isLiveMode = window.location.href.includes(prodURL);
   const razorpayKey = isLiveMode
      ? process.env.REACT_APP_RAZORPAY_LIVE_KEY
      : process.env.REACT_APP_RAZORPAY_TEST_KEY;
   return razorpayKey;
}

const RazorPay = (props: any) => {
   //@ts-ignore
   const Razorpay = useRazorpay();

   const generateOrderId = (coinAmount: any, totalAmount: number) => {
      const header = new Headers();
      const token = localStorage.getItem("token")
      //@ts-ignore
      header.append("token", token);
      header.append("Content-Type", "application/json");


      const raw = JSON.stringify({
         "amount": coinAmount,
         "total_amount": totalAmount
      });

      const requestOptions = {
         method: configJSON.httpPostType,
         headers: header,
         body: raw
      };

      fetch(configJSON.createOrder, requestOptions)
         .then(response => response.json())
         .then(result => {
            if (Boolean(result?.transaction?.order_id)) {
               openPaymentGateway(result?.transaction?.order_id, result?.transaction?.id, coinAmount)
            }

         })
         .catch(error => console.log('error', error));
   }

   const openPaymentGateway = (orderId: string, transactionId: number, coinAmount: any) => {
      const taxAmount = (Number(coinAmount) * 0.18);
      const totalAmount = Number(coinAmount) + taxAmount;
      const options = {
         description: 'Refill wallet',
         name: 'YLanes',
         key: getRazorPayKey(),
         amount: String(totalAmount),
         currency: "INR",
         order_id: orderId,//Replace this with an order_id created using Orders API.
         prefill: {
            email: 'support@ylanes.com',
            contact: '+911234567890',
            name: 'YLanes'
         },
         theme: { color: '#ffcb00' },
         method: {
            netbanking: true,
            card: true,
            wallet: true,
            upi: true,
            paylater: false,
            emi: false,
         },
         config: {
            display: {
               hide: [
                  { method: 'paylater' },
                  { method: 'emi' }
               ],
            }
         },
         handler: (response: any) => {
            verifyPaymentSignature(response, transactionId, coinAmount)
         }
      }
      //@ts-ignore
      const RZR = new Razorpay(options)
      RZR.on("payment.failed", function (response: any) {
         alert(response.error.code + ' -> ' + response.error.description);
      });
      RZR.open()
   }

   const verifyPaymentSignature = async (data: any, transactionId: number, coinAmount: any) => {
      const myHeaders = new Headers();
      const token = localStorage.getItem('token')
      //@ts-ignore
      myHeaders.append("token", token);
      myHeaders.append("Content-Type", "application/json");
      let num = parseInt(coinAmount) + props.freeCoin
      var raw = JSON.stringify({
         "razorpay_payment_id": data?.razorpay_payment_id,
         "order_id": data?.razorpay_order_id,
         "ycoins_purchased": num,
         "free_ycoins": props.freeCoin,
         "duration": "3 Months",
         "razorpay_signature": data?.razorpay_signature
      });

      var requestOptions = {
         method: 'PUT',
         headers: myHeaders,
         body: raw
      };

      fetch(`${configJSON.createOrder}/${transactionId}`, requestOptions)
         .then(response => response.json())
         .then(result => {
            props.handleSuccess(num)
         })
         .catch(error => console.log('error', error));
   }
   return (
      <>
         <Grid
            item
            xs={12}
            lg={9}
            md={9}
            onClick={() => {
               props.coinAmount > 0 ? generateOrderId(props.coinAmount, props.totalAmount) : ''
            }} className={props.coinAmount > 0 ? "payNowButton" : "payNowButtonDisabled"}
         >
            Pay Now
         </Grid>
      </>
   )
}

export default RazorPay;
// Customizable Area End