// Customizable Area Start
import {
  Grid,
  Tabs,
  Tab,
  Breadcrumbs,
  Typography,
  CircularProgress,
  Button,
  Box,
  Hidden
} from '@material-ui/core';
import CategoriessubcategoriesController, {
  Props,
} from '../CategoriessubcategoriesController';
import NavigateNext from '@material-ui/icons/NavigateNext';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
// import { TabList, TabPanel, TabContext } from '@mui/lab'
import React from 'react';
import './style.scss';
import './subcategory.scss';
import {
  standard,
  moderated,
  premium,
  onetoone,
  hourglass,
  cactus,
  solid,
  noRoomImg
} from '../assets';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import LeftMenu from './components/LeftMenu.web';
import CategoryBox from './components/CategoryBox.web';
import RoomBox from './components/RoomBox.web';
import CreateRoom from '../../../DifferentRooms/src/CreateRoom/CreateRoom.web';
import SubCategoryBox from './components/SubCategoryBox.web';
import { isAuthenticated } from '../../../../components/src/utils';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import HeaderNoLogin from '../../../../components/src/HeaderNoLogin';
import StateContext from '../../../../web/src/globalState';
import Draggable from 'react-draggable';
import Slide from '@material-ui/core/Slide';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import RoomTypes from '../../../DifferentRooms/src/CategoriesAndRoomTypes/RoomTypes.web'

const theme = createTheme({
  palette: {
    primary: {
      main: '#FFCB00',
    },
    secondary: {
      main: '#010039',
    },
  },
});

class CategoriesWeb extends CategoriessubcategoriesController {
  static contextType: any = StateContext;
  constructor(props: any) {
    super(props);
  }

  scrollInto = (id: any) => {
    const box = document.getElementById(id);
    const root = document.getElementById('list');
    if (box && root) {
      this.setState({ selectedId: id });
      box.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // TO UNDERSTAND THE TRUE AND FALSE CONDITION FOR QUALITY BOT IS HARD SO I AM USING THIS UNNECESSARY FUNCTION
  trueFalseCheckerForQualityBot = (
    condition: any,
    trueValue: any,
    falseValue: any
  ) => {
    return condition ? trueValue : falseValue;
  };

  // this works when loading and if there no Data
  isLoadingDiv = (type: string) => {
    let props: any = this.props;
    if (this.state.isLoadingInside) {
      return (
        <Grid
          container
          xs={12}
          className="loadingOutsideDiv"
        >
          <div>
            <CircularProgress
              size={80}
              thickness={4}
              style={{ color: '#ffcb00' }}
            />
          </div>
        </Grid>
      );
    } else if (type === 'rooms') {
      if (this.state.selectedTopic.length > 0)
        return (
          <>
            <Grid xs={12} className="noRoomDesc">
              {/* <div className="noShowCrateRoom">No {type} available</div> */}
              <Grid container className="CreateRoomBox">
                <Grid lg={8} md={8} xs={12}>
                  <span className="label">
                    {this.state.selectedTopic[0]?.attributes.name}
                  </span>
                  <span>
                    {this.state.selectedTopic[0]?.attributes.description}
                  </span>
                </Grid>
                <Grid lg={3} md={3} xs={12} className="createRoomButtonBox">
                  <Button
                    style={{ width: '100%', marginTop: '20px', minWidth: 'max-content' }}
                    className="contained-btn margin-spacing-right"
                    variant="contained"
                    onClick={() => {
                      this.context.changeCreateRoomModal(true, {
                        selectedTopic: this.state.selectedTopic[0].attributes.id,
                        selectedCategory: this.state.selectedTopic[0].attributes.category_id,
                        selectedSubCategory: this.state.selectedTopic[0].attributes.sub_category_id
                      });
                    }}
                  >
                    Create Room
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <div className="NoRoomClassBox">
                <img src={noRoomImg}></img>
                <span>No rooms available</span>
                <span className="secondText">Create a room and start having a conversation</span>
            </div>
          </>
        );
    } else {
      return (
        <Grid xs={12} className="noRoom">
          <span>No {type} to show</span>
        </Grid>
      );
    }
  };

  // RENDER
  render() {
    const isModerator =
      JSON.parse(localStorage.getItem('isModerator') as string) || false;
    const props: any = this.props;

    const breadcrumbs = [
      <Link
        to="/CategoriesWeb"
        key="1"
        color="inherit"
        onClick={() => this.backToCategories()}
      >
        {this.state.category}
      </Link>,
      this.trueFalseCheckerForQualityBot(
        this.state.currentLocationParams.sub_id,
        <Link
          to={
            '/CategoriesWeb/category/' +
            this.state.currentLocationParams.id +
            '/subcategory/' +
            this.state.currentLocationParams.sub_id
          }
          key="2"
          color="inherit"
        >
          {this.state.subCategory}
        </Link>,
        <Typography key="2">{this.state.subCategory}</Typography>
      ),
      this.trueFalseCheckerForQualityBot(
        this.state.currentLocationParams.topic_id,
        <Typography key="3">{this.state.topic}</Typography>,
        ''
      ),
    ];

    const isLoggedIn = isAuthenticated();

    return (
      <>
        <ThemeProvider theme={theme}>
          {!isLoggedIn && (
            <HeaderNoLogin
              id={this.props.id}
              navigation={this.props.navigation}
            />
          )}
        </ThemeProvider>
        <Grid style={{ marginTop: isLoggedIn ? '70px' : '0px' }} className="categoryWrapperClass" container>
          <Grid
            id="list"
            className={(isLoggedIn ? 'subCategoryWrapper' : 'subCategoryWrapperNotloggedIn') + (this.state.currentLocationParams.sub_id ? ' subCategory' : '')}
            container
            item
            xs={12}
            md={!isLoggedIn && !this.state.currentLocationParams.sub_id ? 12 : 9}
            alignItems="flex-start"
          >
            {(() => {
              if (this.state.isLoading) {
                const props: any = this.props
                const [, , , id, , sub_id, , topic_id]: any = props.location.pathname.split('/')
                if (sub_id || topic_id) {
                  return (
                    <Grid container>
                      <Grid
                        item
                        container
                        xs={12}
                        md={4}
                        className="leftMenuWrapper"
                      >
                        <Skeleton width="100%" animation="wave" height="100%" variant='text' />
                      </Grid>
                      <Grid style={{ marginTop: '5px', marginBottom: '20px' }} container md={8} xs={12}>
                        <Grid container className="subcategoryBreadcrumb">
                          <Skeleton width="100%" animation="wave" height="70px" variant='text' />
                        </Grid>
                        <Grid
                          item
                          container
                          xs={12}
                          style={{ height: 'max-content' }}
                        >
                          <Grid xs={12} className="subcategoryBannerBox">
                            <div className="subcategoryBanner" >
                              <Skeleton width="100%" animation="wave" height="500px" variant='rect' />
                              {/* <div className="imgText">The purposse of a relationship is not to have another who might complete you, but to have another with whom you might share your completeness<br /><br /><span>- Neale Donald Walsch</span></div> */}
                            </div>
                          </Grid>
                          {Array(20).fill(0).map((_, i) =>
                            <div key={i} className="d-full marginTop" style={{ border: 'none' }}>
                              <Skeleton variant='rect' width="100%" animation="wave" height="180px" />
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                } else {
                  return (
                    <div
                      style={{ width: '100%' }}
                    >
                      <Grid item className="categorySubLabel" xs={12}>
                        <Skeleton width="100%" animation="wave" height="50px" variant='text' />
                      </Grid>
                      <Grid item className="categorySubLabel" xs={12}>
                        <Skeleton width="100%" animation="wave" height="50px" variant='text' />
                      </Grid>
                      <Grid
                        item
                        style={{ marginTop: '10px' }}
                        container
                        xs={12}
                      >
                        {Array(20).fill(0).map((_, i) =>
                          <div className="categoryOutsideBox">
                            <div className="categoryBox" style={{ border: 'none' }}>
                              <div className="categoryImage" style={{ background: '#fff', marginBottom: '20px' }}>
                                <Skeleton variant='rect' width="100%" animation="wave" height="100%" />
                              </div>
                              <Skeleton width="100%" animation="wave" height="50px" />
                            </div>
                          </div>
                        )}
                      </Grid>
                    </div>
                  );
                }
              } else {
                if (this.state.currentLocationParams.topic_id && this.state.currentLocationParams.sub_id && this.state.currentLocationParams.id && this.state.topicsArray.length > 0) {
                  return (
                    <>
                      <Grid
                        item
                        container
                        xs={12}
                        md={4}
                        className="leftMenuWrapper"
                      >
                        <LeftMenu
                          selectedTopic={this.state.selectedTopic}
                          upcomingRooms={this.state.upcomingRooms}
                          selectedSubMenu={this.state.selectedSubMenu}
                          selectedSub={this.state.selectedSub}
                          topicsArray={this.state.topicsArray}
                          backToCategories={this.backToCategories}
                          match={this.state.currentLocationParams}
                          goToTopics={this.goToTopics}
                          goToSubcategory={this.goToSubcategory}
                        />
                      </Grid>
                      <Grid style={{ marginTop: '5px', marginBottom: '20px' }} container md={8} xs={12}>
                        <Grid container className="subcategoryBreadcrumb">
                          <Breadcrumbs
                            separator={<NavigateNext fontSize="small" />}
                            aria-label="breadcrumb"
                          >
                            {breadcrumbs}
                          </Breadcrumbs>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={12}
                          style={{ height: 'max-content' }}
                        >
                          <Grid xs={12} className="subcategoryBannerBox">
                            <div className="subcategoryBanner">
                              <LazyLoadImage
                                className="lazyLoad"
                                // effect="blur"
                                // placeholderSrc={bgg}
                                placeholderSrc={this.state.subCategoryThumb ?? solid}
                                src={
                                  this.state.subCategoryIcon
                                    ? this.state.subCategoryIcon
                                    : cactus
                                }
                              />
                              {/* <div className="imgText">The purposse of a relationship is not to have another who might complete you, but to have another with whom you might share your completeness<br /><br /><span>- Neale Donald Walsch</span></div> */}
                            </div>
                          </Grid>
                          {this.state.upcomingRooms &&
                            this.state.upcomingRooms.length > 0 ? (
                            <RoomBox
                              //@ts-ignore
                              histroy={this.props.history}
                              navigation={this.props.navigation}
                              upcomingRooms={this.state.upcomingRooms}
                            />
                          ) : (
                            this.isLoadingDiv('rooms')
                          )}
                        </Grid>
                      </Grid>
                    </>
                  );
                } else if (
                  this.state.currentLocationParams.sub_id && this.state.currentLocationParams.id
                ) {
                  return (
                    <>
                      <Grid
                        item
                        container
                        xs={12}
                        md={4}
                        className="leftMenuWrapper"
                      >
                        <LeftMenu
                          selectedTopic={this.state.selectedTopic}
                          upcomingRooms={this.state.upcomingRooms}
                          selectedSubMenu={this.state.selectedSubMenu}
                          selectedSub={this.state.selectedSub}
                          topicsArray={this.state.topicsArray}
                          backToCategories={this.backToCategories}
                          match={this.state.currentLocationParams}
                          goToTopics={this.goToTopics}
                          goToSubcategory={this.goToSubcategory}
                        />
                      </Grid>
                      <Grid style={{ marginTop: '5px', marginBottom: '20px' }} item container xs={12} md={8}>
                        <Grid container className="subcategoryBreadcrumb">
                          <Breadcrumbs
                            separator={<NavigateNext fontSize="small" />}
                            aria-label="breadcrumb"
                          >
                            {breadcrumbs}
                          </Breadcrumbs>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={12}
                          style={{ height: 'max-content' }}
                        >
                          <Grid xs={12} className="subcategoryBannerBox">
                            <div className="subcategoryBanner">
                              {this.state.subCategoryIcon ?
                                <LazyLoadImage
                                  className="lazyLoad"
                                  placeholderSrc={this.state.subCategoryThumb ?? solid}
                                  // effect="blur"
                                  // placeholderSrc={bgg}
                                  src={
                                    this.state.subCategoryIcon
                                  }
                                />
                                :
                                <Skeleton width="100%" animation="wave" height="530px" variant='rect' />
                              }
                              {/* <div className="imgText">The purposse of a relationship is not to have another who might complete you, but to have another with whom you might share your completeness<br /><br /><span>- Neale Donald Walsch</span></div> */}
                            </div>
                          </Grid>
                          {this.state.topicsArray &&
                            this.state.topicsArray.length > 0 ? (
                            <SubCategoryBox
                              topicsArray={this.state.topicsArray}
                              goToTopics={this.goToTopics}
                            />
                          ) : (
                            this.isLoadingDiv('topics')
                          )}
                        </Grid>
                      </Grid>
                    </>
                  );
                } else if (
                  this.state.currentLocationParams &&
                  this.state.categoriesArray.length > 0
                ) {
                  return (
                    <>
                      <Grid
                        container
                        item
                        xs={12}
                        justifyContent="center"
                        alignItems="center"
                        className="tabDiv"
                        style={{ borderBottom: '2px solid #dfdfdf' }}
                      >
                        <Grid
                          item
                          container
                          md={isLoggedIn ? 3 : 2}
                          xs={5}
                          className="boldLabel"
                        >
                          <span>Explore All</span>
                        </Grid>
                        <Grid
                          container
                          item
                          xs={isLoggedIn ? 8 : 7}
                          className="tabList"
                        >
                          {this.state.categoriesArray.map((item: any) => {
                            return (
                              <Grid
                                item
                                xs={4}
                                key={item.id}
                                className={
                                  this.state.selectedId === item.id
                                    ? 'tab active'
                                    : 'tab'
                                }
                                onClick={(e) => {
                                  e.preventDefault(), this.scrollInto(item.id);
                                }}
                              >
                                {item.attributes.name}
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        container
                        item
                        className={
                          isLoggedIn
                            ? 'responsiveForLoggedIn'
                            : 'responsiveBoxForCategories'
                        }
                      >
                        {this.state.categoriesArray.map((item: any) => {
                          return (
                            <div
                              style={{ width: '100%' }}
                              key={item.id}
                              id={item.id}
                            >
                              <Grid item className="categorySubLabel" xs={12}>
                                <span>{item.attributes.name}</span>
                              </Grid>
                              <Grid
                                item
                                style={{ marginTop: '10px' }}
                                container
                                xs={12}
                              >
                                <CategoryBox
                                  category={item}
                                  goToSubcategory={this.goToSubcategory}
                                />
                              </Grid>
                            </div>
                          );
                        })}
                      </Grid>
                    </>
                  );
                }
              }
            })()}
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} style={{ display: !isLoggedIn && !this.state.currentLocationParams.sub_id ? 'none' : 'block', }} className="scrollbarY pt0">
            <section
              className="room-types shadow"
              style={{ display: isLoggedIn ? 'block' : 'none' }}
            >
              <h4 className="fs18 fw500 mt0 mb0 room-head mt0">Room Types</h4>
              <div className="daf room-head">
                <label
                  htmlFor="standard"
                  className="fs15 fw500 dFlexAlignCenter"
                >
                  <img src={standard} alt="" className="w32 mr10" />
                  Standard
                </label>
                <input type="checkbox" name="" id="standard" />
              </div>
              <div className="daf room-head">
                <label
                  htmlFor="moderated"
                  className="fs15 fw500 dFlexAlignCenter"
                >
                  <img src={moderated} alt="" className="w32 mr10" />
                  Moderated
                </label>
                <input type="checkbox" name="" id="moderated" />
              </div>
              <div className="daf room-head">
                <label
                  htmlFor="premium"
                  className="fs15 fw500 dFlexAlignCenter"
                >
                  <img src={premium} alt="" className="w32 mr10" />
                  Premium
                </label>
                <input type="checkbox" name="" id="premium" />
              </div>
              <div className="daf plr15">
                <label
                  htmlFor="onetoone"
                  className="fs15 fw500 dFlexAlignCenter"
                >
                  <img src={onetoone} alt="" className="w32 mr10" />
                  One-to-One
                </label>
                <input type="checkbox" name="" id="onetoone" />
              </div>
            </section>
            <section
              className="border radius15 mb30 p15 shadow"
              style={{ display: isLoggedIn ? 'block' : 'none' }}
            >
              <Link
                to="/ModeratorList"
                className="fs14 colorBlue fw500 textDecorationNone"
              >
                Moderators
              </Link>
            </section>
            {!isModerator && isLoggedIn && (
              <section className="border radius15 mb30 p15 shadow">
                <span
                  className="fs14 fw500 colorBlue"
                  onClick={() => {
                    this.props.navigation.navigate('ModuleOrientation');
                  }}
                >
                  Become a Moderator
                </span>
              </section>
            )}
            {!isLoggedIn && this.state.currentLocationParams.sub_id && (
              <section
                className="border radius15 mb30 p15 shadow"
                style={{ marginTop: '78px' }}
              >
                <span
                  className="fs14 fw500 colorBlue"
                  onClick={() => {
                    this.props.navigation.navigate('ModuleOrientation');
                  }}
                >
                  Become a Moderator
                </span>
              </section>
            )}
          </Grid>
        </Grid>
        <Hidden smUp>
                <Box
                  id="bottomComponent"
                  style={{
                    position: 'fixed',
                    bottom: 0,
                    width: '100%',
                    zIndex: 1200,
                  }}
                  sx={{
                    bgcolor: !this.state.selectedFilter
                      ? '#fff'
                      : 'rgba(0, 0, 0, 0)',
                    border: '1px solid #E0E0E0',
                    boxShadow: !this.state.selectedFilter
                      ? '0px 0px 10px rgba(0, 0, 0, 0.25)'
                      : 'none',
                  }}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {this.state.selectedFilter === null && (
                    <>
                     <Box
                        display="flex"
                        alignItems="center"
                        sx={{ borderRight: '1px solid #E0E0E0',padding: '1.2rem .7rem' }}
                        onClick={() => 
                          this.handleChangeMobileFilter('room_type')
                        }
                      >
                        <Typography
                          style={{
                            color: '#27272A',
                            fontSize: '.9rem',
                            fontWeight: 500,
                          }}
                        >
                          Room Types
                        </Typography>
                        <ExpandLessIcon style={{ fontSize: 15 }} />
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{
                          borderRight: '1px solid #E0E0E0',
                          padding: '1.2rem .7rem',
                        }}
                        onClick={() => 
                          this.props.navigation.navigate('ModeratorList')
                          // this.handleChangeMobileFilter('categories')
                        }
                      >
                        <Typography
                          style={{
                            color: '#27272A',
                            fontSize: '.9rem',
                            fontWeight: 500,
                          }}
                        >
                          Moderators
                        </Typography>
                        <ArrowForwardIosIcon style={{ fontSize: 12 }} />
                      </Box>

                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{
                          borderRight: '1px solid #E0E0E0',
                          padding: '1.2rem .7rem',
                        }}
                        onClick={() => 
                        this.props.navigation.navigate('ModuleOrientation')
                        }
                      >
                        <Typography
                          style={{
                            color: '#27272A',
                            fontSize: '.9rem',
                            fontWeight: 500,
                          }}
                        >
                           Become a Moderator
                        </Typography>
                        <ArrowForwardIosIcon style={{ fontSize: 12 }} />
                      </Box>
                    </>
                  )}

                  {this.state.selectedFilter && (
                    <>
                      <div
                        style={{
                          position: 'fixed',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100dvh',
                          backgroundColor: 'rgba(0, 0, 0, 0.6)',
                          zIndex: 1200,
                          backdropFilter: 'blur(4px)',
                        }}
                        onClick={() => this.handleChangeMobileFilter(null)}
                      />
                      <Draggable
                        axis="y"
                        bounds={{ top: 0 }}
                        onStop={this.handleDrag}
                        position={this.state.defaultPosition}
                        handle=".drag-handle"
                      >
                        <Slide direction="up" in={!!this.state.selectedFilter}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            height='auto'
                            zIndex={1300}
                            flex={1}
                            bgcolor="#fff"
                            style={{
                              borderTopLeftRadius: 20,
                              borderTopRightRadius: 20,
                            }}
                          >
                            <div style={{ height: '90%', width: '100%' }}>
                              <Grid item xs={12} sm={12} md={3} lg={3} style={{ display: !isLoggedIn && !this.state.currentLocationParams.sub_id ? 'none' : 'block', }} className="pt0">
                                <section
                                  className="room-types shadow"
                                  style={{ display: isLoggedIn ? 'block' : 'none' }}
                                >
                                  <h4 className="fs18 fw500 mt0 mb0 room-head mt0">Room Types</h4>
                                  <div className="daf room-head">
                                    <label
                                      htmlFor="standard"
                                      className="fs15 fw500 dFlexAlignCenter"
                                    >
                                      <img src={standard} alt="" className="w32 mr10" />
                                      Standard
                                    </label>
                                    <input type="checkbox" name="" id="standard" />
                                  </div>
                                  <div className="daf room-head">
                                    <label
                                      htmlFor="moderated"
                                      className="fs15 fw500 dFlexAlignCenter"
                                    >
                                      <img src={moderated} alt="" className="w32 mr10" />
                                      Moderated
                                    </label>
                                    <input type="checkbox" name="" id="moderated" />
                                  </div>
                                  <div className="daf room-head">
                                    <label
                                      htmlFor="premium"
                                      className="fs15 fw500 dFlexAlignCenter"
                                    >
                                      <img src={premium} alt="" className="w32 mr10" />
                                      Premium
                                    </label>
                                    <input type="checkbox" name="" id="premium" />
                                  </div>
                                  <div className="daf plr15">
                                    <label
                                      htmlFor="onetoone"
                                      className="fs15 fw500 dFlexAlignCenter"
                                    >
                                      <img src={onetoone} alt="" className="w32 mr10" />
                                      One-to-One
                                    </label>
                                    <input type="checkbox" name="" id="onetoone" />
                                  </div>
                                </section>
                              </Grid>
                            </div>
                          </Box>
                        </Slide>
                      </Draggable>
                    </>
                  )}
                </Box>
              </Hidden>
      </>
    );
  }
}
//@ts-ignore
export default withRouter(CategoriesWeb);
// Customizable Area End