// Customizable Area Start
import React, { Component } from 'react';
import CustomizableModalWindow from './customizableModalWindow.web';
import { Card, Typography, Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import './Modals.css';
import { AlertBottomSheet } from './BottomSheet.web';

interface Props {
	feedbackData: any;
	history: any;
	endCallForUser: () => void;
	isModerated: boolean;
	isMobile: boolean;
}

interface S {
	sec: number;
	ontime: boolean;
}

class MustLeaveModal extends Component<Props, S>{
	state = { sec: 10, ontime: true };

	componentDidMount() {
		const timer = setInterval(() => {
			if (this.state.sec >= 1) this.setState({ sec: this.state.sec - 1 });
			if (this.state.sec < 1 && this.state.ontime) {
				this.setState({ ontime: false }, () => {
					this.endRoomForUser();
				})
			}
		}, 1000);

		return () => clearInterval(timer);
	}

	endRoomForUser = () => {
		this.props.endCallForUser();
	}

	render() {
		if (this.props.isMobile) {
			return (
				<CustomizableModalWindow id='must-leave-window' open={true}>
					<AlertBottomSheet type='exit'>
						<Box>
							<Box textAlign='center' mt={1} display='flex' alignItems='center' flexDirection='column'>
								<Typography style={{ color: '#ccc' }}>
									{this.props.isModerated ? "Moderator" : "Two participants"} have asked you to leave.You will be removed in:
								</Typography>

								<Box
									sx={{
										mt: 2,
										mb: 2,
										border: '1px solid #E0E0E0',
										p: 1,
										borderRadius: 10,
										boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
									}}
								>
									<Typography
										style={{
											color: '#010039',
											fontWeight: 600,
											wordBreak: 'break-word',
										}}
									>
										{`00:${this.state.sec < 10 ? '0' : ''}${this.state.sec}`}
									</Typography>
								</Box>
							</Box>

							<Box flex={1} mt={1}>
								<div style={{
									border: '1px solid #FF0100',
									padding: '1rem 3.5rem',
									background: '#FF0100',
									color: '#fff',
									borderRadius: '.5rem',
									fontWeight: 500,
									textAlign: 'center'
								}}
									onClick={this.endRoomForUser}
								>
									Leave
								</div>
							</Box>
						</Box>
					</AlertBottomSheet>
				</CustomizableModalWindow>
			)
		}

		return (
			<CustomizableModalWindow id='must-leave-window' open={true}>
				<Card className='red-card-modal'>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center'
						}}
					>
						<Typography
							style={{ fontSize: '1.1rem' }}
							color='textSecondary'
							variant='h6'
						>
							{this.props.isModerated ? "Moderator" : "Two participants"} have asked you to leave.You will be removed in:
						</Typography>
						<Typography
							style={{ margin: '8px 0' }}
							color='textSecondary'
							variant='h6'
						>
							<span>{`00:${this.state.sec < 10 ? '0' : ''}${this.state.sec}`}</span>
						</Typography>
						<Button onClick={this.endRoomForUser} id='leave-button-modal'>Leave</Button>
					</div>
				</Card>
			</CustomizableModalWindow>
		);
	}
}
// @ts-ignore
export default MustLeaveModal;

// Customizable Area End
