import React, { Component } from 'react'

export default class comingSoon extends Component {

  render() {
    return (
      <div>
        <h2>Coming Soon</h2>
     </div>
    )
  }
}
