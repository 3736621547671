
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { string } from "yup";

// Customizable Area Start
import React from "react";
export const configJSON = require("../config");

export interface Props {
  navigation: any;
  step1Redirect: () => void;
  id: string;
}

type LoginChoices = "Instagram" | "Facebook" | "Twitter" | "LinkedIn";

interface S {
  countryList: any[],
  years: any,
  subCategoriesList: any[],
  getSaveMyProgressData: any[],
  oldSaveMyProgressData: any,
  socialMedia: {
    links: any[];
    added: any[];
    count: number;
  },
  socialConfirm: {
    isOpen: boolean;
    type: LoginChoices | null;
  },
  hasError: string,
  saveMyProgressModal: boolean;
  isMessagePop: boolean,
}

interface SS {
  id: any;
}

export default class OnboardingDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  saveModeratorDataId: any;
  countryListId: string
  subCategoriesListId: string
  socialMediaRef: React.RefObject<HTMLDialogElement>;
  myRef: React.RefObject<HTMLDialogElement>;

  AllSubCategoryId: string;
  saveMyProgressId: string;
  getOldSaveMyProgressId: string;
  getUserProfileDataId: string
  msg = ""
  type = ""
  formRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    const year = (new Date()).getFullYear();
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      countryList: [],
      years: Array.from(new Array(100), (val, index) => year - index),
      subCategoriesList: [],
      socialMedia: { links: ['Twitter', 'LinkedIn', 'Facebook', 'Instagram'], added: [], count: 1 },
      socialConfirm: {
        isOpen: false,
        type: null
      },
      hasError: "",
      saveMyProgressModal: false,
      getSaveMyProgressData: [],
      oldSaveMyProgressData: [],
      isMessagePop: false,
      // Customizable Area End
    };
    // Customizable Area Start
    this.formRef = React.createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (this.countryListId === apiRequestCallId) {
          this.setState({
            countryList: responseJson.data
          })
        }
        if (this.AllSubCategoryId === apiRequestCallId) {
          this.setState({
            subCategoriesList: responseJson.data
          })
        }
        if (this.saveModeratorDataId === apiRequestCallId) {
          if (responseJson?.data) {
            localStorage.setItem("isModerator", responseJson && responseJson.data &&
              responseJson.data.attributes &&
              responseJson.data.attributes?.is_moderator || false);
            this.props.navigation.navigate('HomePagePublic', { step: 4 })
          }
          else if (responseJson.errors) {
            if (responseJson.errors.message) {
              this.handleAlert(responseJson.errors.message)
            }
            else {
              this.handleAlert("Some error occured please try again later")
            }

          }
        }
        if (this.saveMyProgressId === apiRequestCallId) {
          if (responseJson.data) {
            this.setState({
              getSaveMyProgressData: responseJson.data
            })
            setTimeout(() => {
              this.handleAlertSuccess("Moderator Addtional Detail Saved Successfully!");
              this.changeModalStatus(true)
            }, 500);

          }
          else if (responseJson.errors) {
            if (responseJson.errors.message) {
              this.handleAlert(responseJson.errors.message)
            }
            else {
              this.handleAlert("Some error occured please try again later")
            }
          }
          // this.props.navigation.navigate('OnboardingSuccessful')
        }
        if (this.getOldSaveMyProgressId === apiRequestCallId) {
          responseJson.data.attributes.moderator_user_sub_categories = responseJson.data?.attributes?.moderator_user_sub_categories?.map((item: any) => {
            return [item.name, item.sub_category_id]
          })



          // let addedData = responseJson?.data?.attributes?.moderator_profile[0]?.socifal_accounts?.map((item: any) => ({ type: item.social_type, value: { userLink: item.social_url } }))
          let addedData = responseJson?.data?.attributes?.moderator_profile[0]?.socifal_accounts?.map((item: any) => ({ type: item.social_type, value: item.social_url ?? '' }))
          this.setState({ oldSaveMyProgressData: responseJson.data })
          if (addedData) {
            this.setState({ socialMedia: { links: this.state.socialMedia.links, added: addedData, count: addedData.length } })
          }

        }
        if (this.getUserProfileDataId === apiRequestCallId) {

          responseJson.data.attributes.moderator_user_sub_categories = responseJson.data?.attributes?.moderator_user_sub_categories?.map((item: any) => {
            return [item.name, item.sub_category_id]
          })
          let addedData = null

          if (responseJson.data.attributes?.moderator_profile && responseJson.data.attributes?.moderator_profile.length > 0) {
            addedData = responseJson?.data?.attributes?.moderator_profile[0]?.social_accounts?.map((item: any) => ({ type: item?.social_type, value: item?.social_url ?? '' }))
            // addedData = responseJson?.data?.attributes?.moderator_profile[0]?.social_accounts?.map((item: any) => ({ type: item?.social_type, value: {userLink: item?.social_url } }))
            this.setState({ oldSaveMyProgressData: responseJson.data })
          } else {
            this.setState({ oldSaveMyProgressData: null })
          }

          if (addedData) {
            this.setState({ socialMedia: { links: this.state.socialMedia.links, added: addedData, count: addedData.length } })
          }
        }


      }


    }

    // Customizable Area End
  }


  // Customizable Area Start
  handleAlert(msg = "") {
    this.msg = msg
    this.type = "error"
    this.setState({ isMessagePop: true })
  }

  handleAlertSuccess(msg = "") {
    this.msg = msg
    this.type = "success"
    this.setState({ isMessagePop: true })
  }

  getCountryCode() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      // "Access-Control-Allow-Origin": "*"
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryListId = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCountryApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getSubCategories() {

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      // "Access-Control-Allow-Origin": "*"
      "token": localStorage.getItem("token")
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.AllSubCategoryId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllSubcategoriesAPIEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

  }

  saveOnboardingData(formData: any) {
    const headers = {
      "Content-Type": undefined,
      "token": localStorage.getItem("token")
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.saveModeratorDataId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${localStorage.getItem("userId")}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePut
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    )
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  saveProgressOnboardingData(formData: any) {
    const headers = {
      "token": localStorage.getItem("token")
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.saveMyProgressId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/save_my_progress`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePatch
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    )
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getOldSaveMyProgresData() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("token")
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOldSaveMyProgressId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOldSavedAdditionalModeratorAPIEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getUserProfileData(param: any) {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("token")
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserProfileDataId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserProfileDataAPIEndPoint + param
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  // handle social media logins
  addSocialLogin = () => {
    if (this.state.socialMedia.added.length === this.state.socialMedia.count) {
      this.setState((prev) => ({ socialMedia: { ...prev.socialMedia, count: prev.socialMedia.count + 1 } }), () => {
        this.formRef.current.setFieldValue('socialMedia', this.state.socialMedia)
      });
    } else {
      alert("Please add a social media handle first; after that, you can add another one.")
    }
  }

  removeSocialLogin = (index: number) => {
    const tempArr = [...this.state.socialMedia.added];
    tempArr.splice(index, 1);
    this.setState(prev => ({ socialMedia: { ...prev.socialMedia, count: prev.socialMedia.count - 1, added: tempArr } }), () => {
      this.formRef.current.setFieldValue('socialMedia', this.state.socialMedia)
    });
  }

  openSocialMedia = (type: LoginChoices, index: number) => {
    const tempArr = [...this.state.socialMedia.added];
    tempArr[index] = { type, value: "" };
    // tempArr[index] = { type, value: {} };

    this.setState(prev => ({
      socialMedia: {
        ...prev.socialMedia, added: tempArr
      }
    }), () => {
      this.formRef.current.setFieldValue('socialMedia', this.state.socialMedia)
      // if (type.toLowerCase() !== 'facebook' && type.toLowerCase() !== 'linkedin') {
      //   this.setState(prev => ({ socialConfirm: { type, isOpen: true } }), () => {
      //     this.formRef.current.setFieldValue('socialMedia', this.state.socialMedia)
      //   });
      // } else {
      //   this.formRef.current.setFieldValue('socialMedia', this.state.socialMedia)
      // }
    }
    );
  }

  closeSocialMedia = (method: LoginChoices, type: "cancel" | "done") => {
    if (type === "cancel") {
      const indx = this.state.socialMedia.added.findIndex((each) => each?.type === method);
      if (indx > -1) {
        const newRecords = [...this.state.socialMedia.added];
        newRecords.splice(indx, 1);
        this.setState(prev => ({ socialMedia: { ...prev.socialMedia, added: newRecords } }), () => {
          this.formRef.current.setFieldValue('socialMedia', this.state.socialMedia)
        });
      }
    }

    this.setState(prev => ({ socialConfirm: { ...prev.socialConfirm, isOpen: false } }), () => {
      this.formRef.current.setFieldValue('socialMedia', this.state.socialMedia)
    });
  }

  // update user token
  updateToken = (type: LoginChoices, details: any) => {
    if (this.state.hasError) this.setState({ hasError: "" });

    localStorage.setItem(`${type}`, JSON.stringify(details));

    const tempArr = [...this.state.socialMedia.added];
    const index = tempArr.findIndex((each) => each.type === type);
    tempArr[index] = { type, value: details };

    this.setState(prev => ({ socialMedia: { ...prev.socialMedia, added: tempArr } }), () => {
      this.formRef.current.setFieldValue('socialMedia', this.state.socialMedia)
    });

    this.closeSocialMedia(type, "done");
  }

  changeModalStatus = (condition: boolean) => {
    this.setState({ saveMyProgressModal: condition })
    if (condition === false) {
      this.props.step1Redirect()
    }
  }
  // Customizable Area End
}

// Customizable Area End