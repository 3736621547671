export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");
export const calendar = require("../assets/calendar.png");
export const fam = require("../assets/fam.png");
export const yellowheart = require("../assets/yellowheart.png");
export const as = require("../assets/as.png");
export const plle = require("../assets/plle.png");
export const sharef = require("../assets/sharef.png");
export const share = require("../assets/share.png");
export const bgg = require("../assets/bgg.png");
export const backarrow = require("../assets/backarrow.png");
export const peeenc = require("../assets/peeenc.png");
export const thumb = require("../assets/thumb.png");
export const clock = require("../assets/clock.png");
export const standard = require("../assets/standard.png");
export const moderated = require("../assets/moderated.png");
export const premium = require("../assets/premium.png");
export const onetoone = require("../assets/onetoone.png");
export const watch = require("../assets/watch.png");
export const hourglass = require("../assets/hourglass.png");
export const cactus = require("../assets/cactus.png")
export const GoldenTick = require("../assets/GoldenTick.svg")
export const BlueTick = require("../assets/BlueTick.svg")
export const noRoomImg = require('../assets/noRoomImg.png');
export const images = require("../assets/images.png")
export const solid = require("../assets/solid-color-image.png")


