import React, { useEffect, useState } from 'react';
// Customizable Area Start
import RoomsController from '../MyRooms/RoomsController';
import {
  Accordion, AccordionSummary, AccordionDetails, Box, Typography
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import ExpandMoreIcon from '@material-ui/icons/ArrowForwardIos';
import { clock } from '../assets';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import './styles.css';
import Tutorials from '../MyRooms/Tutorials.web';

import DateFnsUtils from '@date-io/date-fns';
import moment, { Moment } from 'moment';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FFCB00',
    },
    secondary: {
      main: '#010039',
    },
  },
});

export const RoomTimesData = [
  { key: 'next_4_hours', value: 'Next 4 hours' },
  { key: 'today', value: 'Today' },
  { key: 'tomorrow', value: 'Tomorrow' },
  { key: 'next_week', value: 'Next Week' },
];

const CustomAccordionDetails = ({
  topic,
  handleSearch,
  elemId,
  isChecked,
  loading,
  cat_id,
}: any) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  return (
    <AccordionDetails key={topic.id}>
      <div className="dFlexAlignCenter">
        <label className="fs14 fw500 mt0 ml10 dFlexAlignCenter">
          <input
            type="checkbox"
            className="mr10"
            name="sub_category"
            onChange={(e) => {
              if (loading) {
                alert('Please wait while we are fetching the previous request.');
                return;
              }
              handleSearch(Number(elemId), Number(topic?.id), Number(cat_id));
              setChecked(e.target.checked);
            }}
            checked={checked}
          />
          {topic.name}
        </label>
      </div>
    </AccordionDetails>
  );
};

class Categories extends RoomsController {
  constructor(props: any) {
    super(props);
  }

  async componentDidMount() {
    if (!this.props.categories.length) this.props.fetchCategories();

    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  async componentWillUnmount(): Promise<void> {
    window.removeEventListener('resize', this.handleResize);
  }

  changeTime = (time: any, type: 'start_time' | 'end_time') => {
    const currentTimeString = moment().format('h:mm:ss a');
    const currentTime = moment(currentTimeString, 'h:mm:ss a');
    const userSelectdTime = moment(time);

    if (userSelectdTime.isBefore(currentTime)) {
      alert('Please select a time in the future');
      return;
    }

    if (
      type === 'end_time' &&
      userSelectdTime.isBefore(this.state.dateTime.start_time)
    ) {
      alert('End time should be greater than start time');
      return;
    }

    if (!userSelectdTime.isValid()) return;

    this.setState(
      (prev) => ({
        ...prev,
        dateTime: {
          ...this.state.dateTime,
          [type]: userSelectdTime,
          date: prev.dateTime.date
            ? prev.dateTime.date
            : moment().format('YYYY-MM-DD'),
        },
      }),
      () => this.props.handleRoomTime('custom', this.state.dateTime)
    );
  };

  updatedDate = (fullDateObj: string, current_time: Moment) => {
    const newDate = moment(fullDateObj);

    const updatedDateTime = current_time.clone().set({
      year: newDate.year(),
      month: newDate.month(),
      date: newDate.date(),
    });

    return updatedDateTime;
  };

  renderCategoriesSkeleton = () => {
    return (
      <>
        {this.props.isCatLoading && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ p: 1 }}
          >
            {Array(3)
              .fill(0)
              .map((_, i) => (
                <Skeleton key={i} animation="wave" width={110} height={35} />
              ))}
          </Box>
        )}
      </>
    );
  };

  renderCategories = () => {
    const {
      categories,
      isCatLoading,
      selectedCat,
      handleCatId,
      getCategories,
    } = this.props;

    return (
      <>
        {!isCatLoading && !!categories.length && (
          <Tutorials
            current={this.context.tutorials.count}
            data={this.context.tutorials.data}
            onNext={this.context.onNextTutorial}
            isOpen={
              this.context.tutorials.isOpen &&
              this.context.tutorials.count === 0
            }
            placement="right"
            {...this.props}
          >
            <div className="btn-groups daf mb15">
              {categories?.map((cate: any) => {
                const ID = Number(cate.id);
                return (
                  <span
                    className={`${selectedCat === ID && 'bgYellow'
                      } fs14 fw500 pointer`}
                    onClick={() => {
                      if (selectedCat !== ID) {
                        handleCatId(ID);
                        this.setState({ panelID: null }, () => {
                          getCategories(ID);
                        });
                      }
                    }}
                    key={cate.id}
                  >
                    {cate?.attributes?.name}
                  </span>
                );
              })}
            </div>
          </Tutorials>
        )}
      </>
    );
  };

  renderSubCategoriesSkeleton = () => {
    const { isCatLoading, loadingTopics } = this.props;
    const { isMobile } = this.state;

    return (
      <>
        {(isCatLoading || loadingTopics) && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
            sx={{ p: 1 }}
          >
            {Array(isMobile ? 18 : 8)
              .fill(0)
              .map((_, i) => (
                <Skeleton key={i} animation="wave" width="100%" height={35} />
              ))}
          </Box>
        )}
      </>
    );
  };

  renderSubCategoriesAndTopics = () => {
    const { panelID } = this.state;
    const {
      selectedCat,
      handleSearch,
      isFilterLoading,
      handleFilter,
      getCategoriesData,
      loadingTopics,
      isCatLoading
    } = this.props;

    return (
      <>
        {(!!getCategoriesData.length && !loadingTopics && !isCatLoading) && (
          <section
            className={`accord mb10`}
            style={{
              pointerEvents:
                (!this.state.isMobile && isFilterLoading) ? 'none' : 'auto'
            }}
          >
            {getCategoriesData?.map((elem: any) => {
              const isModerator = JSON.parse(
                localStorage.getItem('isModerator') as string
              );
              return (
                <React.Fragment key={elem.id}>
                  <Accordion
                    id="demo"
                    expanded={panelID === elem.id || handleFilter(elem.id)}
                    onChange={() => this.handlePanel(elem.id)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <p className="fs14 fw500 mt0 mb0">
                        {loadingTopics
                          ? '............'
                          : elem?.attributes?.name ?? ''}
                      </p>
                    </AccordionSummary>

                    {elem?.attributes?.topics.map(
                      (topic: any, index: number) => {
                        const isTopicChecked = handleFilter(elem.id, topic.id);

                        if (!isModerator && topic.moderator_only) {
                          return null;
                        }

                        return (
                          <CustomAccordionDetails
                            key={`${topic.id}+${index}`}
                            topic={topic}
                            handleSearch={handleSearch}
                            elemId={elem.id}
                            isChecked={isTopicChecked}
                            loading={this.state.isMobile && isFilterLoading}
                            cat_id={selectedCat}
                          />
                        );
                      }
                    )}
                  </Accordion>
                </React.Fragment>
              );
            })}
          </section>
        )}
      </>
    );
  };

  renderNoSubCategories = () => {
    const { getCategoriesData, loadingTopics, isCatLoading } = this.props;

    return (
      <>
        {!isCatLoading && !loadingTopics && !getCategoriesData.length && (
          <Box minHeight={300} sx={{ p: 1 }}>
            <Typography className="fs14 fw500 mt0 mb0">
              No subcategories found.
            </Typography>
          </Box>
        )}
      </>
    );
  };

  renderRoomTimes = () => {
    return (
      <div className="room-times-container">
        {RoomTimesData.map((elem) => {
          return (
            <div className="daf data bBottom" key={elem.key}>
              <label className="fs14 fw500" htmlFor={elem.key}>
                {elem.value}
              </label>
              <input
                type="checkbox"
                checked={this.props.room_times.selected_room === elem.key}
                id={elem.key}
                name={elem.key}
                onChange={() => this.props.handleRoomTime(elem.key, null)}
              />
            </div>
          );
        })}
        <div
          className={`daf data`}
        >
          <label className="fs14 fw500" htmlFor={'custom'}>
            Custom
          </label>
          <input
            type="checkbox"
            checked={this.props.room_times.selected_room === 'custom'}
            id={'custom'}
            // name={elem.key}
            onChange={(e) => {
              this.props.handleRoomTime('custom', null);
              this.setState({
                dateTime: {
                  date: '',
                  start_time: moment().startOf('day'),
                  end_time: moment().endOf('day'),
                },
              });
            }}
          />
        </div>
        {this.props.room_times.selected_room === 'custom' && (
          <div className="data bBottom">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <p
                  className="fs18 fw600 fw500 mt0 mb10"
                  style={{ alignSelf: 'flex-start' }}
                >
                  {moment().format('MMMM YYYY')}
                </p>

                <div className="rooms-details-calendar pb15">
                  {this.getCurrentWeekSlot().map((value: any, index: any) => {
                    return (
                      <div
                        key={value.fullDateObj || index}
                        className="text-center ml5"
                        onClick={() => {
                          this.setState(
                            {
                              dateTime: {
                                date: value?.fullDateObj,
                                start_time: this.updatedDate(
                                  value.fullDateObj,
                                  this.state.dateTime.start_time
                                ),
                                end_time: this.updatedDate(
                                  value.fullDateObj,
                                  this.state.dateTime.end_time
                                ),
                              },
                            },
                            () => {
                              this.props.handleRoomTime(
                                'custom',
                                this.state.dateTime
                              );
                            }
                          );
                        }}
                      >
                        <span className="fs12 fw500">{value?.day}</span>
                        <div
                          className={
                            this.state.dateTime.date == value?.fullDateObj
                              ? 'calen-div dateSelectedClass'
                              : 'calen-div'
                          }
                        >
                          <span className="fs16 fw500">{value?.date}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <ThemeProvider theme={theme}>
                  <KeyboardTimePicker
                    keyboardIcon={<img height="16px" src={clock} />}
                    inputVariant="outlined"
                    onChange={(time) => this.changeTime(time, 'start_time')}
                    value={this.state.dateTime.start_time}
                  />
                  <Typography component="span" style={{ margin: '5px' }}>
                    to
                  </Typography>
                  <KeyboardTimePicker
                    keyboardIcon={<img height="16px" src={clock} />}
                    inputVariant="outlined"
                    onChange={(time) => this.changeTime(time, 'end_time')}
                    value={this.state.dateTime.end_time}
                  />
                </ThemeProvider>
              </div>
            </MuiPickersUtilsProvider>
          </div>
        )}
      </div>
    );
  };

  renderWholeCategories = () => {
    const {
      renderCategoriesSkeleton,
      renderCategories,
      renderSubCategoriesSkeleton,
      renderSubCategoriesAndTopics,
      renderNoSubCategories,
    } = this;

    return (
      <section className={`categories-aside mb30 ${!this.state.isMobile ? 'shadow' : ''}`}>
        <div className="daf p15 categories-head">
          <p className="fs18 fw500 mt0 mb0 drag-handle" style={{ flex: 1 }}>Categories</p>
          <p
            className="fs18 fw500 mt0 mb0 pointer"
            onClick={() => {
              this.props?.navigation?.navigate('CategoriessubcategoriesWeb');
            }}
          >
            Explore all
          </p>
        </div>

        {renderCategoriesSkeleton()}

        {renderCategories()}

        {renderSubCategoriesSkeleton()}

        {renderSubCategoriesAndTopics()}

        {renderNoSubCategories()}
      </section>
    )
  }

  renderWholeRoomTimes = () => {
    const { renderRoomTimes } = this;

    return (
      <div className={`room-times ${!this.state.isMobile ? 'shadow' : ''}`}>
        <h4 className="fs18 fw500 pb10 mt0 mb0 bBottom drag-handle">Room Times</h4>
        {renderRoomTimes()}
      </div>
    )
  }

  render() {
    const {
      renderWholeCategories,
      renderWholeRoomTimes
    } = this;
    const { isMobile } = this.state;
    const choosenFilter = sessionStorage.getItem('choosenFilter') as 'categories' | 'room_times' | '';

    return (
      <div className={`categories-container ${!isMobile ? 'ml10' : ''}`} style={{ flex: 1 }}>

        {!this.state.isMobile && (
          <>
            {/* categories */}
            {renderWholeCategories()}

            {/* Room Times */}
            {renderWholeRoomTimes()}
          </>
        )}

        {(this.state.isMobile && choosenFilter === 'categories') && (
          renderWholeCategories()
        )}

        {(this.state.isMobile && choosenFilter === 'room_times') && (
          renderWholeRoomTimes()
        )}
      </div>
    );
  }
}

export default React.memo(Categories);
