// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Hidden,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const CheckItem = styled(Grid)(({ theme }) => ({
  position: "relative",
  marginBottom: "24px",
  [theme.breakpoints.up("sm")]: {
    "&:after": {
      content: '""',
      position: "absolute",
      left: 25,
      top: 40,
      height: "calc(100% - 10px)",
      borderRight: "1px dashed transparent",
      backgroundImage:
        "linear-gradient(to bottom, #C9C9C9 40%, rgba(255, 255, 255, 0) 20%)",
      backgroundPosition: "left",
      backgroundSize: "1px 25px",
      backgroundRepeat: "repeat-y",
    },
  },
}));

type Props = {
  isStep1Completed: boolean;
  criteria: { screen: number; title: string; description: string }[];
  markStep1AsComplete: () => void;
  expandedItems: number[];
  toggleExpandedItem: (item: number, action: "ADD" | "REMOVE") => void;
};

const Step1 = (props: Props) => {
  const {
    isStep1Completed,
    criteria,
    markStep1AsComplete,
    expandedItems,
    toggleExpandedItem,
  } = props;

  return (
    <>
      <Typography style={{ fontSize: "24px", fontWeight: 600 }}>
        Welcome to the Moderator Orientation Module!
      </Typography>
      <Box pt={2}>
        {criteria.length > 0 &&
          Array.from({ length: 4 }).map((_, index) => {
            const currentElement = criteria.find(
              (item) => item.screen === index + 1
            );
            const isPresent = expandedItems.includes(index);
            return index === 3 ? (
              <Grid
                container
                spacing={2}
                id={`section-${index + 1}`}
                key={index}
              >
                <Hidden xsDown>
                  <Grid item>
                    <CheckCircleOutlineIcon
                      fontSize="large"
                      htmlColor="#C9C9C9"
                    />
                  </Grid>
                </Hidden>
                <Grid item sm={11}>
                  <Accordion
                    expanded={isPresent}
                    onChange={() =>
                      isPresent
                        ? toggleExpandedItem(index, "REMOVE")
                        : toggleExpandedItem(index, "ADD")
                    }
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography
                        style={{
                          fontSize: "21px",
                          fontWeight: 500,
                        }}
                      >
                        {currentElement?.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <style>
                        {`
                        ol { padding: revert; margin: revert; }
                        li { list-style: revert; }
                      `}
                      </style>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${currentElement?.description}`,
                        }}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            ) : (
              <CheckItem
                container
                spacing={2}
                id={`section-${index + 1}`}
                key={index}
              >
                <Hidden xsDown>
                  <Grid item>
                    <CheckCircleOutlineIcon
                      fontSize="large"
                      htmlColor="#C9C9C9"
                    />
                  </Grid>
                </Hidden>
                <Grid item sm={11}>
                  <Accordion
                    style={{ zIndex: 1 }}
                    expanded={isPresent}
                    onChange={() =>
                      isPresent
                        ? toggleExpandedItem(index, "REMOVE")
                        : toggleExpandedItem(index, "ADD")
                    }
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography
                        style={{
                          fontSize: "21px",
                          fontWeight: 500,
                        }}
                      >
                        {currentElement?.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <style>
                        {`
                        ol { padding: revert; margin: revert; }
                        li { list-style: revert; }
                      `}
                      </style>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${currentElement?.description}`,
                        }}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </CheckItem>
            );
          })}
        <Box mt={4} textAlign="center">
          <Button
            onClick={markStep1AsComplete}
            style={{
              backgroundColor: "#010039",
              width: "40%",
              color: "#fff",
              fontWeight: "bolder",
              textTransform: "capitalize",
            }}
          >
            Next
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Step1;
// Customizable Area End
