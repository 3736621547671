export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const logo = require('../assets/logo.png')
export const wallet = require('../assets/wallet.png')
export const notificationIcon = require('../assets/notification.png')
export const messageIcon = require('../assets/Message.png')
export const profileImage = require('../assets/profileImage.png')
export const peopeIcon = require('../assets/peope.png')
export const plusIcon = require('../assets/plusIcon.png')
export const searchIcon = require('../assets/SearchIcon.png')
export const Notification_dot = require('../assets/Notification_dot.svg');
export const NoResult = require('../assets/NoResult.svg');
export const Bell = require('../assets/Bell.svg');
export const Message = require('../assets/Message.svg');
export const Circle = require('../assets/Circle.svg');
export const blueTick = require('../assets/BlueTick.svg');
export const goldenTick = require('../assets/GoldenTick.svg');
export const otpLogo = require('../assets/otp_logo.png');
export { ReactComponent as NotificationIcon } from '../assets/notification.svg';
export { ReactComponent as GroupsIcon } from '../assets/groups.svg';
export { ReactComponent as SettingIcon } from '../assets/settings.svg';
export { ReactComponent as MessageIcon } from '../assets/mobile_message.svg';
export { ReactComponent as LogoutIcon } from '../assets/logout.svg';
export { ReactComponent as ReferHeader } from '../assets/refer_header.svg';
export { ReactComponent as ReferFooter } from '../assets/refer_footer.svg';
export const NoProfilePic = require('../assets/No_Profile_PIc.jpeg');