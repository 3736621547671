// Customizable Area Start
import React from "react";
import { Box, Button, Typography, Container, Grid } from "@material-ui/core";
import { CrossIcon, bgg } from "./assets";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Cone, successfulImg } from "./assets";
import "./styles.css";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import OnboardingguideController, {
  Props as P,
  configJSON,
} from "./OnboardingguideController";
import Header from "../../Settings5/src/settings/header.web";

type Props = {
  handleOnboardingDialogClose: () => void;
} & P;

export default class OnboardingSuccessful extends OnboardingguideController<Props> {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const userId = localStorage.getItem("userId");
    this.getUserProfileData(userId);
  }

  render() {
    console.log(this.props);
    return (
      <ThemeProvider theme={theme}>
        <Grid>
          <div style={{ position: "relative" }}>
            <img
              src={bgg}
              style={{
                position: "absolute",
                width: "35%",
                top: "-78px",
                left: "0px",
              }}
            />
            <img
              src={bgg}
              style={{
                position: "absolute",
                width: "35%",
                right: "0px",
                bottom: "-18%",
              }}
            />

            <Box style={Styles.ImgBox}>
              <img
                style={{
                  height: "270px",
                  width: "270px",
                  marginBottom: "10px",
                  borderRadius: "8px",
                  objectFit: "cover",
                }}
                src={successfulImg}
              />
            </Box>

            <Container
              maxWidth="sm"
              style={{
                textAlign: "center",
                position: "relative",
              }}
            >
              <Typography
                variant="h5"
                style={{
                  fontWeight: 500,
                  position: "relative",
                  textAlign: "center",
                  color: "#27272A",
                  fontSize: "30px",
                  padding: "20px 0px",
                }}
              >
                {" "}
                Thank you for providing additional details in your Moderator
                profile!
              </Typography>
              <p
                style={{
                  padding: "10px 0px",
                  fontSize: "15px",
                  color: "#1F1E51",
                  fontWeight: 500,
                }}
              >
                {" "}
                Your profile has been sent for
                {this.state.isReapplying ? " re" : " "}verification.
              </p>
              <Button
                variant="contained"
                style={Styles.myButton}
                onClick={() =>
                  this.props.navigation.navigate("ModuleOrientation")
                }
              >
                Moderator Orientation Module
              </Button>
              <p style={{ marginTop: "24px" }}>
                <span
                  style={{
                    textDecoration: "none",
                    fontSize: "15px",
                    color: "#98A4B0",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    this.props.navigation.navigate("UpcomingRooms")
                  }
                >
                  Go to Homepage{" "}
                </span>{" "}
              </p>
            </Container>
          </div>
        </Grid>
      </ThemeProvider>
    );
  }
}

const Styles = {
  container: {
    width: "800px",
    marginTop: "40px",
    borderRadius: "20px",
    marginLeft: "50%",
    transform: "translateX(-50%)",
    display: "grid",
    height: "90%",
    alignContent: "center",
  },
  myButton: {
    backgroundColor: "#010039",
    color: "white",
    width: "70%",
    borderRadius: "8px",
    padding: "15px",
    marginTop: "60px",
  },
  Image: {
    height: "270px",
    width: "270px",
    marginBottom: "10px",
    borderRadius: "8px",
    objectFit: "cover",
  },
  ImgBox: {
    display: "flex",
    justifyContent: "center",
  },
};
// Customizable Area End
