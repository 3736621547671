// Customizable Area Start

import React from 'react'
import Settings5Controller from '../../Settings5/src/Settings5Controller';
import CssBaseline from "@material-ui/core/CssBaseline";
import { Container, Typography } from "@material-ui/core";

import {
  Theme,
  createStyles,
  withStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import AlertBlock from "../../alert/src/Alert.web";
import HeaderNoLogin from '../../../components/src/HeaderNoLogin';

//   import '../settings/terms.css' 


const theme = createTheme({
  palette: {
    primary: {
      main: '#FFCB00',
    },
    secondary: {
      main: '#010039',
    },
  },
});

const useStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex"
    },

  }
  );

interface Props {

}


class ModeratorTermsAndPolicy extends Settings5Controller {
  constructor(props: any) {
    super(props)

  }

  handleClose() {
    this.setState({ isMessagePop: false })
  }

  async componentDidMount(): Promise<void> {
    super.componentDidMount();
    this.getModeratorTermsAndConditions();
  }


  render() {

    const displayText = this.state.policyData?.loading ? "Loading.." : this.state.policyData?.moderator ? this.state.policyData.moderator?.description : "No Data Found"

    // @ts-ignore

    return (
      <Container>
        <div className='terms-main-div'>
          <AlertBlock data={{ show: this.state.isMessagePop, close: () => { this.handleClose() }, msg: this.msg, type: this.type, vertical: "top", horizontal: "center" }} />

          <div>
            <CssBaseline />
            <ThemeProvider theme={theme}>
              <HeaderNoLogin id={this.props.id} navigation={this.props.navigation} />
            </ThemeProvider>
            <div className='terms-heading'>
              <h2>Moderator Terms and Conditions</h2>
            </div>


            <div className='terms-content' >
              {/* <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon style={{ borderRadius: "10px" }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        > */}
              {/* <Typography  className={classes.heading}>
                                Platform Terms of Use
                            </Typography> */}
              {/* </AccordionSummary>
                        <AccordionDetails> */}
              <Typography style={{ whiteSpace: 'pre-wrap' }}>
                {this.state.policyData.moderator?.description || "No Data Found"}
              </Typography>
              {/* </AccordionDetails>
                    </Accordion> */}
            </div>


          </div>
        </div>
      </Container>
    )
  }
}

export default withStyles(useStyles, { withTheme: true })(ModeratorTermsAndPolicy)


    // Customizable Area End