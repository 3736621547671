// Customizable Area Start
import React, { Component } from 'react';
import { Modal, Fade, Backdrop } from '@material-ui/core';
import './Modals.css';

interface myProps {
	id: string;
	children: React.ReactNode;
	open: boolean;
}

export default class CustomizableModalWindow extends Component<myProps> {
	render() {
		return (
			<div>
				<Modal
					id={this.props.id}
					aria-labelledby='transition-modal-extension-option'
					aria-describedby='transition-modal-extension-option'
					open={this.props.open}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500
					}}
				>
					<Fade in={this.props.open}>
						<>{this.props.children}</>
					</Fade>
				</Modal>
			</div>
		);
	}
}
// Customizable Area End
