// Customizable Area Start

import React from "react";

import { Box } from "@material-ui/core";
import RandomImages from "../CategoriesAndRoomTypes/RandomImages";
import RoomsController from "../MyRooms/RoomsController";

class Quotes extends RoomsController {

    async componentDidMount() {
        this.getHomePageSettings();
    }

    render(): React.ReactNode {
        const images: string[] = this.state.getHomePageSettings?.sub_category_images?.data
            .map((each: any) => each.attributes.portrait);

        return (
            <Box sx={{ pb: 13 }}>
                {images?.length > 0 && <RandomImages images={images} />}
            </Box>
        );
    }
}

export default React.memo(Quotes);
// Customizable Area End