// Customizable Area Start

import React from 'react';
import Settings5Controller from '../Settings5Controller';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Container } from '@material-ui/core';

import {
  Theme,
  createStyles,
  withStyles,
  createTheme,
  ThemeProvider,
} from '@material-ui/core/styles';
import AlertBlock from '../../../alert/src/Alert.web';

import '../settings/terms.css';
import HeaderNoLogin from '../../../../components/src/HeaderNoLogin';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FFCB00',
    },
    secondary: {
      main: '#010039',
    },
  },
});

const useStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
  });

interface Props { }

class Setting extends Settings5Controller {
  handleClose() {
    this.setState({ isMessagePop: false });
  }

  async componentDidMount(): Promise<void> {
    super.componentDidMount();
    this.getTermsAndConditions();
  }

  render() {
    let displayText = 'No Data Found';

    if (this.state.policyData?.loading) {
      displayText = 'Loading..';
    } else if (this.state.policyData?.terms) {
      displayText = this.state.policyData.terms?.content;
    }

    return (
      <>
        <ThemeProvider theme={theme}>
          <HeaderNoLogin
            id={this.props.id}
            navigation={this.props.navigation}
          />
        </ThemeProvider>
        <Container>
          <div style={{ marginTop: '16px' }} className="terms-main-div">
            <AlertBlock
              data={{
                show: this.state.isMessagePop,
                close: this.handleClose,
                msg: this.msg,
                type: this.type,
                vertical: 'top',
                horizontal: 'center',
              }}
            />

            <div>
              <CssBaseline />

              <div className="terms-heading">
                <h2>Terms and Conditions</h2>
              </div>

              <div className="terms-content">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${displayText}`,
                  }}
                />
              </div>
            </div>
          </div>
        </Container>
      </>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(Setting);

// Customizable Area End
