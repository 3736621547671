// Customizable Area Start
import React from "react";
import { Formik, Field, FieldArray } from "formik";

import {
  Box,
  Button,
  Grid,
  FormControl,
  Typography,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  Chip,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import AlertBlock from "../../../alert/src/Alert.web";
import Alert from "@material-ui/lab/Alert";

import "./styles.css";
import { ples, cross } from "../assets";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import DropDown from "../../../email-account-registration/src/EmailRegister/Dropdown.web";
import * as Yup from "yup";
import ConfirmationModal from "./ConfirmationModal.web";
import SaveMyProgres from "./SaveMyProgressModal";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import OnboardingDetailsController, {
  Props,
  configJSON,
} from "./OnboardingDetailsController";
import Header from "../../../Settings5/src/settings/header.web";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { AnyARecord } from "dns";

type RemoveFunction = (index: number) => void;
type PushFunction = (value: any) => void;

export default class OnboardingDetails extends OnboardingDetailsController {
  constructor(props: Props) {
    super(props);
    this.socialMediaRef = React.createRef();
  }
  async componentDidMount(): Promise<void> {
    this.getCountryCode();
    this.getSubCategories();
    this.getUserProfileData(localStorage.getItem("userId"));
    let formikValues: any = this.formRef.current;
    if (formikValues) {
      setTimeout(() => {
        formikValues.setFieldValue("socialMedia", this.state.socialMedia);
      }, 3000);
    }
  }

  handleCloseAlert = () => {
    this.setState({ isMessagePop: false });
  };

  onSubmit = (data: any) => {
    console.log(data);
  };

  FormValidation: any = Yup.object().shape(
    {
      accountNumber: Yup.string()
        .matches(/^[0-9]+$/, "Account number Must be only digits")
        .matches(/^\d{9,18}$/, "Please fill a valid bank account number")
        .max(19, "Should be a 19 digit account number"),

      ifscCode: Yup.string()
        .matches(
          /^[aA-zZ0-9]+$/,
          "Only letters, number are allowed for this field "
        )
        .matches(/^[A-Za-z]{4}[a-zA-Z0-9]{7}$/, "Please fill a valid IFSC code")
        .max(11, "Should be a 11 digit ifscCode"),

      accountHolder: Yup.string().matches(
        /^[aA-zZ ]+$/,
        "Please enter the name of the account holder"
      ),

      panNumber: Yup.string()
        .matches(
          /^[aA-zZ0-9]+$/,
          "Only letters, number are allowed for this field "
        )
        .matches(
          /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
          "Please fill a valid PAN number "
        )
        .max(10, "Should be a 10 digit PAN Number"),

      gstNumber: Yup.string()
        .matches(
          /^[aA-zZ0-9]+$/,
          "Only letters, number are allowed for this field "
        )
        .matches(
          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
          "Please enter valid GST Number "
        )
        .max(15, "Should be a 15 digit GST Number"),

      bio: Yup.string().required("Please add your bio"),
      // .matches(
      //   /^[A-Za-z0-9 _,]*[A-Za-z0-9][A-Za-z0-9 _,]*$/,
      //   'Only letters, number are allowed for this field '
      // ),

      subcategories: Yup.array()
        .max(3, "A maximum of 3 subcategories can be selected")
        .required("Please select 3 subcategories"),

      personalexperience: Yup.string()
        .min(50, "Experience should contain at least 50 characters")
        .max(2000, "Personal information must be less then 2000 characters")
        .when("certification", {
          is: (value) => {
            if (value) {
              if (
                value[0]?.institute &&
                value[0]?.passingYear &&
                value[0]?.course
              ) {
                return false;
              } else {
                return true;
              }
            } else {
              return true;
            }
          },
          then: Yup.string().required(
            "Please add personal experience or relevant training/certifications."
          ),
          // .matches(
          //   /^[aA-zZ.0-9 ]+$/,
          //   'Only letters, number are allowed for this field '
          // ),
        }),
      socialMedia: Yup.object().shape({
        added: Yup.array()
          .of(
            Yup.object().shape({
              value: Yup.string()
                .required("This field is required.")
                .matches(
                  /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?$/,
                  "Please enter valid URL "
                ),
              // Yup.object().shape({
              //   userLink: Yup.string()
              //   .matches(/^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?$/, 'Please enter valid URL ')
              //   .min(1, 'This field is required.')
              // })
            })
          )
          .required("Please link your social media account"),
      }),
      certification: Yup.array()
        .of(
          Yup.object().shape({
            institute: Yup.string(),
            passingYear: Yup.string().nullable(),
            course: Yup.string(),
          })
        )
        .when("personalexperience", {
          is: (value) => {
            return !value;
          },
          then: Yup.array().of(
            Yup.object().shape({
              institute: Yup.string()
                .required("Please select your institute name")
                .matches(
                  /^[aA-zZ.0-9 ]+$/,
                  "Only letters, number are allowed for this field "
                ),
              passingYear: Yup.string().required(
                "Please select the year you passed the institute"
              ),
              course: Yup.string()
                .required("Please select your certification")
                .matches(
                  /^[aA-zZ.0-9 ]+$/,
                  "Only letters, number are allowed for this field "
                ),
              documents: Yup.array().required("Please provide a document."),
            })
          ),
        }),
    },
    [["personalexperience", "certification"]]
  );

  CreateFormDataPayload = (values: any) => {
    const formData = new FormData();

    formData.append("type", "moderator_profile_data");
    formData.append("attributes[bio]", values.bio);

    values.personalexperience &&
      formData.append("attributes[personal_exp]", values.personalexperience);

    // const subCategoriesId = values.subcategories.map( name:any => this.state.subCategoriesList.find( category=> category.name ) )
    values.subcategories.forEach((item: any) => {
      formData.append("attributes[moderator_sub_categories][]", item[1]);
    });

    values.certification &&
      values.certification.forEach((item: any) => {
        formData.append(
          "attributes[training][][institute_name]",
          item.institute
        );
        formData.append(
          "attributes[training][][passing_year]",
          item.passingYear
        );
        formData.append("attributes[training][][courses][][name]", item.course);
        console.log("item.files", item.files);

        if (item.documents.length > 0) {
          item.documents.forEach((itm: any) => {
            formData.append(
              "attributes[training][][courses][][documents]",
              itm || ""
            );
          });
        }
        // item.trainingId && formData.append("attributes[training][][id]", item.trainingId)
      });

    this.state.socialMedia.added.forEach((item: any) => {
      formData.append("attributes[social_accounts][][social_type]", item.type);
      formData.append("attributes[social_accounts][][social_url]", item?.value);
      // formData.append("attributes[social_accounts][][social_url]", item?.value?.userLink || item?.value?.accessToken)
    });

    const isAllDone: boolean =
      values.bankName.length > 0 &&
      values.accountNumber.length > 0 &&
      values.ifscCode.length > 0 &&
      values.accountHolder.length > 0 &&
      values.panNumber.length > 0;

    let isDataChanged: boolean = false;

    if (
      values.bankName.length > 0 &&
      this.state?.oldSaveMyProgressData?.attributes?.moderator_profile[0]
        ?.bank_details[0]?.bank_name !== values.bankName
    )
      isDataChanged = true;
    else if (
      values.accountNumber.length > 0 &&
      this.state?.oldSaveMyProgressData?.attributes?.moderator_profile[0]
        ?.bank_details[0]?.account_no !== values.accountNumber
    )
      isDataChanged = true;
    else if (
      values.ifscCode.length > 0 &&
      this.state?.oldSaveMyProgressData?.attributes?.moderator_profile[0]
        ?.bank_details[0]?.ifsc_code !== values.ifscCode
    )
      isDataChanged = true;
    else if (
      values.accountHolder.length > 0 &&
      this.state?.oldSaveMyProgressData?.attributes?.moderator_profile[0]
        ?.bank_details[0]?.ac_holder_name !== values.accountHolder
    )
      isDataChanged = true;
    else if (
      values.panNumber.length > 0 &&
      this.state?.oldSaveMyProgressData?.attributes?.moderator_profile[0]
        ?.bank_details[0]?.pan_number !== values.panNumber
    )
      isDataChanged = true;
    else if (
      this.state?.oldSaveMyProgressData?.attributes?.moderator_profile[0]
        ?.bank_details[0]?.gst_number !== values.gstNumber
    )
      isDataChanged = true;
    else if (
      this.state?.oldSaveMyProgressData?.attributes?.moderator_profile[0]
        ?.bank_details[0]?.country !== values.country
    )
      isDataChanged = true;
    else if (
      values.state.length > 0 &&
      this.state?.oldSaveMyProgressData?.attributes?.moderator_profile[0]
        ?.bank_details[0]?.state !== values.state
    )
      isDataChanged = true;
    else if (
      values.city.length > 0 &&
      this.state?.oldSaveMyProgressData?.attributes?.moderator_profile[0]
        ?.bank_details[0]?.city !== values.city
    )
      isDataChanged = true;
    if (isAllDone && isDataChanged) {
      formData.append("attributes[bank_details][bank_name]", values.bankName);
      formData.append(
        "attributes[bank_details][account_no]",
        values.accountNumber
      );
      formData.append("attributes[bank_details][ifsc_code]", values.ifscCode);
      formData.append(
        "attributes[bank_details][ac_holder_name]",
        values.accountHolder
      );
      formData.append("attributes[bank_details][pan_number]", values.panNumber);
      formData.append("attributes[bank_details][gst_number]", values.gstNumber);
      formData.append("attributes[bank_details][country]", values.country);
    }

    return formData;
  };

  render() {
    async function createFile(URL: string) {
      const fileName = `${URL}`.split("/").pop() || "untitled";
      try {
        let response = await fetch(URL);
        let data = await response.blob();

        let metadata = {
          type: data.type,
        };
        let file = new File([data], fileName, metadata);

        return file;
        // ... do something with the file or return it
      } catch (err) {
        console.log(err);
        // ... handle the error as you want !
      }
    }

    const bankList = [
      "Aditya Birla Idea Payments Bank Ltd",
      "Airtel Payments Bank Ltd",
      "Andhra Pradesh GVB",
      "Andhra Pragathi Grameena Bank",
      "Arunachal Pradesh Rural Bank",
      "Aryavart Bank",
      "Assam Gramin Vikash Bank",
      "Au Small Finance Bank Ltd",
      "Axis Bank Ltd",
      "Bandhan Bank Ltd",
      "Bangiya Gramin Vikash Bank",
      "Bank of Baroda",
      "Bank of India",
      "Bank of Maharashtra",
      "Baroda Gujarat Gramin Bank",
      "Baroda Rajasthan Kshetriya Gramin Bank",
      "Baroda Uttar Pradesh Gramin Bank",
      "Canara Bank",
      "Capital Small Finance Bank Ltd",
      "Central Bank of India",
      "Chaitanya Godavari GB",
      "Chhattisgarh Rajya Gramin Bank",
      "City Union Bank Ltd",
      "Coastal Local Area Bank Ltd",
      "CSB Bank Limited",
      "Dakshin Bihar Gramin Bank",
      "DCB Bank Ltd",
      "Dhanlaxmi Bank Ltd",
      "Ellaquai Dehati Bank",
      "Equitas Small Finance Bank Ltd",
      "ESAF Small Finance Bank Ltd",
      "Export-Import Bank of India",
      "Federal Bank Ltd",
      "Fincare Small Finance Bank Ltd",
      "Fino Payments Bank Ltd",
      "HDFC Bank Ltd",
      "Himachal Pradesh Gramin Bank",
      "HSBC",
      "ICICI Bank Ltd",
      "IDBI Bank Limited",
      "IDFC FIRST Bank Limited",
      "India Post Payments Bank Ltd",
      "Indian Bank",
      "Indian Overseas Bank",
      "IndusInd Bank Ltd",
      "J&K Grameen Bank",
      "Jammu & Kashmir Bank Ltd",
      "Jana Small Finance Bank Ltd",
      "Jharkhand Rajya Gramin Bank",
      "Jio Payments Bank Ltd",
      "Karnataka Bank Ltd",
      "Karnataka Gramin Bank",
      "Karnataka Vikas Gramin Bank",
      "Karur Vysya Bank Ltd",
      "Kashi Gomti Samyut Gramin Bank",
      "Kerala Gramin Bank",
      "Kotak Mahindra Bank Ltd",
      "Krishna Bhima Samruddhi LAB Ltd",
      "Lakshmi Vilas Bank Ltd",
      "Madhya Pradesh Gramin Bank",
      "Madhyanchal Gramin Bank",
      "Maharashtra GB",
      "Manipur Rural Bank",
      "Meghalaya Rural Bank",
      "Mizoram Rural Bank",
      "Nagaland Rural Bank",
      "Nainital Bank Ltd",
      "National Bank for Agriculture and Rural Development",
      "National Housing Bank",
      "North East Small Finance Bank Ltd",
      "NSDL Payments Bank Limited",
      "Odisha Gramya Bank",
      "Paschim Banga Gramin Bank",
      "Paytm Payments Bank Ltd",
      "Prathama U.P. Gramin Bank",
      "Puduvai Bharathiar Grama Bank",
      "Punjab & Sind Bank",
      "Punjab Gramin Bank",
      "Punjab National Bank",
      "Purvanchal Bank",
      "Rajasthan Marudhara Gramin Bank",
      "RBL Bank Ltd",
      "Saptagiri Grameena Bank",
      "Sarva Haryana Gramin Bank",
      "Saurashtra Gramin Bank",
      "Standard Chat",
      "Small Industries Development Bank of India",
      "South Indian Bank Ltd",
      "State Bank of India",
      "Subhadra Local Bank Ltd",
      "Suryoday Small Finance Bank Ltd",
      "Tamil Nadu Grama Bank",
      "Tamilnad Mercantile Bank Ltd",
      "Telangana Grameena Bank",
      "Tripura Gramin Bank",
      "UCO Bank",
      "Ujjivan Small Finance Bank Ltd",
      "Union Bank of India",
      "Utkal Grameen Bank",
      "Utkarsh Small Finance Bank Ltd",
      "Uttar Bihar Gramin Bank",
      "Uttarakhand Gramin Bank",
      "Uttar Banga Kshetriya Gramin Bank",
      "Vidharbha Konkan Gramin Bank",
      "YES Bank Ltd",
    ];
    return (
      <ThemeProvider theme={theme}>
        {this.state.saveMyProgressModal && (
          <SaveMyProgres
            closeModal={this.changeModalStatus}
            navigation={this.props.navigation}
          />
        )}
        <ConfirmationModal
          isOpen={this.state.socialConfirm.isOpen}
          handleClose={this.closeSocialMedia}
          userChoice={this.state.socialConfirm.type}
          updateToken={this.updateToken}
        />

        <Box className="main-box onboarding-detail">
          {/* Middle Portion */}
          <Box className="form-box">
            <AlertBlock
              data={{
                show: this.state.isMessagePop,
                close: this.handleCloseAlert,
                msg: this.msg,
                type: this.type,
                vertical: "top",
                horizontal: "center",
              }}
            />

            <Grid>
              <Typography id="main-heading">
                Moderator Profile Details{" "}
              </Typography>
              <Formik
                enableReinitialize={true}
                innerRef={this.formRef}
                initialValues={{
                  bio:
                    this.state?.oldSaveMyProgressData?.attributes
                      ?.moderator_profile[0]?.bio || "",
                  subcategories:
                    this.state?.oldSaveMyProgressData?.attributes
                      ?.moderator_user_sub_categories || [],
                  personalexperience:
                    this.state?.oldSaveMyProgressData?.attributes
                      ?.moderator_profile[0]?.personal_exp || "",
                  certification:
                    this.state?.oldSaveMyProgressData?.attributes?.moderator_profile[0]?.educational_qualifications.map(
                      (item: any) => {
                        let obj: any = {
                          trainingId: item.id,
                          institute: item?.institute_name,
                          passingYear:
                            item?.passing_year === 0 ||
                            item?.passing_year === null
                              ? ""
                              : item?.passing_year,
                          course: "",
                          documents: [],
                          files: [
                            {
                              name:
                                item?.courses?.length > 0
                                  ? `${item?.courses[0]?.document}`
                                      .split("/")
                                      .pop()
                                  : "",
                            },
                          ],
                        };
                        if (item.courses.length > 0) {
                          item.courses.map((item: any) => {
                            if (item.name?.length > 0) {
                              obj.course = item.name;
                            }
                            obj.documents.push(item.document[0]);
                          });
                        }
                        return obj;
                      }
                    ) || [
                      {
                        trainingId: null,
                        institute: "",
                        passingYear: "",
                        course: "",
                        documents: [],
                        files: null,
                      },
                    ],
                  bankName:
                    this.state?.oldSaveMyProgressData?.attributes
                      ?.moderator_profile[0]?.bank_details[0]?.bank_name || "",
                  accountNumber:
                    this.state?.oldSaveMyProgressData?.attributes
                      ?.moderator_profile[0]?.bank_details[0]?.account_no || "",
                  ifscCode:
                    this.state?.oldSaveMyProgressData?.attributes
                      ?.moderator_profile[0]?.bank_details[0]?.ifsc_code || "",
                  accountHolder:
                    this.state?.oldSaveMyProgressData?.attributes
                      ?.moderator_profile[0]?.bank_details[0]?.ac_holder_name ||
                    "",
                  panNumber:
                    this.state?.oldSaveMyProgressData?.attributes
                      ?.moderator_profile[0]?.bank_details[0]?.pan_number || "",
                  gstNumber:
                    this.state?.oldSaveMyProgressData?.attributes
                      ?.moderator_profile[0]?.bank_details[0]?.gst_number || "",
                  country:
                    this.state?.oldSaveMyProgressData?.attributes
                      ?.moderator_profile[0]?.bank_details[0]?.country || "",
                  state:
                    this.state?.oldSaveMyProgressData?.attributes
                      ?.moderator_profile[0]?.bank_details[0]?.state || "",
                  city:
                    this.state?.oldSaveMyProgressData?.attributes
                      ?.moderator_profile[0]?.bank_details[0]?.city || "",
                  // socialMedia: this.state.socialMedia,
                  socialMedia: {
                    links: [],
                    added: [],
                    count: 0,
                  },
                  saveMyProgress: false,
                  // filename: "",
                  // file: null
                }}
                // validate={this.validate}
                validationSchema={this.FormValidation}
                onSubmit={(values) => {
                  //API calling for save Additional moderator Data
                  let payload = this.CreateFormDataPayload(values);
                  this.saveOnboardingData(payload);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  setFieldValue,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Box
                        className="form-onboardingDetails"
                        style={{ display: "flex" }}
                      >
                        <Box className="right-grid">
                          {/* Bio field */}
                          <Grid className="fieldGrid">
                            <Typography
                              style={{
                                paddingBottom: "10px",
                                display: "block",
                                fontWeight: 500,
                                fontSize: "18px",
                                color: "#08063D",
                              }}
                            >
                              Bio
                            </Typography>
                            <textarea
                              placeholder="Please write a header for your profile"
                              rows={4}
                              name="bio"
                              maxLength={100}
                              onBlur={handleBlur}
                              value={values.bio.trimStart()}
                              onChange={(e) => {
                                e.target.value =
                                  e.target.value && e.target.value.trimStart();
                                handleChange(e);
                              }}
                              style={{
                                borderRadius: "8px",
                                width: "100%",
                                border: "1px solid #CFCFCF",
                                backgroundColor: "#FFFFFF",
                                padding: "10px",
                                resize: "none",
                              }}
                            />
                            <Box
                              style={{
                                textAlign: "right",
                                width: "100%",
                                color: "#C2C2C3",
                              }}
                            >
                              {" "}
                              <Typography>
                                {" "}
                                {100 - values.bio.length} characters{" "}
                              </Typography>{" "}
                            </Box>

                            {touched.bio && errors.bio && (
                              <span
                                style={{
                                  display: "block",
                                  color: "red",
                                  fontSize: "12px",
                                }}
                              >
                                {touched.bio && errors.bio}
                              </span>
                            )}
                          </Grid>

                          <Grid
                            className="fieldGrid"
                            style={{
                              border: "0.5px solid #EBEBEB",
                              width: "100%",
                            }}
                          />

                          {/* Preferred Subcategories */}
                          <Grid className="fieldGrid">
                            <FormControl fullWidth variant="outlined">
                              <Typography
                                style={{
                                  paddingBottom: "5px",
                                  display: "block",
                                  fontWeight: 500,
                                  fontSize: "18px",
                                  color: "#08063D",
                                }}
                              >
                                Preferred Subcategories for Moderation
                              </Typography>
                              <Select
                                IconComponent={KeyboardArrowDownRoundedIcon}
                                displayEmpty={true}
                                style={{
                                  width: "100%",
                                  borderRadius: "8px",
                                  background: "#fff",
                                  opacity: 1,
                                }}
                                labelId="mutiple-select-label"
                                multiple
                                className="blur-placeholder-adtn-moderator"
                                value={values.subcategories}
                                name="subcategories"
                                onChange={handleChange}
                                // onClose={(e) => {
                                //     handleBlur(e)
                                // }}
                                onBlur={handleBlur}
                                MenuProps={{
                                  PaperProps: {
                                    style: {
                                      maxHeight: 48 * 4.5 + 8,
                                      width: 250,
                                    },
                                  },
                                  getContentAnchorEl: null,
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "center",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "center",
                                  },
                                  variant: "menu",
                                }}
                                renderValue={(selected: any) =>
                                  selected.length ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        opacity: 1,
                                      }}
                                    >
                                      {selected.map(
                                        (value: any, indx: number) => (
                                          <Chip
                                            key={indx}
                                            label={value[0]}
                                            onClick={(e) => e.stopPropagation()}
                                            deleteIcon={
                                              <CancelIcon
                                                onMouseDown={(event) =>
                                                  event.stopPropagation()
                                                }
                                                style={{ color: "#fff" }}
                                              />
                                            }
                                            onDelete={(e) => {
                                              e.stopPropagation();
                                              setFieldValue(
                                                "subcategories",
                                                values.subcategories.filter(
                                                  (item: any) => {
                                                    return item !== value;
                                                  }
                                                )
                                              );
                                            }}
                                            style={{
                                              margin: 2,
                                              color: "white",
                                              backgroundColor: "#101038",
                                            }}
                                          />
                                        )
                                      )}
                                    </div>
                                  ) : (
                                    "Choose up to 3 subcategories to moderate"
                                  )
                                }
                              >
                                {this.state.subCategoriesList &&
                                  this.state.subCategoriesList.map(
                                    (option: any) => (
                                      <MenuItem key={option[1]} value={option}>
                                        <ListItemText primary={option[0]} />
                                        <ListItemIcon>
                                          {/* @ts-ignore*/}
                                          <Checkbox
                                            checked={values.subcategories?.some(
                                              (itm: any) => itm[1] == option[1]
                                            )}
                                          />
                                        </ListItemIcon>
                                      </MenuItem>
                                    )
                                  )}
                              </Select>
                              {touched.subcategories &&
                                errors.subcategories && (
                                  <span
                                    style={{
                                      display: "block",
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {errors.subcategories &&
                                      touched.subcategories &&
                                      errors.subcategories}
                                  </span>
                                )}
                            </FormControl>
                          </Grid>

                          {/* Personal Experience */}
                          <Grid className="fieldGrid">
                            <FormControl fullWidth variant="outlined">
                              <Typography
                                style={{
                                  paddingBottom: "5px",
                                  display: "block",
                                  fontWeight: 500,
                                  fontSize: "18px",
                                  color: "#08063D",
                                }}
                              >
                                Personal Experience
                              </Typography>
                              <input
                                name="personalexperience"
                                value={values.personalexperience}
                                placeholder="Add your experience"
                                className="input-field width-90"
                                maxLength={2000}
                                onChange={(e) => {
                                  e.target.value =
                                    e.target.value &&
                                    e.target.value.trimStart();
                                  handleChange(e);
                                }}
                              />
                              {touched.personalexperience &&
                                errors.personalexperience && (
                                  <span
                                    style={{
                                      display: "block",
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {errors.personalexperience &&
                                      touched.personalexperience &&
                                      errors.personalexperience}
                                  </span>
                                )}
                            </FormControl>
                          </Grid>

                          <Typography
                            style={{
                              paddingLeft: "40%",
                              marginTop: "20px",
                              fontWeight: 600,
                              color: "#08063D",
                            }}
                          >
                            Or
                          </Typography>

                          {/* Relevant Training/Certification */}
                          <Grid className="fieldGrid">
                            <FormControl style={{ width: "100%" }}>
                              <span>
                                <Typography
                                  style={{
                                    paddingBottom: "-55px",
                                    display: "block",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    color: "#08063D",
                                  }}
                                >
                                  Relevant Training/Certification
                                </Typography>
                              </span>
                              <FieldArray
                                name="certification"
                                render={({
                                  remove,
                                  push,
                                }: {
                                  remove: RemoveFunction;
                                  push: PushFunction;
                                }) => (
                                  <Box style={{ width: "100%" }}>
                                    <Button
                                      type="button"
                                      disableRipple
                                      className="addbutton"
                                      onClick={() =>
                                        push({
                                          institute: "",
                                          passingYear: "",
                                          course: "",
                                          documents: [],
                                        })
                                      }
                                    >
                                      <img src={ples} className="add-icon" />
                                    </Button>
                                    {values.certification.length > 0 &&
                                      values.certification.map(
                                        (friend: any, index: number) => (
                                          <Box className="row" key={index}>
                                            {/* Institute */}
                                            <Box className="col" mt={4}>
                                              <Grid
                                                container
                                                justifyContent="space-between"
                                                md={12}
                                              >
                                                <Typography id="field-array-labels">
                                                  Institute
                                                </Typography>
                                                <Button
                                                  type="button"
                                                  disableRipple
                                                  className="removeButton"
                                                  onClick={() => remove(index)}
                                                >
                                                  <img
                                                    src={cross}
                                                    className="cross-icon"
                                                  />
                                                </Button>
                                              </Grid>
                                              <Field
                                                className="field-array-input"
                                                name={`certification.${index}.institute`}
                                                placeholder="Add institute name"
                                                type="text"
                                                maxLength={30}
                                                onChange={(e: any) => {
                                                  e.target.value =
                                                    e.target.value &&
                                                    e.target.value.trimStart();
                                                  handleChange(e);
                                                }}
                                                onBlur={handleBlur}
                                              />
                                              {touched.certification &&
                                                // @ts-ignore
                                                touched.certification[index]
                                                  ?.institute &&
                                                errors.certification &&
                                                // @ts-ignore
                                                errors.certification[index] && (
                                                  <span
                                                    style={{
                                                      display: "block",
                                                      color: "red",
                                                      fontSize: "12px",
                                                    }}
                                                  >
                                                    {
                                                      // @ts-ignore
                                                      errors.certification[
                                                        index
                                                      ].institute
                                                    }
                                                  </span>
                                                )}
                                              {/* { errors.certification[index].institute && <span style={{display:"block",color:"red",fontSize:"12px"}}>{ errors.certification[index].institute && touched.certification[index].institute && errors.certification[index].institute}</span>} */}
                                            </Box>

                                            {/* Year of passing */}
                                            <Box className="col" mt={4}>
                                              <Typography id="field-array-labels">
                                                Year of passing
                                              </Typography>
                                              <DropDown
                                                style={{ background: "#fff" }}
                                                name={`certification.${index}.passingYear`}
                                                list={
                                                  this.state.years &&
                                                  this.state.years.map(
                                                    (item: any) =>
                                                      item.toString()
                                                  )
                                                }
                                                value={
                                                  values.certification[index]
                                                    .passingYear !== null ||
                                                  values.certification[index]
                                                    .passingYear !== 0
                                                    ? `${values.certification[index].passingYear}`
                                                    : ""
                                                }
                                                onChange={handleChange}
                                                className="MuiOutlinedInput-root year-select"
                                                defaultText="Select year "
                                                onBlur={handleBlur}
                                              />
                                              {touched.certification &&
                                                // @ts-ignore
                                                touched.certification[index]
                                                  ?.passingYear &&
                                                errors.certification &&
                                                // @ts-ignore
                                                errors.certification[index] && (
                                                  <span
                                                    style={{
                                                      display: "block",
                                                      color: "red",
                                                      fontSize: "12px",
                                                    }}
                                                  >
                                                    {
                                                      // @ts-ignore
                                                      errors.certification[
                                                        index
                                                      ].passingYear
                                                    }
                                                  </span>
                                                )}
                                            </Box>

                                            {/* Course */}
                                            <Box className="col" mt={4}>
                                              <Typography id="field-array-labels">
                                                Certification/Course
                                              </Typography>
                                              <Field
                                                className="field-array-input"
                                                name={`certification.${index}.course`}
                                                placeholder="Add certification/course details"
                                                type="text"
                                                value={`${values.certification[index].course}`}
                                                // maxLength={5}
                                                onChange={(e: any) => {
                                                  e.target.value =
                                                    e.target.value &&
                                                    e.target.value.trimStart();
                                                  handleChange(e);
                                                }}
                                                onBlur={handleBlur}
                                              />
                                              {touched.certification &&
                                                // @ts-ignore
                                                touched.certification[index]
                                                  ?.course &&
                                                errors.certification &&
                                                // @ts-ignore
                                                errors.certification[index] && (
                                                  <span
                                                    style={{
                                                      display: "block",
                                                      color: "red",
                                                      fontSize: "12px",
                                                    }}
                                                  >
                                                    {
                                                      // @ts-ignore
                                                      errors.certification[
                                                        index
                                                      ].course
                                                    }
                                                  </span>
                                                )}
                                            </Box>

                                            {/* Document */}
                                            <Box className="col" mt={4}>
                                              <Typography id="field-array-labels">
                                                Documents
                                              </Typography>
                                              <FieldArray
                                                name={`certification.${index}.documents`}
                                                render={({
                                                  remove,
                                                  push,
                                                }: {
                                                  remove: RemoveFunction;
                                                  push: PushFunction;
                                                }) => (
                                                  <Box
                                                    key={index}
                                                    style={{
                                                      width: "100%",
                                                      position: "relative",
                                                    }}
                                                  >
                                                    {values.certification[index]
                                                      ?.documents?.length <
                                                      3 && (
                                                      <Button
                                                        type="button"
                                                        disableRipple
                                                        className="addbuttonAbsolute "
                                                        onClick={() => push("")}
                                                      >
                                                        <img
                                                          src={ples}
                                                          className="add-iconPles"
                                                        />
                                                      </Button>
                                                    )}
                                                    {values.certification[
                                                      index
                                                    ]?.documents?.map(
                                                      (
                                                        item: any,
                                                        docIndex: number
                                                      ) => {
                                                        return (
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              marginBottom:
                                                                "10px",
                                                              alignItems:
                                                                "center",
                                                            }}
                                                          >
                                                            <Box
                                                              style={{
                                                                height: "auto",
                                                                width: "100%",
                                                                padding: 0,
                                                              }}
                                                              className="field-array-input"
                                                            >
                                                              <Button
                                                                component="label"
                                                                style={{
                                                                  width: "100%",
                                                                }}
                                                              >
                                                                <Box
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                    flexDirection:
                                                                      "column",
                                                                    textAlign:
                                                                      "center",
                                                                    padding:
                                                                      "10px",
                                                                    opacity:
                                                                      "0.8",
                                                                  }}
                                                                >
                                                                  {item ? (
                                                                    <div className="upload-file-adtn-moderator">
                                                                      <span>
                                                                        {
                                                                          <span
                                                                            style={{
                                                                              border:
                                                                                "1px solid black",
                                                                              borderRadius:
                                                                                "5px",
                                                                              color:
                                                                                "#fff",
                                                                              background:
                                                                                "#010138",
                                                                              margin:
                                                                                "10px",
                                                                              padding:
                                                                                "1px 1px 1px 15px",
                                                                            }}
                                                                          >
                                                                            {item.name
                                                                              ? item.name
                                                                              : item
                                                                                  .split(
                                                                                    "/"
                                                                                  )
                                                                                  .pop()}
                                                                            <CancelIcon
                                                                              style={{
                                                                                margin:
                                                                                  "0px 15px -6px 0px",
                                                                              }}
                                                                              onClick={(
                                                                                e
                                                                              ) => {
                                                                                e.preventDefault();
                                                                                e.stopPropagation();
                                                                                remove(
                                                                                  docIndex
                                                                                );
                                                                              }}
                                                                            />
                                                                          </span>
                                                                          // .join(", ")
                                                                        }
                                                                      </span>
                                                                    </div>
                                                                  ) : (
                                                                    <>
                                                                      {" "}
                                                                      <Typography>
                                                                        {
                                                                          "+    Upload documents"
                                                                        }
                                                                      </Typography>
                                                                      <Typography
                                                                        style={{
                                                                          fontSize:
                                                                            "12px",
                                                                        }}
                                                                      >
                                                                        <i>
                                                                          (or
                                                                          connect
                                                                          Linkedin
                                                                          profile
                                                                          below)
                                                                        </i>{" "}
                                                                      </Typography>
                                                                    </>
                                                                  )}
                                                                </Box>
                                                                <input
                                                                  name={`certification.${index}.documents`}
                                                                  hidden
                                                                  multiple
                                                                  type="file"
                                                                  onChange={(
                                                                    e: any
                                                                  ) => {
                                                                    if (
                                                                      e.target
                                                                        .files[0]
                                                                        ?.type ===
                                                                      "application/pdf"
                                                                    ) {
                                                                      if (
                                                                        e.target
                                                                          .files[0]
                                                                          .size <
                                                                        10485760
                                                                      ) {
                                                                        if (
                                                                          !values.certification[
                                                                            index
                                                                          ]?.documents?.find(
                                                                            (
                                                                              item: any
                                                                            ) => {
                                                                              if (
                                                                                item.name
                                                                              ) {
                                                                                if (
                                                                                  item.name ===
                                                                                  e
                                                                                    .target
                                                                                    .files[0]
                                                                                    .name
                                                                                )
                                                                                  return true;
                                                                              } else {
                                                                                if (
                                                                                  item
                                                                                    ?.split(
                                                                                      "/"
                                                                                    )
                                                                                    .slice(
                                                                                      -1
                                                                                    )[0] ===
                                                                                  e
                                                                                    .target
                                                                                    .files[0]
                                                                                    .name
                                                                                )
                                                                                  return true;
                                                                              }
                                                                              item ===
                                                                                e
                                                                                  .target
                                                                                  .files[0]
                                                                                  .name;
                                                                            }
                                                                          )
                                                                        ) {
                                                                          setFieldValue(
                                                                            `certification.${index}.documents.${docIndex}`,
                                                                            e
                                                                              .target
                                                                              .files[0]
                                                                          );
                                                                          this.handleAlertSuccess(
                                                                            " File Uploaded Successfully"
                                                                          );
                                                                        } else {
                                                                          this.handleAlert(
                                                                            "File already attached"
                                                                          );
                                                                        }
                                                                      } else {
                                                                        this.handleAlert(
                                                                          "File size must be smaller than 10 megabytes"
                                                                        );
                                                                      }
                                                                    } else {
                                                                      this.handleAlert(
                                                                        " You can only upload PDF type file"
                                                                      );
                                                                    }
                                                                    // alert(" File Uploaded Successfully")
                                                                  }}
                                                                />
                                                              </Button>
                                                            </Box>
                                                            <CancelIcon
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                                margin:
                                                                  "0px 0px 0px 10px",
                                                              }}
                                                              onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                remove(
                                                                  docIndex
                                                                );
                                                              }}
                                                            />
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </Box>
                                                )}
                                              />
                                              {touched.certification &&
                                                // @ts-ignore
                                                touched.certification[index]
                                                  ?.documents &&
                                                errors.certification &&
                                                // @ts-ignore
                                                errors.certification[index]
                                                  .documents && (
                                                  <span
                                                    style={{
                                                      display: "block",
                                                      color: "red",
                                                      fontSize: "12px",
                                                    }}
                                                  >
                                                    {
                                                      // @ts-ignore
                                                      errors.certification[
                                                        index
                                                      ].documents
                                                    }
                                                  </span>
                                                )}
                                              {/* {touched.certification && touched.certification[index].documents && errors.certification && errors.certification[index] && <span style={{display:"block",color:"red",fontSize:"12px"}}>{errors.certification[index].documents}</span>}
                                               */}
                                            </Box>
                                          </Box>
                                        )
                                      )}
                                  </Box>
                                )}
                              />
                            </FormControl>
                          </Grid>

                          <Grid
                            className="fieldGrid"
                            style={{
                              border: "0.5px solid #EBEBEB",
                              width: "100%",
                            }}
                          />

                          {/* Social Media */}
                          <Grid
                            className="fieldGrid"
                            innerRef={this.socialMediaRef}
                          >
                            <FormControl style={{ width: "100%" }}>
                              <Box>
                                <Typography
                                  style={{
                                    display: "block",
                                    fontWeight: 500,
                                    fontSize: "18px",
                                    color: "#08063D",
                                    marginBottom: "20px",
                                  }}
                                >
                                  Social Media
                                </Typography>

                                {this.state.socialMedia.count < 4 && (
                                  <Button
                                    type="button"
                                    disableRipple
                                    className="addbutton"
                                    onClick={this.addSocialLogin}
                                  >
                                    <img src={ples} className="add-icon2" />
                                  </Button>
                                )}
                              </Box>

                              {new Array(this.state.socialMedia.count)
                                .fill(0)
                                .map((_, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      {/* Remove Button */}
                                      {index > 0 && (
                                        <Box className="row">
                                          <Button
                                            disableRipple
                                            type="button"
                                            className="addbutton"
                                            onClick={() =>
                                              this.removeSocialLogin(index)
                                            }
                                          >
                                            <img
                                              src={cross}
                                              className="cross-icon"
                                            />
                                          </Button>
                                        </Box>
                                      )}

                                      <Box
                                        className="col"
                                        key={index}
                                        marginBottom={1}
                                      >
                                        <DropDown
                                          name={`socialmedia.${index}.socialMedia`}
                                          list={
                                            this.state.socialMedia.links &&
                                            this.state.socialMedia.links
                                              .filter(
                                                (each) =>
                                                  !this.state.socialMedia.added.find(
                                                    (login) =>
                                                      login?.type === each
                                                  )
                                              )
                                              .map((item: any) =>
                                                item.toString()
                                              )
                                          }
                                          value={
                                            this.state.socialMedia.added[index]
                                              ?.type
                                          }
                                          onChange={(e) => {
                                            this.openSocialMedia(
                                              e.target.value,
                                              index
                                            );
                                          }}
                                          className="MuiOutlinedInput-root year-select"
                                          defaultText="Select social media profile"
                                          style={{ background: "white" }}
                                        />

                                        <Box className="col" mt={4}>
                                          <Field
                                            className="field-array-input"
                                            name={`socialmedia.${index}.addUrl`}
                                            placeholder="Profile URL"
                                            type="text"
                                            onChange={(e: any) => {
                                              // e.target.value =
                                              //   e.target.value &&
                                              //   e.target.value.trimStart();
                                              // handleChange(e);
                                              let data: any = {
                                                ...this.state.socialMedia,
                                              };
                                              data.added[index].value =
                                                e.target.value;
                                              // data.added[index].value = { ...(data.added[index].value ?? {}), userLink: e.target.value }
                                              this.setState({
                                                socialMedia: data,
                                              });
                                            }}
                                            // disabled={(this.state.socialMedia.added[index]?.type.toLowerCase() !== 'facebook' && this.state.socialMedia.added[index]?.type.toLowerCase() !== 'linkedin') ? true : false}
                                            value={
                                              this.state.socialMedia.added[
                                                index
                                              ]?.value
                                              // this.state.socialMedia.added[index]?.value?.accessToken ||
                                              // ''
                                            }
                                          />
                                        </Box>
                                      </Box>
                                      {touched.socialMedia?.added &&
                                        // @ts-ignore
                                        errors.socialMedia?.added[index] && (
                                          <span
                                            style={{
                                              display: "block",
                                              color: "red",
                                              fontSize: "12px",
                                            }}
                                          >
                                            {
                                              errors.socialMedia?.added[index] // @ts-ignore
                                                .value
                                            }
                                          </span>
                                        )}
                                    </React.Fragment>
                                  );
                                })}
                            </FormControl>

                            {touched.socialMedia?.added &&
                              errors.socialMedia?.added &&
                              typeof errors.socialMedia?.added === "string" && (
                                <span
                                  style={{
                                    display: "block",
                                    color: "red",
                                    fontSize: "12px",
                                  }}
                                >
                                  {touched.socialMedia?.added &&
                                    errors.socialMedia &&
                                    errors.socialMedia?.added}
                                </span>
                              )}
                            {this.state.hasError && (
                              <span className="error-text">
                                {this.state.hasError}
                              </span>
                            )}
                          </Grid>

                          <Grid
                            className="fieldGrid"
                            style={{
                              border: "0.5px solid #EBEBEB",
                              width: "100%",
                            }}
                          />

                          {/* Add Bank Details  */}
                          <Grid className="fieldGrid">
                            <FormControl
                              style={{
                                width: "100%",
                              }}
                            >
                              <Typography
                                style={{
                                  fontWeight: 500,
                                  fontSize: "20px",
                                  color: "#05043C",
                                }}
                              >
                                Add Bank Details (Optional){" "}
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: "14px",
                                  color: "gray",
                                  paddingRight: "54px",
                                }}
                              >
                                If you wish to encash your earnings as a
                                Moderator, provide your Indian bank account
                                details{" "}
                              </Typography>
                              <Box mt={2}>
                                <Typography id="field-array-labels">
                                  Name of Bank
                                </Typography>
                                <DropDown
                                  name={`bankName`}
                                  list={bankList.map((item: any) => item)}
                                  value={values.bankName}
                                  className="MuiOutlinedInput-root year-select width-100 "
                                  defaultText="Select bank "
                                  onChange={(e: any) => {
                                    e.target.value =
                                      e.target.value &&
                                      e.target.value.trimStart();
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                  style={{ background: "white" }}
                                />
                              </Box>

                              <Box mt={4}>
                                <Typography id="field-array-labels">
                                  Account Number
                                </Typography>
                                <input
                                  name="accountNumber"
                                  value={values.accountNumber}
                                  placeholder="Add account number"
                                  className="input-field"
                                  maxLength={19}
                                  onChange={(e: any) => {
                                    // if (
                                    //   RegExp(/^[0-9]{0,19}$/).test(
                                    //     e.target.value
                                    //   )
                                    // ) {
                                    handleChange(e);
                                    // }
                                  }}
                                  onBlur={handleBlur}
                                />
                                {touched.accountNumber &&
                                  errors.accountNumber && (
                                    <span
                                      style={{
                                        display: "block",
                                        color: "red",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {errors.accountNumber &&
                                        touched.accountNumber &&
                                        errors.accountNumber}
                                    </span>
                                  )}
                              </Box>

                              <Box mt={4}>
                                <Typography id="field-array-labels">
                                  IFSC Code
                                </Typography>
                                <input
                                  name="ifscCode"
                                  value={values.ifscCode}
                                  placeholder="Add IFSC code"
                                  className="input-field"
                                  maxLength={11}
                                  onChange={(e: any) => {
                                    e.target.value =
                                      e.target.value &&
                                      e.target.value.trimStart();
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                />

                                {touched.ifscCode && errors.ifscCode && (
                                  <span
                                    style={{
                                      display: "block",
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {errors.ifscCode &&
                                      touched.ifscCode &&
                                      errors.ifscCode}
                                  </span>
                                )}
                              </Box>

                              <Box mt={4}>
                                <Typography id="field-array-labels">
                                  Name of Account Holder
                                </Typography>
                                <input
                                  name="accountHolder"
                                  className="input-field"
                                  value={values.accountHolder}
                                  placeholder="Add account holder name"
                                  onChange={(e: any) => {
                                    e.target.value =
                                      e.target.value &&
                                      e.target.value.trimStart();
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                />

                                {touched.accountHolder &&
                                  errors.accountHolder && (
                                    <span
                                      style={{
                                        display: "block",
                                        color: "red",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {errors.accountHolder &&
                                        touched.accountHolder &&
                                        errors.accountHolder}
                                    </span>
                                  )}
                              </Box>

                              <Box mt={4}>
                                <Typography id="field-array-labels">
                                  PAN Number
                                </Typography>
                                <input
                                  name="panNumber"
                                  className="input-field"
                                  value={values.panNumber}
                                  placeholder="Add PAN number"
                                  maxLength={10}
                                  onChange={(e: any) => {
                                    e.target.value =
                                      e.target.value &&
                                      e.target.value.trimStart();
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                />

                                {touched.panNumber && errors.panNumber && (
                                  <span
                                    style={{
                                      display: "block",
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {errors.panNumber &&
                                      touched.panNumber &&
                                      errors.panNumber}
                                  </span>
                                )}
                              </Box>

                              <Box mt={4}>
                                <Typography id="field-array-labels">
                                  GST Number
                                </Typography>
                                <input
                                  name="gstNumber"
                                  className="input-field"
                                  value={values.gstNumber}
                                  placeholder="Add GST number (optional)"
                                  maxLength={15}
                                  onChange={(e: any) => {
                                    e.target.value =
                                      e.target.value &&
                                      e.target.value.trimStart();
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                />

                                {touched.gstNumber && errors.gstNumber && (
                                  <span
                                    style={{
                                      display: "block",
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {errors.gstNumber &&
                                      touched.gstNumber &&
                                      errors.gstNumber}
                                  </span>
                                )}
                              </Box>

                              <Box mt={4}>
                                <Typography id="field-array-labels">
                                  Country
                                </Typography>
                                <DropDown
                                  style={{ background: "#fff" }}
                                  name={`country`}
                                  list={
                                    this.state.countryList &&
                                    this.state.countryList.map(
                                      (item: any) => item.name
                                    )
                                  }
                                  value={values.country}
                                  onChange={handleChange}
                                  className="MuiOutlinedInput-root year-select width-100"
                                  defaultText="Select your country "
                                  onBlur={handleBlur}
                                />
                              </Box>
                            </FormControl>
                          </Grid>

                          {/* Submit button */}
                          {this.state?.oldSaveMyProgressData?.attributes
                            ?.reapply_after < 0 ? (
                            <Alert
                              variant="outlined"
                              severity="info"
                              color="warning"
                              style={{ marginTop: "40px" }}
                            >
                              You can save your application resubmission
                              progress at any time, but you will only be able to
                              reapply 6 months after your last attempt.
                            </Alert>
                          ) : (
                            <Grid
                              style={{
                                textAlign: "center",
                                paddingTop: "40px",
                              }}
                            >
                              <Button
                                type="submit"
                                variant="contained"
                                style={Styles.myButton}
                              >
                                Next
                              </Button>
                            </Grid>
                          )}

                          {/* Save my progress in draft */}
                          <Box
                            mt={5}
                            style={{ textAlign: "center", cursor: "pointer" }}
                          >
                            <Typography
                              style={{ fontSize: "14px", color: "#9AA6B1" }}
                              onClick={async (e: any) => {
                                let self = this;
                                // setFieldValue('saveMyProgress',true)
                                let payload =
                                  this.CreateFormDataPayload(values);
                                payload.append("is_draft", "true");

                                if (
                                  self.state?.oldSaveMyProgressData?.attributes
                                    ?.moderator_profile[0]?.bank_details[0]
                                    ?.profile_id
                                ) {
                                  payload.append(
                                    "profile_id",
                                    self.state?.oldSaveMyProgressData
                                      ?.attributes?.moderator_profile[0]
                                      ?.bank_details[0]?.profile_id
                                  );
                                }
                                // if (values.certification) {
                                //   for (let item of values.certification) {
                                //     const files = await createFile(item.documents)
                                //     item.trainingId && payload.append("attributes[training][][courses][][document]", files || "")
                                //     item.trainingId && payload.append("attributes[training][][id]", item.trainingId)
                                //   }

                                // }

                                self.state?.oldSaveMyProgressData?.attributes?.moderator_profile[0]?.educational_qualifications.forEach(
                                  (item: any) => {
                                    const id = item.id;
                                    if (values.certification) {
                                      const check = values.certification.some(
                                        (mainItem: any) =>
                                          mainItem.trainingId == id
                                      );
                                      check ||
                                        payload.append(
                                          "attributes[training][][delete]",
                                          "true"
                                        );
                                    }
                                  }
                                );

                                this.saveProgressOnboardingData(payload);
                                //  handleSubmit(e)
                                // for (let pair of payload.entries()) {
                                //   console.log("payload", pair[0] + ', ' + pair[1]);
                                // }
                              }}
                            >
                              {" "}
                              Save my progress{" "}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </form>
                  );
                }}
              </Formik>
            </Grid>
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
}

const Styles = {
  Input: {
    width: "500px%",
    border: "1px solid black",
  },
  myButton: {
    backgroundColor: "#010039",
    color: "white",
    width: "60%",
    borderRadius: "8px",
    padding: "10px",
    margin: "auto",
  },
};

// Customizable Area End
