import { Animated, Keyboard } from "react-native";
import { RESULTS } from "react-native-permissions";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
// import { persistUserData, retrieveAuthToken, retrieveUserData } from "../../../../mobile/src/utils/Storage";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import React from 'react'
import MessageEnum, {
   getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { AccountBalanceWallet, CreditCard } from '@material-ui/icons';
import RazorPay from "./RazorpayGateway.web";
export const configJSON = require("./config");

export interface Props {
   navigation: any;
   id: string;
   // Customizable Area Start
   addValue: (value: number) => void
   cb?: () => void
   handleOpen?: () => void;
   // Customizable Area End
}

interface S {
   // Customizable Area Start
   isLoading: boolean;
   freeYCoinsArray: any;
   modalOpen: boolean;
   totalAmount: number;
   paymentData: { type: string, icon: any }[];
   coinAmount: string;
   suggestCoin: string;
   suggetsFreeCoin: string;
   isPurchaseSuccess: boolean;
   freeCoin: number;
   errorMessage: boolean;
   errorMessage2: boolean;
   // Customizable Area End

}

interface SS {
   // Customizable Area Start
   id: any;
   // Customizable Area End
}

export default class RefillWalletController extends BlockComponent<
   Props,
   S,
   SS
> {
   getFetchYcoinsApiCall: any;
   getGenerateOrderId: any;
   getGatewayOrderId: any;
   getUserProfileIdApiCall: any;
   getUpdateCoins: any;
   [x: string]: any;
   constructor(props: Props) {
      // Customizable Area Start
      super(props);

      this.subScribedMessages = [
         getName(MessageEnum.SessionResponseMessage),
         getName(MessageEnum.RestAPIResponceMessage)
      ];

      this.state = {
         isLoading: false,
         freeYCoinsArray: [],
         modalOpen: false,
         totalAmount: 0,
         errorMessage: false,
         errorMessage2: false,
         isPurchaseSuccess: false,
         paymentData: [
            {
               type: 'Credit/Debit Card',
               icon: <CreditCard style={{ fontSize: '30px' }} />
            },
            {
               type: 'UPI Payments',
               icon: <CreditCard style={{ fontSize: '30px' }} />
            },
            {
               type: 'Netbanking',
               icon: <CreditCard style={{ fontSize: '30px' }} />
            },
            {
               type: 'Digital Wallet',
               icon: <AccountBalanceWallet style={{ fontSize: '30px' }} />
            }
         ],
         freeCoin: 0,
         coinAmount: '',
         suggestCoin: '',
         suggetsFreeCoin: ''
      }
      // Customizable Area End
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
   }

   async componentDidMount(): Promise<void> {
      this.fetchYCoins();
      // this.getUserProfile();
   }


   async receive(from: string, message: Message) {
      // Customizable Area Start
      if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
         const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
         );

         const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
         );

         const errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
         );

         if (!responseJson) return ''
         if (responseJson) {
            if (apiRequestCallId === this.getFetchYcoinsApiCall) {
               this.setState({ freeYCoinsArray: responseJson, isLoading: false })
            } else if (apiRequestCallId === this.getGenerateOrderId) {
               if (responseJson.transaction?.order_id) {
                  this.openPaymentGateway(responseJson?.transaction.order_id, responseJson.transaction?.id)
               }
            } else if (apiRequestCallId === this.getUserProfileIdApiCall) {
            } else if (apiRequestCallId === this.getUpdateCoins) {
            } else if (apiRequestCallId === this.getGatewayOrderId) {
            }
         }
      }
      // Customizable Area End
   }

   fetchYCoins = () => {
      this.setState({ isLoading: true })
      const header = {
         "Content-Type": configJSON.ApiContentType,
         // eslint-disable-next-line no-undef
         token: localStorage.getItem("token")
      };
      const requestMessage = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getFetchYcoinsApiCall = requestMessage.messageId;

      requestMessage.addData(
         getName(MessageEnum.RestAPIResponceEndPointMessage),
         configJSON.fetchFreeCoins
      );
      requestMessage.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(header)
      );

      requestMessage.addData(
         getName(MessageEnum.RestAPIRequestMethodMessage),
         configJSON.httpGetType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
   }

   getUserProfile = async () => {
      const header = {
         "Content-Type": configJSON.ApiContentType,
         // eslint-disable-next-line no-undef
         token: localStorage.getItem("token")
      };
      const requestMessage = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getUserProfileIdApiCall = requestMessage.messageId;

      requestMessage.addData(
         getName(MessageEnum.RestAPIResponceEndPointMessage),
         configJSON.getUserDetails + localStorage.getItem('userId')
      );
      requestMessage.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(header)
      );

      requestMessage.addData(
         getName(MessageEnum.RestAPIRequestMethodMessage),
         configJSON.httpGetType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
   }

   updateYCoins = async () => {

      const header = {
         "Content-Type": configJSON.ApiContentType,
         // eslint-disable-next-line no-undef
         token: localStorage.getItem('token')
      };

      const requestMessage = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getUpdateCoins = requestMessage.messageId;

      requestMessage.addData(
         getName(MessageEnum.RestAPIResponceEndPointMessage),
         configJSON.updateCoins + String(this.state.coinAmount)
      );
      requestMessage.addData(
         getName(MessageEnum.RestAPIRequestBodyMessage),
         {}
      );
      requestMessage.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(header)
      );

      requestMessage.addData(
         getName(MessageEnum.RestAPIRequestMethodMessage),
         configJSON.httpPostType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
   }
}