// Customizable Area Start
import React from 'react';
import { Box, Button, Typography, Container, Grid } from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Avatar from '@material-ui/core/Avatar';
import { Oval, joh, thoma, bgg } from './assets';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import './styles.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
      contrastText: '#fff',
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: '20px 0px',
    },
  },
});

import OnboardingguideController, {
  Props as P,
  configJSON,
} from './OnboardingguideController';
import { CrossIcon } from './assets';
import Header from '../../Settings5/src/settings/header.web';

type Props = {
  setStep: (step: number) => void;
  handleOnboardingDialogClose: () => void;
} & P;

export default class Onboardingguide extends OnboardingguideController<Props> {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount(): Promise<void> {
    // const mainView = document.getElementById("main-view");
    // mainView && (mainView.style.height = "100%")
    this.getModeratorInfo();
  }

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid>
          <div style={{ position: 'relative' }}>
            <img
              src={bgg}
              style={{
                position: 'absolute',
                width: '35%',
                top: '-78px',
                left: '0px'
              }}
            />
            <img
              src={bgg}
              style={{
                position: 'absolute',
                width: '35%',
                right: '0px',
                bottom: '-18%',
              }}
            />

            <Box sx={{ paddingX: '30%' }}>
              <AvatarGroup max={4} style={webStyle.avtarGroup}>
                <Avatar
                  alt="Jack Sparrow"
                  src={Oval}
                  style={{ width: '55px', height: '55px' }}
                />
                <Avatar
                  alt="Mike Wazowski"
                  src={joh}
                  style={{ width: '55px', height: '55px' }}
                />
                <Avatar
                  alt="Evelyn Carnahan"
                  src={thoma}
                  style={{ width: '55px', height: '55px' }}
                />
              </AvatarGroup>
            </Box>

            <Container
              maxWidth='sm'
              style={{
                textAlign: 'center',
                position: 'relative'
              }}
            >
              <Typography
                style={{
                  marginTop: '25px',
                  color: '#27272A',
                  fontWeight: 600,
                  position: 'relative',
                  textAlign: 'center',
                  fontSize: '28px',
                }}
              >
                {' '}
                Do you want to be a Moderator?{' '}
              </Typography>
              <ul
                style={{
                  textAlign: 'left',
                  padding: 'revert'
                }}
              >
                <li style={webStyle.liContent}>
                  <Typography style={{ fontSize: '14px' }}>
                    {this.state.moderatorInfoList1?.description}{' '}
                  </Typography>
                </li>
                <li style={webStyle.liContent}>
                  <Typography style={{ fontSize: '14px', marginRight: '20px' }}>
                    {this.state.moderatorInfoList2?.description}{' '}
                  </Typography>
                </li>
              </ul>
              <p
                style={{
                  paddingInline: '3em',
                  fontSize: '13px',
                  textAlign: 'left',
                }}
              >
                <Typography style={{ fontSize: '14px' }}>
                  <a
                    target="_blank"
                    href={`${location.origin}/ModuleOrientation`}
                    style={{
                      textDecoration: 'none',
                      color: '#45446E',
                      fontWeight: 600,
                    }}
                  >
                    Click here
                  </a>
                  <span style={{ color: '#8A98A6' }}>
                    {' '}
                    to learn more about how to become a Moderator
                  </span>
                </Typography>
              </p>
              <p
                style={{
                  paddingInline: '3em',
                  fontSize: '12px',
                  textAlign: 'left',
                  marginTop: '30px',
                }}
              >
                <Typography style={{ fontSize: '11px', color: '#A2ADB8' }}>
                  {' '}
                  {this.state.moderatorInfoList3?.description}{' '}
                </Typography>
              </p>
              <Button
                variant="contained"
                style={webStyle.myButton}
                onClick={() => {
                  this.props.setStep(3);
                }}
              >
                Sign up now
              </Button>

              <p style={{ marginTop: '20px' }}>
                <a
                  onClick={() => {
                    this.props.navigation.navigate('UpcomingRooms');
                  }}
                  style={{
                    textDecoration: 'none',
                    fontSize: '15px',
                    color: '#98A4B0',
                    cursor: 'pointer',
                  }}
                >
                  Maybe later{' '}
                </a>{' '}
              </p>
            </Container>
          </div>
        </Grid>
      </ThemeProvider>
      // Customizable Area Start
    );
  }
}

const webStyle = {
  container: {
    width: '800px',
    marginTop: '40px',
    borderRadius: '20px',
    marginLeft: '50%',
    transform: 'translateX(-50%)',
    display: 'grid',
    height: '90%',
    alignContent: 'center',
  },
  myButton: {
    backgroundColor: '#010039',
    color: 'white',
    width: '68%',
    borderRadius: '8px',
    padding: '10px',
    marginTop: '40px',
  },
  mainHead: {
    marginTop: '25px',
    color: 'dark',
    fontWeight: '2px',
  },
  subContent: {
    opacity: '0.8',
    margin: '0px',
    textAlign: 'left',
    padding: '0px 60px',
  },
  liContent: {
    fontSize: '14px',
    color: '#8A98A6',
    margin: '20px 0px',
    listStyle: 'disc',
  },
  avtarGroup: {
    marginTop: '10px',
    justifyContent: 'center',
  },
  mainBox: {
    textAlign: 'center',
    width: '75%',
    margin: '0 auto',
  },
};
// Customizable Area End
