Object.defineProperty(exports, "__esModule", {
    value: true
  });
  
  // Customizable Area Start
  const url = require('../../../../framework/src/config.js')
  exports.httpGetType = "GET";
  exports.httpPostType = "POST";
  exports.httpDeleteType = "DELETE";
  exports.httpPutType = "PUT";
  exports.ApiContentType = "application/json";
 
 exports.updateCoins = 'bx_block_ycoins/ycoins?ycoin[ycoins]=';
 exports.fetchFreeCoins = 'bx_block_ycoins/free_ycoins';
 exports.createOrder = url.baseURL + '/bx_block_payments/razorpays';
 exports.getUserDetails = 'bx_block_profile/profiles/';
 // Customizable Area End
 