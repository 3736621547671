// Customizable Area Start
import React from "react";
import {
    Button, Grid, Accordion, AccordionSummary, Container,
    List, ListItem, ListItemSecondaryAction, ListItemText, ListItemAvatar, Avatar,
    Hidden, Typography, Box
} from "@material-ui/core";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { plusIcon } from '../../../Settings5/src/assets';
import DifferentRoomsController, { Props } from "../DifferentRoomsController";
import { yellowheart, backgroundImage, goldenTick } from "../assets";
import CircularProgress from '@material-ui/core/CircularProgress';
import "./Moderator.web.css";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from "react-router-dom";
import Draggable from 'react-draggable';
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import AlertBlock from "../../../alert/src/Alert.web";
import Slide from '@material-ui/core/Slide';



const ageArray = [
    "18-24",
    "25-29",
    "30-39",
    "40-49",
    "50-59",
    "60+"
]

const genderArray = [
    {key: "Male", value: "Male"},
    {key: "Female", value: "Female"},
    {key: "Non-Binary", value: "Non-binary"},
    {key: "Not-disclose", value: "Do not wish to disclose"},
]


class ModeratorList extends DifferentRoomsController {

    constructor(props: any) {
        super(props);
    }

    async componentDidMount() {
        this.getCategoriesListData();

        this.getModeratorList();
    }

    handleCloseAlert = () => {
        this.setState({ isMessagePop: false })
    }

    selectedCat(index: any) {
        if (this.state?.getCategoriesList?.some((item: any) => item.isSelected === true)) {
            let findIndex = this.state?.getCategoriesList?.findIndex((item: any) => item.isSelected === true)
            this.state.getCategoriesList[findIndex].isSelected = !this.state.getCategoriesList[findIndex].isSelected;
            this.state.getCategoriesList[index].isSelected = !this.state.getCategoriesList[index].isSelected;
            this.setState({ getCategoriesList: this.state.getCategoriesList })
        }
        else {
            this.state.getCategoriesList[index].isSelected = !this.state.getCategoriesList[index].isSelected;
            this.setState({ getCategoriesList: this.state.getCategoriesList })
        }
    }

    ageList = (type?: string) => {
        if (type === 'mobile') {
            return (
                <section className="categories-aside">

                {/* Category Explore all */}
                <div className="daf p15 categories-head" style={{ margin: "0px"}}>
                    <p style={{ margin: "0px", fontWeight: 500 }}>Age</p>
                </div>
                {ageArray.length ?
                        ageArray?.map((age: any, index: any) => (
                            <div
                                className={`daf bBottom ${this.state.moderatorFilters?.age?.includes(age) ? "selected-category" : ""}`}
                                key={age || index}>
                                <label className="data flex-sb pointer">
                                    {age}
                                    <input
                                        checked={this.state.selected_age === age}
                                        onChange={() => this.setState(prev =>
                                            ({ selected_age: prev.selected_age === age ? '' : age }))}
                                        type="checkbox"
                                    />
                                </label>
                            </div>
                        ))
                        : <div>
                            <Button
                                style={{
                                    width: "100%",
                                    justifyContent: "start",
                                    textTransform: "capitalize",
                                    paddingLeft: "20px"
                                }}
                            >
                                No data found
                            </Button></div>
                    }
                </section>
            )
        } else {
            return (
                <section>
                <Accordion
                    style={{
                        marginTop: "30px",
                        marginBottom: "30px",
    
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ color: "#000", fontSize: "24px" }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ margin: "0px" }}
                    >
                        <p style={{ margin: "0px 0px", fontWeight: 500, fontSize: "18px" }}>Age</p>
                    </AccordionSummary>
    
                    {ageArray.length ?
                        ageArray?.map((age: any, index: any) => (
                            <div
                                className={`daf bBottom ${this.state.moderatorFilters?.age?.includes(age) ? "selected-category" : ""}`}
                                key={age || index}>
                                <label className="data flex-sb pointer">
                                    {age}
                                    <input
                                        checked={this.state.selected_age === age}
                                        onChange={() => this.setState(prev =>
                                            ({ selected_age: prev.selected_age === age ? '' : age }))}
                                        type="checkbox"
                                    />
                                </label>
                            </div>
                        ))
                        : <div>
                            <Button
                                style={{
                                    width: "100%",
                                    justifyContent: "start",
                                    textTransform: "capitalize",
                                    paddingLeft: "20px"
                                }}
                            >
                                No data found
                            </Button></div>
                    }
                </Accordion>
            </section>
            )
        }
    }

    genderList = (type?: string) => {
        if (type === 'mobile') {
            return (
                <section className="categories-aside" >

                {/* Category Explore all */}
                <div className="daf p15 categories-head" style={{ margin: "0px"}}>
                    <p style={{ margin: "0px", fontWeight: 500 }}>Gender</p>
                </div>
                {genderArray.length ?
                            genderArray?.map((each, index: any) => (
                                <div
                                    className={`daf bBottom ${this.state.moderatorFilters?.gender?.includes(each.key) ? "selected-category" : ""}`}
                                    key={each.key || index}
                                >
                                    <label className="data flex-sb pointer">
                                        {each.value}
                                        <input
                                            checked={this.state.selected_gender === each.key}
                                            onChange={() => this.setState(prev => ({
                                                selected_gender: prev.selected_gender === each.key ? '' : each.key
                                            }))}
                                            type="checkbox"
                                        />
                                    </label>
    
                                </div>
                            ))
                            : <div>
                                <Button
                                    style={{
                                        width: "100%",
                                        justifyContent: "start",
                                        textTransform: "capitalize",
                                        paddingLeft: "20px"
                                    }}
                                >
                                    No data found
                                </Button></div>
                        }
                </section>
            )
        } else {
            return (
                <>
                    {/* Gender list accordion with checkbox */}
                    <section style={{ overflow: 'hidden' }}>
                    <Accordion
                        style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon style={{ color: "#000", fontSize: "24px" }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{ margin: "0px" }}
                        >
                            <p style={{ margin: "0px 0px", fontWeight: 500, fontSize: "18px" }}>Gender</p>
                        </AccordionSummary>
    
                        {genderArray.length ?
                            genderArray?.map((each, index: any) => (
                                <div
                                    className={`daf bBottom ${this.state.moderatorFilters?.gender?.includes(each.key) ? "selected-category" : ""}`}
                                    key={each.key || index}
                                >
                                    <label className="data flex-sb pointer">
                                        {each.value}
                                        <input
                                            checked={this.state.selected_gender === each.key}
                                            onChange={() => this.setState(prev => ({
                                                selected_gender: prev.selected_gender === each.key ? '' : each.key
                                            }))}
                                            type="checkbox"
                                        />
                                    </label>
    
                                </div>
                            ))
                            : <div>
                                <Button
                                    style={{
                                        width: "100%",
                                        justifyContent: "start",
                                        textTransform: "capitalize",
                                        paddingLeft: "20px"
                                    }}
                                >
                                    No data found
                                </Button></div>
                        }
                    </Accordion>
                </section>
                </>
            )
        }
    }

    categoryList = (type?: string) => {
        return (
            <section className="categories-aside mb15">

            {/* Category Explore all */}
            <div className="daf p15 categories-head">
                <p style={{ margin: "0px", fontWeight: 500 }}>Categories</p>
                <p style={{ margin: "0px", fontWeight: 500, cursor: "pointer" }} onClick={() => {
                    this.props?.navigation?.navigate("CategoriessubcategoriesWeb")
                }}>Explore all</p>
            </div>

            {/* categoryList Button */}
            <div className="buttons-div-moderator"> {console.log("pathname", this.state.getCategoriesList)
            }
                {
                    this.state?.getCategoriesList.length > 0 &&
                    this.state?.getCategoriesList?.map((item: any, index: any) => {

                        return (
                            <Button
                                key={item?.catId}
                                className={item.isSelected ? "buttons-div-moderator btn-Category-inner" : "buttons-div-moderator btn-unselected"}
                                variant='contained'
                                onClick={(e) => {
                                    this.selectedCat(index)
                                    this.getSubcategories(item?.catId);
                                    this.setState({ btnSelect: item?.catName })
                                }}
                            >
                                {item?.catName}
                            </Button>
                        )
                    })
                }

            </div>


            {/* SubcategoryList with checkbox */}
            <section style={ type === 'mobile' ? { overflow: "auto",height: "calc(100% - 21vh)"  } : {overflow: "hidden"}}>

                {this.state.subCategoryList.map((subCat: any, ind: number) => {
                    return (
                        <div
                            className={`daf bBottom ${this.state.moderatorFilters?.relationship?.includes(subCat) ? "selected-category" : ""}`}
                            key={subCat?.id || ind}
                        >
                            <label className="data flex-sb pointer">
                                {subCat.attributes.name}
                                <input
                                    onChange={() => {
                                        this.setState(prev =>
                                        ({
                                            selected_subcategory:
                                                prev.selected_subcategory === subCat?.id ? "" : subCat?.id
                                        }))
                                    }}
                                    checked={this.state.selected_subcategory === subCat?.id}
                                    type="checkbox"
                                />
                            </label>
                        </div>
                    )
                })
                }
            </section>
        </section>
        )
    }

    render() {

        return (
            <>

                <div style={{ marginBottom: "80px" }}>
                </div>

                <Container maxWidth="xl" className='moderator-wrapperr' style={{ backgroundColor: "#fcfbfcfc", paddingTop: "30px", minHeight: "100%" }}>
                    <Grid container spacing={3} style={{ position: "relative" }}>

                        {/* Background Y Images  */}
                        <div className="background-image-y-outter">
                            <img className="background-image1-y-inner" src={backgroundImage} alt="Yimage" />
                            <img className="background-image2-y-inner" src={backgroundImage} alt="Yimage" />
                            <img className="background-image3-y-inner" src={backgroundImage} alt="Yimage" />
                        </div>


                        {/* Grid 1 start : Left-Side-Bar  */}
                        <Hidden smDown>
                        <Grid item className="scroll-bar" style={{ padding: "0px" }} xs={12} sm={12} md={3} lg={3}>
                            <section style={{
                                position: "relative",
                                overflow: "auto",
                                // maxHeight :"calc(100vh - 66px)",
                                width: "100%"

                            }}>

                                {this.categoryList()}

                                {/* Age list accordion with checkbox */}
                                {this.ageList()}

                                {/* Gender list accordion with checkbox */}
                                {this.genderList()}
                            </section>

                        </Grid>
                        </Hidden>
                        <Hidden mdUp>
                        <Box
                            id="bottomComponent"
                            style={{
                                position: 'fixed',
                                bottom: 0,
                                width: '100%',
                                borderTopLeftRadius: 20,
                                borderTopRightRadius: 20,
                                zIndex: 1200,
                            }}
                            sx={{
                                bgcolor: !this.state.selectedFilter
                                ? '#fff'
                                : 'rgba(0, 0, 0, 0)',
                                border: '1px solid #E0E0E0',
                                boxShadow: !this.state.selectedFilter
                                ? '0px 0px 10px rgba(0, 0, 0, 0.25)'
                                : 'none',
                            }}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            >
                            {this.state.selectedFilter === null && (
                                <>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    sx={{
                                    borderRight: '1px solid #E0E0E0',
                                    padding: '1.2rem .7rem',
                                    }}
                                    onClick={() =>
                                    this.handleChangeMobileFilter('categories')
                                    }
                                >
                                    <Typography
                                    style={{
                                        color: '#27272A',
                                        fontSize: '.9rem',
                                        fontWeight: 500,
                                    }}
                                    >
                                    Categories
                                    </Typography>
                                    <ExpandLessIcon style={{ fontSize: 15 }} />
                                </Box>

                                <Box
                                    display="flex"
                                    alignItems="center"
                                    sx={{
                                    borderRight: '1px solid #E0E0E0',
                                    padding: '1.2rem .7rem',
                                    }}
                                    onClick={() =>
                                    this.handleChangeMobileFilter('age')
                                    }
                                >
                                    <Typography
                                    style={{
                                        color: '#27272A',
                                        fontSize: '.9rem',
                                        fontWeight: 500,
                                    }}
                                    >
                                    Age
                                    </Typography>
                                    <ExpandLessIcon style={{ fontSize: 15 }} />
                                </Box>

                                <Box
                                    display="flex"
                                    alignItems="center"
                                    sx={{ padding: '1.2rem .7rem' }}
                                    onClick={() =>
                                    this.handleChangeMobileFilter('gender')
                                    }
                                >
                                    <Typography
                                    style={{
                                        color: '#27272A',
                                        fontSize: '.9rem',
                                        fontWeight: 500,
                                    }}
                                    >
                                    Gender
                                    </Typography>
                                    <ExpandLessIcon style={{ fontSize: 15 }} />
                                </Box>
                                </>
                            )}

                            {this.state.selectedFilter && (
                                <>
                                <div
                                    style={{
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100dvh',
                                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                    zIndex: 1200,
                                    backdropFilter: 'blur(4px)',
                                    }}
                                    onClick={() => this.handleChangeMobileFilter(null)}
                                />
                                <Draggable
                                    axis="y"
                                    bounds={{ top: 0 }}
                                    onStop={this.handleDrag}
                                    position={this.state.defaultPosition}
                                    handle=".drag-handle"
                                >
                                    <Slide direction="up" in={!!this.state.selectedFilter}>
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            height={
                                            this.state.selectedFilter === 'categories'
                                                ? '80vh'
                                                : 'auto'
                                            }
                                            zIndex={1300}
                                            flex={1}
                                            bgcolor="#fff"
                                            style={{
                                            borderTopLeftRadius: 20,
                                            borderTopRightRadius: 20,
                                            }}
                                        >
                                        <div style={{ height: '100%', width: '100%' }}>
                                            {(() => {
                                                switch (this.state.selectedFilter) {
                                                case 'categories':
                                                    return this.categoryList('mobile');
                                                case 'age':
                                                    return this.ageList('mobile');
                                                case 'gender':
                                                    return this.genderList('mobile');
                                                default:
                                                    return <></>;
                                                }
                                            })()}
                                        </div>
                                    </Box>
                                    </Slide>
                                </Draggable>
                                </>
                            )}
                            </Box>
                        </Hidden>
                        {/* Grid 1 end  */}



                        {/* Grid 2 start : Main-Content */}
                        <AlertBlock data={{ show: this.state.isMessagePop, close: this.handleCloseAlert, msg: this.msg, type: this.type, vertical: "top", horizontal: "center" }} />

                        <Grid className="outer-moderator-list" item xs={12} sm={12} md={6} lg={6} style={{ paddingTop: "0px" }}>
                            <section >

                                <div className="main-div-outter-moderator-list">
                                    <span className='Heading-moderator' style={{ position: "relative" }}>
                                        Moderators
                                    </span>
                                </div>

                                <div className="Moderator-content-outter">
                                    <List dense className=''>
                                        {this.state.loading && this.state?.getModeratorListData == null ?
                                            <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "center", height: "70%" }}>
                                                <CircularProgress />
                                            </div>
                                            : ""
                                        }

                                        {
                                            !this.state.loading &&

                                                this.state?.getModeratorListData?.length > 0 ? this.state?.getModeratorListData?.map((value: any, index: any) => {

                                                    const labelId = `checkbox-list-secondary-label-${value}`;
                                                    return (
                                                        <div className="list-item-outter" key={value.toString() + index.toString()} >
                                                            <ListItem>
                                                                {value?.attributes?.profile_image ?
                                                                    <ListItemAvatar style={{ alignSelf: 'flex-start', marginTop: "19px" }}>
                                                                        <Link to={{ pathname: "/UserProfile", state: { userId: value.id } }}>
                                                                            <img height="40px" width="40px" style={{ borderRadius: "50%", cursor: "pointer" }} src={value?.attributes?.profile_image} alt="profileImage" />
                                                                        </Link>
                                                                    </ListItemAvatar>
                                                                    :
                                                                    <Link to={{ pathname: "/UserProfile", state: { userId: value.id } }}>
                                                                        <Avatar style={{ alignSelf: 'flex-start', marginBottom: "26px", marginRight: "10px", cursor: "pointer" }} />
                                                                    </Link>
                                                                }

                                                                <img src={goldenTick} style={{ width: "15px", borderRadius: "50%", position: "absolute", top: '52px', left: "40px" }} />


                                                                <ListItemText className="list-item-content" id={labelId}>
                                                                    <div className='list-first-div'>
                                                                        <Link to={{ pathname: "/UserProfile", state: { userId: value.id } }} className='list-first-div-inside'>
                                                                            <span style={{ cursor: "pointer" }}>{value?.attributes?.full_name}</span>
                                                                        </Link>
                                                                        <span className='span-after-username'><img height="13px" width="13px" style={{ marginRight: "5px" }} src={yellowheart} />{value?.attributes?.hearts}</span>
                                                                        <span className='span-after-username'>
                                                                            {`(${value?.attributes?.age_range[0]}
                                                                                ${value?.attributes?.age_range[1] && "-"}
                                                                                ${value?.attributes?.age_range[1]} yrs)`
                                                                            }</span>
                                                                    </div>
                                                                    <div className="list-second-div">
                                                                        <span>{`${value?.attributes?.number_of_followers} followers` || `0 followers`}</span>
                                                                        <span className='rooms'><FiberManualRecordIcon />{value?.attributes?.room_count} rooms hosted</span>
                                                                    </div>

                                                                    {value?.attributes?.moderator_profile ?
                                                                        <p className="list-thirth-text">{value?.attributes?.moderator_profile[0]?.bio}</p>
                                                                        : null
                                                                    }
                                                                </ListItemText>
                                                                <ListItemSecondaryAction>
                                                                    {/* { index == 0 ?
                                                    <Button>Following</Button> 
                                                    : */}
                                                                    {
                                                                        value?.attributes?.is_following ?
                                                                            <Button style={{ background: "#010138", color: "#fff" }} onClick={() => this.sendUnFollowRequest(value?.id)} >
                                                                                Following
                                                                            </Button> :

                                                                            <Button style={{ background: "#fff", color: "#101038" }} onClick={() => this.sendFollowRequest(value?.id)}>
                                                                                <img height="10px" width="10px" style={{ marginRight: "5px" }} src={plusIcon} alt='plusicon' />
                                                                                Follow
                                                                            </Button>
                                                                    }
                                                                    {/* }  */}
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                        </div>
                                                    );
                                                })

                                                : null
                                        }
                                        {
                                            this.state?.getModeratorListData != null && this.state?.getModeratorListData?.length == 0 &&
                                            <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "center", height: "70%" }}>
                                                <h2>No Data Found</h2>
                                            </div>
                                        }



                                    </List>
                                </div>

                            </section>
                        </Grid>
                        {/* Grid 2 end  */}

                    </Grid>
                </Container>
            </>
        );
    }
}

export default ModeratorList;


  // Customizable Area End