import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
export const configJSON = require("./config");
export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  moderatorInfoList1: any;
  moderatorInfoList2: any;
  moderatorInfoList3: any;
  moderatorInfoList4: any;
  moderatorInfoList5: any;
  moderatorInfoList6: any;
  isReapplying: boolean;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OnboardingguideController<P> extends BlockComponent<
  P & Props,
  S,
  SS
> {
  moderatorInfoId: any;
  getUserProfileDataId: string;
  constructor(props: P & Props) {
    // Customizable Area Start
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      moderatorInfoList1: [],
      moderatorInfoList2: [],
      moderatorInfoList3: [],
      moderatorInfoList4: [],
      moderatorInfoList5: [],
      moderatorInfoList6: [],
      isReapplying: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (this.moderatorInfoId === apiRequestCallId) {
          this.setState({
            moderatorInfoList1: responseJson.data[0],
            moderatorInfoList2: responseJson.data[1],
            moderatorInfoList3: responseJson.data[2],
            moderatorInfoList4: responseJson.data[3],
            moderatorInfoList5: responseJson.data[4],
            moderatorInfoList6: responseJson.data[5],
          });
        } else if (this.getUserProfileDataId === apiRequestCallId) {
          this.setState({
            isReapplying:
              responseJson.data?.attributes?.moderator_rejection_time ?? false,
          });
        }
      }
    }

    // Customizable Area End
  }

  getUserProfileData(userId: string | null) {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserProfileDataId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserProfileDataAPIEndPoint + userId
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getModeratorInfo() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.moderatorInfoId = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getModeratorInfoApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  skipOnboarding() {
    // Customizable Area Start
    const msg: Message = new Message(
      getName(MessageEnum.OnboardingGuideControllerSkipOnboarding)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Customizable Area End
  }

  exitOnboarding() {
    // Customizable Area Start
    const msg: Message = new Message(
      getName(MessageEnum.OnboardingGuideControllerDoneOnboarding)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Customizable Area End
  }
}

// Customizable Area End
