import {
   Grid, TextField, Box, CircularProgress
} from '@material-ui/core'
import React from 'react'
import RefillWalletController from './RefillWalletWebController'
import './refillWallet.css'
import RazorPay from './RazorpayGateway.web';
//@ts-ignore
import StateContext from '../../../../web/src/globalState'
import { refillComplete, ylanesLogo } from '../assets';
import CloseIcon from '@material-ui/icons/Close';

export default class RefillWallet extends RefillWalletController {

   static contextType: any = StateContext
   constructor(props: any) {
      super(props);
      this.handleOpen = this.handleOpen.bind(this)
   }

   handleSuccess = (value: number) => {
      this.setState({ isPurchaseSuccess: true })
      if (this.props && this.props.addValue) {
         this.props.addValue(value)
         this.context.setCoin(value, true)
      } else {
         this.context.setCoin(value, true)
      }
   }

   handleOpen(event?: any) {
      this.setState({ modalOpen: !this.state.modalOpen })
      this.setState({ isPurchaseSuccess: false })
      // if (this.state.isPurchaseSuccess) this.setState({ isPurchaseSuccess: false })
   }

   handleChange(value: string) {
      if (value.length <= 4) {
         if (this.state.errorMessage2) this.setState({ errorMessage2: false })
         if (+value < 10000) {
            if (this.state.errorMessage) this.setState({ errorMessage: false })
            this.setState({ coinAmount: value }, () => {
               let withoutTax = +this.state.coinAmount * this.state.freeYCoinsArray.one_ycoin_value
               let total = withoutTax + (withoutTax * (this.state.freeYCoinsArray.tax_percentage / 100))
               this.setState({ totalAmount: total })
            });
            if (this.state.freeYCoinsArray?.ycoins.data.length > 0) {
               let coinArr = this.state.freeYCoinsArray.ycoins.data
               let findedCoin: any = coinArr.findIndex((item: any) => item.attributes.start_amount <= +value && item.attributes.end_amount >= +value)
               if (findedCoin > -1) {
                  this.setState({ freeCoin: coinArr[findedCoin].attributes.free_ycoins, suggetsFreeCoin: coinArr[findedCoin + 1]?.attributes?.free_ycoins })
                  console.log(this.state.freeCoin)
                  if (+value > 0 && value !== '' && value < coinArr[coinArr.length - 1].attributes.start_amount) {
                     this.setState({ suggestCoin: (coinArr[findedCoin + 1]?.attributes?.start_amount - +value).toString() })
                  } else {
                     this.setState({ suggestCoin: '' })
                  }
               } else {
                  this.setState({ suggestCoin: '' })
               }
            }
         } else {
            this.setState({ errorMessage: true })
            setTimeout(() => {
               this.setState({ errorMessage: false })
            }, 5000)
         }
      } else {
         this.setState({ errorMessage2: true })
         setTimeout(() => {
            this.setState({ errorMessage2: false })
         }, 2000)
      }
   }


   render() {
      return (
         <>
            <Box className={this.state.modalOpen ? 'coinRefillBox active' : 'coinRefillBox'} onClick={(e) => this.handleOpen(e)}>
               {this.state.isPurchaseSuccess ?
                  <Grid className="coinRefillBoxSuccess" container onClick={(e) => { e.stopPropagation() }}>
                     <Grid item container className="coinRefillBoxImg">
                        <img src={refillComplete} />
                        <img className="ylanesLogo" src={ylanesLogo} />
                     </Grid>
                     <div className="coinRefillBoxSuccessText">
                        <span className="coinRefillBoxSuccessTextBold">Hurray! We have updated your wallet</span>
                        <span>Thank you for being a valued member of the community.</span>
                        <span className="coinRefillBoxSuccessTextBold">See you soon in the Ylanes!</span>
                     </div>
                     {/* <div className="coinRefillBoxSuccessReferFriend">Refer a friend</div> */}
                     <div className="coinRefillBoxSuccessContinue" onClick={(e) => {
                        this.handleOpen(e);
                        if (this.props.cb) {
                           this.props.cb();
                           setTimeout(() => {
                              if (this.props.handleOpen)
                                 this.props.handleOpen();
                           }, 200);
                        }
                     }}>Continue</div>
                  </Grid>
                  :
                  <Grid className="coinRefillBoxContent" container onClick={(e) => { e.stopPropagation() }}>
                     <Grid item style={{ fontSize: '25px', padding: '10px', paddingLeft: '0px', paddingTop: '0px' }} xs={12}>Refill Wallet</Grid>
                     <CloseIcon onClick={() => this.handleOpen()} />
                     <Grid item style={{ fontSize: '16px', paddingBottom: '30px' }} xs={12}>
                        <Grid item xs={12} style={{ paddingBottom: '5px' }}>Amount</Grid>
                        <Grid item xs={12}>
                           <TextField
                              style={{ width: '100%' }}
                              id="outlined-input"
                              type="number"
                              label=""
                              placeholder='0'
                              value={this.state.coinAmount}
                              defaultValue=""
                              onChange={(e) => (this.handleChange(e.target.value))}
                              InputProps={{

                              }}
                           />
                           {this.state.errorMessage || this.state.errorMessage2 ? <Grid item xs={12} style={{ color: 'red', marginTop: '10px', }}>You can buy up to 9999 coins.</Grid> : ''}
                           {/* {this.state.errorMessage2 ? <Grid item xs={12} style={{ color: 'red', marginTop: '10px', }}>* Coin amount can maximum 4 digit number</Grid> : ''} */}
                        </Grid>
                     </Grid>
                     {/* Data Table*/}
                     <Grid container xs={12} item>
                        <Grid item md={7} lg={7} xs={12} container spacing={0} alignItems="flex-start" justifyContent="flex-end">
                           <Grid item xs={12} container style={{ paddingBottom: '5px', paddingRight: '10px' }}>
                              <Grid item md={12} style={{ paddingBottom: '0px' }} className="boldText">1 YCoin = {this.state.freeYCoinsArray.one_ycoin_value} INR</Grid>
                              <Grid item md={12} style={{ color: '#aeaeae', textAlign: 'left', fontSize: '16px' }}>Popular Buy: Pay For <b>5000 YCoins</b>, Get <b>3000 YCoins FREE</b></Grid>
                              <Grid item md={12} style={{ paddingBottom: '0px', textAlign: 'left' }} className="boldText">{'Total Amount (Inclusive of taxes): ₹ ' + this.state.totalAmount}</Grid>
                              <Grid item md={12} style={{ color: '#aeaeae', textAlign: 'left', fontSize: '16px' }}>No cash refund for YCoins purchased</Grid>
                           </Grid>
                        </Grid>
                        <Grid md={5} lg={5} xs={12}>
                           <Grid container className="coinPriceOutsideBox" xs={12}>
                              <Grid className="coinLabel" xs={6} >
                                 Buy YCoins
                              </Grid>
                              <Grid className="coinLabel" xs={6} >
                                 Free YCoins
                              </Grid>
                              {this.state.isLoading ?
                                 <Grid item container xs={12} style={{ height: '100%', justifyContent: 'center' }} alignItems="center">
                                    <div>
                                       <CircularProgress size={80} thickness={4} style={{ color: '#ffcb00' }} />
                                    </div>
                                 </Grid>
                                 :
                                 this.state.freeYCoinsArray?.ycoins?.data.length > 0 && this.state.freeYCoinsArray?.ycoins?.data.map((item: any) => {
                                    return (
                                       <React.Fragment key={item.id}>
                                          <Grid container className="coinBody" xs={6} lg={6} >
                                             <span className="coinDataSpan">{item?.attributes?.start_amount}</span>
                                          </Grid>
                                          <Grid container className="coinBody" xs={6} lg={6}>
                                             <span className="coinDataSpan">{item?.attributes?.free_ycoins}</span>
                                          </Grid>
                                       </React.Fragment>
                                    )
                                 }
                                 )}
                           </Grid>
                        </Grid>
                        <Grid item xs={12} container alignItems="flex-end" justifyContent="center">
                           <Grid item xs={12} lg={12} md={12} className="coinEarnBox">{+this.state.suggestCoin > 0 ? 'If you buy ' + this.state.suggestCoin + ' more YCoins, you will get ' + this.state.suggetsFreeCoin + ' YCoins free' : ''}</Grid>
                           <RazorPay freeCoin={this.state.freeCoin} handleSuccess={this.handleSuccess} coinAmount={this.state.coinAmount} totalAmount={this.state.totalAmount} />
                        </Grid>
                     </Grid>
                  </Grid>
               }
            </Box>
         </>
      )
   }
}

 // Customizable Area End