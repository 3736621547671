import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import { Grid } from '@material-ui/core'

export const RoomCard = () => {
   return (  
      <section className="room-details bgWhite mb20 blueCard">
         <div className="bBottom p15">
            <div>
               {/* <Skeleton variant="circle" width="50px" animation="wave" height="50px" /> */}
               <Skeleton variant="text" width="40%" animation="wave" height="50px" />
               <div className=" mb10">
                  <Skeleton variant="rect" width={120} height={15} animation="wave" />
               </div>
               <Skeleton variant="rect" width="100%" animation="wave" height="150px" />
            </div>
         </div>
         <div className="text-end daf p15 mr10">
            <Skeleton width="100%" animation="wave" height="30px" /> 
         </div>
      </section>
   )
}

export const ParticipantCard = () => {
   return (  
      <section className="room-details bgWhite mb20 blueCard">
         <div className="bBottom p15">
            <div>
               <div className="d-flex mb10">
                  <Skeleton className="mr10" variant="circle" width="40px" animation="wave" height="40px" />
                  <Skeleton variant="text" width="30%" animation="wave" height="30px" />
               </div>
               <Skeleton variant="rect" width="100%" animation="wave" height="50px" />
            </div>
         </div>
      </section>
   )
}

export const YourCard = () => {
   return (  
      <Grid xs={12} className="hostOutside">
         <div className="bBottom p15">
            <div>
               <div className="d-flex mb10">
                  <Skeleton className="mr10" variant="circle" width="40px" animation="wave" height="40px" />
                  <Skeleton variant="text" width="30%" animation="wave" height="30px" />
               </div>
               <Skeleton variant="rect" width="100%" animation="wave" height="250px" />
            </div>
         </div>
      </Grid>
   )
}