import React from "react";
import { Box, Container, Typography, Grid, Button } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { styled, createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  highlightedUnderline,
  logo,
  ceo,
  coo,
  mobileMarkup,
  bgPartical,
  linkedin,
} from "../assets";

import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import HeaderNoLogin from "../../../../components/src/HeaderNoLogin";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFCB00",
    },
    secondary: {
      main: "#010039",
    },
  },
});

const HighlightedText = styled("span")({
  position: "relative",
  paddingBottom: "4px",
  "&:after": {
    content: `url(${highlightedUnderline})`,
    position: "absolute",
    bottom: 0,
    left: "50%",
    right: "50%",
    height: "4px",
    transform: "translate(-50%, -50%)",
    width: "75%",
  },
});

export interface Props {
  navigation: any;
  id: string;
}

export default class AboutPage extends BlockComponent<
  Props,
  { aboutPageData: string },
  {}
> {
  fetchAboutPageId: string;
  firstSectonRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      aboutPageData: "",
    };
    this.firstSectonRef = React.createRef();

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.fetchAboutPageData();
    this.firstSectonRef.current?.scrollIntoView();
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.fetchAboutPageId) {
          this.setState({
            aboutPageData:
              responseJson.data?.[0]?.attributes?.description ?? "",
          });
        }
      }
    }
  }

  fetchAboutPageData = () => {
    const headers = {
      "Content-Type": "application/json",
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchAboutPageId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_admin/about_us"
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  render() {
    const { navigation, id } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <div ref={this.firstSectonRef}>
          <HeaderNoLogin navigation={navigation} id={id} />
          <Container>
            <Box my={2} textAlign="center">
              <Typography
                color="secondary"
                style={{
                  fontSize: "48px",
                  fontWeight: 700,
                  marginBottom: "64px",
                }}
              >
                <HighlightedText>About YLan</HighlightedText>
                es
              </Typography>
              <Typography
                style={{
                  fontSize: "24px",
                  color: "#807F9C",
                  marginBottom: "16px",
                  whiteSpace: "pre-wrap",
                }}
              >
                {this.state.aboutPageData}
              </Typography>
            </Box>
          </Container>
          <Box mt={20} mb={10} py={4} bgcolor="#FBFBFB">
            <Container
              style={{
                position: "relative",
              }}
            >
              <img
                alt="bg ring"
                src={bgPartical}
                style={{
                  position: "absolute",
                  right: "0%",
                  top: "40%",
                }}
              />
              <Grid
                spacing={4}
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item md={4}>
                  <img src={mobileMarkup} alt="mobile markup" width="390px" />
                </Grid>
                <Grid item md={8}>
                  <Typography
                    style={{
                      fontSize: "24px",
                      color: "#807F9C",
                      marginBottom: "48px",
                    }}
                  >
                    YLanes enables Meaningful Conversations with People with
                    similar interests people
                  </Typography>
                  <Box>
                    {benifits.map((benifit, index) => (
                      <Grid
                        container
                        style={{ marginBottom: "24px" }}
                        key={index}
                      >
                        <Grid item md={1}>
                          <CheckCircleIcon color="primary" fontSize="large" />
                        </Grid>
                        <Grid item md={11}>
                          <Typography
                            color="secondary"
                            style={{
                              fontSize: "24px",
                              fontWeight: 500,
                            }}
                          >
                            {benifit.title}
                          </Typography>
                          <Typography
                            color="secondary"
                            style={{
                              fontSize: "18px",
                              color: "#807F9C",
                            }}
                          >
                            {benifit.description}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Container>
            <Box mb={8} textAlign="center">
              <Typography
                color="secondary"
                style={{
                  fontSize: "48px",
                  fontWeight: 700,
                  marginBottom: "64px",
                }}
              >
                YL
                <HighlightedText>anes Found</HighlightedText>
                ers
              </Typography>
              <Grid container justifyContent="center" spacing={8}>
                {members.map((member, index) => (
                  <Grid item key={index}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <img
                          height="144px"
                          src={member.img}
                          alt={member.name}
                        />
                      </Grid>
                      <Grid item>
                        <Box textAlign="left">
                          <Typography
                            color="secondary"
                            style={{
                              fontSize: "32px",
                              fontWeight: 500,
                            }}
                          >
                            {member.name}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "18px",
                              color: "#807F9C",
                            }}
                          >
                            {member.position}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "18px",
                              color: "#807F9C",
                            }}
                          >
                            {member.extras}
                          </Typography>
                          <Typography
                            component={"a"}
                            href={member.profileUrl}
                            target="_blank"
                            style={{
                              fontSize: "18px",
                              color: "#807F9C",
                            }}
                          >
                            <img
                              src={linkedin}
                              alt="linkedin"
                              style={{
                                verticalAlign: "top",
                                marginRight: theme.spacing(1),
                              }}
                            />
                            View LinkedIn Profile
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Container>
        </div>
      </ThemeProvider>
    );
  }
}

const benifits = [
  {
    title: "Share your stories & ideas",
    description: "on topics that matter to you (anonymously if you wish)",
  },
  {
    title: 'Connect with those who "Get You"',
    description: "from our wide network across the world",
  },
  {
    title: "Hold a safe space for others",
    description:
      "to share their thoughts, on topics of your experience or expertise.",
  },
];

const members = [
  {
    name: "Rajesh Ivaturi",
    img: ceo,
    position: "Co-Founder, CEO",
    extras: "IIM-A, Ex-Partner EY",
    profileUrl: "https://in.linkedin.com/in/rajeshivaturi",
  },
  {
    name: "Deepti Punjabi",
    img: coo,
    position: "Co-Founder, COO",
    extras: "IIM-C, Deutsche Bank",
    profileUrl: "https://in.linkedin.com/in/deepti-punjabi-43a02418",
  },
];
