import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton';
import { topRightYellow, walletIcon } from '../../assets';

const WalletBalance = (props: any) => {
   return (
      <div>
         <div className="walletBalanceMain">
            <img src={topRightYellow} />
            <img className="walletIcon" src={walletIcon} />
            <span className="balance">
               Wallet balance
            </span>
            <span className="coinAmount">
               {props.isLoading || props.userProfile.ycoins === undefined ? <Skeleton width="100%" animation="wave" height="100%" /> : props.userProfile.ycoins + ' YCoins'}
            </span>
         </div>
         <div className="p40">
            <div className="refillWalletButton" onClick={() => { props.openModal() }}>{'Refill Wallet'}</div>
         </div>
      </div>
   )
}

export default WalletBalance;