// Customizable Area Start
import React, { PureComponent } from "react";
import "./dropdown.css"
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';

interface Select extends Props {
    value?: string;
    valueToShow?: any[];
    valueToStore?: any;
}

interface Props {
    name?: string;
    list: string[];
    trial?: any[];
    defaultText?: string;
    defaultValue?: any;
    onChange?: (event: any) => any;
    // value?:string | string[];
    className?: string;
    IconElement?: any;
    style?: {}
    classNameOptions?: string;
    styleOptions?: {};
    type?: string;
    textAlign?: "center" | "right" | "left";
    onBlur?: (event: any) => any
    disabled?: boolean
}

interface State {
    value: any;
    phone?: null | number;
}


//Component for Dropdown gender for setup profile page

export default class DropDown extends PureComponent<Select, State, any> {
    private myRef: React.RefObject<HTMLInputElement>;
    private dRef: React.RefObject<HTMLInputElement>;
    constructor(props: Props) {
        super(props)
        this.state = {
            value: ""
        }
        this.handleDropDown = this.handleDropDown.bind(this)
        this.onChange = this.onChange.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this)
        this.myRef = React.createRef()
        this.dRef = React.createRef()
    }

    componentDidMount() {
        document.querySelector(".dropdown-container *")
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside(event: any) {
        if (this.myRef?.current && !this.myRef?.current.contains(event.target) && this.myRef.current?.className.indexOf("block") > -1) {
            this.myRef.current?.classList.remove('block')
            this.dRef.current?.classList.remove('bottom-flat-border')

            this.props.onBlur && this.props.onBlur({
                target: {
                    name: this.props.name
                }
            })

        }
    }

    handleDropDown(e: any) {

        if (this.myRef.current?.className.indexOf("block") === -1) {
            this.myRef.current?.classList.add('block')
            this.dRef.current?.classList.add('bottom-flat-border')
        }
        else {
            this.myRef.current?.classList.remove('block')
            this.dRef.current?.classList.remove('bottom-flat-border')
        }
    }
    onChange(ab: any) {
        ab()
    }
    render() {
        const {
            name,
            list,
            defaultText,
            onChange: OnChange,
            value,
            valueToShow,
            valueToStore,
            className,
            IconElement,
            style,
            classNameOptions,
            styleOptions,
            type,
            textAlign,
            disabled,
            onBlur: OnBlur,
        } = this.props


        return (
            <div
                onClick={!disabled ? this.handleDropDown : () => { }}
                className={`dropdown-container ${className}`}
            >
                <div className="flex_sb_center dropdown-border" ref={this.dRef} style={{ textAlign, ...style }} >
                    <div className="dropdown-value" style={{ opacity: value ? "1" : "0.4", fontWeight: 500 }}> {value || defaultText || "Default Text"} </div>
                    {!disabled && (IconElement ? IconElement : <KeyboardArrowDownRoundedIcon className='dropdown-icon' />)}
                </div>
                <div id="Demoo" ref={this.myRef} className="dropdown-content-container ">
                    {/* <div className="flex_sb_center dropdown-content-border" style={{textAlign, ...style}} >
                        <div className="dropdown-value"> { value || defaultText || "Default Text" } </div>
                        { IconElement ? IconElement : <KeyboardArrowDownRoundedIcon className='dropdown-icon'  />}
                    </div>                 */}
                    <div className='dropdown-content-scroll'>
                        {
                            list && list.length > 0 ?
                                list.map(
                                    (item, ind) => {
                                        const isPresent = value === item
                                        return <div
                                            className={`dropdown-content dropdown-content-border ${isPresent ? "selected" : ""} ${classNameOptions}`}
                                            key={ind}
                                            onClick={
                                                () => {
                                                    const event = {
                                                        target: {
                                                            value: item,
                                                            name
                                                        }
                                                    }

                                                    OnChange && OnChange(event)
                                                }
                                            }

                                            style={{ ...styleOptions }}
                                        >
                                            {item}
                                        </div>
                                    }
                                )
                                : <div className='dropdown-content dropdown-content-border'>
                                    No data found
                                </div>
                        }
                    </div>
                </div>
            </div>

        )
    }
}

interface PhoneProps extends Select {
    phone?: string
}

//Component for Mobile input for sign up page
export class MobileInput extends PureComponent<PhoneProps, State, any> {
    private myRef: React.RefObject<HTMLInputElement>;
    private dRef: React.RefObject<HTMLInputElement>;
    item: React.RefObject<any>;
    constructor(props: PhoneProps) {
        super(props)
        this.state = {
            value: "",
            phone: null
        }
        this.handleDropDown = this.handleDropDown.bind(this)
        this.onChange = this.onChange.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this)
        this.myRef = React.createRef()
        this.dRef = React.createRef()
        this.item = React.createRef()
    }

    componentDidMount() {
        document.querySelector(".dropdown-container *")
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside(event: any) {
        if (this.myRef?.current && !this.myRef?.current.contains(event.target) && this.myRef.current?.className.indexOf("block") > -1) {
            this.myRef.current?.classList.remove('block')
            this.dRef.current?.classList.remove('bottom-flat-border')
        }
    }

    handleDropDown(e: any) {

        if (this.myRef.current?.className.indexOf("block") === -1) {
            this.myRef.current?.classList.add('block')
            this.dRef.current?.classList.add('bottom-flat-border')
        }
        else {
            this.myRef.current?.classList.remove('block')
            this.dRef.current?.classList.remove('bottom-flat-border')
        }
    }
    onChange(ab: any) {
        ab()
    }



    render() {
        const { list,
            trial,
            defaultText,
            defaultValue,
            onChange: OnChange,
            value,
            valueToShow,
            valueToStore,
            className,
            IconElement,
            style,
            classNameOptions,
            styleOptions,
            type,
            textAlign,
            onBlur: OnBlur,
            name,
            phone,
        } = this.props

        return (
            <div className={`dropdown-container ${className}`} >
                <div className="flex_sb_center dropdown-border" ref={this.dRef} style={{ textAlign, ...style }} >
                    <div className="dropdown-value country-code-div"> {value || "Default Text"} </div>
                    <input
                        disabled={true}
                        name={name}
                        onClick={(e) => e.stopPropagation()}
                        // type="number" 

                        className='phone-input'
                        value={this.props.phone}
                        placeholder={defaultText || "Default Text"}
                        onChange={(e) => {
                            if (RegExp(/^[0-9]{0,12}$/).test(e.target.value)) {
                                const event = {
                                    target: {
                                        value: defaultValue,
                                        phoneNumber: e.target.value
                                    }
                                }
                                // @ts-ignore
                                this.state.phone = e.target.value
                                OnChange && OnChange(event)
                            }



                        }}
                        onBlur={(e) => {
                            const event = {
                                target: {
                                    name
                                }
                            }
                            OnBlur && OnBlur(event)
                        }}
                    />
                </div>
                <div id="Demoo" ref={this.myRef} className="dropdown-content-container">
                    <div className='dropdown-content-scroll' style={{ maxHeight: "230px" }}>
                        {
                            trial?.map(
                                (item, ind) => {
                                    const isPresent = value === item.flag + " " + item.dial_code
                                    return <div
                                        className={`dropdown-content pointer ${isPresent ? "selected" : ""} ${classNameOptions}`}
                                        key={ind}
                                        onClick={
                                            () => {
                                                const event = {
                                                    target: {
                                                        value: item,
                                                        phoneNumber: this.state.phone
                                                    }
                                                }

                                                OnChange && OnChange(event)
                                            }
                                        }
                                        style={{ ...styleOptions }}
                                    >
                                        <span className="drop_down_flag_style">{item.flag}</span><span className="drop_down_countryName_style">{item.name}</span>
                                    </div>
                                }
                            )
                        }
                    </div>
                </div>
            </div>

        )
    }
}
// Customizable Area End
