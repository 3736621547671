// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Snackbar,
} from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import HeaderNoLogin from '../../../../components/src/HeaderNoLogin';
import { FacebookIcon, InstaIcon, HomeIcon, MailIcon, TwitterIcon } from '../assets';

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFCB00",
    },
    secondary: {
      main: "#010039",
    },
  },
});

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  name: string;
  email: string;
  message: string;
  snackbar: {
    isOpen: boolean;
    message: string;
    severity: "error" | "success" | "info" | "warning";
  };
}

const socialMediaLinks = [
  {
    name: 'Facebook',
    link: 'https://www.facebook.com/people/YLanes/100087925750413',
    icon: <FacebookIcon style={{ width: '1.9rem', height: '1.9rem' }} />
  },
  {
    name: 'Instagram',
    link: 'https://www.instagram.com/ylanes_official/',
    icon: <InstaIcon style={{ width: '1.9rem', height: '1.9rem' }} />
  },
  {
    name: 'Twitter',
    link: 'https://twitter.com/Ylanes_official',
    icon: <TwitterIcon style={{ width: '1.9rem', height: '1.9rem' }} />
  },
];

interface CBProps {
  mt?: number;
  textAlign?: 'start' | 'center' | 'end';
  icon: any;
  title: string;
}

const ContactBox: React.FC<CBProps> = ({
  mt = 0,
  icon,
  title,
  textAlign = "start",
  children,
}) => {
  return (
    <Box textAlign={textAlign} sx={{ mt }}>
      <Box display="flex">
        {/* <img src={icon} alt="icon" style={{ marginRight: '1rem' }} /> */}
        {icon}
        <Typography
          variant="body1"
          style={{ fontWeight: 600, fontSize: "1.2rem" }}
        >
          {title}
        </Typography>
      </Box>
      <Box style={{ marginLeft: "3.5rem" }}>
        <Typography
          style={{ fontSize: ".8rem", fontWeight: 500, opacity: 0.9 }}
        >
          {children}
        </Typography>
      </Box>
    </Box>
  );
};

export default class ContactUsPage extends BlockComponent<Props, S, {}> {
  // messagesId to be subscribed
  sendRequestMessageId: string;
  firstSectonRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.firstSectonRef = React.createRef();

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      name: "",
      email: "",
      message: "",
      snackbar: { isOpen: false, message: "", severity: "error" },
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.firstSectonRef.current?.scrollIntoView();
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let errorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (errorJson) {
        console.log(errorJson);
        return;
      }

      if (apiCallId === this.sendRequestMessageId) {
        const errors = responseJson?.errors;
        if (errors) {
          const error = errors[0];
          this.openSnackbar(error.message, "error");
          return;
        }

        this.openSnackbar(
          "Your message has been sent successfully.",
          "success"
        );
        this.setState((prev) => ({
          ...prev,
          name: "",
          email: "",
          message: "",
        }));
      }
    }
  }

  openSnackbar = (
    message: string,
    severity: "error" | "success" | "info" | "warning"
  ) => {
    this.setState((prev) => ({
      ...prev,
      snackbar: { isOpen: true, message, severity },
    }));
  };

  closeSnackbar = () => {
    this.setState((prev) => ({
      ...prev,
      snackbar: { ...prev.snackbar, isOpen: false },
    }));
  };

  handleChange = (type: string, value: string) => {
    this.setState((prev) => ({ ...prev, [type]: value }));
  };

  validateName = (name: string) => {
    const namePattern: RegExp = /^[A-Za-z]+(?:\s+[A-Za-z]+)*$/;
    return namePattern.test(name);
  };

  validateEmail = (email: string) => {
    const emailPattern: RegExp =
      /^[a-z0-9]+([._%+-][a-z0-9]+)*@[a-z0-9.-]+\.[a-z]{2,4}$/i;
    return emailPattern.test(email);
  };

  sendContactUs = (e: React.FormEvent) => {
    e.preventDefault();
    const { name, email, message } = this.state;
    if (!name.trim().length || !email.trim().length || !message.trim().length) {
      this.openSnackbar("Please fill all fields", "error");
      return;
    }

    // validate name
    if (name.trim().length < 3) {
      this.openSnackbar("Name should contain atleast 3 characters.", "error");
      return;
    }

    // validate name
    if (!this.validateName(name.trim())) {
      this.openSnackbar("Please enter a valid name", "error");
      return;
    }

    // validate email
    if (!this.validateEmail(email)) {
      this.openSnackbar("Please enter a valid email", "error");
      return;
    }

    // validate message
    if (message.trim().length < 10) {
      this.openSnackbar(
        "Message should contain atleast 10 characters.",
        "error"
      );
      return;
    }

    const data = {
      name,
      email,
      description: message,
    };

    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendRequestMessageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/contact_us"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  render() {
    const { navigation, id } = this.props;
    const { name, email, message } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <div ref={this.firstSectonRef}>
          <HeaderNoLogin navigation={navigation} id={id} />

          <Snackbar
            open={this.state.snackbar.isOpen}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            onClose={this.closeSnackbar}
            autoHideDuration={5000}
          >
            <Alert
              severity={this.state.snackbar.severity}
              color={this.state.snackbar.severity}
              variant="filled"
              onClose={this.closeSnackbar}
            >
              {this.state.snackbar.message ||
                "Something went wrong — Please try again!"}
            </Alert>
          </Snackbar>

          <Container>
            <Box mb={8} mt={4} textAlign="center">
              <Typography variant="h2" style={{ fontWeight: 500 }}>
                Contact Us
              </Typography>
              <br />
              <Box display="flex" justifyContent="space-between">
                <form
                  onSubmit={this.sendContactUs}
                  style={{
                    boxShadow: "rgb(0 0 0 / 8%) 0px 4px 12px",
                    padding: "1.5rem",
                    flex: 1,
                    borderRadius: "5px",
                  }}
                >
                  <TextField
                    color="secondary"
                    style={{ margin: "8px 0px" }}
                    fullWidth
                    label="Your name"
                    variant="outlined"
                    value={name}
                    onChange={(e) => this.handleChange("name", e.target.value)}
                  />
                  <TextField
                    color="secondary"
                    style={{ margin: "8px 0px" }}
                    fullWidth
                    label="Your email"
                    variant="outlined"
                    type="email"
                    value={email}
                    onChange={(e) => this.handleChange("email", e.target.value)}
                  />
                  <TextField
                    color="secondary"
                    style={{ margin: "8px 0px" }}
                    fullWidth
                    multiline
                    label="Your message"
                    variant="outlined"
                    maxRows={7}
                    minRows={7}
                    value={message}
                    onChange={(e) =>
                      this.handleChange("message", e.target.value)
                    }
                  />
                  <Box
                    style={{
                      margin: "8px 0px",
                      width: "100%",
                      textAlign: "left",
                    }}
                  >
                    <Button
                      color="secondary"
                      size="large"
                      variant="contained"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Box>
                </form>

                {/* contact us  */}
                <Box
                  sx={{ ml: 4, p: 3 }}
                  style={{
                    boxShadow: "rgb(0 0 0 / 8%) 0px 4px 12px",
                    borderRadius: "5px",
                  }}
                  height="80%"
                >
                  {/* address */}
                  <ContactBox
                    icon={<HomeIcon style={{ marginRight: '1rem', width: '2.5rem', height: '2.5rem' }} />}
                    title="Reach Us On"
                  >
                    Madivam Connects Pvt Ltd <br />
                    HD - 088, WeWork Enam Sambhav C - 20 <br />
                    BKC G Block Road <br />
                    Mumbai 400051 <br />
                    Maharashtra <br />
                    India
                  </ContactBox>

                  {/* email */}
                  <ContactBox
                    icon={<MailIcon style={{ marginRight: '1rem', width: '2.5rem', height: '2.5rem' }} />}
                    title="Email Us On"
                    mt={3.5}
                  >
                    support@ylanes.com
                  </ContactBox>

                  {/* Social media links */}
                  <Box sx={{ mt: 5 }}>
                    <Box>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 600, fontSize: "1.2rem" }}
                      >
                        Connect With Social
                      </Typography>

                      <Box
                        display="flex"
                        alignItems="center"
                        sx={{ mt: 2, pb: 4 }}
                        justifyContent="center"
                      >
                        {socialMediaLinks.map((item, index) => {
                          return (
                            <a
                              href={item.link}
                              target="_blank"
                              rel="noreferrer"
                              style={{
                                marginRight:
                                  index === socialMediaLinks.length - 1
                                    ? 0
                                    : "1.3rem",
                              }}
                            >
                              {item.icon}
                            </a>
                          );
                        })}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Container>
        </div>
      </ThemeProvider>
    );
  }
}
// Customizable Area End
