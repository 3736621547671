// Customizable Area Start

import React from "react";
import Stepper from "@material-ui/core/Stepper";
import {
  Button,
  Grid,
  TextField,
  Container,
  withWidth,
  CircularProgress,
  Box
} from "@material-ui/core";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import AlertBlock from "../../../alert/src/Alert.web";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import "./verification.css";
import Onboarding from "./onBoarding.web";
import OtpInput from "react-otp-input";
import EmailAccountRegistrationController from "../EmailAccountRegistrationController";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import { withRouter, RouteComponentProps } from "react-router-dom";
import * as firebase from "firebase";
import "firebase/auth";
import * as Yup from "yup";
import DropDown from "./Dropdown.web";

import { withStyles } from "@material-ui/core/styles";
import StepConnector from "@material-ui/core/StepConnector";

import ClearIcon from "@material-ui/icons/Clear";
import {
  Card,
  CardContent,
  CardActions,
  CardHeader,
  FormControl,
} from "@material-ui/core";
import { Formik } from "formik";
import { Chip } from "@material-ui/core";
import {
  celebrationImage2,
  welcomeImg,
  wallet_image,
  profile_imageIcon,
  otpLogo,
} from "../assets";
import TextSlider from "./textSlider";

interface Props extends RouteComponentProps<any> {
  activeStep: number;
  setActiveStep: (step: number) => void;
  setStep: (step: number) => void;
  handleOnboardingDialogClose: () => void;
  width: Breakpoint;
}

const getReferCode = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const referralCode = params.get("referral_code");

  return referralCode;
}

class OtpVerification extends EmailAccountRegistrationController<Props> {
  otp = 1234;
  topSectonRef: React.RefObject<HTMLInputElement>;

  constructor(props: any) {
    super(props);
    this.topSectonRef = React.createRef();
    this.yearList = this.yearList.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    //@ts-ignore
    this.handleAlert = this.handleAlert.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.setState({
      activeStep: this.props.activeStep,
    });
  }

  async componentDidMount() {
    this.timer();
    if (this.state.activeStep === 0) this.getPolicyDetails();
    if (this.state.activeStep === 2) this.subCategoryList();

    // add recaptcha
    const verifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      }
    );

    this.setState({ recaptchaVerifier: verifier });
  }

  componentDidUpdate(prevProps: Props, prevState: { activeStep: number }) {
    if (prevState.activeStep !== this.state.activeStep) {
      this.topSectonRef?.current?.scrollIntoView();
      this.props.setActiveStep(this.state.activeStep);
      if (this.state.activeStep === 1) this.getPolicyDetails();
      if (this.state.activeStep === 2) this.subCategoryList();
    }
  }

  setupSchema = Yup.object().shape({
    subCategories: Yup.array()
      .min(3, "Please choose a min of 3 preferred subcategories")
      .required("Please choose a min of 3 preferred subcategories"),
    profileName: Yup.string()
      .required("Please enter a profile name.")
      .matches(/^[aA-zZ\s]+$/, "Please enter valid User name.")
      .max(28),
    gender: Yup.string().required("Please select your gender"),
    userName: Yup.string()
      .required("Please enter a username")
      .matches(
        /^[aA-zZ0-9_]+$/,
        "Only letters, numbers, and underscores are allowed"
      )
      .max(15, "User Name must be of 15 characters"),
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Please enter a valid email"),
    yearOfBirth: Yup.string().required("Please select your year of birth"),
  });

  options = [
    // { label: "Placeholder", value: "" },
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Non - Binary", value: "nonBinary" },
    { label: "Do not wish to disclose", value: "notDisclose" },
  ];

  yearList(back: number) {
    const year = new Date().getFullYear() - 18;
    return Array.from({ length: back }, (v, i) => year - i);
  }

  option1 = this.yearList(100);

  option2 = [
    "Starting a romantic relationship",
    "Marry?-Why?Why not?",
    "2nd (nth) marriage",
    "LGBTQ + relationship",
    "Making a relationship/marriage works",
  ];

  handleCategoriesChange = (event: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      this.setState({
        selected:
          this.state.selected.length === this.options.length
            ? []
            : this.options,
      });
      return;
    }
    this.setState({ selected: value });
  };

  handleClose() {
    this.setState({ isMessagePop: false });
  }

  handleChange(value1: any, event: any) {
    //@ts-ignore
    this.setState({ [value1]: event.target.value });
  }

  handleOTPChange = (otp: any) =>
    this.setState({ otp: otp }, () => {
      if (this.state.otp.length === 6) {
        this.handleNext(null);
      }
    });

  handleSubmit(event: any) {
    const data = new FormData(event.target);
    event.preventDefault();
  }

  inputfocus = (elmnt: any) => {
    if (elmnt.key == "a") return false;

    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      console.log("next");

      const next = elmnt.target.tabIndex;
      if (next < 4) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  deleteSelected = (value: any) => {
    const data = this.state.selected.filter((item: any) => item !== value);
    this.setState({ selected: data });
  };

  handleNext = (e: any) => {
    this.setState({ isChecking: true });
    if (e) {
      e.preventDefault();
    }

    const { otp } = this.state;
    const confirmationResult = (window as any).confirmationResult;

    if (!confirmationResult) {
      return;
    }

    confirmationResult
      .confirm(otp)
      .then((result: any) => {
        const user = result.user;

        // pass user token to backend
        const phone_number = localStorage.getItem("p_no");
        if (!phone_number) return;

        // get FCM token
        this.handleGetFirebaseToken({
          mobile: phone_number,
          otp,
          token: user.ya,
        });
      })
      .catch((error: Error) => {
        this.setState({ isChecking: false, otp: "" });
        this.handleAlert("Invalid OTP. Please try again.");
      });
  };

  QontoConnector = withStyles({
    active: {
      "& $line": {
        borderColor: "#000037",
      },
    },
    completed: {
      "& $line": {
        borderColor: "#000037",
      },
    },
    line: {
      borderColor: "#f2f3f5",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  })(StepConnector);

  render() {
    const { width } = this.props;

    const isSmall = width === "sm" || width === "xs";

    function getSteps() {
      return ["Verify Number", "Setup Profile", "Onboarding"];
    }

    const steps = getSteps();

    // const handleSetup = () => {
    //     this.setState({ activeStep: this.state.activeStep + 1, profileSetupCompleted : true ,hideStepper:true });
    // }

    // const handleBack = () => {
    //   this.setState({ activeStep: this.state.activeStep - 1 });
    // };

    const handleReset = () => {
      this.setState({ activeStep: 0, otp: "" });
    };

    const proceedOnboarding = (e: any) => {
      e.preventDefault();
      const sessionToken = sessionStorage.getItem("token") ?? "";
      localStorage.setItem("token", sessionToken);
      sessionStorage.removeItem("token");
      this.setState({ profileSetupCompleted: false, hideStepper: false });
    };
    const minutes = Math.floor(this.state.otpTimer / 60);
    const seconds = this.state.otpTimer - minutes * 60;
    return (
      <>
        <Container ref={this.topSectonRef}>
          <AlertBlock
            data={{
              show: this.state.isMessagePop,
              close: this.handleClose,
              msg: this.msg,
              type: this.type,
              vertical: "top",
              horizontal: "center",
            }}
          />

          {/* STEPPER */}
          {!this.state.hideStepper && (
            <Container maxWidth="md">
              <Stepper
                alternativeLabel={isSmall}
                activeStep={
                  this.state.activeStep > 1
                    ? this.state.activeStep - 1
                    : this.state.activeStep
                }
                connector={<this.QontoConnector />}
              >
                {steps.map((label, index) => {
                  const stepProps: { completed?: boolean } = {};
                  stepProps.completed =
                    index <
                    (this.state.activeStep > 1
                      ? this.state.activeStep - 1
                      : this.state.activeStep);
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel StepIconComponent={QontoStepIcon}>
                        {label}
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Container>
          )}

          {/* OTP VERIFICATION */}
          {this.state.activeStep === 0 ? (
            <Container maxWidth="sm">
              <div style={{ textAlign: "center" }}>
                <h2
                  style={{
                    fontWeight: 500,
                    fontSize: "30px",
                    marginBottom: "8px",
                  }}
                >
                  OTP Verification
                </h2>
                <p>
                  Please enter 6 digit code that we have sent you on associated
                  mobile number
                </p>
                <div style={{ marginBottom: "40px" }}>
                  <img
                    style={{ maxHeight: "158px" }}
                    src={otpLogo}
                    alt="image"
                  />
                </div>

                {this.state.isChecking ? (
                  <CircularProgress color="primary" />
                ) : (
                  <form>
                    <div className="center-otp-container">
                      {/* Input for OTP */}
                      <OtpInput
                        value={this.state.otp}
                        onChange={this.handleOTPChange}
                        numInputs={6}
                        focusStyle={true}
                        shouldAutoFocus={true}
                        isInputNum={true}
                        isInputSecure={true}
                        containerStyle={{
                          flexWrap: "wrap",
                          justifyContent: "center",
                        }}
                        inputStyle={{
                          color: "#000",
                          width: "50px",
                          margin: "10px 8px",
                          textAlign: "center",
                          fontSize: "25px",
                          height: "50px",
                          borderRadius: "10px",
                          border: "1px solid rgb(208, 198, 198)",
                        }}
                      />
                    </div>
                    <div className="submit-button-div">
                      <Button
                        style={{ width: isSmall ? "100%" : "50%" }}
                        className="submit-btn"
                        type="submit"
                        value="Submit"
                        variant="contained"
                        color="primary"
                        onClick={this.handleNext}
                      >
                        Verify
                      </Button>
                    </div>
                  </form>
                )}
                <div className="bottom-text">
                  <p className="text-small">
                    Code expires in {"0" + minutes} :{" "}
                    {seconds < 10 ? "0" + seconds : seconds}{" "}
                  </p>

                  <p className="text-big">
                    Didn't get the code?
                    <strong
                      style={{
                        cursor: this.state.otpTimer ? "not-allowed" : "pointer",
                        color: this.state.otpTimer ? "#00000052 " : "#010138",
                        marginLeft: ".3rem",
                      }}
                      onClick={() => {
                        if (this.state.otpTimer) return;
                        this.resendOTP();
                      }}
                    >
                      Resend OTP
                    </strong>
                  </p>
                </div>
              </div>
            </Container>
          ) : null}

          {/* T & C */}
          {this.state.activeStep === 1 ? (
            <div
              className={isSmall ? "" : "div-container"}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "40px 0px",
                whiteSpace: "pre-wrap",
                color: "#7F8E9D",
                textAlign: "justify",
                fontSize: "16px",
              }}
            >
              <div style={{ width: "fit-content" }}>
                <img
                  style={{
                    height: "270px",
                    width: "270px",
                    marginBottom: "10px",
                    borderRadius: "8px",
                    objectFit: "cover",
                  }}
                  src={welcomeImg}
                  alt="celebrationImage"
                />
              </div>
              <TextSlider
                incActiveStep={() => {
                  this.setState({ activeStep: 2 });
                }}
                policyDetailsSplited={this.state.policyDetailsSplited}
              />
            </div>
          ) : null}

          {/* SETUP PROFILE */}
          {this.state.activeStep === 2 ? (
            <Grid container justify-content="center" spacing={1}>
              <Grid item>
                <Card elevation={0}>
                  <CardHeader
                    style={{ textAlign: "center", padding: "0 0 16px" }}
                    title="Setup Profile"
                  ></CardHeader>
                  <Formik
                    initialValues={{
                      profileImageURL: "",
                      profileImage: "",
                      profileName: "",
                      gender: "",
                      userName: "",
                      email: "",
                      yearOfBirth: "",
                      subCategories: [],
                      referCode: getReferCode() || ''
                    }}
                    validationSchema={this.setupSchema}
                    onSubmit={(values) => {
                      let formData = new FormData();
                      formData.append("profile_img", values.profileImage);
                      // const selectedIds = values?.subCategories?.map(item => this.state.subCategories?.filter((item1: any) => item1 === item)[0]?.id)
                      const selectedIds = values?.subCategories?.map(
                        (item) => item[1]
                      );

                      localStorage.setItem("userName", values.userName);
                      let payload = {
                        type: "sms_account",
                        attributes: {
                          full_phone_number: localStorage.getItem("p_no"),
                          //full_phone_number: "+919998887777",
                          full_name: values.profileName,
                          user_name: values.userName,
                          birth_year: values.yearOfBirth,
                          gender:
                            values?.gender === "Do not wish to disclose"
                              ? "Not-disclose"
                              : values?.gender.replace(/\s/g, ""),
                          email: values.email,
                          sub_categories: selectedIds,
                          referred_by: values.referCode
                        },
                      };
                      formData.append("data", JSON.stringify(payload));
                      this.setupProfileData(formData);
                      // handleSetup()
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                      handleSubmit,
                    }) => {
                      return (
                        <form onSubmit={handleSubmit}>
                          <input
                            type="file"
                            accept="image/*"
                            id="profileImage"
                            hidden
                            onChange={(e) => {
                              //@ts-ignore
                              if (e.target.files[0]?.type.includes("image")) {
                                const objectUrl =
                                  e.target.files &&
                                  URL.createObjectURL(e.target.files[0]);
                                setFieldValue("profileImageURL", objectUrl);
                                setFieldValue(
                                  "profileImage",
                                  e.target.files ? e.target.files[0] : ""
                                );
                              } else {
                                this.handleAlert(`Only images are supported.`);
                              }
                            }}
                          />
                          <div
                            className="AccountCircleIcon"
                            style={{
                              margin: "0px auto",
                              width: "60px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              document.getElementById("profileImage")?.click()
                            }
                          >
                            {values.profileImageURL ? (
                              <img
                                width={62}
                                height={62}
                                src={values.profileImageURL}
                              />
                            ) : (
                              <img
                                width={62}
                                height={62}
                                src={profile_imageIcon}
                              />
                            )}
                          </div>
                          {/* <AccountCircleIcon className="AccountCircleIcon" /> */}

                          <CardContent className="card-contain">
                            <Grid
                              item
                              container
                              spacing={1}
                              justify-content="center"
                            >
                              <Grid item xs={12} sm={6} md={6}>
                                <label>Profile Name</label>
                                <TextField
                                  placeholder="Maximum character should be 50"
                                  variant="outlined"
                                  fullWidth
                                  name="profileName"
                                  value={values.profileName}
                                  inputProps={{ maxLength: 50 }}
                                  onChange={(e) => {
                                    e.target.value = e.target.value
                                      ?.replace(/[^a-zA-Z]/g, "")
                                      .substr(0, 50);
                                    // e.target.value = e.target.value?.trimStart()
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                // autoFocus
                                />
                                {(touched.profileName && errors.profileName && (
                                  <span
                                    style={{
                                      display: "block",
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {errors.profileName &&
                                      touched.profileName &&
                                      errors.profileName}
                                  </span>
                                )) || (
                                    <label className="small-label">
                                      Change allowed once per month
                                    </label>
                                  )}
                              </Grid>

                              <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth variant="outlined">
                                  <label>Gender</label>
                                  <DropDown
                                    list={this.options.map(
                                      (item) => item?.label
                                    )}
                                    value={values.gender}
                                    name="gender"
                                    onChange={handleChange}
                                    className="MuiOutlinedInput-root"
                                    defaultText="Select gender"
                                    onBlur={handleBlur}
                                  />
                                </FormControl>
                                {(touched.gender && errors.gender && (
                                  <span
                                    style={{
                                      display: "block",
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {errors.gender &&
                                      touched.gender &&
                                      errors.gender}
                                  </span>
                                )) || (
                                    <label className="small-label">
                                      Can be changed only once
                                    </label>
                                  )}
                              </Grid>

                              <Grid item xs={12} sm={6} md={6}>
                                <label>User Name</label>
                                <TextField
                                  placeholder="Maximum character should be 50"
                                  variant="outlined"
                                  fullWidth
                                  name="userName"
                                  value={values.userName}
                                  inputProps={{ maxLength: 50 }}
                                  onChange={(e) => {
                                    e.target.value =
                                      e.target.value?.trimStart();
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                />
                                {(touched.userName && errors.userName && (
                                  <span
                                    style={{
                                      display: "block",
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {errors.userName &&
                                      touched.userName &&
                                      errors.userName}
                                  </span>
                                )) || (
                                    <>
                                      <label className="small-label">
                                        Numbers, letters and undescores only;
                                      </label>
                                      <br />
                                      <label className="small-label">
                                        Change allowed twice per year
                                      </label>
                                    </>
                                  )}
                              </Grid>

                              <Grid item xs={12} sm={6} md={6}>
                                <label>Email</label>
                                <TextField
                                  placeholder="Eg. aaron@abc.com"
                                  variant="outlined"
                                  fullWidth
                                  name="email"
                                  value={values.email}
                                  onChange={(e) => {
                                    e.target.value = e.target.value?.trim();
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                />
                                {(touched.email && errors.email && (
                                  <span
                                    style={{
                                      display: "block",
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {errors.email &&
                                      touched.email &&
                                      errors.email}
                                  </span>
                                )) || (
                                    <>
                                      <label className="small-label">
                                        Change allowed once per month. Not visible
                                        to others.
                                      </label>
                                    </>
                                  )}
                              </Grid>

                              <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth variant="outlined">
                                  <label>Year of Birth</label>
                                  <DropDown
                                    list={this.option1.map((item) =>
                                      item.toString()
                                    )}
                                    value={values.yearOfBirth}
                                    name="yearOfBirth"
                                    onChange={handleChange}
                                    className="MuiOutlinedInput-root"
                                    defaultText="Select year of birth"
                                    onBlur={handleBlur}
                                  />
                                </FormControl>
                                {(touched.yearOfBirth && errors.yearOfBirth && (
                                  <span
                                    style={{
                                      display: "block",
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {errors.yearOfBirth &&
                                      touched.yearOfBirth &&
                                      errors.yearOfBirth}
                                  </span>
                                )) || (
                                    <>
                                      <label className="small-label">
                                        You must be 18+. Year cannot be edited
                                      </label>
                                      <br />
                                      <label className="small-label">
                                        Only your age range will be visible to
                                        others.
                                      </label>
                                    </>
                                  )}
                              </Grid>

                              <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth variant="outlined">
                                  <label>Preferred Subcategories</label>
                                  <Select
                                    displayEmpty={true}
                                    labelId="mutiple-select-label"
                                    multiple
                                    className="blur-placeholder"
                                    value={values.subCategories}
                                    name="subCategories"
                                    onChange={handleChange}
                                    IconComponent={KeyboardArrowDownRoundedIcon}
                                    onClose={(e) => {
                                      handleBlur({
                                        target: { name: "subCategories" },
                                      });
                                    }}
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight: 48 * 4.5 + 8,
                                          width: 250,
                                        },
                                      },
                                      getContentAnchorEl: null,
                                      anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "center",
                                      },
                                      transformOrigin: {
                                        vertical: "top",
                                        horizontal: "center",
                                      },
                                      variant: "menu",
                                    }}
                                    inputProps={{ style: { fontSize: 10 } }} // font size of input text
                                    renderValue={() => (
                                      <div style={{ fontSize: 13 }}>
                                        Choose min 3 favourite subcategories
                                      </div>
                                    )}
                                  >
                                    {this.state.subCategories?.map(
                                      (option: any) => (
                                        <MenuItem
                                          key={option[1]}
                                          value={option}
                                          style={{
                                            whiteSpace: "break-spaces",
                                          }}
                                        >
                                          <ListItemText primary={option[0]} />
                                          <ListItemIcon>
                                            {/* @ts-ignore*/}
                                            <Checkbox
                                              checked={values.subCategories?.some(
                                                (itm) => itm[1] == option[1]
                                              )}
                                            />
                                          </ListItemIcon>
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                  {(touched.subCategories &&
                                    errors.subCategories && (
                                      <span
                                        style={{
                                          display: "block",
                                          color: "red",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {errors.subCategories &&
                                          touched.subCategories &&
                                          errors.subCategories}
                                      </span>
                                    )) || (
                                      <>
                                        <label className="small-label">
                                          Can be changed only once. Not visible to
                                          others.
                                        </label>
                                      </>
                                    )}

                                  <div className="selected-subcategories-wrapper">
                                    {values.subCategories?.map(
                                      (value: any, key: any) => (
                                        <Chip
                                          key={value[0] + key}
                                          label={value[0]}
                                          className="selected-chip"
                                          onDelete={() => {
                                            setFieldValue(
                                              "subCategories",
                                              values.subCategories.filter(
                                                (item: any) => {
                                                  return item[1] !== value[1];
                                                }
                                              )
                                            );
                                          }}
                                          deleteIcon={
                                            <ClearIcon
                                              style={{ color: "white" }}
                                            />
                                          }
                                        />
                                      )
                                    )}
                                  </div>
                                </FormControl>
                              </Grid>
                            </Grid>

                            <Grid item lg={12} style={{
                              marginTop: '1rem',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}>
                              <Box width='50%'>
                                <label>Referral Code</label>
                                <TextField
                                  placeholder="Enter your code"
                                  variant="outlined"
                                  fullWidth
                                  name="referCode"
                                  value={values.referCode}
                                  inputProps={{ maxLength: 50 }}
                                  onChange={(e) => {
                                    e.target.value =
                                      e.target.value?.trimStart();
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                  autoComplete='off'
                                />
                                {(touched.referCode && errors.referCode && (
                                  <span
                                    style={{
                                      display: "block",
                                      color: "red",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {errors.referCode &&
                                      touched.referCode &&
                                      errors.referCode}
                                  </span>
                                )) || (
                                    <label className="small-label">
                                      If you were referred to YLanes, enter your referral code and get 100 bonus YCoins!
                                    </label>
                                  )}
                              </Box>
                            </Grid>
                          </CardContent>

                          <CardActions style={{ justifyContent: "center" }}>
                            <Button
                              color="secondary"
                              variant="contained"
                              type="submit"
                              style={{
                                width: isSmall ? "100%" : "50%",
                              }}
                            >
                              Submit
                            </Button>
                          </CardActions>
                        </form>
                      );
                    }}
                  </Formik>
                </Card>
              </Grid>
            </Grid>
          ) : null}

          {/* WELCOME PAGE */}
          {this.state.profileSetupCompleted == true ? (
            <Container maxWidth="sm" style={{ textAlign: "center" }}>
              <div>
                <img
                  style={{
                    height: "270px",
                    width: "270px",
                    marginBottom: "10px",
                    borderRadius: "8px",
                    objectFit: "cover",
                  }}
                  src={celebrationImage2}
                  alt="celebrationImage"
                />
              </div>
              <div className="heading">
                {/* ts-ignore */}
                <h2>Welcome to YLanes!</h2>
              </div>
              {!this.state.hasPreviousAccount && (
                <div>
                  {/* ts-ignore */}
                  <p className="paragraph">
                    We have credited
                    <span
                      style={{ fontWeight: "bold" }}
                    >{` ${this.state.freeYCoins} free coins `}</span>
                    to your account to be spent within{" "}
                    <span style={{ fontWeight: "bold" }}>3 months.</span>
                  </p>
                </div>
              )}

              <div className="submit-button-div">
                <Button
                  className="submit-btn"
                  type="submit"
                  value="Submit"
                  variant="contained"
                  onClick={proceedOnboarding}
                >
                  Enter
                </Button>
              </div>
            </Container>
          ) : //ON-BOARDING PAGE
            this.state.activeStep === 3 && !this.state.onBordingStepper ? (
              <>
                <div className="onboard-heading" style={{ textAlign: "center" }}>
                  <p
                    style={{
                      width: isSmall ? "100%" : "65%",
                      margin: "12px auto",
                    }}
                  >
                    Dear {localStorage.getItem("userName")}, a warm welcome to the
                    YLanes community!{" "}
                  </p>
                </div>
                <div
                  className={isSmall ? "" : "div-container"}
                  style={{ textAlign: "center" }}
                >
                  <div style={{ marginBottom: "16px" }}>
                    <img
                      style={{ height: "176px" }}
                      src={wallet_image}
                      alt="wallet-image"
                    />
                  </div>
                  <div className="h3-tag">
                    <h3>Help others know you better.</h3>
                  </div>
                  {!this.state.hasPreviousAccount && (
                    <div className="earnPoint-para">
                      <p>
                        Complete your profile and earn{" "}
                        {this.state.additionalYcoins} YCoins
                      </p>
                    </div>
                  )}
                  <div className="submit-button-div">
                    <Button
                      style={{ marginTop: "16px" }}
                      className="submit-btn-onboard"
                      value="Submit"
                      variant="contained"
                      onClick={() => this.setState({ onBordingStepper: true })}
                    >
                      Next
                    </Button>
                  </div>
                  <div className="skip-btn" style={{ margin: "24px 0" }}>
                    <span onClick={() => this.props.setStep(2)}>SKIP</span>
                  </div>
                </div>
              </>
            ) : this.state.onBordingStepper == true ? (
              <Onboarding
                hideStepper={() =>
                  this.setState({ hideStepper: !this.state.hideStepper })
                }
                step={this.state.onBoardingStep}
                onBoardingQuestion={() => this.onBoardingQuestion()}
                onBoardingQuestions={this.state.onBoardingQuestions}
                onBoardingAnswers={(value: any) => this.onboardingAnswers(value)}
                redirectAdditonalModerator={() => this.props.setStep(2)}
                additionalYcoins={this.state.additionalYcoins}
                topSectonRef={this.topSectonRef}
                hasPreviousAccount={this.state.hasPreviousAccount}
              />
            ) : null}
        </Container>

        <div id="recaptcha-container" />
      </>
    );
  }
}

function QontoStepIcon(props: any) {
  const { active, completed, className } = props;

  return (
    <div>
      {completed ? (
        <CheckCircleIcon />
      ) : (
        <CheckCircleOutlineIcon style={{ color: "#f2f3f5" }} />
      )}
    </div>
  );
}

// @ts-ignore
export default withRouter(withWidth()(OtpVerification));

// Customizable Area End
