// Customizable Area Start
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import StateContext from "../../../../web/src/globalState";
export const configJSON = require("../../src/config.js");
export interface Props {
  navigation: any;
  id: string;
  history: any;
  match: any;
  step1Redirect: () => void;
}

interface S {
  isOtherOpen: boolean;
  userProfileData: any;
  connectedUserData: any;
  show: any;
  selectedUserId: any;
  sendConnectRequestData: any;
  getPastRoomData: any[];
  getUpcomingRoomData: any[];
  registerModal: boolean;
  currentRoomPrice: any;
  isMessagePop: boolean;
  addResources: boolean;
  registerAnonymously: boolean;
  isRegisteredFlag: boolean;
  participantsDetails: any;
  walletYCoins: any;
  openReportPopUp: any;
  reportValue: string;
  ownFollowers: any;
  resourceId: string;
  loading: boolean;
  likeResourceData: any;
  disLikeResourceData: any;
  acceptRejectData: any;
  disconnectUserData: any;
  removeModal: boolean;
  removeParticpantId: any;
  touchPoints: any;
  isTouchPointLoading: boolean;
  tabValue: number;
  userId: string | null;
  deniedOpen: boolean;
}

interface SS {
  id: any;
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {
  followRequestId: string;
  unfollowRequestId: string;
  getUserProfileDataId: any;
  getConnectedUserDataId: any;
  show: string;
  sendConnectRequestId: any;
  createConnectionAPIId: string;
  getPastRoomId: string;
  getUpcomingRoomId: string;
  registerRoomID: any;
  getWalletCoinsId: any;
  deleteResourceId: any;
  reportResourceId: any;
  msg = "";
  type = "";
  getOwnFollowersAPIId: any;
  likeResourceId: string;
  disLikeResourceId: string;
  acceptRejectFriendConnectionId: string;
  disconnectUserId: string;
  removeFromRoomId: any;
  blockUnBlockUserMessageID: string;
  fetchTouchPointsId: string;
  static contextType: any = StateContext;
  deniedOpen: boolean;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      isOtherOpen: false,
      userProfileData: [],
      connectedUserData: null,
      show: "public",
      selectedUserId: null,
      sendConnectRequestData: {},
      getPastRoomData: [],
      getUpcomingRoomData: [],
      registerModal: false,
      currentRoomPrice: 0,
      isMessagePop: false,
      addResources: false,
      registerAnonymously: false,
      isRegisteredFlag: false,
      participantsDetails: [],
      walletYCoins: 0,
      ownFollowers: null,
      openReportPopUp: null,
      reportValue: "",
      resourceId: "",
      loading: true,
      likeResourceData: [],
      disLikeResourceData: [],
      acceptRejectData: [],
      disconnectUserData: [],
      removeModal: false,
      removeParticpantId: null,
      touchPoints: [],
      isTouchPointLoading: false,
      tabValue: 0,
      userId: null,
      deniedOpen: false,
    };

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  handleAlert(msg = "") {
    this.msg = msg;
    this.type = "error";
    this.setState({ isMessagePop: true });
  }

  handleAlertSuccess(msg = "") {
    this.msg = msg;
    this.type = "success";
    this.setState({ isMessagePop: true });
  }

  async receive(from: String, message: Message) {
    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (this.removeFromRoomId === apiRequestCallId) {
          if (responseJson) {
            this.props.history.push("Rooms?type=my");
            // const route = this.props.history.location.search.split('=')[1];
            // this.context.setCoin(this.state.currentRoomPrice, true)
          }
        }
        if (this.fetchTouchPointsId === apiRequestCallId) {
          this.setState({
            touchPoints: responseJson,
            isTouchPointLoading: false,
          });
        }
        if (this.blockUnBlockUserMessageID === apiRequestCallId) {
          if (responseJson?.message) {
            this.handleAlertSuccess(responseJson.message || 'Success');
            this.setState((prev) => ({
              userProfileData: { ...prev.userProfileData, is_blocked: !prev.userProfileData.is_blocked }
            }));
          } else {
            this.handleAlert(responseJson?.meta?.message);
          }
        }
        if (this.registerRoomID == apiRequestCallId) {
          this.props.history.push("Rooms?type=my");
          if (responseJson?.meta) {
            // this.CongratulationRegisteringModalOpen()
            this.handleAlert(responseJson?.meta?.message);
          } else if (!responseJson?.errors) {
            // this.getUpcomingRooms();
            this.handleAlertSuccess("Register to Room Successfully!");
            this.getWalletCoins();
          } else if (responseJson?.errors) {
            this.handleAlert(
              responseJson?.errors[0]?.message || "Something went wrong!"
            );
          } else {
            this.handleAlert("Something went wrong!");
          }
        }
        if (this.disconnectUserId === apiRequestCallId) {
          if (responseJson?.message) {
            let selectedUserId = localStorage.getItem("selectedUserId");
            this.getUserProfileData(selectedUserId);
            this.handleAlertSuccess(
              responseJson?.message || "You are no longer connected."
            );
          } else {
            this.handleAlert("* Something went wrong!");
          }
        }
        if (this.acceptRejectFriendConnectionId === apiRequestCallId) {
          if (responseJson?.data || responseJson?.message) {
            let selectedUserId = localStorage.getItem("selectedUserId");
            this.getUserProfileData(selectedUserId);
            if (responseJson.data)
              this.handleAlertSuccess(
                responseJson?.message || "Accepted Successfully!"
              );
            else
              this.handleAlertSuccess(
                responseJson?.message || "Rejected Successfully!"
              );
          } else {
            this.handleAlert("* Something went wrong!");
          }
        }
        if (this.followRequestId === apiRequestCallId) {
          if (responseJson?.data) {
            let selectedUserId = localStorage.getItem("selectedUserId");
            this.getUserProfileData(selectedUserId);
            this.handleAlertSuccess("Started following Successfully!");
          } else {
            this.handleAlert("* Something went wrong!");
          }
        }
        if (this.unfollowRequestId === apiRequestCallId) {
          if (responseJson?.message) {
            let selectedUserId = localStorage.getItem("selectedUserId");
            this.getUserProfileData(selectedUserId);
            this.handleAlertSuccess("Unfollowed Successfully!");
          } else {
            this.handleAlert("* Something went wrong!");
          }
        }
        if (this.deleteResourceId === apiRequestCallId) {
          this.handleAlertSuccess("Resource deleted");
          let userId = localStorage.getItem("userId");
          this.getUserProfileData(userId);
        }
        if (this.likeResourceId === apiRequestCallId) {
          this.setState({ likeResourceData: responseJson.data });

          this.getUserProfileData(localStorage.getItem("selectedUserId"));
        }
        if (this.disLikeResourceId === apiRequestCallId) {
          this.setState({ disLikeResourceData: responseJson.data });
          this.getUserProfileData(localStorage.getItem("selectedUserId"));
        }
        if (apiRequestCallId === this.getConnectedUserDataId) {
          if (responseJson?.data) {
            this.setState({
              connectedUserData: responseJson.data,
              loading: false,
            });
          } else {
            this.setState({ loading: false, connectedUserData: [] });
          }
        }
        if (
          responseJson.data &&
          apiRequestCallId === this.getOwnFollowersAPIId
        ) {
          this.setState({ ownFollowers: responseJson.data, loading: false });
        } else if (
          !responseJson.data &&
          apiRequestCallId === this.getOwnFollowersAPIId
        ) {
          this.setState({ loading: false, ownFollowers: [] });
        }
        if (responseJson.data) {
          if (apiRequestCallId === this.getUserProfileDataId) {
            this.setState(
              {
                userProfileData: {
                  ...responseJson?.data?.attributes,
                  id: responseJson?.data?.id,
                },
              },
              () => {
                if (window.location.pathname === "/UserPersonalProfile") {
                  localStorage.setItem(
                    "profile_image",
                    this.state.userProfileData?.profile_image
                  );
                  window.dispatchEvent(new Event("storage"));
                }
              }
            );

            //@ts-ignore
            let ownUserId = localStorage.getItem("userId");
            if (ownUserId != responseJson?.data?.id) {
              this.getPastRoomData(
                this.props?.history?.location?.state?.userId,
                3,
                1,
                "past"
              );
              this.getUpcomingRoomData(
                this.props?.history?.location?.state?.userId,
                3,
                1,
                "upcoming"
              );
            }
            // let id = localStorage.setItem("selectedUserId", responseJson?.data?.id);
            const conversation_sids =
              responseJson?.data?.attributes.conversation_sid;
            if (conversation_sids.length > 0) {
              sessionStorage.setItem(
                "conversation_sid",
                conversation_sids[conversation_sids.length - 1]
              );
            } else {
              sessionStorage.setItem("conversation_sid", "");
            }
          }
          if (this.getWalletCoinsId === apiRequestCallId) {
            this.setState({
              walletYCoins: responseJson?.data?.attributes?.ycoins,
            });
          }

          if (this.reportResourceId === apiRequestCallId) {
            this.handleAlertSuccess("Resource has been reported");
            this.setState({ openReportPopUp: null });
          } else if (apiRequestCallId === this.createConnectionAPIId) {
            if (responseJson?.data?.conversation_sid) {
              // navigate
              sessionStorage.setItem(
                "conversation_sid",
                responseJson?.data?.conversation_sid
              );
              this.props.history.push({
                pathname: "/Chat",
                state: {
                  user: this.state.userProfileData,
                },
              });
            } else {
              alert("something went wrong ....");
            }
          } else if (apiRequestCallId === this.sendConnectRequestId) {
            if (responseJson?.data?.attributes) {
              this.setState({ sendConnectRequestData: responseJson.data });
              let selectedUserId = localStorage.getItem("selectedUserId");
              this.getUserProfileData(selectedUserId);
              this.handleAlertSuccess(
                responseJson?.message || "Connect request sent"
              );
            } else {
              this.handleAlert("* Something went wrong!");
            }
          } else if (apiRequestCallId === this.getPastRoomId) {
            this.setState({ getPastRoomData: responseJson.data });
          } else if (apiRequestCallId === this.getUpcomingRoomId) {
            this.setState({ getUpcomingRoomData: responseJson.data });
          }
        } else if (responseJson.errors && responseJson.errors[0]) {
          if (apiRequestCallId === this.getPastRoomId) {
            this.setState({ getPastRoomData: [] });
          } else if (apiRequestCallId === this.getUpcomingRoomId) {
            this.setState({ getUpcomingRoomData: [] });
          } else if (this.reportResourceId === apiRequestCallId) {
            this.handleAlert("Resource already reported");
            this.setState({ openReportPopUp: null, isOtherOpen: false });
          }
          const er = Object.keys(responseJson.errors[0]);
          if (er && er[0]) {
            // return this.handleAlert(responseJson.errors[0][er[0]])
          }
        }
      }
    }
  }
  RegisterModalOpen = (id: any, room_price: any, room: any) => {
    this.setState({
      registerModal: true,
      currentRoomPrice: room_price,
      participantsDetails: room.attributes.participants.data,
    });
  };
  RegisterModalClose = () => this.setState({ registerModal: false });

  RemoveModalOpen = (id: any, roomPrice: any) =>
    this.setState({
      removeModal: true,
      removeParticpantId: id,
      currentRoomPrice: roomPrice,
    });
  RemoveModalClose = () => this.setState({ removeModal: false });

  sendFollowRequest(value: any) {
    let formData = new FormData();
    formData.append("data[attributes][account_id]", value);

    const headers = {
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.followRequestId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.followModeratorApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePost
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  removingFromRoom(room_id: any) {
    this.setState({ removeModal: false });
    const headers = {
      // "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      TZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      UTime: Math.floor(new Date().getTime() / 1000).toString(),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeFromRoomId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.removeFromRoomApiEndPoint}${room_id}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePut
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  sendUnFollowRequest(unfolowerId: any) {
    let userId = localStorage.getItem("userId");
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unfollowRequestId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.unfollowModeratorApiEndPoint +
      userId +
      "?account_id=" +
      unfolowerId
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeDelete
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getUserProfileData(param: any) {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserProfileDataId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserProfileDataAPIEndPoint + param
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getOwnFollowers() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOwnFollowersAPIId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMyFollowerAPiEndPoint
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getConnectedUserData() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getConnectedUserDataId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getConnecteduserDataAPIEndPoint}?page=1&per_page=10`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  sendConnectRequest = (user_id: any) => {
    let payload = {
      data: {
        account_id: user_id,
      },
    };

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendConnectRequestId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendConnectRequestApiEndPoint
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePost
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  getPastRoomData(userId: any, perPage: any, page: any, roomTime: any) {
    let reqQuery = `user_id=${userId || this.state.userId}&page=${page}&time=${roomTime}`
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPastRoomId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPastRoomAsPerUserIdApiEndPoint + reqQuery
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getUpcomingRoomData(userId: any, perPage: any, page: any, roomTime: any) {

    let reqQuery = `user_id=${userId || this.state.userId}&page=${page}&time=${roomTime}`
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUpcomingRoomId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPastRoomAsPerUserIdApiEndPoint + reqQuery
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  createConversation(params: string) {
    const conversation_sid = sessionStorage.getItem("conversation_sid");
    if (conversation_sid) {
      this.props.history.push({
        pathname: "/Chat",
        state: {
          user: this.state.userProfileData,
        },
      });
      return;
    }

    let token = localStorage.getItem("token");

    if (token) {
      let payload = {
        account_id: params || localStorage.getItem('selectedUserId'),
      };

      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token: token,
      };

      const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createConnectionAPIId = getValidationsMsg.messageId;

      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.sendConversationRequestApiEndPoint
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(payload)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypePost
      );
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }
  }

  registerRoom(payload: any, isRegisteredFlag: boolean) {
    const headers = {
      // "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      TZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      UTime: Math.floor(new Date().getTime() / 1000).toString(),
    };
    this.setState({ isRegisteredFlag: isRegisteredFlag });
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.registerRoomID = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.RegisterRoomApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePost
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      payload
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getWalletCoins() {
    const id = localStorage.getItem("userId");
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getWalletCoinsId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getWalletCoinsApiEndPoint + id
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  deleteResource(resourceId: any) {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteResourceId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteresourceApiEndPoint + `${resourceId}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeDelete
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  reportResource(room_id: any, user_id: any, reason: any) {
    let payload = `resource_id=${room_id}&user_id=${user_id}&reason=${reason}`;

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.reportResourceId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reportResourceApiEndPoint + payload
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePost
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  likeResource(resource_id: any) {
    let payload = {
      data: {
        attributes: {
          likeable_id: `${resource_id}`, // resource_id
          likeable_type: "BxBlockDifferentRooms::Resource",
        },
      },
    };

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.likeResourceId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.likeResourceAPIEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePost
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  deleteLikeResource(resourceId: any, liked_id: any) {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.disLikeResourceId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteLikeResourceAPIEndPoint +
      `${resourceId}?likeable_id=${liked_id}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeDelete
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  pushToProfile(id: number | string) {
    this.props.history.push("/UserProfile", { userId: id });
  }

  acceptRejectFriendConnection(requestId: any, status: any) {
    const payload = {
      data: {
        status: status,
      },
    };

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.acceptRejectFriendConnectionId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.acceptRejectFriendConnectionApiEndPoint + requestId
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePatch
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  disconnectUser(accountId: any) {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.disconnectUserId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.disConnectUserApiEndPoint + accountId
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePost
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  fetchTouchPoints = (userId: string, page: number, perPage: number) => {
    this.setState({ isTouchPointLoading: true });
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchTouchPointsId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.fetchTouchPointsApiEndPoint}?id=${userId}&page=${page}&per_page=${perPage}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.validationApiContentType,
        token: localStorage.getItem("token"),
      })
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  updateProfileTurtoials = () => {
    const headers = {
      "Content-Type": configJSON.updateTutorialsApiContentType,
      token: localStorage.getItem("token"),
    };

    const body = {
      flags: {
        is_dashboard_training_done: true,
        is_profile_training_done: true,
      },
    };

    const msg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    msg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    msg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateTurtoialsMethod
    );

    msg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateTutorialsApiEndPoint
    );

    msg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    localStorage.setItem("is_profile_training_done", "true");
    runEngine.sendMessage(msg.id, msg);
  };


  handleblockUnBlockkUser = () => {
    const { is_blocked, id } = this.state.userProfileData;

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("token")
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.blockUnBlockUserMessageID = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      is_blocked ? configJSON.unBlockUserApiEndpoint : configJSON.blockUserApiEndpoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ user_id: id })
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      is_blocked ? configJSON.httpDeleteType : configJSON.httpPostType
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

}

// Customizable Area End
