// Customizable Area Start
import React from "react";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
   yellowheart,
   as,
   GoldenTick,
   BlueTick,
   images,
   share,
} from "../../assets";

const RoomBox = (props: any) => {
   console.log(props, 'PROPS')
   const timeHelper = (time: any) => {
      let UpcomingApiDate = time; // got from the Api
      let upcomingtimestamp = new Date(UpcomingApiDate).getTime();
      let upcomingDay = new Date(upcomingtimestamp).getDate();
      let upcomingMonth = new Date(upcomingtimestamp).getMonth() + 1;
      let upcomingYear = new Date(upcomingtimestamp).getFullYear();
      return `${upcomingDay}/${upcomingMonth}/${upcomingYear}`;
   }
   return (
      <>
         <div className="topicAndRoomCountLabel">Rooms <span>{props.upcomingRooms.length}</span></div>
         {props.upcomingRooms.map((elem: any) => {
            let url = window.location.href.split('/')[3];
            let UpcomingApiDate = elem?.attributes?.start_time; // got from the Api

            let endDate = new Date(elem?.attributes?.end_time);
            let presentDate = new Date();

            let UpcomingNewDateFormat = new Date(elem?.attributes?.start_time)
               .toLocaleString(navigator.language, {
                  hour: '2-digit',
                  minute: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  day: '2-digit',
                  hour12: true,
               })
               .replace(/,/g, ' ');

            const gender = elem?.attributes?.host?.data?.attributes?.gender;
            const userGender =
               gender === 'Male' ? 'M' : gender === 'Female' ? 'F' : 'NB';

            const user = elem?.attributes;
            const host = user?.host?.data;
            const currentUserId = localStorage.getItem('userId');

            const userName =
               host?.id == currentUserId
                  ? 'You'
                  : user?.is_anonymously
                     ? user.anonymously_name
                     : host?.attributes?.full_name;

            return (
               <div key={elem?.id} style={{ width: '100%', margin: '10px' }}>
                  <section
                     className="room-details mb20 shadow"
                  >
                     <Grid container className="bBottom">
                        <Grid item xs={12} md={8} className="borderRight" onClick={() => {
                           // props.navigation.navigate("RoomDetails",
                           //    { id: elem?.id, url: url, elem: elem }
                           // );
                           // props.history.push('/RoomDetailsWeb/', { id: elem?.id, state: { url, elem } },);
                        }}>
                           <Link to={{ pathname: `/RoomDetailsWeb/${elem?.id}`, state: { url, upcomingRoomsData: [elem] } }}>
 

                              <div className="p15 ">
                                 <div className="daf">
                                    <h3 className="fs22 colorBlue fw500 mt0 mb0">
                                       {elem?.attributes?.topic_name}
                                    </h3>
                                    <div>
                                    </div>
                                 </div>
                                 <div className="mb15 ">
                                    <p className="fs14 fw500 colorGrey mt0 mb0">
                                       {elem?.attributes?.sub_category_name}
                                    </p>
                                    <span className="fs12 fw500 colorGrey">
                                       {elem?.attributes?.room_type_name}
                                    </span>
                                 </div>
                                 {console.log(elem?.attributes)}
                                 <Link to={{ pathname: `/RoomDetailsWeb/${elem?.id}`, state: { url, upcomingRoomsData: [elem] } }}>
                                    <p className="fs14 colorGrey fw500 mh75" style={{ wordBreak: 'break-word' }}>
                                       {elem?.attributes?.description}
                                    </p>
                                 </Link>
                                 <div className="seats-time">
                                    <div>
                                       <p className="fs14 colorGrey fw500 mb5 mt0">
                                          {`${elem?.attributes?.max_participants -
                                             elem?.attributes?.participants_count
                                             } seats remaining`}
                                       </p>
                                       <p className="fs14 colorBlue fw500 mt0 mb0">
                                          {UpcomingNewDateFormat}
                                       </p>
                                    </div>
                                 </div>
                              </div>
                           </Link>
                        </Grid>
                        <Grid item xs={12} md={4}>
                           <div className=" m15">
                              <p className="fs15 colorGrey mb10">Host</p>
                              <div className="d-flex">
                                 <div className="user-card__image-container">
                                    <img
                                       src={
                                          elem?.attributes?.host?.data?.attributes
                                             ?.profile_image === '' ||
                                             (elem?.attributes?.is_anonymously &&
                                                elem?.attributes?.host?.data?.id !=
                                                localStorage.getItem('userId'))
                                             ? images
                                             : elem?.attributes?.host?.data?.attributes
                                                ?.profile_image
                                       }
                                       alt="profile_image"
                                       className="radius100 w48 cover"
                                    />
                                    {(elem?.attributes?.host?.data?.attributes
                                       ?.is_moderator ||
                                       elem?.attributes?.host?.data?.attributes
                                          ?.is_superhost) && (
                                          <img
                                             src={
                                                elem?.attributes?.host?.data?.attributes
                                                   ?.is_moderator
                                                   ? GoldenTick
                                                   : BlueTick
                                             }
                                             alt="verified_tick"
                                             className="upcoming-room_check-mark"
                                          />
                                       )}
                                 </div>
                                 <div className="ml15" style={{ overflow: 'hidden' }}>
                                    <h4
                                       className="fs15 fw600 colorBlue mb5 mt0 text-capitalize trim-text"
                                       style={{ cursor: 'pointer' }}
                                       onClick={() => {
                                          const inlineProps:any = props
                                          if (!elem?.attributes?.is_anonymously) {
                                             const currentID = elem?.attributes?.host?.data?.id;
                                             const userId = localStorage.getItem('userId');
                                             if (currentID == userId) {
                                                inlineProps.histroy.push('/UserPersonalProfile');
                                                return;
                                             }
                                             inlineProps.histroy.push('/UserProfile', {
                                                userId: currentID,
                                             });
                                          }
                                       }}
                                    >
                                       {userName}
                                    </h4>
                                    <p className="dFlexAlignCenter mt0 fs15 fw500 colorGrey">
                                       <img src={yellowheart} alt="" className="w20 mr10" />
                                       <span className="fs14 mr5">
                                          {' '}
                                          {elem?.attributes?.host?.data?.attributes?.hearts ||
                                             0}
                                       </span>
                                       {userGender}(
                                       {elem?.attributes?.host?.data?.attributes?.age_range[0]}
                                       {elem?.attributes?.host?.data?.attributes
                                          ?.age_range[1] && '-'}
                                       {elem?.attributes?.host?.data?.attributes?.age_range[1]}
                                       )
                                    </p>
                                 </div>
                              </div>
                              <p className="fs15 colorGrey fw500 mb10 mt0">
                                 REGISTERED : {elem?.attributes?.participants?.data?.length}
                              </p>

                              {elem?.attributes?.participants?.data?.length > 0 &&
                                 elem?.attributes?.participants?.data?.map(
                                    (participants: any) => {
                                       if (
                                          !participants?.attributes?.participant?.data
                                             ?.attributes
                                       )
                                          return;
                                       const { full_name } =
                                          participants?.attributes?.participant?.data
                                             ?.attributes;
                                       let name;
                                       if (
                                          participants?.attributes?.participant?.data?.id ===
                                          localStorage.getItem('userId')
                                       ) {
                                          name = 'You';
                                       } else if (participants?.attributes?.is_anonymously) {
                                          name = participants?.attributes?.anonymously_name;
                                       } else {
                                          name = full_name;
                                       }

                                       const gender =
                                          participants?.attributes?.participant?.data
                                             ?.attributes?.gender;

                                       return (
                                          <div className="d-flex mb10" key={participants.id}>
                                             <div className="user-card__image-container">
                                                <img
                                                   src={
                                                      participants?.attributes?.participant?.data
                                                         ?.attributes?.profile_image === ''
                                                         ? images
                                                         : participants?.attributes
                                                            ?.is_anonymously && name !== 'You'
                                                            ? images
                                                            : participants?.attributes?.participant
                                                               ?.data?.attributes?.profile_image
                                                   }
                                                   alt=""
                                                   className="radius100 w48 cover"
                                                />
                                                {(participants?.attributes?.participant?.data
                                                   ?.attributes?.is_moderator ||
                                                   participants?.attributes?.participant?.data
                                                      ?.attributes?.is_superhost) && (
                                                      <img
                                                         src={
                                                            participants?.attributes?.participant?.data
                                                               ?.attributes?.is_moderator
                                                               ? GoldenTick
                                                               : BlueTick
                                                         }
                                                         alt="verified_tick"
                                                         className="upcoming-room_check-mark"
                                                      />
                                                   )}
                                             </div>

                                             <div
                                                className="ml15"
                                                style={{ overflow: 'hidden' }}
                                             >
                                                <h4
                                                   className="fs15 fw600 colorBlue mb5 mt0 text-capitalize trim-text"
                                                   style={{ cursor: 'pointer' }}
                                                   onClick={() => {
                                                      if (
                                                         !participants?.attributes?.is_anonymously
                                                      ) {
                                                         const currentID =
                                                            participants?.attributes?.participant
                                                               ?.data?.id;
                                                         const userId =
                                                            localStorage.getItem('userId');
                                                         // if (currentID == userId) {
                                                         //    props.history.push(
                                                         //       '/UserPersonalProfile'
                                                         //    );
                                                         //    return '';
                                                         // }
                                                         props.histroy.push('/UserProfile', {
                                                            userId: currentID,
                                                         });
                                                      }
                                                   }}
                                                >
                                                   {name}
                                                </h4>
                                                <p className="dFlexAlignCenter mt0 fs15 fw500 colorGrey">
                                                   <img
                                                      src={yellowheart}
                                                      alt=""
                                                      className="w20 mr10"
                                                   />
                                                   <span className="fs14 mr5">
                                                      {participants?.attributes?.participant?.data
                                                         ?.attributes?.hearts || 0}
                                                   </span>
                                                   {gender === 'Male'
                                                      ? 'M'
                                                      : gender === 'Female'
                                                         ? 'F'
                                                         : 'NB'}
                                                   (
                                                   {
                                                      participants?.attributes?.participant?.data
                                                         ?.attributes?.age_range[0]
                                                   }
                                                   {participants?.attributes?.participant?.data
                                                      ?.attributes?.age_range[1] && '-'}
                                                   {
                                                      participants?.attributes?.participant?.data
                                                         ?.attributes?.age_range[1]
                                                   }
                                                   )
                                                </p>
                                             </div>
                                          </div>
                                       );
                                    }
                                 )}
                           </div>
                        </Grid>
                     </Grid>
                     <div className="daf action-status">
                        <div className="daf">
                           <img src={as} alt="" className="w18 mr10" />
                           <strong className="fs20 fw600 colorBlue">
                              {elem?.attributes?.room_price}
                           </strong>
                        </div>
                        <Link to={{ pathname: `/RoomDetailsWeb/${elem?.id}`, state: { url, upcomingRoomsData: [elem] } }}>
                           <strong style={{ cursor: 'pointer' }} className="fs20 fw600 colorBlue">GO TO ROOM DETAILS</strong>
                        </Link>
                     </div>
                  </section>

               </div>
            );
         })}
      </>
   )
}
export default RoomBox;
// Customizable Area End