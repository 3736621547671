// Customizable Area Start
import React from "react";

import {
    Box, CircularProgress, Grid, Typography, Container, List
} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import "./list.css"
import { TouchUser } from "./UserPersonalProfile.web";
import UserPersonalProfileController, { Props } from "./UserPersonalProfileController";

export default class TouchPoints extends UserPersonalProfileController {
    constructor(props: Props) {
        super(props);
    }

    async componentDidMount(): Promise<void> {
        let userId = localStorage.getItem("userId") as string;

        this.getUserProfileData(userId);
        this.fetchTouchPoints(userId, 1, 10);
    }

    loadMore = () => {
        let userId = localStorage.getItem("userId") as string;

        this.fetchTouchPoints(userId, 1, this.state?.touchPoints.touchpoints_count);
    }

    render() {
        const usersLength = this.state.touchPoints.touchpoints?.data?.length;
        const { isTouchPointLoading: isLoading } = this.state;

        return (
            <Container maxWidth="xl" style={{
                backgroundColor: "#fcfbfcfc",
                height: this.state.touchPoints.touchpoints?.data?.length > 0 ? 'auto' : '100%'
            }}
                className="MuiContainer-root moderator-wrapper moderator-wrapper-connection" >
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                        <Box style={{ display: "flex" }}
                            onClick={() => { this.props.navigation.navigate("UserPersonalProfile") }}
                            className='back-arrow-btn'
                        >
                            <ArrowBackIcon />
                            <Typography style={{ paddingLeft: "10px" }}>
                                Back To Profile
                            </Typography>
                        </Box>

                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <section >

                            <div className="main-div-outter-moderator-list">
                                <span className='Heading-moderator'>
                                    Touch Points
                                </span>
                            </div>

                            <div className="Moderator-content-outter">
                                {!!usersLength && (
                                    <List dense className='touch-points-user-container'>
                                        {this.state.touchPoints.touchpoints.data.map((item: any, index: number) =>
                                            <TouchUser
                                                item={item}
                                                index={index}
                                                sendFriendRequest={this.sendConnectRequest}
                                            />
                                        )}

                                        {this.state?.touchPoints.touchpoints_count > usersLength && (
                                            <Box mt={3} textAlign="right" onClick={this.loadMore}>
                                                {isLoading ? <CircularProgress style={{ color: "#ffcb00" }} /> : (
                                                    <Typography
                                                        variant="h6"
                                                        style={{ fontWeight: 600, cursor: "pointer" }}
                                                    >
                                                        Load More
                                                    </Typography>
                                                )}
                                            </Box>
                                        )}
                                    </List>
                                )}

                                <Box mt={10} display="flex" justifyContent='center' alignItems='center' flexDirection="column">
                                    {(!isLoading && !usersLength) && (
                                        <Typography variant="h6" style={{ fontWeight: 600 }}>No users found.</Typography>
                                    )}
                                    {isLoading && (
                                        <>
                                            <CircularProgress style={{ color: "#ffcb00" }} />
                                            <Typography variant="h6"
                                                style={{ fontWeight: 600, marginTop: '1rem' }}
                                            >
                                                Loading.....
                                            </Typography>
                                        </>
                                    )}
                                </Box>
                            </div>
                        </section>
                    </Grid>
                </Grid>
            </Container >
        )
    }
}
// Customizable Area End
