// Customizable Area Start
import React from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  LinearProgress,
  Divider,
  Grid,
  Avatar,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import { quote, logoHalf, quizImage } from '../assets';
import ComplateSuccess from './complateModuleSucces';

const getTextForSure = (text: string | undefined | null) => {
  return text ?? '';
};

const myOwnTernaryCuzWhyNot = (
  condition: any,
  trueValue: any,
  falseValue: any
) => {
  return condition ? trueValue : falseValue;
};

export type quizType = {
  counter: number;
  quiz: {
    currentQuestion: number;
    selectedAnswers: number[][];
    answeredQuestions: { questionNo: number; correct: boolean }[];
    answered: boolean[];
    completed: boolean;
    isComplateModal: boolean;
    questions: Array<{
      id: number;
      name: string;
      answers: [number, string, boolean][];
      is_multiple_answer: boolean;
    }>;
    answerKeys: string[];
    answers: Array<{
      id: number;
      details: string;
      onboarding_question_id: number;
      is_correct: boolean;
    }>;
    isLoading: boolean;
  };
};

type Props = quizType & {
  incrementCounter: () => void;
  moderatorOrientation: {
    screen: number;
    title: string;
    description: string;
  }[];
  isOrientationCompleted: boolean;
  markOrientationAs: (completed: boolean) => void;
  complateModal: (isComplated: boolean) => void;
  markStep2AsComplete: () => void;
  submitAnswer: () => void;
  handleSelect: (is_multiple_answer: boolean, answer: number) => void;
  handleSubmit: () => void;
  handleComplete: () => void;
  handleNext: () => void;
  resetQuiz: () => void;
  setCurrentQuestion: (questionNumber: number) => void;
  step1Redirect: () => void;
};

const Step2 = (props: Props) => {
  const {
    counter,
    quiz: {
      currentQuestion,
      selectedAnswers,
      answeredQuestions,
      answered,
      completed,
      isComplateModal,
      questions,
      answerKeys,
      isLoading,
    },
    incrementCounter,
    moderatorOrientation,
    markStep2AsComplete,
    isOrientationCompleted,
    markOrientationAs,
    handleSelect,
    handleSubmit,
    handleComplete,
    handleNext,
    resetQuiz,
    setCurrentQuestion,
    step1Redirect,
  } = props;

  let uniqueScreen = 0;
  const uniqueSortedModeratorOrientation = [...moderatorOrientation]
    .sort((a, b) => a.screen - b.screen)
    .filter((orientation) => {
      if (uniqueScreen !== orientation.screen) {
        uniqueScreen = orientation.screen;
        return true;
      }
      return false;
    });

  const currentModeratorOrientation =
    uniqueSortedModeratorOrientation[counter] || {};

  const currentQuestionObj = questions[currentQuestion] || {};

  const isQuizCompleted =
    answered[currentQuestion] &&
    !isLoading &&
    currentQuestion === questions.length - 1;

  const actionBtnVariant = answered[currentQuestion] ? 'outlined' : 'contained';
  const actionBtnText =
    answered[currentQuestion] && !isLoading ? 'Next' : 'Submit';

  const orientationBoxDisplay =
    currentModeratorOrientation.title === '' ? 'flex' : 'block';

  return (
    <>
      <Box>
        <Typography
          style={{
            fontSize: '24px',
            fontWeight: 600,
            marginBottom: '16px',
          }}
        >
          Moderator Orientation Module
        </Typography>
        {isOrientationCompleted ? (
          <Box
            px={2}
            py={4}
            sx={{
              bgcolor: '#FFFFFF',
              borderRadius: '16px',
            }}
          >
            <Grid
              container
              alignItems="center"
              spacing={2}
              style={{ paddingBottom: '16px' }}
            >
              <Grid item>
                <IconButton size="small" onClick={step1Redirect}>
                  <KeyboardBackspaceIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography
                  style={{
                    fontSize: '18px',
                    fontWeight: 500,
                    display: 'inline',
                  }}
                >
                  Quiz
                </Typography>
              </Grid>
            </Grid>
            <Box
              style={{
                maxWidth: '730px',
                margin: 'auto',
              }}
            >
              {completed && (
                <>
                  {isComplateModal && <ComplateSuccess closeModal={() => { props.complateModal(false) }} />}
                  <Box>
                    <Box sx={{ textAlign: 'center', marginBottom: '64px' }}>
                      <img src={quizImage} style={{
                        height: '270px',
                        width: '270px',
                        marginBottom: '10px',
                        borderRadius: '8px',
                        objectFit: 'cover'
                      }} />
                      <Typography
                        style={{
                          fontWeight: 500,
                          fontSize: '22px',
                          marginTop: '18px',
                        }}
                      >
                        Congratulations you have successfully completed the
                        Moderator Orientation Module
                      </Typography>
                    </Box>
                    <Typography
                      style={{
                        fontWeight: 500,
                        fontSize: '20px',
                      }}
                    >
                      Your Score :
                      {
                        answeredQuestions.filter((answer) => answer.correct)
                          .length
                      }
                      /{questions.length}
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: 500,
                        fontSize: '20px',
                      }}
                    >
                      Retake the quiz as many times as you'd like.
                    </Typography>
                    <br />
                    <br />
                    <Button
                      fullWidth
                      variant="outlined"
                      color="secondary"
                      onClick={resetQuiz}
                    >
                      <Typography
                        style={{
                          fontSize: '24px',
                          fontWeight: 600,
                        }}
                      >
                        Retake
                      </Typography>
                    </Button>
                    <br />
                    <br />
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={markStep2AsComplete}
                    >
                      <Typography
                        style={{
                          fontSize: '24px',
                          fontWeight: 600,
                        }}
                      >
                        Continue
                      </Typography>
                    </Button>
                  </Box>
                </>
              )}
              {!completed && (
                <Box>
                  <Grid container spacing={4} style={{ marginBottom: '16px' }}>
                    {[...Array(questions.length)].map((e, i: number) => {
                      const bgSelectionAvatar: string = answeredQuestions.find(
                        (answeredQuestion) => answeredQuestion.questionNo === i
                      )?.correct
                        ? '#5DE172'
                        : '#FF6060';
                      return (
                        <Grid item key={questions[i].id}>
                          <Box
                            sx={{
                              padding: '0px 4px 12px 4px',
                              borderBottom:
                                currentQuestion === i
                                  ? '2px solid #FFD11E'
                                  : '2px solid #D1D4E3',
                            }}
                          >
                            <Avatar
                              style={{
                                cursor: selectedAnswers[i]
                                  ? 'pointer'
                                  : 'default',
                                height: '32px',
                                width: '32px',
                                background: answeredQuestions.find(
                                  (answeredQuestion) =>
                                    answeredQuestion.questionNo === i
                                )
                                  ? bgSelectionAvatar
                                  : '#D1D4E3',
                              }}
                              onClick={
                                selectedAnswers[i]
                                  ? () => setCurrentQuestion(i)
                                  : undefined
                              }
                            >
                              <Typography
                                style={{
                                  fontWeight: 500,
                                  fontSize: '16px',
                                  display: 'inline',
                                }}
                              >
                                {i + 1}
                              </Typography>
                            </Avatar>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Typography style={{ fontWeight: 700, fontSize: '18px' }}>
                    {currentQuestion + 1} - {currentQuestionObj.name}
                  </Typography>
                  {currentQuestionObj.is_multiple_answer && (
                    <Typography
                      style={{
                        fontWeight: 300,
                        fontSize: '16px',
                        marginTop: '8px',
                      }}
                    >
                      (select all applicable answers)
                    </Typography>
                  )}
                  <Grid
                    container
                    direction="column"
                    spacing={2}
                    style={{
                      margin: '16px 0px',
                    }}
                  >
                    {currentQuestionObj.answers?.map((answer, index) => {
                      const bgPreSelection1 = myOwnTernaryCuzWhyNot(
                        answer[2],
                        '#5EE173',
                        '#FF605F'
                      );
                      const bgSelection1 = myOwnTernaryCuzWhyNot(
                        answered[currentQuestion],
                        bgPreSelection1,
                        '#010039'
                      );

                      const bgPreSelection2 = myOwnTernaryCuzWhyNot(
                        answer[2],
                        '#5EE173',
                        '#D1D4E3'
                      );
                      const bgSelection2 = myOwnTernaryCuzWhyNot(
                        answered[currentQuestion],
                        bgPreSelection2,
                        '#D1D4E3'
                      );

                      const bgSelection = myOwnTernaryCuzWhyNot(
                        selectedAnswers[currentQuestion].includes(answer[0]),
                        bgSelection1,
                        bgSelection2
                      );

                      return (
                        <Grid
                          item
                          key={answer[0]}
                          style={{
                            width: 'fit-content',
                          }}
                          onClick={() =>
                            !answered[currentQuestion] &&
                            handleSelect(
                              currentQuestionObj.is_multiple_answer,
                              answer[0]
                            )
                          }
                        >
                          <Box display="flex" gridGap={4}>
                            <Avatar
                              style={{
                                background: bgSelection,
                                display: 'inline-flex',
                                marginRight: '4px',
                                verticalAlign: 'middle',
                                width: '40px',
                                height: '40px',
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: '18px',
                                  display: 'inline',
                                }}
                              >
                                {String.fromCharCode(index + 65)}
                              </Typography>
                            </Avatar>
                            <Typography
                              style={{
                                fontSize: '14px',
                                alignSelf: 'center',
                              }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: `${answer[1]}`,
                                }}
                              />
                            </Typography>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                  {answered[currentQuestion] && !isLoading && (
                    <Box my={4}>
                      <Typography style={{ fontWeight: 500, fontSize: '14px' }}>
                        Answer Key:
                      </Typography>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${answerKeys[currentQuestion]}`,
                        }}
                      />
                    </Box>
                  )}
                  {myOwnTernaryCuzWhyNot(
                    isQuizCompleted,
                    <Button
                      style={{ fontSize: '16px' }}
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={() => handleComplete()}
                    >
                      <Typography
                        style={{
                          fontSize: '24px',
                          fontWeight: 600,
                        }}
                      >
                        Complete
                      </Typography>
                    </Button>,
                    <Button
                      style={{ fontSize: '16px' }}
                      disabled={
                        selectedAnswers[currentQuestion].length === 0 ||
                        isLoading
                      }
                      fullWidth
                      variant={actionBtnVariant}
                      color="secondary"
                      onClick={() =>
                        answered[currentQuestion]
                          ? handleNext()
                          : handleSubmit()
                      }
                    >
                      {isLoading && (
                        <CircularProgress
                          color="secondary"
                          size={20}
                          style={{ marginRight: '16px' }}
                        />
                      )}
                      <Typography
                        style={{
                          fontSize: '24px',
                          fontWeight: 600,
                        }}
                      >
                        {actionBtnText}
                      </Typography>
                    </Button>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <>
            <Typography
              style={{
                fontSize: '16px',
                color: '#797979',
                marginBottom: '8px',
              }}
            >
              This may take around 20 minutes to complete
            </Typography>
            <LinearProgress
              variant="determinate"
              value={
                (counter * 100) / (uniqueSortedModeratorOrientation.length - 1)
              }
              style={{
                backgroundColor: '#D8D8D8',
                borderRadius: '5px',
                height: '10px',
              }}
            />
            <Box>
              <Box
                mt={2}
                pt={4}
                pb={1}
                bgcolor="#FFD770"
                height="526px"
                position="relative"
                overflow="auto"
                display={orientationBoxDisplay}
                style={{
                  borderTopLeftRadius: '16px',
                  borderTopRightRadius: '16px',
                }}
              >
                {myOwnTernaryCuzWhyNot(
                  currentModeratorOrientation.title === '',
                  <>
                    <img
                      alt="quote"
                      src={quote}
                      style={{
                        position: 'absolute',
                        left: '9%',
                        top: '9%',
                      }}
                    />
                    <Container maxWidth="md" style={{ alignSelf: 'center' }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${getTextForSure(
                            currentModeratorOrientation.description
                          )}`,
                        }}
                      />
                    </Container>
                  </>,
                  <Container>
                    <style>
                      {`
                        ul { padding: revert; margin: revert; }
                        li { list-style: revert; }
                      `}
                    </style>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${getTextForSure(
                          currentModeratorOrientation.description
                        )}`,
                      }}
                    />
                  </Container>
                )}
              </Box>
              <Box
                height="72px"
                bgcolor="#FFD770"
                textAlign="center"
                style={{
                  borderBottomLeftRadius: '16px',
                  borderBottomRightRadius: '16px',
                }}
              >
                <Divider
                  style={{
                    backgroundColor: '#FFFFFF',
                  }}
                />
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img alt="logo" src={logoHalf} />
                </Box>
              </Box>
            </Box>

            <Box textAlign="right" my={2}>
              <Button
                onClick={() => {
                  if (counter > 16) markOrientationAs(true);
                  else incrementCounter();
                }}
              >
                Next
              </Button>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default Step2;
// Customizable Area End
