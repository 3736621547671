Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Feedback";
exports.labelBodyText = "Feedback Body";

exports.btnExampleTitle = "CLICK ME";

exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.ApiContentType = "application/json";

exports.reportUserEndpoint = `bx_block_different_rooms/reported_user`;
exports.roomFeedbackEndpoint = `bx_block_different_rooms/room_feedback`;
exports.participantFeedbackEndpoint = `bx_block_different_rooms/participant_feedbacks`;

exports.getPrevFeedbacks =
  "bx_block_different_rooms/participant_feedbacks/myroom_feedback_to_others";

exports.updateUserFeedbackEndPoint =
  "bx_block_different_rooms/participant_feedbacks/update_feedback";
exports.updateUserFeedbackMethod = "PATCH";

exports.getPrevRoomFeedback =
  "bx_block_different_rooms/room_feedback/room_feedback";

exports.updateRoomFeedback = "bx_block_different_rooms/room_feedback";
exports.updateRoomFeedbackMethod = "PATCH";

exports.endCallForAllEndPoint =
  "bx_block_different_rooms/rooms/end_room_for_all";
exports.endCallForCallMethod = "POST";

exports.earnedYCoinsEndPoint = "bx_block_ycoins/ycoins/earned_ycoins";
exports.earnedYCoinsMethod = "POST";

exports.sendConnectRequestApiEndPoint = '/bx_block_request_management/requests';

exports.blockUserApiEndpoint = 'account_block/accounts/block_unblock_user';
exports.bulletPointsAPIEndPoint = "bx_block_admin/web_contents";

exports.patchApiMethod = "PATCH";
// Customizable Area End
