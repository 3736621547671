// Customizable Area Start
import React from 'react'
import { Grid, CircularProgress } from '@material-ui/core'
import './accountStatement.css';
import { yellowheart, dotted } from '../../assets';

const AccountStatement = (props: any) => {
   const timeHelper = (time: any) => {
      let UpcomingApiDate = time; // got from the Api
      let upcomingtimestamp = new Date(UpcomingApiDate).getTime();
      let upcomingDay = new Date(upcomingtimestamp).getDate();
      let upcomingMonth = new Date(upcomingtimestamp).getMonth() + 1;
      let upcomingYear = new Date(upcomingtimestamp).getFullYear();
      return `${upcomingDay}/${upcomingMonth}/${upcomingYear}`;
   }
   const encryptionForNumber = (value: any) => {
      let creditCard = value.substr(0, value.length).replace(/\d/g, "*")
      creditCard = creditCard + value.substr(value.length - 2, value.length)
      return creditCard
   }
   return (
      <div className='d-flex'>
         <div className="accountStatementBox">
            <span className="transactionLabel">Account Statement</span>
            <div className="accountStatementLabels">
               <span>Account Statement</span>
               <span>YCoins</span>
            </div>
            <div className="transaction">
            {props.accountStatementData && props.accountStatementData.moderated_rooms && props.accountStatementData.moderated_rooms?.data?.length > 0 ?
               props.accountStatementData.moderated_rooms.data.map((item: any) => {
                  return (
                     <div key={item.id} className="accounStatementDataBox">
                        <div className="accountDataTypeAndPrice">
                           <span>{item.attributes.topic_name}</span>
                           <span>{(item.attributes.status != 'Completed' ? '-' : '+') + item.attributes.earned_coin}</span>
                        </div>
                        <div className="accountDataTıme">
                           {timeHelper(item.attributes.end_time)}
                        </div>
                        <div className="accountParticipants">Participants: {item.attributes.participants_count}</div>
                        <div className="accountCompleted">
                           {item.attributes.status} 
                           <span className={item.attributes.status != 'Completed' ? "hearthCountBad" : "heartCount"}>
                              <img src={yellowheart} />{(item.attributes.status != 'Completed' ? '-' : '') + item.attributes.total_hearts}
                           </span> 
                        </div>
                     </div>
                  )
               })
               :
               <div className="accounStatementDataBox">
                  NO DATA
               </div>
            }
            </div>
            {props.accountLoading && <Grid container xs={12} style={{ height: '100%', justifyContent: 'center', margin: '10px 0px' }} alignItems="center">
               <div>
                  <CircularProgress size={80} thickness={4} style={{ color: '#ffcb00' }} />
               </div>
            </Grid>
            }
         </div>
         <div className="bankDetailsOutsideBox">
          {props?.bankDetails && Object.keys(props.bankDetails).length > 0 && props.bankDetails.ac_holder_name.length > 0 ?
               <>
                  <div className="bankDetailsBox">
                     <div className="bankDetailEditButton" onClick={() => props.handleChangeDetail()}>
                        <img src={dotted} />
                     </div>
                     <span className="bankDetailBoxUserName">{props.bankDetails.ac_holder_name}</span>
                     <span className="bankDetailInfo">{props.bankDetails.bank_name}</span>
                     <span className="bankDetailInfo">{'Account Number: ' + encryptionForNumber(props.bankDetails.account_no)}</span>
                     <span className="bankDetailInfo">{'IFSC CODE: ' + props.bankDetails.ifsc_code}</span>
                     <span className="bankDetailInfo">{'PAN: ' + props.bankDetails.pan_number}</span>
                     <span className="bankDetailInfo">{'GST NUMBER: ' + props.bankDetails.gst_number}</span>
                  </div>
               </>
               :
               <div className="enterBankDetailsButton active" onClick={() => props.handleChangeDetail()}>Enter Bank Details</div>
            }
            </div>
      </div>
   )
}

export default AccountStatement;
// Customizable Area End