// Customizable Area Start
import { IBlock } from '../../../../../framework/src/IBlock';
import { Message } from '../../../../../framework/src/Message';
import { BlockComponent } from '../../../../../framework/src/BlockComponent';
import MessageEnum, { getName } from '../../../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../../../framework/src/RunEngine';
import { RemoteVideoTrack } from 'twilio-video';

export const configJSON = require("../config.js");

export interface Props {
    // navigation: any;
    index: number;
    id: number;
    remoteParticipant: any;
    accountId: number;
    isAnonymously: boolean;
    isHost: boolean;
    isModerator: boolean;
    anonymouslyName: string | null;
    profileImg: string;

    selfAlertCount: number;
    selfLeaveCount: number;
    showMaxAlert: boolean;
    showMaxLeave: boolean;
    currentUser: any;
    isRoomModarated: boolean;
    fullName: string;

    handleAlertCount: (x: number) => void
    handleLeaveCount: (x: number) => void
    handleMaxAlert: () => void
    handleMaxLeave: () => void

    retract_leave_status: any;
    startTime: string;
}

interface S {
    loading: boolean;
    tracks: any;
    audioTrackDisabled: boolean;
    videoTrackDisabled: boolean;
    user_full_name: string;
}

interface SS {
    id: any;
}

export default class ParticipantVideoCardController extends BlockComponent<Props, S, SS> {


    constructor(props: Props) {
        super(props);

        //getting all publications of one remote participant
        const existingPublications = Array.from(this.props.remoteParticipant.tracks.values());
        const existingTracks = existingPublications.map((trackPublication: any) => {
            return trackPublication.track
        });
        //removing if pulication.track is undefined
        const nonNullTracks = existingTracks.filter((track: any) => track !== null)

        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
        ];

        this.state = {
            loading: true,
            //initializing the tracks
            tracks: nonNullTracks,
            audioTrackDisabled: false,
            videoTrackDisabled: false,
            user_full_name: ''
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        //if new tracks are published will store remote participants in state
        //when automatically subscribed ..it will added to state tracks and will cause rerender
        this.props.remoteParticipant.on('trackSubscribed', (track: any) => {
            //listening tracks of new user for enable/disable to reflect in UI

            if (track?.kind === "audio") this.setState({ audioTrackDisabled: !track?.isEnabled })
            if (track?.kind === "video") this.setState({ videoTrackDisabled: !track?.isEnabled })

            track.on('disabled', (e: any) => {
                if (e.kind === "audio") this.setState({ audioTrackDisabled: !e.isEnabled })
                else if (e.kind === "video") this.setState({ videoTrackDisabled: !e.isEnabled })
            })

            track.on('enabled', (e: any) => {
                if (e.kind === "audio") this.setState({ audioTrackDisabled: !e.isEnabled })
                else if (e.kind === "video") this.setState({ videoTrackDisabled: !e.isEnabled })
            })

            this.addTrack(track);
        });
        this.props.remoteParticipant.on('trackUnsubscribed', (track: RemoteVideoTrack) => {
            console.log("remoteParticipant trackUnsubscribed", track)
            this.setState({ videoTrackDisabled: true });
        });

        this.setState({ loading: false }, () => {
            //attaching existing-non-null tracks
            this.state.tracks.map((track: any) => {
                if (track) {
                    //listening tracks of existing for enable/disable to reflect in UI

                    if (track.kind === "audio") this.setState({ audioTrackDisabled: !track?.isEnabled })
                    if (track?.kind === "video") this.setState({ videoTrackDisabled: !track?.isEnabled })

                    track.on('disabled', (e: any) => {
                        if (e.kind === "audio") this.setState({ audioTrackDisabled: !e.isEnabled })
                        else if (e.kind === "video") this.setState({ videoTrackDisabled: !e.isEnabled })
                    })

                    track.on('enabled', (e: any) => {
                        if (e.kind === "audio") this.setState({ audioTrackDisabled: !e.isEnabled })
                        else if (e.kind === "video") this.setState({ videoTrackDisabled: !e.isEnabled })
                    });

                    if (track?.kind !== "data")
                        document.querySelector(`#remote-participant-${this.props.id || this.props.index}`)?.appendChild(track.attach());
                }
            })
        });


    }

    async componentWillUnmount() {
        console.log("COMPONENT_WILL_UNMOUNT CALLED FOR REMOTE-PARTICIPANT-" + this.props.id)
        let remoteParticipantHtmlElement = document.querySelector(`#remote-participant-${this.props.id || this.props.index}`);
        if (remoteParticipantHtmlElement) remoteParticipantHtmlElement.innerHTML = "";
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
        if (this.props.fullName.trim().length) {
            if (prevState.user_full_name !== this.props.fullName) {
                this.setState({ user_full_name: this.props.fullName });
            }
        }
    }

    async receive(from: string, message: Message) {
        // runEngine.debugLog('Message Received', message);
    }

    addTrack(track: any) {
        setTimeout(() => {
            const participant_container = document.querySelector(
                `#remote-participant-${this.props.id || this.props.index}`
            );
            const video_screens = participant_container?.getElementsByTagName("video");

            if (video_screens && video_screens.length > 1) {
                participant_container?.removeChild(video_screens[0]);
            }
        }, 500);

        if (track?.kind !== "data")
            document.querySelector(`#remote-participant-${this.props.id}`)?.appendChild(track.attach());
        this.setState({
            tracks: [...this.state.tracks, track]
        });
    }

}
// Customizable Area End