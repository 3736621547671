// Customizable Area Start
import { IBlock } from '../../../../../framework/src/IBlock';
import { Message } from '../../../../../framework/src/Message';
import { BlockComponent } from '../../../../../framework/src/BlockComponent';
import MessageEnum, {
    getName
} from '../../../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../../../framework/src/RunEngine';
import React, { FC, useEffect, useRef, useState } from "react";
import { Box, Typography, makeStyles, Button, Hidden, CircularProgress } from "@material-ui/core";
import { CameraOn, AudioOn, CameraOff, AudioOff } from "../../assets";
import CustomAvatarGroupWeb from "../../../../DifferentRooms/src/UpcomingRooms/CustomAvatarGroup.web";
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import moment from "moment";
import actionCable from 'actioncable';
const configJSON = require('../config.js');
const app = require('../../../../../framework/src/config.js');

type ROOM_DETAILS = {
    room_id: number | undefined;
    host_details: any;
    participant_users: any[],
    on_call_users: string;
    topic_name: string,
    start_time: string,
    isLoading: boolean,
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '5rem',

        [theme.breakpoints.up('sm')]: {
            height: '100dvh'
        },

        '& > div': {
            '&:last-child': {
                [theme.breakpoints.up('sm')]: {
                    marginLeft: '7rem',
                }
            }
        },

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        }
    },
    video: {
        width: '40rem',
        height: '30rem',
        boxShadow: '0 5px 10px rgba(0,0,0,.5)',
        borderRadius: '.5rem',
        overflow: 'hidden',

        [theme.breakpoints.down('sm')]: {
            width: '90vw',
            height: '38dvh',
        },

        '& > video': {
            borderRadius: '.5rem',
            transform: 'rotateY(180deg)',
            '-webkit-transform': 'rotateY(180deg)', /* Safari and Chrome */
            '-moz-transform': 'rotateY(180deg)', /* Firefox */
            width: '100%',
            minHeight: '100%',
            objectFit: 'cover'
        }
    },
    waves: {
        position: 'absolute',
        right: 5,
        bottom: 0,
        zIndex: 1,

        '& > div': {
            display: 'flex',
            justifyContent: 'center',
            transform: 'scaleY(-1)'
        }
    },
    equalizerBar: {
        width: '8px',
        opacity: 0.9,
        transition: 'height 0.2s',
        marginRight: '4px',
    },
    controls: {
        marginTop: 20,

        '& svg': {
            width: '3.5rem',
            height: '3.5rem',
            cursor: 'pointer',
            transition: 'scale 0.4s',

            '&:hover': {
                scale: 1.2
            },

            '&:first-child': {
                marginRight: 10
            },

            [theme.breakpoints.down('sm')]: {
                width: '3rem',
                height: '3rem',
            }
        }
    },
    alert: {
        position: 'absolute',
        top: '50%',
        right: '50%',
        transform: 'translate(50%,-50%)',

        '& p': {
            fontSize: '1.5rem',
            color: '#fff',

            [theme.breakpoints.down('sm')]: {
                fontSize: '1rem'
            }
        }
    },
    buttons: {
        '& button': {
            width: '20rem',
            borderRadius: '40px !important',

            [theme.breakpoints.down('sm')]: {
                width: '90vw'
            },

            '&:first-child': {
                background: '#FFCB00',
                marginBottom: 15
            },

            '&:last-child': {
                background: '#FF6060',
                color: '#fff'
            },

            '& span': {
                textTransform: 'uppercase',
                fontSize: '1rem',
                fontWeight: 600,
            },

            '&:disabled': {
                opacity: 0.5
            }
        }
    },
    roomDetails: {
        [theme.breakpoints.up('sm')]: {
            marginBottom: '2.5rem',
        },

        [theme.breakpoints.down('sm')]: {
            marginBottom: '1rem',
        },

        '& h5': {
            fontWeight: 500
        }
    },
    avatars: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 20
        }
    }
}));

interface RDProps {
    room_details: ROOM_DETAILS
}

const RoomDetails: FC<RDProps> = ({ room_details }) => {
    const classes = useStyles();

    return (
        <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column' className={classes.roomDetails}>
            <Typography variant="h5">Ready to enter?</Typography>

            <Box pl={3} pr={3} height='7rem'>
                {room_details.isLoading && (
                    <Box sx={{
                        mt: 3,
                        mb: 3,
                    }}>
                        <CircularProgress style={{ color: '#FFCB00' }} />
                    </Box>
                )}

                {!room_details.isLoading && (
                    <Box
                        display='flex'
                        flexDirection='column'
                        alignItems='center'
                        textAlign='center'
                        sx={{
                            mt: 3,
                            mb: 3,
                            border: '1px solid #E0E0E0',
                            p: 1,
                            borderRadius: 10,
                            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
                        }}
                    >
                        <Typography
                            style={{
                                color: '#010039',
                                fontWeight: 600,
                                wordBreak: 'break-word',
                            }}
                        >
                            {room_details.topic_name || '-'} Room
                        </Typography>
                        <Typography
                            style={{
                                fontSize: '.8rem',
                                color: 'gray',
                            }}
                        >
                            on {room_details.start_time ? moment(room_details.start_time).format('Do MMMM YYYY [at] h:mm A') : '-'}
                        </Typography>
                    </Box>
                )}

            </Box>

        </Box>
    )
}

interface ERProps {
    handleJoin: () => void;
    room_details: ROOM_DETAILS,
    on_call_users: any[],
}

const EnterRoom: FC<ERProps> = ({ handleJoin, room_details, on_call_users }) => {
    const classes = useStyles();
    const avatars = on_call_users.map((each) => ({
        id: each.account_id,
        alt: each.user_name,
        src: each.profile_img
    }));

    return (
        <Box>
            <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column' mb={5} className={classes.avatars}>
                {room_details.isLoading && (
                    <CircularProgress style={{ color: '#FFCB00' }} />
                )}

                {(!room_details.isLoading && on_call_users.length > 0) && (
                    <>
                        <Box mb={1}>
                            <CustomAvatarGroupWeb avatars={avatars} maxVisibleAvatars={avatars.length + 1} />
                        </Box>
                        <Typography>Already in the room</Typography>
                    </>
                )}
            </Box>

            <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column' className={classes.buttons}>
                <Button onClick={handleJoin} disabled={room_details.isLoading}>Join</Button>
                <Link to='/Rooms'>
                    <Button>Leave</Button>
                </Link>
            </Box>
        </Box>
    )
}

interface AVProps {
    room_details: ROOM_DETAILS,
    history: RouteComponentProps['history'],
    state: RouteComponentProps["location"]["state"] | any,
    on_call_users: any[],
}

const AdjustVideo: FC<AVProps> = ({ room_details, history, state, on_call_users }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [waveHeights, setWaveHeights] = useState<number[]>([]);
    const [isCameraOn, setCameraOn] = useState(false);
    const [isAudioOn, setAudioOn] = useState(false);
    const audioStreamRef = useRef<MediaStream | null>(null);
    const videoStreamRef = useRef<MediaStream | null>(null);

    const numberOfBars = 5;

    const classes = useStyles();

    const handleCameraOn = () => {
        setIsLoading(true);
        navigator.mediaDevices.getUserMedia({ video: true })
            .then((stream) => {
                videoStreamRef.current = stream; // Store the video stream in the ref
                if (videoRef.current) {
                    videoRef.current.srcObject = stream; // Start the video stream

                    setCameraOn(true);
                }
            })
            .catch((error) => {
                setCameraOn(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleCameraOff = () => {
        if (videoRef.current) {
            videoRef.current.srcObject = null; // Stop the video stream
            setCameraOn(false);
            // setVideoStream(null);
        }
    }

    const handleAudioOn = () => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {
                const audioContext = new AudioContext();
                const mediaStreamSource = audioContext.createMediaStreamSource(stream);
                const analyser = audioContext.createAnalyser();
                analyser.fftSize = 32;
                const bufferLength = analyser.frequencyBinCount;
                const dataArray = new Uint8Array(bufferLength);

                const animationFrame = () => {
                    analyser.getByteFrequencyData(dataArray);

                    const averages: number[] = [];
                    for (let i = 0; i < numberOfBars; i++) {
                        const start = (bufferLength / numberOfBars) * i;
                        const end = (bufferLength / numberOfBars) * (i + 1);
                        const sum = dataArray.slice(start, end).reduce((sum, value) => sum + value, 0);
                        const average = sum / (end - start);
                        averages.push(average);
                    }

                    const isSpeaking = averages.some((average) => average > 150); // Adjust the threshold as needed

                    setIsSpeaking(isSpeaking);
                    setWaveHeights(averages);

                    requestAnimationFrame(animationFrame);
                }

                mediaStreamSource.connect(analyser);
                animationFrame();

                audioStreamRef.current = stream; // Store the audio stream in the ref
                setAudioOn(true);
            })
            .catch((error) => {
                console.error('Error accessing microphone:', error);
                setAudioOn(false);
            });
    }

    const handleAudioOff = () => {
        if (audioStreamRef.current) {
            audioStreamRef.current.getTracks().forEach((track) => {
                if (track.kind === 'audio') {
                    track.stop();
                }
            });
            audioStreamRef.current = null; // Store the audio stream in the ref
            setAudioOn(false);
        }
    }

    const handleRoomJoin = () => {
        if (!state?.state) {
            history.push('/Rooms');
            return;
        }

        // redirect to Video call screen Page
        history.push(`/Room/${room_details.room_id}?video=${isCameraOn}&audio=${isAudioOn}`, {
            state: {
                ...state.state
            },
        });
    }

    useEffect(() => {
        return () => {
            if (audioStreamRef.current) {
                audioStreamRef.current.getTracks().forEach((track) => {
                    if (track.kind === 'audio') {
                        track.stop();
                    }
                });
            }
            if (videoStreamRef.current) {
                videoStreamRef.current.getTracks().forEach((track) => {
                    if (track.kind === 'video') {
                        track.stop();
                    }
                });
            }
        };
    }, []);

    return (
        <Box className={classes.root}>
            <Hidden smUp>
                <RoomDetails room_details={room_details} />
            </Hidden>

            <Box>
                <Box style={{ position: 'relative' }}>
                    <Box className={classes.waves}>
                        <Box>
                            {waveHeights.map((height, index) => (
                                <Box
                                    key={index}
                                    className={classes.equalizerBar}
                                    style={{ height: `${height}px`, backgroundColor: isSpeaking ? '#1e3354' : 'transparent' }}
                                />
                            ))}
                        </Box>
                    </Box>

                    <Box className={classes.video} style={{ background: 'rgb(32,33,36)' }}>
                        <video ref={videoRef} autoPlay />
                        {(!isLoading && !isCameraOn) && (
                            <Box className={classes.alert}>
                                <Typography>Camera is off</Typography>
                            </Box>
                        )}
                        {isLoading && (
                            <Box className={classes.alert}>
                                <Typography>Camera is staring</Typography>
                            </Box>
                        )}
                    </Box>
                </Box>

                <Box display='flex' alignItems='center' justifyContent='center' className={classes.controls}>
                    {isCameraOn ? <CameraOn onClick={handleCameraOff} /> : <CameraOff onClick={handleCameraOn} />}
                    {isAudioOn ? <AudioOn onClick={handleAudioOff} /> : <AudioOff onClick={handleAudioOn} />}
                </Box>
            </Box>

            {!isLoading && (
                <Hidden smUp>
                    <EnterRoom
                        handleJoin={handleRoomJoin}
                        room_details={room_details}
                        on_call_users={on_call_users}
                    />
                </Hidden>
            )}

            <Hidden smDown>
                <Box width='25vw'>
                    <RoomDetails room_details={room_details} />

                    <EnterRoom
                        handleJoin={handleRoomJoin}
                        room_details={room_details}
                        on_call_users={on_call_users}
                    />
                </Box>
            </Hidden>
        </Box>
    )
}

interface Props extends RouteComponentProps { }
interface S {
    room_details: ROOM_DETAILS;
    on_call_users: (number | string)[];
    participants: any[];
}
interface SS { }

class AdjustVideoSettings extends BlockComponent<Props, S, SS> {
    roomDetailMessageId: string;

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            room_details: {
                room_id: undefined,
                start_time: '',
                topic_name: '',
                host_details: null,
                on_call_users: '',
                participant_users: [],
                isLoading: true,
            },
            on_call_users: [],
            participants: [],
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let apiCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let errorJson = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiCallId === this.roomDetailMessageId) {
                this.setState({ room_details: { ...responseJson, isLoading: false } }, () => {
                    const { room_details } = this.state;
                    const host_details = room_details?.host_details;
                    const participant_users = room_details?.participant_users;

                    if (host_details) {
                        this.setState({ participants: [host_details] });
                    }

                    if (participant_users) {
                        this.setState(prev => ({ participants: [...prev.participants, ...participant_users] }));
                    }

                    const on_call_users = room_details?.on_call_users?.split(',');
                    if (on_call_users?.length > 0) {
                        this.checkOnCallUsers(on_call_users);
                    }
                });
            }
        }
    }

    // helpers
    getRoomID = () => {
        const pathname = this.props.location.pathname;
        const regex = /\/(\d+)\/?$/;
        const match = pathname.match(regex);

        if (match) {
            const number = parseInt(match[1]);
            return number;
        } else {
            this.props.history.replace('/Room');
        }
    }

    getRoomDetails = () => {
        let reqMsg2 = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.roomDetailMessageId = reqMsg2.messageId;

        reqMsg2.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.roomDetailsEndpoint}?room_id=${this.getRoomID()}`
        );

        reqMsg2.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetType
        );

        reqMsg2.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            { token: localStorage.getItem('token') }
        );

        runEngine.sendMessage(reqMsg2.id, reqMsg2);
    }

    checkOnCallUsers = (on_call_users: string[]) => {
        this.setState(prev => ({
            on_call_users:
                prev.participants.filter(user => on_call_users.includes(String(user.account_id)))
        }));
    }

    async componentDidMount(): Promise<void> {
        // retrive room details 
        this.getRoomDetails();

        // socket connection to monitor the users that are waiting
        const token = localStorage.getItem('token');
        const account_id = localStorage.getItem('userId');
        const socketURL = `${app.baseURL}/cable?token=${token}`;
        const cable = actionCable.createConsumer(socketURL);

        // AccountsChannel subscription
        cable.subscriptions.create(
            {
                channel: "AccountsChannel",
                account_id,
            },
            {
                connected: () => { },
                disconnected: () => { },
                received: (data) => {
                    if (data?.on_call_users) {
                        this.checkOnCallUsers(data.on_call_users);
                    } else {
                        this.setState({ on_call_users: [] });
                    }
                },
            }
        );
    }

    render(): React.ReactNode {
        return (
            <main>
                <AdjustVideo
                    room_details={this.state.room_details}
                    history={this.props.history}
                    state={this.props.location.state}
                    on_call_users={this.state.on_call_users}
                />
            </main>
        )
    }
}

export default withRouter(AdjustVideoSettings);
// Customizable Area End
