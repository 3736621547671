// Customizable Area Start
import React from "react";
import { Grid } from "@material-ui/core";

const SubCategoryBox = (props: any) => {
   const isModerator = JSON.parse(localStorage.getItem('isModerator') as string);

   return (
      <>
         <div className="topicAndRoomCountLabel">Topics <span>{props.topicsArray.length}</span></div>
         {props.topicsArray.map((item: any, index: number) => {

            if (!isModerator) {
               if (item.attributes.moderator_only) {
                  return null;
               }
            }

            return (
               <div key={index} className={index === 0 ? "d-full" : "d-full marginTop"}>
                  <div className="subcategoryPostOutisde">
                     <Grid container xs={12} className="subcategoryPost">
                        <Grid md={8} xs={12} className="subcategoryName">
                           <span>{item.attributes.name}</span>
                        </Grid>
                        <Grid xs={4} className="roomCount">
                           {/* <span>{item.attributes.topics.length + 1}</span> */}
                           <span>{item.attributes.room_count + ' Rooms'}</span>
                        </Grid>
                     </Grid>
                     <Grid container xs={12}>
                        <Grid md={8} xs={12} className="subcategoryDesc">
                           <span>{item.attributes.description ?? 'No description'}</span>
                        </Grid>
                        <Grid md={4} xs={4} onClick={() => props.goToTopics(true, item)} className="subcategoryButton">
                           Explore Rooms
                        </Grid>
                     </Grid>
                     <Grid container xs={12} className="subcategoryForMobile">
                        <Grid xs={6} className="roomCountForSmall">
                           <span>{item.attributes.room_count + ' Rooms'}</span>
                        </Grid>
                        <Grid xs={6} onClick={() => props.goToTopics(true, item)} className="subcategoryButton">
                           Explore Rooms
                        </Grid>
                     </Grid>
                  </div>
               </div>
            )
         })
         }

      </>
   )
}
export default SubCategoryBox;
// Customizable Area End