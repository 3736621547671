// Customizable Area Start
import React from "react";
import { Typography, Box, Slide } from '@material-ui/core';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';

import { LeaveIcon } from "../../assets";

interface Props {
    maxWidth?: number;
    addBackDrop?: boolean;
    handleClose?: () => void;
    showSlider?: boolean;
}

interface S {
    defaultPosition: { x: number, y: number }
}

class BottomSheet extends React.PureComponent<Props, S> {

    constructor(props: Props) {
        super(props);

        this.state = {
            defaultPosition: { x: 0, y: 0 }
        }
    }

    handleDrag = (_: DraggableEvent, data: DraggableData) => {
        if (data.y >= 50) {
            // Change this value to control the minimum distance to close the slider.
            if (this.props.handleClose)
                this.props.handleClose();
        } else {
            this.setState({ defaultPosition: { x: 0, y: 0 } });
        }
    };

    render(): React.ReactNode {
        const { children, addBackDrop, handleClose, maxWidth, showSlider = true } = this.props;

        return (
            <Box
                id="bottomComponent"
                style={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    borderTopLeftRadius: 20,
                    borderTopRightRadius: 20,
                    zIndex: 1200,
                    maxHeight: `${maxWidth}vh` || '65vh'
                }}
                sx={{
                    bgcolor: 'rgba(0, 0, 0, 0)',
                    border: '1px solid #E0E0E0',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)'
                }}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                {addBackDrop && (
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100vh',
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            zIndex: 1100,  // Adjusted zIndex to be lower than the draggable box
                            backdropFilter: 'blur(4px)',
                        }}
                        onClick={handleClose}
                    />
                )}
                <Draggable
                    axis="y"
                    bounds={{ top: 0 }}
                    onStop={this.handleDrag}
                    position={this.state.defaultPosition}
                    handle=".drag-handle"
                >
                    <Slide direction="up" in={true}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            maxHeight={`${maxWidth}vh` || '65vh'}
                            zIndex={1300}
                            flex={1}
                            bgcolor="#fff"
                            style={{
                                borderTopLeftRadius: 20,
                                borderTopRightRadius: 20,
                            }}
                        >
                            {/* slider */}
                            {
                                showSlider && <Box
                                    flex={1}
                                    className="drag-handle"
                                    height='2rem'
                                    width='100%'
                                    display='flex'
                                    alignItems='center'
                                    justifyContent='center' py={1.5}
                                >
                                    <Box style={{ width: '3rem', height: '4px', background: '#D8D8D8', borderRadius: 1 }} />
                                </Box>
                            }

                            <div style={{ height: '90%', width: '100%', overflow: 'auto' }}>
                                {children}
                            </div>
                        </Box>
                    </Slide>
                </Draggable>
            </Box>
        )
    }
}

interface ABSProps extends Props {
    type: 'exit' | 'alert';
}

export class AlertBottomSheet extends React.PureComponent<ABSProps> {
    render(): React.ReactNode {
        const { children, type } = this.props;
        const iconStyles: React.CSSProperties = { fill: '#FF0100', fontSize: '3rem' };

        return (
            <BottomSheet {...this.props}>
                <Box
                    display='flex'
                    alignItems='center'
                    justifyItems='center'
                    flexDirection='column'
                    py={5}
                    px={2}
                >
                    <Box mb={2}>
                        {type === 'exit' ?
                            <LeaveIcon style={iconStyles} /> :
                            <ErrorOutlineOutlinedIcon style={iconStyles} />
                        }
                    </Box>

                    <Typography style={{ fontWeight: 500, fontSize: '1.5rem', color: '#262556' }}>Alert Update</Typography>

                    {children}
                </Box>
            </BottomSheet>
        )
    }
}

export default BottomSheet;
// Customizable Area End