export const onBoardingOne = require('../assets/Onboarding_One.png');
export const onBoardingTwo = require('../assets/Onboarding_Two.png');
export const onboardingThree = require('../assets/Onboarding_Three.png');
export const onboardingFour = require('../assets/Onboarding_Four.png');
export const imgPasswordVisible = require('../assets/ic_password_visible.png');
export const imgPasswordInVisible = require('../assets/ic_password_invisible.png');
export const CrossIcon = require('../assets/CrossIcon.png');
export const Cone = require('../assets/Cone.png');
export const successfulImg = require('../assets/successfulImg.png');
export const Oval = require('../assets/Oval.png');
export const joh = require('../assets/joh.png');
export const thoma = require('../assets/thoma.png');
export const bgg = require('../assets/bgg.png');
export const bg = require('../assets/bg.svg');
export const ples = require('../assets/ples.png');
export const cross = require('../assets/cross.png');
export const down = require('../assets/down.png');
export const rightarr = require('../assets/rightarr.png');
export const se = require('../assets/se.png');
export const sed = require('../assets/sed.png');
export const dotted = require('../assets/dotted.png');
export const quote = require('../assets/quote.png');
export const logoHalf = require('../assets/logo-half.png');
export const quizImage = require('../assets/quizImage.png');
export const saveMyProgressImage = require('../assets/saveMyProgressImage.png');
export const moduleSuccess = require('../assets/moduleSuccess.png');
