import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
//@ts-ignore
import StateContext from '../../../web/src/globalState';
import { Client as ConversationsClient } from '@twilio/conversations';
import { getFirebaseToken } from "../../../web/src/firebase";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history?: any;
  routeMap: any;
  getMyRooms: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  anchorEl: null | HTMLElement;
  mobileMoreAnchorEl: null | HTMLElement;
  mobileOpen: boolean;
  showContent: string;
  selecteDropDownData: any;
  deactivateReason: any;
  createRoomModal: boolean;
  countryList: any[];
  policyData: any;
  isMessagePop: boolean;
  openProfileDropDown: boolean;
  walletYCoins: number;
  friendsPopUp: null | HTMLElement;
  friendConnectionList: any[];
  chats: any[];
  unreadMsgCount: number;
  getAcceptedAllData: any[];
  boundConversations: Set<string>;
  faqData: any;
  isMobile: boolean;
  isMobileMenuOpen: boolean;
  isExtraSmall: boolean;
  isSearchBarFocused: boolean;
  googleCalendarSync: boolean;
  isFriendRequestOpen: boolean;
  notifications: {
    data: any[],
    totalPage: number,
    currentPage: number,
    isLastPage: boolean,
    isLoading: boolean,
    selectedID: number | null,
  };
  isCheckingOTP: boolean;
  unreadNotificationCount: number;
  openMobileNotification: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings5Controller extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getWalletCoinsId: string;
  getSearchDropdownDataCallId: string;
  toggleGoogleCalendarSyncCallId: string;
  getAllSearchDropdownDataCallId: string;
  getDeactivateAccountCallId: string;
  deactivateAccountCallId: string;
  submitFeedbackCallId: string;
  countryListId: string;
  getTermsAndPolicyId: string;
  getTermsAndConditionsId: string;
  getModeratorTermsAndConditionsId: string;
  getFriendConnectionId: string;
  acceptRejectFriendConnectionId: string;
  twilioAPICallID: string;
  conversationsClient: any;
  loadUserAPICallId: string;
  acceptAllCallId: string;
  unreadMessageCountApiID: string;
  markAllAsReadApiID: string;
  deleteNotificationApiID: string;
  updateFCMTokenMessageID: string;
  deleteAllDataCallId: string;
  notificationCallID: string;
  verifyOtpCallId: string;
  getFAQsId: string;
  msg = "";
  type = "";
  static contextType: any = StateContext;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      anchorEl: null,
      mobileMoreAnchorEl: null,
      mobileOpen: false,
      showContent: "FAQs",
      selecteDropDownData: [],
      deactivateReason: "",
      createRoomModal: false,
      countryList: [],
      policyData: { loading: true },
      isMessagePop: false,
      openProfileDropDown: false,
      walletYCoins: 0,
      friendsPopUp: null,
      friendConnectionList: [],
      chats: [],
      unreadMsgCount: 0,
      getAcceptedAllData: [],
      boundConversations: new Set(),
      faqData: [],
      isMobile: false,
      isMobileMenuOpen: false,
      isExtraSmall: false,
      isSearchBarFocused: false,
      googleCalendarSync: localStorage.getItem("googleCalendarSync") === "true",
      isFriendRequestOpen: false,
      notifications: { data: [], totalPage: 1, currentPage: 1, isLastPage: false, isLoading: false, selectedID: null },
      isCheckingOTP: false,
      unreadNotificationCount: 0,
      openMobileNotification: false,
      // moderatorPolicy: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.deactivateAccount = this.deactivateAccount.bind(this);
    this.submitFeedback = this.submitFeedback.bind(this);
    this.getCountryCode = this.getCountryCode.bind(this);
    this.getTermsAndPolicyData = this.getTermsAndPolicyData.bind(this);
    this.getWalletCoins = this.getWalletCoins.bind(this);
    this.acceptRejectFriendConnection =
      this.acceptRejectFriendConnection.bind(this);

    // Customizable Area End
  }

  navigate(routeMap: any, match: any, history: any, to: any, params?: any) {
    let url = routeMap[to].path;
    // replace params ids in the url with actual values
    if (params && Object.keys(params).length > 0) {
      Object.keys(params).forEach((param) => {
        const re = RegExp(`\:${param}\\??`); // eslint-disable-line no-useless-escape
        url = url.replace(re, escape(params[param]));
      });
    }
    // removing empty params from url - every string between /: and ?
    url = url.replace(/\/:(.*?)(?=\/|$)/g, "");
    // if the route is not a modal
    if (!routeMap[to].modal) {
      history.push(url);
      // if the route is a modal
    } else {
      // checking if the url ends with a slash or not
      const slash = /\/$/.test(match.url) ? "" : "/";
      // current url in the browser + slash + modal url with parameters
      url = match.url + slash + url;
      // removing the */ from the url
      url = url.replace(/\*\/?/g, "");
      history.push(url);
    }
  }

  redirect(history: any, to: any, param: any) {
    history.push({
      pathname: `/${to}`,
      state: param,
    });
    return;
  }

  handlegetAllSearchDropdownDataCallId = (
    apiRequestCallId: any,
    responseJson: any
  ) => {
    if (apiRequestCallId === this.getAllSearchDropdownDataCallId) {
      this.setState({ selecteDropDownData: responseJson });
    }
  };

  handlegetSearchDropdownDataCallId = (
    apiRequestCallId: any,
    responseJson: any
  ) => {
    if (apiRequestCallId === this.getSearchDropdownDataCallId) {
      this.setState({ selecteDropDownData: responseJson });
    }
  };

  handleToggleGoogleCalendarSyncCallId = (
    apiRequestCallId: any,
    responseJson: any
  ) => {
    if (apiRequestCallId === this.toggleGoogleCalendarSyncCallId) {
      console.log(responseJson);
      if (responseJson) {
        localStorage.setItem(
          "googleCalendarSync",
          `${!this.state.googleCalendarSync}`
        );
        this.setState(({ googleCalendarSync }) => ({
          googleCalendarSync: !googleCalendarSync,
        }));
      }
    }
  };

  handledeactivateAccountCallId = (
    apiRequestCallId: any,
    responseJson: any
  ) => {
    if (apiRequestCallId === this.deactivateAccountCallId) {
      console.log("Deactivate Api CAll", responseJson);
    }
  };

  handlesubmitFeedbackCallId = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.submitFeedbackCallId) {
      console.log("submitFeedbackCallId", responseJson);
      if (responseJson.data) {
        this.handleAlertSuccess("Feedback submitted Successfully");
      } else {
        this.handleAlert("Something went wrong!");
      }
    }
  };

  handlecountryListId = (apiRequestCallId: any, responseJson: any) => {
    if (this.countryListId === apiRequestCallId) {
      if (responseJson && responseJson.data)
        this.setState({ countryList: responseJson?.data });
    }
  };

  handlegetTermsAndPolicyId = (apiRequestCallId: any, responseJson: any) => {
    if (this.getTermsAndPolicyId === apiRequestCallId) {
      if (responseJson && responseJson.data) {
        if (responseJson?.data?.name === "TERMS") {
          this.setState({
            policyData: {
              ...this.state.policyData,
              terms: responseJson?.data,
              loading: false,
            },
          });
        } else if (responseJson?.data?.name === "Privacy Policy") {
          this.setState({
            policyData: {
              ...this.state.policyData,
              privacyPolicy: responseJson?.data,
              loading: false,
            },
          });
        }
      }
      if (responseJson?.data?.id === 2) {
        this.getModeratorTermsAndConditions();
      } else if (responseJson && responseJson.errors) {
        this.setState({ policyData: { loading: false } });
        if (responseJson.errors.message)
          this.handleAlert(responseJson.errors.message);
        else this.handleAlert("Some error occured please try again later");
      } else if (responseJson && responseJson.error) {
        this.setState({ policyData: { loading: false } });
        if (responseJson.error) this.handleAlert(responseJson.error);
        else this.handleAlert("Some error occured please try again later");
      }
    }
  };

  handleGetTermsAndConditionsId = (
    apiRequestCallId: any,
    responseJson: any
  ) => {
    if (this.getTermsAndConditionsId === apiRequestCallId) {
      this.setState({ policyData: { terms: responseJson.data, loading: false } });
    }
  };

  handleGetModeratorTermsAndConditionsId = (
    apiRequestCallId: any,
    responseJson: any
  ) => {
    if (this.getModeratorTermsAndConditionsId === apiRequestCallId) {
      this.setState({
        policyData: {
          ...this.state.policyData,
          loading: false,
          moderator:
            responseJson.data.length > 0
              ? responseJson.data[responseJson.data.length - 1]
              : null,
        },
      });
    }
  };

  handlegetWalletCoinsId = (apiRequestCallId: any, responseJson: any) => {
    if (this.getWalletCoinsId === apiRequestCallId) {
      if (responseJson?.data) {
        localStorage.setItem("referral_code", responseJson?.data?.attributes?.referral_code);
        localStorage.setItem("country_code", responseJson?.data?.attributes?.country_code);
        localStorage.setItem("share_link", responseJson?.data?.attributes?.share_link);
        localStorage.setItem("device_ids", JSON.stringify(responseJson?.data?.attributes?.device_id || []));

        localStorage.setItem(
          "isModerator",
          responseJson?.data?.attributes?.is_moderator || false
        );
        localStorage.setItem(
          "googleCalendarSync",
          responseJson?.data?.attributes?.google_calendar_sync || false
        );
        this.setState({ walletYCoins: responseJson.data.attributes.ycoins });
        this.context.setCoin(responseJson.data.attributes.ycoins, false);
        this.context.setUserProfileData(responseJson.data.attributes);
      }
    }
  };

  handlegetFriendConnectionId = (apiRequestCallId: any, responseJson: any) => {
    if (this.getFriendConnectionId === apiRequestCallId) {
      this.setState({ friendConnectionList: responseJson.data });
    }
  };

  handleacceptRejectFriendConnectionId = (
    apiRequestCallId: any,
    responseJson: any
  ) => {
    if (this.acceptRejectFriendConnectionId === apiRequestCallId) {
      if (responseJson.data)
        this.handleAlertSuccess(
          responseJson?.message || "Accepted Successfully!"
        );
      else
        this.handleAlertSuccess(
          responseJson?.message || "Rejected Successfully!"
        );
      this.getFriendConnectionList();
    }
  };

  handleloadUserAPICallId = (apiRequestCallId: any, responseJson: any) => {
    if (this.loadUserAPICallId === apiRequestCallId) {
      if (responseJson?.message) return;
      this.setState({ chats: responseJson?.data?.chats }, () => {
        this.getTwilioToken();
      });
    }
  };

  handletwilioAPICallID = (apiRequestCallId: any, responseJson: any) => {
    if (this.twilioAPICallID === apiRequestCallId) {
      if (responseJson?.token) this.createConnection(responseJson.token);
    }
  };

  handleAcceptAllCallId = (apiRequestCallId: any, responseJson: any) => {
    if (this.acceptAllCallId === apiRequestCallId) {
      this.getFriendConnectionList();
      this.handleAlertSuccess("All Connection request accepted Successfully");
    }
  };

  goToHomePage = () => {
    this.deleteFCMTokenFromBE();
    localStorage.clear();
    sessionStorage.clear();
    setTimeout(() => {
      this.props.history.push("/");
    }, 500);
  };

  handleDeactiveAccountCallId = (apiRequestCallId: any, responseJson: any) => {
    if (this.getDeactivateAccountCallId === apiRequestCallId) {
      this.handleAlertSuccess("Account Deactivated Successfully.");

      // clear local and session storage and redirect to home page
      this.goToHomePage();
    }
  };

  handleVerifyOtp = (apiRequestCallId: any, responseJson: any) => {
    if (this.verifyOtpCallId === apiRequestCallId) {
      this.setState({ isCheckingOTP: false });
      if (responseJson?.meta) {
        this.handleAlertSuccess("Deleted all data successfully.");

        // clear local and session storage and redirect to home page
        this.goToHomePage();
      } else {
        this.handleAlert(
          responseJson?.errors[0]?.message ||
          "Something went wrong. Please try again later."
        );
      }
    }
  };

  handleFAQData = (apiRequestCallId: any, responseJson: any) => {
    if (this.getFAQsId === apiRequestCallId) {
      if (!responseJson.data) {
        this.handleAlert("Some error occured please try again later.");
        return;
      }
      this.setState({ faqData: responseJson.data });
    }
  };

  handleNotificationData = (apiRequestCallId: any, responseJson: any) => {
    if (this.notificationCallID === apiRequestCallId) {
      if (responseJson?.errors) {
        this.setState(prev => ({
          notifications: {
            ...prev.notifications, isLastPage: true, isLoading: false
          }
        }));
        return;
      }

      this.setState(prev => ({
        notifications: {
          ...prev.notifications,
          data: [...prev.notifications.data, ...responseJson?.notifications?.data] || [],
          currentPage: prev.notifications.currentPage + 1,
          totalPage: responseJson?.meta?.total_pages,
          isLastPage: responseJson?.meta?.last_page,
          isLoading: false
        }
      }), () => {
        this.setState({ unreadNotificationCount: responseJson?.meta?.total_unread_count });
      });
    }
  }

  handleUnReadNotificationData = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.unreadMessageCountApiID) {
      if (responseJson?.data) {
        const tempNotifications = this.state.notifications.data.map(
          (each) => each.id === responseJson.data.id ?
            responseJson.data : each);

        this.setState(prev => ({
          notifications: { ...prev.notifications, data: tempNotifications },
          unreadNotificationCount: prev.unreadNotificationCount - 1
        }));
      }
    }
  }

  handleMarkAllAsReadData = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.markAllAsReadApiID) {

      if (responseJson?.errors) {
        return this.handleAlert(responseJson?.errors[0] || "Something went wrong.");
      }

      if (responseJson?.data) {
        // Create the lookup map from updated_notifications array
        const updated_notifications = responseJson.data;

        const updatedNotificationsMap = updated_notifications.reduce((acc: any, notification: any) => {
          acc[notification.id] = notification;
          return acc;
        }, {});

        // Count of processed updates
        let processedUpdates = 0;

        // For each notification in notifications array, update icon_url if id exists in the map
        const notifications = [...this.state.notifications.data];
        for (let notification of notifications) {
          const updatedNotification = updatedNotificationsMap[notification.id];

          if (updatedNotification) {
            notification.attributes.icon_url = updatedNotification.attributes.icon_url;
            processedUpdates++;
          }
          // Break the loop if all updates have been processed
          if (processedUpdates === updated_notifications.length) {
            break;
          }
        }

        this.setState(prev => ({
          notifications: { ...prev.notifications, data: notifications },
          unreadNotificationCount: 0
        }));
      }
    }
  }

  handleDeleteNotificationData = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.deleteNotificationApiID) {

      if (responseJson?.errors) {
        return this.handleAlert(responseJson?.errors[0] || "Something went wrong.");
      }

      if (responseJson) {
        const tempNotifications = this.state.notifications.data.filter((each) => each.id !== this.state.notifications.selectedID);
        this.setState(prev => ({ notifications: { ...prev.notifications, data: tempNotifications } }));
      }
    }
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.handlegetAllSearchDropdownDataCallId(
          apiRequestCallId,
          responseJson
        );
        this.handlegetSearchDropdownDataCallId(apiRequestCallId, responseJson);
        this.handleToggleGoogleCalendarSyncCallId(
          apiRequestCallId,
          responseJson
        );
        this.handledeactivateAccountCallId(apiRequestCallId, responseJson);
        this.handlesubmitFeedbackCallId(apiRequestCallId, responseJson);
        this.handlecountryListId(apiRequestCallId, responseJson);
        this.handlegetTermsAndPolicyId(apiRequestCallId, responseJson);
        this.handleGetTermsAndConditionsId(apiRequestCallId, responseJson);
        this.handleGetModeratorTermsAndConditionsId(
          apiRequestCallId,
          responseJson
        );
        this.handlegetWalletCoinsId(apiRequestCallId, responseJson);
        this.handlegetFriendConnectionId(apiRequestCallId, responseJson);
        this.handleacceptRejectFriendConnectionId(
          apiRequestCallId,
          responseJson
        );
        this.handleloadUserAPICallId(apiRequestCallId, responseJson);
        this.handletwilioAPICallID(apiRequestCallId, responseJson);
        this.handleAcceptAllCallId(apiRequestCallId, responseJson);
        this.handleDeactiveAccountCallId(apiRequestCallId, responseJson);
        this.handleVerifyOtp(apiRequestCallId, responseJson);
        this.handleFAQData(apiRequestCallId, responseJson);
        this.handleNotificationData(apiRequestCallId, responseJson);
        this.handleUnReadNotificationData(apiRequestCallId, responseJson);
        this.handleMarkAllAsReadData(apiRequestCallId, responseJson);
        this.handleDeleteNotificationData(apiRequestCallId, responseJson);

        if (responseJson.errors) {
          if (responseJson.errors[0]?.token) {
            localStorage.clear();
            this.props.history.push("registration");
          }
        }
      }
    }

    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  getSearchDropdownData(data: any) {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllSearchDropdownDataCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchAPiEndPointPage + "1"
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    //  getValidationsMsg.addData(
    //    getName(MessageEnum.RestAPIRequestBodyMessage),
    //    JSON.stringify(payload)
    //  )

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  addCustomSearchData(query: any) {
    const formData = new FormData();
    formData.append("query", query);

    const headers = {
      "Content-Type": undefined,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSearchDropdownDataCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchAllAPiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  toggleGoogleCalendarSync() {
    const formData = new FormData();
    formData.append("calendar_sync", `${!this.state.googleCalendarSync}`);

    const headers = {
      "Content-Type": undefined,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.toggleGoogleCalendarSyncCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.googleCalendarSyncAPiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  deactivateAccount(deactivate_reason: any) {
    const formData = new FormData();
    formData.append("deactivate_reason", deactivate_reason);

    const headers = {
      "Content-Type": undefined,
      token: localStorage.getItem("token"),
    };

    const deactivateApicall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDeactivateAccountCallId = deactivateApicall.messageId;

    deactivateApicall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deactivateAccountAPiEndPoint
    );

    deactivateApicall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    deactivateApicall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    deactivateApicall.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(deactivateApicall.id, deactivateApicall);
  }

  verifyOtp = (firebase_token: string) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
      FToken: firebase_token,
    };

    const verifyOtpMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verifyOtpCallId = verifyOtpMsg.messageId;

    verifyOtpMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyOtpAPiEndPoint
    );

    verifyOtpMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    verifyOtpMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.verifyOTPApiMethod
    );

    verifyOtpMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ reason: this.state.deactivateReason })
    );

    runEngine.sendMessage(verifyOtpMsg.id, verifyOtpMsg);
  };

  submitFeedback(feedback: any) {
    const payload = {
      feedback: feedback,
    };

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.submitFeedbackCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.submitFeedbackAPiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getCountryCode() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "Access-Control-Allow-Origin": "*",
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryListId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCountryListApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getFAQs = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFAQsId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFAQsApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  getTermsAndPolicyData(param: string) {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTermsAndPolicyId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTermsAndPolicyApiEndPoint}${(param && "?name=" + param) || ""
      }`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getTermsAndConditions() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTermsAndConditionsId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTermsAndConditionApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getModeratorTermsAndConditions() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getModeratorTermsAndConditionsId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getModeratorTermsAndConditionApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getWalletCoins() {
    const id = localStorage.getItem("userId");
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getWalletCoinsId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getWalletCoinsApiEndPoint + id
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getFriendConnectionList() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFriendConnectionId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getConnectionRequestDataApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  acceptRejectFriendConnection(requestId: any, status: any) {
    const payload = {
      data: {
        status: status,
      },
    };

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.acceptRejectFriendConnectionId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.acceptRejectFriendConnectionApiEndPoint + requestId
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  AcceptAllConnectionRequest(payload: any) {
    const headers = {
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.acceptAllCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.acceptAllApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      payload
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  fetchAllNotifications = () => {
    const token = localStorage.getItem('token');

    if (!token) return;

    this.setState(prev => ({ notifications: { ...prev.notifications, isLoading: true } }));

    const headers = { token };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationCallID = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.notificationEndPoint}?page=${this.state.notifications.currentPage}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  handleAlertSuccess(msg = "") {
    this.msg = msg;
    this.type = "success";
    this.setState({ isMessagePop: true });
  }

  handleAlert(msg = "") {
    this.msg = msg;
    this.type = "error";
    this.setState({ isMessagePop: true });
  }

  updateWalletCoins(newValue: any) {
    this.setState({ walletYCoins: newValue });
  }

  /* Handle unread messages count */

  getConnectedChatList = () => {
    const token = localStorage.getItem("token");

    if (token) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: token,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.loadUserAPICallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.loadUsersEndpoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.loadUsersMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  getTwilioToken = () => {
    const token = localStorage.getItem("token");

    if (token) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: token,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.twilioAPICallID = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.twilioAPIEndpoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.twilioMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  handleUnreadCount = (id: number) => {
    const token = localStorage.getItem('token');

    if (token && id) {
      const header = {
        'Content-Type': configJSON.validationApiContentType,
        token: token,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.unreadMessageCountApiID = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.unreadCountAPIEndpoint}/${id}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.unreadCountAPIMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  handleMarkAllAsRead = () => {
    const token = localStorage.getItem('token');

    if (token) {
      const header = {
        'Content-Type': configJSON.validationApiContentType,
        token: token,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.markAllAsReadApiID = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.markAllAsReadEndPoint}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.markAllAsReadMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  handleDeleteNotification = (id: number) => {
    const token = localStorage.getItem('token');

    if (token && id) {
      this.setState(prev => ({ notifications: { ...prev.notifications, selectedID: id } }));
      const header = {
        'Content-Type': configJSON.validationApiContentType,
        token: token,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.deleteNotificationApiID = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.deleteNotificationEndPoint}/${id}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.deleteNotificationMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  updateFCMToken = (firebase_token: string) => {
    const token = localStorage.getItem('token');

    const header = {
      'Content-Type': configJSON.validationApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateFCMTokenMessageID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateTokenAPIEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateTokenAPIMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ device_id: firebase_token })
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteFCMTokenFromBE = () => {
    const oldFirebaseToken = localStorage.getItem("firebaseToken");

    const headers = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('token')
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'account_block/accounts/update_device_token'
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );

    // body
    const payload = { last_device_id: oldFirebaseToken };
    console.log("payload", payload);

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  createConnection = async (token: string) => {
    (window as any).conversationsClient = ConversationsClient;
    this.conversationsClient = new ConversationsClient(token);

    let unread = 0;
    const currentUserName = localStorage.getItem("userName");
    this.conversationsClient.on(
      "conversationJoined",
      async (conversation: any) => {
        conversation.getUnreadMessagesCount().then((unread_count: number) => {
          if (!this.state.boundConversations.has(conversation)) {
            let newConversation = conversation;

            newConversation.on("messageAdded", (message: any) => {
              console.log("message", message);
              if (
                !window.location.pathname.includes("/Room/") &&
                message.state.author !== currentUserName
              )
                this.setState((prev) => ({
                  unreadMsgCount: prev.unreadMsgCount + 1,
                }));
            });
            this.setState({
              boundConversations: new Set([
                ...this.state.boundConversations,
                newConversation,
              ]),
            });
          }

          const data = this.state.chats.find(
            (each) => each.conversation_sid === conversation.sid
          );
          if (!data) return;

          if (unread_count === null) unread++;
          else unread += unread_count;

          this.setState({ unreadMsgCount: unread });
        });
      }
    );
  };

  updateUnreadCount = () => {
    window.addEventListener(
      "storage",
      () => {
        const read_count = Number(
          sessionStorage.getItem("remove_count") as string
        );

        const { unreadMsgCount } = this.state;
        if (read_count > 0 && unreadMsgCount >= read_count) {
          this.setState((prev) => ({
            unreadMsgCount: prev.unreadMsgCount - read_count,
          }));
        }
      },
      false
    );
  };

  removeLisener = () => {
    window.removeEventListener("storage", this.handleNotificationCountChange, false);
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("focus", this.fetchAllNotifications);
  };

  handleResize = () => {
    const isMobile = window.innerWidth < 768;
    this.setState({ isMobile });

    const isExtraSmall = window.innerHeight < 680;
    this.setState({ isExtraSmall });
  };

  handlePageChange = () => {
    const location = this.props.history.location;
    if(location?.search) {
      const page = decodeURIComponent(location.search.split("=")[1]);
      this.setState({ showContent: page });
    }
  }

  toggleMobileMenu = () => {
    this.setState({ isMobileMenuOpen: !this.state.isMobileMenuOpen });
  };

  toggleFriendRequestShow = () => {
    this.setState({ isFriendRequestOpen: !this.state.isFriendRequestOpen });
  };

  handleLogout = () => {
    this.deleteFCMTokenFromBE();
    localStorage.clear();
    this.props.history.push("/");
  }

  handleNotificationCountChange = () => {
    const notiCount = JSON.parse(sessionStorage.getItem("notification_count") as string);
    if (!notiCount) return;

    this.setState({ unreadNotificationCount: notiCount }, () => {
      sessionStorage.removeItem("notification_count");
    });
  }

  toggleMobileNotification = () => {
    this.setState(prev => ({ openMobileNotification: !prev.openMobileNotification }));
  }

  handleGetFirebaseToken = () => {
    if (Notification.permission === "granted") {
      getFirebaseToken()
        .then((firebaseToken) => {
          if (!firebaseToken) return;
          // send firebase token with device ID to BE
          this.updateFCMToken(firebaseToken);
        })
        .catch((err: Error) => alert("Error getting Firebase token."));
    } else if (Notification.permission !== "denied") {
      // 1st time user
      Notification.requestPermission()
        .then((permission) => {
          if (permission === "granted") {
            this.handleGetFirebaseToken();
          } else {
            console.log("Notification permission is not granted.");
          }
        })
        .catch((err) => console.log("Error requesting notification permission."));
    } else {
      // reject
      console.log("Notification permission is not granted.");
    }
  }

  handleFCMTokenForAlreadyLoggedInUsers = () => {
    const firebase_token = localStorage.getItem("firebaseToken");
    const device_ids: any[] = JSON.parse(localStorage.getItem("device_ids") as string) || [];

    if (!firebase_token) {
      this.handleGetFirebaseToken();
      return;
    }

    const isTokenFound = device_ids.some(each => each === firebase_token);
    if (!isTokenFound) {
      this.handleGetFirebaseToken();
    }
  }
  // Customizable Area End
}
