Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
const url = require('../../../../framework/src/config.js')
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";
exports.httpPutType = "PUT";
exports.ApiContentType = "application/json";

exports.getYcoins = "bx_block_ycoins/ycoins?page=";
exports.getAccountStatement = "bx_block_different_rooms/rooms/moderated_host";
exports.getAccountStatement = "bx_block_different_rooms/rooms/moderated_host?page=";
exports.updateBankDetails = url.baseURL + "/account_block/accounts";
exports.redeemCoins = 'bx_block_ycoins/ycoins/redeem_request'

exports.fetchTouchPointsApiEndPoint = 'bx_block_different_rooms/rooms/touch_point';
// Customizable Area End
