// Customizable Area Start
import React, { PureComponent } from "react";
import "./dropdown.css"
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';

interface Select extends Props {
    value?: string;
    valueToShow?: any[];
    valueToStore?: any;
}

interface Props {
    name?: string;
    list: string[];
    trial?: any[];
    defaultText?: string;
    defaultValue?: any;
    onChange?: (event: any) => any;
    // value?:string | string[];
    className?: string;
    IconElement?: any;
    style?: {}
    classNameOptions?: string;
    styleOptions?: {};
    type?: string;
    textAlign?: "center" | "right" | "left";
    onBlur?: (event: any) => any;
    value?: string;
}

interface State {
    value: any;
    phone?: string;
}

interface DDState {
    isOpen: boolean;
    value: any;
    selectedValue: string;
}

export default class DropDown extends PureComponent<Props, DDState> {
    private containerRef: React.RefObject<HTMLDivElement>;

    constructor(props: Props) {
        super(props);
        this.state = {
            isOpen: false,
            value: this.props.defaultText || "",
            selectedValue: ""
        };
        this.handleDropDown = this.handleDropDown.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.containerRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside(event: MouseEvent) {
        if (this.containerRef.current && !this.containerRef.current.contains(event.target as Node)) {
            this.setState({ isOpen: false });
            this.props.onBlur && this.props.onBlur({ target: { name: this.props.name } });
        }
    }

    handleDropDown() {
        this.setState(prevState => ({ isOpen: !prevState.isOpen }));
    }

    handleSubmit(value: string) {
        this.setState({ value, selectedValue: value });
        this.props.onChange && this.props.onChange({
            target: { value, name: this.props.name }
        });
    }

    render() {
        const { list, defaultText, className, IconElement, style, classNameOptions, styleOptions, textAlign } = this.props;
        const { isOpen, value, selectedValue } = this.state;

        const dropdownClass = isOpen ? 'block' : '';
        const borderClass = isOpen ? 'bottom-flat-border' : '';

        return (
            <div
                onClick={this.handleDropDown}
                className={`dropdown-container ${className}`}
                ref={this.containerRef}
            >
                <div
                    className={`flex_sb_center dropdown-border ${!!selectedValue.length ? 'dropdown-border-active' : ''} ${borderClass}`}
                    style={{ textAlign, ...style }}
                >
                    <div className="dropdown-value" style={{ opacity: value ? 1 : 0.4, fontWeight: 500 }}>
                        {value || defaultText || "Select an option"}
                    </div>
                    {IconElement || (isOpen ? <KeyboardArrowUpRoundedIcon className='dropdown-icon' /> :
                        <KeyboardArrowDownRoundedIcon className='dropdown-icon' />)}
                </div>
                <div className={`dropdown-content-container ${dropdownClass}`}>
                    <div className='dropdown-content-scroll'>
                        {
                            !!list?.length ? list.map((item, index) => (
                                <div
                                    className={`dropdown-content dropdown-content-border ${classNameOptions}`}
                                    key={index}
                                    onClick={() => this.handleSubmit(item)}
                                    style={{ ...styleOptions }}
                                >
                                    {item}
                                </div>
                            ))
                                : <div className='dropdown-content dropdown-content-border'>No data found</div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}


interface PhoneProps extends Select {
    phone?: string;
    inputTabIndex?: number;
}

//Component for Mobile input for sign up page
export class MobileInput extends PureComponent<PhoneProps, State, any> {
    private myRef: React.RefObject<HTMLInputElement>;
    private dRef: React.RefObject<HTMLInputElement>;
    item: React.RefObject<any>;
    constructor(props: PhoneProps) {
        super(props)
        this.state = {
            value: "",
            phone: ''
        }
        this.handleDropDown = this.handleDropDown.bind(this)
        this.onChange = this.onChange.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this)
        this.myRef = React.createRef()
        this.dRef = React.createRef()
        this.item = React.createRef()
    }

    componentDidMount() {
        document.querySelector(".dropdown-container *")
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside(event: any) {
        if (this.myRef?.current && !this.myRef?.current.contains(event.target) && this.myRef.current?.className.indexOf("block") > -1) {
            this.myRef.current?.classList.remove('block')
            this.dRef.current?.classList.remove('bottom-flat-border')
        }
    }

    handleDropDown(e: any) {

        if (this.myRef.current?.className.indexOf("block") === -1) {
            this.myRef.current?.classList.add('block')
            this.dRef.current?.classList.add('bottom-flat-border')
        }
        else {
            this.myRef.current?.classList.remove('block')
            this.dRef.current?.classList.remove('bottom-flat-border')
        }
    }
    onChange(ab: any) {
        ab()
    }



    render() {
        const { list,
            trial,
            defaultText,
            defaultValue,
            onChange: OnChange,
            value,
            valueToShow,
            valueToStore,
            className,
            IconElement,
            style,
            classNameOptions,
            styleOptions,
            type,
            textAlign,
            onBlur: OnBlur,
            name,
            phone,
            inputTabIndex,
        } = this.props

        return (
            <div onClick={this.handleDropDown} className={`dropdown-container ${className}`} >
                <div className="flex_sb_center dropdown-border" ref={this.dRef} style={{ textAlign, ...style }} >
                    <div className="dropdown-value country-code-div"> {value || "Default Text"} </div>
                    <input
                        name={name}
                        onClick={(e) => e.stopPropagation()}
                        tabIndex={inputTabIndex}
                        className='phone-input'
                        value={this.props.phone}
                        placeholder={defaultText || "Default Text"}
                        onChange={(e) => {
                            if (RegExp(/^[0-9]{0,12}$/).test(e.target.value)) {
                                const event = {
                                    target: {
                                        value: defaultValue,
                                        phoneNumber: e.target.value
                                    }
                                };
                                this.setState({ phone: e.target.value as string });
                                OnChange && OnChange(event);
                            }
                        }}
                        onBlur={(e) => {
                            const event = {
                                target: {
                                    name
                                }
                            }
                            OnBlur && OnBlur(event)
                        }}
                        autoComplete="off"
                        type="number"
                        autoFocus
                    />
                </div>
                <div id="Demoo" ref={this.myRef} className="dropdown-content-container">
                    <div className='dropdown-content-scroll' style={{ maxHeight: "230px" }}>
                        {
                            trial?.map(
                                (item, ind) => {
                                    const isPresent = value === item.flag + " " + item.dial_code
                                    return <div
                                        className={`dropdown-content pointer ${isPresent ? "selected" : ""} ${classNameOptions}`}
                                        key={ind}
                                        onClick={
                                            () => {
                                                const event = {
                                                    target: {
                                                        value: item,
                                                        phoneNumber: this.state.phone
                                                    }
                                                }

                                                OnChange && OnChange(event)
                                            }
                                        }
                                        style={{ ...styleOptions }}
                                    >
                                        <span className="drop_down_flag_style">{item.flag}</span><span className="drop_down_countryName_style">{item.name}</span>
                                    </div>
                                }
                            )
                        }
                    </div>
                </div>
            </div>

        )
    }
}

interface SaparateValuesDropdown {
    value: string,
    selected: string,
}

interface SaparateValuesDropDownProps extends Props {
    value: any,
    startContent: string;
    endContent: string;
    contents?: any[];
}

export class SaparateValuesDropDown extends PureComponent<SaparateValuesDropDownProps, DDState> {
    private containerRef: React.RefObject<HTMLDivElement>;

    constructor(props: SaparateValuesDropDownProps) {
        super(props);
        this.state = {
            isOpen: false,
            value: "",
            selectedValue: ""
        };
        this.handleDropDown = this.handleDropDown.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.containerRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside(event: MouseEvent) {
        if (this.containerRef.current && !this.containerRef.current.contains(event.target as Node)) {
            this.setState({ isOpen: false });
            this.props.onBlur && this.props.onBlur({ target: { name: this.props.name } });
        }
    }

    handleDropDown() {
        this.setState(prevState => ({ isOpen: !prevState.isOpen }));
    }

    handleSubmit(value: any) {
        console.log(value)
        this.setState({ value, selectedValue: value.id });
        this.props.onChange && this.props.onChange({
            target: { value, name: this.props.name }
        });
    }

    render() {
        const { name, contents, startContent, endContent, className, IconElement, style, classNameOptions, styleOptions, textAlign } = this.props;
        const { isOpen, value, selectedValue } = this.state;

        const dropdownClass = isOpen ? 'block' : '';
        const borderClass = isOpen ? 'bottom-flat-border' : '';

        return (
            <div
                onClick={this.handleDropDown}
                className={`dropdown-container ${className}`}
                ref={this.containerRef}
            >
                <div
                    className={`flex_sb_center dropdown-border ${!!selectedValue ? 'dropdown-border-active' : ''} ${borderClass}`}
                    style={{ textAlign, ...style }}>
                    <div className="dropdown-value" style={{ fontWeight: 500 }}>
                        {value?.attributes?.name || "Select an option"}
                    </div>
                    {IconElement || (isOpen ? <KeyboardArrowUpRoundedIcon className='dropdown-icon' /> :
                        <KeyboardArrowDownRoundedIcon className='dropdown-icon' />)
                    }
                </div>
                <div className={`dropdown-content-container ${dropdownClass}`}>
                    <div className='dropdown-content-scroll'>
                        {
                            contents && contents.length > 0
                                ? contents.map((item, index) => (
                                    <div
                                        className={`dropdown-content dropdown-content-border ${classNameOptions}`}
                                        key={index}
                                        onClick={() => this.handleSubmit(item)}
                                        style={{ ...styleOptions, display: "flex", justifyContent: "space-between" }}
                                    >
                                        <span style={{ textAlign: "left" }}>{item?.attributes[startContent]}</span>
                                        <span />
                                        <span style={{ textAlign: "right" }}>
                                            {name === "room"
                                                ? (item?.attributes[endContent] && item?.attributes[endContent] + " YCoins" || "Free")
                                                : item[endContent]
                                            }
                                        </span>
                                    </div>
                                ))
                                : <div className='dropdown-content dropdown-content-border'>No data found</div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}


// Customizable Area End
