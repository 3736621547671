Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.apiEndPointGetSubcategory = "bx_block_categories/sub_categories"
exports.apiEndPointGetCategory ="bx_block_categories/categories"
exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";
exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET"

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.removeFromRoomApiEndPoint = "bx_block_different_rooms/rooms/delete_room_registration?room_id="
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.methodTypeGet = "GET",
exports.getUserProfileDataAPIEndPoint = "bx_block_profile/profiles/"
exports.getConnecteduserDataAPIEndPoint = "bx_block_request_management/requests/friends";
exports.methodTypePost = "POST"
exports.methodTypeDelete = "DELETE"
exports.methodTypePut = "PUT"
exports.methodTypePatch = "PATCH"
exports.sendConnectRequestApiEndPoint = "/bx_block_request_management/requests";
// exports.getPastRoomAsPerUserIdApiEndPoint = "bx_block_different_rooms/rooms/user_past_and_upcoming_rooms";
exports.getPastRoomAsPerUserIdApiEndPoint = "bx_block_different_rooms/rooms/global_rooms?";
exports.sendConversationRequestApiEndPoint = "bx_block_chat/chat_tokens/create_conversations";
exports.RegisterRoomApiEndPoint = "bx_block_different_rooms/rooms/registered_in_rooms";
exports.getWalletCoinsApiEndPoint = "bx_block_profile/profiles/";
exports.deleteresourceApiEndPoint = "bx_block_profile/profiles/delete_resource?id=";
exports.reportResourceApiEndPoint = "bx_block_different_rooms/reported_user/report_to_resource?";
exports.getMyFollowerAPiEndPoint = "bx_block_followers/follows";
exports.likeResourceAPIEndPoint = "bx_block_like/likes";
exports.updateTutorialsApiContentType = "application/json";
exports.updateTurtoialsMethod = "PUT";
exports.updateTutorialsApiEndPoint = "account_block/accounts/update_flags";
exports.deleteLikeResourceAPIEndPoint = "bx_block_like/likes/";
exports.followModeratorApiEndPoint = "bx_block_followers/follows";
exports.unfollowModeratorApiEndPoint = "bx_block_followers/follows/";
exports.acceptRejectFriendConnectionApiEndPoint = "bx_block_request_management/requests/";
exports.disConnectUserApiEndPoint = "bx_block_request_management/requests/disconnect_request?account_id="

// touch points
exports.fetchTouchPointsApiEndPoint = 'bx_block_different_rooms/rooms/touch_point'

// block and unblock
exports.blockUserApiEndpoint = 'account_block/accounts/block_user'
exports.unBlockUserApiEndpoint = 'account_block/accounts/unblock_user'
exports.httpPostType = 'POST'
exports.httpDeleteType = 'DELETE'
// Customizable Area End

