// Customizable Area Start
import { IBlock } from '../../../../../../framework/src/IBlock';
import { Message } from '../../../../../../framework/src/Message';
import { BlockComponent } from '../../../../../../framework/src/BlockComponent';
import MessageEnum, {
	getName
} from '../../../../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../../../../framework/src/RunEngine';
import moment from 'moment';

export const configJSON = require('../../config.js');

export interface Props {
	// navigation: any;
	// id: string;
	client: any;
	isConnected: boolean;
	localIdentity: any;
	conversationSID: any;
	allRoomParticipants: any[];
}

export interface message {
	authorSID: string;
	author: string;
	body: string;
	timestamp: string;
}

export interface S {
	conversation: any;
	messageList: message[];
	isMobile: boolean;
	isTyping: boolean;
	typer: string;
}

export interface SS {
	id: any;
}

export default class messageTabController extends BlockComponent<Props, S, SS> {
	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);

		this.subScribedMessages = [];

		this.state = {
			conversation: undefined,
			messageList: [],
			isMobile: false,
			isTyping: false,	
			typer: ''
		};
		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
	}

	// Customize Area Start

	lastMesgRef: React.RefObject<HTMLDivElement>;

	// Customize Area End

	setMessgaes = (messages: any[]) => {
		let messageObject = messages.reverse().map((item: any) => {
			return {
				authorSID: item.participantSid,
				author: item.author,
				body: item.body,
				timestamp: moment(item.dateCreated).format('LT')
			};
		});
		this.setState({ messageList: messageObject }, () => this.executeScroll());
	}

	receivedMessagePaginator = (messagePaginator: any, totalMessages: number, messages: any[]) => {
		totalMessages += messagePaginator.items.length;
		messagePaginator.items.reverse().forEach(function (message: any) {
			messages.push(message);
		});

		if (messagePaginator.hasPrevPage) {
			messagePaginator.prevPage()
				.then((msgPaginator: any) =>
					this.receivedMessagePaginator(msgPaginator, totalMessages, messages)
				);
		} else {
			// set messages...
			this.setMessgaes(messages);
		}
	}


	async componentDidMount() {
		super.componentDidMount();

		const messages: any[] = [];
		let totalMessages = 0;
		if (this.props.isConnected === true && this.props.client && this.props.conversationSID) {
			try {
				let conversationSID = this.props.conversationSID;
				let conversation = await this.props.client?.getConversationBySid(
					conversationSID
				);
				this.setState({ conversation },
					async () => {
						// fetch messages, max 100 messages can fetch at once
						conversation.getMessages(100).
							then((msgPaginator: any) =>
								this.receivedMessagePaginator(msgPaginator, totalMessages, messages)
							);

						// on message add 
						conversation.on('messageAdded', (msg: any) => {
							let newMsg = {
								authorSID: msg.participantSid,
								author: msg.author,
								body: msg.body,
								timestamp: moment(msg.dateCreated).format('LT')
							};
							this.setState({
								messageList: [...this.state.messageList, newMsg]
							}, () => this.executeScroll());
						});

						// handle typing 
						conversation.on('typingStarted', (x: any) => {
							this.setState({ isTyping: true, typer: x.identity });
						});
						conversation.on('typingEnded', (x: any) => {
							this.setState({ isTyping: false, typer: '' });
						});


						// mark all messages to read when open chat window
						conversation.setAllMessagesRead();
					});
			} catch (error) {
				console.log(error);
			}
		}

		this.handleResize();
		window.addEventListener('resize', this.handleResize);
	}

	async componentWillUnmount(): Promise<void> {
		window.removeEventListener('resize', this.handleResize);
	}

	async receive(from: string, message: Message) {
		runEngine.debugLog('Message Received', message);
	}

	handleResize = () => {
		const isMobile = window.innerWidth <= 768;
		this.setState({ isMobile });
	}

	executeScroll = () => {
		if (this.lastMesgRef && this.lastMesgRef.current) {
			if (this.state.isMobile) {
				this.lastMesgRef.current.scrollIntoView();
			} else {
				this.lastMesgRef.current.scrollIntoView({ behavior: "auto", block: "end" });
			}
		}
	}
}
// Customizable Area End
