Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start

exports.roomDetailsEndpoint = `bx_block_chat/chat_tokens/room_details`;
exports.sendConnectionRequestEndpoint = `bx_block_request_management/requests`;
exports.participantTokenGenerationEndpoint = `bx_block_chat/chat_tokens/video_call_access_token`;
exports.roomExtensionEndpoint = `bx_block_different_rooms/rooms/extend_room`;

exports.createTwilioRoomEndpoint = `bx_block_chat/chat_tokens/create_room`;
exports.callRequestHttpsEndpoint = `bx_block_chat/call_requests`;
exports.retractRequestEndpoint = `bx_block_chat/call_requests/retract_request`;

exports.httpPostType = "POST";
exports.httpGetType = "GET";

exports.contentType = "application/json";
exports.endCallEndPoint = "bx_block_different_rooms/rooms/end_room_for_user";
exports.leaveCallForBothUserAPI = 'bx_block_different_rooms/rooms/end_scheduled_room'
exports.endCallMethod = "POST";

exports.leaveCallAPI = "bx_block_different_rooms/rooms/leave_room";
exports.leaveCallMenthod = "POST";

exports.acceptRejectFriendConnectionApiEndPoint =
  "bx_block_request_management/requests";
exports.patchApiMethod = "PATCH";
exports.validationApiContentType = "application/json";

exports.endCallForAllEndPoint =
  "bx_block_different_rooms/rooms/end_room_for_all";
exports.endCallForCallMethod = "POST";

exports.earnedYCoinsEndPoint = "bx_block_ycoins/ycoins/earned_ycoins";
exports.earnedYCoinsMethod = "POST";
// Customizable Area End
