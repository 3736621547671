// Customizable Area Start
import React, { Component } from 'react';
import CustomizableModalWindow from './customizableModalWindow.web';
import {
	Card, CardContent, Typography,
	Box, Slide
} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import './Modals.css';
import { AlertBottomSheet } from './BottomSheet.web';

interface Props {
	reason: string;
	isMobile: boolean;
}

export default class AlertModal extends Component<Props> {
	render() {

		if (this.props.isMobile) {
			return (
				<CustomizableModalWindow id='alert-window' open={true}>
					<AlertBottomSheet type='alert'>
						<Box mt={1} textAlign='center'>
							<Typography style={{ color: '#ccc' }}>You have been alerted for {this.props.reason}.</Typography>
						</Box>
					</AlertBottomSheet>
				</CustomizableModalWindow>
			)
		}

		return (
			<CustomizableModalWindow id='alert-window' open={true}>
				<Card
					className='border-card-modal'
					style={{ width: '20rem', borderColor: '#ffcb00' }}
				>
					<Typography id='modal-text' variant='h6'>
						<ErrorOutlineIcon className='modal-icon' fontSize='small' />
						Alert Update
					</Typography>
					<CardContent style={{ color: 'gray', fontSize: '.9rem' }}>
						{`You have been alerted for ${this.props.reason}.`}
					</CardContent>
				</Card>
			</CustomizableModalWindow>
		);
	}
}
// Customizable Area End
