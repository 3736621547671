// Customizable Area Start
import React from 'react';

import { Tooltip, Box, Typography, } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';

interface UIProps {
    minWidth: number;
    minHeight: number;
    position: 'top' | 'bottom' | 'left' | 'right';
    gap: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        arrow: {
            "&:before": {
                border: "1px solid #ffcb00",
                borderTopLeftRadius: "5px",
                borderBottomLeftRadius: "5px",
                boxShadow: "0 0 5px rgba(255, 203, 0, 0.2), 0 0 5px rgba(255, 203, 0, 0.2), 0 0 5px rgba(255, 203, 0, 0.2), 0 0 5px rgba(255, 203, 0, 0.2)",
            },
            color: '#fff',
            fontSize: 50,

        },
        tooltip: (props: UIProps) => ({
            marginLeft: props.position === 'right' ? props.gap : 'auto',
            marginTop: props.position === 'bottom' ? props.gap : 'auto',
            marginRight: props.position === 'left' ? props.gap : 'auto',
            backgroundColor: '#fff',
            border: ".5px solid #ffcb00",
            color: "#000",
            height: props.minHeight,
            minWidth: props.minWidth,
            borderRadius: "25px",
            boxShadow: "0 0 10px rgba(255, 203, 0, 0.2), 0 0 10px rgba(255, 203, 0, 0.2), 0 0 10px rgba(255, 203, 0, 0.2), 0 0 10px rgba(255, 203, 0, 0.2)",
            padding: "20px",
        }),
        heading: {
            fontWeight: 600,
            fontSize: 17,
        },
        message: {
            fontWeight: 600,
            fontSize: 14,
            marginTop: 20
        },
        nextBtn: {
            marginLeft: 'auto',
            marginTop: 'auto',
            cursor: 'pointer',

            "& span": {
                fontWeight: 600,
                padding: 0,
                margin: 0
            }
        },
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 999,
            background: 'rgba(0, 0, 0, 0)',
            pointerEvents: 'auto',
        }
    })
);

interface Props {
    data: any[];
    current: number;
    isOpen: boolean;
    onNext: () => void;
    placement?: 'top' | 'bottom' | 'left' | 'right';
}

const Tutorials: React.FC<Props> = (props) => {
    const { data, current, children, isOpen, onNext, placement = 'bottom' } = props;

    const tutorials = data[current];
    const classes = useStyles({
        minWidth: tutorials?.width || 300,
        position: placement,
        gap: tutorials?.gap || 'auto',
        minHeight: tutorials?.height || 180,
    });

    if (!tutorials) return <>{children}</>;

    const { title, description } = tutorials;

    const total = data.length;

    const Overlay: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
        const classes = useStyles({ minWidth: 0, minHeight: 0, position: 'bottom', gap: 0 });
        return <div className={classes.overlay} onClick={onClick} />;
    };

    return (
        <>
            {isOpen && <Overlay onClick={onNext} />}
            <Tooltip
                title={
                    <Box display="flex" flexDirection="column" height="100%">
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography color="inherit" className={classes.heading}>
                                {title}
                            </Typography>
                            <Typography className={classes.heading}>
                                ({current + 1}/{total})
                            </Typography>
                        </Box>

                        <Box>
                            <Typography className={classes.message}>{description}</Typography>
                        </Box>

                        <Box className={classes.nextBtn} onClick={onNext}>
                            <Typography component="span">
                                {current === total - 1 ? 'Done' : 'Next'}
                            </Typography>
                        </Box>
                    </Box>
                }
                classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                placement={placement}
                open={isOpen}
                arrow
                interactive
                TransitionComponent={Zoom}
            >
                <div>{children}</div>
            </Tooltip>
        </>
    );
};

export default Tutorials;
// Customizable Area End