// Customize Area Start
import React, { FC } from 'react';
import { Modal, Typography, Box, Button, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import firebase from "firebase";
import LoginWithInsta from './LoginWithInsta';
import LoginWithLinkedIn from './LoginWithLinkedIn';
// Customize Area End

// Customize Area Start
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backdropFilter: "blur(3px)",
        backgroundColor: 'rgba(0,0,10,0.7)',
    },
    paper: {
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 550,
        borderRadius: ".5rem",
        textAlign: "center"
    },
    message: {
        color: "#222153",
        fontWeight: 600
    },
    buttons: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 30,

        "& button": {
            height: 45,
            borderRadius: ".3rem !important",
            width: 350,

            "&:first-child": {
                fontSize: 18,
                background: "#010039",
                color: theme.palette.common.white,
            },
            "&:last-child": {
                border: "1px solid #222153",
                fontSize: 18,
                marginTop: 10
            }
        }
    }
}));
// Customize Area End

// Customize Area Start
type CHOICE = "Instagram" | "Facebook" | "Twitter" | "LinkedIn";
interface Props {
    // Customize Area Start
    isOpen: boolean;
    handleClose: (method: CHOICE, type: "cancel" | "done") => void;
    userChoice: CHOICE | null;
    updateToken: (type: CHOICE, details: any) => void;
    // Customize Area End
}
// Customize Area End

// Customize Area Start
const ConfirmationModal: FC<Props> = ({ isOpen, handleClose, userChoice, updateToken }) => {

    // Customize Area Start
    const classes = useStyles();
    const handleAuth = async () => {
        let provider;

        if (userChoice === "Facebook") {
            provider = new firebase.auth.FacebookAuthProvider();
        } else if (userChoice === "Twitter") {
            provider = new firebase.auth.TwitterAuthProvider();
        }

        if (!provider) {
            return;
        };

        firebase
            .auth()
            .signInWithPopup(provider)
            .then((result) => {
                const profile: any = (result.additionalUserInfo as firebase.auth.AdditionalUserInfo).profile;

                let userLink = '';
                if (userChoice === "Twitter") {
                    userLink = `https://twitter.com/${profile?.screen_name}`
                }
                if (userChoice === 'Facebook') {
                    userLink = profile.link;
                }
                updateToken(userChoice as CHOICE, {
                    profile,
                    accessToken: (result.credential as firebase.auth.OAuthCredential).accessToken,
                    userLink,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };
    // Customize Area End

    // Customize Area Start

    return (
        <Modal
            open={true}
            onClose={() => handleClose(userChoice as CHOICE, "cancel")}
            className={classes.modal}
            style={{ display: !isOpen ? "none" : "flex" }}
        >
            <Fade in={isOpen} >
                <Box className={classes.paper}>
                    <Typography className={classes.message}>You've to log in with your {userChoice} account to continue.</Typography>

                    <Box className={classes.buttons}>
                        {(userChoice === "Facebook" || userChoice === "Twitter") &&
                            <Button onClick={handleAuth}>Login with {userChoice} </Button>
                        }

                        <LoginWithLinkedIn
                            client_id={"77k9apmmc5vs6w" || ''}
                            client_secret={"uxxDWdW1loz0Slqv" || ''}
                            redirect_uri={window.location.href}
                            onResolve={({ _, data }: any) => {
                                updateToken(userChoice as CHOICE, {
                                    profile: data,
                                    accessToken: data.access_token
                                });
                            }}
                            onReject={(err: any) => {
                                console.log(err);
                                alert("Something went wrong, please try again later.");
                                handleClose("LinkedIn", "cancel");
                            }}
                        >
                            <Button style={{ display: userChoice === "LinkedIn" ? "flex" : "none" }}>
                                Login with {userChoice}
                            </Button>
                        </LoginWithLinkedIn>

                        <LoginWithInsta
                            client_id={"642389557669968" || ''}
                            client_secret={"04aa5353b21ccc115c560b76f3148465" || ''}
                            redirect_uri={window.location.href}
                            onResolve={({ _, data }: any) => {
                                let userLink = `https://instagram.com/${data.username}`
                                updateToken(userChoice as CHOICE, {
                                    profile: data,
                                    accessToken: data.access_token,
                                    userLink
                                });
                            }}
                            scope={"user_profile"}
                            onReject={(err: any) => {
                                console.log(err);
                                alert("Something went wrong, please try again later.");
                                handleClose("Instagram", "cancel");
                            }}
                        >
                            <Button style={{ display: userChoice === "Instagram" ? "flex" : "none" }}> Login with {userChoice} </Button>
                        </LoginWithInsta>
                        <Button onClick={() => handleClose(userChoice as CHOICE, "cancel")}>Cancel</Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}
// Customize Area End

// Customize Area Start
export default ConfirmationModal;
// Customize Area End
