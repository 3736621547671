export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const Oval = require("../assets/Oval.png");
export const joh = require("../assets/joh.png");
export const thoma = require("../assets/thoma.png");
export const england = require("../assets/england.png");
export const dotted = require("../assets/dotted.png");
export const heart = require("../assets/heart.png");
export const people = require("../assets/people.png");
export const wallet = require("../assets/wallet.png");
export const thum = require("../assets/thum.png");
export const attach = require("../assets/attach.png");
export const tick = require("../assets/Tick.png");
export const facebook = require("../assets/Facebook.svg");
export const instagram = require("../assets/instagram.svg");
export const linkedin = require("../assets/linkdin.svg");
export const Twitter = require("../assets/twitter.svg");
export const FacebookPng = require("../assets/Facebook.png");
export const InstagramPng = require("../assets/Instagram.png");
export const LinkedInPng = require("../assets/LinkedIn.png");
export const TwitterPng = require("../assets/Twitter.png");
export const mainLogo = require("../assets/main_logo.png");
export const logo = require("../assets/logo.svg");
export const celebrationImage = require("../assets/celebration_image.png");
export const wallet_image = require("../assets/wallet_image.png");
export const profile_image = require("../assets/profile_image.png");
export const profile_imageIcon = require("../assets/profile_image.png");
export const otpLogo = require("../assets/otp_logo.png");
export const cancel_logo = require("../assets/cancel_logo.png");
export const backarrow = require("../assets/backarrow.png");
export const cross = require("../assets/cross.png");
export const ples = require("../assets/ples.png");
export const goldenTick = require("../assets/GoldenTick.svg");
export const blueTick = require("../assets/BlueTick.svg");
export const likeIcon = require("../assets/like.svg");
export const Message = require("../assets/Message.svg");
export const Circle = require("../assets/Circle.svg");
export const NoUserImg = require("../assets/no_img.png");
