// Customizable Area Start
import React from 'react'
import { backarrow } from "../../assets";
import Skeleton from '@material-ui/lab/Skeleton';

const LeftMenu = (props: any) => {
   return (
      <>
         <div className="subcategoriesBox">
            <div onClick={() => props.backToCategories()} className="subcategoriesBackButton">
               <img src={backarrow} width="22px" height="22px" alt="" />{props.match.topic_id ? 'Back to Subcategories' : 'Back to Categories'}
            </div>
            <div className="subcategoriesLabel">
               {props.match.topic_id ? 'Topics' : 'Subcategories'}
            </div>
            <div className="subcategoriesList">
               {props.match.topic_id &&
                  props.topicsArray && props.topicsArray.map((item: any, index: number) => {
                     return (
                        <div onClick={() => props.goToTopics(true, item)} key={index} className={"subcategoriesListBox " + (item.id == props?.selectedTopic[0]?.id ? 'active' : '')}>
                           <div className="subcategoriesName">
                              {/* <img src={item.attributes.img} alt="" /> */}
                              <span>{item.attributes.name}</span>
                           </div>
                        </div>
                     )
                  })
               }
               {!props.match.topic_id &&
                  (
                     props.selectedSubMenu.length === 0 ?
                        Array(10).fill(0).map((_, i) =>
                           <Skeleton key={i} width="100%" animation="wave" height="50px" variant='text' />
                        )
                        :
                        props.selectedSubMenu.map((item: any) => {
                           return (
                              <div onClick={() => props.goToSubcategory(null, item)} key={item.id} className={"subcategoriesListBox " + (props.selectedSub && item.id == props?.selectedSub[0]?.id ? 'active' : '')}>
                                 <div className="subcategoriesName">
                                    <span>{item.attributes.name}</span>
                                 </div>
                              </div>
                           )
                        })
                  )
               }
            </div>
         </div>
      </>
   )
}
export default LeftMenu;
// Customizable Area End