// Customizable Area Start

import React from "react";
import { Grid, CircularProgress } from '@material-ui/core';
import AlertBlock from "../../../alert/src/Alert.web";
import './verification.css';
import { backarrow } from "../assets";
// import Onboarding from "./onBoarding.web";
// import EmailAccountRegistrationController from "../EmailAccountRegistrationController";
import { withRouter } from "react-router-dom";
import './UserEditProfileWeb.scss';
import UserEditProfileController from "./UserEditProfileController";
import PublicInformation from './components/PublicInformation';
import ModeratorInformation from "./components/ModeratorInformation";
import PersonalInformation from "./components/PersonalInformation";
import RouteLeavePrompt from "./routerLeavePrompt";



class UserEditProfile extends UserEditProfileController {
    constructor(props: any) {
        super(props);
    }

    // constructor(props: any) {
    //     super(props);
    //     this.handleChange = this.handleChange.bind(this);
    //     this.handleSubmit = this.handleSubmit.bind(this);
    // }

    render() {
        return (
            <Grid container xs={12} className="UserEditProfile" justifyContent="center">
                <AlertBlock data={{ show: this.state.isMessagePop, close: this.handleCloseAlert, msg: this.msg, type: this.type, vertical: "top", horizontal: "center" }} />
                <RouteLeavePrompt
                    when={this.state.blockNavigation}
                    navigate={(path: any) => {
                        this.props.history.push(path);
                    }}
                    shouldBlockNavigation={(location: any) => {
                        if (this.state.blockNavigation) {
                            return true;
                        }
                        return false;
                    }}
                />
                {this.state.loading ?
                    <div className="loadingMask">
                        <Grid container xs={12} style={{ height: '100%', justifyContent: 'center' }} alignItems="center">
                            <div>
                                <CircularProgress size={80} thickness={4} style={{ color: '#ffcb00' }} />
                            </div>
                        </Grid>
                    </div>
                    :
                    <></>
                }
                <Grid xs={12} lg={8} md={8} item>
                    <div onClick={() => { this.props.navigation.goBack() }} className="editProfileBack">
                        <img src={backarrow} width="22px" height="22px" alt="" />Edit profile
                    </div>
                    <Grid className="outSide" item xs={12} justifyContent="space-around" container>
                        <Grid xs={12} item className="cardHeader">PERSONAL INFORMATION</Grid>
                        <PersonalInformation handleAlert={this.handleAlert} changeForm={this.formChanged} countryData={this.state.countryList} checker={this.state.checker} update={this.setUserData} userData={this.state.userProfileData} subcategories={this.state.subCategories} setError={this.changeAnyError} />
                    </Grid>
                    <Grid className="outSide" item xs={12} justifyContent="space-around" container>
                        <Grid xs={12} item className="cardHeader">PUBLIC INFORMATION</Grid>
                        <Grid xs={12} item className="cardHeader moderators">ONBOARDING QUESTIONS</Grid>
                        <PublicInformation handleAlert={this.handleAlert} onboardinQuestions={this.state.onboardingQuestions} changeForm={this.formChanged} checker={this.state.checker2} update={this.setUserData} userData={this.state.userProfileData} subcategories={this.state.subCategories} setError={this.changeAnyError} />
                    </Grid>
                    {this.state.userProfileData?.is_moderator ?
                        <Grid className="outSide" item xs={12} container justifyContent="space-around">
                            <Grid xs={12} item className="cardHeader moderators">MODERATORS ONLY</Grid>
                            <ModeratorInformation handleAlert={this.handleAlert} changeForm={this.formChanged} checker={this.state.checker3} update={this.setUserData} userData={this.state.userProfileData} subcategories={this.state.subCategories} setError={this.changeAnyError} />
                        </Grid>
                        :
                        ''
                    }
                    <Grid item xs={12} justifyContent="space-around" container>
                        <Grid xs={12} className="PerosnalInformationFormInside" container justifyContent="space-around">
                            <Grid xs={12} md={8} lg={8} container>
                                <Grid item xs={12} md={6} lg={6} className={!this.state.submitEnable ? "saveChanges disabled" : "saveChanges"} onClick={() => { this.state.submitEnable && this.handleSubmit() }}>
                                    <p> Save Changes </p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

// @ts-ignore 
export default withRouter(UserEditProfile);
// Customizable Area End