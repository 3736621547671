export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const closeIcon = require("./room/assets/closeIcon.png");
export const blueTick = require('./room/assets/BlueTick.svg');
export const goldenTick = require('./room/assets/GoldenTick.svg');
export const userDefaultImg = require("./room/assets/defaultProfilePic.png");
export const walletIcon = require("./room/assets/wallet.png");
export { ReactComponent as AudioOn } from './room/assets/audio-on.svg';
export { ReactComponent as CameraOn } from './room/assets/camera-on.svg';
export { ReactComponent as AudioOff } from './room/assets/audio-off.svg';
export { ReactComponent as CameraOff } from './room/assets/camera-off.svg';
export { ReactComponent as LeaveIcon } from './room/assets/leave.svg';
export { ReactComponent as PeopleGroup } from './room/assets/people_group.svg';
export { ReactComponent as ClosedDoor } from './room/assets/closed_door.svg';