  // Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { DraggableEvent, DraggableData } from 'react-draggable';	
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  categoriesArray: any;
  topic: any;
  category: string;
  subCategory: string;
  subCategoryIcon: string;
  subCategoryThumb: string;
  roomName: string;
  isVisible: boolean;
  dropdownCategoryStatus: boolean;
  activeModalType: string;
  selectedCategoryID: any;
  selectedObj: Array<any>;
  selectedSub: Array<any>;
  selectedSubMenu: Array<any>;
  topicsArray: Array<any>;
  upcomingRooms: Array<any>;
  selectedTopic: Array<any>;
  isLoading: boolean;
  isLoadingInside: boolean;
  currentLocationParams: {
    id: string,
    sub_id: string,
    topic_id: string
  },
  selectedId: number | null;
  subcategoryChecker: {
    fetchedSubIds: Array<any>
    allTopicList: Array<any>
  };
  topicChecker: {
    fetchedTopicIds: Array<any>
    allRoomsList: Array<any>
  };
  selectedFilters: {
    sub_cat_id: number;
    topic_id: number;
    topic_name: string;
  }[];
  selectedFilter: 'room_type' | null;
  requestedTopicId: number;
  defaultPosition: { x: number; y: number };
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CategoriessubcategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCategoriesApiCallId: any;
  getSubCategoryTopicsApiCallId: any;
  deleteCategoriesApiCallId: any;
  deleteSubCategoriesApiCallId: any;
  addCategoryApiCallId: any;
  addSubCategoryApiCallId: any;
  getSubCategoryRoomsApiCallId: any;
  getSubCategoryNextRoomsApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      token: "",
      categoriesArray: [],
      topic: "",
      category: "",
      subCategory: "",
      subCategoryIcon: "",
      subCategoryThumb: "",
      roomName: "",
      isVisible: false,
      dropdownCategoryStatus: false,
      activeModalType: "",
      selectedCategoryID: [],
      selectedObj: [],
      selectedSub: [],
      currentLocationParams: {
        id: '',
        sub_id: '',
        topic_id: ''
      },
      selectedSubMenu: [],
      topicsArray: [],
      subcategoryChecker: {
        fetchedSubIds: [],
        allTopicList: [],
      },
      topicChecker: {
        fetchedTopicIds: [],
        allRoomsList: []
      },
      upcomingRooms: [],
      selectedTopic: [],
      isLoading: false,
      isLoadingInside: false,
      selectedId: null,
      selectedFilters: [],
      selectedFilter: null,
      requestedTopicId: 0,
      defaultPosition: { x: 0, y: 0 },
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token }, () => {
        if (this.state.categoriesArray.length == 0) {
          this.getCategories();
        }
      });
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (!responseJson) return "";
      if (responseJson.data) {
        if (apiRequestCallId === this.getCategoriesApiCallId) {
          let array = responseJson.data;
          for (let i = 0; i < array.length; i++) {
            array[i].expand = false;
            array[i].Check = false;
          }
          this.setState({ categoriesArray: array }, () => {
            let props: any = this.props
            const [, , , id, , sub_id, , topic_id]: any = props.location.pathname.split('/')
            this.setState({ currentLocationParams: { id, sub_id, topic_id } }, () => {
              if (id && sub_id && topic_id) {
                this.getSubCategoryTopics(sub_id)
              } else if (id && sub_id) {
                this.selectCategory(id, sub_id)
              } else {
                this.setState({ isLoading: false, selectedId: this.state.categoriesArray[0].id })
              }
            })
          });
        } else if (apiRequestCallId === this.addCategoryApiCallId) {
          this.setState({ isVisible: false, category: "" }, () => {
            this.getCategories();
          });
        } else if (apiRequestCallId === this.addSubCategoryApiCallId) {
          this.setState(
            {
              isVisible: false,
              category: "",
              subCategory: "",
              selectedCategoryID: []
            },
            () => {
              this.getCategories();
            }
          );
        } else if (apiRequestCallId === this.getSubCategoryTopicsApiCallId) {
          this.setState((prevState) => {
            return {
              topicsArray: responseJson.data,
              subcategoryChecker: {
                fetchedSubIds: prevState.subcategoryChecker.fetchedSubIds.concat(responseJson.data[0]?.attributes?.sub_category_id),
                allTopicList: prevState.subcategoryChecker.allTopicList.concat(responseJson.data)
              },
              isLoadingInside: false,
              isLoading: false
            }
          }, () => {
            if (this.state.topicChecker.fetchedTopicIds.length === 0) {
              this.selectTopic(+this.state.currentLocationParams.topic_id)
            }
          });
        } else if (apiRequestCallId === this.getSubCategoryRoomsApiCallId) {
          this.setState((prevState) => { return { upcomingRooms: prevState.upcomingRooms.concat(responseJson.data), topicChecker: { ...prevState.topicChecker, allRoomsList: prevState.topicChecker.allRoomsList.concat(responseJson.data), } } },
            () => {
              this.getSubcategoryNextAvailableRooms(this.state.requestedTopicId)
            }
          );
        } else if (apiRequestCallId === this.getSubCategoryNextRoomsApiCallId) {
          this.setState((prevState) => { return { upcomingRooms: prevState.upcomingRooms.concat(responseJson.data), topicChecker: { ...prevState.topicChecker, allRoomsList: prevState.topicChecker.allRoomsList.concat(responseJson.data), } } });
        }
      } else if (
        apiRequestCallId === this.deleteCategoriesApiCallId &&
        !responseJson.error
      ) {
        this.getCategories();
      } else if (
        apiRequestCallId === this.deleteCategoriesApiCallId &&
        responseJson.error
      ) {
        this.parseApiCatchErrorResponse(responseJson.error.message);
      } else if (
        apiRequestCallId === this.deleteSubCategoriesApiCallId &&
        !responseJson.error
      ) {
        this.getCategories();
      } else if (
        apiRequestCallId === this.deleteSubCategoriesApiCallId &&
        responseJson.error
      ) {
        this.parseApiCatchErrorResponse(responseJson.error.message);
      } else if (apiRequestCallId === this.getSubCategoryRoomsApiCallId && responseJson.errors) {
        this.getSubcategoryNextAvailableRooms(+this.state.currentLocationParams.topic_id)
      } else if (apiRequestCallId === this.getSubCategoryNextRoomsApiCallId && responseJson.errors) {
        this.setState({ isLoadingInside: false, isLoading: false })
      } else if (responseJson.errors) {
        // this.parseApiErrorResponse(responseJson);
        // this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  //FOR WEB------


  async componentDidUpdate(prevProps: any) {
    const props: any = this.props;
    if (location.pathname !== prevProps.location.pathname) {
      const [, , , id, , sub_id, , topic_id]: any = props.location.pathname.split('/')
      this.setState({ currentLocationParams: { id, sub_id, topic_id } }, () => {
        if (id && sub_id && topic_id) {
          this.selectTopic(topic_id)
        } else if (id && sub_id) {
          this.selectCategory(id, sub_id)
        }
      })
    }
  }

  async componentWillUnmount(): Promise<void> {
    this.context.changeCreateRoomModal(false, null)
  }


  goToSubcategory = (category: any, subcategory: any) => {
    let props: any = this.props
    if (props.location.pathname.split('/').filter(Boolean)[4] === subcategory.id) return ''
    this.setState({ subCategoryIcon: '' , subCategoryThumb: ''})
    if (this.state.currentLocationParams.id) {
      props.history.push(`/CategoriesWeb/category/${this.state.currentLocationParams.id}/subcategory/${subcategory.id}`)
    } else {
      props.history.push(`/CategoriesWeb/category/${category.id}/subcategory/${subcategory.id}`)
    }
  };

  backToCategories = () => {
    let props: any = this.props
    if (this.state.currentLocationParams.topic_id) {
      props.history.push(`/CategoriesWeb/category/${this.state.currentLocationParams.id}/subcategory/${this.state.currentLocationParams.sub_id}`)
    } else {
      props.history.push('/CategoriesWeb')
    }
  };

  goToTopics = (isRendered: boolean, topic: any) => {
    this.setState({ isLoadingInside: true })
    const token = localStorage.getItem("token");
    if (!token) {
      this.props.navigation.navigate("HomePage", { step: 0 });
      return;
    }
    let props: any = this.props
    if (this.state.currentLocationParams.id && this.state.currentLocationParams.sub_id) {
      props.history.push(`/CategoriesWeb/category/${this.state.currentLocationParams.id}/subcategory/${this.state.currentLocationParams.sub_id}/rooms/${topic.id}`)
    }
  }

  selectCategory = (categoryId: any, subcategoryId: any) => {
    let category = this.state.categoriesArray.find((item: any) => item.id == categoryId)
    if (category) {
      let sub = category.attributes.sub_categories.data.filter((item: any) => item.id == subcategoryId)
      if (sub && sub[0] && sub[0].attributes) {
        this.setState(
          {
            selectedSub: sub,
            selectedSubMenu: category.attributes.sub_categories.data,
            subCategoryIcon: sub[0].attributes.landscape,
            subCategoryThumb: sub[0].attributes.thumb,
            category: category.attributes.name,
            subCategory: sub[0].attributes.name,
          },
          () => {
            if (this.state.subcategoryChecker.fetchedSubIds.find((item: number) => item === +subcategoryId)) {
              const filteredData: any[] = this.state.subcategoryChecker.allTopicList.filter((item: any) => item.attributes.sub_category_id === +subcategoryId)
              this.setState({ topicsArray: filteredData })
            } else {
              this.getSubCategoryTopics(subcategoryId)
            }
          }
        )
      }
    }
  }

  selectTopic = (topicId: number) => {
    if (this.state.topicsArray?.length > 0) {
      let topic = this.state.topicsArray.filter((item) => item.id == topicId)
      let category = this.state.categoriesArray.find((item: any) => item.id == this.state.currentLocationParams.id)
      let sub = category.attributes.sub_categories.data.filter((item: any) => item.id == this.state.currentLocationParams.sub_id)
      if (topic && this.state.selectedTopic[0]?.id !== topicId) {
        this.setState({
          selectedTopic: topic,
          category: category.attributes?.name,
          subCategory: sub[0].attributes?.name,
          subCategoryIcon: sub[0].attributes.landscape,
          subCategoryThumb: sub[0].attributes.thumb,
          topic: topic[0]?.attributes?.name
        }, () => {
          let currentTopicID = this.state.currentLocationParams.topic_id
          if (this.state.topicChecker.fetchedTopicIds.find((item => item == currentTopicID))) {
            let data = this.state.topicChecker.allRoomsList.filter((item: any) => item.attributes.topic_id === +currentTopicID)
            this.setState({ upcomingRooms: data ?? [], isLoadingInside: false, isLoading: false })
          } else {
            this.setState(prevState => {
              return {
                topicChecker: {
                  ...prevState.topicChecker,
                  fetchedTopicIds:
                    prevState.topicChecker.fetchedTopicIds.concat(this.state.currentLocationParams.topic_id)
                },
                upcomingRooms: []
              };
            });
            this.getSubcategoryUpcomingRooms(+this.state.currentLocationParams.topic_id)
          }
        })
      }
    }
  };

  getSubCategoryTopics = (id: number) => {
    // console.log('CATEGORY', this.state.token)
    // if (!this.state.token) {
    //   return;
    // }
    const token = null
    // const token = localStorage.getItem("token")
    this.setState({ isLoadingInside: true })
    let header: {
      "Content-Type": any;
      token?: string | null;
    } = { "Content-Type": configJSON.categoryApiContentType, token }
    if (!token)
      delete header.token

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubCategoryTopicsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subCategoryTopicsAPIEndPoint + String(id)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSubcategoryUpcomingRooms = (id: number) => {
    this.setState({ isLoadingInside: true, requestedTopicId: id })
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubCategoryRoomsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subCategoryUpcomingRoomsAPIEndPoint + String(id)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
  };

  getSubcategoryNextAvailableRooms = (id: number) => {
    this.setState({ isLoadingInside: true })
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubCategoryNextRoomsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subCategoryNextAvailableRoomsAPIEndPoint + String(id)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
  };

  //--------------
  setCategoryTxt = (text: string) => {
    this.setState({ category: text });
  };
  setSubCategoryTxt = (text: string) => {
    this.setState({ subCategory: text });
  };
  clickCategory = (item: any, Index: number) => {
    let array = this.state.categoriesArray;
    let idarray = this.state.selectedCategoryID;
    let index = idarray.indexOf(item.attributes.id);

    if (index > -1) {
      idarray.splice(index, 1);
      array[Index].Check = false;
      this.setState({ categoriesArray: array });
    } else {
      idarray.push(item.attributes.id);
      array[Index].Check = true;
      this.setState({ categoriesArray: array });
      this.setState({ selectedCategoryID: idarray });
    }
  };

  toggleModal = (type: string) => {
    this.setState({ activeModalType: type, isVisible: !this.state.isVisible });
  };

  expandCategoryView = () => {
    this.setState({
      dropdownCategoryStatus: !this.state.dropdownCategoryStatus
    });
  };
  expand = (id: string) => {
    let array = this.state.categoriesArray;
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        array[i].expand = !array[i].expand;
      }
    }
    this.setState({ categoriesArray: array });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  addCategory = () => {
    if (this.isStringNullOrBlank(this.state.category)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else {
      let data = {
        categories: [{ name: this.state.category }]
      };
      const header = {
        "Content-Type": configJSON.categoryApiContentType,
        token: this.state.token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addCategoryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.categoryAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };
  addSubCategory = () => {
    if (this.isStringNullOrBlank(this.state.subCategory)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (this.state.selectedCategoryID.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorCategory);
      return false;
    } else {
      let data = {
        sub_category: {
          name: this.state.subCategory
        },
        parent_categories: this.state.selectedCategoryID
      };
      const header = {
        "Content-Type": configJSON.categoryApiContentType,
        token: this.state.token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addSubCategoryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.subCategoryAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteCategories = (id: number) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteSubCategories = (id: number) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteSubCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subCategoryAPIEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCategories = () => {
    // if (!this.state.token) {
    //   return;
    // }
    // const token = localStorage.getItem("token")
    const token = null
    let header: {
      "Content-Type": any;
      token?: string | null;
    } = { "Content-Type": configJSON.categoryApiContentType, token }
    this.setState({ isLoading: true })
    if (!token)
      delete header.token
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleDrag = (e: DraggableEvent, data: DraggableData) => {
    if (data.y >= 50) {
      // Change this value to control the minimum distance to close the slider.
      // this.handleChangeMobileFilter(null);
    } else {
      this.setState({ defaultPosition: { x: 0, y: 0 } });
    }
  };

  handleChangeMobileFilter = (filter: 'room_type' | null) => {
    sessionStorage.setItem('choosenFilter', filter || '');
    this.setState({ selectedFilter: filter });
  };

  // Customizable Area End
}
