// Customizable Area Start

import EmailAccountRegistrationController from '../EmailAccountRegistrationController';
import { logo } from '../assets';

import React from 'react';
import { Button, Grid, Typography, Container } from '@material-ui/core';
import { Formik } from 'formik';
import 'react-phone-input-2/lib/style.css';
import './styles.css';
import * as Yup from 'yup';
import { MobileInput } from './Dropdown.web';
import AlertBlock from '../../../alert/src/Alert.web';
import { withRouter, RouteComponentProps } from 'react-router-dom';
// firebase imports
import * as firebase from 'firebase';
import 'firebase/auth';

interface Props extends RouteComponentProps<any> {
  setStep: (step: number) => void;
  handleOnboardingDialogClose: () => void;
}

class EmailRegister extends EmailAccountRegistrationController<Props> {
  mobileRegex = /^\d{4,13}$/;

  constructor(props: any) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  async componentDidMount() {
    this.getCountryCode();

    // add recaptcha
    const verifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible'
    });

    this.setState({ recaptchaVerifier: verifier });
  }

  SignupSchema = Yup.object().shape({
    mobile: Yup.string()
      .matches(/^\d+$/, 'Must be only digits')
      .min(4, 'Please enter a valid phone number')
      .max(12, 'Phone Number maximum length allowed 12 digit')
      .required('Mobile Number required '),
    termsAndConditions: Yup.boolean().oneOf(
      [true],
      'You must agree to our Terms of Use and Privacy Policy.'
    ),
  });

  getFlagAndCode(phone: any) {
    return phone.flag + ' ' + phone.dial_code;
  }

  handleClose() {
    this.setState({ isMessagePop: false });
  }

  render() {
    return (
      <>
        <Grid container style={{ height: '100%' }}>
          <Grid
            item
            xs={12}
            md={4}
            style={{
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: '#ffcb00',
            }}
          >
            <img
              src={logo}
              alt="image"
              style={{ width: 'clamp(150px, 75vw, 248px)' }}
            />
          </Grid>
          <Grid item xs={12} md={8} style={{ alignSelf: 'center' }}>
            <Container maxWidth="sm">
              <Formik
                initialValues={{
                  mobile: sessionStorage.getItem('number') || '',
                  termsAndConditions: false,
                  countryCode: {
                    name: 'India',
                    flag: '🇮🇳',
                    code: 'IN',
                    dial_code: '+91',
                  },
                }}
                validationSchema={this.SignupSchema}
                onSubmit={(values, { setSubmitting }) => {
                  this.setState({ mobileNumber: `${values.countryCode.dial_code}${values.mobile}` }, () => {
                    this.verifyCapcha();
                  });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <AlertBlock
                      data={{
                        show: this.state.isMessagePop,
                        close: this.handleClose,
                        msg: this.msg,
                        type: this.type,
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    />

                    <Typography
                      style={{
                        fontWeight: 500,
                        fontSize: '2.25rem',
                      }}
                    >
                      Sign In / Sign Up
                    </Typography>
                    <div>
                      <label htmlFor="mobile" className="label">
                        Mobile Number
                        <MobileInput
                          inputTabIndex={1}
                          style={{ marginTop: '10px' }}
                          trial={this.state.countryList}
                          list={this.state.countryList}
                          value={this.getFlagAndCode(values.countryCode)}
                          className="block"
                          defaultText="Enter mobile number"
                          phone={values.mobile}
                          onChange={(e) => {
                            setFieldValue('countryCode', e.target.value);
                            setFieldValue('mobile', e.target.phoneNumber);
                            sessionStorage.setItem(
                              'number',
                              e.target.phoneNumber
                            );
                          }}
                          onBlur={handleBlur}
                          name="mobile"
                          defaultValue={values.countryCode}
                        />
                        <span className="error">
                          {errors.mobile && touched.mobile && errors.mobile}
                        </span>
                      </label>{' '}
                      <br></br>
                    </div>

                    <Grid container spacing={2}>
                      <Grid item>
                        <input
                          tabIndex={2}
                          type="checkbox"
                          className="termandconditioncheckbox"
                          style={{ marginTop: '2px' }}
                          name="termsAndConditions"
                          checked={values.termsAndConditions}
                          onBlur={handleBlur}
                          onKeyDown={(e) => {
                            if ((e.which || e.keyCode) == 13) {
                              console.log('enter is clicked');
                              setFieldValue(
                                'termsAndConditions',
                                !values.termsAndConditions
                              );
                              e.preventDefault();
                            }
                          }}
                          onClick={(e) => {
                            setFieldValue(
                              'termsAndConditions',
                              !values.termsAndConditions
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <span>
                          Please read and agree to our &nbsp;
                          <a
                            className="a1"
                            tabIndex={3}
                            onKeyDown={(e) => {
                              if ((e.which || e.keyCode) == 13) {
                                sessionStorage.setItem(
                                  't&cshow',
                                  'Terms and conditions'
                                );
                                this.props.navigation.navigate(
                                  'TermsAndConditions'
                                );
                                e.preventDefault();
                              }
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              sessionStorage.setItem(
                                't&cshow',
                                'Terms and conditions'
                              );
                              this.props.navigation.navigate(
                                'TermsAndConditions'
                              );
                            }}
                          >
                            Terms Of Use
                          </a>
                          &nbsp;and&nbsp;
                          <a
                            className="a2"
                            tabIndex={4}
                            onKeyDown={(e) => {
                              if ((e.which || e.keyCode) == 13) {
                                sessionStorage.setItem(
                                  't&cshow',
                                  'Privacy Policy'
                                );
                                this.props.navigation.navigate(
                                  'PrivacyPolicyData'
                                );
                                e.preventDefault();
                              }
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              sessionStorage.setItem(
                                't&cshow',
                                'Privacy Policy'
                              );
                              this.props.navigation.navigate(
                                'PrivacyPolicyData'
                              );
                            }}
                          >
                            Privacy Policy
                          </a>{' '}
                        </span>
                      </Grid>
                    </Grid>
                    {/* <label htmlFor="termsAndConditions" className="terms" /> */}
                    <span className="error">
                      {errors.termsAndConditions &&
                        touched.termsAndConditions &&
                        errors.termsAndConditions}
                    </span>

                    <div
                      className="submit-button-div"
                      style={{ marginTop: '20px' }}
                    >
                      <Button
                        type="submit"
                        tabIndex={5}
                        variant="contained"
                        className="btn"
                        id="sign-in-button"
                      >
                        Get OTP
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>

              <div id="recaptcha-container" />
            </Container>
          </Grid>
        </Grid>
      </>
    );
  }
}

// @ts-ignore
export default withRouter(EmailRegister);
// Customizable Area End
