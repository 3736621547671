import React, { useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grow from "@material-ui/core/Grow";
import BottomSheet from "../Modals/BottomSheet.web";

// create style for box
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: "absolute",
      zIndex: 9999,
      width: "36vw",
      left: "3rem",
      top: "7.5rem",
    },

    container: {
      background: "#fff",
      borderRadius: "1rem",
      gap: "1rem",
      padding: "1rem",
    },

    backDrop: {
      cursor: "pointer",
    },
  })
);

interface Props {
  isMobile: boolean;
  isOpen: boolean;
  toggleModal: () => void;
  roomTopic: string;
  description: string;
  roomImgLink: string;
}

const MainContent = ({
  roomTopic,
  description,
}: {
  roomTopic: string;
  description: string;
}) => {
  return (
    <Box flex={3}>
      <Typography
        gutterBottom
        style={{
          color: "#010039",
          fontWeight: 500,
          fontSize: "1.2rem",
        }}
      >
        {roomTopic}
      </Typography>
      <Typography style={{ color: "#979797", fontSize: ".9rem" }}>
        {description || "No room description found."}
      </Typography>
    </Box>
  );
};

export default function RoomDescriptionModal(props: Props) {
  const [loading, setLoading] = useState(true);
  const {
    isMobile,
    isOpen,
    toggleModal: handleClose,
    description,
    roomTopic,
    roomImgLink,
  } = props;
  const classes = useStyles();

  const handleImageLoad = () => {
    setLoading(false);
  };

  if (!isOpen) return null;

  if (isMobile) {
    return (
      <BottomSheet addBackDrop handleClose={handleClose}>
        <Box padding=".5rem 1rem 1.5rem">
          <MainContent roomTopic={roomTopic} description={description} />
        </Box>
      </BottomSheet>
    );
  }

  return (
    <Box className={classes.root}>
      {/* backdrop */}
      <Box
        position="fixed"
        top={0}
        left={0}
        width="100%"
        height="100%"
        bgcolor="rgba(0,0,0,.3)"
        zIndex={-1}
        onClick={handleClose}
        className={classes.backDrop}
      />

      <Grow in={isOpen}>
        <Box display="flex" alignItems="center" className={classes.container}>
          <Box flex={1} textAlign="center">
            {/* show a loader while img is loading */}
            {loading && <CircularProgress style={{ color: "#ffcb00" }} />}
            <img
              src={roomImgLink}
              alt="room-img"
              onLoad={handleImageLoad}
              style={{
                display: loading ? "none" : "block",
                height: "10rem",
                width: "10rem",
                objectFit: "cover",
                borderRadius: "1rem",
              }}
            />
          </Box>

          <MainContent roomTopic={roomTopic} description={description} />
        </Box>
      </Grow>
    </Box>
  );
}
