// Customizable Area Start
import { Grid, Box,Hidden } from '@material-ui/core'
import React from 'react'
import WalletWebController, { Props } from './WalletWebController'
import WalletBalance from './components/walletBalanceWeb';
import Skeleton from '@material-ui/lab/Skeleton';
import TransactionHistory from './components/transactionHistory';
import { withRouter } from 'react-router-dom';
import RefillWallet from '../refillwallet/RefillWallet.web';
//@ts-ignore
import StateContext from '../../../../web/src/globalState'
import './MyWallet.scss'
import HistoryIcon from '@material-ui/icons/History';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { backarrow, redeemComplate, refundedCoin} from "../assets";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import AccountDetailForm from './components/accounDetailsForm'
import ModeratorWallet from './components/ModeratorWallet';
import AccountStatement from './components/AccountStatement';
import { SwitchInputForClass } from '../../../DifferentRooms/src/RoomDetails/RoomDetailsComponents/CustomFormHooks';
import MobileWrapper from './components/MobileResponsive/MobileResponsiveWrapper';
import MobileModeratorAccordion from './components/MobileResponsive/MobileModeratorAccordion'

class MyWallet extends WalletWebController {
   static contextType: any = StateContext
   containerRef = React.createRef<HTMLDivElement>();
   constructor(props: any) {
      super(props);
      this.myRef = React.createRef()
   }
   openModal = () => {
      this.myRef.current.handleOpen()
   }
   setSuccess = (booelaan: boolean) => {
      this.setState({ expanded: booelaan })
   }

   scrollToTop = () => {
      const node = this.containerRef.current;
      if (node) {
        node.scrollTop = 0;
      }
    };

   setUserType = (value: string) => {
      this.setState({isUserTab: value})
      this.scrollToTop()
   }

   handleScroll = (e: any) => {
      if (this.state.isMorePage) {
         let heightDiff = e.currentTarget.scrollHeight - e.currentTarget.offsetHeight
         if (Math.ceil(heightDiff - e.currentTarget.scrollTop) < 5 && this.state.isMorePage) {
            this.getWalletBalance()
         }
      }
   };

   handleAccountStatementScroll = (e: any) => {
      if (this.state.isMorePageAccount) {
         let heightDiff = e.currentTarget.scrollHeight - e.currentTarget.offsetHeight
         if (Math.ceil(heightDiff - e.currentTarget.scrollTop) < 5 && this.state.isMorePageAccount) {
            this.getAccountStatement()
         }
      }
   };

   render() {
      return (
         <>
            {/* @ts-ignore  */}
            <RefillWallet ref={this.myRef} addValue={this.walletSuccess} />
            <TransitionGroup style={{position: 'relative'}}>
               {this.state.isDetailsOpen &&
                     <CSSTransition
                     key="edit-detail"
                     timeout={500}
                     classNames="fade-right"
                  >
                     <AccountDetailForm bankDetails={this.state.bankDetails} closeDetail={this.changeIsDetailsOpen} cb={this.changeIsDetailsOpen}/>
                  </CSSTransition>
               }
            </TransitionGroup>
            <Hidden mdDown>
               {this.state.expanded &&
                  <Box className={this.state.expanded ? 'coinRefillBox active' : 'coinRefillBox'}>
                     <Grid className="coinRefillBoxSuccess" container onClick={(e) => { e.stopPropagation() }}>
                        <Grid container className="coinRefillBoxImg">
                           <img src={redeemComplate} />
                        </Grid>
                        <div className="coinRefillBoxSuccessText">
                           <span>Hurray! We have processed your redemption request, we shall be transferring the claim amount to the bank account shared by you shortly.</span>
                           <span>Thank you for being a valued member of the community.</span>
                           <span className="coinRefillBoxSuccessTextBold">See you soon in the YLanes!</span>
                        </div>
                        {/* <div className="coinRefillBoxSuccessReferFriend">Refer a friend</div> */}
                        <div className="coinRefillBoxSuccessContinue" onClick={(e) => { this.setSuccess(false) }}>Continue</div>
                     </Grid>
                  </Box>
               }
               <Grid className="myWallet" item container xs={12}>
                  <Grid className="myWalletInside" item lg={4} md={4} xs={12}>
                     <div className="p40 walletTopWrapper">
                        <div onClick={() => { this.props.navigation.goBack() }} className="walletBackButton">
                           <img src={backarrow} width="16px" height="16px" alt="" />Wallet
                        </div>
                        <SwitchInputForClass text1='User Wallet' text2='Moderator Wallet' setFunction={this.setUserType} state={this.state.isUserTab} disabled={!this.state.userProfile.is_moderator}/>
                     </div>
                     <div className="p40Flex">
                     <TransitionGroup style={{position: 'relative', height: '190px'}}>
                        {this.state.isUserTab !== 'Moderator Wallet' &&
                              <CSSTransition
                                 key="wallet-balance"
                                 timeout={1000}
                                 classNames="fade"
                              >
                                 <WalletBalance isLoading={this.state.isLoading} openModal={this.openModal} userProfile={this.state.userProfile} />
                              </CSSTransition>
                        }
                        {this.state.isUserTab === 'Moderator Wallet' &&
                              <CSSTransition
                                 key="moderator-wallet"
                                 timeout={1000}
                                 classNames="fade"
                              >
                                 <ModeratorWallet 
                                    redeemCoins={this.getRedeemCoins} 
                                    userProfile={this.state.userProfile}
                                    bankDetails={this.state.bankDetails} 
                                    touchPoints={this.state.touchPoints} 
                                    moderated_details={this.state.moderated_details} 
                                 />
                              </CSSTransition>
                        }
                     </TransitionGroup>
                     </div>
                     <div className="feedbackOutside">
                        <div className="feedbackDiv">For any questions or concerns, please provide us <span onClick={() => {
                              const props:any = this.props
                              props.history.push('/Setting', { page: 'feedback' })
                           }} 
                           className="feedbackHighlight">Feedback</span>
                        </div>
                     </div>
                  </Grid>
                  <Grid ref={this.containerRef} className="myWalletInsideRight" onScroll={(e) => { this.state.isUserTab === 'Moderator Wallet' ? this.handleAccountStatementScroll(e) : this.handleScroll(e) }} 
                  item md={8} lg={8} xs={12}>
                     <TransitionGroup style={{position: 'relative'}}>
                        {this.state.isUserTab !== 'Moderator Wallet' &&
                              <CSSTransition
                                 key="wallet-balance"
                                 timeout={1000}
                                 classNames="fade-right"
                              >
                                 <TransactionHistory 
                                    handleScroll={this.handleScroll} 
                                    loading={this.state.transactionLoading} 
                                    getHistory={this.getWalletBalance} 
                                    isLoading={this.state.isLoading} 
                                    userHistory={this.state.userHistory} 
                                 />
                              </CSSTransition>
                        }
                        {this.state.isUserTab === 'Moderator Wallet' &&
                              <CSSTransition
                                 key="moderator-wallet"
                                 timeout={1000}
                                 classNames="fade-right"
                              >
                                 <AccountStatement 
                                    handleAccountStatementScroll={this.handleAccountStatementScroll} 
                                    accountLoading={this.state.accountLoading} 
                                    userData={this.context} 
                                    accountStatementData={this.state.accountStatementData} 
                                    bankDetails={this.state.bankDetails} 
                                    handleChangeDetail={this.changeIsDetailsOpen}
                                 />
                              </CSSTransition>
                        }
                     </TransitionGroup>
                  </Grid>
               </Grid>
            </Hidden>

{/*-----------------------------------------------MOBILE VERSION--------------------------------------------------------------- */}
            
            <Hidden mdUp>
               <div className="myWallet mobileMyWallet">
                  <TransitionGroup style={{position: 'relative'}}>
                     {this.state.pageOrder === 0 && 
                        <CSSTransition
                           key="wallet-balance"
                           timeout={500}
                           classNames="fade"
                        >
                           <MobileWrapper label="Wallet" navigation={this.props.navigation.goBack} >
                              <WalletBalance isLoading={this.state.isLoading} openModal={this.openModal} userProfile={this.state.userProfile} />
                                 <div className="walletLinks" onClick={() => this.setState({pageOrder: 1})}> 
                                    <HistoryIcon />
                                    <div className="text">
                                       Transaction History
                                    </div>
                                    <KeyboardArrowRightIcon />
                                 </div>
                                 {this.state.userProfile.is_moderator && 
                                    <div className="walletLinks" onClick={() => this.setState({pageOrder: 2})}>
                                       <img src={refundedCoin} /> 
                                       <div className="text">
                                          Moderator Wallet
                                          <span>Redeem Earned YCoins</span>
                                       </div>
                                       <KeyboardArrowRightIcon />
                                    </div>                  
                                 }
                           </MobileWrapper>
                        </CSSTransition>
                     }
                     {this.state.pageOrder === 1 &&
                           <CSSTransition
                              key="transaction"
                              timeout={500}
                              classNames="fade-right"
                           >
                              <MobileWrapper handleScroll={this.handleScroll} label="Transaction History" changePage={() => {this.setState({pageOrder: 0})}} >
                                 <TransactionHistory loading={this.state.transactionLoading} getHistory={this.getWalletBalance} isLoading={this.state.isLoading} userHistory={this.state.userHistory} />
                              </MobileWrapper>
                           </CSSTransition>
                     }
                     {this.state.pageOrder === 2 &&
                        <CSSTransition
                           key="moderator-wallet"
                           timeout={500}
                           classNames="fade-right"
                        >
                           <MobileWrapper label="Moderator Wallet" changePage={() => {this.setState({pageOrder: 0})}}  >
                              <ModeratorWallet 
                                 redeemCoins={this.getRedeemCoins} 
                                 userProfile={this.state.userProfile}
                                 bankDetails={this.state.bankDetails} 
                                 touchPoints={this.state.touchPoints} 
                                 moderated_details={this.state.moderated_details} 
                              />
                              <MobileModeratorAccordion 
                                handleAccountStatementScroll={this.handleAccountStatementScroll} 
                                accountLoading={this.state.accountLoading} 
                                userData={this.context} 
                                accountStatementData={this.state.accountStatementData} 
                                bankDetails={this.state.bankDetails} 
                                handleChangeDetail={this.changeIsDetailsOpen}
                              /> 
                           </MobileWrapper>
                        </CSSTransition>
                     }
                  </TransitionGroup>
               </div>
            </Hidden>
         </>
      )
   }
}

//@ts-ignore
export default withRouter(MyWallet);

// Customizable Area End