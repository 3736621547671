import React from 'react';
import { Container, Typography, Box } from '@material-ui/core';
import { FaqPage as DefaultFaqPage } from '../../../Settings5/src/settings/settingContent.web';
import HeaderNoLogin from '../../../../components/src/HeaderNoLogin';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import { IBlock } from '../../../../framework/src/IBlock';
import { Message } from '../../../../framework/src/Message';
import { BlockComponent } from '../../../../framework/src/BlockComponent';
import MessageEnum, {
  getName,
} from '../../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../../framework/src/RunEngine';

export interface Props {
  navigation: any;
  id: string;
}

interface State {
  faqData: [];
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#FFCB00',
    },
    secondary: {
      main: '#010039',
    },
  },
});

export default class FaqPage extends BlockComponent<Props, State, {}> {
  getFAQsId: string;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      faqData: [],
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.getFAQsId === apiRequestCallId) {
        if (!responseJson?.data) {
          // this.handleAlert("Some error occured please try again later.");
          return;
        }
        this.setState({ faqData: responseJson.data });
      }
    }
  }

  getFAQs = () => {
    const headers = {
      'Content-Type': 'application/json',
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFAQsId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_admin/policies/faq_list?type=Web'
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  render() {
    const { id, navigation } = this.props;
    const { faqData } = this.state;

    return (
      <>
        <ThemeProvider theme={theme}>
          <HeaderNoLogin id={id} navigation={navigation} />
        </ThemeProvider>
        <Container>
          <Box mb={8} mt={4} textAlign="center">
            <Typography variant="h2" style={{ fontWeight: 500 }}>
              FAQs
            </Typography>
          </Box>
          <DefaultFaqPage faqData={faqData} getFAQs={this.getFAQs} />
        </Container>
      </>
    );
  }
}
