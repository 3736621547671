// Customizable Area Start
import React, { useState, useEffect } from 'react'
import { Grid, CircularProgress, Button, TextField } from '@material-ui/core';


interface PropTypes {
   userData: {}
   subcategories: any[]
   checker: boolean
   onboardinQuestions: any[]
   update: any
   setError: (e: boolean, index: number) => void
   handleAlert: (msg: string, success: boolean) => void
   changeForm: () => void
}

const PublicInformation = ({ userData, subcategories, checker, update, changeForm, setError, handleAlert, onboardinQuestions }: PropTypes) => {

   const [text, setText] = useState("")
   const [isChanged, setChanged] = useState<boolean>(false)
   const [inputButton, setinputButton] = useState(false)
   const [errorList, setErrorList] = useState([])
   const [hasErrorIn, setHasErrorIn] = useState<boolean>(true)
   const [isLoad, setIsLoad] = useState(false)
   const [publicInformation, setPublicInformation] = useState({
      word1: '1',
      word2: '',
      word3: '',
      checked: '',
      textArea2: '',
      textArea3: '',
      textArea4: ''
   })

   const errorTypes = {
      selectCategory: 'Please Select at least ',
      text: 'Please enter valid ',
      pleaseSelect: 'Please select ',
      cannot: 'Text field can not be empty '
   }

   const handleChange = (e: any) => {
      if (!isChanged) {
         setChanged(true)
         changeForm()
      }
      if (e.target.value && e.target.value.length) {
         setErrorList(errorList.filter(item => item !== e.target.name))
         setPublicInformation({ ...publicInformation, [e.target.name]: e.target.value })
      } else {
         setPublicInformation({ ...publicInformation, [e.target.name]: e.target.value })

         //@ts-ignore
         setErrorList([...errorList, e.target.name])
      }
   }

   const detector = {
      word1: '1',
      word2: '',
      word3: '',
      checked: '',
      textArea2: '',
      textArea3: '',
      textArea4: ''
   }

   // Update lastest version of data to state
   useEffect(() => {
      if (checker && errorList.length === 0) {

         const e = publicInformation

         const onboarding_data = [{
            question_id: onboardinQuestions?.find(item => item.level === 1)?.id,
            data: [e.word3, e.word2, e.word1],
            level: 1
         },
         {
            question_id: onboardinQuestions?.find(item => item.level === 2)?.id,
            data: [e.checked],
            level: 2
         },
         {
            question_id: onboardinQuestions?.find(item => item.level === 3)?.id,
            data: [e.textArea2],
            level: 3
         },
         {
            question_id: onboardinQuestions?.find(item => item.level === 4)?.id,
            data: [e.textArea3],
            level: 4
         },
         {
            question_id: onboardinQuestions?.find(item => item.level === 5)?.id,
            data: [e.textArea4],
            level: 5
         }
         ]
         update(onboarding_data)
      }
   }, [checker])

   
   // checks is there Error
   useEffect(() => {
      if (errorList.length > 0) {
         if (!hasErrorIn) {
            setError(true, 1)
            setHasErrorIn(true)
         }
      } else {
         if (hasErrorIn) {
            setError(false, 1)
            setHasErrorIn(false)
         }
      }
   }, [errorList])

   //sets data to state
   useEffect(() => {
      if (Object.keys(userData).length > 0) {
         let uData: any = userData
         let personData = {
            word1: '',
            word2: '',
            word3: '',
            checked: '',
            textArea2: '',
            textArea3: '',
            textArea4: ''
         }
         if (uData.onboarding_details && uData.onboarding_details.length > 0) {
            const q1 = uData.onboarding_details.find((item: any) => item.level === 1)
            const q2 = uData.onboarding_details.find((item: any) => item.level === 2)
            const q3 = uData.onboarding_details.find((item: any) => item.level === 3)
            const q4 = uData.onboarding_details.find((item: any) => item.level === 4)
            const q5 = uData.onboarding_details.find((item: any) => item.level === 5)
            const checkList = ['Mountains', 'Relax at home', 'Read Books', 'Beaches', 'Party with friends', 'Adventure', 'Bringe Television']
            checkList && checkList.length && checkList.includes(q2?.answers[0]) ? setText('') : setText(q2?.answers[0])
            personData = {
               word1: q1?.answers[0] ?? '',
               word2: q1?.answers[1] ?? '',
               word3: q1?.answers[2] ?? '',
               checked: q2?.answers[0] ?? '',
               textArea2: q3?.answers[0] ?? '',
               textArea3: q4?.answers[0] ?? '',
               textArea4: q5?.answers[0] ?? ''
            }
         }
         setPublicInformation(personData)
      }
   }, [userData])

   // If the data is setted to state, it will render the component
   useEffect(() => {
      if (!isLoad && JSON.stringify(publicInformation) !== JSON.stringify(detector)) {
         setIsLoad(true)
      }
   }, [publicInformation])

   return (
      <Grid xs={12} className="PerosnalInformationFormInside" container justifyContent="space-around">
         {isLoad ?
            <Grid xs={12} md={8} lg={8} container>
               <form className="fullForm">
                  <div className="pInfoLabel">
                     {onboardinQuestions?.find(item => item.level === 1)?.name}
                  </div>
                  <div className="wordsFlex">
                     <div className="pInfoText">
                        <TextField
                           className="pInfotextField"
                           placeholder="Word 1"
                           variant="outlined"
                           fullWidth
                           name="word1"
                           value={publicInformation.word1}
                           onChange={(e) => {
                              e.target.value = e.target.value?.trimStart()
                              handleChange(e)
                           }
                           }
                           inputProps={{ maxLength: 30 }}
                        />
                     </div>

                     <TextField
                        className="pInfotextField"
                        placeholder="Word 2"
                        variant="outlined"
                        fullWidth
                        name="word2"
                        value={publicInformation.word2}
                        inputProps={{ maxLength: 30 }}
                        onChange={(e) => {
                           e.target.value = e.target.value?.trimStart()
                           handleChange(e)
                        }
                        }
                     />

                     <TextField
                        className="pInfotextField"
                        placeholder="Word 3"
                        variant="outlined"
                        fullWidth
                        name="word3"
                        value={publicInformation.word3}
                        inputProps={{ maxLength: 30 }}
                        onChange={(e) => {
                           e.target.value = e.target.value?.trimStart()
                           handleChange(e)
                        }
                        }
                     />
                     {errorList.find(item => item === 'word3' || item === 'word2' || item === 'word1') && <span style={{ display: "block", color: "red", fontSize: "12px", textAlign: "start", height: "25px" }}>{errorTypes.cannot}</span>}
                  </div>
                  <div className="pInfoLabel">
                     {onboardinQuestions?.find(item => item.level === 2)?.name}
                  </div>
                  {/* First three holiday suggestion button */}
                  <Grid xs={12} className="pInfoCheckButtons">
                     <Button
                        className={`checkInfoButton ${"Mountains" == publicInformation.checked ? "active" : null}`}
                        variant='contained'
                        onClick={(e) => {
                           setPublicInformation({ ...publicInformation, checked: "Mountains" })
                        }
                        }
                     >Mountains
                     </Button>

                     <Button
                        className={`checkInfoButton ${"Relax at home" == publicInformation.checked ? "active" : null}`}
                        variant='contained'
                        onClick={(e) => {
                           setPublicInformation({ ...publicInformation, checked: "Relax at home" })
                        }
                        }
                     >Relax at home
                     </Button>

                     <Button
                        className={`checkInfoButton ${"Read Books" == publicInformation.checked ? "active" : null}`}
                        variant='contained'
                        onClick={(e) => {
                           setPublicInformation({ ...publicInformation, checked: "Read Books" })
                        }
                        }

                     >Read Books
                     </Button>

                     {/* Second line two holiday suggestion button */}
                     <Button
                        className={`checkInfoButton ${"Beaches" == publicInformation.checked ? "active" : null}`}
                        variant='contained'
                        onClick={(e) => {
                           setPublicInformation({ ...publicInformation, checked: "Beaches" })
                        }
                        }
                     >Beaches
                     </Button>

                     <Button
                        className={`checkInfoButton ${"Party with friends" == publicInformation.checked ? "active" : null}`}
                        variant='contained'
                        onClick={(e) => {
                           setPublicInformation({ ...publicInformation, checked: "Party with friends" })
                        }
                        }

                     >Party with friends
                     </Button>


                     {/* last three holiday suggestion button */}
                     <Button
                        className={`checkInfoButton ${"Adventure" == publicInformation.checked ? "active" : null}`}
                        variant='contained'
                        onClick={(e) => {
                           setPublicInformation({ ...publicInformation, checked: "Adventure" })
                        }
                        }
                     >Adventure
                     </Button>

                     <Button
                        className={`checkInfoButton ${"Bringe Television" == publicInformation.checked ? "active" : null}`}
                        variant='contained'
                        onClick={(e) => {
                           setPublicInformation({ ...publicInformation, checked: "Bringe Television" })
                        }
                        }
                     >Binge Television
                     </Button>

                     <div className="earnPoint-para buttons-div" >
                        {!inputButton ?
                           <Button
                              className={`checkInfoButton ${text?.length && text == publicInformation.checked ? "active" : null}`}
                              variant="outlined"
                              id="customInputBtn"
                              onDoubleClickCapture={() => {
                                 document.getElementById("customInputBtn")?.focus()
                                 setinputButton(true)
                                 document.getElementById("customInputBtn")?.focus()
                              }}
                              style={{ textTransform: "none" }}
                              onClick={(e) => {
                                 // text == "Add activity" ?
                                 setinputButton(true)
                                 // :
                                 setPublicInformation({ ...publicInformation, checked: text })
                              }}
                           >
                              {text?.length && text == publicInformation.checked ? text : "Add activity"}
                           </Button>
                           :
                           <TextField className="checkInfoText"
                              variant="outlined"
                              style={{ border: "none" }}
                              placeholder={text == publicInformation.checked && publicInformation.checked ? text : "Add activity"}
                              // value={ text }
                              inputProps={{ maxLength: 30 }}
                              onBlur={() => {
                                 if (text.trim() && text.trim() != "Add activity") {
                                    setPublicInformation({ ...publicInformation, checked: text.trim() })
                                 }
                                 else {
                                    setText("Add activity")
                                    setPublicInformation({ ...publicInformation, checked: "" })
                                 }
                                 setinputButton(true)
                              }}
                              onChange={(e) => {
                                 if (e.target.value.length <= 30) {
                                    setText(e.target.value.trimStart())
                                 }
                              }}
                              onKeyDown={(e) => {
                                 if (e.key == "Enter") {
                                    if (text.trim() && text.trim() != "Add activity") {
                                       setPublicInformation({ ...publicInformation, checked: text.trim() })
                                    }
                                    else {
                                       setText("Add activity")
                                    }
                                    setinputButton(false)
                                 }
                              }}
                           />
                        }
                     </div>

                     <div className="pInfoLabel">
                        {onboardinQuestions?.find(item => item.level === 3)?.name}
                     </div>

                     <Grid xs={12} className="pInfoCheckButtons">
                        <TextField
                           className='text-area'
                           placeholder="Your answer" multiline
                           name='textArea2'
                           variant="outlined"
                           value={publicInformation.textArea2}
                           onChange={(e) => {
                              e.target.value = e.target.value?.trimStart()
                              handleChange(e)
                           }}
                           inputProps={{
                              maxLength: 100
                           }}
                           helperText={`${100 - publicInformation.textArea2.length} Characters`}
                           margin="normal"
                        />
                     </Grid>
                     {errorList.find(item => item === 'textArea2') && <span style={{ display: "block", color: "red", fontSize: "12px", textAlign: "start", height: "25px", marginTop: "3px" }}>{errorTypes.cannot}</span>}

                  </Grid>
                  <div className="pInfoLabel">
                     {onboardinQuestions?.find(item => item.level === 4)?.name}
                  </div>

                  <Grid xs={12} className="pInfoCheckButtons">
                     <TextField
                        className='text-area' minRows="4"
                        placeholder="Your answer" multiline
                        name='textArea3'
                        variant="outlined"
                        value={publicInformation.textArea3}
                        onChange={(e) => {
                           e.target.value = e.target.value?.trimStart()
                           handleChange(e)
                        }}
                        inputProps={{
                           maxLength: 100
                        }}
                        helperText={`${100 - publicInformation.textArea3.length} Characters`}
                        margin="normal"
                     />
                  </Grid>
                  {errorList.find(item => item === 'textArea3') && <span style={{ display: "block", color: "red", fontSize: "12px", textAlign: "start", height: "25px", marginTop: "3px" }}>{errorTypes.cannot}</span>}

                  <div className="pInfoLabel">
                     {onboardinQuestions?.find(item => item.level === 5)?.name}
                  </div>


                  <Grid xs={12} className="pInfoCheckButtons">
                     <TextField
                        className='text-area' minRows="4"
                        placeholder="Your answer" multiline
                        name='textArea4'
                        variant="outlined"
                        value={publicInformation.textArea4}
                        onChange={(e) => {
                           e.target.value = e.target.value?.trimStart()
                           handleChange(e)
                        }}
                        inputProps={{
                           maxLength: 2000
                        }}
                        helperText={`${2000 - publicInformation.textArea4.length} Characters`}
                        margin="normal"
                     />
                  </Grid>
                  {errorList.find(item => item === 'textArea4') && <span style={{ display: "block", color: "red", fontSize: "12px", textAlign: "start", height: "25px", marginTop: "3px" }}>{errorTypes.cannot}</span>}
               </form>
            </Grid>
            :
            <Grid container xs={12} style={{ height: '300px', justifyContent: 'center' }} alignItems="center">
               <div>
                  <CircularProgress size={80} thickness={4} style={{ color: '#ffcb00' }} />
               </div>
            </Grid>
         }
      </Grid>
   )
}

export default PublicInformation;
// Customizable Area End
