Object.defineProperty(exports, "__esModule", {
  value: true,
});
// Customizable Area Start
const CONFIG = require("../../../../../src/packages/framework/src/config");
exports.validationApiContentType = "application/json";
exports.getCountryApiEndPoint = "account_block/accounts/country_list";
exports.getSubcategoriesApiEndPoint = "bx_block_categories/sub_categories?";
exports.methodTypeGet = "GET";
exports.methodTypePatch = "PATCH";
exports.methodTypePut = "PUT";
exports.saveModeratorDetailAPIEndPoint = "account_block/accounts/1";
exports.getAllSubcategoriesAPIEndPoint = "bx_block_categories/sub_categories/sub_cat_drop_down";
exports.getOldSavedAdditionalModeratorAPIEndPoint = "account_block/accounts/show_moderator_profile"
exports.getUserProfileDataAPIEndPoint = "bx_block_profile/profiles/";
exports.getModeratorInfoApiEndPoint =
  "account_block/onboarding_questions/moderator_info_points";
exports.title1 = "Sell or Buy products";
exports.title2 = "Sell / Buy products";
exports.title3 = " Buy products";
exports.title4 = "Sell products";
exports.subtitle1 =
  "Done with React Native Onboarding Swiper Done with React Native Onboarding SwiperDone with React Native Onboarding SwiperDone with React Native Onboarding Swiper";
exports.subtitle2 =
  "Done with React Native Onboarding Swiper Done with React Native Onboarding SwiperDone with React Native Onboarding SwiperDone with React Native";
exports.subtitle3 =
  "Done with React Native Onboarding Swiper Done with React Native Onboarding SwiperDone with React Native Onboarding SwiperDone with React Native Onboarding Swiper";
exports.subtitle4 =
  "Done with React Native Onboarding Swiper Done with React Native Onboarding SwiperDone with React Native Onboarding SwiperDone with React Native Onboarding Swiper";
exports.baseURL = CONFIG.baseURL;
  // Customizable Area End
