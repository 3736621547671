import React, {useEffect, useState} from 'react';
import { Box } from "@material-ui/core";
 import { thumb } from "../../assets";

type UserLikeProps = {
   current_likes: number;
   updateLike: () => void;
 };

 const UserLike = ({ current_likes, updateLike }: UserLikeProps) => {
   const [likes, setLikes] = useState(current_likes);
   const [isAlreadyClicked, setClicked] = useState(false);
 
   useEffect(() => {
     setLikes(current_likes);
   }, [current_likes]);
 
   const handleLike = () => {
     if (!isAlreadyClicked) {
       setLikes((like) => like + 1);
     } else {
       setLikes((like) => like - 1);
     }
     setClicked((prev) => !prev);
 
     updateLike();
   };
 
   return (
     <Box className="customLike">
       <span className="fs14 fw500 colorBlue">{likes}</span>
       <img
         onClick={handleLike}
         src={thumb}
         alt="like_btn"
         className="ml5 thumbsup d-btn"
       />
     </Box>
   );
 };

 export default UserLike;