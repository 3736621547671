// Customizable Area Start
import React, { useEffect, useRef, useState } from "react";

interface Props {
    images: string[];
}

const RandomImages: React.FC<Props> = ({ images }) => {
    const imgRef = useRef<HTMLImageElement>(null);
    const [indx, setIndx] = useState(0);
    let timer: NodeJS.Timeout;

    // add and remove animate class
    const toggleClass = () => {
        if (!indx) return;
        const imgElement = imgRef.current;
        const className = "animate";

        if (imgElement) {
            if (imgElement.classList.contains(className)) {
                imgElement.classList.remove(className);
            } else {
                imgElement.classList.add(className);
            }
        }
    };

    const handleOnLoad = () => {
        toggleClass();

        timer = setTimeout(() => {
            setIndx((count) => (count + 1 === images.length ? 0 : count + 1));
        }, 1000 * 10);

        setTimeout(() => {
            toggleClass();
        }, 2000);
    };

    useEffect(() => {
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, []);

    // cache all images while mount
    useEffect(() => {
        const cacheImages = async () => {
            const promises = images.map((link) => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.src = link;
                    img.onload = resolve;
                    img.onerror = reject;
                });
            });

            await Promise.all(promises);
        };

        cacheImages();
    }, []);

    return (
        <div id="random-img-container">
            <img
                src={images[indx]}
                alt="canvas"
                onLoad={handleOnLoad}
                ref={imgRef}
                className='w-100'
            />
        </div>
    );
}

export default RandomImages;
// Customizable Area End