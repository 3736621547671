// Customizable Area Start
import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import StateContext from "../../../../web/src/globalState";
import moment from "moment";
import calendarSync from "../CalendarSync.web";

// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
  onclose: (value: boolean) => void;
  walletAmount: number;
  routeMap: any;
  history: any;
  onClose?: any;
  values?: any;
  getWalletAmount: () => void;
  updateYCoins: () => void;
  getMyRooms: () => void;
  selectedValue?: any;
  prevData: any;
  // getMyRooms: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  subCategoryList: any[];
  categoryList: any[];
  roomTypeList: any[];
  dateSelectedClass: any;
  currentWeekSlot: any;
  currentMonth: any;
  selectedDate: any;
  currentTime: any;
  selectedDateInTimePiker: any;
  isMessagePop: boolean;
  openCreateSuccessPopUp: boolean;
  createRoomSuccessData: string;
  hasError: string;
  isMobile: boolean;
  bulletPoints: string[];

  roomData: any;
  payload: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateRoomController extends BlockComponent<Props, S, SS> {
  categoryListId: any;
  form: any;
  subCategoryListId: any;
  roomTypeId: any;
  createRoomID: any;
  verifyCurrentTime: string;
  bulletPointsId: string;
  msg = "";
  type = "";
  getMyRoomId: string;
  static contextType: any = StateContext;
  resouceInputRef: React.RefObject<any>;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.state = {
      subCategoryList: [],
      categoryList: [],
      roomTypeList: [],
      dateSelectedClass: "",
      selectedDate: null,
      currentWeekSlot: null,
      currentMonth: "",
      currentTime: null,
      selectedDateInTimePiker: moment("00:00", "h:mm a"),
      // selectedDateInTimePiker: `${Number(moment().format("LT").split(/[:\s]/)[1])%15 == 0  ? moment().format("LT") : moment().set('minute', ( Number(moment().format("LT").split(/[:\s]/)[1]) - Number(moment().format("LT").split(/[:\s]/)[1])%15 + 15 )).format("LT") }`,
      isMessagePop: false,
      openCreateSuccessPopUp: false,
      createRoomSuccessData: "",
      hasError: "",
      isMobile: false,
      bulletPoints: [],

      roomData: {},
      payload: {},
    };
    this.form = React.createRef();
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.resouceInputRef = React.createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (this.categoryListId == apiRequestCallId) {
          this.handleCategoryListData(responseJson);
        } else if (this.subCategoryListId == apiRequestCallId) {
          this.handleSubCategoryListData(responseJson);
        } else if (this.roomTypeId == apiRequestCallId) {
          this.handleRoomTypeData(responseJson);
        } else if (this.createRoomID == apiRequestCallId) {
          this.handleCreateRoomData(responseJson);
        } else if (this.bulletPointsId === apiRequestCallId) {
          this.handleBulletPointsData(responseJson);
        } else if (this.verifyCurrentTime === apiRequestCallId) {
          this.handleVerifyCurrentTime(responseJson);
        }
      }
    }
  }

  handleAlert(msg = "") {
    this.msg = msg;
    this.type = "error";
    this.setState({ isMessagePop: true });
  }

  handleAlertSuccess(msg = "") {
    this.msg = msg;
    this.type = "success";
    this.setState({ isMessagePop: true });
  }

  getCategory() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.categoryListId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoiresAPIEndPoint
    );
    // console.log(">>id", this.categoryListId)
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getSubCategory(value: any) {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.subCategoryListId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubCategoriesAPiEndPoint + value
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getRoomType() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.roomTypeId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRoomTypeAPIEndPoint
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // fetch unix time 
  getCurrentTime = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verifyCurrentTime = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/get_time`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  createRoom(payload: any, UTime: null | number = null) {
    const isTimeZoneWrong = JSON.parse(sessionStorage.getItem('isTimeZoneWrong') || 'false');

    if (isTimeZoneWrong && !UTime) {
      this.setState({ payload });
      this.getCurrentTime();
      return;
    }

    const headers = {
      "Content-Type": undefined,
      token: localStorage.getItem("token"),
      TZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      UTime: UTime || Math.floor(new Date().getTime() / 1000).toString(),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createRoomID = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createRoomAPIEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethodType
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      payload
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  bulletPoints() {
    const headers = {
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.bulletPointsId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bulletPointsAPIEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getMyRooms() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMyRoomId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.GetMyRoomsApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Customizable Area Start

  // handle receive function methods
  handleCategoryListData = (responseJson: any) => {
    this.setState(
      {
        categoryList: responseJson?.data || ["No data found!"],
      },
      () => {
        if (this.props.selectedValue) {
          let findedCategory = this.state.categoryList.find(
            (item) =>
              item.attributes.id === this.props.selectedValue.selectedCategory
          );
          if (findedCategory) {
            this.getSubCategory(findedCategory.id);
            this.form.current.setFieldValue("categoriesId", findedCategory.id);
            this.form.current.setFieldValue(
              "categories",
              findedCategory.attributes.name
            );
          }
        }
      }
    );
  };

  handleSubCategoryListData = (responseJson: any) => {
    this.setState(
      {
        subCategoryList: responseJson?.data || ["No data found!"],
      },
      () => {
        if (this.props.selectedValue) {
          let selectedTopic = this.props.selectedValue.selectedTopic;
          let findedSubcategory = this.state.subCategoryList.find(
            (item) =>
              item.attributes.id ===
              this.props.selectedValue.selectedSubCategory
          );
          this.form.current.setFieldValue(
            "subcategoriesId",
            findedSubcategory.id
          );
          this.form.current.setFieldValue(
            "subcategories",
            findedSubcategory.attributes.name
          );
          const topicId = this.state.subCategoryList
            ?.filter(
              (item: any) =>
                item.attributes.id === findedSubcategory.attributes.id
            )[0]
            ?.attributes.topics?.filter((item: any) => {
              return item.id === selectedTopic;
            });
          if (topicId[0]?.id && topicId[0]?.name) {
            this.form.current.setFieldValue("topicId", topicId[0].id);
            this.form.current.setFieldValue("topic", topicId[0].name);
          }
        }
      }
    );
  };

  handleRoomTypeData = (responseJson: any) => {
    if (!JSON.parse(localStorage.getItem("isModerator") as string)) {
      responseJson.data = responseJson.data.filter((item: any) => {
        return item?.attributes?.name != "Moderated";
      });
    }

    this.setState({
      roomTypeList: responseJson.data,
    });
  };

  handleSyncToGoogleRoom = async () => {
    const isAlreadySynced = await calendarSync([this.state.roomData], "ADD");
    if (!isAlreadySynced) {
      this.handleAlertSuccess("Successfully Synced to Google Calendar");
    } else {
      this.handleAlertSuccess("Already Synced to Google Calendar");
    }
  }

  handleCreateRoomData = async (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ roomData: responseJson.data }, () => {
        if (localStorage.getItem("googleCalendarSync") === "true") {
          this.handleSyncToGoogleRoom();
        }
      });

      this.setState({ openCreateSuccessPopUp: true });
      this.handleRoomsLoad();
    } else if (responseJson.error) {
      this.handleAlert(responseJson?.error);
    } else if (responseJson.errors) {
      this.handleAlert(
        responseJson?.errors[0] || responseJson?.errors[0]?.message || responseJson?.errors?.message
      );
    } else {
      this.handleAlert("Something went wrong!");
    }
  };

  handleBulletPointsData = async (responseJson: any) => {
    // TODO: make this dynamic based on createRoomTextData type ("plain_text" or "html")
    const createRoomTextData = responseJson.data?.find(
      (item: any) =>
        item.attributes.content_key === "ROOM_CREATION_CONFIRMATION"
    )?.attributes;
    if (createRoomTextData) {
      this.setState({
        bulletPoints: JSON.parse(createRoomTextData.content_value ?? "{}")
          ?.bulletPoints,
      });
    } else if (responseJson.error) {
      this.handleAlert(responseJson?.error);
    } else if (responseJson.errors) {
      this.handleAlert(responseJson?.errors?.message);
    } else {
      this.handleAlert("Something went wrong!");
    }
  };

  handleVerifyCurrentTime = (responseJson: any) => {
    if (responseJson?.unixtime) {
      this.createRoom(this.state.payload, responseJson.unixtime);
    } else {
      this.handleAlert("Something went wrong!");
    }
  }

  handleCreateSuccessPopupClose = (value: string) => {
    this.setState({
      openCreateSuccessPopUp: false,
      createRoomSuccessData: value,
    });
    this.props.history.push("/Rooms?type=my");
  };

  handleRoomsLoad = () => {
    this.props.updateYCoins();
  };

  handleResize = () => {
    const isMobile = window.innerWidth < 768;
    this.setState({ isMobile });
  };
  // Customizable Area End
}

// Customizable Area End
