import React, { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { cancelLogo } from './assets';
import EmailAccountRegistrationWeb from '../../../blocks/email-account-registration/src/EmailRegister/EmailRegister.web';
import OtpVerificationWeb from '../../../blocks/email-account-registration/src/EmailRegister/otpVerification.web';
import Onboardingguide from '../../../blocks/onboardingguide/src/Onboardingguide.web';
import ConfirmModerator from '../../../blocks/onboardingguide/src/ConfirmModerator.web';
import OnboardingSuccessful from '../../../blocks/onboardingguide/src/OnboardingSuccessful.web';

type Props = {
  navigation: any;
  id: string;
  isOnboardingDialogOpen: boolean;
  handleOnboardingDialogClose: () => void;
  step?: number;
};

const RegistrationDialog = (props: Props) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [step, setStep] = useState(0);
  const [activeStep, setActiveStep] = useState(0);

  const commonProps = {
    handleOnboardingDialogClose: props.handleOnboardingDialogClose,
    navigation: props.navigation,
    id: props.id,
  };

  useEffect(() => {
    setStep(props.step ?? 0);
  }, [props.step]);

  return (
    <Dialog
      aria-labelledby="register-dialog"
      open={props.isOnboardingDialogOpen}
      onClose={props.handleOnboardingDialogClose}
      maxWidth="md"
      fullWidth
      fullScreen={isSmall}
      PaperProps={
        isSmall
          ? {}
          : {
            style: {
              borderRadius: '24px',
            },
          }
      }
      style={isSmall ? {} : { backdropFilter: 'blur(15px)' }}
    >
      <DialogContent
        style={{
          padding: 0,
          height: '787px',
          display: 'grid',
          alignItems: 'center',
        }}
      >
        <Box position="absolute" top="0" right="0">
          <img
            className="cancel-icon"
            onClick={() => {
              props.handleOnboardingDialogClose();
            }}
            src={cancelLogo}
            alt="close dialog"
          />
        </Box>
        {step === 0 && (
          <EmailAccountRegistrationWeb
            activeStep={activeStep}
            setStep={setStep}
            {...commonProps}
          />
        )}
        {step === 1 && (
          <OtpVerificationWeb
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            setStep={setStep}
            {...commonProps}
          />
        )}
        {step === 2 && <Onboardingguide setStep={setStep} {...commonProps} />}
        {step === 3 && <ConfirmModerator {...commonProps} />}
        {step === 4 && <OnboardingSuccessful {...commonProps} />}
      </DialogContent>
    </Dialog>
  );
};

export default RegistrationDialog;
