Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.methodTypeGet = "GET",
exports.getUserProfileDataAPIEndPoint = "bx_block_profile/profiles/"
// exports.subCategoryListApiEndPoint = "bx_block_categories/sub_categories?category_id="
exports.subCategoryListApiEndPoint = "/bx_block_categories/sub_categories/sub_cat_drop_down"
exports.onBoardingAnswerAPIEndPoint = "account_block/accounts/"
exports.getCountryListApiEndPoint = "account_block/accounts/country_list"
exports.onBoardingQuestionApiEndPoint = "account_block/onboarding_questions"
exports.methodTypePost = "POST"
exports.methodTypeDelete = "DELETE"
exports.methodTypePut = "PUT" 
exports.methodTypePatch = "PATCH" 
// Customizable Area End

