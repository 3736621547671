import React from "react";


import {
    // Customizable Area Start
    Box,
    Button,
    Grid,
    Typography,
    Avatar,
    Container,
    Slider,
    CircularProgress
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { Link } from "react-router-dom";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {
    List, ListItem, ListItemSecondaryAction, ListItemText, ListItemAvatar,
} from "@material-ui/core";
import moment from "moment";

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import "./list.css"
import {
    createTheme,
    ThemeProvider
} from "@material-ui/core/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

import UserPersonalProfileController, {
    Props,
} from "./UserPersonalProfileController";

export default class FollowerList extends UserPersonalProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    //Customizable Area Start

    async componentDidMount(): Promise<void> {
        let userId = localStorage.getItem("userId");

        this.getUserProfileData(userId);
        this.getOwnFollowers();
    }

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Container maxWidth="xl" style={{ backgroundColor: "#fcfbfcfc", minHeight: "100%" }} className="moderator-wrapper moderator-wrapper-connection">
                <Grid container spacing={3} className="followerList">
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                        <Box style={{ display: "flex" }} onClick={() => { this.props.navigation.navigate("UserPersonalProfile") }}>
                            <ArrowBackIcon />
                            <Typography style={{ paddingLeft: "10px" }}>
                                Back To Profile
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <section >

                            <div className="main-div-outter-moderator-list">
                                <span className='Heading-moderator'>
                                    Followers
                                </span>
                            </div>

                            <div className="Moderator-content-outter">
                                <List dense className=''>
                                    {this.state.loading && this.state?.ownFollowers == null ?
                                        <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "center", height: "70%" }}>
                                            <CircularProgress />
                                        </div>
                                        : ""
                                    }
                                    {!this.state.loading &&
                                        this.state?.ownFollowers?.length > 0 ?
                                        this.state?.ownFollowers?.map((value: any, index: any) => {
                                            let getCreatedYear = moment(value.attributes?.created_at).format("YYYY");
                                            let getCreatedMonth = moment(value.attributes?.created_at).format("MMM");
                                            const labelId = `checkbox-list-secondary-label-${value}`;
                                            console.log(value, 'value here')
                                            return (
                                                <div className="list-item-outter">
                                                    <ListItem key={index} >
                                                        <ListItemAvatar style={{ alignSelf: 'flex-start', marginTop: "10px", cursor: "pointer" }}>
                                                            {value?.attributes?.user?.profile_image ?
                                                                <img onClick={() => { this.props.history.push('/UserProfile', { userId: value.attributes.current_user_id }) }} height="40px" width="40px" style={{ borderRadius: "50%" }} src={value?.attributes?.user?.profile_image} alt="profileImage" />
                                                                :
                                                                <AccountCircleIcon onClick={() => { this.props.history.push('/UserProfile', { userId: value.attributes.current_user_id }) }} style={{ fontSize: "50px", color: "#9a9a9a" }} />
                                                            }

                                                            {/* <img height="40px" width="40px" style ={{borderRadius:"50%"}} src={value?.attributes?.account?.profile_image} alt="profileImage" /> */}
                                                        </ListItemAvatar>
                                                        <ListItemText onClick={() => { this.props.history.push('/UserProfile', { userId: value.attributes.current_user_id }) }} className="list-item-content" id={labelId}>
                                                            <div className='list-first-div'>
                                                                <Link to={{ pathname: "/UserProfile", state: { userId: value.attributes.current_user_id } }}
                                                                    style={{
                                                                        color: "#444461", fontWeight: 600,
                                                                        paddingRight: "10px",
                                                                        fontSize: "16px"
                                                                    }}
                                                                >
                                                                    <span style={{ cursor: "pointer" }}  >
                                                                        {value?.attributes?.user?.full_name}
                                                                    </span>
                                                                </Link>
                                                                <Typography style={{ color: "#8E8E8E", fontSize: '12px', textTransform: "uppercase" }} >SINCE {getCreatedMonth} {getCreatedYear} </Typography>

                                                            </div>



                                                        </ListItemText>
                                                        <ListItemSecondaryAction>
                                                            {value?.attributes?.account?.is_friends ?

                                                                <Button style={{ background: "#010138", color: "#fff" }} >
                                                                    Connected
                                                                </Button>
                                                                :
                                                                ""
                                                            }

                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </div>
                                            );
                                        })
                                        :
                                        null
                                    }
                                    {
                                        this.state?.ownFollowers != null && this.state?.ownFollowers?.length == 0 &&
                                        <h2>You Dont have any Followers</h2>
                                    }
                                </List>
                            </div>

                        </section>
                    </Grid>
                    {/* Grid 2 end  */}

                </Grid>

            </Container>

        )
    }
}
                // Customized Area End
