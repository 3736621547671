// Customizable Area Start
import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import ParticipantTab from './participant/participantTab.web';
import MessageTab from './message/messageTab.web';
import './SideBar.css';

interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	value: any;
}

export interface MyProps {
	client: any;
	isConnected: boolean;
	remoteIdentityList: any;
	localIdentity: any;
	allRoomParticipants: any;

	conversationSID: any;

	feedbackData: any;
	handleFeedbackData: (data: any) => void;
	sendConnectionRequest: (ID: number) => void;
}

interface S {
	value: number,
	unreadMsgCount: number;
	socketData: any;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
		>
			{value === index && (
				<Box p={3}>
					<span>{children}</span>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number, value: number) {
	return {
		disableRipple: true,
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
		className: `tab-label ${value === index ? 'active-tab' : 'disabled-tab'}`
	};
}
export default class SideBar extends Component<MyProps, S> {
	state = {
		value: 1,
		unreadMsgCount: 0,
		socketData: null
	};

	handleHeartChange = (hearts: number | null, id: number, name: string, userImage: any) => {
		if (hearts) {
			let temp = this.props.feedbackData;
			let newData = temp.get(id);
			if (newData) {
				newData.hearts = hearts;
				temp.set(id, newData);
			}
			this.props.handleFeedbackData(temp);
		}
	};
	handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		// set to zero, because set all messages to read !!
		if (newValue === 0 && this.state.unreadMsgCount > 0) {
			this.setState({ unreadMsgCount: 0 });
		}

		this.setState({ value: newValue });
	};

	getUnreadMsgCount = async () => {
		let conversation = await this.props.client?.getConversationBySid(
			this.props.conversationSID
		);

		conversation.on('messageAdded', (msg: any) => {
			const currentUser = localStorage.getItem("userName");
			if (msg.author !== currentUser && this.state.value !== 0) {
				this.setState(prev => ({ unreadMsgCount: prev.unreadMsgCount + 1 }))
			}
		});

		// retrive unread messages count
		conversation.getUnreadMessagesCount().then((unread_count: number) => {
			if (unread_count === 0) return;

			// retrive total number of messages.
			if (unread_count === null) {
				conversation.getMessagesCount().then((total_count: number) => {
					this.setState(({ unreadMsgCount: total_count }));
				});
				return;
			}

			this.setState(({ unreadMsgCount: unread_count }));
		});
	}

	async componentDidMount() {
		this.getUnreadMsgCount();
	}

	render() {
		const { unreadMsgCount } = this.state;

		return (
			<div id='sidebar'>
				<div className='paper'>
					<Paper elevation={0} variant='outlined'>
						<div id='tab'>
							<Tabs
								value={this.state.value}
								onChange={this.handleChange}
								aria-label='simple tabs'
							>
								<Tab label={unreadMsgCount > 0 ? `Messages (${unreadMsgCount})` : "Messages"}
									{...a11yProps(0, this.state.value)}
								/>
								<Tab
									label={`Participants (${this.props.remoteIdentityList.length +
										1})`}
									{...a11yProps(1, this.state.value)}
								/>
							</Tabs>
							<TabPanel value={this.state.value} index={0}>
								<MessageTab
									client={this.props.client}
									isConnected={this.props.isConnected}
									localIdentity={this.props.localIdentity}
									conversationSID={this.props.conversationSID}
									allRoomParticipants={this.props.allRoomParticipants}
								/>
							</TabPanel>
							<TabPanel value={this.state.value} index={1}>
								{this.props.allRoomParticipants.length === 0 && (
									<CircularProgress size={20} style={{ color: '#FFCB00' }} />
								)}
								{this.props.allRoomParticipants.map((participant: any) => {
									if (
										this.props.remoteIdentityList.includes(participant.user_name) ||
										this.props.localIdentity === participant.user_name
									) {
										const hasSingleEmptyString = participant.resources.includes("") && participant.resources.length === 1;
										const selfAnonymously = this.props.allRoomParticipants.
											map((each: any) => each.account_id == localStorage.getItem("userId") && each.is_anonymously).includes(true);

										return (
											<ParticipantTab
												key={participant.account_id}
												self={this.props.localIdentity === participant.user_name}
												id={participant.account_id}
												yourTake={participant.your_take}
												resources={hasSingleEmptyString ? [] : participant.resources}
												is_anonymously={participant.is_anonymously}
												anonymously_name={participant.anonymously_name}
												userImage={participant.profile_img}
												user_name={participant.user_name}
												fullName={participant.full_name}
												connectionStatus={
													typeof participant.connection_status !== "string" ?
														participant.connection_status?.status === null ? "Pending" :
															participant.connection_status?.status : participant.connection_status
												}
												connectionRequest={participant.connection_status}
												isConnected={participant.is_connected}
												initialHeartValue={this.props.feedbackData.get(participant.account_id)?.hearts || 0}
												handleHeartChange={this.handleHeartChange}
												sendConnectionRequest={this.props.sendConnectionRequest}
												is_superhost={participant.is_superhost}
												is_moderator={participant.is_moderator}
												selfAnonymously={selfAnonymously}
											/>
										);
									}
								})}
							</TabPanel>
						</div>
					</Paper>
				</div>
			</div>
		);
	}
}
// Customizable Area End
