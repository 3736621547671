//Customizable Area Start
import React, { PropsWithChildren, useState } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MenuList from '../../ThirdPartyAudioAndVideoIntergration/src/room/VideoChatScreen/menuList.web';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import ReportOutlinedIcon from '@material-ui/icons/ReportOutlined';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';

import { UserWarningIcon } from './assets';

const OPTION_1 = 'Send connection request';
const OPTION_2 = 'Report';
const OPTION_3 = 'Block user';
const OPTION_4 = 'Unblock user';

interface Props {
    openReportModal: () => void;
    sendFriendRequest: () => void;
    openBlockModal: () => void;
    openUnBlockModal: () => void;
    is_anonymously: boolean;
    is_already_connected: boolean;
    status: string | null;
}

const MoreOption: React.FC<Props> = ({
    openReportModal, sendFriendRequest, openBlockModal,
    is_anonymously, is_already_connected, status, openUnBlockModal
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget); // set anchorEl to the currentTarget when icon is clicked
    };

    const handleClose = (option: string) => {
        if (option === OPTION_1) {
            sendFriendRequest();
        } else if (option === OPTION_2) {
            openReportModal();
        } else if (option === OPTION_3) {
            openBlockModal();
        } else if (option === OPTION_4) {
            openUnBlockModal();
        }
        setAnchorEl(null); // set it back to null to close the menu
    };

    return (
        <div>
            <IconButton onClick={handleClick} style={{ paddingRight: 0, paddingLeft: 0 }}>
                <MoreVertIcon style={{ color: '#979797' }} />
            </IconButton>
            <Menu
                id='simple-menu'
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {/* {(!is_anonymously && !is_already_connected) && (
                    <MenuList
                        color={'#010039'}
                        icon={<PersonAddOutlinedIcon style={{ color: '#010039', marginRight: '.7rem' }} />}
                        value={OPTION_1}
                        handlerFunction={handleClose}
                        param={OPTION_1}
                    />
                )} */}
                <MenuList
                    color={'#DD5E4A'}
                    icon={<ReportOutlinedIcon style={{ color: '#DD5E4A', marginRight: '.7rem' }} />}
                    value={OPTION_2}
                    handlerFunction={handleClose}
                    param={OPTION_2}
                    lastOne /* change lastOne in v2.3 */
                />
                {(status && status !== 'blockedby') && (
                    <MenuList
                        color={'#DD5E4A'}
                        icon={<UserWarningIcon style={{ color: '#DD5E4A', marginRight: '.7rem', width: '1.3rem', height: '1.3rem' }} />}
                        value={status === 'blocked' ? OPTION_4 : OPTION_3}
                        handlerFunction={handleClose}
                        param={status === 'blocked' ? OPTION_4 : OPTION_3}
                        lastOne
                    />
                )}
            </Menu>
        </div>
    );
}

export default MoreOption;


interface MProps extends PropsWithChildren<{}> {
    title: string;
    handleClick: () => void;
    showForwardIcon?: boolean;
    type: 'alert' | 'info';
}

export const MobileOption = (props: MProps) => {
    const { handleClick, title, showForwardIcon, type, children } = props;

    return (
        <Box display='flex' alignItems='center' justifyContent='space-between' onClick={handleClick} mb={4}>
            <Box display='flex' alignItems='center'>
                {children}
                <Typography style={{ color: type === 'alert' ? '#DD5E4A' : '#010039', fontWeight: 500 }}>{title}</Typography>
            </Box>

            {showForwardIcon && <ChevronRightOutlinedIcon style={{ color: '#DD5E4A', fontWeight: 500 }} />}
        </Box>
    )
}

//Customizable Area End
