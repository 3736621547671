// Customizable Area Start

import React, { useContext, useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Button,
  FormControl,
  Snackbar,
  Box,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { otpLogo, ReferFooter, ReferHeader } from "../assets";
import "./setting.web.css";
import DropDown from "../../../email-account-registration/src/EmailRegister/Dropdown.web";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import StateContext from "../../../../web/src/globalState";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import Switch from "@material-ui/core/Switch";
import OtpInput from "react-otp-input";
import * as firebase from "firebase";
import "firebase/auth";

export const configJSON = require("../config.js");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontWeight: 600,
    },
    alert: {
      "& .MuiSvgIcon-root": {
        color: "#fff !important",
      },
    },
  })
);

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// FAQ's
interface FaqProps {
  getFAQs: () => void;
  faqData: any[];
}

export function FaqPage(props: FaqProps) {
  const classes = useStyles();
  const { getFAQs, faqData } = props;

  useEffect(() => {
    getFAQs();
  }, []);

  return (
    <>
      {faqData.length > 0 &&
        faqData.map((faq, index) => {
          return (
            <Box mb={2.2} key={`${faq.id}-${index}`}>
              <Accordion>
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon style={{ borderRadius: "10px" }} />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    {faq.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ paddingLeft: "1.7rem" }}>
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html: faq.answer,
                      }}
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          );
        })}
    </>
  );
}

//SyncGoogleCalender
export function SyncGoogleCalender({
  toggleGoogleCalendarSync,
  googleCalendarSync,
}: {
  toggleGoogleCalendarSync: () => void;
  googleCalendarSync: boolean;
}) {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <div className="container-c1d">
          <h3>Sync rooms to Google Calendar?</h3>
          <div>
            {loading ? (
              <CircularProgress
                size={20}
                thickness={4}
                style={{ color: "#ffcb00" }}
              />
            ) : (
              <Switch
                checked={googleCalendarSync}
                onClick={toggleGoogleCalendarSync}
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            )}
          </div>
        </div>
      </Grid>
    </div>
  );
}

//Deactivate Account
interface DAProps {
  deactivateAccount: (reason: string) => void;
  setDeactiveReason: (reason: string) => void;
  verifyOTP: (firebase_token: string) => void;
  isCheckingOTP: boolean;
  startOTPChecking: () => void;
  stopOTPChecking: () => void;
}

const ResendOTP = ({
  timer,
  sendOTP,
}: {
  timer: number;
  sendOTP: () => void;
}) => {
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const [remainingTime, setRemainingTime] = useState(timer);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (isTimerRunning && remainingTime > 0) {
      interval = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    }

    if (remainingTime === 0) {
      setIsTimerRunning(false);
    }

    return () => clearInterval(interval);
  }, [isTimerRunning, remainingTime]);

  const startTimer = () => {
    setIsTimerRunning(true);
  };

  const resendOTP = () => {
    setRemainingTime(timer);
    startTimer();
    sendOTP();
  };

  const minutes = Math.floor(remainingTime / 60);
  const seconds = remainingTime % 60;

  return (
    <div className="bottom-text">
      <p className="text-small">
        Code expires in {"0" + minutes}:{seconds < 10 ? "0" + seconds : seconds}
      </p>

      <p className="text-big">
        Didn't get the code?
        <strong
          style={{
            cursor: isTimerRunning ? "not-allowed" : "pointer",
            color: isTimerRunning ? "#00000052" : "#010138",
            marginLeft: ".3rem",
          }}
          onClick={isTimerRunning ? undefined : resendOTP}
        >
          Resend OTP
        </strong>
      </p>
    </div>
  );
};

export function DeactivateAccount(props: DAProps) {
  const [reason, setReason] = useState("");
  const classes = useStyles();
  const [otherReason, setOtherReason] = useState("");
  const [open, setOpen] = useState(false);
  const { userCoinAmount } = useContext(StateContext);
  const [error, setError] = useState("");
  const [isOtpSend, setIsOtpSend] = useState(false);
  const [isOtpSending, setIsOtpSending] = useState(false);
  const { deactivateAccount } = props;
  const [otp, setOtp] = useState("");
  const [capcha, setCapcha] = useState<{
    recaptchaVerifier: firebase.auth.RecaptchaVerifier | null;
    recaptchaToken: string;
  }>({
    recaptchaVerifier: null,
    recaptchaToken: "",
  });
  const [isFirstMount, setIsFirstMount] = useState(true);

  const options = [
    { label: "I am not enjoying YLanes anymore", value: "notEnjoying" },
    {
      label: "I do not appreciate the policies of YLanes",
      value: "wrongPolicies",
    },
    {
      label: "I am not happy with the people I am interacting with",
      value: "notHappy",
    },
    { label: "Other (Please Specify)", value: "other" },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleErrClose = () => {
    setError("");
  };

  const hasError = () => {
    if (!reason || (reason == "Other (Please Specify)" && !otherReason)) {
      setError("Please enter reason for deleting all data");
      return true;
    }

    return false;
  };

  const handleDeactive = () => {
    if (!hasError()) {
      setError("");
      if (reason === "Other (Please Specify)") {
        deactivateAccount(otherReason ? otherReason : reason);
      } else {
        deactivateAccount(reason);
      }
    }
  };

  const handleDeleteAll = () => {
    if (!hasError()) {
      setError("");
      if (reason === "Other (Please Specify)") {
        props.setDeactiveReason(otherReason ? otherReason : reason);
      } else {
        props.setDeactiveReason(reason);
      }
      handleClickOpen();
    }
  };

  const verifyOtp = (otp: string) => {
    const confirmationResult = (window as any).confirmationResult;

    if (!confirmationResult) {
      return;
    }

    confirmationResult
      .confirm(otp)
      .then((result: any) => {
        const user = result.user;
        console.log("firebase USER 2", user);
        props.verifyOTP(user.ya);
      })
      .catch((error: Error) => {
        props.stopOTPChecking();
        setOtp('');
        console.log('error', error);
        // show alert
        setError(error.message);
      });
  };

  const confirmDeleteAllData = (event?: React.FormEvent) => {
    if (event) {
      event.preventDefault();
    }

    if (!otp.trim().length) {
      setError("Please enter your OTP before proceeding");
      return;
    } else if (otp.length !== 6) {
      setError("Please enter a valid OTP");
      return;
    }

    props.startOTPChecking();
    verifyOtp(otp);
  };

  useEffect(() => {
    const verifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
      }
    );

    setCapcha((prev) => ({
      ...prev,
      recaptchaVerifier: verifier,
    }));
  }, []);

  const sendOTP = (appVerifier: firebase.auth.ApplicationVerifier) => {
    if (!appVerifier) {
      return;
    }

    firebase
      .auth()
      .signInWithPhoneNumber(
        localStorage.getItem("p_no") as string,
        appVerifier
      )
      .then((confirmationResult) => {
        setIsOtpSend(true);
        console.log("OTP sent");

        (window as any).confirmationResult = confirmationResult;
      })
      .catch((error: Error) => {
        console.log("error", error);
        setError(error.message);
      })
      .finally(() => {
        setIsOtpSending(false);
      });
  };

  const verifyCapcha = () => {
    setIsOtpSending(true);
    const { recaptchaVerifier, recaptchaToken } = capcha;
    if (recaptchaToken || !recaptchaVerifier) return;

    recaptchaVerifier
      .verify()
      .then((token: string) => {
        setCapcha((prev) => ({
          ...prev,
          recaptchaToken: token,
        }));
        sendOTP(recaptchaVerifier);
      })
      .catch((error: Error) => {
        console.log("recaptcha verify error", error);
      });
  };

  const resendOTP = () => {
    setCapcha((prev) => ({
      ...prev,
      recaptchaToken: "",
    }));
  };

  useEffect(() => {
    if (isFirstMount) {
      setIsFirstMount(false);
      return;
    } else if (!capcha.recaptchaToken) {
      verifyCapcha();
    }
  }, [capcha.recaptchaToken]);

  // auto verify OTP
  useEffect(() => {
    if (otp.length === 6) {
      confirmDeleteAllData();
    }
  }, [otp]);

  return (
    <>
      <div id="recaptcha-container" />

      {error && (
        <Snackbar
          open={!!error}
          autoHideDuration={5000}
          onClose={handleErrClose}
        >
          <Alert
            onClose={handleErrClose}
            severity="error"
            className={classes.alert}
          >
            {error}
          </Alert>
        </Snackbar>
      )}

      <Grid>
        <Grid item xs={12} sm={12} md={10} lg={6} xl={8}>
          <div className="feedback-container">
            <div>
              <h3
                style={{ fontWeight: 500, color: "#010138", marginTop: "0px" }}
              >
                Select reason for deactivation
              </h3>
            </div>

            <div className="earnPoint-para">
              <FormControl fullWidth variant="outlined">
                <DropDown
                  list={options.map((item) => item?.label)}
                  value={reason}
                  onChange={(e) => {
                    setReason(e.target.value);
                  }}
                  className="MuiOutlinedInput-root deactivation-dropdown"
                  defaultText="Select reason"
                />
              </FormControl>
            </div>

            {reason == "Other (Please Specify)" && (
              <div
                className="earnPoint-para textarea-custom"
                style={{ marginTop: "20px" }}
              >
                <TextField
                  className="text-area"
                  placeholder="Your answer"
                  multiline
                  name="textArea"
                  variant="outlined"
                  value={otherReason}
                  onChange={(e) => {
                    setOtherReason(e.target.value);
                  }}
                  margin="normal"
                />
              </div>
            )}

            <div>
              <h3
                style={{
                  fontWeight: 600,
                  marginTop: "20px",
                  color: "#010138",
                  fontSize: "15px",
                }}
              >
                Deactivate
              </h3>

              <p className="grey-para">
                This will put your account in a hold status. You will not be
                searchable by other users nor appear in any lists (e.g.
                connects, room history). You can reactivate your account any
                time by logging in.
              </p>
            </div>

            <div>
              <h3
                style={{
                  fontWeight: 600,
                  marginTop: "20px",
                  color: "#010138",
                  fontSize: "15px",
                }}
              >
                Delete all data
              </h3>

              <p className="grey-para">
                This will delete your entire account permanently including all
                of your data and history (e.g. connects, touchpoints, hearts,
                etc.). This cannot be undone. Your wallet has
                <span> {userCoinAmount} YCoins </span>
                in it, which you will lose if you delete your account.
              </p>
            </div>

            <div
              className="submit-button-div"
              style={{ marginTop: "30px", padding: "0px 10px" }}
            >
              <Button
                style={{ fontWeight: 700, fontSize: "16px" }}
                className="submit-btn-onboard"
                variant="contained"
                onClick={handleDeactive}
              >
                Deactivate
              </Button>

              <Button
                className="delete-btn"
                variant="contained"
                onClick={handleDeleteAll}
                disabled={isOtpSending}
              >
                Delete all data
              </Button>
            </div>

            {/* Alert Dailog */}
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div className="alert-modal">
                {!isOtpSend && (
                  <DialogTitle
                    id="alert-dialog-title"
                    style={{ color: "#010138" }}
                  >
                    {
                      "Are you sure you want to delete your YLanes account including all data?"
                    }
                  </DialogTitle>
                )}
                <DialogContent>
                  {!isOtpSend && (
                    <DialogContentText
                      id="alert-dialog-description"
                      style={{ color: " #C2C4CA", fontWeight: 500 }}
                    >
                      This action cannot be undone.
                    </DialogContentText>
                  )}

                  {isOtpSend && (
                    <div
                      className="otp-container"
                      style={{ textAlign: "center", margin: "0 15%" }}
                    >
                      <h2 style={{ fontWeight: 500, fontSize: "35px" }}>
                        OTP Verification
                      </h2>
                      <p>
                        Please enter 6 digit code that we have sent you on
                        associated mobile number
                      </p>
                      <div style={{ margin: "1.5rem 0" }}>
                        <img width="60%" src={otpLogo} alt="image" />
                      </div>

                      {!props.isCheckingOTP && (
                        <form
                          style={{ margin: '1rem 0' }}
                          onSubmit={confirmDeleteAllData}
                        >
                          <div
                            className="otp-container center-otp-container"
                            style={{ textAlign: 'center' }}
                          >
                            {/* Input for OTP */}
                            <OtpInput
                              value={otp}
                              onChange={(otp: any) => {
                                setOtp(otp);
                              }}
                              numInputs={6}
                              focusStyle={true}
                              shouldAutoFocus={true}
                              isInputNum={true}
                              isInputSecure={true}
                              inputStyle={{
                                color: '#000',
                                width: '50px',
                                margin: '10px 8px',
                                textAlign: 'center',
                                fontSize: '25px',
                                height: '50px',
                                borderRadius: '10px',
                                border: '1px solid rgb(208, 198, 198)',
                              }}
                            />
                          </div>
                          <div className="submit-button-div">
                            <Button
                              className="submit-btn"
                              type="submit"
                              value="Submit"
                              variant="contained"
                              color="primary"
                              style={{ padding: '1rem' }}
                              disabled={isOtpSending}
                            >
                              Verify
                            </Button>
                          </div>
                        </form>
                      )}

                      {props.isCheckingOTP && (
                        <CircularProgress style={{ color: '#ffcb00' }} />
                      )}

                      <ResendOTP timer={120} sendOTP={resendOTP} />
                    </div>
                  )}
                </DialogContent>
                <DialogActions>
                  {!isOtpSend && (
                    <>
                      <Button
                        className="modal-cancel-btn"
                        variant="outlined"
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="modal-delete-btn"
                        variant="outlined"
                        onClick={verifyCapcha}
                        autoFocus
                        disabled={isOtpSending}
                      >
                        Delete
                      </Button>
                    </>
                  )}
                </DialogActions>
              </div>
            </Dialog>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

//Terms and condition
export function TermsAndCondition(props: any) {
  const classes = useStyles();
  React.useEffect(() => {
    props.terms("TERMS");
  }, []);
  return (
    <>
      <div
        style={{
          marginBottom: "20px",
        }}
      >
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ borderRadius: "10px" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              Platform Terms of Use
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              className="terms-codtn-para margin-bottom-custom"
              style={{ whiteSpace: "pre-wrap" }}
            >
              {props.termData?.terms?.description || "No Data Found"}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>

      <div
        style={{
          marginBottom: "20px",
        }}
      >
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ borderRadius: "10px" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Moderator Terms</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              className="terms-codtn-para margin-bottom-custom"
              style={{ whiteSpace: "pre-wrap" }}
            >
              {props.termData?.moderator?.description || "No Data Found"}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
}

//Privacy Policy
export function PrivacyPolicy(props: any) {
  const classes = useStyles();
  React.useEffect(() => {
    props.policyDetails();
  }, []);
  return (
    <>
      <div
        style={{
          marginBottom: "20px",
        }}
      >
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ borderRadius: "10px" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Privacy Policy</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography 
              className="terms-codtn-para margin-bottom-custom"
              style={{ whiteSpace: "pre-wrap" }}
            >
              {props?.policyDataa?.privacyPolicy?.description || "No Data Found"}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
}

//Provide Feedback
export function Feedback(props: any) {
  const [text, setText] = useState("");
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const { isOpen, message, type } = snackbar;

  const showSnackbar = (message: string, type: string) => {
    setSnackbar({ isOpen: true, message, type });
  };

  const handleClose = () => {
    setSnackbar({ isOpen: false, message: "", type: "" });
  };

  const handleSubmitFeedback = () => {
    // add validation
    if (text.trim() === "") {
      showSnackbar("Please enter your feedback.", "error");
      return;
    } else if (text.length < 50) {
      showSnackbar("Please enter at least 50 characters.", "error");
      return;
    }

    props.feedbackData(text);
  };
  return (
    <>
      <Snackbar
        open={isOpen}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity={type as any} variant="filled">
          {message}
        </Alert>
      </Snackbar>

      <div className="feedback-container">
        <div>
          <h3 style={{ fontWeight: 500, marginTop: "0px" }}>
            We would love to hear from you.
          </h3>
        </div>

        <div className="earnPoint-para">
          <TextField
            className="text-area"
            placeholder="Type in your feedback"
            multiline
            name="text"
            variant="outlined"
            value={text}
            // onBlur={handleBlur}
            onChange={(e) => setText(e.target.value)}
            inputProps={{
              maxLength: 1000,
            }}
            helperText={`${1000 - text.length} Characters`}
            margin="normal"
          />
        </div>
        {/* {errors.textArea && <span style={{ display: "block", color: "red", fontSize: "12px" }}>{errors.textArea && touched.textArea && errors.textArea}</span>} */}

        <div className="submit-button-div" style={{ marginTop: "200px" }}>
          <Button
            className="submit-btn-onboard"
            variant="contained"
            onClick={handleSubmitFeedback}
          >
            Send
          </Button>
        </div>

        <p
          style={{
            paddingTop: "25px",
            fontSize: "14px",
            color: "#aaa4a4",
            fontWeight: 500,
          }}
        >
          if you still want to connect with us, email us at{" "}
          <a
            href="mailto:support@ylanes.com"
            style={{ color: "#010138", textDecoration: "underline" }}
          >
            support@ylanes.com
          </a>
        </p>
      </div>
    </>
  );
}

//Refer
export function Refer(props: any) {
  const [snackbar, setSnackbar] = useState({ isOpen: false, message: "", type: "" });
  const { isOpen, message, type } = snackbar;

  const showSnackbar = (message: string, type: string) => {
    setSnackbar({ isOpen: true, message, type });
  };

  const handleClose = () => {
    setSnackbar({ isOpen: false, message: "", type: "" });
  };

  const handleCopy = () => {
    const share_link = localStorage.getItem("share_link");
    const referral_code = localStorage.getItem("referral_code");
    const full_name = localStorage.getItem("fullName");

    if (share_link && referral_code && full_name) {
      const message = `${full_name} has invited you to join YLanes. use this Referral Code to earn extra welcome bonus. See you in the YLanes. \r\nClick here to join ${share_link} \r\nReferral Code: ${referral_code}`;

      navigator.clipboard.writeText(message).then(() => {
        showSnackbar("Link copied to clipboard", "success");
      });
    } else {
      showSnackbar("Something went wrong, please logout & login again.", "error");
    }
  }

  return (
    <>
      <Snackbar
        open={isOpen}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ marginTop: '4rem' }}
      >
        <Alert onClose={handleClose} severity={type as any} variant="filled">
          {message}
        </Alert>
      </Snackbar >

      <Grid item xs={12} sm={12} md={12} lg={8}>
        <Box
          style={{
            background: '#fff', height: '92vh', borderRadius: '1.2rem 1.2rem 0rem 0rem'
          }} textAlign='center' display='flex' flexDirection='column'>
          <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column'>
            <Box mb={10} pt={3}>
              <ReferHeader />
            </Box>

            <Box>
              <Typography style={{
                color: "#1E3354",
                fontSize: "1.5rem",
                fontWeight: 600,
                lineHeight: "1.5rem"
              }}>
                Refer your friends
              </Typography>
              <Box style={{ width: '22rem' }} pt={1.5} pb={2}>
                <Typography style={{
                  color: "#7F8E9D",
                  fontSize: "1rem",
                }}>
                  Send your friend this link to refer them to YLanes. When they sign up, you’ll receive a bonus!
                </Typography>
              </Box>
              <Button style={{
                background: '#FFCB00', color: '#010039', width: '23.4rem',
                height: '2.8rem', borderRadius: '.5rem'
              }} onClick={handleCopy}>
                Copy Link
              </Button>
            </Box>
          </Box>
          <Box marginTop='auto' pb={2.5}>
            <ReferFooter />
          </Box>
        </Box>
      </Grid>
    </>
  );
}

// Customizable Area End
