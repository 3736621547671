import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { DraggableEvent, DraggableData } from 'react-draggable';	

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { number } from "yup";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  roomTabValue: any,
  btnSelect: any,
  moderatorFilters: any,
  subCategoryList: any,
  getModeratorListData: any,
  isMessagePop: boolean,
  followedUserdata: any,
  loading: boolean,
  getCategoriesList: any,

  selected_age: string,
  selected_gender: string,
  selected_subcategory: string,
  selectedFilter: string | null
  defaultPosition: { x: number; y: number };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DifferentRoomsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSubcategoriesId: string
  moderatorListId: string
  msg = ""
  type = ""
  categoriesId: string
  followRequestId: string
  unfollowRequestId: string
  getCategoriesId: string

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      roomTabValue: 0,
      btnSelect: null,
      moderatorFilters: {
        relationship: [],
        exploring: [],
        reflection: [],
        age: [],
        gender: []
      },
      subCategoryList: [],
      getModeratorListData: null,
      isMessagePop: false,
      followedUserdata: {},
      loading: true,
      getCategoriesList: [],

      selected_age: '',
      selected_gender: '',
      selected_subcategory: '',
      selectedFilter: null,
      defaultPosition: {x:0, y:0}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId) {
        if (this.getSubcategoriesId === apiRequestCallId) {
          if (responseJson && responseJson.data) {
            this.setState({ subCategoryList: responseJson?.data })
          }
        }
        if (this.moderatorListId === apiRequestCallId) {
          if (responseJson && responseJson.data) {
            this.setState({ getModeratorListData: responseJson?.data, loading: false })
          } else {
            this.setState({ loading: false, getModeratorListData: [] })
          }
        }
        if (this.followRequestId === apiRequestCallId) {
          if (responseJson && responseJson?.data) {
            this.handleAlertSuccess(responseJson?.meta?.message || "Followed Successfully!");
            this.setState({ followedUserdata: responseJson?.data }, () => {
              this.getModeratorList();
            })
          } else {
            this.handleAlert("Something went wrong!")
          }
        }
        if (this.unfollowRequestId === apiRequestCallId) {
          if (responseJson) {
            this.handleAlertSuccess(responseJson?.message || "Unfollowed Successfully");
            this.getModeratorList();
          }
        }
        if (this.getCategoriesId === apiRequestCallId) {
          if (responseJson && responseJson.data) {
            const category = responseJson?.data?.map((item: any, index: number) => {
              if (index === 0) {

                return { catId: item?.attributes.id, catName: item?.attributes?.name, isSelected: true }
              }
              else {
                return { catId: item?.attributes.id, catName: item?.attributes?.name, isSelected: false }
              }
            })
            this.setState({ getCategoriesList: category, btnSelect: category[0]?.catName })
            this.getSubcategories(category[0]?.catId)
          }
        }
        else if (responseJson.errors && responseJson.errors[0]) {
          const er = Object.keys(responseJson.errors[0])
          if (er && er[0]) {
            return this.handleAlert(responseJson.errors[0][er[0]])
          }

        }

        if (responseJson.errors) {
          if (responseJson.errors[0]?.token) {
            this.props.navigation.navigate("registration")
          }
        }


      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  handleAlert(msg = "") {
    this.msg = msg
    this.type = "error"
    this.setState({ isMessagePop: true })
  }

  handleAlertSuccess(msg = "") {
    this.msg = msg
    this.type = "success"
    this.setState({ isMessagePop: true })
  }

  getSubcategories(value: any) {

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("token")
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSubcategoriesId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubCategoriesAPiEndPoint + value
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getFinalURL = (URL: string) => {
    let finalURL = URL;

    const finalFilters: any = {};
    const { selected_age, selected_gender, selected_subcategory } = this.state;

    if (selected_age) {
      finalFilters.age = selected_age;
    }
    if (selected_gender) {
      finalFilters.gender = selected_gender;
    }
    if (selected_subcategory) {
      finalFilters.sub_category_id = selected_subcategory;
    }

    finalFilters.page = 1;

    Object.keys(finalFilters).forEach((key, indx) => {
      finalURL += `${indx === 0 ? '?' : '&'}${key}=${finalFilters[key]}`;
    });

    return finalURL;
  }

  getModeratorList() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("token")
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.moderatorListId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.getFinalURL(configJSON.GetModeratorListApiEndPoint)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  sendFollowRequest(value: any) {
    let formData = new FormData();
    formData.append("data[attributes][account_id]", value)

    const headers = {
      "Content-Type": undefined,
      "token": localStorage.getItem("token")

    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.followRequestId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.followModeratorApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethodType
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    )
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  sendUnFollowRequest(unfolowerId: any) {
    let userId = localStorage.getItem("userId")
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("token")

    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unfollowRequestId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.unfollowModeratorApiEndPoint + userId + "?account_id=" + unfolowerId
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethodType
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getCategoriesListData() {

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("token")
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategoriesId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoriesAPIEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GetMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    if ((prevState.selected_age !== this.state.selected_age)
      || (prevState.selected_gender !== this.state.selected_gender)
      || (prevState.selected_subcategory !== this.state.selected_subcategory)) {
      this.getModeratorList();
    }
  }

  handleChangeMobileFilter = (filter: string | null) => {
    sessionStorage.setItem('choosenFilter', filter || '');
    this.setState({ selectedFilter: filter });
  };

  handleDrag = (e: DraggableEvent, data: DraggableData) => {
    if (data.y >= 50) {
      // Change this value to control the minimum distance to close the slider.
      // this.handleChangeMobileFilter(null);
    } else {
      this.setState({ defaultPosition: { x: 0, y: 0 } });
    }
  };
  // Customizable Area End
}
