// Customizable Area Start
import { IBlock } from '../../../../../../framework/src/IBlock';
import { Message } from '../../../../../../framework/src/Message';
import { BlockComponent } from '../../../../../../framework/src/BlockComponent';
import MessageEnum, {
	getName
} from '../../../../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../../../../framework/src/RunEngine';
export const configJSON = require('../../config.js');

export interface Props {
	id: number;
	self: boolean;
	yourTake: string;
	resources: any;
	is_anonymously: boolean;
	anonymously_name: string;
	user_name: string;
	fullName: string;
	userImage: string;
	connectionStatus: any;
	connectionRequest: any;
	isConnected: boolean;
	initialHeartValue: number;
	handleHeartChange: (x: number | null, id: number, name: string, userImage: any) => void;
	sendConnectionRequest: (ID: number) => void;
	is_moderator: boolean;
	is_superhost: boolean;
	selfAnonymously: boolean;
}

interface S {
	isConnected: boolean;
	connectionStatus: string;
	heartValue: number | null;
}

interface SS {
	id: any;
}

export default class participantTabController extends BlockComponent<Props, S, SS> {
	sendConnectionMessageId: string;
	acceptRejectFriendConnectionId: string;

	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);

		this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

		this.state = {
			connectionStatus: this.props.connectionStatus,
			isConnected: this.props.isConnected,
			heartValue: this.props.initialHeartValue
		};
		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
	}



	async componentDidMount() {
		super.componentDidMount();
	}

	componentDidUpdate(
		prevProps: Readonly<Props>,
		prevState: Readonly<S>,
		snapshot?: SS | undefined
	): void {
		if (prevState.heartValue !== this.state.heartValue) {
			this.props.handleHeartChange(this.state.heartValue, this.props.id, this.props.user_name, this.props.userImage);
		}
		if (prevProps.connectionStatus !== this.props.connectionStatus) {
			if (typeof this.props.connectionStatus === 'string') {
				this.setState({ connectionStatus: this.props.connectionStatus });
			} else {
				this.setState({ connectionStatus: this.props.connectionStatus?.status || '' });
			}
		}
	}

	async receive(from: string, message: Message) {
		// runEngine.debugLog('Message Received', message);
		if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
			let apiCallId = message.getData(
				getName(MessageEnum.RestAPIResponceDataMessage)
			);
			let errorJson = message.getData(
				getName(MessageEnum.RestAPIResponceErrorMessage)
			);
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
		}
	}

	handleClick = (e: any) => {
		e.preventDefault();
		this.props.sendConnectionRequest(this.props.id);
	};

	// handle connection request 
	sendConnectRequest = (status: any, id: number) => {
		const token = localStorage.getItem("token");

		if (!token) return;

		const payload = {
			"data": {
				status
			}
		}

		const headers = {
			"Content-Type": configJSON.validationApiContentType,
			"token": localStorage.getItem("token")
		};

		const getValidationsMsg = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.acceptRejectFriendConnectionId = getValidationsMsg.messageId;

		getValidationsMsg.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`${configJSON.acceptRejectFriendConnectionApiEndPoint}/${id}`
		);

		getValidationsMsg.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(headers)
		);
		getValidationsMsg.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.patchApiMethod
		);

		getValidationsMsg.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(payload)
		);

		runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
	}
}
// Customizable Area End
