export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backarrow = require("../assets/backarrow.png");
export const earnedCoins = require("../assets/earnedCoin.png");
export const freeCoin = require("../assets/freeCoin.png");
export const coinPurchased = require("../assets/coinPurchased.png");
export const redeemedYCoins = require("../assets/redeemedYCoins.png");
export const refundedCoin = require("../assets/refundedCoin.png");
export const dotted = require("../assets/dotted.png")
export const refillComplete = require("../assets/refillComplete.png")
export const ylanesLogo = require("../assets/ylanesLogo.png")
export const yellowheart = require("../assets/yellowheart.png")
export const spentYcoins = require("../assets/spentYcoins.png")
export const penaltyYcoins = require("../assets/penaltyYcoins.png")
export const topRight = require("../assets/topRight.png")
export const topRightYellow = require("../assets/topRightYellow.png")
export const walletIcon = require("../assets/walletIcon.png")
export const redeemComplate = require("../assets/redeemComplate.png")
export const infoIcon = require("../assets/infoIcon.png")