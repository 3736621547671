// Customizable Area Start
import React, { Component } from 'react';
import CustomizableModalWindow from './customizableModalWindow.web';
import './Modals.css';
import { Card, Typography } from '@material-ui/core';
import { BsFillDoorOpenFill } from 'react-icons/bs';

interface Props {
	retractText: string
}

export default class GreenModal extends Component<Props> {
	render() {
		const { retractText } = this.props;

		return (
			<CustomizableModalWindow id='green-modal' open={true}>
				<Card
					className='border-card-modal'
					style={{
						maxWidth: '48rem',
						color: '#262556',
						borderColor: '#c6edc6',
						padding: '12px 5px 15px 5px',
						zIndex: 1000
					}}
				>
					<div id='modal-text'>
						<BsFillDoorOpenFill className='modal-icon-2' size={35} />
						<Typography style={{ width: "85%", overflowWrap: "break-word" }} variant='h6'>
							{retractText}
						</Typography>
					</div>
				</Card>
			</CustomizableModalWindow>
		);
	}
}
// Customizable Area End
