import React, {useState, useRef,useEffect} from 'react'
import {
   Container,
   Grid,
   Button,
   TextField,
   Box,
   Menu
 } from "@material-ui/core";
 import {
   goldenTick,
   blueTick,
   yellowheart,
   thumb,
   images
 } from "../../assets";
 import CreateIcon from "@material-ui/icons/Create";
 import ClearIcon from "@material-ui/icons/Clear";
 import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import './HostTake.scss';
import UserLike from './UserLike'
import EditTake from './EditTake';


const HostTake = ({updateNewResource, roomDetails, hostDetails, updateRequest,callUpdateYourTakeAPI,history, handleLikeCount, handleReportClick}:any) => {

    const [isEdit, setIsEdit] = useState<boolean>(false)
    const didMount = useRef(false);
    const [hasError, setError] = useState("");

    const getProfilePicture = (room_details: any) => {
      if(room_details?.attributes?.participant?.data?.attributes?.profile_image ) return room_details?.attributes?.participant?.data?.attributes?.profile_image;
      else if (room_details?.attributes?.is_anonymously) return images
      return images
    };
    
    let res: any;
    let room_details: any;
    
    const checkIfCurrentUser = (elem: any) => {
      return (
        parseInt(elem?.attributes?.participant?.data?.id, 10) ===
        parseInt(localStorage.getItem("userId") as string, 10)
      );
    };
    
    const getUserName = (
      isLoggedInUser: boolean,
      is_anonymously: boolean,
      elem: any
    ) => {
      const getUsernameValue = () => {
        if (isLoggedInUser) {
          return "You";
        } else if (is_anonymously) {
          return elem?.attributes?.anonymously_name;
        } else {
          return elem?.attributes?.participant?.data?.attributes?.full_name;
        }
      };
    
      return getUsernameValue();
    };
    
    const getTick = (elem: any) => {
      return elem?.attributes?.participant?.data?.attributes?.is_moderator
        ? goldenTick
        : blueTick;
    };

    const HostResources = () => {
      return (
         <>
           {hostDetails?.host_details?.host_resources?.map(
             (
               resource: {
                 id: number;
                 likes: number;
                 url: string;
               },
               i: number
             ) => {
               const { id, likes, url } = resource;
               const fullUrl = url.includes("//") ? url : `//${url}`;
               return (
                  <div className="hostResources">
                     <div className="resource">
                        <div className="url" onClick={() => {}}>
                           {fullUrl}
                        </div>
                        <div className="buttons">
                          <UserLike
                            current_likes={likes}
                            updateLike={() => handleLikeCount(id)}
                          />
                          {!roomDetails?.attributes?.is_host && 
                            <span
                              className="fs12 colorLightGrey fw500"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => handleReportClick(e, id)}
                              >
                                  <PriorityHighIcon className="reportIcon"/>
                            </span>
                          }
                        </div>
                     </div>
                   </div>
               )
             })}
         </>
      )
    }

    const CheckUserType = () => {
         if (parseInt(localStorage.getItem("userId") as string, 10) === parseInt(roomDetails?.attributes?.host?.data?.id, 10) ) {
            history.push("/UserPersonalProfile");
         } else if (roomDetails?.attributes?.is_anonymously) return
         else {
            history.push("/UserProfile", {
               userId: roomDetails?.attributes?.host?.data?.id,
            });
         }
    };

    const dynamicUserName = (room_details: any) => {
      if ( room_details?.attributes?.host?.data?.id == localStorage.getItem("userId")) {
        return "You";
      } else {
        if (room_details?.attributes?.is_anonymously) {
          return room_details?.attributes?.anonymously_name;
        } else {
          return room_details?.attributes?.host?.data?.attributes?.full_name;
        }
      }
   }

   const renderHostGender = (roomDetails: any) => {
    return (
      <>
        {roomDetails?.attributes?.host?.data?.attributes?.gender === "Male"
          ? "M" : (roomDetails?.attributes?.host?.data?.attributes?.gender ==="Female" ? "F" :
            "NB")
          }
        ({roomDetails?.attributes?.host?.data?.attributes?.age_range?.[0]}-
        {roomDetails?.attributes?.host?.data?.attributes?.age_range?.[1]} yrs)
      </>
    );
  };

    const hostTakeWrapper = (elem: any) => {
      const is_anonymously = elem?.attributes?.is_anonymously;
      const isLoggedInUser = checkIfCurrentUser(elem);
      const userName = getUserName(isLoggedInUser, is_anonymously, elem);
      const tick = getTick(elem);
      return (
        <>
          {isEdit ? 
            <EditTake roomDetails={roomDetails} setIsEdit={setIsEdit} hostDetails={hostDetails} updateNewResource={updateNewResource}/>
          :
          <>
            {roomDetails?.attributes?.is_host &&
              <Grid xs={12} className="hostTakeBoxTexts" container>
                <Grid xs={6}>Your Take</Grid>
                <Grid xs={6} className="editTakeBox" style={{textAlign: 'right'}} onClick={() => {setIsEdit(!isEdit)}}>Edit Take</Grid>
            </Grid>
            }
         <Grid xs={12} className="hostOutside">
            <div className={"hostTakeBox " + (roomDetails?.attributes?.is_host ? "notHost" : "")}>
              <Grid md={(roomDetails?.attributes?.is_host || hostDetails?.host_details?.host_resources.length === 0) ? 12 : 8} xs={12}>
                <Grid xs={12} onClick={CheckUserType} className="hostTakeTopBox">
                  <div className="profileImage">
                      <img src={getProfilePicture(roomDetails)} />
                  </div>
                  <div className="hostName">
                      <span>{dynamicUserName(roomDetails)}</span>
                      <img src={yellowheart} alt="" />
                        {roomDetails?.attributes?.host?.data?.attributes?.hearts || 0}
                  </div>
                  <div className="genderAndAge">
                      {renderHostGender(roomDetails)}
                  </div>
                  <div className="customHostBox">HOST</div>
                </Grid>
                <Grid xs={12} className="middleBox">
                {hostDetails?.host_details?.your_take}
                </Grid>
              </Grid>
                {hostDetails?.host_details?.host_resources.length > 0 &&
                  <Grid xs={12} md={roomDetails?.attributes?.is_host ? 12 : 4} className={"resourcesBox " + (roomDetails?.attributes?.is_host ? '' : 'notHost')}>
                    {HostResources()}
                  </Grid>
                }
                </div>
            </Grid>
          </>
         }
      </>
      )
    }

   return (
      <>
      {hostTakeWrapper(hostDetails)}
      </>
   )
}

export default HostTake;