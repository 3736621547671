// Customizable Area Start
import React, { Fragment } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  Modal,
  Avatar,
  Container, TextField
} from "@material-ui/core";
import MessageIcon from '@material-ui/icons/Message';
import {
  yellowheart,
  plle,
  share,
  as,
  images
} from "../../../DifferentRooms/src/assets";
import AlertBlock from "../../../alert/src/Alert.web";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Link, withRouter } from "react-router-dom";;
import { Formik } from "formik";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Dialog,
} from "@material-ui/core";
import moment from "moment";
import HeaderWeb from "../../../Settings5/src/settings/header.web";
import "./UserProfile.css";
import {
  createTheme,
  ThemeProvider
} from "@material-ui/core/styles";
import { Favorite } from '@material-ui/icons';
import * as Yup from 'yup';



interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface PCProps {
  full_name: string;
  is_anonymously: boolean;
  profile_image: string;
  hearts: string;
  gender: string;
  age_range: number[];
  your_take: string;
  anonymously_name: string;
}

const ParticipantCard: React.FC<PCProps> = ({
  age_range, full_name, gender, hearts, is_anonymously, profile_image, your_take, anonymously_name
}) => {
  return (
    <div className="part-resources mb15">
      <div className="d-flex">
        <div>
          <img src={is_anonymously ? images : profile_image || images
          } alt="" className="radius100 w24 cover mr10" />
        </div>
        <div>
          <div className="d-flex">
            <h3 className="fs14 fw600 colorBlue mb0 mt0">{
              is_anonymously ? anonymously_name :
                full_name}
            </h3>
            <span className="dFlexAlignCenter fs14 colorGrey fw500">
              <img src={yellowheart} alt="" className="w18 ml10 mr10" />
              {hearts}
            </span>
            <span className="fs13 colorGrey fw500 ml15">
              {gender === "Male" ? "M" : gender === "Female" ? "F" : "NB"}
              ({age_range[0]}
              {age_range[1] ? "-" : ""}
              {age_range[1]} yrs)
            </span>
          </div>
          <p className="fs13 colorLightGrey fw500 mb5 mt10 text-justify">
            {your_take}
          </p>
        </div>
      </div>
    </div>
  )
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import UserPersonalProfileController, {
  Props,
} from "../UserPersonalProfile/UserPersonalProfileController";
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import {
  heart,
  people,
  thum, attach, linkedin, instagram, facebook, Twitter, blueTick, goldenTick, likeIcon
} from "../assets";
import Popover from '@material-ui/core/Popover';

class UserProfile extends UserPersonalProfileController {
  constructor(props: Props) {
    super(props);
  }
  resourceId = ""
  reportValue = ""
  createRoomSchema = Yup.object().shape({
    yourTake: Yup.string()
      .required('Please enter Your Take')
      .min(50, "Your Take must be at least 50 characters"),
  });

  handleRefillOpen = () => {
    // @ts-ignore 
    this.myRef.current.handleOpen()
  }

  handleCloseAlert = () => {
    this.setState({ isMessagePop: false })
  }

  getUserID = () => {
    let user_id: string | null = '';

    // get from params (for notification redirection)
    const params = new URLSearchParams(new URL(window.location.href).search);
    const userID = params.get("id");

    if (userID) {
      user_id = userID;
    } else {
      // from url history state
      user_id = this.props?.history?.location?.state?.userId;
    }

    return user_id;
  }

  async componentDidMount(): Promise<void> {
    const user_id = this.getUserID();

    if (!user_id) return;

    localStorage.setItem("selectedUserId", user_id);
    this.setState({ userId: user_id });
    const type = this.props?.history?.location?.search?.split("=")[1];

    if (type && type === "rooms") {
      this.setState({ show: "room" });
    }

    this.getUserProfileData(user_id);
    this.getOwnFollowers()
    this.getWalletCoins();
    this.fetchTouchPoints(user_id, 1, 5);
  }
  //@ts-ignore
  shouldComponentUpdate(prevProps: any, prevState: any) {
    let previousUserId = localStorage.getItem("selectedUserId");
    let seconduserID = this.getUserID();
    if (previousUserId != seconduserID) {

      this.getUserProfileData(seconduserID);
      return false
    }
    return true
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    const userId = this.getUserID();
    const prevId = localStorage.getItem("selectedUserId");

    if (!userId) return;

    if (Number(userId) !== Number(prevId)) {
      localStorage.setItem("selectedUserId", userId);
      this.props.history.replace('/UserProfile', { userId });
      this.getUserProfileData(userId);
      this.getOwnFollowers()
      this.getWalletCoins();
      this.fetchTouchPoints(userId, 1, 5);
    }
  }

  handlePublic = () => {
    this.setState({ show: "public" });

  };

  handleOpenReportPopup = (e: any) => {
    this.setState({ openReportPopUp: e.currentTarget });

  };

  handleCloseReportPopup = () => {
    this.setState({ openReportPopUp: null })
  }

  selectReportValue = (value: string, isButton?: boolean) => {
    if (this.state.isOtherOpen) {
      if (value.length > 0) {
        return this.reportResource(this.resourceId, localStorage.getItem("userId"), value)
      } else {
        this.handleAlert('Text area can not be empty')
        // alert('Textarea can not be empty')
      }
    }
    if (isButton) {
      // api call
      return this.reportResource(this.resourceId, localStorage.getItem("userId"), value)
    }
    this.setState({ reportValue: value })
  }

  handleRoom = () => {
    setTimeout(() => {
      this.setState({ show: "room" });
    }, 500)
  };

  defaultImage = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdDXECBv76wa78obNrJNqayP3o7cy4RaZNg_l_YuhSzP6qoWuHr6BTtn8JgNuHFVmSaf4&usqp=CAU"

  selectedRegisterRoom: any

  render() {
    const { show } = this.state

    const reportsData = [
      "Explicit Content", "Phishing", "Violence"
    ]
    const socialMediaIcons = (media: any) => {
      const lowerType = media.social_type.toLowerCase();
      // check if social_url contains http or https;
      let social_url = media.social_url;
      if (!social_url.includes("http") && !social_url.includes("https")) {
        social_url = "https://" + social_url;
      }

      if (lowerType === "facebook") {
        return <a href={social_url} target="_blank" rel="noopener noreferrer" >
          <img style={{ height: "50px" }} src={facebook} alt='facebook' />
        </a>
      }
      else if (lowerType === "twitter") {
        return <a href={social_url} target="_blank" rel="noopener noreferrer" >
          <img style={{ height: "50px" }} src={Twitter} alt='twitter' />
        </a>
      }
      else if (lowerType === "linkedin") {
        return <a href={social_url} target="_blank" rel="noopener noreferrer" >
          <img style={{ height: "50px" }} src={linkedin} alt='linkedin' />
        </a>
      }
      else if (lowerType === "instagram") {
        return <a href={social_url} target="_blank" rel="noopener noreferrer" >
          <img style={{ height: "50px" }} src={instagram} alt='instagram' />
        </a>
      }
      return null
    }
    const reportPopup = <Popover
      open={!!this.state.openReportPopUp}
      anchorEl={this.state.openReportPopUp}
      onClose={this.handleCloseReportPopup}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <ul id="report-popup" >
        {
          reportsData.map((item: string, indx) => {
            return <li key={indx} style={{ cursor: "pointer" }} onClick={() => this.selectReportValue(item, true)} >{item}</li>
          })
        }
        <li style={{ cursor: "pointer" }} onClick={() => { this.setState({ isOtherOpen: !this.state.isOtherOpen }) }} >{"Other"}</li>
        {this.state.isOtherOpen &&
          <div style={{ padding: "5px", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <input style={{ width: '150px', height: '40px', borderRadius: '7px' }} placeholder="Type your reason" onChange={(e) => this.reportValue = e.target.value} />
            <Button onClick={() => this.selectReportValue(this.reportValue)} style={{ background: "#010138", color: "white", maxWidth: "100px", marginTop: "10px" }} > Submit </Button>
          </div>
        }
      </ul>
    </Popover>

    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={false}
          className="mainContainer"
        >

          <Grid container spacing={1}>

            {/* First Column */}
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Box className="column1-main-box profile" >
                <Box className="column1-child-box1"  >
                  <Box className="column1-child-box1-clild1" sx={{
                    px: '20px',
                    py: '20px',
                    overflow: 'hidden',
                  }} style={{
                    wordBreak: "break-word"
                  }} >
                    <Box style={{ position: "relative" }}
                      sx={{
                      }} >
                      <Avatar id="profilePic" alt={this.state?.userProfileData?.full_name} src={this.state.userProfileData?.profile_image || this.defaultImage} />
                      {this.state.userProfileData?.is_superhost ?
                        < img src={blueTick} style={{ width: "16px", position: "absolute", left: "48px", top: "50px" }} />
                        : ""
                      }

                      {this.state.userProfileData?.is_moderator ?
                        <div>
                          <img src={goldenTick} style={{ width: "16px", position: "absolute", left: "48px", top: "50px" }} />
                        </div>
                        :
                        ""
                      }

                    </Box>
                    <Box sx={{ pl: '20px', my: 'auto', width: "100%" }} >
                      <Box sx={{
                        "width": "100%",
                        "display": "flex",
                        "justifyContent": "space-between",
                        "alignItems": "center"

                      }} >
                        <Typography id="profile-name">
                          {this.state?.userProfileData?.full_name}
                        </Typography>

                      </Box>
                      <Box sx={{
                        display: 'flex',
                        fontWeight: 500
                      }} >
                        <div style={{ fontSize: '12px', color: '#ADADAD' }}>{this.state?.userProfileData?.user_name}</div>
                        {
                          this.state?.userProfileData?.gender === "Do not wish to disclose" ? null :
                            <div style={{ marginLeft: '10px', fontSize: '13px', color: '#ADADAD' }} >
                              {this.state?.userProfileData?.gender}
                            </div>
                        }
                        {/* <div style={{ marginLeft: '10px', fontSize: '12px', color: '#ADADAD' }} >{this.state?.userProfileData?.gender} </div> */}
                        <div style={{ marginLeft: '10px', fontSize: '12px', color: '#ADADAD' }}>

                          {this.state?.userProfileData?.age_range && this.state?.userProfileData?.age_range[0]}
                          {this.state?.userProfileData?.age_range?.length > 0 && this.state?.userProfileData?.age_range[1] && "-"}
                          {this.state?.userProfileData?.age_range && this.state?.userProfileData?.age_range[1]} yrs

                        </div>

                      </Box>
                    </Box>
                  </Box>

                  <Grid container xs={12} style={{ padding: '20px 0px' }} justifyContent="space-evenly">
                    <Grid container xs={12} lg={6} md={6} style={{ marginBottom: '10px' }}>
                      <Grid xs={5}>
                        <div ><img src={heart} className="profileChildIcon" /> </div>
                        <div className="hearts1" > <Typography style={{ fontSize: '14px', fontWeight: 600 }} >HEARTS</Typography> </div>
                        <div className="hearts2" ><Typography>{this.state?.userProfileData?.hearts}</Typography> </div>
                      </Grid>

                      <Grid xs={7}>
                        <div > <img src={people} className="profileChildIcon" />  </div>
                        <div className="hearts" > <Typography style={{ fontSize: '14px', fontWeight: 600 }} >TOUCH POINTS</Typography> </div>
                        <div className="hearts2" ><Typography>{this.state?.touchPoints.touchpoints_count}</Typography></div>
                      </Grid >
                    </Grid>
                    <Grid lg={4} md={4} xs={10} style={{
                      display: "flex", flexDirection: "column"
                    }}>
                      {/* Follow or following button */}
                      {
                        this.state.userProfileData?.is_moderator && this.state.userProfileData?.is_following ?
                          <Button variant="outlined" onClick={() => this.sendUnFollowRequest(this.state?.userProfileData?.id)}
                            style={{ width: "100%", marginRight: "20px", border: "1px solid #101038", background: "#010138", color: "#fff", borderRadius: "18px", cursor: "pointer" }}
                          >
                            Following
                          </Button>
                          :
                          this.state.userProfileData?.is_moderator ?

                            <Button variant="outlined" onClick={() => this.sendFollowRequest(this.state?.userProfileData?.id)}
                              style={{ width: "100%", border: "1px solid #010138", background: "#fff", color: "#010138", borderRadius: "18px", cursor: "pointer" }}
                            >
                              Follow
                            </Button>
                            : ""
                      }

                      {/* Connect button */}
                      {
                        this.state.userProfileData?.friend_request_status === "Connect" &&
                        <Button style={{
                          width: "100%", marginRight: "20px", marginTop: "5px", border: "1px solid #010038", background: "#fff", color: "#010038", borderRadius: "18px"
                        }}
                          onClick={() => this.sendConnectRequest(this.props?.history?.location?.state?.userId)}
                        >
                          Connect
                        </Button>
                      }

                      {/* Connected button */}
                      {
                        this.state.userProfileData?.friend_request_status === "Connected" &&
                        <Button style={{
                          width: "100%", marginRight: "20px", marginTop: "5px", border: "1px solid #101038", background: "#101038", color: "white", borderRadius: "18px", cursor: "pointer"
                        }} onClick={() => {
                          this.disconnectUser(localStorage.getItem("selectedUserId"))
                        }}>
                          Connected
                        </Button>
                      }

                      {/* Pending button */}
                      {
                        this.state.userProfileData?.friend_request_status === "Pending" &&
                        <Button style={{
                          width: "100%", marginRight: "20px", marginTop: "5px", border: "1px solid lightgrey", background: "#f7f7f7", color: "#dbdbdb", borderRadius: "18px"
                        }} disabled={true}>
                          Pending
                        </Button>
                      }

                      {/* Accept Reject  button */}
                      {
                        this.state.userProfileData?.friend_request_status && this.state.userProfileData?.friend_request_status?.data &&
                        <div className='accept-reject' style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          alignItems: "self-end",
                          flexDirection: "column"
                        }}>
                          <Button style={{
                            color: "#fff",
                            background: "#010138",
                            width: "100%",
                            height: "40px",
                            border: "1px solid #010138",
                            margin: "10px 0px 8px 0px"
                          }} onClick={() => {
                            this.acceptRejectFriendConnection(this.state.userProfileData?.friend_request_status?.data?.id, "Accepted");
                          }}>
                            Accept
                          </Button>
                          <Button style={{
                            color: "#fff",
                            width: "100%",
                            height: "40px",
                            border: "1px solid #010138",
                            marginBottom: "10px",
                            background: "#010138"
                          }} onClick={() => {
                            this.acceptRejectFriendConnection(this.state.userProfileData?.friend_request_status?.data?.id, "Rejected");
                          }}>
                            Reject
                          </Button>
                        </div>
                      }

                      {/* Block & Unblock User */}
                      {this.state.userProfileData?.id && (
                        <Button style={{
                          width: "100%",
                          marginRight: "20px",
                          marginTop: "5px",
                          border: "1px solid #fff",
                          background: this.state.userProfileData.is_blocked ? '#010138' : "#DD5E4A",
                          color: "white",
                          borderRadius: "18px", cursor: "pointer"
                        }} onClick={this.handleblockUnBlockkUser}>
                          {this.state.userProfileData.is_blocked ? 'Unblock' : 'Block'}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Box>

                {/*Direct message Buttom */}
                {
                  this.state.userProfileData?.friend_request_status === "Connected" &&

                  <Button style={{
                    background: "#fff",
                    border: "1px solid #dbd8d8",
                    marginTop: "40px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "start",
                    paddingLeft: "15px"
                  }} onClick={() => this.createConversation(this.props?.history?.location?.state?.userId)}
                  >

                    <MessageIcon style={{ color: "#828282", marginRight: "13px", width: "20px" }} />
                    Direct message
                  </Button>

                }
              </Box>

            </Grid>


            {/* Center Column */}
            <Grid item xs={12} sm={12} md={6} lg={6} >
              <Box sx={{ borderBottom: "1px solid lightgray", display: "flex" }}>
                <Typography className="option_btn"
                  style={{ fontWeight: 500, color: show === "public" ? "#101038" : "#d3d3d3", borderBottom: show === "public" ? "5px solid #FFCB00" : 0 }}
                  onClick={() => {
                    this.setState({ show: "public" });
                    this.props.history.push("?type=profile", { userId: this.props?.history?.location?.state?.userId });
                  }
                  }>Public Info</Typography>
                <Typography className="option_btn"
                  style={{ fontWeight: 500, color: show === "room" ? "#101038" : "#d3d3d3", borderBottom: show === "room" ? "5px solid #FFCB00" : 0 }}
                  onClick={() => {
                    this.setState({ show: "room" });
                    this.getPastRoomData(this.props?.history?.location?.state?.userId, 3, 1, 'past');
                    this.getUpcomingRoomData(this.props?.history?.location?.state?.userId, 3, 1, 'upcoming');
                    this.props.history.push("?type=rooms", { userId: this.props?.history?.location?.state?.userId });
                  }}>Rooms</Typography>
              </Box>
              <Box mt={4}>
                {/* ================================ Public tab ============================================ */}
                {
                  show === "public" &&
                  (
                    <Box>
                      {
                        this.state.userProfileData?.is_moderator &&

                        <>

                          <Box style={{ marginTop: "20px", display: "flex", justifyContent: "space-around", flexWrap: 'wrap' }}>
                            <div className="mainTopStatistics">
                              <Typography className="label" >FOLLOWERS</Typography>
                              <Typography className="count">{this.state?.ownFollowers?.length || 0}</Typography>
                            </div>
                            <div className="mainTopStatistics">
                              <Typography className="label" >ROOMS HOSTED</Typography>
                              <Typography className="count" >{this.state?.userProfileData?.total_room_count || 0}</Typography>
                            </div>

                            {
                              this.state?.userProfileData?.moderator_profile?.length > 0 && this.state?.userProfileData?.moderator_profile[0]?.social_accounts?.map(socialMediaIcons)

                            }
                          </Box>

                          {/* Bio Box */}
                          <Box className="middle-box"
                            mt={2}
                            // pb={0.5}
                            px={3}
                            pt={3}
                            style={{
                              overflow: "hidden",
                              wordBreak: "break-all"
                            }}
                          >


                            <Box sx={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }} >
                              <Typography id="middel-box-lable" >Bio</Typography>
                              <Box style={{
                                display: 'flex',
                                width: '20%',
                                justifyContent: 'flex-end'
                              }} >
                                {/* <img src={dotted} className="penIcon" /> */}

                              </Box>
                            </Box>
                            <Box sx={{
                              display: 'flex',
                              my: "20px"
                            }} >
                              <Box>
                                <span style={{ width: '25px', fontSize: "18px", color: "#858585", fontWeight: 500 }} >
                                  {this.state?.userProfileData?.moderator_profile?.length > 0 && this.state?.userProfileData?.moderator_profile[0]?.bio}
                                </span>
                              </Box>
                            </Box>
                          </Box>

                          {/* Certificate Box */}
                          {this.state?.userProfileData?.moderator_profile?.length > 0 && this.state?.userProfileData?.moderator_profile[0]?.educational_qualifications.length > 0 &&
                            <Box className="middle-box"
                              mt={2}
                              // pb={0.5}
                              px={3}
                              pt={3}
                              style={{
                                overflow: "hidden",
                                wordBreak: "break-all"
                              }}
                            >


                              <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between'
                              }} >
                                <Typography id="middel-box-lable" >Certification</Typography>
                                <Box style={{
                                  display: 'flex',
                                  width: '20%',
                                  justifyContent: 'flex-end'
                                }} >
                                  {/* <img src={dotted} className="penIcon" /> */}

                                </Box>
                              </Box>
                              <Box sx={{
                                display: 'flex',
                                my: "20px"
                              }} >
                                <Box>
                                  <span style={{ width: '25px', fontSize: "18px", color: "#858585", fontWeight: 500 }} >
                                    {
                                      this.state?.userProfileData?.moderator_profile?.length > 0 && this.state?.userProfileData?.moderator_profile[0]?.educational_qualifications?.map((detail: any) => {
                                        return <>
                                          {detail?.courses?.map((detail: any) => {
                                            return (<div>
                                              {detail.name}
                                            </div>)

                                          })}
                                        </>

                                      })
                                    }
                                  </span>
                                </Box>
                              </Box>
                            </Box>
                          }

                          {/* <Box className="middle-box"
                            mt={2}
                            // pb={0.5}
                            px={3}
                            pt={3}
                          >


                            <Box sx={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }} >
                              <Typography id="middel-box-lable" >Documents</Typography>
                              <Box style={{
                                display: 'flex',
                                width: '20%',
                                justifyContent: 'flex-end'
                              }} >

                              </Box>
                            </Box>
                            <Box sx={{
                              display: 'flex',
                              my: "20px"
                            }} >
                              <Box> 
                                  <span style={{ width: '25px', fontSize: "20px" }} >
                                    <p style={{ color: "#00008B" }}>
                                      {this.state?.userProfileData?.moderator_profile?.length > 0 && this.state?.userProfileData?.moderator_profile[0]?.educational_qualifications[0]?.courses[0]?.document}
                                    </p>
                                  </span> 
                              </Box>
                            </Box>
                          </Box> */}

                          {/* Experience Box */}
                          {this.state?.userProfileData?.moderator_profile?.length > 0 && this.state?.userProfileData?.moderator_profile[0]?.personal_exp &&
                            <Box className="middle-box"
                              mt={2}
                              // pb={0.5}
                              px={3}
                              pt={3}
                              style={{
                                overflow: "hidden",
                                wordBreak: "break-all"
                              }}
                            >


                              <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between'
                              }} >
                                <Typography id="middel-box-lable" >Personal Experiences</Typography>
                                <Box style={{
                                  display: 'flex',
                                  width: '20%',
                                  justifyContent: 'flex-end'
                                }} >
                                  {/* <img src={dotted} className="penIcon" /> */}

                                </Box>
                              </Box>
                              <Box sx={{
                                display: 'flex',
                                my: "20px"
                              }} >
                                <Box>
                                  <span style={{ width: '25px', fontSize: "18px", color: "#858585", fontWeight: 500 }} >
                                    {this.state?.userProfileData?.moderator_profile?.length > 0 && this.state?.userProfileData?.moderator_profile[0]?.personal_exp}
                                  </span>
                                </Box>
                              </Box>
                            </Box>
                          }

                          {/* Subcategories Box */}
                          <Box className="middle-box"
                            mt={2}
                            // pb={0.5}
                            px={3}
                            pt={3}
                            style={{
                              overflow: "hidden"
                            }}
                          >


                            <Box sx={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }} >
                              <Typography id="middel-box-lable" >Preferred Sub Categories for Moderator</Typography>
                              <Box style={{
                                display: 'flex',
                                width: '20%',
                                justifyContent: 'flex-end'
                              }} >
                                {/* <img src={dotted} className="penIcon" /> */}

                              </Box>
                            </Box>
                            <Box sx={{
                              display: 'flex',
                              my: "20px"
                            }} >
                              {/* <Box style={{ fontSize: "20px", display: "flex", flexDirection: "row", justifyContent: "space-between" }} >
                                <div>RleationShip</div>
                                <div>Kids </div>
                                <div>Work-Life Balance</div>

                              </Box> */}
                              <Box sx={{
                                display: 'flex',
                                flexWrap: 'wrap'
                              }}>
                                {
                                  this.state?.userProfileData?.moderator_user_sub_categories?.map((Item: any, index: number) => {

                                    return <span key={index} style={{
                                      fontSize: "18px",
                                      width: "100%",
                                      fontWeight: 500,
                                      color: "#707070",
                                      display: "flex",
                                      marginRight: "25px",
                                      paddingBottom: "4px",
                                      borderBottom: ' 1px solid #fafafa'
                                    }}>{
                                        Item.name
                                      }

                                      {/* {
                                        (index != this.state?.userProfileData?.moderator_user_sub_categories?.length - 1) ?
                                          <Box ml={3} style={{ opacity: '0.3' }} >&#124;</Box> : null
                                      } */}
                                    </span>
                                  })
                                }
                              </Box>
                            </Box>
                          </Box>
                        </>
                      }

                      {/* Country Box */}

                      <Box className="middle-box"
                        mt={2}
                        // pb={0.5}
                        px={3}
                        pt={3}
                      ><Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }} >
                          <Typography id="middel-box-lable" >Country</Typography>

                        </Box>
                        <Box sx={{
                          display: 'flex',
                          my: "20px"
                        }} >
                          <Box> <span style={{ width: '25px', fontSize: "20px" }} >{this.state?.userProfileData?.country_details?.flag}</span> </Box>
                          <Box ml={2}
                            style={{
                              marginLeft: "12px",
                              display: "flex",
                              alignItems: "center",

                            }}

                          ><Typography id="middel-box-text" style={{ fontSize: "18px" }} >{this.state?.userProfileData?.country_details?.name} </Typography></Box>
                        </Box>
                      </Box>

                      {/* Question and Answers Box */}
                      {
                        this.state.userProfileData && this.state?.userProfileData?.onboarding_details &&
                        this.state?.userProfileData?.onboarding_details?.sort((a: any, b: any) => a.level - b.level).map((item: any, i: number) => <Box className="middle-box"
                          key={i}
                          mt={4}
                          // pb={1}
                          px={3}
                          pt={3}
                          style={{
                            overflow: "hidden",
                            wordBreak: "break-all"
                          }}
                        >
                          <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }} >
                            <Typography id="middel-box-lable" >
                              {/* { 3 words your friends would describe you } */}
                              {item?.question}
                            </Typography>

                          </Box>
                          <Box sx={{
                            display: 'flex',
                            my: "20px"
                          }} >
                            <Box sx={{
                              display: 'flex',
                              flexWrap: 'wrap'
                            }}>
                              {
                                item?.answers?.map((Item: any, index: number) => {
                                  return <span key={index} style={{
                                    fontSize: "18px",
                                    width: "100%",
                                    fontWeight: 500,
                                    color: "#707070",
                                    display: "flex",
                                    marginRight: "25px",
                                    paddingBottom: "4px",
                                    borderBottom: ' 1px solid #fafafa'

                                  }}>
                                    {
                                      Item
                                    }

                                    {/* {
                                      (index != item?.answers.length - 1) ?
                                        <Box ml={3} style={{ opacity: '0.3' }} >&#124;</Box> : null
                                    } */}
                                  </span>
                                })
                              }
                            </Box>
                          </Box>
                        </Box>)
                      }
                    </Box>
                  )
                }


                {/* ====================================Rooms Tab======================================= */}

                {show === "room" &&
                  (
                    <>
                      {/*================================= Upcoming rooms ===================================*/}
                      {
                        !this.state?.getUpcomingRoomData || this.state?.getUpcomingRoomData?.length === 0 ? <div style={{ display: 'flex', justifyContent: 'center', color: '#010138' }}><Typography variant="h6" style={{ fontWeight: 600 }}>No Upcoming Room Found !</Typography></div> :
                          <>
                            <Box mt={2}>
                              {/* <Typography variant='h6' style={{ color: "#101038", marginTop: "25px", paddingBottom: "20px" }}>Upcoming rooms</Typography> */}
                              <Box>
                                <Grid container direction='column' style={{ flexWrap: 'nowrap' }} spacing={3}>
                                  {this.state?.getUpcomingRoomData?.map((elem: any, i) => {
                                    return (
                                      <Fragment key={i}>
                                        <section className="room-details mb20 shadow" key={elem.id} style={{ background: "#fff" }}>
                                          <Grid container className="bBottom">
                                            <Grid item xs={12} md={8} className="borderRight">
                                              <div className="p15 ">
                                                <div className="daf">
                                                  <h3 className="fs22 colorBlue fw500 mt0 mb0">
                                                    {elem.attributes?.topic_name}
                                                  </h3>
                                                  <div>
                                                    {/* <img src={plle} alt="dsaf" className="w22" />
                                                    <img
                                                      src={share}
                                                      alt="dsaf"
                                                      className="w22 ml5"
                                                    /> */}
                                                  </div>
                                                </div>
                                                <div className="mb15 ">
                                                  <p className="fs14 fw500 colorGrey mt0 mb0">
                                                    {elem.attributes?.sub_category_name}
                                                  </p>
                                                  <span className="fs12 fw500 colorGrey">
                                                    {elem.attributes?.room_type_name} room
                                                  </span>
                                                </div>
                                                <p className="fs14 colorGrey fw500 mh75">
                                                  {elem.attributes?.description}
                                                </p>
                                                <div className="seats-time">
                                                  <div>
                                                    {/* <p className="fs14 colorGrey fw500 mb5 mt0">
                                                            04 Seats is remaining
                                                          </p> */}
                                                    <p className="fs14 colorBlue fw500 mt0 mb0">
                                                      {moment(elem.attributes.start_time).format('l')} &nbsp;&nbsp;{' '}
                                                      {moment(elem.attributes.start_time).format('dddd')} &nbsp;&nbsp;{' '}
                                                      {moment(elem.attributes.start_time).format('LT')}
                                                      {/* {UpcomingNewDateFormat}  */}
                                                      {/* Starts in 5 Minutes */}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                              <div className=" m15" style={{
                                                overflow: "hidden",
                                                wordBreak: "break-all"
                                              }}>
                                                <p className="fs15 colorGrey mb10">Host</p>
                                                <div className="d-flex">
                                                  <img
                                                    src={
                                                      elem.attributes?.host?.data?.attributes?.profile_image ||
                                                      this.defaultImage
                                                    }
                                                    alt="Image"
                                                    className="radius100 w48 cover"
                                                  />
                                                  <div className="ml15 ">
                                                    <h4 className="fs15 fw600 colorBlue mb5 mt0 text-capitalize">
                                                      {elem.attributes?.host?.data?.attributes?.full_name}
                                                    </h4>
                                                    <p className="dFlexAlignCenter mt0 fs15 fw500 colorGrey">
                                                      <img
                                                        src={yellowheart}
                                                        alt="heart"
                                                        className="w20 mr10"
                                                      />
                                                      <span className="fs14 mr5">{elem.attributes?.host?.data?.attributes?.age} </span>
                                                      {elem.attributes?.host?.data?.attributes?.gender === "Male" ? "M" :
                                                        elem.attributes?.host?.data?.attributes?.gender === "Female" ? 'F' : "NB"}
                                                      ({elem.attributes?.host?.data?.attributes?.age_range[0]}
                                                      {elem.attributes?.host?.data?.attributes?.age_range?.length > 0 && elem.attributes?.host?.data?.attributes?.age_range[1] && "-"}
                                                      {elem.attributes?.host?.data?.attributes?.age_range[1]} yrs)
                                                    </p>
                                                  </div>
                                                </div>
                                                <p className="fs15 colorGrey fw500 mb10 mt0">
                                                  REGISTERED : {elem.attributes?.participants?.data?.length}
                                                </p>
                                                {elem?.attributes?.participants?.data?.length > 0 && elem?.attributes?.participants?.data.map((participants: any) => {

                                                  if (elem.attributes.is_delete) {
                                                    return null
                                                  };

                                                  return (
                                                    <div className="d-flex mb10"
                                                      style={{
                                                        overflow: "hidden", wordBreak: "break-all"
                                                      }}
                                                      key={participants.id}>
                                                      <img
                                                        src={
                                                          participants?.attributes?.participant?.data
                                                            ?.attributes?.profile_image || this.defaultImage
                                                        }
                                                        alt="image"
                                                        className="radius100 w48 mr10 cover"
                                                      />
                                                      <div className="mr10">
                                                        <h4 className="fs15 fw600 colorBlue mb0 mt0 text-capitalize">
                                                          {participants?.attributes?.participant?.data?.attributes?.full_name}
                                                        </h4>
                                                        <p className="fs14 fw500 colorGrey mt0">
                                                          <img
                                                            src={yellowheart}
                                                            alt="heart"
                                                            className="w20 mr5"
                                                          />
                                                          <span className="fs14 mr5">{participants?.attributes?.participant?.data?.attributes?.age} </span>
                                                          {participants?.attributes?.participant?.data?.attributes?.gender === "Male" ? "M" :
                                                            participants?.attributes?.participant?.data?.attributes?.gender === "Female" ? "F" : "NB"}
                                                          ({participants?.attributes?.participant?.data?.attributes?.age_range[0]}
                                                          {participants?.attributes?.participant?.data?.attributes?.age_range?.length > 0 && participants?.attributes?.participant?.data?.attributes?.age_range[1] && "-"}
                                                          {participants?.attributes?.participant?.data?.attributes?.age_range[1]} yrs)
                                                        </p>
                                                      </div>
                                                    </div>
                                                  );
                                                })}
                                              </div>
                                            </Grid>
                                          </Grid>
                                          <div className="daf action-status">
                                            <div className="daf">
                                              <img src={as} alt="" className="w18 mr10" />
                                              <strong className="fs20 fw600 colorBlue pointer">{elem?.attributes?.room_price}</strong>
                                            </div>

                                            {(!elem?.attributes?.is_registered && !elem?.attributes?.is_host && elem?.attributes?.remaining_seats > 0) &&
                                              <strong className="fs20 fw600 colorBlue pointer"
                                                onClick={
                                                  () => {
                                                    this.selectedRegisterRoom = elem
                                                    this.RegisterModalOpen(elem?.id, elem?.attributes?.room_price, elem)
                                                  }
                                                }
                                              >REGISTER</strong>
                                            }
                                            {elem?.attributes?.is_registered &&
                                              <strong className="fs20 fw600 colorBlue pointer" onClick={() => {
                                                this.RemoveModalOpen(elem?.id, elem?.attributes?.room_price)
                                              }}> CANCEL</strong>
                                            }
                                          </div>
                                        </section>
                                      </Fragment>

                                    )
                                  })}
                                </Grid>

                              </Box>

                            </Box>
                          </>
                      }
                      <div style={{ height: '2px', backgroundColor: '#dbd8d8', width: '100%', margin: '10px 0px' }}></div>
                      {/* ================ ================= Past rooms ====================================== */}
                      {
                        !this.state?.getPastRoomData || this.state?.getPastRoomData?.length === 0 ? <div style={{ display: 'flex', justifyContent: 'center', color: '#010138' }}><Typography variant="h6" style={{ fontWeight: 600 }}>No Past Room Found !</Typography></div> :
                          <Box mt={2}>
                            <Typography variant='h6' style={{ color: "#101038", marginTop: "25px", paddingBottom: "20px" }}>Past rooms</Typography>

                            <Box>
                              <Grid container direction='column' style={{ flexWrap: 'nowrap' }} spacing={3}>
                                {this.state.getPastRoomData?.map((elem: any) => {
                                  return (
                                    <>
                                      <section className="room-details mb20 shadow" key={elem.id} style={{ background: "#f8f8f800" }}>
                                        <Grid container className="bBottom">
                                          <Grid item xs={12} md={8} className="borderRight">
                                            <div className="p15 ">
                                              <div className="daf">
                                                <h3 className="fs22 colorBlue fw500 mt0 mb0">
                                                  {elem.attributes?.topic_name}
                                                </h3>
                                                <div>
                                                  {/* <img src={plle} alt="dsaf" className="w22" />
                                                  <img
                                                    src={share}
                                                    alt="dsaf"
                                                    className="w22 ml5"
                                                  /> */}
                                                </div>
                                              </div>
                                              <div className="mb15 ">
                                                <p className="fs14 fw500 colorGrey mt0 mb0">
                                                  {elem.attributes?.sub_category_name}
                                                </p>
                                                <span className="fs12 fw500 colorGrey">
                                                  {elem.attributes?.room_type_name} room
                                                </span>
                                              </div>
                                              <p className="fs14 colorGrey fw500 mh75">
                                                {elem.attributes?.description}
                                              </p>
                                              <div className="seats-time">
                                                <div>
                                                  {/* <p className="fs14 colorGrey fw500 mb5 mt0">
                                                                    04 Seats is remaining
                                                                  </p> */}
                                                  <p className="fs14 colorBlue fw500 mt0 mb0">
                                                    {moment(elem.attributes?.start_time).format('l')} &nbsp;&nbsp;{' '}
                                                    {moment(elem.attributes?.start_time).format('dddd')} &nbsp;&nbsp;{' '}
                                                    {moment(elem.attributes?.start_time).format('LT')}
                                                    {/* {UpcomingNewDateFormat}  */}
                                                    {/* Starts in 5 Minutes */}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </Grid>
                                          <Grid item xs={12} md={4}>
                                            <div className=" m15"
                                              style={{
                                                overflow: "hidden",
                                                wordBreak: "break-all"
                                              }}
                                            >
                                              <p className="fs15 colorGrey mb10">Host</p>
                                              <div className="d-flex">
                                                <img
                                                  src={
                                                    elem.attributes?.host?.data?.attributes?.profile_image ||
                                                    this.defaultImage
                                                  }
                                                  alt="Image"
                                                  className="radius100 w48 cover"
                                                />
                                                <div className="ml15 ">
                                                  <h4 className="fs15 fw600 colorBlue mb5 mt0 text-capitalize">
                                                    {elem.attributes?.host?.data?.attributes?.full_name}
                                                  </h4>
                                                  <p className="dFlexAlignCenter mt0 fs15 fw500 colorGrey">
                                                    <img
                                                      src={yellowheart}
                                                      alt="heart"
                                                      className="w20 mr10"
                                                    />
                                                    <span className="fs14 mr5">{elem.attributes?.host?.data?.attributes?.age} </span>
                                                    {elem.attributes?.host?.data?.attributes?.gender === "Male" ? "M" :
                                                      elem.attributes?.host?.data?.attributes?.gender === "Female" ? 'F' : "NB"}
                                                    ({elem.attributes?.host?.data?.attributes?.age_range[0]}
                                                    {elem.attributes?.host?.data?.attributes?.age_range?.length > 0 && elem.attributes?.host?.data?.attributes?.age_range[1] && "-"}
                                                    {elem.attributes?.host?.data?.attributes?.age_range[1]} yrs)
                                                  </p>
                                                </div>
                                              </div>
                                              <p className="fs15 colorGrey fw500 mb10 mt0">
                                                REGISTERED : {elem.attributes?.participants?.data?.length}
                                              </p>
                                              {elem?.attributes?.participants?.data?.length > 0 && elem?.attributes?.participants?.data.map((participants: any) => {
                                                // if (elem.attributes.is_delete) {
                                                //   return null
                                                // };

                                                return (
                                                  <div className="d-flex mb10"
                                                    style={{
                                                      overflow: "hidden", wordBreak: "break-all"
                                                    }}
                                                    key={participants?.id}>
                                                    <img
                                                      src={
                                                        participants?.attributes?.participant?.data
                                                          ?.attributes?.profile_image || this.defaultImage
                                                      }
                                                      alt="image"
                                                      className="radius100 w48 mr10 cover"
                                                    />
                                                    <div className="mr10">
                                                      <h4 className="fs15 fw600 colorBlue mb0 mt0 text-capitalize">
                                                        {participants?.attributes?.participant?.data?.attributes?.full_name}
                                                      </h4>
                                                      <p className="fs14 fw500 colorGrey mt0">
                                                        <img
                                                          src={yellowheart}
                                                          alt="heart"
                                                          className="w20 mr5"
                                                        />
                                                        <span className="fs14 mr5">{participants?.attributes?.participant?.data?.attributes?.age} </span>
                                                        {participants?.attributes?.participant?.data?.attributes?.gender === "Male" ? "M" :
                                                          participants?.attributes?.participant?.data?.attributes?.gender === "Female" ? "F" : "NB"}
                                                        ({participants?.attributes?.participant?.data?.attributes?.age_range[0]}
                                                        {participants?.attributes?.participant?.data?.attributes?.age_range?.length > 0 && participants?.attributes?.participant?.data?.attributes?.age_range[1] && "-"}
                                                        {participants?.attributes?.participant?.data?.attributes?.age_range[1]} yrs)
                                                      </p>
                                                    </div>
                                                  </div>
                                                );
                                              })}
                                            </div>
                                          </Grid>
                                        </Grid>
                                        {
                                          elem?.attributes?.is_delete && <strong className="fs20 fw600 colorBlue pointer cancelledBtn">{elem?.attributes?.feedback_received}</strong>
                                        }
                                        {/* <div className="daf action-status">
                                                          <div className="daf">
                                                            <img src={as} alt="" className="w18 mr10"/>
                                                             <strong className="fs20 fw600 colorBlue pointer">{elem?.attributes?.room_price}</strong>
                                                          </div>
                                                         
                                                             
                                                          <strong className="fs20 fw600 colorBlue pointer" 
                                                            //   onClick={
                                                            //      ()=>this.RegisterModalOpen(elem?.id,elem?.attributes?.room_price)
                                                            //     }
                                                            >REGISTER</strong> 
                                                        </div> */}
                                      </section>
                                    </>
                                  )
                                })}
                              </Grid>

                            </Box>

                          </Box>
                      }
                    </>
                  )
                }

              </Box>

            </Grid>


            {/* Last Column */}
            <Grid item xs={12} sm={12} md={3} lg={3} className="scrollbarY right">


              <Box mt={3} px={2} >
                <Typography variant="h5" style={{ fontWeight: 500, color: '#010039', fontSize: '18px' }} > Resources </Typography>
              </Box>

              <Box mt={1} ml={2} className="column1-child-box1 column2-child-box2" >
                {
                  this.state?.userProfileData?.room_resources?.length > 0 ?
                    this.state?.userProfileData?.room_resources.map((resource: any, index: any) => {
                      if (resource?.resource_urls.length > 0) {
                        return (
                          resource?.resource_urls?.map((item: any) => {
                            const filterLikeUserId = item?.user_ids.find((id: any) => id === Number(localStorage.getItem("userId")))
                            if (item?.url?.length > 0) {
                              return (
                                <Box style={{ borderBottom: '0.1px solid #dbd8d8' }} pb={2} >
                                  <Box px={2} pt={2} >
                                    <Typography style={{ fontWeight: 500, color: '#9C9C9C', fontSize: "13px" }} >
                                      <span>{resource?.category_name}</span>
                                      <ChevronRightIcon style={{
                                        height: "18px",
                                        position: "relative",
                                        top: "5px"

                                      }} />
                                      <span>{resource?.sub_category_name}</span>
                                      <ChevronRightIcon style={{
                                        height: "18px",
                                        position: "relative",
                                        top: "5px"

                                      }} />
                                      <span>{resource?.topic_name}</span>

                                      {/* {"Relationship > Starting a romantic relationship > What is love ?"}  */}
                                    </Typography>
                                  </Box>
                                  <Box px={2} pt={1} >
                                    <Typography style={{ fontWeight: 500, color: '#010039', fontSize: "16px", cursor: "pointer", wordBreak: "break-all" }} >
                                      <a href={item?.url.includes("https://") ? item?.url : "https://" + item?.url}
                                        target="_blank"
                                        style={{ color: "#010138", fontWeight: 500 }}
                                      >
                                        {item?.url}
                                      </a>
                                    </Typography>
                                  </Box>
                                  <Box px={2} pt={2} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                                    <Box style={{ display: 'flex', }} >
                                      {filterLikeUserId ?
                                        <ThumbUpIcon style={{ fontSize: "20px", color: "#0b1275", cursor: "pointer" }} onClick={() => {
                                          this.deleteLikeResource(localStorage.getItem("userId"), item?.id)
                                        }} />
                                        :
                                        <ThumbUpAltOutlinedIcon style={{ fontSize: "20px", color: "#0b1275", cursor: "pointer" }} onClick={() => {
                                          this.likeResource(item.id)
                                        }} />
                                      }

                                      {/* <img src={likeIcon} style={{ width: "14px", height: '14px', marginTop: '5px' }} /> */}
                                      <Typography style={{ fontWeight: 500, color: '#010039', fontSize: "16px", marginLeft: '10px' }}>
                                        {item?.likes}
                                      </Typography>

                                    </Box>
                                    {/* {reportPopup} */}
                                    <Button style={{ fontWeight: 500, color: '#7E7E7E', fontSize: "14px", marginLeft: '20px', cursor: "pointer" }}
                                      onClick={(e) => {
                                        this.resourceId = item.id
                                        this.handleOpenReportPopup(e)
                                      }}
                                    >
                                      Report
                                    </Button>
                                  </Box>
                                </Box>
                              )
                            }
                          })
                        )
                      }
                    })
                    :
                    <Typography style={{ color: "#010039", fontWeight: 500, textAlign: "left", width: "100%", padding: "10px" }} >No resource found!</Typography>

                }
              </Box>
              {reportPopup}

            </Grid>

            {/* Register Modal when user click on register button */}
            <AlertBlock data={{ show: this.state.isMessagePop, close: this.handleCloseAlert, msg: this.msg, type: this.type, vertical: "top", horizontal: "center" }} />
            {this.state.registerModal === true && (
              <section className="register-room">
                <div className="daf mb15">
                  <h2 className="fs18 colorBlue fw600 mb0 mt0">Register for this Room</h2>
                  <CloseIcon onClick={() => {
                    this.selectedRegisterRoom = null
                    this.RegisterModalClose()
                  }} className="pointer" />
                </div>
                <h3 className="fs16 colorBlue fw600 mb15 mt0">Participants and Resources</h3>

                <ParticipantCard
                  full_name={this.selectedRegisterRoom?.attributes?.host?.data?.attributes?.full_name}
                  age_range={this.selectedRegisterRoom?.attributes?.host?.data?.attributes?.age_range}
                  anonymously_name={this.selectedRegisterRoom?.attributes?.anonymously_name}
                  gender={this.selectedRegisterRoom?.attributes?.host?.data?.attributes?.gender}
                  hearts={this.selectedRegisterRoom?.attributes?.host?.data?.attributes?.hearts || 0}
                  is_anonymously={this.selectedRegisterRoom?.attributes?.is_anonymously}
                  profile_image={this.selectedRegisterRoom?.attributes?.host?.data?.attributes?.profile_image}
                  your_take={this.selectedRegisterRoom?.attributes?.your_take}
                />


                {this.selectedRegisterRoom?.attributes?.participants.length > 0 && this.selectedRegisterRoom?.attributes?.participants?.map((elem: any) => {
                  return (
                    <div key={elem.id}>
                      {elem?.attributes?.participants?.data?.length > 0 && elem?.attributes?.participants?.data?.map((contestant: any) => {
                        const is_anonymously = contestant?.attributes?.is_anonymously;

                        return (
                          <ParticipantCard
                            full_name={contestant?.attributes?.participant?.data?.attributes?.full_name}
                            age_range={contestant?.attributes?.participant?.data?.attributes?.age_range}
                            anonymously_name={this.state.participantsDetails[0]?.attributes?.anonymously_name}
                            gender={contestant?.attributes?.participant?.data?.attributes?.gender}
                            hearts={contestant?.attributes?.participant?.data?.attributes?.hearts || 0}
                            is_anonymously={is_anonymously}
                            profile_image={contestant?.attributes?.participant?.data?.attributes?.profile_image}
                            your_take={contestant?.attributes?.your_take}
                            key={contestant.id}
                          />
                        )
                      })}
                    </div>
                  )
                })}

                <section className="radius8 mb15 bgWhite">
                  <Formik
                    initialValues={{
                      yourTake: "",
                      resource: [""],
                      anonymus: false,
                      screenName: ""
                    }}
                    validationSchema={this.createRoomSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      const payload = new FormData();
                      // @ts-ignore
                      payload.append("registered_room[room_id]", `${this.selectedRegisterRoom?.id}`)
                      payload.append("registered_room[is_anonymously]", `${values?.anonymus}`)

                      const validResource = values.resource.filter((item: any) => item)
                      validResource.forEach((item: any) => {
                        payload.append("registered_room[resources_attributes][][url]", item);
                      });

                      payload.append("registered_room[anonymously_name]", values.screenName)
                      payload.append("registered_room[your_take]", values.yourTake)

                      // @ts-ignore
                      this.registerRoom(payload, this.selectedRegisterRoom?.attributes?.is_registered)
                      this.setState({ registerModal: false })
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                      /* and other goodies */
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <h3 className="fs18 colorBlue fw500 mt0 mb10">Your Take</h3>
                        <div className="room-details-register">
                          <TextField
                            className="register-textarea"
                            multiline
                            name='yourTake'
                            variant="outlined"
                            value={values.yourTake}
                            placeholder="Please share what you will be bringing to this conversation - a subtopic, experience, question, perspective, some ideas, etc. You can also share any relevant resources such as YouTube links, websites, Instagram / Pinterest pages, etc. using the link below"
                            onChange={(e) => {
                              e.target.value = e.target.value?.trimStart()
                              handleChange(e)
                            }}
                            // margin="normal"
                            inputProps={{
                              maxLength: 300
                            }}
                            onBlur={handleBlur}
                          />
                          {errors.yourTake && <span style={{ display: "block", color: "red", fontSize: "12px", textAlign: "start" }}>{errors.yourTake && touched.yourTake && errors.yourTake}</span>}
                        </div>
                        <div className="dFlexAlignCenter mb10" onClick={() => this.setState({ addResources: true })}>
                          <label htmlFor="resources" className="fs15 fw600 colorBlue add-resources"> <img src={attach} alt="" /> Add resources</label>
                        </div>
                        {this.state.addResources === true &&
                          <div className="room-details-register mb5">
                            <input
                              id="resources"
                              type="text" placeholder="www.google.com" className="w-100" name="resource"
                              value={values.resource}
                              onChange={(e) => {
                                e.target.value = e.target.value?.trimStart()
                                handleChange(e)
                              }}
                              onBlur={handleBlur}
                            />
                          </div>}
                        <div className="dFlexAlignCenter mb10" onClick={() => this.setState({ registerAnonymously: true })}>
                          <input type="checkbox" id="register" />
                          <label htmlFor="register" className="fs15 ml10 fw600 colorBlue">REGISTER ANONYMOUSLY</label>
                        </div>
                        {this.state.registerAnonymously === true &&
                          <div className="room-details-register">
                            <label htmlFor="register" className="fs15 fw600 colorBlue">Screen Name*</label>
                            <br />
                            <input
                              type="text"
                              className="w-100"
                              placeholder="Screen Name"
                              name="screenName"
                              value={values.screenName}
                              onChange={(e) => {
                                e.target.value = e.target.value?.trimStart()
                                handleChange(e)
                              }}
                              onBlur={handleBlur}
                            />
                          </div>}
                        <Button
                          type="submit"
                          variant="contained"
                          style={{
                            background: "#FFCC00",
                            width: "100%",
                            padding: "15px 0px !important",
                            textTransform: "none",
                            marginTop: "20px",
                            marginBottom: "20px"
                          }}
                          disabled={this.state.walletYCoins < this.selectedRegisterRoom?.attributes?.room_price ? true : false}
                        >
                          <Typography style={{ fontWeight: 600, fontSize: "18px" }}>
                            Register
                          </Typography>
                        </Button>
                      </form>
                    )}
                  </Formik>
                  {/* {this.state.walletYCoins < this.selectedRegisterRoom?.attributes?.room_price && (<div className="text-center" onClick={this.handleRefillOpen}>
                            <span className="fs14 colorBlue fw600 textUnderline">Refill your wallet</span>
                          </div>)} */}
                </section>
              </section>
            )}
            <Modal
              open={this.state.removeModal}
              onClose={this.RemoveModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <section className="modal p15">
                <h3 className="fs15 colorBlue fw500 mb10 mt0">
                  Are you sure you want to retract your registration request?
                </h3>
                <div className="daf">
                  <button style={{ cursor: "pointer" }} className="btn-transparent fs15 colorBlue fw500 width45" onClick={() => this.RemoveModalClose()}>
                    No
                  </button>
                  <button style={{ cursor: "pointer" }} className="btn-blue fs15 colorWhite fw500 width45" onClick={() => this.removingFromRoom(this.state.removeParticpantId)}>
                    Yes
                  </button>
                </div>
              </section>
            </Modal>
          </Grid>
        </Container>



      </ThemeProvider >
    );
  }
}


// @ts-ignore
export default withRouter(UserProfile);

const ResourceData = [
  {
    Name: "Darla Jones",
    Age: "29",
    Gender: "Male",
    Age_range: [40, 45],
    Zoom_link: "http://www.zoomit.com"

  },
  {
    Name: "Darla Jones",
    Age: "29",
    Gender: "Male",
    Age_range: [40, 45],
    Zoom_link: "http://www.zoomit.com"

  }, {
    Name: "Darla Jones",
    Age: "29",
    Gender: "Male",
    Age_range: [40, 45],
    Zoom_link: "http://www.zoomit.com"

  }, {
    Name: "Darla Jones",
    Age: "29",
    Gender: "Male",
    Age_range: [40, 45],
    Zoom_link: "http://www.zoomit.com"

  }, {
    Name: "Darla Jones",
    Age: "29",
    Gender: "Male",
    Age_range: [40, 45],
    Zoom_link: "http://www.zoomit.com"

  },
]

const RoomsData = [
  {
    title: "My equation with the boss",
    subtitle: "Workplace,boss,collegues",
    describe: "Standard room",
    paragraph: "Maecenas suscript diam Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's the printing and typesetting industry. Lorem Ipsum has been the industry's",
    date: "14/03/2022",
    day: "Monday",
    time: " 4.30PM",
    hostName: "Darla Jones",
    age: "29 M(40-45 yrs)",

  },
  {
    title: "My equation with the boss",
    subtitle: "Workplace,boss,collegues",
    describe: "Standard room",
    paragraph: "Maecenas suscript diam Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's the printing and typesetting industry. Lorem Ipsum has been the industry's",
    date: "14/03/2022",
    day: "Monday",
    time: " 4.30PM",
    hostName: "Darla Jones",
    age: "29 M(40-45 yrs)",

  },
  {
    title: "My equation with the boss",
    subtitle: "Workplace,boss,collegues",
    describe: "Standard room",
    paragraph: "Maecenas suscript diam Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's the printing and typesetting industry. Lorem Ipsum has been the industry's",
    date: "14/03/2022",
    day: "Monday",
    time: " 4.30PM",
    hostName: "Darla Jones",
    age: "29 M(40-45 yrs)",

  },
]
// Customizable Area End