// Customizable Area Start
import React, { Component } from "react";

import {
  Box,
  Button,
  Grid,
  Typography,
  Avatar,
  Container,
  Slider,
  Tooltip,
  Hidden,
  Dialog,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import "./styles.scss";
import {
  createTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";

import AlertBlock from "../../../alert/src/Alert.web";

import UserPersonalProfileController, {
  Props,
} from "./UserPersonalProfileController";
import {
  NoUserImg,
  dotted,
  heart,
  people,
  wallet,
  blueTick,
  goldenTick,
  instagram,
  linkedin,
  facebook,
  Twitter,
  Oval,
  joh,
  thoma,
} from "../assets";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Tutorials from "../../../DifferentRooms/src/MyRooms/Tutorials.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
    text: {
      primary: "#010039",
      secondary: "#7F8E9D",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface TPProps extends RouteComponentProps {
  item: any;
  index: number;
  sendFriendRequest: (user_id: number) => void;
}

interface TPState {
  isSend: boolean;
}

class TouchPointUser extends Component<TPProps, TPState> {
  constructor(props: TPProps) {
    super(props);
    this.state = {
      isSend: false,
    };
  }

  getMonthAndYear = (date: string) => {
    const momentDate = moment(date);
    const shortMonthName = momentDate.format("MMM");
    const year = momentDate.format("YYYY");

    return `IN ${shortMonthName} ${year}`;
  };

  handleRequestSend = (user_id: number) => {
    this.setState({ isSend: true });
    this.props.sendFriendRequest(user_id);
  };

  public render() {
    const { item, index, history } = this.props;
    const user = item.attributes;

    const openProfile = (userId: number, is_anonymous: boolean) => {
      if (is_anonymous) return;
      history.push("/UserProfile", { userId });
    };

    return (
      <Box px={2} className="touch-points-profile" key={item.id || index}>
        <div className="user-card__image-container">
          <img src={user.profile_image || NoUserImg} id="profilePic0" />
          {!user.is_anonymous && (user.is_moderator || user.is_superhost) && (
            <img
              src={user.is_moderator ? goldenTick : blueTick}
              alt=""
              className="user-card__check-mark"
            />
          )}
        </div>

        <Box mt={1} className="touch-points-profile-name">
          <Typography
            className="trim-text"
            onClick={() => openProfile(user.account_id, user.is_anonymous)}
          >
            {user.full_name}
          </Typography>
          <Typography>{this.getMonthAndYear(user.created_at)} </Typography>
        </Box>
        {!user.is_anonymous && user.is_connected && (
          <Box mt={2} className="touch-points-profile-action">
            <button id="connected-btn">
              <span>Connected</span>
            </button>
          </Box>
        )}
      </Box>
    );
  }
}

export const TouchUser = withRouter(TouchPointUser);

export const staticTutorials = [
  {
    id: 1,
    title: "Track Progress",
    description: "Track your progress as a user.",
    width: 300,
    height: 180,
    gap: 27,
  },
  {
    id: 2,
    title: "Become a Moderator",
    description:
      "Hold a safe space and earn money as a Moderator*; Learn more here.",
    width: 200,
    height: 180,
    gap: 27,
  },
];

const getDynamicRoomCount = (count: number) => {
  return count >= 5 ? 5 : count;
};

class UserProfileRegular extends UserPersonalProfileController {
  constructor(props: Props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleDeniedClose = this.handleDeniedClose.bind(this);
  }

  handleChange = (newValue: any) => {};

  handleClose() {
    this.setState({ isMessagePop: false });
  }

  handleDeniedClose() {
    this.setState({ deniedOpen: false });
  }

  async componentDidMount(): Promise<void> {
    // load tutorials if user is new
    const is_dashboard_training_done = JSON.parse(
      localStorage.getItem("is_profile_training_done") || "false"
    );
    const isModerator = localStorage.getItem("isModerator") === "true";

    let finalTurtorials = !isModerator
      ? staticTutorials
      : staticTutorials.filter((tutorial) => tutorial.id !== 2);
    console.log("finalTurtorials", finalTurtorials);
    this.context.setTutorials(
      finalTurtorials,
      this.updateProfileTurtoials,
      !is_dashboard_training_done
    );
    let userId = localStorage.getItem("userId") as string;
    this.getUserProfileData(userId);
    this.getConnectedUserData();
    this.getOwnFollowers();
    this.fetchTouchPoints(userId, 1, 5);
  }

  socialMediaIcons = (media: any, index: number) => {
    const lowerType = media.social_type.toLowerCase();
    const socialURL =
      media.social_url.startsWith("http://") ||
      media.social_url.startsWith("https://")
        ? media.social_url
        : `http://${media.social_url}`;
    if (lowerType === "facebook") {
      return (
        <a
          href={socialURL}
          target="_blank"
          rel="noopener noreferrer"
          key={index}
        >
          <img style={{ height: "50px" }} src={facebook} alt="facebook" />
        </a>
      );
    } else if (lowerType === "twitter") {
      return (
        <a
          href={socialURL}
          target="_blank"
          rel="noopener noreferrer"
          key={index}
        >
          <img style={{ height: "50px" }} src={Twitter} alt="twitter" />
        </a>
      );
    } else if (lowerType === "linkedin") {
      return (
        <a
          href={socialURL}
          target="_blank"
          rel="noopener noreferrer"
          key={index}
        >
          <img style={{ height: "50px" }} src={linkedin} alt="linkedin" />
        </a>
      );
    } else if (lowerType === "instagram") {
      return (
        <a
          href={socialURL}
          target="_blank"
          rel="noopener noreferrer"
          key={index}
        >
          <img style={{ height: "50px" }} src={instagram} alt="instagram" />
        </a>
      );
    }
    return null;
  };

  getProgressComponent = (userProfileData: any) => {
    if (userProfileData?.is_moderator) {
      return (
        <Box px={3}>
          <Typography
            style={{ textAlign: "right", color: "#071E3D", fontWeight: 500 }}
            id="discrete-slider-custom"
            gutterBottom
          >
            {getDynamicRoomCount(
              this.state?.userProfileData?.room_count_as_moderator_one_month
            ) || 0}
            /5 Rooms
          </Typography>
          <Tutorials
            current={this.context.tutorials.count}
            data={this.context.tutorials.data}
            onNext={this.context.onNextTutorial}
            isOpen={
              this.context.tutorials.isOpen &&
              this.context.tutorials.count === 0
            }
            placement="right"
            {...this.props}
          >
            <Slider
              value={
                ((this.state?.userProfileData
                  ?.room_count_as_moderator_one_month || 0) /
                  5) *
                100
              }
              // getAriaValueText={valuetext}
              aria-labelledby="discrete-slider-custom"
              // step={100}
              valueLabelDisplay="auto"
              // marks={marks}
              className="slider0"
            />
          </Tutorials>
        </Box>
      );
    } else if (userProfileData?.is_superhost) {
      return (
        <Box px={3}>
          <Typography
            style={{ textAlign: "right", color: "#071E3D", fontWeight: 500 }}
            id="discrete-slider-custom"
            gutterBottom
          >
            {getDynamicRoomCount(
              this.state?.userProfileData?.room_count_as_superhost_one_month
            ) || 0}
            /5 Rooms
          </Typography>
          <Tutorials
            current={this.context.tutorials.count}
            data={this.context.tutorials.data}
            onNext={this.context.onNextTutorial}
            isOpen={
              this.context.tutorials.isOpen &&
              this.context.tutorials.count === 0
            }
            placement="right"
            {...this.props}
          >
            <Slider
              value={
                ((this.state?.userProfileData
                  ?.room_count_as_superhost_one_month || 0) /
                  5) *
                100
              }
              // getAriaValueText={valuetext}
              aria-labelledby="discrete-slider-custom"
              // step={100}
              valueLabelDisplay="auto"
              // marks={marks}
              className="slider0"
            />
          </Tutorials>
          {/* <Typography style={{ textAlign: "center", fontSize: "10px" }}>
                        To maintain Superhost status: you must continue creating an average of 5 rooms/month
                    </Typography> */}
        </Box>
      );
    } else {
      return (
        <Box px={3}>
          {/* {Room Progress regular user} */}
          <Typography
            style={{ textAlign: "right", color: "#071E3D", fontWeight: 500 }}
            id="discrete-slider-custom"
            gutterBottom
          >
            {getDynamicRoomCount(
              this.state?.userProfileData?.regular_user_room_count
            ) || 0}
            /5 Rooms
          </Typography>
          <Tutorials
            current={this.context.tutorials.count}
            data={this.context.tutorials.data}
            onNext={this.context.onNextTutorial}
            isOpen={
              this.context.tutorials.isOpen &&
              this.context.tutorials.count === 0
            }
            placement="right"
            {...this.props}
          >
            <Slider
              value={
                ((this.state?.userProfileData?.regular_user_room_count || 0) /
                  5) *
                100
              }
              // getAriaValueText={valuetext}
              aria-labelledby="discrete-slider-custom"
              // step={100}
              valueLabelDisplay="auto"
              // marks={marks}
              className="slider0"
            />
          </Tutorials>
          {/* {Heart Progress regular user} */}
          <Typography
            style={{ textAlign: "right", color: "#071E3D", fontWeight: 500 }}
            id="discrete-slider-custom"
            gutterBottom
          >
            {this.state?.userProfileData?.hearts || 0}/50 Hearts
          </Typography>
          <Slider
            value={((this.state?.userProfileData?.hearts || 0) / 50) * 100}
            // getAriaValueText={valuetext}
            aria-labelledby="discrete-slider-custom"
            // step={100}
            valueLabelDisplay="auto"
            // marks={marks}
            className="slider0"
          />
        </Box>
      );
    }
  };

  renderUserAvatar = () => {
    return (
      <Box style={{ position: "relative" }}>
        <Avatar
          id="profilePic"
          alt={this.state?.userProfileData?.full_name}
          src={this.state.userProfileData?.profile_image}
        />
        {this.state.userProfileData?.is_moderator ? (
          <img
            src={goldenTick}
            style={{
              width: "17px",
              borderRadius: "50%",
              position: "absolute",
              right: "0",
              bottom: "5px",
            }}
          />
        ) : this.state.userProfileData?.is_superhost ? (
          <img
            src={blueTick}
            style={{
              borderRadius: "50%",
              width: "15px",
              height: "15px",
              position: "absolute",
              right: "5px",
              bottom: "3px",
            }}
          />
        ) : null}
      </Box>
    );
  };

  render() {
    const LightTooltip = withStyles(() => ({
      tooltip: {
        backgroundColor: "#fff",
        color: "#cbd3d9",
        fontSize: 14,
        fontWeight: 600,
        right: "50px",
        border: "1px solid #cbd3d9",
      },
    }))(Tooltip);

    const userProfileData = this.state.userProfileData;
    let progressComponent = this.getProgressComponent(userProfileData);

    const futureDate = new Date(
      new Date().getTime() +
        (this.state.userProfileData?.reapply_after || 0) * 24 * 60 * 60 * 1000
    );


    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={false} className="mainContainer">
          <Grid container style={{ height: "100%" }}>
            {/* First Column */}
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              className="scrollbarY left"
            >
              <Box className="column1-main-box profile">
                <Box className="column1-child-box1">
                  <Box
                    className="column1-child-box1-clild1"
                    sx={{
                      px: "20px",
                      py: "20px",
                    }}
                    style={{
                      overflow: "hidden",
                      wordBreak: "break-all",
                    }}
                  >
                    {this.renderUserAvatar()}
                    <Box sx={{ pl: "20px", my: "auto", width: "80%" }}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography id="profile-name">
                          {this.state?.userProfileData?.full_name}
                        </Typography>
                        <Box
                          style={{
                            display: "flex",
                            width: "15%",
                            justifyContent: "flex-end",
                          }}
                        >
                          <img
                            src={dotted}
                            onClick={() => {
                              this.props.navigation.navigate("EditProfile");
                            }}
                            style={{
                              width: "15px",
                              height: "16px",
                              paddingLeft: "-10px",
                              marginRight: "14px",
                              cursor: "pointer",
                            }}
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          fontWeight: 500,
                          flexWrap: "wrap",
                        }}
                      >
                        <div style={{ fontSize: "13px", color: "#ADADAD" }}>
                          {this.state?.userProfileData?.user_name}{" "}
                        </div>
                        {this.state?.userProfileData?.gender ===
                        "Do not wish to disclose" ? null : (
                          <div
                            style={{
                              marginLeft: "10px",
                              fontSize: "13px",
                              color: "#ADADAD",
                            }}
                          >
                            {this.state?.userProfileData?.gender}
                          </div>
                        )}
                        <div
                          style={{
                            marginLeft: "10px",
                            fontSize: "13px",
                            color: "#ADADAD",
                          }}
                        >
                          {this.state?.userProfileData?.age_range?.length &&
                            this.state?.userProfileData?.age_range[0]}
                          {this.state?.userProfileData?.age_range?.length > 0 &&
                            this.state?.userProfileData?.age_range[1] &&
                            "-"}
                          {this.state?.userProfileData?.age_range &&
                            this.state?.userProfileData?.age_range[1]}{" "}
                          yrs
                        </div>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      py: "20px",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Box>
                      <div>
                        <img src={heart} className="profileChildIcon" />{" "}
                      </div>
                      <div className="hearts1">
                        {" "}
                        <Typography style={{ fontSize: "14px" }}>
                          HEARTS
                        </Typography>{" "}
                      </div>
                      <div className="hearts2">
                        <Typography>
                          {this.state?.userProfileData?.hearts}
                        </Typography>{" "}
                      </div>
                    </Box>

                    <Box>
                      <div>
                        {" "}
                        <img src={people} className="profileChildIcon" />{" "}
                      </div>
                      <div className="hearts">
                        {" "}
                        <Typography style={{ fontSize: "14px" }}>
                          TOUCH POINTS
                        </Typography>{" "}
                      </div>
                      <div className="hearts2">
                        <Typography>
                          {this.state?.touchPoints.touchpoints_count}
                        </Typography>
                      </div>
                    </Box>

                    <Box
                      onClick={() => this.props.navigation.navigate("MyWallet")}
                    >
                      <div>
                        {" "}
                        <img src={wallet} className="profileChildIcon" />{" "}
                      </div>
                      <div className="hearts">
                        {" "}
                        <Typography
                          style={{ fontSize: "14px", cursor: "pointer" }}
                        >
                          WALLETS
                        </Typography>{" "}
                      </div>
                      <div className="hearts2">
                        <Typography style={{ cursor: "pointer" }}>
                          {this.state?.userProfileData?.ycoins}
                        </Typography>
                      </div>
                    </Box>
                  </Box>
                </Box>
                <Hidden mdUp>
                  <Grid container item xs={12} className="tabList profile">
                    <Grid
                      item
                      xs={4}
                      // key={ite}
                      className={
                        this.state.tabValue === 0 ? "tab active" : "tab"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ tabValue: 0 });
                      }}
                    >
                      Personal Profile
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      // key={ite}
                      className={
                        this.state.tabValue === 1 ? "tab active" : "tab"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ tabValue: 1 });
                      }}
                    >
                      Public Profile
                    </Grid>
                  </Grid>
                  {this.state.tabValue === 0 && (
                    <>
                      <Box mt={4} pl={1}>
                        <Typography
                          style={{
                            textAlign: "left",
                            fontSize: "22px",
                            fontWeight: 500,
                            color: "#071E3D",
                          }}
                        >
                          Personal Info{" "}
                        </Typography>
                      </Box>
                      <Box mt={2} className="column1-child-box1">
                        <Box
                          className="column1-child-box1-clild1"
                          sx={{
                            mx: "auto",
                            px: "10px",
                            py: "20px",
                          }}
                        >
                          {this.state.userProfileData?.is_moderator ? (
                            <Box
                              pl={2}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: "17px",
                                  fontWeight: 500,
                                  color: "#071E3D",
                                  textAlign: "left",
                                }}
                              >
                                Progress
                              </Typography>
                              <LightTooltip
                                title="Moderator Orientation Module"
                                placement="left"
                              >
                                <div>
                                  <MoreHorizIcon
                                    onClick={() =>
                                      this.props.navigation.navigate(
                                        "ModuleOrientation"
                                      )
                                    }
                                  />
                                </div>
                              </LightTooltip>
                            </Box>
                          ) : (
                            <Box pl={2}>
                              <Typography
                                style={{
                                  fontSize: "17px",
                                  fontWeight: 500,
                                  color: "#071E3D",
                                  textAlign: "left",
                                }}
                              >
                                Progress toward becoming a Superhost
                              </Typography>
                            </Box>
                          )}
                        </Box>

                        <Box
                          sx={{
                            py: "20px",
                          }}
                        >
                          {/* {Moderator User progress bar} */}
                          {progressComponent}

                          {this.state.userProfileData?.is_superhost && (
                            <Box px={3} mt={2}>
                              {/* <Typography style={{ textAlign: "start", fontSize: "10px", color: "rgb(189, 189, 191)", fontWeight: 500 }}>
                                                                You didn't open the required 5 rooms per month. You must open all rooms (insert end of 2 months date)
                                                                to maintain the superhost Status.
                                                            </Typography> */}
                              <Typography
                                style={{
                                  textAlign: "start",
                                  fontSize: "12px",
                                  color: "rgb(189, 189, 191)",
                                  fontWeight: 500,
                                }}
                              >
                                To maintain superhost status : you must continue
                                creating 5 rooms per month.
                              </Typography>
                            </Box>
                          )}
                          {this.state.userProfileData?.is_moderator && (
                            <Box px={3} mt={2}>
                              <Typography
                                style={{
                                  textAlign: "start",
                                  fontSize: "12px",
                                  color: "rgb(189, 189, 191)",
                                  fontWeight: 500,
                                }}
                              >
                                To maintain moderator status : you must continue
                                creating 5 rooms per month.
                              </Typography>
                              <Typography
                                style={{
                                  textAlign: "start",
                                  fontSize: "12px",
                                  paddingTop: "10px",
                                  fontWeight: 500,
                                  color: "rgb(189, 189, 191)",
                                }}
                              >
                                Try to gain 5 hearts per touchpoints in
                                moderator rooms. Current Rating :{" "}
                                {this.state.userProfileData?.moderator_rating ||
                                  0}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>

                      <Box mt={4} className="column1-child-box1">
                        <Box
                          className="column1-child-box1-clild1"
                          sx={{
                            mx: "auto",
                            px: "10px",
                            py: "25px",
                          }}
                        >
                          <Box
                            className="subcategories-box"
                            pl={2}
                            style={{ display: "flex", flexWrap: "wrap" }}
                          >
                            <Typography
                              style={{
                                fontSize: "17px",
                                fontWeight: 500,
                                color: "#071E3D",
                                textAlign: "left",
                              }}
                            >
                              Preferred Subcategories
                            </Typography>
                            <img
                              onClick={() => {
                                this.props.navigation.navigate("EditProfile");
                              }}
                              src={dotted}
                              className="penIcon2"
                            />
                          </Box>
                        </Box>

                        <Box
                          px={3}
                          sx={{
                            py: "20px",
                            // color: '#707070',
                            fontSize: "18px",
                            color: "#858585",
                            fontWeight: 500,
                            textAlign: "left",
                          }}
                        >
                          {this.state.userProfileData?.user_sub_categories?.map(
                            (item: any, index: number) => {
                              return (
                                <Typography
                                  key={index}
                                  style={{
                                    color: "#777777",
                                    marginTop: index != 0 ? "10px" : "auto",
                                  }}
                                >
                                  {item?.name}
                                </Typography>
                              );
                            }
                          )}
                        </Box>
                      </Box>

                      <Box mt={4} className="column1-child-box1">
                        <Box
                          className="column1-child-box1-clild1"
                          sx={{
                            mx: "auto",
                            px: "10px",
                            py: "25px",
                          }}
                        >
                          <Box
                            className="subcategories-box"
                            pl={2}
                            style={{ display: "flex" }}
                          >
                            <Typography
                              style={{
                                fontSize: "17px",
                                fontWeight: 500,
                                color: "#071E3D",
                                textAlign: "left",
                              }}
                            >
                              Basic Information
                            </Typography>
                            <img
                              onClick={() => {
                                this.props.navigation.navigate("EditProfile");
                              }}
                              src={dotted}
                              className="penIcon2"
                            />
                          </Box>
                        </Box>

                        <Box
                          px={3}
                          sx={{
                            py: "20px",
                            color: "#8E8E8E",
                            fontWeight: 500,
                            textAlign: "left",
                            fontSize: "12px",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: 500,
                              color: "#858585",
                            }}
                          >
                            Phone Number{" "}
                          </Typography>
                          <Typography
                            style={{
                              marginTop: "2px",
                              color: "#010039",
                              fontWeight: 500,
                            }}
                          >
                            {
                              this.state?.userProfileData?.country_details
                                ?.dial_code
                            }
                            -{this.state?.userProfileData?.phone_number}
                          </Typography>
                          <Typography
                            style={{
                              marginTop: "10px",
                              fontSize: "14px",
                              fontWeight: 500,
                              color: "#858585",
                            }}
                          >
                            Email{" "}
                          </Typography>
                          <Typography
                            style={{
                              marginTop: "2px",
                              color: "#010039",
                              fontWeight: 500,
                            }}
                          >
                            {this.state?.userProfileData?.email}{" "}
                          </Typography>
                          <Typography
                            style={{
                              marginTop: "10px",
                              fontSize: "14px",
                              fontWeight: 500,
                              color: "#858585",
                            }}
                          >
                            Year of Birth{" "}
                          </Typography>
                          <Typography
                            style={{
                              marginTop: "2px",
                              color: "#010039",
                              fontWeight: 500,
                            }}
                          >
                            {this.state?.userProfileData?.birth_year}{" "}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        mt={4}
                        className="column1-child-box1"
                        style={{ border: "none" }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            className="touch-points"
                            style={{ fontWeight: 500 }}
                          >
                            Touch Points{" "}
                          </Typography>
                          <Typography
                            className="touch-points"
                            style={{ fontWeight: 500 }}
                            onClick={() =>
                              this.props.navigation.navigate("TouchPointList")
                            }
                          >
                            See All
                          </Typography>
                        </Box>
                      </Box>

                      <Box mt={1} py={2} className="column1-child-box1">
                        {this.state.touchPoints.touchpoints?.data?.length > 0 &&
                          this.state.touchPoints.touchpoints.data.map(
                            (item: any, index: number) => {
                              return (
                                <TouchUser
                                  item={item}
                                  index={index}
                                  sendFriendRequest={this.sendConnectRequest}
                                />
                              );
                            }
                          )}

                        {this.state.touchPoints.touchpoints?.data?.length ==
                          0 && (
                          <Box
                            px={2}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Typography>No touch Points found.</Typography>
                          </Box>
                        )}
                      </Box>
                    </>
                  )}
                  {this.state.tabValue === 1 && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      className="mainMiddle"
                    >
                      <Typography
                        style={{
                          textAlign: "left",
                          fontSize: "22px",
                          fontWeight: 500,
                          color: "#071E3D",
                        }}
                      >
                        Public Info{" "}
                      </Typography>
                      {this.state.userProfileData?.is_moderator && (
                        <>
                          {/* ================= Followers , Rooms Hosted and Social media Icons =======================*/}
                          <Box
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              justifyContent: "space-around",
                              flexWrap: "wrap",
                            }}
                          >
                            <div className="mainTopStatistics">
                              <Typography className="label">
                                FOLLOWERS
                              </Typography>
                              <Typography className="count">
                                {this.state?.ownFollowers?.length || 0}
                              </Typography>
                            </div>
                            <div className="mainTopStatistics">
                              <Typography className="label">
                                ROOMS HOSTED
                              </Typography>
                              <Typography className="count">
                                {this.state?.userProfileData
                                  ?.total_room_count || 0}
                              </Typography>
                            </div>
                            {this.state?.userProfileData?.moderator_profile
                              ?.length > 0 &&
                              this.state?.userProfileData?.moderator_profile[0]?.social_accounts?.map(
                                this.socialMediaIcons
                              )}
                          </Box>

                          {/* Bio Box */}
                          <Box
                            className="middle-box"
                            mt={2}
                            // pb={0.5}
                            px={3}
                            pt={3}
                            style={{
                              overflow: "hidden",
                              wordBreak: "break-all",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography id="middel-box-lable">Bio</Typography>
                              <Box
                                style={{
                                  display: "flex",
                                  width: "15%",
                                  justifyContent: "flex-end",
                                }}
                                onClick={() => {
                                  this.props.navigation.navigate("EditProfile");
                                }}
                              >
                                <img src={dotted} className="penIcon" />
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                my: "20px",
                              }}
                            >
                              <Box>
                                <span
                                  style={{
                                    width: "25px",
                                    fontSize: "18px",
                                    color: "#858585",
                                    fontWeight: 500,
                                  }}
                                >
                                  {this.state?.userProfileData
                                    ?.moderator_profile?.length > 0 &&
                                    this.state?.userProfileData
                                      ?.moderator_profile[0]?.bio}
                                </span>
                              </Box>
                            </Box>
                          </Box>

                          {/* Certification Box */}
                          {this.state?.userProfileData?.moderator_profile
                            ?.length > 0 &&
                            this.state?.userProfileData?.moderator_profile[0]
                              ?.educational_qualifications.length > 0 && (
                              <Box
                                className="middle-box"
                                mt={2}
                                // pb={0.5}
                                px={3}
                                pt={3}
                                style={{
                                  overflow: "hidden",
                                  wordBreak: "break-all",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography id="middel-box-lable">
                                    Certification
                                  </Typography>
                                  <Box
                                    style={{
                                      display: "flex",
                                      width: "15%",
                                      justifyContent: "flex-end",
                                    }}
                                    onClick={() => {
                                      this.props.navigation.navigate(
                                        "EditProfile"
                                      );
                                    }}
                                  >
                                    <img src={dotted} className="penIcon" />
                                  </Box>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    my: "20px",
                                  }}
                                >
                                  <Box>
                                    <span
                                      style={{
                                        width: "25px",
                                        fontSize: "18px",
                                        color: "#858585",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {this.state?.userProfileData
                                        ?.moderator_profile?.length > 0 &&
                                        this.state?.userProfileData?.moderator_profile[0]?.educational_qualifications?.map(
                                          (detail: any) => {
                                            return (
                                              <>
                                                {detail?.courses?.map(
                                                  (detail: any) => {
                                                    return (
                                                      <div>{detail.name}</div>
                                                    );
                                                  }
                                                )}
                                              </>
                                            );
                                          }
                                        )}
                                    </span>
                                  </Box>
                                </Box>
                              </Box>
                            )}

                          {/* Document Box */}
                          {this.state?.userProfileData?.moderator_profile
                            ?.length > 0 &&
                            this.state?.userProfileData?.moderator_profile[0]
                              ?.educational_qualifications.length > 0 && (
                              <Box
                                className="middle-box"
                                mt={2}
                                // pb={0.5}
                                px={3}
                                pt={3}
                                style={{
                                  overflow: "hidden",
                                  wordBreak: "break-all",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography id="middel-box-lable">
                                    Documents
                                  </Typography>
                                  <Box
                                    style={{
                                      display: "flex",
                                      width: "15%",
                                      justifyContent: "flex-end",
                                    }}
                                    onClick={() => {
                                      this.props.navigation.navigate(
                                        "EditProfile"
                                      );
                                    }}
                                  >
                                    <img src={dotted} className="penIcon" />
                                  </Box>
                                </Box>
                                <Box
                                  sx={{
                                    my: "20px",
                                  }}
                                >
                                  <Box className="documentsBox">
                                    {this.state?.userProfileData
                                      ?.moderator_profile?.length > 0 &&
                                      this.state.userProfileData.moderator_profile[0]?.educational_qualifications?.map(
                                        (detail: any, index: number) => {
                                          return detail?.courses?.map(
                                            (detail: any) => {
                                              return (
                                                <>
                                                  <span className="documentLinks">
                                                    <a
                                                      href={detail.document[0]}
                                                      target="_blank"
                                                      style={{
                                                        fontSize: "14px",
                                                        textDecoration: "none",
                                                        width: "100%",
                                                      }}
                                                    >
                                                      {
                                                        detail.document[0]
                                                          .split("/")
                                                          .slice(-1)[0]
                                                      }
                                                    </a>
                                                  </span>
                                                </>
                                              );
                                            }
                                          );
                                        }
                                      )}
                                  </Box>
                                </Box>
                              </Box>
                            )}

                          {/* Experience Box */}
                          {this.state?.userProfileData?.moderator_profile
                            ?.length > 0 &&
                            this.state?.userProfileData?.moderator_profile[0]
                              ?.personal_exp && (
                              <Box
                                className="middle-box"
                                mt={2}
                                // pb={0.5}
                                px={3}
                                pt={3}
                                style={{
                                  overflow: "hidden",
                                  wordBreak: "break-all",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography id="middel-box-lable">
                                    Personal Experiences
                                  </Typography>
                                  <Box
                                    style={{
                                      display: "flex",
                                      width: "15%",
                                      justifyContent: "flex-end",
                                    }}
                                    onClick={() => {
                                      this.props.navigation.navigate(
                                        "EditProfile"
                                      );
                                    }}
                                  >
                                    <img
                                      src={dotted}
                                      className="penIcon"
                                      onClick={() => {
                                        this.props.navigation.navigate(
                                          "EditProfile"
                                        );
                                      }}
                                    />
                                  </Box>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    my: "20px",
                                  }}
                                >
                                  <Box>
                                    <span
                                      style={{
                                        width: "25px",
                                        fontSize: "20px",
                                        color: "#858585",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {this.state?.userProfileData
                                        ?.moderator_profile?.length > 0 &&
                                        this.state?.userProfileData
                                          ?.moderator_profile[0]?.personal_exp}
                                    </span>
                                  </Box>
                                </Box>
                              </Box>
                            )}

                          {/* subcategories Box */}
                          <Box
                            className="middle-box"
                            mt={2}
                            // pb={0.5}
                            px={3}
                            pt={3}
                            style={{
                              overflow: "hidden",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography id="middel-box-lable">
                                Preferred Sub Categories for Moderator
                              </Typography>
                              <Box
                                style={{
                                  display: "flex",
                                  width: "15%",
                                  justifyContent: "flex-end",
                                }}
                                onClick={() => {
                                  this.props.navigation.navigate("EditProfile");
                                }}
                              >
                                <img src={dotted} className="penIcon" />
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                my: "20px",
                              }}
                            >
                              <Box
                                style={{
                                  flexWrap: "wrap",
                                  display: "flex",
                                  justifyContent: "space-around",
                                }}
                              >
                                {this.state?.userProfileData?.moderator_user_sub_categories?.map(
                                  (Item: any, index: number) => {
                                    return (
                                      <span
                                        key={index}
                                        style={{
                                          fontSize: "18px",
                                          width: "100%",
                                          fontWeight: 500,
                                          color: "#707070",
                                          display: "flex",
                                          marginRight: "25px",
                                          paddingBottom: "4px",
                                          borderBottom: " 1px solid #fafafa",
                                        }}
                                      >
                                        {Item.name}

                                        {/* {
                                                                            (index != this.state?.userProfileData?.moderator_user_sub_categories?.length - 1) ?
                                                                                <Box ml={3} style={{ opacity: '0.3' }} >&#124;</Box> : null
                                                                        } */}
                                      </span>
                                    );
                                  }
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </>
                      )}

                      {/* Country Box */}
                      <Box
                        className="middle-box"
                        mt={2}
                        // pb={0.5}
                        px={3}
                        pt={3}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography id="middel-box-lable">Country</Typography>
                          <Box
                            style={{
                              display: "flex",
                              width: "15%",
                              justifyContent: "flex-end",
                            }}
                          ></Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            my: "20px",
                          }}
                        >
                          <Box>
                            {" "}
                            <span style={{ width: "25px", fontSize: "20px" }}>
                              {
                                this.state?.userProfileData?.country_details
                                  ?.flag
                              }
                            </span>{" "}
                          </Box>
                          <Box
                            ml={2}
                            style={{
                              marginLeft: "12px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              id="middel-box-text"
                              style={{ fontSize: "18px" }}
                            >
                              {
                                this.state?.userProfileData?.country_details
                                  ?.name
                              }{" "}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>

                      {/* Onboarding questions and Answers */}
                      {this.state.userProfileData &&
                        this.state?.userProfileData?.onboarding_details &&
                        this.state?.userProfileData?.onboarding_details
                          ?.sort((a: any, b: any) => a.level - b.level)
                          .map((item: any, i: number) => (
                            <Box
                              className="middle-box"
                              mt={4}
                              key={i}
                              // pb={1}
                              px={3}
                              pt={3}
                              style={{
                                overflow: "hidden",
                                wordBreak: "break-all",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography id="middel-box-lable">
                                  {/* 3 words your friends would describe you */}
                                  {item?.question}
                                </Typography>
                                <Box
                                  style={{
                                    display: "flex",
                                    width: "15%",
                                    justifyContent: "flex-end",
                                  }}
                                  onClick={() => {
                                    this.props.navigation.navigate(
                                      "EditProfile"
                                    );
                                  }}
                                >
                                  <img src={dotted} className="penIcon" />
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  my: "20px",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  {item?.answers?.map(
                                    (Item: any, index: number) => {
                                      return (
                                        <span
                                          key={index}
                                          style={{
                                            fontSize: "18px",
                                            width: "100%",
                                            fontWeight: 500,
                                            color: "#707070",
                                            display: "flex",
                                            marginRight: "25px",
                                            paddingBottom: "4px",
                                            borderBottom: " 1px solid #fafafa",
                                          }}
                                        >
                                          {Item}
                                          {/*             
                                                                    {
                                                                        (index != item?.answers.length - 1) ?
                                                                            <Box ml={3} style={{ opacity: '0.3' }} >&#124;</Box> : null
                                                                    } */}
                                        </span>
                                      );
                                    }
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          ))}
                    </Grid>
                  )}
                </Hidden>
                <Hidden smDown>
                  <Box mt={4} pl={1}>
                    <Typography
                      style={{
                        textAlign: "left",
                        fontSize: "22px",
                        fontWeight: 500,
                        color: "#071E3D",
                      }}
                    >
                      Personal Info{" "}
                    </Typography>
                  </Box>
                  <Box mt={2} className="column1-child-box1">
                    <Box
                      className="column1-child-box1-clild1"
                      sx={{
                        mx: "auto",
                        px: "10px",
                        py: "20px",
                      }}
                    >
                      {this.state.userProfileData?.is_moderator ? (
                        <Box
                          pl={2}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "17px",
                              fontWeight: 500,
                              color: "#071E3D",
                              textAlign: "left",
                            }}
                          >
                            Progress
                          </Typography>
                          <LightTooltip
                            title="Moderator Orientation Module"
                            placement="left"
                          >
                            <div style={{ cursor: "pointer" }}>
                              <MoreHorizIcon
                                onClick={() =>
                                  this.props.navigation.navigate(
                                    "ModuleOrientation"
                                  )
                                }
                              />
                            </div>
                          </LightTooltip>
                        </Box>
                      ) : (
                        <Box pl={2}>
                          <Typography
                            style={{
                              fontSize: "17px",
                              fontWeight: 500,
                              color: "#071E3D",
                              textAlign: "left",
                            }}
                          >
                            Progress toward becoming a Superhost
                          </Typography>
                        </Box>
                      )}
                    </Box>

                    <Box
                      sx={{
                        py: "20px",
                      }}
                    >
                      {/* {Moderator User progress bar} */}
                      {progressComponent}
                      {this.state.userProfileData?.is_superhost && (
                        <Box px={3} mt={2}>
                          {/* <Typography style={{ textAlign: "start", fontSize: "10px", color: "rgb(189, 189, 191)", fontWeight: 500 }}>
                                                        You didn't open the required 5 rooms per month. You must open all rooms (insert end of 2 months date)
                                                        to maintain the superhost Status.
                                                    </Typography> */}
                          <Typography
                            style={{
                              textAlign: "start",
                              fontSize: "12px",
                              color: "rgb(189, 189, 191)",
                              fontWeight: 500,
                            }}
                          >
                            To maintain superhost status : you must continue
                            creating 5 rooms per month.
                          </Typography>
                        </Box>
                      )}
                      {this.state.userProfileData?.is_moderator && (
                        <Box px={3} mt={2}>
                          <Typography
                            style={{
                              textAlign: "start",
                              fontSize: "12px",
                              color: "rgb(189, 189, 191)",
                              fontWeight: 500,
                            }}
                          >
                            To maintain moderator status : you must continue
                            creating 5 rooms per month.
                          </Typography>
                          <Typography
                            style={{
                              textAlign: "start",
                              fontSize: "12px",
                              paddingTop: "10px",
                              fontWeight: 500,
                              color: "rgb(189, 189, 191)",
                            }}
                          >
                            Try to gain 5 hearts per touchpoints in moderator
                            rooms. Current Rating :{" "}
                            {this.state.userProfileData?.moderator_rating || 0}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>

                  <Box mt={4} className="column1-child-box1">
                    <Box
                      className="column1-child-box1-clild1"
                      sx={{
                        mx: "auto",
                        px: "10px",
                        py: "25px",
                      }}
                    >
                      <Box
                        className="subcategories-box"
                        pl={2}
                        style={{ display: "flex" }}
                      >
                        <Typography
                          style={{
                            fontSize: "17px",
                            fontWeight: 500,
                            color: "#071E3D",
                            textAlign: "left",
                          }}
                        >
                          Preferred Subcategories
                        </Typography>
                        <img
                          onClick={() => {
                            this.props.navigation.navigate("EditProfile");
                          }}
                          src={dotted}
                          className="penIcon2"
                        />
                      </Box>
                    </Box>

                    <Box
                      px={3}
                      sx={{
                        py: "20px",
                        // color: '#707070',
                        fontSize: "18px",
                        color: "#858585",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {this.state.userProfileData?.user_sub_categories?.map(
                        (item: any, index: number) => {
                          return (
                            <Typography
                              key={index}
                              style={{
                                color: "#777777",
                                marginTop: index != 0 ? "10px" : "auto",
                              }}
                            >
                              {item?.name}
                            </Typography>
                          );
                        }
                      )}
                    </Box>
                  </Box>

                  <Box mt={4} className="column1-child-box1">
                    <Box
                      className="column1-child-box1-clild1"
                      sx={{
                        mx: "auto",
                        px: "10px",
                        py: "25px",
                      }}
                    >
                      <Box
                        className="subcategories-box"
                        pl={2}
                        style={{ display: "flex" }}
                      >
                        <Typography
                          style={{
                            fontSize: "17px",
                            fontWeight: 500,
                            color: "#071E3D",
                            textAlign: "left",
                          }}
                        >
                          Basic Information
                        </Typography>
                        <img
                          onClick={() => {
                            this.props.navigation.navigate("EditProfile");
                          }}
                          src={dotted}
                          className="penIcon2"
                        />
                      </Box>
                    </Box>

                    <Box
                      px={3}
                      sx={{
                        py: "20px",
                        color: "#8E8E8E",
                        fontWeight: 500,
                        textAlign: "left",
                        fontSize: "12px",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: 500,
                          color: "#858585",
                        }}
                      >
                        Phone Number{" "}
                      </Typography>
                      <Typography
                        style={{
                          marginTop: "2px",
                          color: "#010039",
                          fontWeight: 500,
                        }}
                      >
                        {
                          this.state?.userProfileData?.country_details
                            ?.dial_code
                        }
                        -{this.state?.userProfileData?.phone_number}
                      </Typography>
                      <Typography
                        style={{
                          marginTop: "10px",
                          fontSize: "14px",
                          fontWeight: 500,
                          color: "#858585",
                        }}
                      >
                        Email{" "}
                      </Typography>
                      <Typography
                        style={{
                          marginTop: "2px",
                          color: "#010039",
                          fontWeight: 500,
                        }}
                      >
                        {this.state?.userProfileData?.email}{" "}
                      </Typography>
                      <Typography
                        style={{
                          marginTop: "10px",
                          fontSize: "14px",
                          fontWeight: 500,
                          color: "#858585",
                        }}
                      >
                        Year of Birth{" "}
                      </Typography>
                      <Typography
                        style={{
                          marginTop: "2px",
                          color: "#010039",
                          fontWeight: 500,
                        }}
                      >
                        {this.state?.userProfileData?.birth_year}{" "}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    mt={4}
                    className="column1-child-box1"
                    style={{ border: "none" }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        className="touch-points"
                        style={{ fontWeight: 500 }}
                      >
                        Touch Points{" "}
                      </Typography>
                      <Typography
                        className="touch-points"
                        style={{ fontWeight: 500 }}
                        onClick={() =>
                          this.props.navigation.navigate("TouchPointList")
                        }
                      >
                        See All
                      </Typography>
                    </Box>
                  </Box>

                  <Box mt={1} py={2} className="column1-child-box1">
                    {this.state.touchPoints.touchpoints?.data?.length > 0 &&
                      this.state.touchPoints.touchpoints.data.map(
                        (item: any, index: number) => {
                          return (
                            <TouchUser
                              item={item}
                              index={index}
                              sendFriendRequest={this.sendConnectRequest}
                            />
                          );
                        }
                      )}

                    {this.state.touchPoints.touchpoints?.data?.length == 0 && (
                      <Box
                        px={2}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography>No touch Points found.</Typography>
                      </Box>
                    )}
                  </Box>
                </Hidden>
              </Box>
            </Grid>

            {/* Center Column */}
            <AlertBlock
              data={{
                show: this.state.isMessagePop,
                close: this.handleClose,
                msg: this.msg,
                type: this.type,
                vertical: "top",
                horizontal: "center",
              }}
            />
            <Hidden smDown>
              <Grid item xs={12} sm={12} md={6} lg={6} className="mainMiddle">
                <Typography
                  style={{
                    fontWeight: 500,
                    color: "#010039",
                    fontSize: "22px",
                  }}
                >
                  Public Info
                </Typography>

                {this.state.userProfileData?.is_moderator && (
                  <>
                    {/* ================= Followers , Rooms Hosted and Social media Icons =======================*/}
                    <Box
                      style={{
                        marginTop: "20px",
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          style={{
                            fontSize: "15px",
                            color: "rgb(189, 189, 191)",
                            fontWeight: 500,
                          }}
                        >
                          FOLLOWERS
                        </Typography>
                        <Typography
                          style={{
                            fontWeight: "bold",
                            paddingTop: "10px",
                            paddingLeft: "20px",
                          }}
                        >
                          {this.state?.ownFollowers?.length || 0}
                        </Typography>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          style={{
                            fontSize: "15px",
                            color: "rgb(189, 189, 191)",
                            fontWeight: 500,
                          }}
                        >
                          ROOMS HOSTED
                        </Typography>
                        <Typography
                          style={{
                            fontWeight: "bold",
                            paddingTop: "10px",
                            paddingLeft: "30px",
                          }}
                        >
                          {this.state?.userProfileData?.total_room_count || 0}
                        </Typography>
                      </div>

                      {this.state?.userProfileData?.moderator_profile?.length >
                        0 &&
                        this.state?.userProfileData?.moderator_profile[0]?.social_accounts?.map(
                          this.socialMediaIcons
                        )}
                    </Box>

                    {/* Bio Box */}
                    <Box
                      className="middle-box"
                      mt={2}
                      // pb={0.5}
                      px={3}
                      pt={3}
                      style={{
                        overflow: "hidden",
                        wordBreak: "break-all",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography id="middel-box-lable">Bio</Typography>
                        <Box
                          style={{
                            display: "flex",
                            width: "15%",
                            justifyContent: "flex-end",
                          }}
                          onClick={() => {
                            this.props.navigation.navigate("EditProfile");
                          }}
                        >
                          <img src={dotted} className="penIcon" />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          my: "20px",
                        }}
                      >
                        <Box>
                          <span
                            style={{
                              width: "25px",
                              fontSize: "18px",
                              color: "#858585",
                              fontWeight: 500,
                            }}
                          >
                            {this.state?.userProfileData?.moderator_profile
                              ?.length > 0 &&
                              this.state?.userProfileData?.moderator_profile[0]
                                ?.bio}
                          </span>
                        </Box>
                      </Box>
                    </Box>

                    {/* Certification Box */}
                    {this.state?.userProfileData?.moderator_profile?.length >
                      0 &&
                      this.state?.userProfileData?.moderator_profile[0]
                        ?.educational_qualifications.length > 0 && (
                        <Box
                          className="middle-box"
                          mt={2}
                          // pb={0.5}
                          px={3}
                          pt={3}
                          style={{
                            overflow: "hidden",
                            wordBreak: "break-all",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography id="middel-box-lable">
                              Certification
                            </Typography>
                            <Box
                              style={{
                                display: "flex",
                                width: "15%",
                                justifyContent: "flex-end",
                              }}
                              onClick={() => {
                                this.props.navigation.navigate("EditProfile");
                              }}
                            >
                              <img src={dotted} className="penIcon" />
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              my: "20px",
                            }}
                          >
                            <Box>
                              <span
                                style={{
                                  width: "25px",
                                  fontSize: "18px",
                                  color: "#858585",
                                  fontWeight: 500,
                                }}
                              >
                                {this.state?.userProfileData?.moderator_profile
                                  ?.length > 0 &&
                                  this.state?.userProfileData?.moderator_profile[0]?.educational_qualifications?.map(
                                    (detail: any) => {
                                      return (
                                        <>
                                          {detail?.courses?.map(
                                            (detail: any) => {
                                              return <div>{detail.name}</div>;
                                            }
                                          )}
                                        </>
                                      );
                                    }
                                  )}
                              </span>
                            </Box>
                          </Box>
                        </Box>
                      )}

                    {/* Document Box */}
                    {this.state?.userProfileData?.moderator_profile?.length >
                      0 &&
                      this.state?.userProfileData?.moderator_profile[0]
                        ?.educational_qualifications.length > 0 && (
                        <Box
                          className="middle-box"
                          mt={2}
                          // pb={0.5}
                          px={3}
                          pt={3}
                          style={{
                            overflow: "hidden",
                            wordBreak: "break-all",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography id="middel-box-lable">
                              Documents
                            </Typography>
                            <Box
                              style={{
                                display: "flex",
                                width: "15%",
                                justifyContent: "flex-end",
                              }}
                              onClick={() => {
                                this.props.navigation.navigate("EditProfile");
                              }}
                            >
                              <img src={dotted} className="penIcon" />
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              my: "20px",
                            }}
                          >
                            <Box className="documentsBox">
                              {this.state?.userProfileData?.moderator_profile
                                ?.length > 0 &&
                                this.state.userProfileData.moderator_profile[0]?.educational_qualifications?.map(
                                  (detail: any, index: number) => {
                                    return detail?.courses?.map(
                                      (detail: any) => {
                                        return (
                                          <>
                                            <span className="documentLinks">
                                              <a
                                                href={detail.document[0]}
                                                target="_blank"
                                                style={{
                                                  fontSize: "14px",
                                                  textDecoration: "none",
                                                  width: "100%",
                                                }}
                                              >
                                                {
                                                  detail.document[0]
                                                    .split("/")
                                                    .slice(-1)[0]
                                                }
                                              </a>
                                            </span>
                                          </>
                                        );
                                      }
                                    );
                                  }
                                )}
                            </Box>
                          </Box>
                        </Box>
                      )}

                    {/* Experience Box */}
                    {this.state?.userProfileData?.moderator_profile?.length >
                      0 &&
                      this.state?.userProfileData?.moderator_profile[0]
                        ?.personal_exp && (
                        <Box
                          className="middle-box"
                          mt={2}
                          // pb={0.5}
                          px={3}
                          pt={3}
                          style={{
                            overflow: "hidden",
                            wordBreak: "break-all",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography id="middel-box-lable">
                              Personal Experiences
                            </Typography>
                            <Box
                              style={{
                                display: "flex",
                                width: "15%",
                                justifyContent: "flex-end",
                              }}
                              onClick={() => {
                                this.props.navigation.navigate("EditProfile");
                              }}
                            >
                              <img
                                src={dotted}
                                className="penIcon"
                                onClick={() => {
                                  this.props.navigation.navigate("EditProfile");
                                }}
                              />
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              my: "20px",
                            }}
                          >
                            <Box>
                              <span
                                style={{
                                  width: "25px",
                                  fontSize: "20px",
                                  color: "#858585",
                                  fontWeight: 500,
                                }}
                              >
                                {this.state?.userProfileData?.moderator_profile
                                  ?.length > 0 &&
                                  this.state?.userProfileData
                                    ?.moderator_profile[0]?.personal_exp}
                              </span>
                            </Box>
                          </Box>
                        </Box>
                      )}

                    {/* subcategories Box */}
                    <Box
                      className="middle-box"
                      mt={2}
                      // pb={0.5}
                      px={3}
                      pt={3}
                      style={{
                        overflow: "hidden",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography id="middel-box-lable">
                          Preferred Sub Categories for Moderator
                        </Typography>
                        <Box
                          style={{
                            display: "flex",
                            width: "15%",
                            justifyContent: "flex-end",
                          }}
                          onClick={() => {
                            this.props.navigation.navigate("EditProfile");
                          }}
                        >
                          <img src={dotted} className="penIcon" />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          my: "20px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                          }}
                        >
                          {this.state?.userProfileData?.moderator_user_sub_categories?.map(
                            (Item: any, index: number) => {
                              return (
                                <span
                                  key={index}
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: 500,
                                    color: "#707070",
                                    display: "flex",
                                    marginRight: "25px",
                                  }}
                                >
                                  {Item.name}

                                  {index !=
                                  this.state?.userProfileData
                                    ?.moderator_user_sub_categories?.length -
                                    1 ? (
                                    <Box ml={3} style={{ opacity: "0.3" }}>
                                      &#124;
                                    </Box>
                                  ) : null}
                                </span>
                              );
                            }
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </>
                )}

                {/* Country Box */}
                <Box
                  className="middle-box"
                  mt={2}
                  // pb={0.5}
                  px={3}
                  pt={3}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography id="middel-box-lable">Country</Typography>
                    <Box
                      style={{
                        display: "flex",
                        width: "15%",
                        justifyContent: "flex-end",
                      }}
                    ></Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      my: "20px",
                    }}
                  >
                    <Box>
                      {" "}
                      <span style={{ width: "25px", fontSize: "20px" }}>
                        {this.state?.userProfileData?.country_details?.flag}
                      </span>{" "}
                    </Box>
                    <Box
                      ml={2}
                      style={{
                        marginLeft: "12px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        id="middel-box-text"
                        style={{ fontSize: "18px" }}
                      >
                        {this.state?.userProfileData?.country_details?.name}{" "}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                {/* Onboarding questions and Answers */}
                {this.state.userProfileData &&
                  this.state?.userProfileData?.onboarding_details &&
                  this.state?.userProfileData?.onboarding_details
                    ?.sort((a: any, b: any) => a.level - b.level)
                    .map((item: any, i: number) => (
                      <Box
                        className="middle-box"
                        mt={4}
                        key={i}
                        // pb={1}
                        px={3}
                        pt={3}
                        style={{
                          overflow: "hidden",
                          wordBreak: "break-all",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography id="middel-box-lable">
                            {/* 3 words your friends would describe you */}
                            {item?.question}
                          </Typography>
                          <Box
                            style={{
                              display: "flex",
                              width: "15%",
                              justifyContent: "flex-end",
                            }}
                            onClick={() => {
                              this.props.navigation.navigate("EditProfile");
                            }}
                          >
                            <img src={dotted} className="penIcon" />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            my: "20px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                            }}
                          >
                            {item?.answers?.map((Item: any, index: number) => {
                              return (
                                <span
                                  key={index}
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: 500,
                                    color: "#707070",
                                    display: "flex",
                                    marginRight: "25px",
                                  }}
                                >
                                  {Item}

                                  {index != item?.answers.length - 1 ? (
                                    <Box ml={3} style={{ opacity: "0.3" }}>
                                      &#124;
                                    </Box>
                                  ) : null}
                                </span>
                              );
                            })}
                          </Box>
                        </Box>
                      </Box>
                    ))}
              </Grid>
            </Hidden>

            {/* Last Column */}

            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              className="scrollbarY right"
            >
              {!JSON.parse(localStorage.getItem("isModerator") as string) ? (
                <Tutorials
                  current={this.context.tutorials.count}
                  data={this.context.tutorials.data}
                  onNext={this.context.onNextTutorial}
                  isOpen={
                    this.context.tutorials.isOpen &&
                    this.context.tutorials.count === 1
                  }
                  placement="left"
                  {...this.props}
                >
                  <Box
                    pl={1}
                    mt={1}
                    className="right-side-btn-div"
                    style={{ textAlign: "left" }}
                  >
                    <Button
                      className="right-side-btn"
                      style={{ background: "#fff" }}
                      variant="outlined"
                    >
                      <Typography
                        style={{
                          color: "#010039",
                          fontWeight: 500,
                          textAlign: "left",
                        }}
                        onClick={() => {
                          if (this.state.userProfileData?.reapply_after > 0)
                            this.setState({ deniedOpen: true });
                          else
                            this.props.navigation.navigate("ModuleOrientation");
                        }}
                      >
                        Become a Moderator
                      </Typography>
                    </Button>
                  </Box>
                </Tutorials>
              ) : (
                ""
              )}

              {/* <Box pl={1} mt={4} className="right-side-btn-div" >
                                <Button className="right-side-btn" style={{ background: "#fff" }} variant="outlined" >
                                    <Typography style={{ color: "#010039", fontWeight: 500, textAlign: "left", width: "100%" }} >Refer a Friend</Typography>
                                </Button>
                            </Box> */}

              <Box mt={5} px={2}>
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: 500,
                    color: "#010039",
                    fontSize: "18px",
                  }}
                >
                  {" "}
                  Resources{" "}
                </Typography>
              </Box>
              <Box
                mt={1}
                ml={1}
                className="column1-child-box1 column2-child-box2"
              >
                {this.state?.userProfileData?.room_resources?.length > 0 ? (
                  this.state?.userProfileData?.room_resources.map(
                    (resource: any) => {
                      if (resource?.resource_urls.length > 0) {
                        return resource?.resource_urls?.map(
                          (item: any, index: number) => {
                            const filterLikeUserId = item?.user_ids.find(
                              (id: any) =>
                                id === Number(localStorage.getItem("userId"))
                            );
                            if (item?.url?.length > 0) {
                              return (
                                <Box
                                  style={{
                                    borderBottom: "0.1px solid #dbd8d8",
                                  }}
                                  pb={2}
                                  key={item?.id || index}
                                >
                                  <Box px={2} pt={2}>
                                    <Typography
                                      style={{
                                        fontWeight: 500,
                                        color: "#9C9C9C",
                                        fontSize: "13px",
                                      }}
                                    >
                                      <span>{resource?.category_name}</span>
                                      <ChevronRightIcon
                                        style={{
                                          height: "18px",
                                          position: "relative",
                                          top: "5px",
                                        }}
                                      />
                                      <span>{resource?.sub_category_name}</span>
                                      <ChevronRightIcon
                                        style={{
                                          height: "18px",
                                          position: "relative",
                                          top: "5px",
                                        }}
                                      />
                                      <span>{resource?.topic_name}</span>

                                      {/* {"Relationship > Starting a romantic relationship > What is love ?"}  */}
                                    </Typography>
                                  </Box>
                                  <Box px={2} pt={1}>
                                    <Typography
                                      style={{
                                        fontWeight: 500,
                                        color: "#010039",
                                        fontSize: "16px",
                                        wordBreak: "break-all",
                                      }}
                                    >
                                      <a
                                        href={"https://" + item?.url}
                                        target="_blank"
                                        style={{
                                          color: "#010138",
                                          fontWeight: 500,
                                        }}
                                      >
                                        {item?.url}
                                      </a>
                                    </Typography>
                                  </Box>

                                  <Box
                                    px={2}
                                    pt={2}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box style={{ display: "flex" }}>
                                      {filterLikeUserId ? (
                                        <ThumbUpIcon
                                          style={{
                                            fontSize: "20px",
                                            color: "#0b1275",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            this.deleteLikeResource(
                                              localStorage.getItem("userId"),
                                              item?.id
                                            );
                                          }}
                                        />
                                      ) : (
                                        <ThumbUpAltOutlinedIcon
                                          style={{
                                            fontSize: "20px",
                                            color: "#0b1275",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            this.likeResource(item.id);
                                          }}
                                        />
                                      )}
                                      <Typography
                                        style={{
                                          fontWeight: 500,
                                          color: "#010039",
                                          fontSize: "16px",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        {item?.likes}
                                      </Typography>
                                    </Box>
                                    <Button
                                      style={{
                                        fontWeight: 500,
                                        color: "#7E7E7E",
                                        fontSize: "14px",
                                        marginLeft: "20px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        this.deleteResource(item.id);
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  </Box>
                                </Box>
                              );
                            }
                          }
                        );
                      }
                    }
                  )
                ) : (
                  <Typography
                    style={{
                      color: "#010039",
                      fontWeight: 500,
                      textAlign: "left",
                      width: "100%",
                      padding: "10px",
                    }}
                  >
                    No resource found!
                  </Typography>
                )}
              </Box>

              <Box
                mt={4}
                ml={1}
                className="column1-child-box1 column2-child-box2"
                style={{ border: "none" }}
              >
                <Box
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    className="touch-points"
                    style={{ fontWeight: 500, color: "#010039" }}
                  >
                    Connection{" "}
                  </Typography>
                  {this.state?.connectedUserData?.length > 0 ? (
                    <Typography
                      className="touch-points"
                      style={{ fontWeight: 500, color: "#010039" }}
                      onClick={() => {
                        this.props.navigation.navigate("ConnectionList");
                      }}
                    >
                      See All{" "}
                    </Typography>
                  ) : null}
                </Box>
              </Box>

              <Box
                mt={1}
                py={2}
                mx={2}
                ml={1}
                className="column1-child-box1 column2-child-box2"
              >
                {this.state?.connectedUserData?.length > 0 ? (
                  this.state?.connectedUserData
                    ?.slice(0, 6)
                    .map((item: any, index: number) => {
                      let getCreatedYear = moment(
                        item.attributes.created_at
                      ).format("YYYY");
                      let getCreatedMonth = moment(
                        item.attributes.created_at
                      ).format("MMM");

                      return (
                        <Box
                          key={index}
                          px={2}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingBottom: "10px",
                          }}
                        >
                          <div
                            style={{ display: "flex", cursor: "pointer" }}
                            onClick={() => {
                              this.props.history.push("/UserProfile", {
                                userId: item.attributes.other_user_id,
                              });
                            }}
                          >
                            {
                              <img
                                src={
                                  item?.attributes?.profile_image || NoUserImg
                                }
                                id="profilePic0"
                              />
                            }

                            <Box mt={1} style={{ textAlign: "left" }}>
                              <Typography
                                style={{
                                  color: "#010138",
                                  fontWeight: 500,
                                  wordBreak: "break-all",
                                }}
                              >
                                {item.attributes.full_name}
                              </Typography>

                              <Typography
                                style={{
                                  color: "#8E8E8E",
                                  fontSize: "12px",
                                  textTransform: "uppercase",
                                }}
                              >
                                SINCE {getCreatedMonth} {getCreatedYear}{" "}
                              </Typography>
                            </Box>
                          </div>
                          <Box mt={2}>
                            <button id="connected-btn">
                              {" "}
                              <span style={{ fontSize: "12px" }}>
                                Connected
                              </span>{" "}
                            </button>
                          </Box>
                        </Box>
                      );
                    })
                ) : (
                  <Typography
                    style={{
                      color: "#010039",
                      fontWeight: 500,
                      textAlign: "left",
                      width: "100%",
                      padding: "10px",
                    }}
                  >
                    No Connected user found
                  </Typography>
                )}
              </Box>

              {this.state?.userProfileData &&
              (this.state?.userProfileData?.is_moderator_profile_created ||
                this.state?.userProfileData?.is_moderator) ? (
                <Box
                  mt={4}
                  ml={1}
                  className="column1-child-box1 column2-child-box2"
                  style={{ border: "none" }}
                >
                  <Box
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      className="touch-points"
                      style={{ fontWeight: 500, color: "#010039" }}
                    >
                      Followers{" "}
                    </Typography>
                    {this.state?.ownFollowers?.length > 0 ? (
                      <Typography
                        className="touch-points"
                        style={{ fontWeight: 500, color: "#010039" }}
                        onClick={() => {
                          this.props.navigation.navigate("FollowerList");
                        }}
                      >
                        See All{" "}
                      </Typography>
                    ) : null}
                  </Box>
                </Box>
              ) : (
                ""
              )}
              {this.state?.userProfileData &&
              (this.state?.userProfileData?.is_moderator_profile_created ||
                this.state?.userProfileData?.is_moderator) ? (
                <Box
                  mt={1}
                  py={2}
                  mx={2}
                  ml={1}
                  className="column1-child-box1 column2-child-box2"
                >
                  {this.state?.ownFollowers?.length > 0 ? (
                    this.state?.ownFollowers
                      ?.slice(0, 6)
                      .map((item: any, index: number) => {
                        let getCreatedYear = moment(
                          item.attributes?.created_at
                        ).format("YYYY");
                        let getCreatedMonth = moment(
                          item.attributes?.created_at
                        ).format("MMM");

                        return (
                          <Box
                            key={index}
                            px={2}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              paddingBottom: "10px",
                            }}
                          >
                            <div style={{ display: "flex" }}>
                              <Avatar id="profilePic0">
                                {(item?.attributes?.user?.profile_image && (
                                  <img
                                    src={item?.attributes?.user?.profile_image}
                                    style={{ width: "80px" }}
                                  />
                                )) || (
                                  <AccountCircleIcon
                                    style={{ fontSize: "60px" }}
                                  />
                                )}
                              </Avatar>

                              <Box mt={1} style={{ textAlign: "left" }}>
                                <Typography
                                  style={{
                                    color: "#010138",
                                    fontWeight: 500,
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {item?.attributes?.user?.full_name}
                                </Typography>

                                <Typography
                                  style={{
                                    color: "#8E8E8E",
                                    fontSize: "12px",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  SINCE {getCreatedMonth} {getCreatedYear}{" "}
                                </Typography>
                              </Box>
                            </div>

                            {item?.attributes?.user?.is_friends ? (
                              <Box mt={2}>
                                <button id="connected-btn">
                                  {" "}
                                  <span style={{ fontSize: "12px" }}>
                                    Connected
                                  </span>{" "}
                                </button>
                              </Box>
                            ) : (
                              ""
                            )}
                          </Box>
                        );
                      })
                  ) : (
                    <Typography
                      style={{
                        color: "#010039",
                        fontWeight: 500,
                        textAlign: "left",
                        width: "100%",
                        padding: "10px",
                      }}
                    >
                      No Followers found
                    </Typography>
                  )}
                </Box>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Container>
        <Dialog
          open={this.state.deniedOpen}
          onClose={this.handleDeniedClose}
          fullWidth={true}
          maxWidth="sm"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent style={{ textAlign: "center", paddingBlock: "40px" }}>
            <Box position="absolute" top="0" right="0">
              <IconButton aria-label="close" onClick={this.handleDeniedClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box mb={2}>
              <AvatarGroup
                max={4}
                style={{
                  marginTop: "10px",
                  justifyContent: "center",
                }}
              >
                <Avatar
                  alt="Jack Sparrow"
                  src={Oval}
                  style={{ width: "55px", height: "55px" }}
                />
                <Avatar
                  alt="Mike Wazowski"
                  src={joh}
                  style={{ width: "55px", height: "55px" }}
                />
                <Avatar
                  alt="Evelyn Carnahan"
                  src={thoma}
                  style={{ width: "55px", height: "55px" }}
                />
              </AvatarGroup>
            </Box>
            <Box mb={2}>
              <Typography
                color="textPrimary"
                style={{ fontWeight: 500, fontSize: "24px" }}
              >
                We're sorry; Your Moderator Application was denied
              </Typography>
              <Typography color="textSecondary">
                Kindly reinitiate the process after 6 months once you have
                acquired more relevant expertise or life experiences in the
                context of the topics you have chosen.
              </Typography>
            </Box>
            <Box mb={2} display="flex" justifyContent="center">
              <div
                style={{
                  border: "1px solid #E0E0E0",
                  borderRadius: "12px",
                  padding: "12px",
                }}
              >
                <Typography
                  color="textPrimary"
                  style={{ fontWeight: 700, fontSize: "16px" }}
                >
                  You can reapply
                </Typography>
                <Typography color="textSecondary">
                  on {futureDate.getDate()}th{" "}
                  {futureDate.toLocaleString("default", { month: "long" })}{" "}
                  {futureDate.getFullYear()}
                </Typography>
              </div>
            </Box>
            <Button
              style={{ borderRadius: "50px" }}
              className="contained-btn"
              variant="contained"
              onClick={() => {
                this.props.navigation.navigate("ModuleOrientation");
              }}
            >
              Visit Orientation Module
            </Button>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    );
  }
}

export default withRouter(UserProfileRegular as any);
// Customizable Area End