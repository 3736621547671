// Customizable Area Start
import React, { Component } from 'react';
import { ListItem, Grid } from '@material-ui/core';
import '../SideBar.css';

interface myProps {
	username: string;
	message: string;
	timeStamp: string;
}

export default class OtherMessage extends Component<myProps> {
	render() {
		return (
			<ListItem key={1}>
				<Grid direction='row' justifyContent='flex-start' container>
					<Grid  item>
						<div className='other-message-body'>
							<div className='other-message-body-detail'>
								<span className='fade'>{this.props.username}</span>
								<span style={{fontWeight:500}}>{this.props.message}</span>
							</div>
							<span className='fade time-stamp'>{this.props.timeStamp}</span>
						</div>
					</Grid>
				</Grid>
			</ListItem>
		);
	}
}
// Customizable Area End