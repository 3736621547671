import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { imagePerson, imageChat, newHearth } from '../assets'
const TextSlider = (props: { incActiveStep: () => void, policyDetailsSplited: string[] }) => {
   const [activeTab, setActiveTab] = useState(1);
   const [activeStep, setActiveStep] = useState(1);

   const handleTabClick = (tabId: number) => {
      setActiveTab(tabId);
      setActiveStep(tabId)
   };

   return (
      <>
         <img src={activeTab === 1 ? newHearth : (activeTab === 2 ? imageChat : imagePerson)}
            style={{
               height: '36px',
               width: '36px',
               margin: '20px 0px',
            }}
         />
         <div className="heading">
            {/* ts-ignore */}
            <h2>{props.policyDetailsSplited[0]}</h2>
         </div>
         <div className="tabsContainer">
            <div className="tabsContent">
               {props.policyDetailsSplited.map((tab: string, index: number) => {
                  if (index > 0) {
                     return (
                        <div key={index} className={`tabContent ${index === activeTab ? 'active' : (activeTab > index ? 'big' : 'small')}`}>
                           <p>{tab}</p>
                        </div>
                     )
                  }
               })}
            </div>
            <div className="tabsButtons">
               {props.policyDetailsSplited.map((content: string, index: number) => {
                  if (index > 0) {
                     return (
                        <div
                           key={index}
                           className={`tabButton ${index === activeTab ? 'active' : ''}`}
                           onClick={() => handleTabClick(index)}
                        />
                     )
                  }
               })}
            </div>
         </div>
         <div className="submit-button-div" style={{ marginTop: '24px' }}>
            <Button
               className="submit-btn-onboard"
               value="Submit"
               variant="contained"
               onClick={() => {
                  if (activeStep === props.policyDetailsSplited.length - 1) {
                     props.incActiveStep()
                  } else if (activeStep < props.policyDetailsSplited.length - 1) {
                     handleTabClick(activeStep + 1)
                  }
               }}
            >
               {activeStep === props.policyDetailsSplited.length - 1 ? 'I understand' : 'Next'}
            </Button>
         </div>
      </>
   );
};

export default TextSlider;
