import React from 'react'
import { earnedCoins, freeCoin, coinPurchased, redeemedYCoins, refundedCoin, spentYcoins, penaltyYcoins } from "../../assets";
import { Grid, CircularProgress } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';

const TransactionHistory = (props: any) => {

   const timeHelper = (time: any) => {
      return new Date(time).toLocaleString(navigator.language, {
      //   hour: '2-digit',
      //   minute: '2-digit',
        month: 'short',
        year: 'numeric',
        day: '2-digit',
      //   hour12: true,
      }).replace(/,/g, ' ');
   }

   const imgType = (tnx_issue_type: string) => {
      switch (tnx_issue_type) {
         case 'Purchased':
            return coinPurchased;
         case 'Free':
            return freeCoin;
         case 'Earned':
            return earnedCoins;
         case 'Redeem':
            return redeemedYCoins;
         case 'Refund':
            return refundedCoin;
         case 'Spent':
            return spentYcoins;
         case 'Penalty':
            return penaltyYcoins;
         default:
            return earnedCoins;
      }
   }

   const checkType = (issue_type: string) => {
      switch(issue_type) {
         case 'Purchased':
            return 'Purchased YCoins';
         case 'ACCOUNT_CREATE':
             return 'Account Create';
         default: 
            return issue_type;
      }
   }

   const formatYcoins = (item:any) => {
      if (item.ycoins === 0) return "0";
    
      const sign = item.tnx_type === 'debit' ? '-' : '+';
      return sign + item.ycoins;
    }

   const sortedUserHistory = props?.userHistory ?? []

   return (
      <div>
         <span className="transactionLabel">Transaction history</span>
         <div className="transactionSecondLabelBox"> 
            <span className="yCoinLabel">Transaction</span>
            <span className="yCoinLabel">YCoins</span>
         </div>
         {props.isLoading ?
            <Grid container xs={12} style={{ height: '100%', justifyContent: 'center' }} alignItems="center">
               {Array(15).fill(0).map((_, index) => {
                  return (
                     <div key={index} className="transactionBox">
                        <div className="transactionLeftBox">
                           <Skeleton variant="circle" width="50px" animation="wave" height="50px" />
                        </div>
                        <div className="transactionMiddleBox">
                           <span className="transactionType">
                              <Skeleton variant="text" width="100%" animation="wave" height="25px" />
                           </span>
                           <span className="transactionDate"><Skeleton width="100%" animation="wave" height="25px" /></span>
                        </div>
                     </div>
                  )
               })}
            </Grid>
            :
            <div className="transaction">
               {sortedUserHistory.length > 0 && sortedUserHistory.map((item: any) => {
                  return (
                     <div key={item?.id} className="transactionBox">
                        <div className="transactionLeftBox">
                           <img src={imgType(item.tnx_issue_type)}
                              style={{ width:item.tnx_issue_type === 'Spent' ? '30px' : '40px', height: '40px' }}
                           />
                        </div>
                        <div className="transactionMiddleBox">
                           <span className="transactionType">
                              {checkType(item.issue_type)}
                           </span>
                           <span className="transactionDate">{timeHelper(item.created_at)}</span>
                        </div>
                        <div className="transactionRight">
                           {formatYcoins(item)}
                        </div>
                     </div>
                  )
               })}

               {props.loading && 
                  <Grid container xs={12} style={{ height: '100%', marginTop: '10px', marginBottom: '10px', justifyContent: 'center' }} alignItems="center">
                     <div>
                        <CircularProgress size={80} thickness={4} style={{ color: '#ffcb00' }} />
                     </div>
                  </Grid>
               }
            </div>
         }
      </div>
   )
}

export default TransactionHistory;