import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  IconButton,
  Avatar,
  Card,
  CardContent,
  CardActions,
  Divider,
  Hidden,
  Drawer,
  withWidth,
  List,
  ListItem,
} from "@material-ui/core";
import { styled, createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import "./HomePage.css";
import AppleIcon from "@material-ui/icons/Apple";
import ShopIcon from "@material-ui/icons/Shop";
import MenuIcon from "@material-ui/icons/Menu";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import {
  logo,
  heroImage,
  bgPartical,
  highlightedUnderline,
  datingStories,
  search,
  task,
  heart,
  howItWorks,
  roomType1,
  roomType2,
  roomType3,
  roomType4,
  store,
  market,
  exploreWeb,
  logoLight,
  circle,
  dottedLine,
} from "../assets";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { isAuthenticated } from "../../../../components/src/utils";
import RegistrationDialog from "../RegistrationDialog";

const MenuItem = styled(Typography)({
  fontSize: "16px",
  fontWeight: 400,
  cursor: "pointer",
  "&:hover": {
    position: "relative",
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: "-5px",
      left: "50%",
      right: "50%",
      background: "#FFCB00",
      height: "4px",
      borderRadius: "4px",
      transform: "translate(-50%, -50%)",
      width: "70%",
    },
  },
});

const MenuListItem = styled(ListItem)({
  fontSize: "16px",
  fontWeight: 400,
});

const HighlightedText = styled("span")({
  position: "relative",
  paddingBottom: "4px",
  "&:after": {
    content: `url(${highlightedUnderline})`,
    position: "absolute",
    bottom: 0,
    left: "50%",
    right: "50%",
    height: "4px",
    transform: "translate(-50%, -50%)",
    width: "75%",
  },
});

const TopicSpecialText = styled("span")(({ theme }) => ({
  fontSize: "34px",
  fontWeight: 800,
  color: "#FFFFFF",
  display: "inline",
}));

const TopicAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  width: theme.spacing(7),
  height: theme.spacing(7),
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFCB00",
    },
    secondary: {
      main: "#010039",
    },
    text: {
      primary: "#27272A",
    },
  },
});

export interface Props extends RouteComponentProps<any> {
  navigation: any;
  id: string;
  width: Breakpoint;
}

export interface State {
  landingPageData: {
    heading: { description: string; id: number }[];
    room_types: { icon: string; description: string; name: string }[];
    topics: { icon: string; description: string; name: string }[];
    user_experiences: { image: string; description: string; name: string }[];
  };
  activePlan: string;
  step: number;
  isOnboardingDialogOpen: boolean;
  isSideMenuOpen: boolean;
}

class HomePage extends BlockComponent<Props, State, {}> {
  fetchLandingPageId: string;
  homeRef: React.RefObject<HTMLInputElement>;
  categoryRef: React.RefObject<HTMLInputElement>;
  works: React.RefObject<HTMLInputElement>;
  experience: React.RefObject<HTMLInputElement>;
  rooms: React.RefObject<HTMLInputElement>;
  userType: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.homeRef = React.createRef();
    this.categoryRef = React.createRef();
    this.works = React.createRef();
    this.experience = React.createRef();
    this.rooms = React.createRef();
    this.userType = React.createRef();

    this.state = {
      landingPageData: {
        heading: [],
        room_types: [],
        topics: [],
        user_experiences: [],
      },
      activePlan: "",
      step: 0,
      isOnboardingDialogOpen: false,
      isSideMenuOpen: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.fetchLandingPageData();
    const stepFromUrl = this.props.match.params?.step;
    if (stepFromUrl)
      this.setState({
        step: Number(stepFromUrl),
        isOnboardingDialogOpen: true,
      });
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.fetchLandingPageId) {
          // temp fix to swap
          if (responseJson?.topics?.[2]) {
            const temp = responseJson.topics[0];
            responseJson.topics[0] = responseJson.topics[2];
            responseJson.topics[2] = temp;
          }

          this.setState({
            landingPageData: responseJson,
          });
        }
      }
    }
  }

  fetchLandingPageData = () => {
    const headers = {
      "Content-Type": "application/json",
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchLandingPageId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/profiles/get_landing_page_data"
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  handleOnboardingDialogClose = () => {
    this.setState({ isOnboardingDialogOpen: false });
  };

  handleSideMenuClose = () => {
    this.setState({ isSideMenuOpen: false });
  };

  render() {
    const {
      landingPageData,
      activePlan,
      step,
      isOnboardingDialogOpen,
      isSideMenuOpen,
    } = this.state;

    const { width } = this.props;

    const isSmall = width === "sm" || width === "xs";
    const isTab = width === "md";

    const enterYlanesButton = (
      <Button
        color="primary"
        size="small"
        variant="contained"
        onClick={() =>
          isAuthenticated()
            ? this.props.navigation.navigate("UpcomingRooms")
            : this.setState({ isOnboardingDialogOpen: true })
        }
      >
        Enter YLanes
      </Button>
    );

    return (
      <ThemeProvider theme={theme}>
        <Box
          style={{
            position: "sticky",
            top: "0",
            zIndex: 2,
            boxShadow: "0px 0px 18px 5px rgb(0 0 0 / 5%)",
            backgroundColor: "white",
          }}
        >
          <Container>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ paddingBlock: "8px" }}
            >
              <Grid item>
                <img src={logo} alt="logo" height="56" />
              </Grid>
              <Grid item>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  style={{ margin: 0 }}
                >
                  {!isSmall &&
                    MenuRouter(this)?.map((item) => {
                      return (
                        <Grid item key={item.menu}>
                          <MenuItem
                            onClick={() =>
                              item.ref.current?.scrollIntoView({
                                behavior: "smooth",
                              })
                            }
                          >
                            {item.menu}
                          </MenuItem>
                        </Grid>
                      );
                    })}
                  <Hidden xsDown>
                    <Grid item>{enterYlanesButton}</Grid>
                  </Hidden>

                  <Hidden mdUp>
                    <Grid item>
                      <IconButton
                        onClick={() =>
                          this.setState({
                            isSideMenuOpen: true,
                          })
                        }
                      >
                        <MenuIcon color="secondary" />
                      </IconButton>
                    </Grid>
                  </Hidden>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Container ref={this.homeRef} style={{ scrollMarginTop: "196px" }}>
          <Box mt={5} position="relative">
            {!isSmall && (
              <img
                src={bgPartical}
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "-10%",
                }}
              />
            )}
            <Grid
              spacing={3}
              container
              justifyContent="space-around"
              alignItems="center"
              direction={isSmall ? "column-reverse" : "row"}
            >
              <Grid item xs={12} md={6}>
                <Typography
                  color="secondary"
                  style={{
                    fontSize: "47px",
                    fontWeight: 800,
                    lineHeight: "63px",
                    maxWidth: "28ch",
                  }}
                >
                  A Welcoming Space for{" "}
                  <HighlightedText>Meaningful</HighlightedText> Conversations
                </Typography>

                <Box pt={2} pb={3}>
                  <style>
                    {`
                      p { margin-bottom: 8px }
                    `}
                  </style>
                  <Typography color="secondary" component="div">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${
                          landingPageData.heading?.[0]?.description ?? ""
                        }`,
                      }}
                    />
                  </Typography>
                </Box>

                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      endIcon={<ArrowForwardIcon />}
                      onClick={() =>
                        isAuthenticated()
                          ? this.props.navigation.navigate("UpcomingRooms")
                          : this.setState({ isOnboardingDialogOpen: true })
                      }
                    >
                      Get Started
                    </Button>
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={() =>
                        window.open(
                          "https://play.google.com/store/apps/details?id=com.YLanes1",
                          "_blank",
                          "noopener"
                        )
                      }
                      style={{
                        borderRadius: "15px",
                        border: "1px solid #010039",
                      }}
                    >
                      <ShopIcon color="secondary" />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={() =>
                        window.open(
                          "https://apps.apple.com/us/app/ylanes/id1634627479",
                          "_blank",
                          "noopener"
                        )
                      }
                      style={{
                        borderRadius: "15px",
                        border: "1px solid #010039",
                      }}
                    >
                      <AppleIcon color="secondary" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={5} style={{ textAlign: "right" }}>
                <img
                  style={{ width: "100%", maxWidth: "425px" }}
                  src={heroImage}
                  alt="hero"
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Box my={8} textAlign="center">
          <Container
            ref={this.categoryRef}
            style={{ scrollMarginTop: "115px" }}
          >
            <Typography
              color="secondary"
              style={{ fontSize: "41px", fontWeight: 700 }}
            >
              Wide
              <HighlightedText> range of </HighlightedText>
              conversation topics
            </Typography>
          </Container>
          <Box mt={4} py={3} bgcolor="#FDC902">
            <Container>
              <Typography
                color="secondary"
                style={{ fontSize: "31px", fontWeight: 800, display: "inline" }}
              >
                You can choose from a list of{" "}
                <TopicSpecialText>75+ conversation topics</TopicSpecialText>{" "}
                covering Relationships, Reflections and Exploring Self
              </Typography>
              <Box mt={3} textAlign="left">
                <Container>
                  <Grid container>
                    {landingPageData.topics?.map((topic) => (
                      <Grid
                        key={topic.name}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 24,
                          marginBottom: "16px",
                        }}
                      >
                        <TopicAvatar
                          src={topic.icon}
                          imgProps={{
                            style: {
                              height: 48,
                              width: 48,
                            },
                          }}
                        />
                        <Typography
                          color="secondary"
                          style={{
                            fontSize: "18px",
                            fontWeight: 600,
                          }}
                        >
                          {topic.name}
                        </Typography>
                      </Grid>
                    ))}
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 24,
                        marginBottom: "16px",
                      }}
                    >
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        endIcon={<NavigateNextIcon />}
                        onClick={() =>
                          this.props.navigation.navigate(
                            "CategoriessubcategoriesWeb"
                          )
                        }
                      >
                        <Typography
                          style={{
                            fontSize: "18px",
                            fontWeight: 600,
                            marginRight: "auto",
                          }}
                        >
                          Explore all
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            </Container>
          </Box>
        </Box>
        <Container ref={this.works} style={{ scrollMarginTop: "115px" }}>
          <Box mt={4} pb={4} position="relative" textAlign="center">
            {!isSmall && (
              <img
                alt="bg ring"
                src={bgPartical}
                style={{
                  position: "absolute",
                  right: "5%",
                  top: "2%",
                }}
              />
            )}
            <Typography
              color="secondary"
              style={{ fontSize: "41px", fontWeight: 700 }}
            >
              <HighlightedText>How it works</HighlightedText>
            </Typography>
            <Box mt={5}>
              <Grid
                container
                spacing={4}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12} md={9}>
                  <img
                    src={howItWorks}
                    alt="video call"
                    width="100%"
                    style={{ objectFit: "contain" }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Grid container spacing={4} justifyContent="center">
                    {howWorksSteps.map((step) => (
                      <Grid
                        key={step.name}
                        item
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Box
                          bgcolor="#E6E6EB"
                          height="96"
                          width="96"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          borderRadius="24px"
                          mb={2}
                        >
                          <img src={step.img} alt="search" />
                        </Box>
                        <Typography
                          color="secondary"
                          style={{ fontSize: "19px", fontWeight: 600 }}
                        >
                          {step.name}
                        </Typography>
                        <Typography
                          color="secondary"
                          style={{ fontSize: "12px" }}
                        >
                          {step.description}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
        <Box py={4} textAlign="center" bgcolor="#FBFBFB">
          <Container
            style={{ position: "relative", scrollMarginTop: "115px" }}
            ref={this.experience}
          >
            {!isSmall && (
              <>
                <img
                  alt="bg ring"
                  src={bgPartical}
                  style={{
                    position: "absolute",
                    right: "4%",
                    bottom: "2%",
                  }}
                />
                <img
                  alt="bg ring"
                  src={bgPartical}
                  style={{
                    position: "absolute",
                    left: "10%",
                    top: "0%",
                  }}
                />
              </>
            )}
            <Typography
              color="secondary"
              style={{ fontSize: "41px", fontWeight: 700 }}
            >
              <HighlightedText>User experiences</HighlightedText>
            </Typography>
            <Box my={4}>
              <Grid container spacing={9} justifyContent="center">
                {landingPageData.user_experiences?.map((testimonial, indx) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={indx}>
                    <Card
                      style={{ height: "100%", borderRadius: "24px" }}
                      elevation={10}
                    >
                      <CardContent
                        style={{ padding: "16px", textAlign: "left" }}
                      >
                        <Box
                          mb={3}
                          display="flex"
                          alignItems="center"
                          gridGap="16px"
                        >
                          <Avatar
                            style={{ height: "64px", width: "64px" }}
                            alt={testimonial.name}
                            src={testimonial.image}
                          />
                          <Typography
                            color="secondary"
                            style={{
                              fontSize: "19px",
                              fontWeight: 700,
                            }}
                          >
                            {testimonial.name}
                          </Typography>
                        </Box>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: 500,
                            color: "#807F9C",
                          }}
                        >
                          {testimonial.description}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Container>
        </Box>
        <Container ref={this.rooms} style={{ scrollMarginTop: "115px" }}>
          <Box my={4} textAlign="center" position="relative">
            {!isSmall && (
              <img
                alt="bg ring"
                src={bgPartical}
                style={{
                  position: "absolute",
                  left: "4%",
                  top: "13%",
                }}
              />
            )}
            <Typography
              color="secondary"
              style={{ fontSize: "41px", fontWeight: 700 }}
            >
              <HighlightedText>Explore rooms</HighlightedText>
            </Typography>
            <Typography
              style={{
                fontSize: "20px",
                fontWeight: 500,
                color: "#807F9C",
                margin: "24px 0 48px",
              }}
            >
              There are four types of rooms you can join or create*
            </Typography>
            <Grid container spacing={4}>
              {landingPageData.room_types?.map((room, indx) => (
                <Grid item xs={12} sm={6} lg={3} key={indx}>
                  <Card
                    elevation={room.name === activePlan ? 10 : 0}
                    onMouseOver={() => this.setState({ activePlan: room.name })}
                    onMouseOut={() => this.setState({ activePlan: "" })}
                    style={{
                      borderRadius: "24px",
                      border:
                        room.name === activePlan ? "" : "1px solid #D8D9D9",
                    }}
                  >
                    <CardContent
                      style={{
                        height: "calc(100% - 76px)",
                        backgroundColor:
                          room.name === activePlan
                            ? theme.palette.primary.main
                            : "#FFFFFF",
                        padding: "24px",
                      }}
                    >
                      <img
                        style={
                          room.name === activePlan
                            ? { filter: "brightness(130%) contrast(130%)" }
                            : {}
                        }
                        height="48px"
                        src={room.icon}
                        alt={room.name}
                      />
                      <Typography
                        color="secondary"
                        style={{
                          fontSize: "23px",
                          fontWeight: 600,
                          margin: "8px 0 16px",
                        }}
                      >
                        {room.name}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                          color:
                            room.name === activePlan ? "#010039" : "#807F9C",
                        }}
                      >
                        {room.description}
                      </Typography>
                      {/* <Typography
                        color="secondary"
                        style={{
                          fontSize: '16px',
                          fontWeight: 500,
                          color: room.name === activePlan ? '#010039' : '#807F9C',
                        }}
                      >
                        {room.size}
                      </Typography> */}
                    </CardContent>
                    <CardActions
                      style={{
                        backgroundColor:
                          room.name === activePlan
                            ? theme.palette.secondary.main
                            : "#FBFBFB",
                        justifyContent: "center",
                        padding: "16px 0",
                      }}
                    >
                      <Button
                        variant={
                          room.name === activePlan ? "outlined" : "contained"
                        }
                        color={
                          room.name === activePlan ? "default" : "secondary"
                        }
                        size="small"
                        style={
                          room.name === activePlan
                            ? {
                                backgroundColor: "#FFFFFF",
                              }
                            : {}
                        }
                        onClick={() => {
                          isAuthenticated()
                            ? this.props.navigation.navigate("UpcomingRooms")
                            : this.setState({ isOnboardingDialogOpen: true });
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "16px",
                            fontWeight: 500,
                            color:
                              room.name === activePlan ? "#010039" : "#FFFFFF",
                          }}
                        >
                          Explore Room
                        </Typography>
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
        <Box pt={4} textAlign="center" position="relative">
          <Container ref={this.userType} style={{ scrollMarginTop: "115px" }}>
            <Typography
              color="secondary"
              style={{ fontSize: "41px", fontWeight: 700 }}
            >
              How do I bec<HighlightedText>ome a Moderator </HighlightedText>
              or Superhost?
            </Typography>
            <Box mt={8} position="relative">
              {!isSmall && (
                <img
                  alt="bg ring"
                  src={bgPartical}
                  style={{
                    position: "absolute",
                    left: "15%",
                    top: "0%",
                  }}
                />
              )}
              <Hidden smDown>
                <img
                  src={dottedLine}
                  style={{
                    position: "absolute",
                    top: "137px",
                    left: "0px",
                    width: "100%",
                    zIndex: -1,
                  }}
                />
              </Hidden>
              <Grid container spacing={2} justifyContent="space-around">
                <Grid item xs={11}>
                  <Grid
                    container
                    spacing={4}
                    justifyContent="space-around"
                    direction={isSmall ? "column" : "row"}
                  >
                    <Grid
                      item
                      xs={12}
                      md={4}
                      style={{
                        marginTop: isSmall
                          ? "0px"
                          : isTab
                          ? "calc(100vw - 78%)"
                          : "345px",
                      }}
                    >
                      <Grid
                        container
                        direction="column"
                        spacing={2}
                        style={{ maxWidth: isSmall ? "none" : "315px" }}
                      >
                        <Grid item style={{ position: "relative" }}>
                          <Hidden mdUp>
                            <img
                              src={circle}
                              style={{
                                position: "absolute",
                                top: "0px",
                                bottom: "0px",
                                right:
                                  width === "xs"
                                    ? "calc(-8% - 6px)"
                                    : "calc(-6% - 8px)",
                                marginBlock: "auto",
                              }}
                            />
                          </Hidden>
                          <Typography
                            color="secondary"
                            style={{
                              fontSize: "18px",
                              padding: "8px",
                              background: "#ffcb00",
                              borderRadius: "16px",
                              paddingInline: isTab ? "26px" : "0px",
                            }}
                          >
                            Create min 5 rooms and earn 50 hearts to become a
                            Superhost and get a blue tick.
                          </Typography>
                        </Grid>
                        <Hidden smDown>
                          <Grid item>
                            <img src={circle} />
                          </Grid>
                        </Hidden>
                        <Grid item>
                          <Box textAlign="left">
                            <Typography
                              color="secondary"
                              style={{ fontSize: "14px" }}
                            >
                              A Superhost can create five Standard or Premium
                              rooms for free each month.
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      style={{
                        marginTop: isSmall
                          ? "0px"
                          : isTab
                          ? "calc(100vw - 92%)"
                          : "137px",
                      }}
                    >
                      <Grid
                        container
                        direction="column"
                        spacing={2}
                        style={{ maxWidth: isSmall ? "none" : "340px" }}
                      >
                        <Grid item style={{ position: "relative" }}>
                          <Hidden mdUp>
                            <img
                              src={circle}
                              style={{
                                position: "absolute",
                                top: "0px",
                                bottom: "0px",
                                right:
                                  width === "xs"
                                    ? "calc(-8% - 6px)"
                                    : "calc(-6% - 8px)",
                                marginBlock: "auto",
                              }}
                            />
                          </Hidden>
                          <Typography
                            color="secondary"
                            style={{
                              fontSize: "18px",
                              padding: "8px",
                              background: "#ffcb00",
                              borderRadius: "16px",
                            }}
                          >
                            Click "Become a Moderator," add relevant experience
                            or expertise, and complete the Moderator
                            Orientation.
                          </Typography>
                        </Grid>
                        <Hidden smDown>
                          <Grid item>
                            <img src={circle} />
                          </Grid>
                        </Hidden>
                        <Grid item>
                          <Box textAlign="left">
                            <Typography
                              style={{
                                color: "secondary",
                                fontSize: "14px",
                              }}
                            >
                              Certified coaches, counsellors, etc. Automatically
                              attain Superhost status once their profile is
                              verified.
                            </Typography>
                            <br />
                            <Typography
                              style={{
                                color: "secondary",
                                fontSize: "14px",
                              }}
                            >
                              Users with personal experiences relevant to the
                              subcategories can also become Moderators.
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      style={{
                        marginTop: isSmall
                          ? "0px"
                          : isTab
                          ? "calc(100vw - 105%)"
                          : "0px",
                      }}
                    >
                      <Grid
                        container
                        direction="column"
                        spacing={2}
                        style={
                          isSmall
                            ? {}
                            : {
                                marginLeft: "36px",
                                maxWidth: "340px",
                              }
                        }
                      >
                        <Grid item style={{ position: "relative" }}>
                          <Hidden mdUp>
                            <img
                              src={circle}
                              style={{
                                position: "absolute",
                                top: "0px",
                                bottom: "0px",
                                right:
                                  width === "xs"
                                    ? "calc(-8% - 6px)"
                                    : "calc(-6% - 8px)",
                                marginBlock: "auto",
                              }}
                            />
                          </Hidden>
                          <Typography
                            color="secondary"
                            style={{
                              fontSize: "18px",
                              padding: "8px",
                              background: "#ffcb00",
                              borderRadius: "16px",
                              paddingInline: isTab ? "26px" : "0px",
                            }}
                          >
                            Create min 5 rooms and earn 100 hearts to get the
                            Moderator status and coveted golden tick.
                          </Typography>
                        </Grid>
                        <Hidden smDown>
                          <Grid item>
                            <img src={circle} />
                          </Grid>
                        </Hidden>
                        <Grid item>
                          <Box textAlign="left">
                            <Typography
                              style={{
                                color: "secondary",
                                fontSize: "14px",
                              }}
                            >
                              Moderators can open rooms for free and earn YCoins
                              which can be cashed* out.
                            </Typography>
                            <br />
                            <Typography
                              style={{
                                color: "secondary",
                                fontSize: "14px",
                              }}
                            >
                              Moderators can have followers and the
                              distinguished golden tick.
                            </Typography>
                            <br />
                            <Typography
                              style={{
                                color: "secondary",
                                fontSize: "14px",
                              }}
                            >
                              *Cash out option is currently available only to
                              Moderators with Indian bank accounts. YCoins can
                              always be used within the platform.
                            </Typography>
                            <br />
                            <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              endIcon={<ArrowForwardIcon />}
                              onClick={() =>
                                isAuthenticated()
                                  ? this.props.navigation.navigate(
                                      "UpcomingRooms"
                                    )
                                  : this.setState({
                                      isOnboardingDialogOpen: true,
                                    })
                              }
                            >
                              <Typography
                                color="secondary"
                                style={{
                                  fontSize: "18px",
                                  fontWeight: 500,
                                  padding: "4px",
                                }}
                              >
                                Learn more
                              </Typography>
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Hidden mdUp>
                  <Grid className="dotted-line-vertical" item>
                    {/* <img
                      src={circle}
                      style={{
                        position: 'absolute',
                        top: '136px',
                        left: '-6px',
                      }}
                    />
                    <img
                      src={circle}
                      style={{
                        position: 'absolute',
                        top: '320px',
                        left: '-6px',
                      }}
                    /> */}
                  </Grid>
                </Hidden>
              </Grid>
            </Box>
          </Container>
        </Box>
        <Box mt={4} bgcolor={theme.palette.primary.main} py={2}>
          <Container>
            <Grid
              spacing={2}
              container
              justifyContent={isSmall ? "center" : "space-between"}
              alignItems="center"
            >
              <Grid item>
                <Typography
                  style={{
                    color: "#27272A",
                    fontSize: "22px",
                    fontWeight: 600,
                  }}
                >
                  Available on
                </Typography>
              </Grid>
              <Grid item>
                <Grid
                  container
                  spacing={3}
                  justifyContent={isSmall ? "center" : "space-between"}
                >
                  <Grid item>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.YLanes1"
                      target="_blank"
                      rel="noopener"
                    >
                      <img height="56px" src={market} alt="play store" />
                    </a>
                  </Grid>
                  <Grid item>
                    <a
                      href="https://apps.apple.com/us/app/ylanes/id1634627479"
                      target="_blank"
                      rel="noopener"
                    >
                      <img height="56px" src={store} alt="apple store" />
                    </a>
                  </Grid>
                  <Grid
                    item
                    onClick={() => {
                      isAuthenticated()
                        ? this.props.navigation.navigate("UpcomingRooms")
                        : this.setState({ isOnboardingDialogOpen: true });
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <img height="56px" src={exploreWeb} alt="explore web" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <IconButton
                  style={{
                    borderRadius: "12px",
                    backgroundColor: theme.palette.secondary.main,
                    padding: "16px",
                  }}
                  onClick={() =>
                    this.homeRef.current?.scrollIntoView({ behavior: "smooth" })
                  }
                >
                  <Box bgcolor={theme.palette.primary.main} borderRadius="50px">
                    <ArrowUpwardIcon color="secondary" />
                  </Box>
                </IconButton>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box pt={10} pb={5} bgcolor={theme.palette.secondary.main}>
          <Container style={{ position: "relative", paddingBottom: "56px" }}>
            {!isSmall && (
              <img
                alt="bg ring"
                src={bgPartical}
                style={{
                  position: "absolute",
                  right: "4%",
                  bottom: "4%",
                }}
              />
            )}
            <Grid container justifyContent="space-between">
              <Grid item>
                <img src={logoLight} alt="logo" height="92" />
                <Box>
                  <Box display="flex" sx={{ mt: 3 }}>
                    <MailOutlineIcon
                      style={{
                        color: "#7F8E9D",
                        marginRight: ".5rem",
                        marginLeft: "-.5px",
                      }}
                    />
                    <Typography
                      style={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "#7F8E9D",
                      }}
                    >
                      Email Us On
                    </Typography>
                  </Box>
                  <Typography
                    style={{
                      fontSize: "13.5px",
                      fontWeight: 500,
                      color: "#FFF",
                      opacity: 0.5,
                    }}
                  >
                    <a
                      style={{ color: "inherit" }}
                      href="mailto:support@ylanes.com"
                    >
                      support@ylanes.com
                    </a>
                  </Typography>
                </Box>

                <Box>
                  <Box display="flex" sx={{ mt: 2 }}>
                    <LocationOnOutlinedIcon
                      style={{
                        color: "#7F8E9D",
                        marginRight: ".5rem",
                        marginLeft: "-.5px",
                      }}
                    />
                    <Typography
                      style={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "#7F8E9D",
                      }}
                    >
                      Reach Us On
                    </Typography>
                  </Box>
                  <Typography
                    style={{
                      fontSize: "13.5px",
                      fontWeight: 500,
                      color: "#FFF",
                      opacity: 0.5,
                    }}
                  >
                    Madivam Connects Pvt Ltd <br />
                    HD - 088, WeWork Enam Sambhav C - 20 <br />
                    BKC G Block Road <br />
                    Mumbai 400051 <br />
                    Maharashtra <br />
                    India
                  </Typography>
                </Box>
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 500,
                    color: "#AAAAAA",
                    margin: "32px 0 16px",
                  }}
                >
                  Follow us on
                </Typography>
                <Box display="flex" gridGap="16px">
                  <a
                    href="https://www.facebook.com/people/YLanes/100087925750413"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconButton
                      style={{
                        backgroundColor: theme.palette.primary.main,
                        height: "32px",
                        width: "32px",
                      }}
                    >
                      <FacebookIcon color="secondary" />
                    </IconButton>
                  </a>
                  <a
                    href="https://www.instagram.com/ylanes_official/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconButton
                      style={{
                        backgroundColor: theme.palette.primary.main,
                        height: "32px",
                        width: "32px",
                      }}
                    >
                      <InstagramIcon color="secondary" />
                    </IconButton>
                  </a>
                  <a
                    href="https://twitter.com/Ylanes_official"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconButton
                      style={{
                        backgroundColor: theme.palette.primary.main,
                        height: "32px",
                        width: "32px",
                      }}
                    >
                      <TwitterIcon color="secondary" />
                    </IconButton>
                  </a>
                </Box>
              </Grid>
              <Grid item>
                <Grid container spacing={2} direction="column">
                  {MenuRouter(this).map((item) => {
                    return (
                      <Grid item key={item.menu}>
                        <Typography
                          style={{
                            fontSize: "17px",
                            fontWeight: 500,
                            color: "AAAAAA",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            item.ref.current?.scrollIntoView({
                              behavior: "smooth",
                            })
                          }
                        >
                          {item.menu}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  spacing={2}
                  direction="column"
                  style={{ textAlign: "right" }}
                >
                  {FooterExtraMenu.map((item) => {
                    return (
                      <Grid item key={item.menu}>
                        <Typography
                          style={{
                            fontSize: "17px",
                            fontWeight: 500,
                            color: "#AAAAAA",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            this.props.navigation.navigate(
                              item?.link ?? "HomePage"
                            )
                          }
                        >
                          {item.menu}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
            <Box my={8}>
              <Divider style={{ backgroundColor: "#D8D8D8" }} />
            </Box>
            <Typography
              style={{
                fontSize: "15px",
                color: "#FFFFFF",
              }}
            >
              Welcome to YLanes! <br />
              At YLanes, we believe in the power of meaningful conversations. We
              have created a platform that enables individuals to connect and
              engage in authentic, heart-to-heart conversations through audio
              and video calls. Whether you're seeking to share your life
              experiences, discuss your passions, or simply connect with
              interesting individuals from around the world, our website and
              mobile app, available on Google Play and the Apple App Store,
              provide the perfect avenue for these enriching interactions.
            </Typography>
            <Typography
              style={{
                fontSize: "15px",
                fontWeight: 500,
                color: "#AAAAAA",
                marginTop: "16px",
              }}
            >
              All rights reserved
            </Typography>
          </Container>
        </Box>
        <RegistrationDialog
          navigation={this.props.navigation}
          id={this.props.id}
          step={step}
          isOnboardingDialogOpen={isOnboardingDialogOpen}
          handleOnboardingDialogClose={this.handleOnboardingDialogClose}
        />
        <Drawer
          anchor="right"
          open={isSideMenuOpen}
          onClose={this.handleSideMenuClose}
          disableEnforceFocus
        >
          <List style={{ width: "250px" }}>
            {MenuRouter(this)?.map((item) => {
              return (
                <MenuListItem
                  button
                  key={item.menu}
                  onClick={() => {
                    this.handleSideMenuClose();
                    setTimeout(() => {
                      item.ref.current?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }, 0);
                  }}
                >
                  {item.menu}
                </MenuListItem>
              );
            })}
            <Hidden smUp>
              <ListItem>{enterYlanesButton}</ListItem>
            </Hidden>
          </List>
        </Drawer>
      </ThemeProvider>
    );
  }
}

export default withRouter(withWidth()(HomePage));

const MenuRouter = (context: any) => [
  {
    menu: "Home",
    ref: context.homeRef,
  },
  {
    menu: "Categories",
    ref: context.categoryRef,
  },
  {
    menu: "How it Works",
    ref: context.works,
  },
  {
    menu: "Experiences",
    ref: context.experience,
  },
  {
    menu: "Rooms",
    ref: context.rooms,
  },
  {
    menu: "User Types",
    ref: context.userType,
  },
];

const FooterExtraMenu = [
  {
    menu: "About",
    link: "AboutPage",
  },
  {
    menu: "Privacy Policy",
    link: "PrivacyPolicyData",
  },
  {
    menu: "Terms & Conditions",
    link: "TermsAndConditions",
  },
  {
    menu: "FAQs",
    link: "FaqPage",
  },
  {
    menu: "Contact us",
    link: "ContactUsPage",
  },
];

const topics = [
  { name: "Dating stories", img: datingStories },
  { name: "Purpose of life", img: datingStories },
  { name: "Spirituality", img: datingStories },
  { name: "Letting go", img: datingStories },
  { name: "Your life story", img: datingStories },
  { name: "Gender identity  ", img: datingStories },
  { name: "Parenting", img: datingStories },
  { name: "Battling Cancer", img: datingStories },
];

const howWorksSteps = [
  {
    img: search,
    name: "Find a topic of your interest",
    description: "from over 75+ curated topics",
  },
  {
    img: task,
    name: "Register or create a room",
    description: "Max 6 in one video call",
  },
  {
    img: heart,
    name: "Join on schedule",
    description: "Remain anonymous if you wish",
  },
];

const testimonials = [
  {
    img: "https://i.pravatar.cc/80?img=68",
    name: "Vasu",
    description:
      "I love this unique and well-crafted platform - Video calls, the safety features, “rules of engagement”, “your take on a topic”, the concept of “hearts”, “resources” etc.",
  },
  {
    img: "https://i.pravatar.cc/80?img=44",
    name: "Tamanna",
    description:
      "At YLanes, everyone is an equal participant; No advice, No counselling, No gyan, No spectators, only sharing stories... and I love it!",
  },
  {
    img: "https://i.pravatar.cc/80?img=52",
    name: "Piyush",
    description:
      "Talking to strangers initially felt scary, but in the small groups of 4-5 people sharing stories on topics we care about, I felt more liberated than in many known circles",
  },
  {
    img: "https://i.pravatar.cc/80?img=26",
    name: "Drishti",
    description:
      "It is great to meet amazing people sharing my interests; I feel understood and accepted and I love exchanging perspectives.",
  },
  {
    img: "https://i.pravatar.cc/80?img=27",
    name: "Aruna",
    description:
      "The topics on YLanes are so well curated, I would like to participate in at least 30 of these conversations",
  },
];

const rooms = [
  {
    img: roomType1,
    title: "Standard",
    description:
      "First come, first serve; Anyone can create or register for these rooms",
    size: "(5 in a room)",
    active: true,
  },
  {
    img: roomType2,
    title: "Premium",
    description:
      "Host has to approve entry; Anyone can create or register for these rooms",
    size: "(5 in a room)",
    active: false,
  },
  {
    img: roomType3,
    title: "Moderated",
    description:
      "First come, first serve, but only a Moderator can create these rooms",
    size: "(1+5 in a room)",
    active: false,
  },
  {
    img: roomType4,
    title: "One-to-One",
    description:
      "Host has to approve entry; Anyone can create or register for these rooms",
    size: "(1+1 in a room)",
    active: false,
  },
];
