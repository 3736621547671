Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Settings5";
exports.labelBodyText = "Settings5 Body";

exports.btnExampleTitle = "CLICK ME";
exports.searchAllAPiEndPoint =
  "bx_block_categories/categories/search_all_records";
exports.searchAPiEndPointPage =
  "bx_block_categories/categories/search_all_records?page=";
exports.postApiMethod = "POST";
exports.deactivateAccountAPiEndPoint =
  "account_block/accounts/deactivate_account";
exports.putApiMethod = "PUT";
exports.getApiMethod = "GET";
exports.patchApiMethod = "PATCH";
exports.submitFeedbackAPiEndPoint = "bx_block_feedback/feedbacks";
exports.getCountryListApiEndPoint = "account_block/accounts/country_list";
exports.getTermsAndPolicyApiEndPoint = "bx_block_admin/policies";
exports.getWalletCoinsApiEndPoint = "bx_block_profile/profiles/";
exports.getConnectionRequestDataApiEndPoint =
  "bx_block_request_management/requests";
exports.acceptRejectFriendConnectionApiEndPoint =
  "bx_block_request_management/requests/";

exports.twilioAPIEndpoint = "bx_block_chat/chat_tokens/get_access_token";
exports.twilioMethod = "GET";

exports.loadUsersEndpoint = "bx_block_chat/chat_tokens/user_chats";
exports.loadUsersMethod = "GET";

exports.acceptAllApiEndPoint =
  "bx_block_request_management/requests/accept_all";
exports.getTermsAndConditionApiEndPoint =
  "bx_block_admin/policies/terms_and_conditions";
exports.getModeratorTermsAndConditionApiEndPoint =
  "account_block/onboarding_questions/moderator_term_condition";

exports.deleteAllDataMethod = "POST";
exports.deleteAllDataAPiEndPoint = "account_block/accounts/delete_account_otp";

exports.verifyOTPApiMethod = "DELETE";

const url = require('../../../framework/src/config.js')
exports.GetMyUpcomingRoomsApiEndPoint = url.baseURL + "/bx_block_different_rooms/rooms/my_upcoming_rooms";
exports.GetMyPastRooms = url.baseURL + "/bx_block_different_rooms/rooms/my_past_rooms"
exports.verifyOtpAPiEndPoint = "account_block/accounts/sms_otp_confirmation";

exports.getFAQsApiEndPoint = "bx_block_admin/policies/faq_list?type=Web";
exports.googleCalendarSyncAPiEndPoint =
  "bx_block_profile/profiles/toggle_google_calendar_sync";

exports.notificationEndPoint = 'bx_block_notifications/notifications';

exports.unreadCountAPIEndpoint = 'notifications/notifications';
exports.unreadCountAPIMethod = 'PUT';

exports.markAllAsReadEndPoint = 'bx_block_notifications/notifications/read_all';
exports.markAllAsReadMethod = 'PUT';

exports.deleteNotificationEndPoint = 'bx_block_notifications/notifications';
exports.deleteNotificationMethod = 'DELETE';

exports.updateTokenAPIEndpoint = 'account_block/accounts/add_device_token';
exports.updateTokenAPIMethod = 'POST'
// Customizable Area End