import React, { Ref, useEffect, useRef, useState } from "react";
// Customizable Area Start
import RoomDetailsController from "./RoomDetailsController";
import {
  Container, Grid, Dialog, Button, Hidden, Typography,
  TextField, Box, Modal, Menu, MenuItem, withWidth,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import UpcomingRoomsComponent from './RoomDetailsComponents/UpcomingRoomsComponent'
import HostTake from "./RoomDetailsComponents/HostTake";
import {
  backarrow, goldenTick, blueTick, clock, attach, as,
  images, addIcon, createRoomSuccess, roomUnavailable,
} from "../assets";
import { RoomCard, ParticipantCard, YourCard } from "./RoomDetailsComponents/RoomDetailsSkeletons";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { RoomTimesData } from "../CategoriesAndRoomTypes/Categories.web";
import "./RoomDetails.css";
import { Link, withRouter } from "react-router-dom";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import AlertBlock from "../../../alert/src/Alert.web";
import RefillWallet from "../../../Payments/src/refillwallet/RefillWallet.web";
import actionCable from "actioncable";
import RulesOfEngagement from "./RulesOfEngagement";
import { checkDifference } from "../MyRooms/RoomsController";
import moment, { Moment } from "moment";
import LateJoinScreen from "./LateJoinScreen";
import UserTake from './RoomDetailsComponents/UserTake'
import ShareLink from "./RoomDetailsComponents/ShareLink";
const x = require("../../../../framework/src/config");

const myOwnTernaryCuzWhyNot = (
  condition: any,
  trueValue: any,
  falseValue: any
) => {
  return condition ? trueValue : falseValue;
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFCB00",
    },
    secondary: {
      main: "#010039",
    },
  },
});

const getDateTime = (dateTime: string) => {
  return `${moment(dateTime).format("Do MMMM YYYY")} at ${moment(
    dateTime
  ).format("h:mm A")}`;
};

type RemoveFunction = (index: number) => void;
type PushFunction = (value: any) => void;

let res: any;

class RoomDetails extends RoomDetailsController {
  textRef: any;
  editedUrls: any;
  constructor(props: any) {
    super(props);
    // @ts-ignore
    this.myRef = React.createRef();
    // @ts-ignore
    this.rulesRef = React.createRef();
    this.editedUrls = React.createRef();
    let room_id_url = window.location.href.split("/")[4];

    // const dummy_data = this.props.history.location.state.upcomingRoomsData.map((ele:any)=>{return ele.id})
  }

  componentDidUpdate() {
    if (
      this.props.history.location.pathname.split("/")[2] !==
      this.state.roomDetails?.id
    ) {
      let roomId = this.props.history.location.pathname.split("/")[2];
      let finded = this.props.history.location.state?.upcomingRoomsData?.find(
        (item: any) => item.id == roomId
      );
      // if (finded) {
      //   this.setState({
      //     roomDetails: finded,
      //     roomId: roomId,
      //     registrantsDetails: finded.attributes.participants.data,
      //   });
      //   room_details = finded;
      //   this.forceUpdate();
      // }
    }
  }

  stateSetter = (updateFunc: (prev: any) => any) => {
    return new Promise((resolve, reject) => {
      this.setState(updateFunc, () => {
        resolve(true);
      });
    });
  }

  async componentDidMount() {
    const cable = actionCable.createConsumer(`${x.baseURL}/cable`);
    let account_id = parseInt(localStorage.getItem("userId") || "0");

    const subscription = cable.subscriptions.create(
      {
        channel: "AccountsChannel",
        account_id,
      },
      {
        connected: () => {
          console.log("CONNECTED");
        },
        disconnected: () => {
          console.log("DISCONNECTED");
        },
        received: (data) => {
          this.setState({ roomStartNotification: data.room_id });
        },
      }
    );

    /*********************************** */
    this.getHostDetails(this.state.roomId);

    const roomsData = this.props.history.location.state?.upcomingRoomsData;

    this.getRoomDetails(this.state.roomId);
    // const data = this.props.history.location.state?.upcomingRoomsData[res];
    // console.log(data, 'PROP DATA', this.state.roomDetails, 'ROOM DETAİl')
    // if (roomsData) {
    //   this.setRoomData(previousURL, data);
    // } else {
    // }

    this.getRegistrants(this.state.roomId);
    await this.getCurrentWeekData();

    // mobile responsive
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  async componentWillUnmount(): Promise<void> {
    window.removeEventListener("resize", this.handleResize);
  }

  // utils functions
  isTimeArrived = (dt1: string) => {
    const date1 = new Date(dt1);
    const date2 = new Date();

    if (date2.getTime() > date1.getTime()) {
      return true;
    }

    const diffInSeconds: number = Math.floor(
      Math.abs((date2.getTime() - date1.getTime()) / 1000)
    );

    // 300 seconds = 5 min.
    return diffInSeconds <= 300;
  };

  getCurrentWeekData = async () => {
    var currentDate = moment();

    var weekStart = currentDate;
    var weekEnd = currentDate.add(7);

    var days = [];

    for (var i = 0; i <= 6; i++) {
      let dateobj = moment(weekStart)
        .add(i, "days")
        .format("MMMM-DD-ddd")
        .split("-");
      let obj = {
        month: dateobj[0],
        date: dateobj[1],
        day: dateobj[2],
        fullDateObj: moment(weekStart).add(i, "days").format("YYYY-MM-DD"),
      };
      days.push(obj);
    }

    this.setState({
      currentWeekSlot: days,
      currentMonth: days[0].month,
      currentTime: moment().format("LT"),
    });

    let todayDay = moment().format("DD");

    this.setState({ selectedDate: todayDay });
    return days;
  };

  createRoomSchema = Yup.object().shape({
    yourTake: Yup.string()
      .required("Please enter Your Take")
      .min(50, "Your Take must be at least 50 characters"),
  });

  handleClose = () => {
    this.props.onclose(false);
  };

  handleCloseAlert = () => {
    this.setState({ isMessagePop: false });
  };

  navigateToRoom = () => {
    const roomDetails = this.state.roomDetails;

    this.props.history.push(`/adjust-settings/${roomDetails.id}`, {
      state: {
        is_host: roomDetails?.attributes?.is_host,
        roomSID: roomDetails?.attributes?.sids?.room_sid,
        conversationSID: roomDetails?.attributes?.sids?.conversation_sid,
      },
    });
  };

  enterRoom = () => {
    this.navigateToRoom();
  };

  //  Refill wallet open modal
  handleRefillOpen = () => {
    // @ts-ignore
    this.myRef.current.handleOpen();
  };

  handleRulesOpen = () => {
    // @ts-ignore
    this.rulesRef.current.handleOpen();
  };

  handleReportClick = (
    event: React.MouseEvent<HTMLSpanElement>,
    resourceId: number
  ) => {
    this.setState({
      anchorEl: event.currentTarget,
      reportResourceId: resourceId,
    });
  };

  handleReportClose = () => {
    this.setState({ anchorEl: null });
    this.toggleReasonOpen(false);
  };

  toggleReasonOpen = (isReasonOpen: boolean) => {
    this.setState({ isReasonOpen });
  };

  handleReportMenuItemClick = (reason: string) => {
    this.reportResource(this.state.reportResourceId as number, reason);
    this.handleReportClose();
  };

  getDateFormat = () => {
    let OurNewDateFormat: any = new Date(this.state.roomDetails?.attributes?.start_time)
      .toLocaleString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        month: "2-digit",
        year: "numeric",
        day: "2-digit",
        hour12: true,
        weekday: "long",
      })
      .replace(/,/g, " ");

    OurNewDateFormat = OurNewDateFormat.split(" ");
    let weekday = OurNewDateFormat[0];
    OurNewDateFormat.splice(0, 1).join(" ");
    OurNewDateFormat[2] = ` ${weekday} `;

    return OurNewDateFormat;
  };

  renderRoomButton = () => {
    const { roomDetails } = this.state;
    const isTimeArrive = this.isTimeArrived(roomDetails?.attributes?.start_time)

    const checkUserType = (): string => {
      let type: string = ''
      let details = roomDetails.attributes

      if (isTimeArrive && (details.is_host || details.is_registered)) {
        type = 'arrived'
      } else if (details.is_host) {
        type = 'host'
      } else if (details.is_registered) {
        type = 'parti'
      } else if (details.remaining_seats > 0) {
        type = 'joinable'
      } else {
        type = 'full'
      }
      return type
    }

    const userType: string = checkUserType();

    const selectFunctionAsType = () => {
      let returningFunc: any = () => { }
      if (userType === 'arrived') {
        const joined_users = roomDetails?.attributes?.joined_users;
        const user_id = localStorage.getItem("userId");
        const diff = checkDifference(
          new Date(this.state.roomDetails?.attributes.start_time),
          new Date()
        );
        if (joined_users && joined_users.includes(user_id)) {
          returningFunc = this.navigateToRoom()
        } else if (diff < 0 && Math.abs(diff) >= 15) {
          returningFunc = this.toggleTimePassedModal()
        } else {
          returningFunc = this.handleRulesOpen()
        }
      } else if (userType === 'host') {
        returningFunc = this.CancelModalOpen()
      } else if (userType === 'parti') {
        returningFunc = this.RemoveModalOpen()
      } else if (userType === 'joinable') {
        this.setState((prev) => ({
          registerModal: true,
          congratulationModal: {
            ...prev.congratulationModal,
            dateTime: roomDetails?.attributes?.start_time,
            room_name: roomDetails?.attributes?.sub_category_name,
          },
        }));
      }
      return returningFunc
    }

    let buttonText: string = ''

    switch (userType) {
      case 'arrived':
        buttonText = 'ENTER'
        break;
      case 'host':
        buttonText = 'CANCEL'
        break;
      case 'parti':
        buttonText = 'CANCEL'
        break;
      case 'joinable':
        buttonText = 'REGISTER'
        break;
      default:
        buttonText = 'FULL'
        break;
    }

    return (
      <>
        <Hidden xsDown>
          <Typography
            style={{
              color: "#7f8e9d",
              flex: 1,
              textAlign: "center",
              fontSize: "14px",
              padding: "5px",
              width: "100%",
            }}
            variant="body1"
          >
            You can ENTER 5 min before the start time.
          </Typography>
        </Hidden>
        <strong
          className="fs20 fw600 colorBlue pointer"
          onClick={selectFunctionAsType}
        >
          {buttonText}
        </strong>
      </>
    );
  }

  dynamicUserName = () => {
    if (
      this.state.roomDetails?.attributes?.host?.data?.id == localStorage.getItem("userId")
    ) {
      return "You";
    } else {
      if (this.state.roomDetails?.attributes?.is_anonymously) {
        return this.state.roomDetails?.attributes?.anonymously_name;
      } else {
        return this.state.roomDetails?.attributes?.host?.data?.attributes?.full_name;
      }
    }
  };

  changeTime = (time: any, type: "start_time" | "end_time") => {
    const currentTimeString = moment().format("h:mm:ss a");
    const currentTime = moment(currentTimeString, "h:mm:ss a");
    const userSelectdTime = moment(time);

    if (userSelectdTime.isBefore(currentTime)) {
      alert("Please select a time in the future");
      return;
    }

    if (
      type === "end_time" &&
      userSelectdTime.isBefore(this.state.room_times.start_time)
    ) {
      alert("End time should be greater than start time");
      return;
    }

    if (!userSelectdTime.isValid()) return;

    this.setState(
      (prev) => ({
        ...prev,
        room_times: {
          ...this.state.room_times,
          [type]: userSelectdTime,
          date: prev.room_times.date
            ? prev.room_times.date
            : moment().format("YYYY-MM-DD"),
        },
      }),
      () => this.handleCallAPI()
    );
  };

  updatedDate = (fullDateObj: string, current_time: Moment) => {
    const newDate = moment(fullDateObj);

    const updatedDateTime = current_time.clone().set({
      year: newDate.year(),
      month: newDate.month(),
      date: newDate.date(),
    });

    return updatedDateTime;
  };

  renderLateJoinScreen = () => {
    return (
      <>
        {this.state.isTimePassed && (
          <LateJoinScreen
            isOpen={this.state.isTimePassed}
            handleClose={this.toggleTimePassedModal}
          />
        )}
      </>
    );
  };

  renderRoomPrice = () => {
    const { roomDetails } = this.state;

    return (
      <div className="daf" style={{ minWidth: '50px' }}>
        {!roomDetails?.attributes?.is_host && (
          <>
            {" "}
            <img src={as} alt="" className="w18 mr10" />
            <strong className="fs20 fw600 colorBlue pointer">
              {roomDetails?.attributes?.room_price}
            </strong>
          </>
        )}
      </div>
    );
  };

  getHostProfilePicture = () => {
    return this.state.roomDetails?.attributes?.host?.data?.attributes?.profile_image ===
      "" || this.state.roomDetails?.attributes?.is_anonymously
      ? images
      : this.state.roomDetails?.attributes?.host?.data?.attributes?.profile_image;
  };

  renderVerifiedTick = () => {
    return (
      <>
        {(this.state.roomDetails?.attributes?.host?.data?.attributes?.is_moderator ||
          this.state.roomDetails?.attributes?.host?.data?.attributes?.is_superhost) && (
            <img
              src={
                this.state.roomDetails?.attributes?.host?.data?.attributes?.is_moderator
                  ? goldenTick
                  : blueTick
              }
              alt="verified_tick"
              width={15}
            />
          )}
      </>
    );
  };

  hasResouce = (elem: any) => {
    return elem?.attributes?.resources?.length > 0;
  };

  getProfilePicture = (elem: any) => {
    return elem?.attributes?.participant?.data?.attributes?.profile_image ===
      "" || elem?.attributes?.is_anonymously
      ? images
      : elem?.attributes?.participant?.data?.attributes?.profile_image;
  };

  renderRoomCard = () => {
    const OurNewDateFormat = this.getDateFormat();
    if (this.state.isRoomDetailLoading) {
      return (
        <RoomCard />
      )
    } else {
      return (
        <section
          className="room-details bgWhite mb20 blueCard"
        >
          <div className="bBottom p15">
            <div className="daf flexWrap mb10">
              <h3 className="fs24 colorBlue fw600 mt0 mb0">
                {this.state.roomDetails?.attributes?.topic_name}
              </h3>
              <ShareLink roomDetail={this.state.roomDetails} />
            </div>
            <div className="d-flex mb10" style={{ justifyContent: 'space-between' }}>
              <p className="fs14 fw500 colorGrey mt0 mb0">
                {this.state.roomDetails?.attributes?.sub_category_name}
              </p>
              <div>
                <span className="fs12 mt0 mb0 fw500 colorGrey customTypeBox">
                  {this.state.roomDetails?.attributes?.room_type_name}
                </span>
                <span className="fs12 mt0 mb0 fw500 colorGrey customTypeBox">
                  {this.state.roomDetails?.attributes?.is_global ? 'Global' : 'Local'}
                </span>
              </div>
            </div>
            <p className="fs14 colorGrey fw500 mb30">
              {this.state.roomDetails?.attributes?.description}
            </p>
            <p className="fs14 colorBlue fw500 mb15">
              {OurNewDateFormat}
            </p>
          </div>
          <div className="text-end daf p15 mr10">
            {this.renderRoomPrice()}
            <strong
              className="fs20 fw600 colorBlue pointer d-flex"
              style={{ justifyContent: 'flex-end', width: '100%' }}
              id="roomStatus"
            >
              {this.state.roomDetails?.attributes && this.renderRoomButton()}
            </strong>
          </div>
        </section>
      )
    }
  }

  renderYourTake = () => {
    if (this.state.isUserLoading && this.state.isHostLoading) {
      return (
        <YourCard />
      )
    } else if (this.state.roomDetails?.attributes?.is_host) {
      return (
        <section>
          <HostTake
            updateNewResource={this.updateNewResource}
            roomDetails={this.state.roomDetails}
            hostDetails={this.state.hostDetails}
            updateRequest={this.updateRequest}
            callUpdateYourTakeAPI={this.callUpdateYourTakeAPI}
            history={this.props.history}
            handleLikeCount={this.handleLikeCount}
            handleReportClick={this.handleReportClick}
          />
        </section>
      )
    } else if (!this.state.roomDetails?.attributes?.is_host && this.state.roomDetails?.attributes?.is_registered) {
      return (
        this.state.registrantsDetails?.map((item: any, index: number) => {
          let userId: number = +JSON.parse(
            localStorage.getItem("userId") as string
          );
          if (item.attributes.account_id === userId) {
            return (
              <UserTake
                key={item.attributes.updated_at}
                updateNewResource={this.updateNewResource}
                roomDetails={this.state.roomDetails}
                userDetails={item}
                updateRequest={this.updateRequest}
                callUpdateYourTakeAPI={this.callUpdateYourTakeAPI}
                history={this.props.history}
                handleLikeCount={this.handleLikeCount}
                handleReportClick={this.handleReportClick}
              />
            )
          }
        })
      )
    }
  }

  renderParticipant = () => {
    if (this.state.isUserLoading) {
      return (
        <ParticipantCard />
      )
    } else {
      return (
        <>
          {!this.state.roomDetails?.attributes?.is_host &&
            <HostTake
              updateNewResource={this.updateNewResource}
              roomDetails={this.state.roomDetails}
              hostDetails={this.state.hostDetails}
              updateRequest={this.updateRequest}
              callUpdateYourTakeAPI={this.callUpdateYourTakeAPI}
              history={this.props.history}
              handleLikeCount={this.handleLikeCount}
              handleReportClick={this.handleReportClick}
            />
          }
          {/* Host Details Section  */}
          {this.state.registrantsDetails?.map((item: any, index: number) => {
            let userId: number = +JSON.parse(
              localStorage.getItem("userId") as string
            );
            if (item.attributes.account_id !== userId) {
              return (
                <UserTake
                  updateNewResource={this.updateNewResource}
                  roomDetails={this.state.roomDetails}
                  userDetails={item}
                  updateRequest={this.updateRequest}
                  callUpdateYourTakeAPI={this.callUpdateYourTakeAPI}
                  history={this.props.history}
                  handleLikeCount={this.handleLikeCount}
                  handleReportClick={this.handleReportClick}
                />
              )
            }
          })}
        </>
      )
    }
  }

  renderRegisterModal = () => {
    const { addResources, registerAnonymously, walletYCoins } = this.state;

    return (
      <>
        {this.state.registerModal === true && (
          <section className="borderYellow p15 radius8 mb15 bgWhite">
            <Formik
              initialValues={{
                yourTake: "",
                resource: [""],
                anonymus: false,
                screenName: "",
                hasError: [true],
              }}
              validationSchema={this.createRoomSchema}
              onSubmit={(values, { setSubmitting }) => {
                const payload = new FormData();
                payload.append(
                  "registered_room[room_id]",
                  `${this.state.roomId}`
                );
                payload.append(
                  "registered_room[is_anonymously]",
                  `${this.state.registerAnonymously}`
                );
                values.resource.forEach((item: any) => {
                  payload.append(
                    "registered_room[resources_attributes][][url]",
                    item
                  );
                });
                // payload.append("registered_room[resources_attributes][][url]", values.resource)
                payload.append(
                  "registered_room[anonymously_name]",
                  values.screenName
                );
                payload.append("registered_room[your_take]", values.yourTake);
                for (var pair of payload.entries()) {
                  // console.log(pair[0] + ', ' + pair[1]);
                }
                this.registerRoom(
                  payload,
                  this.state.roomDetails.attributes.is_registered
                );
                this.setState({ registerModal: false });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <h3 className="fs18 colorBlue fw500 mt0 mb10">Your Take</h3>
                  <div className="room-details-register">
                    {/* <textarea className="w-100"></textarea> */}
                    <TextField
                      className="register-textarea"
                      multiline
                      name="yourTake"
                      variant="outlined"
                      value={values.yourTake}
                      placeholder="Please share what you will be bringing to this conversation - a subtopic, experience, question, perspective, some ideas, etc. You can also share any relevant resources such as YouTube links, websites, Instagram / Pinterest pages, etc. using the link below"
                      onChange={(e) => {
                        e.target.value = e.target.value?.trimStart();
                        handleChange(e);
                      }}
                      // margin="normal"
                      inputProps={{
                        maxLength: 300,
                      }}
                      helperText={`${300 - values.yourTake.length} Characters`}
                      onBlur={handleBlur}
                    />
                    {errors.yourTake && (
                      <span
                        style={{
                          display: "block",
                          color: "red",
                          fontSize: "12px",
                          textAlign: "start",
                          marginBottom: "10px",
                        }}
                      >
                        {errors.yourTake && touched.yourTake && errors.yourTake}
                      </span>
                    )}
                  </div>
                  <div
                    className="dFlexAlignCenter mb10"
                    onClick={() => this.setState({ addResources: true })}
                  >
                    <label
                      htmlFor="resources"
                      className="fs15 fw600 colorBlue add-resources"
                    >
                      {" "}
                      <img src={attach} alt="" /> Add resources
                    </label>
                  </div>
                  {myOwnTernaryCuzWhyNot(
                    addResources === true,
                    <div className="room-details-register mb5 pr">
                      <FieldArray
                        name="resource"
                        render={({
                          remove,
                          push,
                        }: {
                          remove: RemoveFunction;
                          push: PushFunction;
                        }) => (
                          <>
                            {values.resource.map(
                              (resourceData: any, index: number) => (
                                <div
                                  style={{
                                    marginTop: myOwnTernaryCuzWhyNot(
                                      index == 0,
                                      undefined,
                                      "16px"
                                    ),
                                    position: "relative",
                                  }}
                                  key={index}
                                >
                                  <input
                                    name={`resource.${index}`}
                                    value={resourceData}
                                    placeholder={"Enter your resource link"}
                                    onChange={(e) => {
                                      e.target.value =
                                        e.target.value?.trimStart();
                                      handleChange(e);
                                    }}
                                    onBlur={(e) => {
                                      e.target.value =
                                        e.target.value?.trimStart();
                                      let pattern =
                                        /(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
                                      let result = pattern.test(e.target.value);

                                      if (
                                        !result &&
                                        values.resource[index] == ""
                                      ) {
                                        result = true;
                                      }
                                      const arr = [...values.hasError];
                                      arr[index] = result;
                                      setFieldValue(`hasError`, arr);

                                      handleBlur(e);
                                    }}
                                    style={{
                                      width: "100%",
                                      borderRadius: "10px",
                                      height: "45px",
                                      border: "0.1px solid #DBDAE3",
                                      fontSize: "16px",
                                      paddingLeft: "10px",
                                    }}
                                    autoComplete="off"
                                  />

                                  {index > 0 && (
                                    <span
                                      style={{
                                        fontSize: "23px",
                                        fontWeight: "bolder",
                                        color: "grey",
                                        cursor: "pointer",
                                        top: "4px",
                                        right: "10px",
                                        position: "absolute",
                                      }}
                                      onClick={() => {
                                        remove(index);
                                        const arr = [...values.hasError].splice(
                                          index,
                                          1
                                        );
                                        setFieldValue(`hasError`, arr);
                                      }} // remove a friend from the list
                                    >
                                      -
                                    </span>
                                  )}
                                  <span
                                    style={{
                                      display: "block",
                                      color: "red",
                                      fontSize: "12px",
                                      textAlign: "start",
                                    }}
                                  >
                                    {!values.hasError[index] &&
                                      "* Please enter valid url!"}
                                    {/* // {errors[`resource${index+1}`] && errors.resource} */}
                                  </span>
                                </div>
                              )
                            )}

                            {values.resource.length < 3 && (
                              <img
                                src={addIcon}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  const arr = [...values.hasError, true];
                                  setFieldValue(`hasError`, arr);
                                  push("");
                                }}
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  top: "13px",
                                  right: "13px",
                                  position: "absolute",
                                  cursor: "pointer",
                                }}
                                alt="+"
                              />
                            )}
                          </>
                        )}
                      />
                    </div>,
                    null
                  )}
                  <div
                    className="dFlexAlignCenter mb10"
                    onClick={() => this.setState({ registerAnonymously: true })}
                  >
                    <input type="checkbox" id="register" />
                    <label
                      htmlFor="register"
                      className="fs15 ml10 fw600 colorBlue"
                    >
                      REGISTER ANONYMOUSLY
                    </label>
                  </div>
                  {registerAnonymously === true ? (
                    <div className="room-details-register">
                      <label
                        htmlFor="register"
                        className="fs15 fw600 colorBlue"
                      >
                        Screen Name*
                      </label>
                      <br />
                      <input
                        type="text"
                        className="w-100"
                        placeholder="Screen Name"
                        name="screenName"
                        value={values.screenName}
                        onChange={(e) => {
                          e.target.value = e.target.value?.trimStart();
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      />
                    </div>
                  ) : null}
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      background:
                        walletYCoins < this.state.roomDetails.attributes.room_price
                          ? "#dedede"
                          : "#FFCC00",
                      color:
                        walletYCoins < this.state.roomDetails.attributes.room_price
                          ? "#fff"
                          : "#010138",
                      width: "100%",
                      padding: "15px 0px !important",
                      textTransform: "none",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                    disabled={
                      walletYCoins < this.state.roomDetails.attributes.room_price
                        ? true
                        : false
                    }
                  >
                    <Typography style={{ fontWeight: 600, fontSize: "18px" }}>
                      Register
                    </Typography>
                  </Button>
                </form>
              )}
            </Formik>
            {walletYCoins < this.state.roomDetails?.attributes?.room_price && (
              <div className="text-center" onClick={this.handleRefillOpen}>
                <span className="fs14 colorBlue fw600 textUnderline">
                  Refill your wallet
                </span>
              </div>
            )}
          </section>
        )}
      </>
    );
  };

  renderRoomTimesFilter = () => {
    return (
      <>
        <div className="room-times bgWhite">

          <Box className="bBottom" display='flex' alignItems='center' justifyContent='space-between'>
            <h4 className="fs16 fw500 pb10 mt0 mb0">Room Times</h4>
            {
              this.state.room_times.type && <h4
                onClick={() => this.handleRoomTime(this.state.room_times.type, null)} className="fs16 fw500 pb10 mt0 mb0 cursor"
              >
                Clear Filter
              </h4>
            }
          </Box>

          {RoomTimesData.map((elem) => {
            return (
              <div className="daf data bBottom" key={elem.key}>
                <label className="fs14 fw500" htmlFor={elem.key}>
                  {elem.value}
                </label>
                <input
                  type="checkbox"
                  checked={this.state.room_times.type === elem.key}
                  id={elem.key}
                  name={elem.key}
                  onChange={() => this.handleRoomTime(elem.key, null)}
                />
              </div>
            );
          })}

          <div className="daf data bBottom">
            <label className="fs14 fw500" htmlFor={"custom"}>
              Custom
            </label>
            <input
              type="checkbox"
              checked={this.state.room_times.type === "custom"}
              id={"custom"}
              name={"custom"}
              onChange={() => {
                this.handleRoomTime("custom", null);
                this.setState((prev) => ({
                  room_times: {
                    ...prev.room_times,
                    date: "",
                    start_time: moment().startOf("day"),
                    end_time: moment().endOf("day"),
                  },
                }));
              }}
            />
          </div>

          {/* Custom Calendar Part  */}
          {this.state.room_times.type === "custom" && (
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="p15">
                  <p className="fs18 fw600 fw500 mt0 mb15">
                    {moment().format("MMMM YYYY")}
                  </p>

                  <div className="rooms-details-calendar pb15">
                    {this.state?.currentWeekSlot.map(
                      (value: any, index: any) => {
                        return (
                          <div
                            key={value.fullDateObj || index}
                            className="text-center ml5"
                            onClick={() => {
                              this.setState(
                                (prev) => ({
                                  room_times: {
                                    ...prev.room_times,
                                    date: value?.fullDateObj,
                                    start_time: this.updatedDate(
                                      value.fullDateObj,
                                      this.state.room_times.start_time
                                    ),
                                    end_time: this.updatedDate(
                                      value.fullDateObj,
                                      this.state.room_times.end_time
                                    ),
                                  },
                                }),
                                () => {
                                  this.handleCallAPI();
                                }
                              );
                            }}
                          >
                            <span className="fs12 fw500">{value?.day}</span>
                            <div
                              className={
                                this.state.room_times.date ==
                                  value?.fullDateObj
                                  ? "calen-div dateSelectedClass"
                                  : "calen-div"
                              }
                            >
                              <span className="fs16 fw500">
                                {value?.date}
                              </span>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                  {/* Calendar Part  */}
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0 1rem 1rem",
                  }}
                >
                  <KeyboardTimePicker
                    keyboardIcon={<img height="16px" src={clock} />}
                    inputVariant="outlined"
                    onChange={(time) => this.changeTime(time, "start_time")}
                    value={this.state.room_times.start_time}
                  />
                  <Typography component="span" style={{ margin: "5px" }}>
                    to
                  </Typography>
                  <KeyboardTimePicker
                    keyboardIcon={<img height="16px" src={clock} />}
                    inputVariant="outlined"
                    onChange={(time) => this.changeTime(time, "end_time")}
                    value={this.state.room_times.end_time}
                  />
                </div>
              </MuiPickersUtilsProvider>
            </div>
          )}
        </div>
      </>
    );
  };

  renderReasonMenu = () => {
    const { anchorEl, isReasonOpen } = this.state;

    return (
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={this.handleReportClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => this.handleReportMenuItemClick("Explicit Content")}
        >
          Explicit Content
        </MenuItem>
        <MenuItem onClick={() => this.handleReportMenuItemClick("Phishing")}>
          Phishing
        </MenuItem>
        <MenuItem onClick={() => this.handleReportMenuItemClick("Violence")}>
          Violence
        </MenuItem>
        <MenuItem
          style={{ borderBottom: "0px" }}
          onClick={() => this.toggleReasonOpen(true)}
        >
          Other
        </MenuItem>
        <Box
          style={isReasonOpen ? { display: "grid" } : { display: "none" }}
          mx={2}
          mb={2}
        >
          <TextField
            inputRef={(element) => (this.textRef = element)}
            id="outlined-multiline-static"
            label="Type in your reason"
            multiline
            minRows={4}
            maxRows={4}
            variant="outlined"
          />
          <br />
          <Button
            onClick={() => this.handleReportMenuItemClick(this.textRef.value)}
            variant="contained"
            color="secondary"
          >
            Submit
          </Button>
        </Box>
      </Menu>
    );
  };

  render() {
    return (
      <>
        <ThemeProvider theme={theme}>
          <AlertBlock
            data={{
              show: this.state.isMessagePop,
              close: this.handleCloseAlert,
              msg: this.msg,
              type: this.type,
              vertical: "top",
              horizontal: "center",
            }}
          />
          {/* @ts-ignore */}
          <RefillWallet ref={this.myRef} />
          <RulesOfEngagement
            roomDetails={this.state.roomDetails}
            // @ts-ignore
            ref={this.rulesRef}
            cb={this.enterRoom}
          />

          {/* if users joins 15 minutes late. */}
          {this.renderLateJoinScreen()}

          <section className="room-details-section">
            <Container>
              {/* <div className="room-details-bg-img" /> */}
              <Grid container spacing={2}>
                {/* Grid 1 Start  */}
                <Grid item xs={12} sm={12} md={12} lg={8} style={{ borderRight: '1px solid #c7cad9', padding: '8px 20px' }}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <div>
                        <Grid
                          container
                          className="backAndRoomDetail"
                          item
                          xs={12}
                        >
                          <img
                            src={backarrow}
                            alt=""
                            className="w18 mr15 back_arrow_mobile"
                            onClick={() => window.history.back()}
                          />
                          <h2 className="fs22 colorBlue fw600 mt0">
                            <img
                              src={backarrow}
                              alt=""
                              className="w18 mr15 back_arrow"
                              onClick={() => window.history.back()}
                            />
                            Room Details
                          </h2>
                        </Grid>
                        {this.renderRoomCard()}
                        <h3 className="fs20 colorBlue fw600 mb15">
                          Participants and Resources
                        </h3>
                        <Dialog
                          open={this.state.congratulationModal.isOpen}
                          onClose={this.CongratulationRegisteringModalClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          PaperProps={{ style: { maxWidth: "724px" } }}
                        >
                          {/* <CongratulationsRegistering /> */}
                          <Box py={4} px={10} textAlign="center">
                            <h3 className="fs24 fw500 colorBlue mb20 mt0">
                              Congratulations on Registering for{" "}
                              {this.state.congratulationModal.room_name} Room on{" "}
                              {getDateTime(
                                this.state.congratulationModal.dateTime
                              )}
                            </h3>
                            <img
                              src={createRoomSuccess}
                              alt=""
                              className="h_300 mb30"
                            />
                            <ul>
                              <li
                                className="fs14 fw500 colorGrey mb5"
                                style={{
                                  listStyle: "unset",
                                  textAlign: "start",
                                }}
                              >
                                Please note, entry to the room will be shut 15
                                min after the scheduled start time.
                              </li>
                              <li
                                className="fs14 fw500 colorGrey mb5"
                                style={{
                                  listStyle: "unset",
                                  textAlign: "start",
                                }}
                              >
                                You may cancel your registration, without
                                penalty, upto 30 min from the start time.
                              </li>
                              <li
                                className="fs14 fw500 colorGrey mb5"
                                style={{
                                  listStyle: "unset",
                                  textAlign: "start",
                                }}
                              >
                                Premium and One-to-One room registrations will
                                require approval from the host (unapproved
                                registrants will have their YCoins refunded)
                              </li>
                            </ul>
                            {/* <Link to="#" className="fs15 fw500 colorBlue see-feedback">Refer a friend</Link> */}
                            <button
                              className="btn btn-blue fs15 fw500"
                              style={{ cursor: "pointer" }}
                              onClick={this.CongratulationRegisteringModalClose}
                            >
                              Continue
                            </button>
                          </Box>
                        </Dialog>

                        {/* Cancel Modal  */}
                        <Modal
                          open={this.state.cancelModal}
                          onClose={this.CancelModalClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <section className="modal p15">
                            <h3 className="fs15 colorBlue fw500 mb10 mt0">
                              Are you sure you want to cancel ?
                            </h3>
                            <p className="fs14 colorGrey fw500 mt0 mb15">
                              Your YCoins will be refunded to you as per our
                              cancellation policy. Visit
                              <Link
                                to="/Setting"
                                className="colorBlue fw600 ml5 mr5"
                              >
                                FAQs
                              </Link>
                              for more details.
                            </p>
                            <div className="daf">
                              <button
                                className="btn-transparent fs15 colorBlue fw500 width45"
                                onClick={() => this.CancelModalClose()}
                              >
                                No
                              </button>
                              <button
                                className="btn-blue fs15 colorWhite fw500 width45"
                                onClick={() =>
                                  this.myTabDeleteRoom(
                                    parseInt(this.state.myRoomTabId)
                                  )
                                }
                              >
                                Yes
                              </button>
                            </div>
                          </section>
                        </Modal>
                        {/* Cancel Modal  */}

                        {/* Remove Modal  */}
                        <Modal
                          open={this.state.removeModal}
                          onClose={this.RemoveModalClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <section className="modal p15">
                            <h3 className="fs15 colorBlue fw500 mb10 mt0">
                              Are you sure you want to retract your registration
                              request?
                            </h3>
                            <div className="daf">
                              <button
                                className="btn-transparent fs15 colorBlue fw500 width45"
                                onClick={() => this.RemoveModalClose()}
                              >
                                No
                              </button>
                              <button
                                className="btn-blue fs15 colorWhite fw500 width45"
                                onClick={() =>
                                  this.removingFromRoom(
                                    parseInt(this.state.myRoomTabId)
                                  )
                                }
                              >
                                Yes
                              </button>
                            </div>
                          </section>
                        </Modal>
                        {/* Remove Modal  */}

                        {/* Host Details Section  */}
                        {this.renderParticipant()}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Grid 1 End  */}

                {/* Grid 3 Start  */}
                <Grid item xs={12} sm={12} md={12} lg={4} className="scrollbarY upcoming-section">
                  {this.renderYourTake()}
                  <h2 className="fs22 colorBlue fw600 mb10 mt20">
                    Upcoming Rooms
                  </h2>
                  {/* After clicking on register section */}
                  {this.renderRegisterModal()}

                  {/* Upcoming Rooms Cards Section  */}
                  <section>
                    <UpcomingRoomsComponent stateSetter={this.stateSetter} state={this.state} isRoomsLoading={this.state.isRoomsLoading} history={this.props.history}
                      RemoveModalOpen={this.RemoveModalOpen} CancelModalOpen={this.CancelModalOpen} toggleTimePassedModal={this.toggleTimePassedModal} handleRulesOpen={this.handleRulesOpen}
                    />
                  </section>
                  {/* Upcoming Rooms Cards Section  */}
                </Grid>
                {/* Grid 3 End  */}
              </Grid>
            </Container>
          </section>

          {/* reason menu */}
          {this.renderReasonMenu()}

          <Dialog aria-labelledby="unavailable-dialog-title" open={this.state.isUnavailableOpen}>
            <Box textAlign="center" py={4}>
              {this.state.unavailableReason === "BLOCK" ? (
                <img src={roomUnavailable} alt="room unavailable" />
              ) : (
                <Box display="flex" justifyContent="center">
                  <Box
                    display="flex"
                    justifyContent="center"
                    gridGap="8px"
                    borderRadius="12px"
                    border="1px solid #E0E0E0"
                    paddingX="16px"
                  >
                    <Typography style={{ fontSize: '48px' }}>
                      {this.state.roomDetails?.attributes?.host?.data?.attributes?.country_details?.flag}
                    </Typography>
                    <Box textAlign="left" alignSelf="center">
                      <Typography style={{ fontSize: '10px', fontWeight: 400, color: '#979797' }}>
                        Room available in
                      </Typography>
                      <Typography style={{ fontSize: '16px', fontWeight: 700, color: '#010039' }}>
                        {this.state.roomDetails?.attributes?.host?.data?.attributes?.country_details?.name}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
              <Box my={2}>
                <Typography style={{ fontSize: '24px', fontWeight: 600, color: '#1E3354' }}>
                  {this.state.unavailableReason === "BLOCK" ? "Room Unavailable" : "Room unavailable in your country"}
                </Typography>
                <Typography style={{ fontSize: '16px', fontWeight: 500, color: '#7F8E9D' }}>
                  {this.state.unavailableReason === "BLOCK" ? "We're sorry, but this room is not available due to our blocked user protection guidelines" : "Sorry, the host set this as a local room in another country. You can explore other global rooms."}
                </Typography>
              </Box>
              <style>
                {`
                  .rounded-corner-button {
                    border-radius: 25px !important;
                  }
                `}
              </style>
              <Button onClick={() => this.props.navigation.navigate("UpcomingRooms")} className="rounded-corner-button" color="primary" variant="contained">
                <Typography style={{ fontSize: '16px', fontWeight: 700, color: '#010039' }}>
                  Continue
                </Typography>
              </Button>
            </Box>
          </Dialog>
        </ThemeProvider>
      </>
    );
  }
}

export default withRouter(withWidth()(RoomDetails));
// Customizable Area End