// Customizable Area Start
import React, { Component } from 'react';
import CustomizableModalWindow from './customizableModalWindow.web';
import { Card, Typography, Box } from '@material-ui/core';
import { BsFillDoorOpenFill } from 'react-icons/bs';
import './Modals.css';
import { AlertBottomSheet } from './BottomSheet.web';

interface Props {
	reason: string;
	isMobile: boolean;
	handleClose: () => void;
	handleLeave: () => void;
}
export default class AskToLeaveModal extends Component<Props> {
	render() {
		if (this.props.isMobile) {
			return (
				<CustomizableModalWindow id='alert-window' open={true}>
					<AlertBottomSheet type='exit'>
						<Box mt={1} textAlign='center' display='flex' alignItems='center' flexDirection='column'>
							<Typography style={{ color: '#ccc' }}>
								A user has requested you to leave the conversation due to:
							</Typography>

							<Box
								sx={{
									mt: 2,
									mb: 2,
									border: '1px solid #E0E0E0',
									p: 1,
									borderRadius: 10,
									boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
								}}
							>
								<Typography
									style={{
										color: '#010039',
										fontWeight: 600,
										wordBreak: 'break-word',
									}}
								>
									{this.props.reason}
								</Typography>
							</Box>
						</Box>

						<Box
							display='flex'
							alignItems='center'
							justifyContent='space-between'
							flex={1}
							mt={2}
							style={{ gap: '1rem' }}
						>
							<div style={{
								border: '1px solid #262556',
								padding: '1rem 3.5rem',
								borderRadius: '.5rem',
								color: '#262556',
								fontWeight: 500,
								flex: 1
							}}>
								Cancel
							</div>

							<div style={{
								border: '1px solid #FF0100',
								padding: '1rem 3.5rem',
								background: '#FF0100',
								color: '#fff',
								borderRadius: '.5rem',
								fontWeight: 500,
								flex: 1
							}}>
								Leave
							</div>
						</Box>
					</AlertBottomSheet>
				</CustomizableModalWindow>
			)
		}

		return (
			<CustomizableModalWindow id='ask-to-leave-window' open={true}>
				<Card
					className='border-card-modal'
					style={{
						maxWidth: '48rem',
						color: '#262556',
						borderColor: '#FA8072',
						padding: '12px 5px 15px 5px'
					}}
				>
					<div id='modal-text'>
						<BsFillDoorOpenFill className='modal-icon-2' size={35} />
						<Typography style={{ width: "85%", overflowWrap: "break-word" }} variant='h6'>
							{`A user has requested you to leave the conversation due to: ${this.props.reason
								}`}
						</Typography>
					</div>
				</Card>
			</CustomizableModalWindow>
		);
	}
}
// Customizable Area End
