// Customizable Area Start

import React from "react";
import { Dialog, Box, Typography, Button } from "@material-ui/core";
import { createRoomSuccess, calendar } from "../assets";
import moment from "moment";
import "../MyRooms/MyRooms.css";

export interface CreateRoomSucessProps {
  open: boolean;
  selectedValue: string;
  onSuccessClose: (value: string) => void;
  navigation: any;
  routeMap: any;
  history: any;
  onClose?: any;
  values?: any;
  isMobile?: boolean;
  bulletPoints: string[];
  handleSyncToGoogleRoom: () => void;
}

export function CreateRoomSuccessDialog(props: CreateRoomSucessProps) {
  const { onSuccessClose, selectedValue, open, isMobile, bulletPoints, handleSyncToGoogleRoom } = props;

  const handleClose = () => {
    onSuccessClose(selectedValue);
  };

  const dateTime = moment(props.values.roomTime).format(
    "Do MMMM YYYY [at] h:mm A"
  );

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      PaperProps={{
        style: {
          maxWidth: "724px",
          height: isMobile ? "100vh" : "auto",
          borderRadius: isMobile ? "1rem 1rem 0 0" : "10px",
        },
      }}
      className="DialogWithoZeroMargin fullHeight"
    >
      <Box
        py={4}
        px={isMobile ? "2rem" : 10}
        textAlign="center"
        display="flex"
        flexDirection="column"
        flex={isMobile ? "none" : 1}
        height={"97vh"}
        overflow={"auto"}
      >
        {!isMobile && (
          <h3
            className={`${isMobile ? "fs16" : "fs24"} fw500 colorBlue mb20 mt0`}
          >
            Congratulations on creating {props.values.topic} Room on {dateTime}
          </h3>
        )}

        <img
          src={createRoomSuccess}
          alt=""
          className={`${isMobile ? "" : "h_300"} mb30`}
        />

        {isMobile && (
          <Box>
            <h3 className="fs18 fw500 mb10 mt0">
              Congratulations on creating{" "}
            </h3>

            <Box
              sx={{
                mt: 1,
                mb: 3,
                border: "1px solid #E0E0E0",
                p: 1,
                borderRadius: 10,
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
              }}
            >
              <Typography
                style={{
                  color: "#010039",
                  fontWeight: 600,
                  wordBreak: "break-all",
                }}
              >
                {props.values.topic} Room{" "}
              </Typography>
              <Typography
                style={{
                  fontSize: ".8rem",
                  color: "gray",
                }}
              >
                on {dateTime}
              </Typography>
            </Box>
          </Box>
        )}

        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          mt={2}
          pb={3.5}
          onClick={handleSyncToGoogleRoom}
          style={{ gap: '.5rem' }}
        >
          <img src={calendar} alt="dsaf" className="radius4" width='18px' height='18px' />
          <p className="mb0 colorGrey fw600 fs14 mt0 pointer">
            Sync to Google Calendar
          </p>
        </Box>

        <ul>
          {bulletPoints?.map((point) => (
            <li
              className={`${isMobile ? "fs16" : "fs14"} ${isMobile ? "fw400" : "fw500"
                } colorGrey mb5`}
              style={{ listStyle: "unset", textAlign: "start" }}
              key={point}
            >
              {point}
            </li>
          ))}
        </ul>

        <Box
          mt={3}
          mb={2}
          className="cursor submit-btn-modal"
          onClick={() => {
            sessionStorage.setItem("isLoad", "true");
            window.dispatchEvent(new Event("storage"));
            props.onClose(false);
            handleClose();
          }}
        >
          Continue
        </Box>

        <Box>
            <Button onClick={() => props.history.push('/Setting?page=Refer')}
              variant='text'
              style={{ color: '#010039' }}
            >
              Refer a fried
            </Button>
          </Box>
      </Box>
    </Dialog>
  );
}

// Customizable Area End
