import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';
import { topRight, walletIcon, infoIcon } from '../../assets';

const FromToDate = ({ moderated_details }: { moderated_details: { to_date: string, from_date: string } }) => {
   return (
      <span>
         {moderated_details?.to_date ?
            `${moment(moderated_details?.from_date).format('DD.MM.YYYY')} - ${moment(moderated_details?.to_date).format('DD.MM.YYYY')}` :
            ''
         }
      </span>
   )
}

const ModeratorWallet = (props:any) => {

   return (
      <div>
         <div className="walletBalanceMain moderator">
            <img src={topRight} />
            <img className="walletIcon" src={walletIcon} />
            <span className="balance">
               Earned as Moderator
            </span>
            <span className="coinAmount">
               {props.isLoading || props.userProfile.ycoins === undefined ? <Skeleton width="100%" animation="wave" height="100%" /> : props.userProfile.total_earned_ycoins + ' YCoins'}
            </span>
         </div>
         <div className="ratingBox">
            <div className="earnedAndTime">
               <div className="earnedCoinsCurrentRating">
                  <span>Earned Coins: {props.userProfile.total_earned_ycoins}</span>
                  <span>Current Rating: {' ' + props.moderated_details?.moderated_rating}</span>
               </div>
               <div className="timeAndHearth">
                  <FromToDate moderated_details={props.moderated_details} />
                  <span>Hearts ({props.moderated_details?.moderated_room_hearts}) Touch Points ({props.moderated_details?.moderated_room_touchpoints})</span>
               </div>
            </div>
            <div className="ratingDesc">
               <img src={infoIcon} />
               <div className="moderatorExplainText">
                  <span>Moderators can redeem the YCoins they have earned through hosting Moderated conversations.</span>
                     <ul>
                        <li>To redeem, you must have earned at least <span>10,000 YCoins</span>. </li>
                        <li>If your<span>{" Rating is > 4"}</span>, you will be rewarded with a <span>50% bonus.</span> Example: if you earned 10,000 YCoins, you will get Rs. 15,000 </li>
                     </ul>
               </div>
            </div>
            <div 
               className={props.userProfile.total_earned_ycoins >= props.userProfile.min_redeem_ycoins_limit ? 'enterBankDetailsButton active' : 'enterBankDetailsButton'} 
               onClick={() => { props.userProfile.total_earned_ycoins >= props.userProfile.min_redeem_ycoins_limit && props.redeemCoins()}}
            >
               Redeem YCoins
            </div>
         </div>
      </div>
   )
}

export default ModeratorWallet