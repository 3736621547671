// Customizable Area Start

import React from "react";
import Settings5Controller from "../Settings5Controller";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { ListItemText, Breadcrumbs, Link, withWidth } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import {
  FaqPage,
  SyncGoogleCalender,
  DeactivateAccount,
  Feedback,
  PrivacyPolicy,
  Refer,
  TermsAndCondition,
} from "./settingContent.web";
import { Theme, createStyles, withStyles } from "@material-ui/core/styles";
import AlertBlock from "../../../alert/src/Alert.web";
import { withRouter } from "react-router-dom";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

import "../settings/setting.web.css";

const drawerWidth = 325;

const useStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },

    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    selected: {
      backgroundColor: "#010038",
      color: "#fff",
      border: "1px solid #010038",
      borderRadius: "10px",
    },
    unselected: {
      color: "#010038",
    },
  });

interface Props {
  navigation: any;
  id: string;
  history?: any;
  routeMap: any;
  getMyRooms: any;
}

class Setting extends Settings5Controller {
  constructor(props: any) {
    super(props);
  }

  handleClose() {
    this.setState({ isMessagePop: false });
  }

  async componentDidMount(): Promise<void> {
    super.componentDidMount();
    const termAndPolicyData = sessionStorage.getItem("t&cshow");
    const self: any = this.props;
    // @ts-ignore
    if (this.props.width !== "xs") {
      if (
        termAndPolicyData != "undefined" &&
        termAndPolicyData != "null" &&
        termAndPolicyData
      ) {
        this.setState({ showContent: termAndPolicyData });
      } else {
        if (self?.location && self?.location?.state?.page === "feedback") {
          this.setState({ showContent: "Provide Feedback" });
        } else {
          this.setState({ showContent: "FAQs" });
        }
      }
    } else {
      if (self?.location && self?.location?.state?.page === "feedback") {
        this.setState({ showContent: "Provide Feedback" });
      } else {
        this.setState({ showContent: "Settings" });
      }
    }

    this.handlePageChange();
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const termAndPolicyData = sessionStorage.getItem("t&cshow");
    // @ts-ignore
    if (this.props.width !== "xs" && this.state.showContent === "Settings")
      if (
        termAndPolicyData != "undefined" &&
        termAndPolicyData != "null" &&
        termAndPolicyData
      ) {
        this.setState({ showContent: termAndPolicyData });
      } else {
        this.setState({ showContent: "FAQs" });
      }
  }

  async componentWillUnmount(): Promise<void> {
    sessionStorage.removeItem("t&cshow");

    window.removeEventListener("resize", this.handleResize);
  }

  checkIsSmall = (width: Breakpoint) => {
    return width === "sm" || width === "xs";
  }

  render() {
    // @ts-ignore
    const { classes, width }: { width: Breakpoint } = this.props;

    const isSmall = this.checkIsSmall(width);

    const CONTENT_MAPPING = {
      FAQs:
        <FaqPage getFAQs={this.getFAQs} faqData={this.state.faqData} />,
      "Sync rooms to Google Calendar":
        <SyncGoogleCalender
          toggleGoogleCalendarSync={() =>
            this.toggleGoogleCalendarSync()
          }
          googleCalendarSync={this.state.googleCalendarSync}
        />,
      "Deactivate account":
        <DeactivateAccount
          deactivateAccount={this.deactivateAccount}
          setDeactiveReason={(reason: string) =>
            this.setState({ deactivateReason: reason })
          }
          verifyOTP={this.verifyOtp}
          isCheckingOTP={this.state.isCheckingOTP}
          startOTPChecking={() => this.setState({ isCheckingOTP: true })}
          stopOTPChecking={() => this.setState({ isCheckingOTP: false })}
        />,
      "Become a Moderator": (!JSON.parse(localStorage.getItem("isModerator") ?? "false") ? (
        <section className="border radius15 mb30 p15 shadow">
          <span
            className="fs14 fw500 colorBlue pointer"
            onClick={() => {
              this.props.navigation.navigate("ModuleOrientation");
            }}
          >
            Become a Moderator
          </span>
        </section>
      ) : (
        <span>You are already a Moderator</span>
      )),
      "Terms and conditions": <TermsAndCondition termData={this.state.policyData} terms={this.getTermsAndPolicyData} />,
      "Privacy Policy": <PrivacyPolicy policyDetails={this.getTermsAndPolicyData} policyDataa={this.state.policyData} />,
      "Provide Feedback": <Feedback feedbackData={this.submitFeedback} />,
      "Refer": <Refer />
    };


    const drawer = (
      <div>
        <div
          style={{
            margin: "17px 12px",
            fontSize: "20px",
            fontWeight: 600,
            color: "#010038",
          }}
        // className={classes.toolbar}
        >
          Settings
        </div>
        <Divider />
        <List
          className="list-item-settings"
          style={{
            border: !isSmall ? "1px solid #E5E5E5" : 'none',
            borderRadius: "10px",
            boxShadow: "10px",
            backgroundColor: "#fff",
            marginLeft: "15px",
          }}
        >
          {[
            "FAQs",
            "Sync rooms to Google Calendar",
            "Become a Moderator",
            "Deactivate account",
            "Terms and conditions",
            "Privacy Policy",
            "Provide Feedback",
            "Refer"
          ].map((text) => (
            <ListItem
              button
              key={text}
              className={
                text === this.state.showContent
                  ? classes.selected
                  : classes.unselected
              }
              onClick={() => {
                this.setState({ showContent: text }, () => {
                  this.props.history.push(`?page=${text}`);
                });
                sessionStorage.setItem("t&cshow", text);
              }}
            >
              {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
              <ListItemText className="list-item" primary={text} />
            </ListItem>
          ))}
        </List>
        <Divider />
      </div>
    );

    return (
      <div style={{ marginTop: "72px" }} className="setting-main-div">
        <AlertBlock
          data={{
            show: this.state.isMessagePop,
            close: () => {
              this.handleClose();
            },
            msg: this.msg,
            type: this.type,
            vertical: "top",
            horizontal: "center",
          }}
        />
        {width === "xs" && this.state.showContent === "Settings" && drawer}

        {/* Body for setting page */}
        {((width === "xs" && this.state.showContent !== "Settings") ||
          width !== "xs") && (
            <div className={classes.root}>
              <CssBaseline />

              <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                  <Drawer
                    className="setting-main-drawer"
                    container={null}
                    variant="temporary"
                    anchor={"left"}
                    open={this.state.mobileOpen}
                    onClose={() =>
                      this.setState({ mobileOpen: !this.state.mobileOpen })
                    }
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                      keepMounted: true, // Better open performance on mobile.
                    }}
                  >
                    {drawer}
                  </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                  <Drawer
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                  >
                    {drawer}
                  </Drawer>
                </Hidden>
              </nav>
              {/* //Accordion implemented */}
              <div
                style={{
                  width: this.state.isMobile ? "92vw" : "100%",
                  margin: this.state.isMobile ? "1rem" : "0px 20px",
                }}
              >
                {width === "xs" && (
                  <div>
                    <Breadcrumbs
                      separator={<NavigateNextIcon fontSize="small" />}
                      aria-label="breadcrumb"
                    >
                      <Link
                        color="inherit"
                        component="button"
                        onClick={() => this.setState({ showContent: "Settings" })}
                      >
                        Settings
                      </Link>
                      <span>{this.state.showContent}</span>
                    </Breadcrumbs>
                    <h2 style={{ fontSize: "20px", fontWeight: 600 }}>
                      {this.state.showContent}
                    </h2>
                  </div>
                )}

                {CONTENT_MAPPING[this.state.showContent as keyof typeof CONTENT_MAPPING]}
              </div>
            </div>
          )}
      </div>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(
  // @ts-ignore
  withWidth()(withRouter(Setting))
);

// Customizable Area End
