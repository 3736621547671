import React from "react";


import {
    // Customizable Area Start
    Box,
    Button,
    Grid,
    Typography,
    Avatar,
    Container,
    CircularProgress,
    // Customizable Area End
} from "@material-ui/core";
import {
    List, ListItem, ListItemSecondaryAction, ListItemText, ListItemAvatar,

} from "@material-ui/core";
import {Link} from "react-router-dom";
import { plusIcon } from '../../../Settings5/src/assets';
import { Message, Circle } from '../assets';
import moment from "moment";

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import "./list.css"
import {
    createTheme,
    ThemeProvider
} from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

import UserPersonalProfileController, {
    Props,
} from "./UserPersonalProfileController";

class ConnectionList extends UserPersonalProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    //Customizable Area Start

    async componentDidMount(): Promise<void> {
        let userId = localStorage.getItem("userId");

        this.getUserProfileData(userId);
        this.getConnectedUserData();
    }

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start

            <Container maxWidth="xl" style={{ backgroundColor: "#fcfbfcfc", minHeight: "100%" }} className="moderator-wrapper moderator-wrapper-connection" >
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                        <Box style={{ display: "flex", cursor: "pointer" }}
                            onClick={() => window.history.back()}>
                            <ArrowBackIcon />
                            <Typography style={{ paddingLeft: "10px" }}>
                                Back To Profile
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <section >

                            <div className="main-div-outter-moderator-list">
                                <span className='Heading-moderator'>
                                    Connections
                                </span>
                            </div>

                            <div className="Moderator-content-outter">
                                <List dense className=''>
                                    {this.state.loading && this.state?.connectedUserData == null ?
                                        <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "center", height: "70%" }}>
                                            <CircularProgress />
                                        </div>
                                        :
                                        ""
                                    }
                                    {
                                        !this.state.loading &&
                                            this.state?.connectedUserData?.length > 0 ?
                                            this.state?.connectedUserData?.map((value: any, index: any) => {
                                                let getCreatedYear = moment(value.attributes.created_at).format("YYYY");
                                                let getCreatedMonth = moment(value.attributes.created_at).format("MMM");
                                                const labelId = `checkbox-list-secondary-label-${value.id}`;
                                                return (
                                                    <div className="list-item-outter" key={value.id}>
                                                        <ListItem style={{ cursor: 'pointer' }} key={index}>
                                                            <Box display="flex" alignItems='center' flexGrow={1}
                                                                onClick={() => this.pushToProfile(value?.attributes?.other_user_id)}
                                                            >
                                                                {value?.attributes?.profile_image ?
                                                                    <ListItemAvatar style={{ alignSelf: 'flex-start', marginTop: "19px", cursor: "pointer" }}>
                                                                        <Link to='#'>
                                                                            <img height="40px" width="40px" style={{ borderRadius: "50%" }} src={value?.attributes?.profile_image} alt="profileImage" />
                                                                        </Link>
                                                                    </ListItemAvatar>
                                                                    :
                                                                    <Link to='#'>
                                                                        <Avatar style={{ alignSelf: 'flex-start', marginRight: "10px", cursor: "pointer" }} />
                                                                    </Link>
                                                                }
                                                                <ListItemText className="list-item-content" id={labelId}>
                                                                    <div className='list-first-div'>
                                                                        <span
                                                                            style={{
                                                                                color: "#444461", fontWeight: 600,
                                                                                paddingRight: "10px",
                                                                                fontSize: "16px"
                                                                            }}
                                                                        >
                                                                            <span style={{ cursor: "pointer" }}>{value?.attributes?.full_name}</span>
                                                                        </span>
                                                                        <Typography
                                                                            style={{
                                                                                color: "#8E8E8E",
                                                                                fontSize: '12px',
                                                                                textTransform: "uppercase",
                                                                                marginBottom: "10px",
                                                                                paddingTop: "3px"
                                                                            }}
                                                                        >
                                                                            SINCE {getCreatedMonth} {getCreatedYear}
                                                                        </Typography>

                                                                    </div>
                                                                </ListItemText>
                                                            </Box>

                                                            <ListItemSecondaryAction>
                                                                {/* { index == 0 ? <Button>Following</Button> : */}
                                                                {/* <img className="messageIconButton" src={Message} onClick={() => {
                                                                    this.props.navigation.navigate("ChatMessages",
                                                                        { id: value.attributes.account_id })
                                                                }} /> */}
                                                                {
                                                                    value?.attributes?.status === "Accepted" ?
                                                                        <Button style={{ background: "#010138", color: "#fff" }} >
                                                                            Connected
                                                                        </Button> :

                                                                        <Button style={{ background: "#fff", color: "#101038" }} onClick={() => this.sendConnectRequest(this.props?.navigation?.getValues("userId"))}>

                                                                            Connect
                                                                        </Button>
                                                                }
                                                                {/* }  */}
                                                            </ListItemSecondaryAction>
                                                        </ListItem>

                                                    </div>


                                                );
                                            })
                                            :
                                            null}
                                    {this.state?.connectedUserData != null && this.state?.connectedUserData?.length == 0 &&
                                        <h2 style={{
                                            fontSize: "14px",
                                            fontWeight: 600,
                                            color: "#666",
                                            marginLeft: "30px"
                                        }}>No Data Found</h2>
                                    }
                                </List>
                            </div>

                        </section>
                    </Grid>
                    {/* Grid 2 end  */}

                </Grid>

            </Container>

        )
    }
}
//@ts-ignore
export default withRouter(ConnectionList)
// Customized Area End
