// Customizable Area Start
import React from 'react';
import { ListItem, Card, CardMedia } from '@material-ui/core';
import Dropdownmenu from './dropdownmenu.web';
import './VideoChatScreen.css';
import ParticipantVideoCardController, {
	Props
} from './participantVideoCardController';
import MicOffRoundedIcon from '@material-ui/icons/MicOffRounded';

const defaultProfilePic = require('../assets/defaultProfilePic.png')
export default class ParticipantVideoCard extends ParticipantVideoCardController {
	constructor(props: Props) {
		super(props);
	}
	render() {

		return (
			<ListItem id='participant-video-item'>
				<Card className='participant-video-card'>
					<CardMedia>
						<div id='participant-video-feed'>
							<div id={`remote-participant-${this.props.id || this.props.index}`}>
								{(this.state.videoTrackDisabled) ?
									<img
										style={{
											width: "100%", position: "absolute", bottom: "1%",
											height: "100%", objectFit: "cover",
										}}
										src={this.props.isAnonymously ? defaultProfilePic : this.props.profileImg
											|| defaultProfilePic} alt='unable to load user image'>
									</img> :
									null
								}
							</div>
							{this.state.audioTrackDisabled
								&&
								<div
									style={{
										position: "absolute", right: "6%", bottom: "10%", background: "#d3d3d3",
										display: "inline-block", borderRadius: "8px", opacity: "60%"
									}}>
									<MicOffRoundedIcon />
								</div>
							}
							<Dropdownmenu
								accountId={this.props.accountId}
								selfAlertCount={this.props.selfAlertCount}
								selfLeaveCount={this.props.selfLeaveCount}
								showMaxAlert={this.props.showMaxAlert}
								showMaxLeave={this.props.showMaxLeave}
								handleAlertCount={this.props.handleAlertCount}
								handleLeaveCount={this.props.handleLeaveCount}
								handleMaxAlert={this.props.handleMaxAlert}
								handleMaxLeave={this.props.handleMaxLeave}
								isHost={this.props.isHost}
								isModerator={this.props.isModerator}
								currentUser={this.props.currentUser}
								isRoomModarated={this.props.isRoomModarated}
								retract_leave_status={this.props.retract_leave_status}
								startTime={this.props.startTime}
							/>
						</div>
					</CardMedia>
				</Card>

				<span style={{
					whiteSpace: "nowrap",
					overflow: "hidden !important",
					textOverflow: "ellipsis",
					paddingLeft: '.5rem'
				}}>
					{this.props.isAnonymously
						? this.props.anonymouslyName
						: this.state.user_full_name}
				</span>
			</ListItem>
		);
	}
}
//Customizable Area End
