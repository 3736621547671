import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import EmailAccountRegistrationController from '../EmailAccountRegistrationController';

class NotFound extends EmailAccountRegistrationController<any> {
  render() {
    return (
      <div>
        <h1>404 - Not Found!</h1>
        <Button
          style={{ minWidth: "max-content" }}
          className="contained-btn margin-spacing-right"
          variant="contained"
          onClick={() => this.props.navigation.navigate("Home")}>Go Home</Button>
      </div>)
  }
};

export default NotFound;