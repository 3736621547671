const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token?.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const isAuthenticated = () => {
  const token = localStorage.getItem('token');
  const parsedToken = parseJwt(token || '');
  return Boolean(token && parsedToken?.exp > new Date().getTime() / 1000);
};
