import React, { useState, useEffect, useRef } from 'react'
import './accountDetailsForm.css'
import { Grid, CircularProgress } from '@material-ui/core'
export const configJSON = require("../config.js");
import { backarrow } from "../../assets";
import DropDown from '../../../../email-account-registration/src/EmailRegister/Dropdown.web';

const AccountDetailForm = (props: any) => {
   const [inputField, setInputField] = useState({
      accountHolderName: '',
      bankName: '',
      accountNumber: '',
      // confirmAccountNumber: '',
      ifscCode: '',
      Pan: '',
      GSTNumber: ''
   })

   const bankList = [
      'Aditya Birla Idea Payments Bank Ltd',
      'Airtel Payments Bank Ltd',
      'Andhra Pradesh GVB',
      'Andhra Pragathi Grameena Bank',
      'Arunachal Pradesh Rural Bank',
      'Aryavart Bank',
      'Assam Gramin Vikash Bank',
      'Au Small Finance Bank Ltd',
      'Axis Bank Ltd',
      'Bandhan Bank Ltd',
      'Bangiya Gramin Vikash Bank',
      'Bank of Baroda',
      'Bank of India',
      'Bank of Maharashtra',
      'Baroda Gujarat Gramin Bank',
      'Baroda Rajasthan Kshetriya Gramin Bank',
      'Baroda Uttar Pradesh Gramin Bank',
      'Canara Bank',
      'Capital Small Finance Bank Ltd',
      'Central Bank of India',
      'Chaitanya Godavari GB',
      'Chhattisgarh Rajya Gramin Bank',
      'City Union Bank Ltd',
      'Coastal Local Area Bank Ltd',
      'CSB Bank Limited',
      'Dakshin Bihar Gramin Bank',
      'DCB Bank Ltd',
      'Dhanlaxmi Bank Ltd',
      'Ellaquai Dehati Bank',
      'Equitas Small Finance Bank Ltd',
      'ESAF Small Finance Bank Ltd',
      'Export-Import Bank of India',
      'Federal Bank Ltd',
      'Fincare Small Finance Bank Ltd',
      'Fino Payments Bank Ltd',
      'HDFC Bank Ltd',
      'Himachal Pradesh Gramin Bank',
      'HSBC',
      'ICICI Bank Ltd',
      'IDBI Bank Limited',
      'IDFC FIRST Bank Limited',
      'India Post Payments Bank Ltd',
      'Indian Bank',
      'Indian Overseas Bank',
      'IndusInd Bank Ltd',
      'J&K Grameen Bank',
      'Jammu & Kashmir Bank Ltd',
      'Jana Small Finance Bank Ltd',
      'Jharkhand Rajya Gramin Bank',
      'Jio Payments Bank Ltd',
      'Karnataka Bank Ltd',
      'Karnataka Gramin Bank',
      'Karnataka Vikas Gramin Bank',
      'Karur Vysya Bank Ltd',
      'Kashi Gomti Samyut Gramin Bank',
      'Kerala Gramin Bank',
      'Kotak Mahindra Bank Ltd',
      'Krishna Bhima Samruddhi LAB Ltd',
      'Lakshmi Vilas Bank Ltd',
      'Madhya Pradesh Gramin Bank',
      'Madhyanchal Gramin Bank',
      'Maharashtra GB',
      'Manipur Rural Bank',
      'Meghalaya Rural Bank',
      'Mizoram Rural Bank',
      'Nagaland Rural Bank',
      'Nainital Bank Ltd',
      'National Bank for Agriculture and Rural Development',
      'National Housing Bank',
      'North East Small Finance Bank Ltd',
      'NSDL Payments Bank Limited',
      'Odisha Gramya Bank',
      'Paschim Banga Gramin Bank',
      'Paytm Payments Bank Ltd',
      'Prathama U.P. Gramin Bank',
      'Puduvai Bharathiar Grama Bank',
      'Punjab & Sind Bank',
      'Punjab Gramin Bank',
      'Punjab National Bank',
      'Purvanchal Bank',
      'Rajasthan Marudhara Gramin Bank',
      'RBL Bank Ltd',
      'Saptagiri Grameena Bank',
      'Sarva Haryana Gramin Bank',
      'Saurashtra Gramin Bank',
      'Standard Chat',
      'Small Industries Development Bank of India',
      'South Indian Bank Ltd',
      'State Bank of India',
      'Subhadra Local Bank Ltd',
      'Suryoday Small Finance Bank Ltd',
      'Tamil Nadu Grama Bank',
      'Tamilnad Mercantile Bank Ltd',
      'Telangana Grameena Bank',
      'Tripura Gramin Bank',
      'UCO Bank',
      'Ujjivan Small Finance Bank Ltd',
      'Union Bank of India',
      'Utkal Grameen Bank',
      'Utkarsh Small Finance Bank Ltd',
      'Uttar Bihar Gramin Bank',
      'Uttarakhand Gramin Bank',
      'Uttar Banga Kshetriya Gramin Bank',
      'Vidharbha Konkan Gramin Bank',
      'YES Bank Ltd',
   ]

   const [firstLoad, setFirstLoad] = useState<boolean>(false)
   const [errorList, setErrorList] = useState<Array<any>>([])
   const [validErrorList, setValidErrorList] = useState<Array<any>>([])
   const [loading, setLoading] = useState(false)
   const data = [
      {
         label: `Account Holder's name`,
         type: 'text',
         name: 'accountHolderName',
         placeholder: `Account Holder's name`,
         value: inputField.accountHolderName
      },
      {
         label: 'Bank Name',
         type: 'text',
         name: 'bankName',
         placeholder: `Bank Name`,
         value: inputField.bankName
      },
      {
         label: 'Bank Account Number',
         type: 'text',
         name: 'accountNumber',
         placeholder: 'Account Number',
         value: inputField.accountNumber
      },
      // {
      //    label: 'Confirm Account Number',
      //    type: 'text',
      //    name: 'confirmAccountNumber',
      //    placeholder: 'Confirm Account Number',
      //    value: inputField.confirmAccountNumber
      // },
      {
         label: 'IFSC Code',
         type: 'text',
         name: 'ifscCode',
         placeholder: 'IFSC Code',
         value: inputField.ifscCode
      },
      {
         label: 'PAN Number',
         type: 'text',
         name: 'Pan',
         placeholder: 'PAN',
         value: inputField.Pan
      },
      {
         label: 'GST Number (Optional)',
         type: 'text',
         name: 'GSTNumber',
         placeholder: 'GST Number',
         value: inputField.GSTNumber
      },
   ]

   const creditCardFormatter = (value: any) => {
      let creditCard = value
         .replace(/\s+/g, "")
         .replace(/[^0-9]/gi, "")
         .substr(0, 16);
      const parts = [];

      for (let i = 0; i < creditCard.length; i += 4) {
         parts.push(creditCard.substr(i, 4));
      }

      return parts.length > 1 ? parts.join("-") : value
   }

   const NumberAndTextFormatter = (value: any, limit: any) => {
      let formattedText = value.replace(/[^a-zA-Z0-9 ]/g, "").substr(0, limit)
      return formattedText
   }

   const NumberFormatter = (value: any, limit: any) => {
      let formattedText = value.replace(/[^0-9]/g, "").substr(0, limit)
      return formattedText
   }

   const inputsHandler = (e: any, label: string) => {
      if (e.target.value && e.target.value.length) {
         if (!firstLoad) setFirstLoad(true)
         if (e.target.name === 'accountNumber') {
            let regex = /^\d{9,18}$/;
            if (regex.test(e.target.value)) {
               if (validErrorList.find(item => item === label)) {
                  errorCreator(e.target.name, true)
               }
            } else {
               errorCreator(e.target.name, false)
            }
            setInputField({ ...inputField, [e.target.name]: NumberFormatter(e.target.value, 19) })
         } else if (e.target.name === 'ifscCode') {
            let regex = /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/;
            if (regex.test(e.target.value)) {
               if (validErrorList.find(item => item === label)) {
                  errorCreator(e.target.name, true)
               }
            } else {
               errorCreator(e.target.name, false)
            }
            setInputField({ ...inputField, [e.target.name]: NumberAndTextFormatter(e.target.value, 11) })
         } else if (e.target.name === 'Pan') {
            let regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
            if (regex.test(e.target.value)) {
               if (validErrorList.find(item => item === label)) {
                  errorCreator(e.target.name, true)
               }
            } else {
               errorCreator(e.target.name, false)
            }
            setInputField({ ...inputField, [e.target.name]: NumberAndTextFormatter(e.target.value, 10) })
         } else if (e.target.name === 'GSTNumber') {
            let regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
            if (regex.test(e.target.value)) {
               if (validErrorList.find(item => item === label)) {
                  errorCreator(e.target.name, true)
               }
            } else {
               errorCreator(e.target.name, false)
            }
            setInputField({ ...inputField, [e.target.name]: NumberFormatter(e.target.value, 15) })
         } else if (e.target.name === 'accountHolderName') {
            setInputField({ ...inputField, [e.target.name]: NumberAndTextFormatter(e.target.value, 30) })
         } else if (e.target.name === 'bankName') {
            setInputField({ ...inputField, [e.target.name]: NumberAndTextFormatter(e.target.value, 30) })
         } else {
            setInputField({ ...inputField, [e.target.name]: e.target.value })
         }
      } else {
         setInputField({ ...inputField, [e.target.name]: '' })
         errorCreator(e.target.name, false, '')
         errorCreatorCheckAll()
      }
   }

   React.useEffect(() => {
      if (firstLoad) {
         errorCreatorCheckAll()
      }
   }, [inputField]);

   React.useEffect(() => {
      if (props.bankDetails && Object.keys(props.bankDetails).length > 0) {
         const bankDetails: any = props.bankDetails
         setInputField(
            {
               accountNumber: bankDetails.account_no,
               bankName: bankDetails.bank_name,
               ifscCode: bankDetails.ifsc_code,
               accountHolderName: bankDetails.ac_holder_name,
               Pan: bankDetails.pan_number,
               GSTNumber: bankDetails.gst_number
            }
         )
      }
   }, [])

   const submitButton = () => {
      if (firstLoad && errorList.length === 0 && validErrorList.length === 0) {
         setLoading(true)
         const raw = {
            type: "moderator_profile_data",
            attributes: {
               bank_details: {
                  bank_name: inputField.bankName,
                  account_no: inputField.accountNumber,
                  ifsc_code: inputField.ifscCode,
                  ac_holder_name: inputField.accountHolderName,
                  pan_number: inputField.Pan,
                  gst_number: inputField.GSTNumber,
                  country: "IND",
                  state: "",
                  city: ""
               }
            }
         }
         const myHeaders = new Headers();
         const token = localStorage.getItem('token')
         //@ts-ignore
         myHeaders.append("token", token);
         myHeaders.append("Content-Type", "application/json");

         var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            body: JSON.stringify(raw)
         };
         //@ts-ignore
         fetch(`${configJSON.updateBankDetails}/${localStorage.getItem('userId')}`, requestOptions)
            .then(response => response.json())
            .then(result => {
               if (props && props.cb) {
                  setLoading(false)
                  props.cb(result)
               }
            })
            .catch(error => setLoading(false));
      }
   }

   const errorCreator = (key: string, isRemove?: boolean, value?: string) => {
      return new Promise(function (resolve: any) {
         let errorArray: any = validErrorList
         let find: any = data.find((el) => el.name === key)
         if (isRemove || value?.length === 0) {
            errorArray = errorArray.filter((item: any) => item !== find?.label)
         } else if (!errorArray.find((item: any) => item === find?.label)) {
            errorArray.push(find?.label)
         }
         setValidErrorList(errorArray)
         resolve('done')
      })
   }

   const errorCreatorCheckAll = () => {
      return new Promise(function (resolve: any) {
         const errorIndex: any = []
         for (const [key, value] of Object.entries(inputField)) {
            if (key !== 'GSTNumber') {
               !value || value.length == 0 ? errorIndex.push(key) : null
            }
         }
         let errorTextArr: any = []
         errorIndex.forEach((num: any) => {
            let finded = data.find((el) => el.name === num)
            if (finded) errorTextArr.push(finded.label)
         })
         setErrorList(errorTextArr)
         resolve('done')
      })
   }

   return (
      <div className=" accountDetailsOutside">
         {loading &&
            <div className="loadingMask">
               <Grid container xs={12} style={{ height: '100%', justifyContent: 'center' }} alignItems="center">
                  <div>
                     <CircularProgress size={80} thickness={4} style={{ color: '#ffcb00' }} />
                  </div>
               </Grid>
            </div>
         }
         <div className="accountDetailsFrom" >
            <div onClick={() => { props.closeDetail() }} className="walletBackButton">
               <img src={backarrow} alt="" />Close
            </div>
            {data.map((item, index) => {
               const finded = errorList.find((err) => err === item.label)
               const finded2 = validErrorList.find((err) => err === item.label)

               if (item.name === 'bankName') {
                  return (
                     <div key={index}>
                        <label>{item.label}</label>
                        <DropDown
                           name={item.name}
                           list={bankList.map(
                              (item: any) => item
                           )}
                           value={item.value}
                           className="MuiOutlinedInput-root year-select width-100 "
                           defaultText="Select bank "
                           onChange={(e: any) => {
                              inputsHandler(e, item.label)
                           }}
                           style={{ background: 'white' }}
                        />
                        {finded ?
                           <div className="detailsErrorBox">Please select your bank name</div>
                           :
                           <div className="detailsErrorBox"></div>
                        }
                     </div>
                  )
               } else {
                  return (
                     <div key={index}>
                        <label>{item.label}</label>
                        <input
                           className={finded ? 'error' : ''}
                           type={item.type}
                           name={item.name}
                           onChange={(e) => { inputsHandler(e, item.label) }}
                           placeholder={item.placeholder}
                           value={item.value} />
                        {finded ?
                           <div className="detailsErrorBox">{item.label + " can not be empty"}</div>
                           :
                           (finded2 ?
                              <div className="detailsErrorBox">{"Please fill a valid " + item.label}</div>
                              :
                              <div className="detailsErrorBox"></div>
                           )
                        }
                     </div>
                  )

               }
            })}
            <button className={firstLoad && errorList.length == 0 && validErrorList.length === 0 ? 'detailButton' : 'detailButtonDisabled'} onClick={submitButton}>Save</button>
         </div >
      </div>
   )
}

export default AccountDetailForm;