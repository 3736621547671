// Customizable Area Start

import React, { PureComponent } from "react";

import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
    createStyles, withStyles, WithStyles, Theme
} from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { ClosedDoor } from "../../assets";
import { DialogActions, DialogContent, DialogTitle } from "./LeaveRoomModal.web";

const styles = (theme: Theme) => createStyles({
    mobileView: {
        height: '100vh',
        width: '100vw',
        backgroundColor: theme.palette.common.white,
        position: 'fixed',
        top: 0,
        zIndex: 9999,
        overflow: 'auto',
    },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
    isMobile: boolean;
    isOpen: boolean;
    details: { title: string, description: string };
}

interface S { }

class CancelRoomModal extends PureComponent<Props, S> {

    handleMyRoomsBtn = () => {
        this.props.history.replace('/Rooms?type=my');
    }

    handleGoToHomepageBtn = () => {
        this.props.history.replace('/Rooms?type=upcoming');
    }

    render() {
        const { isOpen, isMobile, details, classes } = this.props;

        if (isOpen && isMobile) {
            return (
                <Box className={classes.mobileView}>
                    {/* main container */}
                    <Box>
                        <Box>
                            <ClosedDoor />
                        </Box>

                        {/* content */}
                        <Box display='flex' textAlign='center' justifyContent='center' flexDirection='column' p={2.5}>
                            <Box textAlign='center' mt={1.5} mb={2.5}>
                                <Typography gutterBottom variant="h6" style={{ color: '#1E3354' }}>{details.title}</Typography>
                                <Typography style={{ color: '#7F8E9D' }}>
                                    {details.description}
                                </Typography>
                            </Box>

                            <Box mb={2}>
                                <Box onClick={this.handleMyRoomsBtn} style={{
                                    background: "#FFCB00", color: "#010039", borderRadius: '2rem',
                                    padding: '1rem', cursor: 'pointer', fontWeight: 600
                                }}>
                                    My rooms
                                </Box>

                                <Box mt={2} onClick={this.handleGoToHomepageBtn} >
                                    <Typography style={{ color: '#121173', fontSize: '.9rem' }}> Go to Homepage</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )
        }

        return (
            <Dialog onClose={this.handleMyRoomsBtn} aria-labelledby="customized-dialog-title" open={isOpen}>
                <DialogTitle id='leave-room-modal-id' onClose={this.handleMyRoomsBtn}>
                    {details.title}
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom style={{ color: '#7F8E9D' }}>
                        {details.description}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleMyRoomsBtn} style={{
                        background: "#FFCB00", color: "#010039", fontWeight: 600
                    }}>
                        My rooms
                    </Button>

                    <Button onClick={this.handleGoToHomepageBtn} style={{
                        fontWeight: 600, color: '#121173', fontSize: '.9rem'
                    }} variant='text'>
                        Go to Homepage
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(withRouter(CancelRoomModal));
// Customizable Area End
