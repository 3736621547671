Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "DifferentRooms";
exports.labelBodyText = "DifferentRooms Body";
exports.apiEndPointGetSubcategory = "bx_block_categories/sub_categories";
exports.apiEndPointGetCategory = "bx_block_categories/categories";

exports.btnExampleTitle = "CLICK ME";
exports.methodTypeGet = "GET";
exports.methodTypePut = "PUT";
exports.getCategoiresAPIEndPoint = "bx_block_categories/categories/search";
exports.getSubCategoriesAPiEndPoint =
  "bx_block_categories/sub_categories?category_id=";
exports.getRoomTypeAPIEndPoint =
  "bx_block_different_rooms/rooms/get_room_types";
exports.GetMethodType = "GET";
exports.postMethodType = "POST";
exports.deleteMethodType = "DELETE";
exports.createRoomAPIEndPoint = "bx_block_different_rooms/rooms";
exports.GetMyRoomsApiEndPoint = "bx_block_different_rooms/rooms/my_rooms";
exports.GetRoomDetailsApiEndPoint = "bx_block_different_rooms/rooms";
exports.GetRoomTypesApiEndPoint =
  "bx_block_different_rooms/rooms/get_room_types";
exports.GetUpcomingRoomsApiEndPoint =
  "bx_block_different_rooms/rooms/get_upcoming_rooms";
exports.GetUpcomingRoomsApiEndPointOnly =
  "bx_block_different_rooms/rooms/get_upcoming_rooms";
exports.MyTabDeleteRoomApiEndPoint =
  "bx_block_different_rooms/rooms/delete_room";
exports.RegisterRoomApiEndPoint =
  "bx_block_different_rooms/rooms/registered_in_rooms";
exports.GetRecommendedRoomApiEndPoint =
  "bx_block_different_rooms/rooms/get_recommended_rooms";
exports.GetRecommendedRoomEndPoint =
  "bx_block_different_rooms/rooms/get_recommended_rooms?";
exports.GetMyRoomsEndPoint = "bx_block_different_rooms/rooms/my_rooms?";
exports.PostAcceptApiEndPoint =
  "bx_block_different_rooms/rooms/joining_request_accepted";
exports.getWalletCoinsApiEndPoint = "bx_block_profile/profiles/";
exports.removeFromRoomApiEndPoint =
  "bx_block_different_rooms/rooms/delete_room_registration?room_id=";
exports.GetHomePageSettingsApiEndPoint = "bx_block_settings/home_page_settings";
exports.GetMyConnectsApiEndPoint =
  "bx_block_request_management/requests/friends?per_page=100&page=1";
exports.GetRoomsFilterApiEndPoint =
  "bx_block_different_rooms/rooms/filtered_rooms?";
exports.GetModeratorListApiEndPoint = "account_block/accounts/moderator_list";
exports.followModeratorApiEndPoint = "bx_block_followers/follows";
exports.unfollowModeratorApiEndPoint = "bx_block_followers/follows/";
exports.categoriesApiEndPoint = "bx_block_categories/categories";
exports.filterUpcomingRooms =
  "bx_block_different_rooms/rooms/get_upcoming_rooms";
exports.GetMyPastRooms = "bx_block_different_rooms/rooms/my_past_rooms";
exports.PutHostYTakeApiEndPoint =
  "bx_block_different_rooms/rooms/update_ytake_for_rooms";
exports.PutParticipantYTakeApiEndPoint =
  "bx_block_different_rooms/rooms/update_ytake_for_registered_rooms";
exports.GetMyUpcomingRoomsApiEndPoint =
  "bx_block_different_rooms/rooms/my_upcoming_rooms";

exports.updateLikeAPI = "bx_block_like/likes";
exports.updateLikeMethod = "POST";

exports.getCategoriesAPIEndPoint = "bx_block_categories/categories";
exports.deleteresourceApiEndPoint =
  "bx_block_profile/profiles/delete_resource?id=";
exports.updateResourceApiEndPoint = "bx_block_different_rooms/rooms/";
exports.updateNewResourcesAPIEndPoint = "bx_block_different_rooms/rooms/host_registration_details_update"
exports.updateNewResourcesParticipantAPIEndPoint = "bx_block_different_rooms/rooms/participant_details_update"

exports.roomDetailsEndpoint = "bx_block_different_rooms/rooms";

exports.submitFeedbackMethod = "POST";
exports.submitFeedbackEndpoint =
  "bx_block_different_rooms/participant_feedbacks";

exports.getPrevFeedbacks =
  "bx_block_different_rooms/participant_feedbacks/myroom_feedback_to_others";
exports.getPrevFeedbacksMethod = "GET";

exports.reportUserEndpoint = "bx_block_different_rooms/reported_user";
exports.reportUserMethod = "POST";

exports.roomFeedbackEndpoint = "bx_block_different_rooms/room_feedback";
exports.roomFeedbackEndMethod = "POST";

exports.updateTutorialsApiContentType = "application/json";
exports.updateTurtoialsMethod = "PUT";
exports.updateTutorialsApiEndPoint = "account_block/accounts/update_flags";

exports.rulesContentType = "application/json";
exports.rulesMethod = "GET";
exports.constant_key = "RULE_OF_ENG_APP_WEB";
exports.rulesApiEndPoint = "/bx_block_admin/dynamic_text?constant_key=";
exports.bulletPointsAPIEndPoint = "bx_block_admin/web_contents";
exports.shareToConnectsAPIEndPoint = "bx_block_different_rooms/rooms/share_room";
exports.GetMyConnectsApiEndPoint =
  "bx_block_request_management/requests/friends?per_page=100&page=1";

exports.sendConnectRequestApiEndPoint = 'bx_block_request_management/requests'
exports.httpPostType = 'POST';
// Customizable Area End
