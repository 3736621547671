import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from ".././assets";
import StateContext, { Props as CProps } from '../../../../web/src/globalState';
import moment from "moment";
type DT = { date: Date, start_time: Date, end_time: Date };
// Customizable Area End

export const configJSON = require(".././config");

export interface Props {
  navigation: any;
  id: string;
  onclose: (value: boolean) => {}
  history: any;
  location: any;
  match: any
  // Customizable Area Start
  // Customizable Area End
}



interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  roomDetails: any;
  hostDetails: any;
  upComingRooms: any;
  addResources: any;
  registerAnonymously: boolean
  congratulationModal: {
    isOpen: boolean;
    room_name: string;
    dateTime: string;
  };
  cancelModal: boolean;
  removeModal: boolean;
  myRoomTabId: any;
  removeParticipantId: any;
  registerModal: any;
  isMessagePop: boolean;
  roomId: number;
  upcomingRoomsWithSubcategoryAndTopicId: any;
  isRegisteredFlag: boolean;
  walletYCoins: number;
  roomsData: any;
  registrantsDetails: any;
  your_takes: {
    isEditing: boolean, take: string, isSave: boolean, prevTake: string
  },
  roomStartNotification: number;
  hasError: string;
  isTimePassed: boolean;
  anchorEl: null | HTMLElement;
  reportResourceId: number | null;
  isReasonOpen: boolean;
  currentWeekSlot: any;
  selectedDate: any;
  currentMonth: any;
  currentTime: any;
  selectedDateInTimePiker: any;
  updateResourceData: any;
  editUrls: any[] | null,
  room_times: { type: string, date: any, start_time: any, end_time: any },
  details: { prevUrl: string, data: any },
  isRoomsLoading: boolean;
  isHostLoading: boolean;
  isMobile: boolean;
  tempUserData: any;
  isRoomDetailLoading: boolean;
  getMyConnects: {
    profile_image: false | string;
    full_name: string;
    other_user_id: number;
  }[];
  isUserLoading: boolean;
  isUnavailableOpen: boolean;
  unavailableReason: string;
  payload: any;
  apiNeedToCalled: 'REGISTER' | 'CANCEL' | 'CANCEL_HOST' | '';
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RoomDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  roomDetailsId: string;
  hostDetailsId: string;
  upcomingRoomsId: string;
  upcomingRoomsWithSubcategoryAndTopicIdId: string;
  registerRoomID: any
  reportResourceId: string;
  msg = ""
  type = ""
  updateRequestId: any;
  registrantsDetailsId: string;
  deleteResourceId: string;
  myRoomTabDeleteId: string;
  verifyCurrentTime: string;
  removeFromRoomId: string;
  ytakeupdateId: string;
  updateLikeID: string;
  updateResourceId: string;
  updateNewResourceId: any;
  getMyConnectsId: string;
  static contextType?: React.Context<CProps> = StateContext;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      roomDetails: [],
      hostDetails: [],
      upComingRooms: [],
      addResources: false,
      registerAnonymously: false,
      congratulationModal: {
        isOpen: false,
        dateTime: '',
        room_name: '',
      },
      cancelModal: false,
      removeModal: false,
      myRoomTabId: window.location.href.split('/')[window.location.href.split('/').length - 1],
      removeParticipantId: localStorage.getItem('userId'),
      registerModal: false,
      getMyConnects: [],
      isMessagePop: false,
      roomId: this.props.navigation.getParam("id", ""),
      upcomingRoomsWithSubcategoryAndTopicId: [],
      isRegisteredFlag: false,
      walletYCoins: this.props.history.location.state?.walletYCoins,
      roomsData: {},
      registrantsDetails: [],
      your_takes: {
        isEditing: false, take: '', isSave: false, prevTake: ''
      },
      roomStartNotification: -1,
      hasError: "",
      isTimePassed: false,
      anchorEl: null,
      reportResourceId: null,
      isReasonOpen: false,
      currentWeekSlot: null,
      selectedDate: null,
      currentMonth: '',
      currentTime: null,
      selectedDateInTimePiker: moment("00:00", "h:mm a"),
      updateResourceData: [],
      editUrls: null,
      room_times: {
        type: '', date: '',
        start_time: moment().startOf('day'),
        end_time: moment().endOf('day'),
      },
      details: { prevUrl: '', data: null },
      isUserLoading: true,
      isRoomsLoading: true,
      isHostLoading: true,
      isMobile: false,
      tempUserData: {},
      isUnavailableOpen: false,
      unavailableReason: "",
      isRoomDetailLoading: false,
      payload: null,
      apiNeedToCalled: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );
      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.handleUpdateResourceData(apiRequestCallId, responseJson);
        this.handleDeleteResourceData(apiRequestCallId);
        this.handleRoomDetailsData(apiRequestCallId, responseJson);
        this.handleHostDetailsData(apiRequestCallId, responseJson);
        this.handleUpcomingRoomsData(apiRequestCallId, responseJson);
        this.handleRegisterRoomsData(apiRequestCallId, responseJson);
        this.handleRgistrantsDetailsData(apiRequestCallId, responseJson);
        this.handleReportResourceData(apiRequestCallId, responseJson);
        this.handleUpdateRequest(apiRequestCallId, responseJson);
        this.handleMyRoomDeleteTabData(apiRequestCallId, responseJson);
        this.handleRemoveFromRoomData(apiRequestCallId, responseJson);
        this.handleNewRoomUpdate(apiRequestCallId, responseJson)
        this.handleMyConnectsData(apiRequestCallId, responseJson);
        this.handleVerifyCurrentTime(apiRequestCallId, responseJson);
      }
    } else {
      this.setState({ isUserLoading: false })
    }
  }

  handleVerifyCurrentTime = (apiRequestCallId: string, responseJson: any) => {
    if (this.verifyCurrentTime === apiRequestCallId) {
      if (responseJson?.unixtime) {
        const api = this.state.apiNeedToCalled;
        if (api === 'REGISTER')
          this.registerRoom(this.state.payload, this.state.isRegisteredFlag, responseJson.unixtime);
        else if (api === 'CANCEL')
          this.removingFromRoom(this.state.myRoomTabId, responseJson.unixtime);
        else if (api === 'CANCEL_HOST')
          this.myTabDeleteRoom(this.state.myRoomTabId, responseJson.unixtime);
      } else {
        this.handleAlert("Something went wrong!");
      }
    }
  };

  handleMyConnectsData = (apiRequestCallId: string, responseJson: any) => {
    if (this.getMyConnectsId === apiRequestCallId) {
      if (responseJson?.data) {
        this.setState({
          getMyConnects: responseJson.data.map(
            (connect: {
              attributes: {
                profile_image: false | string;
                full_name: string;
                other_user_id: number;
              };
            }) => connect.attributes
          ),
        });
      } else if (responseJson?.errors) {
        this.setState({
          getMyConnects: []
        })
      }
    }
  }

  handleUpdateResourceData = (apiRequestCallId: string, responseJson: any) => {
    if (this.updateResourceId === apiRequestCallId) {
      const errors = responseJson?.errors;
      if (errors) {
        this.handleAlert(errors[0].message);
        return;
      }
      this.handleAlertSuccess("Resources Saved")
      this.setState({ editUrls: null })
      this.getRegistrants(this.state.roomId);
      this.getHostDetails(this.state.roomId);
    }
  }

  handleDeleteResourceData = (apiRequestCallId: string) => {
    if (this.deleteResourceId === apiRequestCallId) {
      this.handleAlertSuccess("Resource deleted");
      this.getRegistrants(this.state.roomId);
      this.getHostDetails(this.state.roomId);
    }
  }

  handleRoomDetailsData = (apiRequestCallId: string, responseJson: any) => {
    if (this.roomDetailsId === apiRequestCallId) {
      if (responseJson?.data) {
        this.setRoomData('upcoming', responseJson.data);
        if (!responseJson.data?.attributes?.is_global &&
          localStorage.getItem('country_code') !== responseJson.data?.attributes?.host?.data?.attributes?.country_code) {
          this.setState({ isUnavailableOpen: true, unavailableReason: "COUNTRY" })
        }
      } else if (responseJson?.message === "Room is not available because of our blocked user protection guidelines") {
        this.setState({ isUnavailableOpen: true, unavailableReason: "BLOCK" })
      }
    }
  }

  handleHostDetailsData = (apiRequestCallId: string, responseJson: any) => {
    if (this.hostDetailsId === apiRequestCallId) {
      if (responseJson && responseJson)
        this.setState({ hostDetails: responseJson }, () => {
          this.setState({ isHostLoading: false })
        })
    }
  }

  handleUpcomingRoomsData = (apiRequestCallId: string, responseJson: any) => {
    if (this.upcomingRoomsId === apiRequestCallId) {
      this.setState({ isRoomsLoading: false });

      if (responseJson && responseJson.data) {
        this.setState({ upComingRooms: responseJson?.data })
      } else {
        this.setState({ upComingRooms: [] })
      }
    }
  }

  handleRegisterRoomsData = (apiRequestCallId: string, responseJson: any) => {
    if (this.registerRoomID == apiRequestCallId) {
      if (responseJson?.meta) {
        this.handleAlert(responseJson?.meta?.message)
      }
      else if (responseJson?.errors) {
        this.handleAlert(responseJson?.errors[0]?.message || responseJson?.errors[0] || "You have other room scheduled at same time")
      }
      else if (responseJson) {
        // this.getUpcomingRooms(this.state.roomsData)
        this.CongratulationRegisteringModalOpen();
        this.context.setCoin(responseJson.room_price * -1, true)
        this.handleAlertSuccess("Register to Room Successfully!")
      }
      else {
        this.handleAlert("Something went wxrong!")
      }
    }

  }

  handleRgistrantsDetailsData = (apiRequestCallId: string, responseJson: any) => {
    if (this.registrantsDetailsId === apiRequestCallId) {
      if (responseJson && responseJson.data) {
        this.setState({ registrantsDetails: responseJson.data }, () => {
          this.setState({ isUserLoading: false })
        })
        if (this.state.roomDetails?.attributes?.room_type_name === "One-to-one") {
          let acceptedFilteredData = responseJson?.data?.filter((item: any) => item?.attributes?.is_accepted === true);
          acceptedFilteredData.length ? this.setState({ registrantsDetails: acceptedFilteredData }) : this.setState({ registrantsDetails: responseJson.data })
        }
      } else {
        this.setState({ registrantsDetails: [], isUserLoading: false })
      }
    }
  }

  handleReportResourceData = (apiRequestCallId: string, responseJson: any) => {
    if (this.reportResourceId === apiRequestCallId) {
      const data = responseJson?.data;
      if (data) {
        this.handleAlertSuccess("Resource has been reported")
      } else {
        const error = responseJson?.errors[0].message;
        this.handleAlert(error);
      }

    }
  }

  handleUpdateRequest = (apiRequestCallId: string, responseJson: any) => {
    if (this.updateRequestId === apiRequestCallId) {
      if (responseJson && responseJson.data) {
        if (responseJson.data.attributes.is_accepted === true) {
          this.handleAlertSuccess("Accepted")
        } else {
          this.handleAlert("Rejected!")
        }
        this.setState({ registrantsDetails: [] })
        this.getRegistrants(this.state.roomId);
      }
    }
  }

  handleNewRoomUpdate = (apiRequestCallId: string, responseJson: any) => {
    if (this.updateNewResourceId === apiRequestCallId) {
      if (responseJson?.data) {
        this.setState({ roomDetails: responseJson.data })
        this.getHostDetails(this.state.roomId)
      } else {
        this.getRegistrants(this.state.roomId)
      }
      this.handleAlertSuccess('Your Take Successfuly Updated')
    }
  }

  handleMyRoomDeleteTabData = (apiRequestCallId: string, responseJson: any) => {
    if (this.myRoomTabDeleteId === apiRequestCallId) {
      if (responseJson) {
        this.handleAlertSuccess('Room has been cancelled')
        // this.getMyRooms();
        const self: any = this
        setTimeout(() => {
          self.props.history.push('/Rooms?type=my')
        }, 1000)
      }
    }
  }

  handleRemoveFromRoomData = (apiRequestCallId: string, responseJson: any) => {
    if (this.removeFromRoomId === apiRequestCallId) {
      if (responseJson) {
        if (responseJson?.errors) {
          return this.handleAlert(responseJson?.errors[0]);
        }
        this.handleAlertSuccess('Registeration cancelled successfully ');
        this.context.setCoin(this.state.roomDetails.attributes.room_price, true)
        // this.getMyRooms();
        // this.context.setCoin(responseJson.room_price, true)
        const self: any = this
        setTimeout(() => {
          self.props.history.push('/Rooms?type=my')
        }, 1000)
      }
    }
  }
  // Customizable Area End
  // }

  async componentDidMount() {

  }

  handleAlert(msg = "") {
    this.msg = msg
    this.type = "error"
    this.setState({ isMessagePop: true })
  }

  handleAlertSuccess(msg = "") {
    this.msg = msg
    this.type = "success"
    this.setState({ isMessagePop: true })
  }

  // Congratulation Registering Modal
  CongratulationRegisteringModalOpen = () =>
    this.setState((prev) => ({
      congratulationModal: { ...prev.congratulationModal, isOpen: true },
    }));

  CongratulationRegisteringModalClose = () => {
    this.setState((prev) => ({
      congratulationModal: { ...prev.congratulationModal, isOpen: false },
    }));
    this.props.history.push(`/Rooms?type=my`);

    // this.props.navigation.navigate('')
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  toggleTimePassedModal = () => {
    this.setState(prev => ({ isTimePassed: !prev.isTimePassed }));
  }

  // Room Details Function
  getRoomDetails(value: any) {
    this.setState({ isRoomDetailLoading: true })
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.roomDetailsId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.GetRoomDetailsApiEndPoint}/${value}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Host, Participants & Resources Function 
  getHostDetails(value: any) {
    this.setState({ isHostLoading: true })
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.hostDetailsId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.GetRoomDetailsApiEndPoint}/${value}/participants`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getFilterURl = (URL: string) => {
    let finalURL = URL;

    const finalFilters: any = {};
    const {
      room_times: { date, end_time, start_time, type: room_type },
    } = this.state;

    if (room_type) {
      if (room_type !== "custom") {
        finalFilters['start_time'] = '';
        finalFilters['end_time'] = '';
      } else {
        finalFilters['start_time'] = moment(start_time).utcOffset('+05:30').format('YYYY-MM-DD HH:mm:ss');
        finalFilters['end_time'] = moment(end_time).utcOffset('+05:30').format('YYYY-MM-DD HH:mm:ss');;
      }
      finalFilters["room_times"] = room_type.toLowerCase();
    }

    Object.keys(finalFilters).forEach((key) => {
      finalURL += `&${key}=${finalFilters[key]}`;
    });

    return finalURL;
  };

  // Upcoming Rooms Function Room Details behalf of the categories it is different from upcoming rooms page
  getUpcomingRooms(value: any) {
    this.setState({ isRoomsLoading: true });
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.upcomingRoomsId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.getFilterURl(
        `${configJSON.GetUpcomingRoomsApiEndPoint}?sub_category_id=${value.attributes.sub_category_id}&topic_id=${value.attributes.topic_id}`
      )
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // My Rooms Function 
  getMyRooms(value: any) {
    this.setState({ isRoomsLoading: true });

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.upcomingRoomsId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.getFilterURl(`${configJSON.GetMyRoomsEndPoint}sub_category_id=${value.attributes.sub_category_id}&topic_id=${value.attributes.topic_id}`)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getMyConnects(searchKey: string = "") {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMyConnectsId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.GetMyConnectsApiEndPoint}&search=${searchKey}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Recommended Function 
  getRecommendedRooms(value: any) {
    this.setState({ isRoomsLoading: true });

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.upcomingRoomsId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.getFilterURl(`${configJSON.GetRecommendedRoomEndPoint}sub_category_id=${value.attributes.sub_category_id}&topic_id=${value.attributes.topic_id}`)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Register Room Function 
  registerRoom(payload: any, isRegisteredFlag: boolean, UTime: null | number = null) {
    const isTimeZoneWrong = JSON.parse(sessionStorage.getItem('isTimeZoneWrong') || 'false');
    this.setState({ payload });

    if (isTimeZoneWrong && !UTime) {
      this.setState({ apiNeedToCalled: 'CANCEL' });
      this.getCurrentTime();
      return;
    }

    const headers = {
      // "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem('token'),
      TZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      UTime: UTime || Math.floor(new Date().getTime() / 1000).toString()
    };
    this.setState({ isRegisteredFlag: isRegisteredFlag })
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.registerRoomID = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.RegisterRoomApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethodType
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      payload
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  //Report other user resource
  reportResource(resourceId: number, reason: string) {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem('token')
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.reportResourceId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_different_rooms/reported_user/report_to_resource?resource_id=${resourceId}&user_id=${localStorage.getItem('userId')}&reason=${reason}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethodType
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isValidURL = (url: string) => {
    const pattern = /^(https?:\/\/)([a-zA-Z0-9.-]+\.)?(maps\.)?[a-zA-Z0-9-]{2,256}\.[a-z]{2,6}([/?#].*)?$/gi;
    const result = pattern.test((url.includes("http") || url.includes("https")) ? url : ("https://" + url));
    return result;
  };

  //Update User Resource
  updateResource(roomId: any, resourceId: any, url: any) {
    if (!this.isValidURL(url)) {
      this.handleAlert("Please enter valid URL.");
      return;
    }

    const formData = new FormData();
    formData.append("url", url);

    const headers = {
      "token": localStorage.getItem("token")
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateResourceId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateResourceApiEndPoint + `${roomId}` + "/resources/" + `${resourceId}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePut
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    )
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  updateNewResource = (details: any, isHost: any) => {

    const headers = {
      "token": localStorage.getItem("token"),
      'Content-Type': 'application/json'
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateNewResourceId = getValidationsMsg.messageId;

    const apiSelected = isHost ? configJSON.updateNewResourcesAPIEndPoint : configJSON.updateNewResourcesParticipantAPIEndPoint

    if (!isHost) this.setState({ tempUserData: details })

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiSelected
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePut
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      details
    )

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  //Delete own resource
  deleteResource(resourceId: any) {

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("token")
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteResourceId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteresourceApiEndPoint + `${resourceId}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  updateRequest = (registered_room_id: any, update_value: any) => {

    const isAccepted = update_value === "is_accepted" ? "true" : "false"
    const isRejected = update_value === "is_rejected" ? "true" : "false"

    const formData = new FormData();
    formData.append("registered_room_id", registered_room_id);
    formData.append("is_accepted", isAccepted);
    formData.append("is_rejected", isRejected);

    const headers = {
      // "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem('token')
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateRequestId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.PostAcceptApiEndPoint}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethodType
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    )

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Registrants Details Function 
  getRegistrants(value: any) {
    this.setState({ isUserLoading: true })
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.registrantsDetailsId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createRoomAPIEndPoint}/${value}/registrants`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // My Tab Delete Room Section
  myTabDeleteRoom(id: any, UTime: null | number = null) {
    const isTimeZoneWrong = JSON.parse(sessionStorage.getItem('isTimeZoneWrong') || 'false');

    if (isTimeZoneWrong && !UTime) {
      this.setState({ apiNeedToCalled: 'CANCEL_HOST' });
      this.getCurrentTime();
      return;
    }

    this.setState({ cancelModal: false });

    const headers = {
      'Content-Type': configJSON.validationApiContentType,
      token: localStorage.getItem('token'),
      TZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      UTime: UTime || Math.floor(new Date().getTime() / 1000).toString()
    };

    const requestBody = {
      room_id: id,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    // this.getRoomTypesId = getValidationsMsg.messageId;
    this.myRoomTabDeleteId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.MyTabDeleteRoomApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethodType
    );

    //pass request parametres
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // fetch unix time 
  getCurrentTime = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.verifyCurrentTime = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/get_time`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }


  removingFromRoom(room_id: any, UTime: null | number = null) {
    const isTimeZoneWrong = JSON.parse(sessionStorage.getItem('isTimeZoneWrong') || 'false');

    if (isTimeZoneWrong && !UTime) {
      this.setState({ apiNeedToCalled: 'CANCEL' });
      this.getCurrentTime();
      return;
    }

    this.setState({ removeModal: false });
    const headers = {
      // "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token'),
      TZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      UTime: UTime || Math.floor(new Date().getTime() / 1000).toString()
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeFromRoomId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.removeFromRoomApiEndPoint}${room_id}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePut
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  // Handle Your Take 
  startEditing = (user_take: string) => {
    this.setState(prev => (
      {
        your_takes: {
          ...prev.your_takes, take: user_take, isEditing: !prev.your_takes.isEditing
        }
      }));
  }

  updateYourTake = (take: string) => {
    this.setState(prev => ({ your_takes: { ...prev.your_takes, take } }));
  }

  saveEditing = () => {
    if (!this.state.your_takes.take.trim().length) {
      alert("Please Enter a valid take to save...");
      return;
    }

    this.setState(prev => ({ your_takes: { ...prev.your_takes, prevTake: prev.your_takes.take, isEditing: false, isSave: true } }), () => {
      // Call the API to update
      this.callUpdateYourTakeAPI(this.state.your_takes.take, "host");
    });
  }

  callUpdateYourTakeAPI = (new_take: string, type_of_user: "host" | "participant", participant_room_id?: any) => {
    // will be handle once API is avaiable
    const headers = {
      // "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem('token')
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ytakeupdateId = getValidationsMsg.messageId;

    if (type_of_user === "host") {
      const paths = window.location.pathname.split("/");
      const host_room_id = paths[paths.length - 1];

      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.PutHostYTakeApiEndPoint}?room_id=${host_room_id}&your_take=${new_take}`
      );
    } else if (type_of_user === "participant") {
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.PutParticipantYTakeApiEndPoint}?registered_room_id=${participant_room_id}&your_take=${new_take}`
      );
    } else {
      alert("Something went wrong ....");
      return;
    }

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypePut
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  CancelModalOpen = () => this.setState({ cancelModal: true });
  CancelModalClose = () => {
    this.setState({ cancelModal: false });
  }

  // For Removing as a participants
  RemoveModalOpen = () => this.setState({ removeModal: true });
  RemoveModalClose = () => this.setState({ removeModal: false });

  // Handle Resource Like & DisLike
  handleLikeCount = (resource_id: number) => {

    const token = localStorage.getItem('token');
    if (!token) return;

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateLikeID = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateLikeAPI
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          attributes: {
            likeable_id: resource_id,
            likeable_type: "BxBlockDifferentRooms::Resource"
          }
        }
      })
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateLikeMethod
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);
  }

  handleCallAPI = () => {
    const { data, prevUrl } = this.state.details;
    if (prevUrl === "upcoming") {
      this.getUpcomingRooms(data); // This is get upcoming rooms function of room details page it is upcoming rooms card function
    } else if (prevUrl === "recommended") {
      this.getRecommendedRooms(data); // This is get recommended rooms function of room details page it is recommended rooms card function
    } else if (prevUrl === "my") {
      // this.getMyRooms(data); // This is get myrooms rooms function of room details page it is myrooms rooms card function
    }
  }

  handleRoomTime = (room_type: string, dateTime: DT | null) => {
    const selected_room = this.state.room_times.type === room_type ?
      (room_type === 'custom' && dateTime) ? 'custom' : '' : room_type;

    this.setState((prev) => ({
      room_times: {
        ...prev.room_times,
        type: selected_room,
      },
    }), () => {

      if (room_type == "custom" && dateTime) {
        const { date, start_time, end_time } = dateTime as DT;
        this.setState(prev => ({
          room_times: {
            ...prev.room_times,
            date: date,
            start_time: start_time,
            end_time: end_time,
          }
        }),
          () => this.handleCallAPI()
        );
      } else if (room_type !== "custom" ||
        (room_type == "custom" && selected_room === '' && !dateTime)) {
        this.handleCallAPI();
      }
    });
  };

  setRoomData = (previousURL: string, data: any) => {
    this.setState(({ details: { prevUrl: previousURL || 'upcoming', data }, roomDetails: data }), () => {
      this.setState({ isRoomDetailLoading: false })
      if (previousURL === 'upcoming') {
        this.getUpcomingRooms(data); // This is get upcoming rooms function of room details page it is upcoming rooms card function
      } else if (previousURL === 'recommended') {
        this.getRecommendedRooms(data); // This is get recommended rooms function of room details page it is recommended rooms card function
      } else if (previousURL === 'my') {
        // this.getMyRooms(data); // This is get myrooms rooms function of room details page it is myrooms rooms card function
      }
    });
  }

  handleResize = () => {
    const isMobile = window.innerWidth <= 768;
    this.setState({ isMobile }, () => {
      // grab the status, and add flex 1 if contains CANCEL text
      const container = document.getElementById("roomStatus");
      if (!container) return;

      const containerText = container.textContent;
      if (!containerText) return;

      if (!this.state.isMobile) {
        if (containerText.toLowerCase().includes("cancel")) {
          container.style.flex = "1";
        }
      } else {
        container.style.flex = "0";
      }
    });
  }
  // Customizable Area End
} 