// Customizable Area Start
import React, { Component } from 'react';
import { Grid, TextField, Button } from '@material-ui/core';
import { FiSend } from 'react-icons/fi';
import { VscSmiley } from 'react-icons/vsc';
import EmojiPicker from 'emoji-picker-react';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import '../SideBar.css';

interface myProps {
	conversation: any;
}
export default class MessageFooter extends Component<myProps> {
	state = { emoji: false, text: '', onetime: true };

	handleTextMsg = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ text: e.target.value });
	};

	componentDidUpdate = async () => {
		if (this.props.conversation && this.state.onetime) {
			this.setState({ onetime: false });
		}

		if (this.state.text.length > 0) {
			const x = await this.props.conversation?.typing();
		}
	};

	send = async () => {
		//sending the messages
		try {
			if (this.state.text.trim().length > 0) {
				let userMsg = this.state.text;
				this.setState({ text: '' });
				await this.props.conversation?.sendMessage(userMsg);
				this.props.conversation.setAllMessagesRead();
			}
		} catch (err) {
			console.log(err)
		}
	};

	render() {
		return (
			<Grid className='message-box' alignItems='flex-end' container>
				<Grid className='message-tool' item xs={2}>
					<Button
						onClick={() => this.setState({ emoji: !this.state.emoji })}
						variant='outlined'
					>
						{this.state.emoji ? <KeyboardIcon /> : <VscSmiley fontSize={23} />}
					</Button>
				</Grid>
				<Grid className='text-message' xs={8} item>
					<TextField
						onKeyDown={(e) => {
							if (e.key === 'Enter') this.send();
						}}
						id='text-message'
						fullWidth
						variant='outlined'
						onChange={this.handleTextMsg}
						value={this.state.text}
						placeholder='Type a message'
						size='small'
						autoFocus
					/>
				</Grid>
				<Grid className='message-tool' item xs={2}>
					<Button onClick={this.send} variant='outlined'>
						<FiSend fontSize={23} />
					</Button>
				</Grid>
				<Grid id='emoji-picker' style={{ textAlign: 'center' }} item xs={12}>
					{this.state.emoji && (
						<EmojiPicker
							height={'25vh'}
							width={'100%'}
							onEmojiClick={(emoji) =>
								this.setState({ text: this.state.text + emoji.emoji })
							}
							searchDisabled
							previewConfig={{
								showPreview: false
							}}
						/>
					)}
				</Grid>
			</Grid>
		);
	}
}
// Customizable Area End
