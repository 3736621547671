import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
   getName
} from "../../../../framework/src/Messages/MessageEnum";
export const configJSON = require("./config");

export interface Props {
   navigation: any;
   id: string;
   // Customizable Area Start
   history?: any;
   // Customizable Area End
}

export interface S {
   // Customizable Area Start
   userProfileData: any;
   data: any[]
   isMessagePop: boolean,
   subCategories: any[]
   payloadData: any
   loading: boolean
   checker: boolean
   checker2: boolean
   checker3: boolean
   onboardingQuestions: any[];
   hasAnyError: boolean[]
   submitEnable: boolean
   countryList: any[]
   blockNavigation: any
   // Customizable Area End
}

export interface SS {
   // Customizable Area Start
   id: any;
   // Customizable Area End
}

export default class UserEditProfileController extends BlockComponent<
   Props,
   S,
   SS
> {
   // Customizable Area Start
   getUserProfileDataId: string
   getOnboardingQuestionsId: string
   subCategoryListId: string
   onboardingAnswersId: any
   onboardingModeratorAnswersId: any
   arrayholder: any[]
   countryListId: string
   msg = '';
   type = '';
   // Customizable Area End

   constructor(props: Props) {
      super(props);
      this.subScribedMessages = [
         getName(MessageEnum.RestAPIResponceMessage),
         getName(MessageEnum.NavigationPayLoadMessage),
         getName(MessageEnum.CountryCodeMessage)
      ];
      this.receive = this.receive.bind(this);

      runEngine.attachBuildingBlock(this, this.subScribedMessages);

      this.state = {
         // Customizable Area Start
         userProfileData: [],
         data: [],
         isMessagePop: false,
         subCategories: [],
         payloadData: [],
         loading: false,
         onboardingQuestions: [],
         checker: false,
         hasAnyError: [true, true, true],
         submitEnable: false,
         checker2: false,
         checker3: false,
         countryList: [],
         blockNavigation: false
         // Customizable Area End
      };

      // Customizable Area Start
      this.arrayholder = [];
      // Customizable Area End
   }

   // Customizable Area Start
   async componentDidMount(): Promise<void> {
      localStorage.setItem("moderator-refreshed", JSON.stringify(false));
      const refreshed = JSON.parse(localStorage.getItem("edit-profile-refreshed") as string);
      if (!refreshed) {
         localStorage.setItem("edit-profile-refreshed", JSON.stringify(true));
         window.location.reload();
      }

      this.getUserProfileData();
      this.subCategoryList(1);
      this.getCountryCode();
   }

   // Customizable Area End
   async receive(from: string, message: Message) {
      // Customizable Area Start

      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
         const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
         );

         var responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
         );

         var errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
         );

         if (apiRequestCallId && responseJson) {
            if (apiRequestCallId === this.onboardingModeratorAnswersId) {
               this.setState({ loading: false }, () => {
                  localStorage.setItem("userName", responseJson.data?.attributes.user_name);
                  localStorage.setItem("fullName", responseJson.data?.attributes.full_name);
                  localStorage.setItem("profile_image", responseJson.data?.attributes.profile_image);
                  window.dispatchEvent(new Event("storage"));
                  this.props.navigation.navigate("UserPersonalProfile");
                  // setTimeout(() => {
                  // }, 300);
               })

            } else if (apiRequestCallId === this.onboardingAnswersId) {
               localStorage.setItem("userName", responseJson.data?.attributes.user_name);
               localStorage.setItem("profile_image", responseJson.data?.attributes.profile_image);
               window.dispatchEvent(new Event("storage"));

               this.setState({ loading: false }, () => {
                  if (!responseJson.data.attributes.is_moderator) {
                     this.props.navigation.navigate("UserPersonalProfile")
                  }
               })
            } else if (apiRequestCallId === this.getUserProfileDataId) {
               this.setState({ userProfileData: responseJson?.data?.attributes }, () => {
                  let id = localStorage.setItem("selectedUserId", responseJson?.data?.id);
                  const conversation_sids = responseJson?.data?.attributes.conversation_sid;
                  if (conversation_sids.length > 0) {
                     sessionStorage.setItem("conversation_sid", conversation_sids[conversation_sids.length - 1]);
                  } else {
                     sessionStorage.setItem("conversation_sid", "");
                  }
                  this.getOnboardingQuestions();
               })
               if (!responseJson?.data?.attributes.is_moderator) {
                  const errList: boolean[] = this.state.hasAnyError
                  errList[2] = false
                  this.setState({ hasAnyError: errList })
               }
            } else if (this.subCategoryListId === apiRequestCallId) {
               if (responseJson && responseJson.data) {
                  this.setState({ subCategories: responseJson.data })
               }
            } else if (responseJson.errors) {
               if (responseJson.errors[0]?.token) {
                  this.props.history.push("registration")
               }
            } else if (this.countryListId === apiRequestCallId) {
               this.setState({ countryList: responseJson?.data })
            } else if (this.getOnboardingQuestionsId === apiRequestCallId) {
               this.setState({ onboardingQuestions: responseJson.data })
            } else {
               this.setState({ loading: false }, () => {
                  // this.props.navigation.navigate("UserPersonalProfile")
               })
            }
         }
      }
      // Customizable Area End
   }

   // Customizable Area Start
   onboardingAnswers(payload: any) {
      let formData = new FormData()
      for (const [key, value] of Object.entries(payload)) {
         if (key === 'onboarding_data') {
            formData.append('onboarding_data', JSON.stringify(value))
         } else {
            //@ts-ignore
            formData.append(key, value)
         }
      }
      const headers = {
         "Content-Type": undefined,
         "token": localStorage.getItem("token")
      };

      const getValidationsMsg = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
      );

      this.onboardingAnswersId = getValidationsMsg.messageId;

      getValidationsMsg.addData(
         getName(MessageEnum.RestAPIResponceEndPointMessage),
         configJSON.onBoardingAnswerAPIEndPoint + localStorage.getItem("userId")
      );

      getValidationsMsg.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(headers)
      );
      getValidationsMsg.addData(
         getName(MessageEnum.RestAPIRequestMethodMessage),
         configJSON.methodTypePatch
      );

      getValidationsMsg.addData(
         getName(MessageEnum.RestAPIRequestBodyMessage),
         formData
      )
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
   }

   moderatorAnswers(payload: any) {
      const headers = {
         "Content-Type": undefined,
         "token": localStorage.getItem("token")
      };

      const getValidationsMsg = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
      );

      this.onboardingModeratorAnswersId = getValidationsMsg.messageId;

      getValidationsMsg.addData(
         getName(MessageEnum.RestAPIResponceEndPointMessage),
         configJSON.onBoardingAnswerAPIEndPoint + localStorage.getItem("userId")
      );

      getValidationsMsg.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(headers)
      );
      getValidationsMsg.addData(
         getName(MessageEnum.RestAPIRequestMethodMessage),
         configJSON.methodTypePatch
      );

      getValidationsMsg.addData(
         getName(MessageEnum.RestAPIRequestBodyMessage),
         payload
      )
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
   }

   getCountryCode() {
      const headers = {
         "Content-Type": configJSON.validationApiContentType,
         "Access-Control-Allow-Origin": "*"
      };

      const getValidationsMsg = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
      );
      this.countryListId = getValidationsMsg.messageId;

      getValidationsMsg.addData(
         getName(MessageEnum.RestAPIResponceEndPointMessage),
         configJSON.getCountryListApiEndPoint
      );

      getValidationsMsg.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(headers)
      );
      getValidationsMsg.addData(
         getName(MessageEnum.RestAPIRequestMethodMessage),
         configJSON.methodTypeGet
      );
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
   }

   getUserProfileData() {
      const headers = {
         "Content-Type": configJSON.validationApiContentType,
         "token": localStorage.getItem("token")
      };

      const getValidationsMsg = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getUserProfileDataId = getValidationsMsg.messageId;

      getValidationsMsg.addData(
         getName(MessageEnum.RestAPIResponceEndPointMessage),
         configJSON.getUserProfileDataAPIEndPoint + localStorage.getItem('userId')
      );

      getValidationsMsg.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(headers)
      );
      getValidationsMsg.addData(
         getName(MessageEnum.RestAPIRequestMethodMessage),
         configJSON.methodTypeGet
      );
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
   }

   getOnboardingQuestions() {
      const headers = {
         "Content-Type": configJSON.validationApiContentType,
         "token": localStorage.getItem("token")
      };

      const getValidationsMsg = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getOnboardingQuestionsId = getValidationsMsg.messageId;

      getValidationsMsg.addData(
         getName(MessageEnum.RestAPIResponceEndPointMessage),
         configJSON.onBoardingQuestionApiEndPoint
      );

      getValidationsMsg.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(headers)
      );
      getValidationsMsg.addData(
         getName(MessageEnum.RestAPIRequestMethodMessage),
         configJSON.methodTypeGet
      );
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
   }

   subCategoryList(value: any) {

      const headers = {
         "Content-Type": configJSON.validationApiContentType,
         "token": localStorage.getItem("token")

      };

      const getValidationsMsg = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
      );
      this.subCategoryListId = getValidationsMsg.messageId;

      getValidationsMsg.addData(
         getName(MessageEnum.RestAPIResponceEndPointMessage),
         configJSON.subCategoryListApiEndPoint
      );

      getValidationsMsg.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(headers)
      );
      getValidationsMsg.addData(
         getName(MessageEnum.RestAPIRequestMethodMessage),
         configJSON.methodTypeGet
      );

      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
   }

   setUserData = (payload: any) => {
      if (this.state.checker3) {
         this.moderatorAnswers(payload)
      } else if (this.state.checker2) {
         let d = this.state.payloadData
         d.onboarding_data = payload
         this.setState({ payloadData: d },
            () => {
               this.setState({ checker3: true })
               this.onboardingAnswers(this.state.payloadData)
            }
         )
      } else if (this.state.checker)
         this.setState({ payloadData: payload },
            () => {
               if (this.state.checker) {
                  // this.onboardingAnswers(this.state.payloadData)
                  this.setState({ checker2: true })
               }
            }
         )
   }

   handleSubmit = () => {
      this.setState({ blockNavigation: false })
      this.setState({ loading: true })
      this.setState({ checker: true })
   }

   formChanged = () => {
      if (!this.state.blockNavigation) {
         this.setState({ submitEnable: true })
      }
      this.setState({ blockNavigation: true })
   }

   changeAnyError = (condition: boolean, index: number) => {
      const errList: boolean[] = this.state.hasAnyError
      errList[index] = condition
      this.setState(({ hasAnyError: errList }), () => {
         let allDone: boolean = this.state.hasAnyError.every(item => item === false)
         if (allDone) this.setState({ submitEnable: allDone })
         else this.setState({ submitEnable: false })
      })
   }

   handleAlert = (msg = '', success: boolean) => {
      this.msg = msg;
      this.type = success ? 'success' : 'error';
      this.setState({ isMessagePop: true });
   }


   handleCloseAlert = () => {
      this.setState({ isMessagePop: false })
   }
}