Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Referrals";
exports.labelBodyText = "Referrals Body";

exports.btnExampleTitle = "CLICK ME";

exports.createConversation = "bx_block_chat/chat_tokens/create_conversations";
exports.sendMessageAPI = "bx_block_chat/chat_tokens/create_message";
exports.sendMessageMethod = "POST";

exports.twilioAPI = "bx_block_chat/chat_tokens/get_access_token";
exports.twilioMethod = "GET";

exports.loadUsersAPI = "bx_block_chat/chat_tokens/user_chats";
exports.loadUsersMethod = "GET";

exports.loadMessagesAPI = "bx_block_chat/chat_tokens/get_messages";
exports.loadMessagesMethod = "GET";

exports.disconnectUserAPI =
  "bx_block_request_management/requests/disconnect_request";
exports.disconnectUserMethod = "POST";

exports.landingPageAPI = "bx_block_profile/profiles/get_landing_page_data";
exports.landingPageMethod = "GET";

exports.friendsRequests = "bx_block_request_management/requests/friends";
exports.friendsRequestsMethod = "GET";

exports.fetchConversationAPI = "bx_block_profile/profiles";
exports.fetchConversationMethod = "GET";

exports.sendConversationRequestApiEndPoint =
  "bx_block_chat/chat_tokens/create_conversations";
exports.methodTypePost = "POST";
// Customizable Area End
