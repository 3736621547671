import React from "react";
// Customizable Area Start
import RoomsController from "../MyRooms/RoomsController";
import { withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";
import Tutorials from "../MyRooms/Tutorials.web";

const ROOMS = ["upcoming", "recommended", "my"];

const DisplayRoute = ({ room, isSelected, handleRouteChange }: any) => {
  return (
    <Typography
      key={room}
      className="colorGrey roomTab colorBlue ptblr pointer fw500 fs18 mt0"
      onClick={() => handleRouteChange(room)}
      style={{
        borderBottom: isSelected ? "5px solid #ffcb00" : "none",
        textTransform: "capitalize",
        fontWeight: 600,
        color: !isSelected ? "#7f8e9d" : "#000"
      }}
    >
      {room} Rooms
    </Typography>
  )
}

class RoomTabs extends RoomsController {
  constructor(props: any) {
    super(props);
  }

  async componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const params = new URLSearchParams(this.props.history.location.search);
    const path = params.get('type');
    const { handleRouteChange } = this.props;

    return (
      <>
        <section className={`tab mb10 ${this.state.isMobile ? 'hide_scroll' : ''}`}
          style={{ width: this.state.isMobile ? '100dvw' : 'auto', overflowX: 'auto', overflowY: 'hidden' }}>
          <div className="daf">
            {ROOMS.map((room) => {
              const isSelected = room === path;
              if (room === 'upcoming') {
                return (
                  <Tutorials
                    key={room}
                    current={this.context.tutorials.count}
                    data={this.context.tutorials.data}
                    onNext={this.context.onNextTutorial}
                    isOpen={this.context.tutorials.isOpen && this.context.tutorials.count === 1}
                    {...this.props}
                  >
                    <DisplayRoute
                      room={room}
                      isSelected={isSelected}
                      handleRouteChange={handleRouteChange}
                    />
                  </Tutorials>
                )
              }
              return (
                <DisplayRoute
                  key={room}
                  room={room}
                  isSelected={isSelected}
                  handleRouteChange={handleRouteChange}
                />
              )
            })}
          </div>
          <div className="bor-bottom-myrooms" style={{ width: this.state.isMobile ? '135%' : 'auto' }} />
        </section>
      </>
    );
  }
}

export default withRouter(React.memo(RoomTabs));
