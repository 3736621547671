// Customizable Area Start
import React from 'react';
import {
	Card, CardContent, CardActions,
	Button, Box, Avatar, TextField, Typography
} from '@material-ui/core';
import { blueTick, goldenTick, userDefaultImg } from "../../../assets";
import '../SideBar.css';
import participantTabController from './participantTabController';

export default class ParticipantTab extends participantTabController {
	showResources = () => {
		return this.props.resources.length > 0 && (
			<>
				<div style={{ textAlign: "left" }}>
					<Typography style={{ fontWeight: 600, color: "#262556" }}>Resources</Typography>
				</div>

				<div className='x-field'>
					{this.props.resources.map((each: string, index: number) => {
						const hasHttp = each.toLocaleLowerCase().includes('http');
						return (
							<div className='resource-links' key={index}>
								<a href={hasHttp ? each : `https://${each}`} target="_blank">
									<TextField
										id='outlined-basic'
										fullWidth
										multiline
										minRows={1}
										placeholder={each}
										variant='outlined'
										InputProps={{ readOnly: true }}
									/>
								</a>
							</div>
						)
					})}
				</div>
			</>
		);
	}

	render() {
		const { connectionStatus } = this.state;
		const status = connectionStatus?.toLowerCase();
		const isPending = status === "pending";
		const isConnected = status === "connected";
		const isBlocked = status === "blocked" || status === "blockedby";
		let btnText: string;
		if (isConnected) {
			btnText = "Connected";
		} else if (isPending) {
			btnText = "Pending";
		} else if (isBlocked) {
			btnText = "Blocked";
		} else {
			btnText = "SEND CONNECTION REQUEST";
		}

		const CustomButton = ({ buttonText, disabled, handleOnClick }: any) => {
			return (
				<Button
					style={{
						backgroundColor: disabled ? 'gray' : '#010039',
						pointerEvents: disabled ? "none" : 'auto'
					}}
					onClick={handleOnClick}
					disabled={disabled}
					fullWidth
					disableElevation
					id='connection-button'
				>
					{buttonText}
				</Button>
			)
		}

		const handleBtnClick = (e: React.MouseEvent) => {
			if (!isPending) this.handleClick(e);
		}

		const constAcceptReject = (choice: "Rejected" | "Accepted") => {
			this.sendConnectRequest(choice, this.props.connectionRequest.id || this.props.connectionRequest.attributes.id);
		}

		return (
			<div>
				<Card id='participants' variant='outlined'>
					<CardContent>
						<div className='userdata-and-hearts'>
							<div className='user-username'>
								{this.props.is_anonymously ? (
									<div style={{ marginRight: ".3rem" }}>
										<img
											src={userDefaultImg}
											alt="anonymous_users"
											style={{
												borderRadius: ".5rem", width: "3.2rem", height: "3.2rem",
												border: "2px solid #ccc"
											}}
										/>
									</div>
								) : (
									<div className="user-card__image-container">
										<Avatar
											className='user-avatar'
											variant='square'
											alt='avatar'
											src={this.props.userImage}
										/>
										{(this.props.is_moderator || this.props.is_superhost) && (
											<img
												src={this.props.is_moderator ? goldenTick : blueTick}
												alt="verified_tick"
												className="video-room_check-mark"
											/>
										)}
									</div>
								)}

								<div className='user-name'>
									<Box display="flex" alignItems={"center"}>
										<span className='label'
										>{this.props.is_anonymously ? this.props.anonymously_name : this.props.fullName}</span>
									</Box>
									{!this.props.is_anonymously && <span
										style={{ color: '#d3d3d3', textAlign: 'left' }}
									>
										@{this.props.user_name}
									</span>}
								</div>
							</div>

						</div>
						<Box mt={1}>
							<Typography style={{ fontWeight: 600, color: "#262556" }}>Your Take</Typography>
						</Box>
						<div className='x-field'>
							<label className='label' htmlFor='outlined-multiline-static' />
							<TextField
								id='outlined-multiline-static'
								fullWidth
								multiline
								minRows={4}
								maxRows={4}
								InputProps={{ readOnly: true }}
								placeholder={this.props.yourTake ? this.props.yourTake : "No take provided"}
								variant='outlined'
							/>
						</div>
						{this.showResources()}
					</CardContent>
					<CardActions>
						{((this.props.self || this.props.is_anonymously) || this.props.selfAnonymously) ? null : (
							(btnText === "Pending"
								&& Number(this.props.connectionRequest?.account_id) ===
								Number(localStorage.getItem("userId") as string)) ? (
								<>
									<CustomButton
										buttonText={"Reject"}
										disabled={false}
										handleOnClick={() => constAcceptReject("Rejected")}
									/>
									<CustomButton
										buttonText={"Accept"}
										disabled={false}
										handleOnClick={() => constAcceptReject("Accepted")}
									/>
								</>
							) : btnText === 'Blocked' ? null : (
								<CustomButton
									buttonText={btnText}
									disabled={btnText !== "SEND CONNECTION REQUEST"}
									handleOnClick={handleBtnClick}
								/>
							)
						)}
					</CardActions>
				</Card>
			</div >
		);
	}
}
// Customizable Area End
