import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { AlertProps } from "@material-ui/lab/Alert";
import { DraggableData, DraggableEvent } from "react-draggable";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  leave: boolean;
  handleLeave: (x: boolean) => void;
  roomId: string;
  feedbackData: any;
  endTime: string;
  history: any;
  isMobile?: any;
  allParticipants: any;
  isModeratorRoom: boolean;
  isHost?: boolean;
  joinedUsers: string[];
  currentUser: any;
  reloadRoom?: () => void;
  sendConnectionRequest: (userId: number) => void;
  revertConnectionRequest: (userId: number) => void;
  handleDisconnectUser: (userId: number) => void;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  openFeedBackpopup: boolean;
  openReportPopup: boolean;
  openSmileyPopup: boolean;
  selectedValue: boolean;
  reportValue: string;
  reportId: number;
  smileyValue: string;
  sorryPopUp: boolean;
  sorryValue: string;
  refundPopUp: boolean;
  refundValue: string;
  allFeedback: any;
  roomFeedback: string;
  sadFeedback: string;
  reportAlert: boolean;
  reportAlertMsg: string;
  alertType: AlertProps["severity"];
  extraInfo: any;
  emoji: string;
  prevUserFeedback: any[];
  prevRoomFeedback: any;
  prevRoomData: string;
  prevRoomReaction: any;
  prevRefundStatus: string;
  isFeedbackSending: boolean;
  needRefund: "yes" | "no" | '';
  userChoosenReaction: string;

  defaultPosition: { x: number, y: number };
  isMoreOptionsOpen: boolean;
  selectedUserID: number | null;
  isSelectedUserAnonymous: boolean;
  status: string;
  openThankYouFeedbackModal: { isOpen: boolean, text: string };
  openBlockModal: boolean;
  openUnBlockModal: boolean;
  openDisconnectModal: boolean;
  bulletPoints: { refundText: string, thankYouText: string };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FeedbackController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  reportUserMessageId: string;
  roomFeedbackMessageId: string;
  participantFeedbackMessageId: string;
  totalHeartMessageId: string;
  prevUserFeedbackID: string;
  prevRoomFeedbackID: string;
  acceptRejectFriendConnectionId: string;
  endRoomForAllID: string;
  earnedYCoinsID: string;
  blockUserMessageID: string;
  bulletPointsId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      openFeedBackpopup: this.props.joinedUsers.length > 1 ? this.props.leave : false,
      selectedValue: false,
      openReportPopup: false,
      openSmileyPopup: this.props.joinedUsers.length === 1 ? true : false,
      smileyValue: "",
      reportValue: "",
      reportId: -1,
      sorryPopUp: false,
      sorryValue: "",
      refundPopUp: false,
      refundValue: "",
      alertType: "error",
      allFeedback: null,
      roomFeedback: "",
      sadFeedback: "",
      reportAlert: false,
      reportAlertMsg: "Something went wrong — please try again",
      extraInfo: null,
      emoji: "",
      prevUserFeedback: [],
      prevRoomFeedback: null,
      prevRoomData: '',
      prevRoomReaction: null,
      isFeedbackSending: false,
      needRefund: "",
      userChoosenReaction: '',
      prevRefundStatus: '',

      defaultPosition: { x: 0, y: 0 },
      isMoreOptionsOpen: false,
      selectedUserID: null,
      isSelectedUserAnonymous: false,
      status: '',
      openThankYouFeedbackModal: { isOpen: false, text: '' },
      openBlockModal: false,
      openUnBlockModal: false,
      openDisconnectModal: false,
      bulletPoints: { refundText: "", thankYouText: "" },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let errorJson = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (errorJson) {
        console.log(errorJson);
        return;
      }

      this.handleReportUserMessageId(apiCallId, responseJson);
      this.handleRoomFeedbackMessageId(apiCallId, responseJson, errorJson);
      this.handleTotalHeartMessageId(apiCallId, responseJson, errorJson);
      this.handlePrevUserFeedbackID(apiCallId, responseJson, errorJson);
      this.handlePrevRoomFeedbackID(apiCallId, responseJson, errorJson);
      this.handleBlockUserID(apiCallId, responseJson);
      this.handleBulletPointsData(apiCallId, responseJson);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleShowAlert = (reportAlertMsg: string, alertType: 'error' | 'success') => {
    this.setState({ reportAlert: true, reportAlertMsg, alertType });
  }

  handleReportUserMessageId = (apiCallId: string, responseJson: any) => {
    if (this.reportUserMessageId === apiCallId) {
      if (!responseJson.data && responseJson?.errors[0]) {
        this.setState({ openThankYouFeedbackModal: { text: responseJson?.errors[0]?.message, isOpen: true } });
      } else {
        this.setState({ openThankYouFeedbackModal: { text: 'At YLanes, we value the time taken by our guests for Meaningful Conversations.', isOpen: true } });
      }
      this.setState({ openReportPopup: false });
    }
  }

  handleRoomFeedbackMessageId = (apiCallId: string, responseJson: any, errorJson: any) => {
    if (this.roomFeedbackMessageId === apiCallId) {
      if (responseJson?.errors) {
        this.updateRoomFeedback();

        setTimeout(() => {
          this.setState({ isFeedbackSending: false });
        }, 500);
        return;
      }

      const reaction = responseJson?.data?.attributes?.reaction;
      const redund = responseJson?.data?.attributes?.refund;

      if (reaction === "happy" || reaction === "ok") {
        setTimeout(() => {
          this.props.history.push('/Rooms?type=my');
          if (this.props.reloadRoom) this.props.reloadRoom();
        }, 700);
      } else if (reaction === "sad" && redund) {
        this.setState({
          needRefund: redund,
        }, () => this.setState({
          refundPopUp: true
        }));
      }
    }
  }

  getGender = (gender: string) => {
    let result;

    if (gender === 'Male') {
      result = 'M';
    } else if (gender === 'Female') {
      result = 'F';
    } else {
      result = 'NB';
    }

    return result;
  }

  handleTotalHeartMessageId = (apiCallId: string, responseJson: any, errorJson: any) => {
    if (this.totalHeartMessageId === apiCallId) {
      if (errorJson) {
        console.log(errorJson);
        return;
      }
      this.getLastUsersFeedback();
      this.getLastRoomFeedback();
      let extraInfo: any = {};
      extraInfo[responseJson?.host_details?.account_id] = {
        hearts: responseJson?.host_details?.hearts,
        isSuperhost: responseJson?.host_details?.is_superhost,
        isModerator: responseJson?.host_details?.is_moderator,
        gender: this.getGender(responseJson?.host_details?.gender),
        age_range: responseJson?.host_details?.age_range
      };
      for (const x of responseJson.participants.data) {
        extraInfo[x.attributes.participant.data.id] = {
          hearts: x.attributes.participant.data.attributes.hearts,
          isSuperhost: x.attributes.participant.data.attributes.is_superhost,
          isModerator: x.attributes.participant.data.attributes.is_moderator,
          gender: this.getGender(x.attributes.participant.data.attributes?.gender),
          age_range: x.attributes.participant.data.attributes?.age_range
        };
      }

      this.setState({ extraInfo })
    }
  }

  handlePrevUserFeedbackID = (apiCallId: string, responseJson: any, errorJson: any) => {
    if (this.prevUserFeedbackID === apiCallId) {
      if (errorJson) return;
      this.setState({ prevUserFeedback: responseJson?.data });
    }
  }

  handlePrevRoomFeedbackID = (apiCallId: string, responseJson: any, errorJson: any) => {
    if (this.prevRoomFeedbackID === apiCallId) {
      if (errorJson) return;
      if (responseJson?.data)
        this.setState({ prevRoomFeedback: responseJson?.data }, () => {
          this.setState({
            roomFeedback: this.state.prevRoomFeedback?.attributes?.feedback,
            prevRoomData: this.state.prevRoomFeedback?.attributes?.feedback,
            prevRoomReaction: this.state.prevRoomFeedback?.attributes?.reaction,
            prevRefundStatus: this.state.prevRoomFeedback?.attributes?.refund,
          })
        });
    }
  }

  handleBlockUserID = (apiCallId: string, responseJson: any) => {
    if (apiCallId === this.blockUserMessageID) {
      if (responseJson) {
        const msg = responseJson?.message;
        this.handleShowAlert(msg, 'success');
        this.setState(prev => ({ status: prev.status === 'blocked' ? 'connect' : 'blocked' }))
        setTimeout(() => {
          if (msg.includes('unblock'))
            this.toggleUnBlockModal();
          else if (msg.includes('block'))
            this.toggleBlockModal();
        }, 500);
      }
    }
  }

  handleBulletPointsData = async (apiCallId: string, responseJson: any) => {
    if (this.bulletPointsId === apiCallId && responseJson) {
      // TODO: make this dynamic based on createRoomTextData type ("plain_text" or "html")
      const createRoomTextData = responseJson.data?.find(
        (item: any) =>
          item.attributes.content_key === "BAD_CONVERSATION_SORRY_MESSAGE"
      )?.attributes;
      if (createRoomTextData) {
        this.setState({
          bulletPoints: {
            refundText:
              JSON.parse(createRoomTextData.content_value ?? "{}")
                ?.refundText ?? "",
            thankYouText:
              JSON.parse(createRoomTextData.content_value ?? "{}")
                ?.thankYouText ?? "",
          },
        });
      }
    }
  };

  async componentDidMount() {
    let feedbackArray;
    if (this.props.feedbackData instanceof Map) {
      feedbackArray = Array.from(this.props.feedbackData?.values());
    } else {
      feedbackArray = this.props.feedbackData;
    }
    let m = new Map();
    feedbackArray.forEach((p: any) => {
      m.set(p.userId, { userId: p.userId, heartInput: p.hearts, feedbackInput: '' })
    })
    this.setState({ allFeedback: m }, () => {
      this.getTotalHeart();
    })
    this.bulletPoints();
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    if (prevProps.feedbackData.length !== this.props.feedbackData.length) {
      if (this.props.joinedUsers.length === 1) {
        this.setState({ openSmileyPopup: true, openFeedBackpopup: false });
        return;
      } else if (this.props.joinedUsers.length > 1) {
        this.setState({ openSmileyPopup: false, openFeedBackpopup: true });
      }
    }
  }

  bulletPoints() {
    const headers = {
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.bulletPointsId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bulletPointsAPIEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  endRoomForAll = () => {
    const token = localStorage.getItem("token");

    if (token) {
      const header = {
        // "Content-Type": configJSON.contentType,
        token: token
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.endRoomForAllID = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.endCallForAllEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.endCallForCallMethod
      );

      const [_, ID] = window.location.href.split("Room/");
      const formData = new FormData();
      formData.append("room_id", ID);

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      )

      // runEngine.sendMessage(requestMessage.id, requestMessage);
      // Redirect the user to home page 
      setTimeout(() => this.props.history.push('/Rooms?type=my'), 1000);
    }
  }

  // moderator will earn ycoins after end of the room
  earnedYCoins = () => {
    const token = localStorage.getItem("token");

    if (token) {
      const header = {
        // "Content-Type": configJSON.contentType,
        token: token
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.earnedYCoinsID = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.earnedYCoinsEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.earnedYCoinsMethod
      );

      const [_, ID] = window.location.href.split("Room/");
      const formData = new FormData();
      formData.append("room_id", ID);

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      )

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  showSnackbar = (msg: string, type: "success" | "error") => {
    this.setState({ reportAlert: true, reportAlertMsg: msg, alertType: type });
  }

  sendRoomFeedback = (reaction: string) => {
    if (!reaction && !this.state.prevRoomReaction) {
      this.showSnackbar("Please select a reaction.", "error");
      return;
    }

    this.setState({ userChoosenReaction: reaction });

    let reqMsg1 = new Message(getName(MessageEnum.RestAPIRequestMessage));

    if (this.state.prevRoomData.trim().length) {
      reqMsg1.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.updateRoomFeedback}/${this.state.prevRoomFeedback.id}`);
      reqMsg1.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.updateRoomFeedbackMethod);
    } else {
      reqMsg1.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.roomFeedbackEndpoint);
      reqMsg1.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostType);
    }

    const header = {
      token: localStorage.getItem("token")
    };

    reqMsg1.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    let formdata = new FormData();
    formdata.append("room_id", this.props.roomId);

    if (reaction === "happy") {
      formdata.append("reaction", "happy");
    }
    else if (reaction === "ok") {
      formdata.append("reaction", "ok");
    }
    else if (reaction === "sad") {
      formdata.append("reaction", "sad");
      formdata.append("reason", this.state.sadFeedback);
      formdata.append("refund", this.state.needRefund);
    }
    formdata.append("feedback", this.state.roomFeedback);

    reqMsg1.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);

    this.roomFeedbackMessageId = reqMsg1.messageId;

    this.setState({ isFeedbackSending: true });
    runEngine.sendMessage(reqMsg1.id, reqMsg1);
  }

  updateRoomFeedback = () => {
    let reqMsg1 = new Message(getName(MessageEnum.RestAPIRequestMessage));


    reqMsg1.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateRoomFeedback}/${this.state.prevRoomFeedback.id}`);
    reqMsg1.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.updateRoomFeedbackMethod);

    const header = {
      token: localStorage.getItem("token")
    };

    reqMsg1.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    let formdata = new FormData();
    formdata.append("room_id", this.props.roomId);

    const { userChoosenReaction: reaction } = this.state;

    if (reaction === "happy") {
      formdata.append("reaction", "happy");
    }
    else if (reaction === "ok") {
      formdata.append("reaction", "ok");
    }
    else if (reaction === "sad") {
      formdata.append("reaction", "sad");
      formdata.append("reason", this.state.sadFeedback);
      formdata.append("refund", this.state.needRefund);
    }
    formdata.append("feedback", this.state.roomFeedback);

    reqMsg1.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);

    this.roomFeedbackMessageId = reqMsg1.messageId;

    this.setState({ isFeedbackSending: true });
    runEngine.sendMessage(reqMsg1.id, reqMsg1);
  }

  sendParticipantFeedback = () => {
    let room_id = parseInt(this.props.roomId)
    let from_id = parseInt(localStorage.getItem('userId') || "-1")

    const newFeedback: any[] = []
    const updateFeedback: any = {
      room_id,
      data: []
    };

    let feedbackArray;

    if (this.props.feedbackData instanceof Map) {
      feedbackArray = Array.from(this.state.allFeedback?.values());
    } else {
      feedbackArray = this.state.allFeedback;
    }

    feedbackArray.forEach((user: any) => {
      const { hearts, feedback, id } = this.getPastHearts(user.userId);

      if (hearts > 0 || (feedback && feedback.length)) {
        if (user.feedbackInput) {
          updateFeedback.data.push({
            id: id,
            hearts: parseInt(user.heartInput),
            feedback: user.feedbackInput
          })
        } else {
          updateFeedback.data.push({
            id: id,
            hearts: parseInt(user.heartInput),
          })
        }
      } else {
        if (user.feedbackInput) {
          newFeedback.push({
            room_id,
            feedback_to_id: user.userId,
            feedback_from_id: from_id,
            hearts: parseInt(user.heartInput),
            feedback: user.feedbackInput
          });
        } else {
          newFeedback.push({
            room_id,
            feedback_to_id: user.userId,
            feedback_from_id: from_id,
            hearts: parseInt(user.heartInput),
          });
        }
      }
    });

    // call new record insert API
    if (newFeedback.length) {
      let reqMsg2 = new Message(getName(MessageEnum.RestAPIRequestMessage));
      reqMsg2.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostType);
      reqMsg2.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.participantFeedbackEndpoint);
      const header = {
        token: localStorage.getItem("token"),
        "Content-Type": "application/json"
      };
      reqMsg2.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

      const raw = JSON.stringify({
        room_id,
        data: newFeedback
      });

      reqMsg2.addData(getName(MessageEnum.RestAPIRequestBodyMessage), raw);

      this.participantFeedbackMessageId = reqMsg2.messageId;
      runEngine.sendMessage(reqMsg2.id, reqMsg2);
    }

    // call update API
    if (updateFeedback.data.length) {
      let newRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

      newRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.updateUserFeedbackMethod);

      newRequest.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.updateUserFeedbackEndPoint);

      const header = {
        token: localStorage.getItem("token"),
        "Content-Type": "application/json"
      };
      newRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

      newRequest.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(updateFeedback));

      // this.participantFeedbackMessageId = newRequest.messageId;
      runEngine.sendMessage(newRequest.id, newRequest);
    }
  }


  reportUser = () => {
    let reqMsg3 = new Message(getName(MessageEnum.RestAPIRequestMessage));
    reqMsg3.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostType);
    reqMsg3.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.reportUserEndpoint);
    const header = {
      token: localStorage.getItem("token")
    };

    reqMsg3.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    let formdata = new FormData();
    formdata.append("room_id", this.props.roomId);
    formdata.append("user_id", this.state.reportId.toString());
    formdata.append("reason", this.state.reportValue);
    reqMsg3.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);

    this.reportUserMessageId = reqMsg3.messageId;
    runEngine.sendMessage(reqMsg3.id, reqMsg3);
  }

  getTotalHeart = () => {
    let reqMsg4 = new Message(getName(MessageEnum.RestAPIRequestMessage));
    reqMsg4.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetType);
    reqMsg4.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_different_rooms/rooms/${this.props.roomId}/participants`);
    const header = {
      token: localStorage.getItem("token")
    };

    reqMsg4.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    this.totalHeartMessageId = reqMsg4.messageId;
    runEngine.sendMessage(reqMsg4.id, reqMsg4);
  }

  getLastUsersFeedback = () => {
    const token = localStorage.getItem("token");
    if (!token) return;

    const header = {
      token: localStorage.getItem("token")
    };

    const newRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    newRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetType);
    newRequest.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPrevFeedbacks}?room_id=${this.props.roomId}`);

    newRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    this.prevUserFeedbackID = newRequest.messageId;
    runEngine.sendMessage(newRequest.id, newRequest);
  }

  getLastRoomFeedback = () => {
    const token = localStorage.getItem("token");
    if (!token) return;

    const header = {
      token: localStorage.getItem("token")
    };

    const newRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    newRequest.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetType);
    newRequest.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPrevRoomFeedback}?room_id=${this.props.roomId}`);

    newRequest.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    this.prevRoomFeedbackID = newRequest.messageId;
    runEngine.sendMessage(newRequest.id, newRequest);
  }

  handleConnectRequest = (status: string, id: number) => {
    const token = localStorage.getItem("token");

    if (!token) return;

    const payload = {
      "data": {
        status
      }
    }

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("token")
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.acceptRejectFriendConnectionId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sendConnectRequestApiEndPoint}/${id}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  handleblockUnBlockUser = (user_id: number) => {
    const headers = {
      // "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("token")
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.blockUserMessageID = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.blockUserApiEndpoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    const formData = new FormData();
    formData.append("user_id", user_id.toString());

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  handleHeartInput = (id: any, heartInput: any) => {
    let temp = this.state.allFeedback;
    let tempObj = temp.get(id);
    tempObj.heartInput = heartInput;
    temp.set(id, tempObj);
    this.setState({ allFeedback: temp })
  }
  handleFeedbackInput = (id: any, feedbackInput: any) => {
    let temp = this.state.allFeedback;
    let tempObj = temp.get(id);
    tempObj.feedbackInput = feedbackInput;
    temp.set(id, tempObj);
    this.setState({ allFeedback: temp })
  }
  handleRoomFeedback = (f: string) => {
    this.setState({ roomFeedback: f.slice(0, 250) })
  }
  handleSadFeedback = (sf: string) => {
    this.setState({ sadFeedback: sf });
  }

  handleWantRefund = (wr: 'yes' | 'no') => {
    this.setState({ needRefund: wr });
  }

  getPastHearts = (user_id: number) => {
    const userId = parseInt(localStorage.getItem("userId") as string);
    if (!this.state.prevUserFeedback) return { hearts: 0, feedback: 0, id: -1 };

    const data = this.state.prevUserFeedback.filter((each) => each.attributes.feedback_from_id === userId &&
      each.attributes.feedback_to_id === user_id);

    if (data.length) {
      const attributes = data[0].attributes;
      return { hearts: attributes.hearts, feedback: attributes.feedback || 0, id: data[0].id };
    }
    return { hearts: 0, feedback: 0, id: -1 };
  }

  handleClose = () => {
    this.setState({ openFeedBackpopup: false })
  }

  handleRoomEndModalClose = () => {
    const wantSelfLeave = JSON.parse(sessionStorage.getItem('self_leave') as string);

    if (!wantSelfLeave) return;

    if (new Date() < new Date(this.props.endTime)) {
      this.handleClose();
      this.props.handleLeave(false);
    }
  }

  handleDrag = (e: DraggableEvent, data: DraggableData) => {
    if (data.y >= 50) {
      // Change this value to control the minimum distance to close the slider.
      this.handleRoomEndModalClose();
    } else {
      this.setState({ defaultPosition: { x: 0, y: 0 } });
    }
  };

  checkingHearts = () => {
    let feedbackArray;

    if (this.props.feedbackData instanceof Map) {
      feedbackArray = Array.from(this.state.allFeedback?.values());
    } else {
      feedbackArray = this.state.allFeedback;
    }

    let returnValue = true;
    feedbackArray.forEach((f: any) => {
      if (f.heartInput <= 0) {
        returnValue = false;
        return false;
      }
    })
    return returnValue;
  }

  handleRoomEndSubmit = () => {
    if (this.checkingHearts()) {
      this.setState({ openSmileyPopup: true, openFeedBackpopup: false })
      this.sendParticipantFeedback();
    }
    else {
      this.setState({ reportAlert: true, reportAlertMsg: "Please give at least 1 heart to a participant to submit." })
      setTimeout(() => {
        this.setState({ reportAlert: false, reportAlertMsg: "" })
      }, 1500)
    }
  }

  toggleMoreOptions = () => {
    this.setState(prev => ({ isMoreOptionsOpen: !prev.isMoreOptionsOpen }));
  }

  toggleThankYouModal = () => {
    this.setState(prev => ({
      openThankYouFeedbackModal: {
        ...prev.openThankYouFeedbackModal,
        isOpen: !prev.openThankYouFeedbackModal.isOpen
      }
    }));
  }

  toggleBlockModal = () => {
    this.setState(prev => ({ openBlockModal: !prev.openBlockModal }));
  }

  toggleUnBlockModal = () => {
    this.setState(prev => ({ openUnBlockModal: !prev.openUnBlockModal }));
  }

  toggleDisconnectModal = () => {
    this.setState(prev => ({ openDisconnectModal: !prev.openDisconnectModal }))
  }

  handleDisconnect = (id: number) => {
    this.props.handleDisconnectUser(id);
    setTimeout(() => {
      this.toggleDisconnectModal();
    }, 1000);
  }
  // Customizable Area End
}
