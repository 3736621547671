import React, { FC, ReactNode } from 'react';
import { backarrow } from "../../../assets";

interface MobileWrapperProps {
  children: ReactNode;
  label: string;
  navigation?: { goBack: () => void };
  changePage?: () => void;
  handleScroll?: (e: React.UIEvent<HTMLDivElement>) => void;
  handleAccountStatementScroll?: (e: React.UIEvent<HTMLDivElement>) => void;
}

const MobileWrapper: FC<MobileWrapperProps> = ({handleAccountStatementScroll, handleScroll, children, label, navigation, changePage }) => (
  <div>
      <div className="topBar">
            <div onClick={navigation?.goBack ?? changePage} className="walletBackButton">
            <img src={backarrow} width="16px" height="16px" alt="" />{label}
         </div>
      </div>
      <div className="wrapperInside" 
         onScroll={(e) => { 
            if (label === 'Moderator Wallet' && handleAccountStatementScroll) {
               handleAccountStatementScroll(e);
           } else if (label === 'Transaction History' && handleScroll) {
               handleScroll(e);
           }
         }
      }
      >{children}</div>
  </div>
);

export default MobileWrapper;
