// Customizable Area Start
import React, { Fragment } from 'react';
import { Paper, List } from '@material-ui/core';

import OtherMessage from './otherMessage.web';
import YourMessage from './yourMessage.web';
import MessageFooter from './messageFooter.web';
import '../SideBar.css';
import messageTabController from './messageTabController';
import { message } from './messageTabController';
import { Props } from "./messageTabController";

export default class messageTab extends messageTabController {

	constructor(props: Props) {
		super(props);
		this.lastMesgRef = React.createRef<HTMLDivElement>();
	}

	getUserName = (author: string) => {
		const participant = this.props.allRoomParticipants.filter((participant) => participant.user_name === author);
		if (participant.length) {
			return participant[0].is_anonymously ? participant[0].anonymously_name : participant[0].full_name;
		}
		return author;
	}

	render() {
		return (
			<Paper id='message-tab' elevation={0} variant='outlined'>
				<List style={{ overflowY: "scroll" }}>
					{this.state.messageList.map((msg: message, i) => {
						return (
							<Fragment key={i}>
								{msg.author === this.props.localIdentity ? (
									<>
										<YourMessage key={i} message={msg.body} timeStamp={msg.timestamp} />
										<div ref={this.lastMesgRef as React.RefObject<HTMLDivElement>} />
									</>
								) : (
									<>
										<OtherMessage
											key={i}
											username={this.getUserName(msg.author)}
											message={msg.body}
											timeStamp={msg.timestamp}
										/>
										<div ref={this.lastMesgRef as React.RefObject<HTMLDivElement>} />
									</>
								)}
							</Fragment>
						);
					})}

					{/* scroll to here, to view the last chat. */}
					<div ref={this.lastMesgRef as React.RefObject<HTMLDivElement>} />
				</List>
				{this.state.isTyping && (
					<h4 style={{ display: "inherit", marginLeft: '25px', alignSelf: "flex-start" }}>{`${this.getUserName(this.state.typer)} is typing...`}</h4>
				)}
				<MessageFooter conversation={this.state.conversation} />
			</Paper>
		);
	}
}
// Customizable Area End
