// Customizable Area Start
import React, { useState, useEffect } from 'react'
import { Formik } from "formik";
import * as Yup from 'yup';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import { profile_imageIcon } from "./../../assets";
import ClearIcon from '@material-ui/icons/Clear';
import DropDown, { MobileInput } from '../Dropdown.web'
import { FormControl, Grid, CardContent, CircularProgress, TextField, Select, MenuItem, ListItemIcon, ListItemText, Chip, Checkbox } from '@material-ui/core';


interface PropTypes {
   userData: {}
   subcategories: any[]
   checker: boolean
   update: any
   countryData: any[]
   setError: (e: boolean, index: number) => void
   handleAlert: (msg: string, success: boolean) => void
   changeForm: () => void
}

const PersonalInformation = ({ userData, subcategories, checker, update, countryData, changeForm, setError, handleAlert }: PropTypes) => {

   const [isChanged, setChanged] = useState<boolean>(false)
   const [isLoad, setIsLoad] = useState(false)
   const [hasErrorIn, setHasErrorIn] = useState<boolean>(true)
   const [countryCodes, setCountryCodes] = useState({
      name: "India",
      flag: "🇮🇳",
      code: "IN",
      dial_code: "+91"
   })
   const [personalInfoData, setPersonalInfoData] = useState<any>({
      type: 'update_profile',
      profileImageURL: '',
      profile_img: '',
      full_name: '',
      gender: '',
      user_name: '',
      email: '',
      birth_year: '',
      full_phone_number: '',
      preferred_sub_categories: [0]
   })
   const [errorList, setErrorList] = useState([])
   const detector = {
      type: 'update_profile',
      profileImageURL: '',
      profile_img: '',
      full_name: '',
      gender: '',
      user_name: '',
      email: '',
      birth_year: '',
      full_phone_number: '',
      preferred_sub_categories: [0]
   }

   const errorTypes = {
      selectCategory: 'Please Select at least ',
      text: 'Please enter ',
      pleaseSelect: 'Please select '
   }

   const options = [
      // { label: "Placeholder", value: "" },
      { label: "Male", value: "Male" },
      { label: "Female", value: "Female" },
      { label: "Non - Binary", value: "nonBinary" },
      { label: "Do not wish to disclose", value: "notDisclose" }
   ];

   const yearList = (back: number) => {
      const year = new Date().getFullYear() - 18;
      return Array.from({ length: back }, (v, i) => year - i);
   }

   const option1 = yearList(100);

   const NumberAndTextFormatter = (value: any, limit: any) => {
      let formattedText = value.replace(/[^a-zA-Z0-9_ ]/g, "").substr(0, limit)
      return formattedText
   }

   const NumberAndTextFormatterWithoutSpace = (value: any, limit: any) => {
      let formattedText = value.replace(/[^a-zA-Z0-9_]/g, "").substr(0, limit)
      return formattedText
   }

   const TextFormatterWithoutSpecial = (value: any, limit: any) => {
      let formattedText = value.replace(/[^a-zA-Z]/g, "").substr(0, limit)
      return formattedText
   }

   const NumberFormatter = (value: any, limit: any) => {
      let formattedText = value.replace(/[^0-9 ]/g, "").substr(0, limit)
      return formattedText
   }

   const getFlagAndCode = (phone: any) => {
      if (phone?.flag && phone?.dial_code) {
         return phone.flag + " " + phone.dial_code
      }
   }

   const handleChangeState = (e: any) => {
      if (!isChanged) {
         setChanged(true)
         changeForm()
      }
      
      if (e.target.value && e.target.value.length) {
         if (e.target.name === 'full_name') {
            setErrorList(errorList.filter(item => item !== e.target.name))
            setPersonalInfoData({ ...personalInfoData, [e.target.name]: TextFormatterWithoutSpecial(e.target.value, 28) })
         } else if (e.target.name === 'gender') {
            setErrorList(errorList.filter(item => item !== e.target.name))
            setPersonalInfoData({ ...personalInfoData, [e.target.name]: NumberAndTextFormatter(e.target.value, 15) })
         } else if (e.target.name === 'user_name') {
            setErrorList(errorList.filter(item => item !== e.target.name))
            setPersonalInfoData({ ...personalInfoData, [e.target.name]: NumberAndTextFormatterWithoutSpace(e.target.value, 20) })
         } else if (e.target.name === 'birth_year') {
            setErrorList(errorList.filter(item => item !== e.target.name))
            setPersonalInfoData({ ...personalInfoData, [e.target.name]: NumberFormatter(e.target.value, 30) })
         } else if (e.target.name === 'preferred_sub_categories') {
            if (e.target.value.length <= 2) {
               //@ts-ignore
               setErrorList([...errorList, e.target.name])
            } else setErrorList(errorList.filter(item => item !== e.target.name))
            setPersonalInfoData({ ...personalInfoData, [e.target.name]: e.target.value })
         } else if (e.target.name === 'profile_img') {
            if (e.target.files[0]?.type === 'image/jpg' || e.target.files[0]?.type === 'image/jpeg') {
               setPersonalInfoData({ ...personalInfoData, profileImageURL: URL.createObjectURL(e.target.files[0]), profile_img: e.target.files[0] });
            } else {
               handleAlert("Only JPEG/JPG file types are supported", false)
            }
         } else {
            setPersonalInfoData({ ...personalInfoData, [e.target.name]: e.target.value })
         }
      } else {
         setPersonalInfoData({ ...personalInfoData, [e.target.name]: '' })
         //@ts-ignore
         setErrorList([...errorList, e.target.name])
      }
   }

   //sets data to state
   useEffect(() => {
      if (userData && Object.keys(userData).length > 0) {
         let uData: any = userData
         const persoData = {
            type: 'update_profile',
            profileImageURL: uData.profile_image,
            profile_img: '',
            full_name: uData.full_name,
            gender: uData.gender,
            user_name: uData.user_name,
            email: uData.email,
            birth_year: uData.birth_year,
            full_phone_number: uData.phone_number,
            preferred_sub_categories: uData.user_sub_categories.map((item: any) => item.sub_category_id)
         }
         let ct = countryData.find((item) => item.dial_code === `+${uData.country_code}`)
         setCountryCodes(ct)
         setPersonalInfoData(persoData)
      }
   }, [userData])

   // If the data is setted to state, it will render the component
   useEffect(() => {
      if (!isLoad && JSON.stringify(personalInfoData) !== JSON.stringify(detector)) {
         setIsLoad(true)
      }
   }, [personalInfoData])

   // checks is there Error
   useEffect(() => {
      if (errorList.length > 0) {
         if (!hasErrorIn) {
            setError(true, 0)
            setHasErrorIn(true)
         }
      } else {
         if (hasErrorIn && isLoad) {
            setError(false, 0)
            setHasErrorIn(false)
         }
      }
   }, [errorList, isLoad])


   // Update lastest version of data to state
   useEffect(() => {
      if (checker) {
         let copyArr: any = { ...personalInfoData }
         let changer = JSON.stringify(personalInfoData.preferred_sub_categories)
         copyArr.preferred_sub_categories = changer
         update(copyArr)
      }
   }, [checker])

   return (
      <Grid xs={12} container justifyContent="space-around">
         <Grid xs={12} className="PerosnalInformationFormInside">
            {isLoad ?
               <form>
                  <input type="file" accept="image/*" id="profile_img" hidden
                     name="profile_img"
                     onChange={(e) => {
                        //@ts-ignore
                        if (e.target.files[0]?.type.includes('image')) {
                           const objectUrl = e.target.files && URL.createObjectURL(e.target.files[0])
                           handleChangeState(e)
                        } else {
                           // this.handleAlert(`Images are supported only !`)
                        }
                     }
                     }
                  />
                  <div className="AccountCircleIcon"
                     style={{ margin: "0px auto", width: "60px" }}
                     onClick={() => document.getElementById("profile_img")?.click()}
                  >
                     {
                        personalInfoData.profileImageURL
                           ?
                           < img width={68} height={68} src={personalInfoData.profileImageURL} />
                           :
                           < img width={68} height={68} src={profile_imageIcon} />
                     }
                  </div>
                  <p style={{ textAlign: 'center', marginTop: '20px' }}>Edit Profile Photo</p>
                  {/* <AccountCircleIcon className="AccountCircleIcon" /> */}

                  <CardContent className="card-contain">
                     <Grid className="outer-grid" item container spacing={1} justify-content="center">
                        <Grid item xs={12} sm={6} md={6}>
                           <label>Profile Name</label>
                           <TextField
                              placeholder="Maximum character should be 28"
                              variant="outlined"
                              fullWidth
                              name="full_name"
                              value={personalInfoData.full_name ? personalInfoData.full_name : ''}
                              inputProps={{ maxLength: 28 }}
                              onChange={(e) => {
                                 e.target.value = e.target.value?.trimStart()
                                 handleChangeState(e)
                              }
                              }
                           />
                           {errorList.find(item => item === 'full_name') && <span style={{ display: "block", color: "red", fontSize: "12px" }}>{errorTypes.text + 'your profile name'}</span>
                              || <label className="small-label">Change allowed once per month</label>}
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                           <FormControl fullWidth variant="outlined">
                              <label>Gender</label>
                              <DropDown
                                 list={options.map((item) => item?.label)}
                                 value={personalInfoData.gender}
                                 name="gender"
                                 onChange={handleChangeState}
                                 className='MuiOutlinedInput-root'
                                 defaultText="Select gender"
                              />
                           </FormControl>
                           <label className="small-label">Can be changed only once</label>

                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                           <label>User Name</label>
                           <TextField
                              placeholder="Maximum character should be 15"
                              variant="outlined"
                              fullWidth
                              name="user_name"
                              value={personalInfoData.user_name}
                              onChange={(e) => {
                                 handleChangeState(e)
                              }}
                           />
                           {errorList.find(item => item === 'user_name') && <span style={{ display: "block", color: "red", fontSize: "12px" }}>{errorTypes.text + 'a user name'}</span>
                              || <label className="small-label">Numbers,letters and undescores only; Change allowed only twice per year</label>}


                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                           <label>Email</label>
                           <TextField
                              placeholder="Eg. aaron@abc.com"
                              variant="outlined"
                              fullWidth
                              name="email"
                              value={personalInfoData.email}
                              onChange={(e) => {
                                 handleChangeState(e)
                              }}
                           />
                           {errorList.find(item => item === 'email') && <span style={{ display: "block", color: "red", fontSize: "12px" }}>{errorTypes.text + 'an email'}</span>}

                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                           <FormControl fullWidth variant="outlined">
                              <label>Year of Birth</label>
                              <DropDown
                                 disabled={true}
                                 list={option1.map((item) => item.toString())}
                                 value={personalInfoData.birth_year}
                                 name="birth_year"
                                 onChange={handleChangeState}
                                 className='MuiOutlinedInput-root'
                                 defaultText="Select year of birth"
                              />
                           </FormControl>
                           <label className="small-label">You must be 18+. Year cannot be edited</label>



                        </Grid>

                        <Grid item xs={12} sm={6} md={6} className="subAndCountryBox">
                           <FormControl fullWidth variant="outlined">
                              <label>Preferred Subcategories</label>
                              <Select
                                 displayEmpty={true}
                                 labelId="mutiple-select-label"
                                 multiple
                                 className="blur-placeholder"
                                 value={personalInfoData.preferred_sub_categories}
                                 name='preferred_sub_categories'
                                 onChange={handleChangeState}
                                 IconComponent={KeyboardArrowDownRoundedIcon}
                                 // onClose={(e) => {
                                 //    handleBlur({ target: { name: "subCategories" } })
                                 // }}
                                 MenuProps={{
                                    PaperProps: {
                                       style: {
                                          maxHeight: 48 * 4.5 + 8,
                                          width: 250
                                       }
                                    },
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                       vertical: "bottom",
                                       horizontal: "center"
                                    },
                                    transformOrigin: {
                                       vertical: "top",
                                       horizontal: "center"
                                    },
                                    variant: "menu"
                                 }}
                                 inputProps={{ style: { fontSize: 10 } }} // font size of input text
                                 renderValue={() => <div style={{ fontSize: 13 }}>Choose min 3 favourite subcategories</div>}
                              >

                                 {subcategories?.map((item: any) => {
                                    if (item[1] && item[0]) {
                                       let option: any = {
                                          id: item[1],
                                          attributes: {
                                             id: item[1],
                                             name: item[0]
                                          }
                                       }
                                       return (
                                          <MenuItem
                                             key={option.id} value={option.attributes.id}
                                             style={{ whiteSpace: 'break-spaces' }}
                                          >
                                             <ListItemText primary={option.attributes.name} />
                                             <ListItemIcon>
                                                {/* @ts-ignore*/}
                                                <Checkbox checked={personalInfoData.preferred_sub_categories.find(item => item == option.attributes.id) ? true : false} />
                                             </ListItemIcon>
                                          </MenuItem>
                                       )
                                    }
                                 })}
                              </Select>
                              {errorList.find(item => item === 'preferred_sub_categories') && <span style={{ display: "block", color: "red", fontSize: "12px" }}>Please select 3 preferred subcategories</span>}
                              <div className="selected-subcategories-wrapper">
                                 {personalInfoData.preferred_sub_categories?.map((value: any) => {
                                    let finded = subcategories.find(item => item[1] === value)
                                    if (finded) {
                                       let sub: any = {
                                          id: finded[1],
                                          attributes: {
                                             id: finded[1],
                                             name: finded[0]
                                          }
                                       }
                                       return (
                                          <Chip
                                             key={sub.id}
                                             label={sub.attributes.name}
                                             className="selected-chip"
                                             onDelete={
                                                () => {
                                                   setPersonalInfoData({
                                                      ...personalInfoData,
                                                      preferred_sub_categories: personalInfoData.preferred_sub_categories.filter(
                                                         (item: any) => {
                                                            return item != sub.id
                                                         }
                                                      )
                                                   })
                                                   if (personalInfoData.preferred_sub_categories.length <= 2) {
                                                      //@ts-ignore
                                                      setErrorList([...errorList, 'preferred_sub_categories'])
                                                   }
                                                }
                                             }

                                             deleteIcon={<ClearIcon style={{ color: 'white' }} />}
                                          />
                                       )
                                    }
                                 })}
                              </div>

                           </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                           <FormControl fullWidth variant="outlined">
                              <label>Mobile Phone Number</label>
                              <MobileInput
                                 // inputTabIndex={1}
                                 trial={countryData}
                                 list={countryData}
                                 value={getFlagAndCode(countryCodes)}
                                 className="block"
                                 name="full_phone_number"
                                 defaultText="Enter mobile number"
                                 phone={personalInfoData.full_phone_number}
                                 onChange={(e) => {
                                    setCountryCodes(e.target.value)
                                    handleChangeState(e)
                                    sessionStorage.setItem("number", e.target.phoneNumber)
                                 }}
                                 defaultValue={countryCodes}
                              />
                           </FormControl>
                        </Grid>

                     </Grid>
                  </CardContent>
               </form >
               :
               <Grid container xs={12} style={{ height: '300px', justifyContent: 'center' }} alignItems="center">
                  <div>
                     <CircularProgress size={80} thickness={4} style={{ color: '#ffcb00' }} />
                  </div>
               </Grid>
            }
         </Grid >
      </Grid >
   )
}

export default PersonalInformation;
// Customizable Area End
