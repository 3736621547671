// Customizable Area Start
import React, { useState, useEffect } from "react";
import VideoScreen from "./VideoChatScreen/VideoScreen.web";
import SideBar from "./SideBar/sidebar.web";
import "./room.css";
import RoomController, { Props } from "./RoomController";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button, Box, Typography, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Timer } from "./VideoChatScreen/LeaveRoomModal.web";
import CustomAvatarGroupWeb from "../../../DifferentRooms/src/UpcomingRooms/CustomAvatarGroup.web";
import moment from "moment";
import CancelRoomModal from "./VideoChatScreen/CancelRoomModal.web";

const useStyles = makeStyles(() => ({
  avatars: {
    marginTop: "1.5rem",
    marginBottom: "1rem",

    "& > div": {
      justifyContent: "center !important",
    },
  },

  customDialogPaper: {
    margin: 0,
  },
}));

const PermissionRequest = () => {
  const [hasPermission, setHasPermission] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handlePermission = async () => {
      try {
        // request for camera and microphone permission one by one, if user rejects any of them, then we will show the permission request dialog
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
        setHasPermission(true);
        stream.getTracks().forEach((track) => track.stop());
      } catch (error) {
        setOpen(true);
      }
    };

    handlePermission();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleRetry = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      setHasPermission(true);
      setOpen(false);
      stream.getTracks().forEach((track) => track.stop());
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {!hasPermission && (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Permission Request</DialogTitle>
          <DialogContent>
            <DialogContentText>
              We need access to your camera and microphone to proceed.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleRetry} color="primary">
              Retry
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

const NoRoomFound = ({
  history,
  endRoomForAll,
}: {
  history: any;
  endRoomForAll: () => void;
}) => {
  useEffect(() => {
    // call Room end for all API.
    endRoomForAll();
  }, []);

  const redirectToHome = () => {
    history.push("/Rooms");
  };

  return (
    <Box mt={3} textAlign="center">
      <Typography style={{ fontWeight: 600, fontSize: "1.3rem" }}>
        The room is expired due to no activity for the past 10 minutes.
      </Typography>

      <Typography
        style={{ fontWeight: 600, fontSize: "1.2rem", marginTop: "1rem" }}
      >
        You'll be auto-redirected within{" "}
        <Timer sec={10} afterTimeEnd={redirectToHome} /> seconds.
      </Typography>

      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: "1.5rem", background: "#010039" }}
        onClick={redirectToHome}
      >
        Leave Room
      </Button>
    </Box>
  );
};

const RoomDisconnected = ({ history }: { history: any }) => {
  const redirectToHome = () => {
    history.push("/Rooms");
  };

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <Dialog open={true} fullWidth maxWidth="sm" style={{ zIndex: 9999 }}>
      <DialogTitle>Room Disconnected</DialogTitle>
      <DialogContent>
        <Box textAlign="center">
          <Box>
            <Typography>You have been disconnected from the room.</Typography>
            <Typography>
              You can only connect a single device at a time.
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography style={{ fontWeight: 600 }}>
              You'll be auto-redirected within{" "}
              <Timer sec={10} afterTimeEnd={redirectToHome} /> seconds.
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={refreshPage} color="primary" variant="contained">
          Re-Connect Room
        </Button>
        <Button
          onClick={redirectToHome}
          color="primary"
          variant="contained"
          style={{ background: "#ffcb00" }}
        >
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface WProps extends RouteComponentProps {
  participants: any[];
  isMobile: boolean;
  room_details: {
    topic: string;
    start_time: string;
  };
}

const WaitingForHost: React.FC<WProps> = ({
  participants,
  history,
  room_details,
  isMobile,
}) => {
  const avatars = participants.map((each) => ({
    id: each.account_id,
    alt: each.user_name,
    src: each.profile_img,
  }));
  const classes = useStyles();

  const handleLeave = () => {
    history.replace("/Rooms");
  };

  const handleClose = () => {
    const isLeave = window.confirm("Are you sure you want to leave the room?");
    if (isLeave) {
      handleLeave();
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      style={{ backdropFilter: "blur(4px)" }}
      open={true}
      maxWidth="sm"
      classes={{ paper: isMobile ? classes.customDialogPaper : "" }}
    >
      <DialogContent
        style={{
          width: isMobile ? "90vw" : "20rem",
          maxWidth: "none",
          padding: 0,
        }}
      >
        <Box
          sx={{
            pt: 4,
            pb: 4,
            pr: 2,
            pl: 2,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              style={{
                fontWeight: 500,
                color: "#27272A",
                fontSize: "1.2rem",
              }}
            >
              Waiting for Host
            </Typography>
          </Box>

          <Box
            sx={{
              mt: 2,
              border: "1px solid #E0E0E0",
              p: 1,
              borderRadius: 10,
              boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
            }}
          >
            <Typography
              style={{
                color: "#010039",
                fontWeight: 600,
                wordBreak: "break-all",
              }}
            >
              {room_details.topic}
            </Typography>
            <Typography
              style={{
                fontSize: ".8rem",
                color: "gray",
              }}
            >
              on{" "}
              {moment(room_details.start_time).format(
                "Do MMMM YYYY [at] h:mm A"
              )}
            </Typography>
          </Box>

          <Box className={classes.avatars}>
            <CustomAvatarGroupWeb
              avatars={avatars}
              maxVisibleAvatars={avatars.length + 1}
            />
          </Box>

          <Box sx={{ mb: 3.5 }}>
            <Typography
              style={{
                fontWeight: 500,
                color: "#979797",
              }}
            >
              Please wait for the host <br /> to join the meeting
            </Typography>
          </Box>

          <Box sx={{ width: "100%" }}>
            <Button
              style={{
                backgroundColor: "#FF6060",
                color: "#fff",
                width: "100%",
                fontSize: "1rem",
              }}
              onClick={handleLeave}
            >
              Leave
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

class Room extends RoomController {
  constructor(props: Props) {
    super(props);
  }

  getRenderComponent = () => {
    let renderComponent;

    if (this.state.room && this.state.client) {
      renderComponent = (
        <VideoScreen
          {...this.props}
          history={this.props.history}
          room={this.state.room}
          handleStateUplifting={this.handleStateUplifting}
          startTime={this.state.startTime}
          endTime={this.state.endTime}
          allRoomParticipants={this.state.allRoomParticipants}
          roomTopic={this.state.roomTopic}
          myAccountId={this.state.myAccountId}
          myWalletBalance={this.state.myWalletBalance}
          roomPrice={this.state.roomPrice}
          isModerated={this.state.isModerated}
          feedbackData={this.state.feedbackData}
          sendRoomExtensionRequest={this.sendRoomExtensionRequest}
          getRoomDetails={this.getRoomDetails}
          joined_users={this.state.joined_users}
          on_call_users={this.state.on_call_users}
          hostId={this.state.hostId as number}
          isMobile={this.state.isMobile}
          roomDescription={this.state.roomDescription}
          toggleRoomDescription={this.toggleRoomDescription}
          isRoomDescriptionOpen={this.state.isDescriptionOpen}
          createFeedbackUsersArray={this.createFeedbackUsersArray}
          roomImgLink={this.state.roomImgLink}
          room_type={this.state.room_type}
          sendConnectionRequest={this.sendConnectionRequest}
          revertConnectionRequest={this.revertConnectionRequest}
          handleDisconnectUser={this.handleDisconnectUser}
          currentUnixTime={this.state.currentUnixTime}
        />
      );
    } else {
      renderComponent = (
        <CircularProgress
          size={30}
          style={{ color: "#FFCB00", marginTop: "2rem" }}
        />
      );
    }

    return renderComponent;
  };

  render() {
    return (
      <>
        <Snackbar
          open={!this.state.isMobile && this.state.snackbar.open}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          autoHideDuration={5000}
          onClose={this.closeSnackbar}
        >
          <Alert severity={this.state.snackbar.type as any} variant="filled">
            {this.state.snackbar.message}
          </Alert>
        </Snackbar>

        {/* alert for mobile view */}
        {this.state.isMobile && this.state.snackbar.open && (
          <div className="mobile-snackbar-styles">
            {this.state.snackbar.message}
          </div>
        )}

        <div
          id="room"
          style={{
            height: !this.state.room
              ? "calc(100dvh - 80px)"
              : this.state.isMobile
              ? "auto"
              : "calc(100dvh - 80px)",
          }}
        >
          {this.state.roomDisconnected && (
            <RoomDisconnected history={this.props.history} />
          )}

          {this.state.errorAlert ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              height="90vh"
            >
              {(this.state.customErrorAlert !==
                "Only host can initiate a video call" ||
                (this.state.customErrorAlert === "Room not found" ||
                  this.state.customErrorAlert === "Room time has expired")) && (
                <Typography
                  id="room-error-msg"
                  style={{ fontWeight: 600, fontSize: "1.5rem" }}
                >
                  {this.state.customErrorAlert ||
                    "Something went wrong, Please try again"}
                  .
                </Typography>
              )}

              {(this.state.customErrorAlert === "Room not found" ||
                this.state.customErrorAlert === "Room time has expired") && (
                <>
                  <Typography
                    id="room-error-msg"
                    style={{ fontWeight: 600, fontSize: "1.5rem" }}
                  >
                    The Room has ended.
                  </Typography>

                  <NoRoomFound
                    history={this.props.history}
                    endRoomForAll={this.endRoomForAll}
                  />
                </>
              )}

              {this.state.customErrorAlert ===
                "Only host can initiate a video call" && (
                <WaitingForHost
                  participants={this.state.allRoomParticipants}
                  room_details={{
                    start_time: this.state.startTime,
                    topic: this.state.roomTopic,
                  }}
                  isMobile={this.state.isMobile}
                  {...this.props}
                />
              )}

              {this.state.customErrorAlert === "Room is cancelled" && (
                <CancelRoomModal
                  isOpen={true}
                  isMobile={this.state.isMobile}
                  details={{
                    title: "It seems that the room is cancelled",
                    description:
                      "Sorry, looks like the host couldn't make it. You may leave with a full refund.",
                  }}
                />
              )}

              {/* ask the user for permission if user didn't allow */}
              <PermissionRequest />
            </Box>
          ) : (
            this.getRenderComponent()
          )}
          {this.state.errorAlert
            ? null
            : this.state.localIdentity && (
                <SideBar
                  client={this.state.client}
                  isConnected={this.state.isConnected}
                  remoteIdentityList={this.state.remoteIdentityList}
                  localIdentity={this.state.localIdentity}
                  allRoomParticipants={this.state.allRoomParticipants}
                  conversationSID={this.state.conversationSID}
                  feedbackData={this.state.feedbackData}
                  handleFeedbackData={this.handleFeedbackData}
                  sendConnectionRequest={this.sendConnectionRequest}
                />
              )}
        </div>
      </>
    );
  }
}
// @ts-ignore
export default withRouter(Room);

// Customizable Area End
