import React from 'react'
import {
  Hidden,
  Typography,
  Box
} from "@material-ui/core";
import { checkDifference } from "../../MyRooms/RoomsController";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom";
import { watch } from "../../assets";
import { ParticipantCard } from './RoomDetailsSkeletons';


const UpcomingRoomsComponent = (props:any) => {

  const isTimeArrived = (dt1: string) => {
    const date1 = new Date(dt1);
    const date2 = new Date();
  
    if (date2.getTime() > date1.getTime()) {
      return true;
    }
  
    const diffInSeconds: number = Math.floor(
      Math.abs((date2.getTime() - date1.getTime()) / 1000)
    );
  
    // 300 seconds = 5 min.
    return diffInSeconds <= 300;
  };

  const isTargetDateLarger = (room_details: any) => {
    const currentDate = new Date();
    const targetDate = new Date(room_details?.attributes?.start_time);

    return targetDate > currentDate;
  };

  const navigateToRoom = () => {
    const roomDetails = props.state.roomDetails;

    props.history.push(`/adjust-settings/${roomDetails.id}`, {
      state: {
        is_host: roomDetails?.attributes?.is_host,
        roomSID: roomDetails?.attributes?.sids?.room_sid,
        conversationSID: roomDetails?.attributes?.sids?.conversation_sid,
      },
    });
  };

  const showEnterBtn = () => {
    const { roomDetails } = props.state;

    return (
      <strong
        className="fs20 fw600 colorBlue pointer"
        onClick={() => {
          const joined_users = roomDetails?.attributes?.joined_users;
          const user_id = localStorage.getItem("userId");

          if (joined_users && joined_users.includes(user_id)) {
            navigateToRoom();
            return;
          }

          const diff = checkDifference(
            new Date(roomDetails?.attributes.start_time),
            new Date()
          );
          if (diff < 0 && Math.abs(diff) >= 15) {
            props.toggleTimePassedModal();
            return;
          }
          props.handleRulesOpen(); 
        }}
      >
        ENTER
      </strong>
    );
  };

  const showCancelBtn = (roomDetail: any,isHost?:boolean) => {
    return (
      <>
        <Hidden xsDown>
          <Typography
            style={{
              color: "#7f8e9d",
              flex: 1,
              textAlign: "center",
              fontSize: "14px",
              padding: "5px",
              width: "100%",
            }}
            variant="body1"
          >
            You can ENTER 5 min before the start time.
          </Typography>
        </Hidden>
        <strong
          className="fs20 fw600 colorBlue pointer"
          onClick={() => {
            if (isHost) {
              props.stateSetter({ myRoomTabId: roomDetail.id }).then(() => {
                props.CancelModalOpen() 
              })
            } else {
              props.stateSetter({ myRoomTabId: roomDetail.id }).then(() => {
                props.RemoveModalOpen()
              })
            }
          }}
            // props.CancelModalOpen() 
            // props.stateSetter().then(() => {
            //   roomDetail?.attributes?.is_host
            //   ? props.CancelModalOpen() 
            //   : props.RemoveModalOpen();
            // })
            // // this.setState({ myRoomTabId: roomDetail.id }, () => {
            // //   roomDetail?.attributes?.is_host
            // //     ? props.CancelModalOpen() 
            // //     : props.RemoveModalOpen();
            // // })
            // :
            // (roomDetail?.attributes?.is_host ? props.CancelModalOpen() : props.RemoveModalOpen())
          // }}
        >
          CANCEL
        </strong>
      </>
    );
  };

  const renderRegisterOrCancelOrFullBtn = (roomDetail: any) => {
    if (
      !roomDetail?.attributes?.is_registered &&
      !roomDetail?.attributes?.is_host &&
      roomDetail?.attributes?.remaining_seats > 0
    ) {
      return (
        <span
          onClick={() => {
            props.stateSetter({ roomId: roomDetail?.id });
            props.stateSetter({ registerModal: true });
            props.stateSetter((prev:any) => ({
              congratulationModal: {
                ...prev.congratulationModal,
                dateTime: roomDetail?.attributes?.start_time,
                room_name: roomDetail?.attributes?.sub_category_name,
              },
            }));
            // PARENT FUNCTION
          }}
        >
          REGISTER
        </span>
      );
    } else if (roomDetail?.attributes?.remaining_seats > 0) {
      return (
        <strong
          className="fs20 fw600 colorBlue pointer"
          onClick={() => {
            roomDetail?.attributes?.is_host? 
            props.CancelModalOpen() : 
            props.RemoveModalOpen(); // PARENT
          }}
        >
          CANCEL
        </strong>
      );
    } 
    else {
      return "FULL";
    }
  };

  const upcomingRoomsButton = (roomDetail: any) => {
    const isTimeComes = isTimeArrived(roomDetail?.attributes?.start_time);

    const hasTime = isTargetDateLarger(roomDetail);
    const isAnyOneRegistred = roomDetail?.attributes?.is_registered
    const amIHost = roomDetail?.attributes?.is_host

    if (hasTime) {
      if (isAnyOneRegistred) {
        if (isTimeComes) {
          return showEnterBtn();
        } else {
          if (amIHost) {
            return showCancelBtn(roomDetail, true)
          } else {
            return showCancelBtn(roomDetail)
          }
        }
      } else {
          return renderRegisterOrCancelOrFullBtn(roomDetail);
        // }
      }
    } else {
      return <strong className="fs20 fw600 colorBlue pointer">PASSED</strong>;
    }
  };

  const upcomingRoomsSkeleton = () => {
    const { roomDetails, upComingRooms, walletYCoins } = props.state;

    return (
      <>
        {!props.isRoomsLoading && upComingRooms?.map((elem: any, indx: number) => {
            let upcomingRoomsData = upComingRooms;
            let url = "RoomDetailsWeb";
            let UpcomingNewDateFormat = new Date(elem?.attributes?.start_time)
              .toLocaleString(navigator.language, {
                hour: "2-digit",
                minute: "2-digit",
                month: "2-digit",
                year: "numeric",
                day: "2-digit",
                hour12: true,
              })
              .replace(/,/g, " ");
            let today = new Date();
            if (elem.id !== roomDetails.id && new Date(roomDetails?.attributes?.start_time) > today) {
              const ppLink =
                elem?.attributes?.host.data?.attributes.profile_image;
              const ppLinkUser1 =
                elem?.attributes?.participants?.data[0]?.attributes?.participant
                  ?.data?.attributes?.profile_image;
              const ppLinkUser2 =
                elem?.attributes?.participants?.data[1]?.attributes?.participant
                  ?.data?.attributes?.profile_image;
              const defaultPP =
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdDXECBv76wa78obNrJNqayP3o7cy4RaZNg_l_YuhSzP6qoWuHr6BTtn8JgNuHFVmSaf4&usqp=CAU";
              return (
                <section
                  className="room-details shadow bgWhite daf upcoming-manage mb20"
                  key={elem?.id || indx}
                >
                  <section className="wd100 customFlex">
                    <Link
                      to={{
                        pathname: `/RoomDetailsWeb/${elem?.id}`,
                        state: { url, upcomingRoomsData, walletYCoins },
                      }}
                    >
                      <h3 className="fs19 fw600 colorBlue mb0 mt0">
                        {elem?.attributes?.topic_name}
                      </h3>
                    </Link>
                    <div>
                      {/* <img src={share} alt="" className="w28" /> */}
                    </div>
                  </section>
                  <section className="UpcomingCustomFlex">
                    <section>
                      <p
                        className="fs12 colorGrey fw500 mb10 mt0 wd80"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {elem?.attributes?.sub_category_name}
                      </p>
                      <div className="d-flex">
                        <img
                          src={ppLink?.length > 1 ? ppLink : defaultPP}
                          alt=""
                          className="radius100 w32 mr10 cover"
                        />
                        <div>
                          {ppLinkUser1 !== undefined && (
                            <img
                              src={
                                ppLinkUser1?.length > 1
                                  ? ppLinkUser1
                                  : defaultPP
                              }
                              alt=""
                              className="radius100 w32 mr10 cover"
                            />
                          )}
                          {ppLinkUser2 !== undefined && (
                            <img
                              src={
                                ppLinkUser2?.length > 1
                                  ? ppLinkUser2
                                  : defaultPP
                              }
                              alt=""
                              className="radius100 w32 cover upcom-img"
                            />
                          )}
                        </div>
                      </div>
                    </section>
                    <section className="standard-details">
                      <div className="daf">
                        <div className="mr15">
                          <p className="roomTypeName fs12 colorGrey fw500 mt0 mb5">
                            {elem?.attributes?.room_type_name}
                          </p>
                        </div>
                      </div>
                    </section>
                  </section>
                  <section className="UpcomingCustomBottomFlex">
                    <div className="fs13 colorBlue fw500 mt0 dFlexAlignCenter">
                      <img src={watch} alt="" className="w18 mr5" />
                      <span style={{width: 'max-content'}}>{UpcomingNewDateFormat}</span>
                    </div>
                    <h3
                      className="fs18 colorBlue fw600"
                      style={{
                        paddingRight: "20px",
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      {upcomingRoomsButton(elem)}
                    </h3>
                  </section>
                </section>
              );
            }
          })}
        {!props.isRoomsLoading &&
          (upComingRooms?.length === 0 ||
            (upComingRooms?.length === 1 &&
              upComingRooms[0]?.id === roomDetails.id)) && (
            <p className="fs15 fw600 colorBlue mb0 mt0 mb15">No Rooms Found</p>
          )}

        {props.isRoomsLoading && (
          <ParticipantCard />
          // <Box
          //   mt={5}
          //   mb={10}
          //   display="flex"
          //   alignItems="center"
          //   justifyContent="center"
          // >
          //   <ClipLoader color="#ffcb00" size={30} />
          // </Box>
        )}
      </>
    );
  };

  return (
    <>
      {upcomingRoomsSkeleton()}
    </>
  )
}

export default UpcomingRoomsComponent;