import React from "react";
import "./styles.css";
import { saveMyProgressImage } from '../assets'

const SaveMyProgres = (props: any) => {

   const handleClickOutside = () => {
      props.closeModal(false)
   }


   return (
      <div>
         <div className="modalContainer" onClick={(e) => { handleClickOutside() }}>
            <div className="modalContent" onClick={(e) => { e.stopPropagation() }}>
               <div className="modalImage">
                  <img src={saveMyProgressImage} alt="modal image" />
                  <div className="modalImageText">
                     <span>We have saved your progress.</span>
                     You can come back and complete this at your conveniece by clicking on “become a Moderator” button in the “Personal Info” section on your Profile page.
                     <span>We look forward to welcoming you as a Moderator soon.</span>
                  </div>
               </div>

               <div className="modalButtons">
                  <div className="modalSaveButton1" onClick={() => { handleClickOutside() }}>Moderator Orientation Module</div>
                  <div className="modalSaveButton2" onClick={() => { props.navigation.navigate('HomePage') }}>Go to Homepage </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default SaveMyProgres;
