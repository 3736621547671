// Customizable Area Start
import React from 'react';
import { Button } from "@material-ui/core/";
import LandingPageController from '../LandingPageController';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Typography, Box } from '@material-ui/core';

interface Props extends RouteComponentProps {}

class Home extends LandingPageController {

  async componentDidMount() {
    this.props.history.push('/Rooms');
  }

  render() {

    return (
      <Box
        display="flex"
        justifyContent="center"
        alignContent="center"
        height="100vh"
        flexDirection="column"
        alignItems="center"
      >
        <Typography style={{ fontWeight: 600, fontSize: 30 }}>Home Page</Typography>

        <Button
          style={{ minWidth: "max-content", width: 250 }}
          className="contained-btn margin-spacing-right"
          variant="contained"
          onClick={() => {
            localStorage.getItem("token") ? this.props.history.push('Rooms') :
              this.props.history.push('registration')
          }}
        >
          Enter Ylanes
        </Button>
      </Box>
    )
  }
}

// @ts-ignore
export default withRouter(Home);






 // Customizable Area End