// Customizable Area Start
import React, { useState } from 'react';
import Pagination, { PaginationProps } from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { IconButton } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

interface CustomPaginationProps extends PaginationProps {
    count: number;
    page: number;
    onChange: (event: React.ChangeEvent<unknown>, value: number) => void;
}

function CustomPagination(props: CustomPaginationProps) {
    const { count, shape, page, onChange } = props;

    const handlePrevButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChange(event, page + 1);
    };

    const isDisabled = (type: string) => {
        const isPrevButton = type === 'previous';
        const isNextButton = type === 'next';

        const disabled = (isPrevButton && page === 1) || (isNextButton && page === count);
        return { isPrevButton, isNextButton, disabled };
    }

    const renderItem = (item: any) => {
        const { isPrevButton, isNextButton, disabled } = isDisabled(item.type);

        if (isPrevButton) {
            return (
                <PaginationItem
                    {...item}
                    disabled={disabled}
                    style={{ opacity: disabled ? 0 : 'inherit' }}
                    onClick={disabled ? undefined : handlePrevButtonClick}
                    icon={<IconButton disabled={disabled}><KeyboardArrowLeft /></IconButton>}
                />
            );
        } else if (isNextButton) {
            return (
                <PaginationItem
                    {...item}
                    style={{ opacity: disabled ? 0 : 'inherit' }}
                    disabled={disabled}
                    onClick={disabled ? undefined : handleNextButtonClick}
                    icon={<IconButton disabled={disabled}><KeyboardArrowRight /></IconButton>}
                />
            );
        } else {
            return (
                <PaginationItem
                    {...item}
                    disabled={disabled}
                    onClick={disabled ? undefined : item.onClick}
                    style={page === item.page ? { backgroundColor: '#FFCB00' } : undefined}
                />
            );
        }
    };

    return (
        <Pagination
            count={count}
            shape={shape}
            page={page}
            onChange={onChange}
            renderItem={renderItem}
        />
    );
}

interface CustomPaginationUseProps {
    totalPage: number;
    isFetching: boolean;
    currentPage: number;
    handlePageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
}

// Example usage
export function CustomPaginationUse(props: CustomPaginationUseProps) {
    const { totalPage, isFetching, handlePageChange, currentPage } = props;

    return (
        <CustomPagination
            count={totalPage}
            shape="rounded"
            page={currentPage}
            onChange={handlePageChange}
        />
    );
}

export default CustomPagination;
// Customizable Area End