// Customizable Area Start
import React from "react";
import { Grid, Container, Box, withWidth } from "@material-ui/core/";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import ScrollHandler from "../ScrollHandler";
import Sidebar, { Bottombar } from "./Sidebar";
import Step1 from "./Step1";
import Step2, { quizType } from "./Step2";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { bg } from "../assets";

import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import OnboardingDetails from "../OnboardingDetails/OnboardingDetails.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFCB00",
    },
    secondary: {
      main: "#010039",
    },
  },
});

type Props = {
  navigation: {
    navigate: (to: any, params: any) => void;
    getParam: (param: any, alternative: any) => any;
    goBack: () => void;
    redirect: (to: any, param: any) => void;
    getValues: (param: any) => any;
  };
  width: Breakpoint;
};

type State = {
  step: number;
  expandedItems: number[];
  isStep1Completed: boolean;
  isStep2Completed: boolean;
  isOrientationCompleted: boolean;
  criteria: { screen: number; title: string; description: string }[];
  moderatorOrientation: {
    screen: number;
    title: string;
    description: string;
  }[];
} & quizType;

type SS = {};

class index extends BlockComponent<Props, State, SS> {
  fetchCriteriaCallId = "";
  fetchModeratorOrientationCallId = "";
  fetchQuestionsCallId = "";
  submitAnswerCallId = "";

  state: State = {
    step: 0,
    expandedItems: [],
    isStep1Completed: false,
    isStep2Completed: false,
    isOrientationCompleted: false,
    criteria: [],
    moderatorOrientation: [],
    counter: 0,
    quiz: {
      isComplateModal: true,
      currentQuestion: 0,
      selectedAnswers: [[]],
      answeredQuestions: [],
      answered: [],
      completed: false,
      questions: [],
      answerKeys: [],
      answers: [],
      isLoading: false,
    },
  };

  incrementCounter = () => {
    this.setState((prevState) => ({
      counter: prevState.counter + 1,
    }));
  };

  markStep1AsComplete = () => {
    this.setState({
      isStep1Completed: true,
      step: this.state.step + 1,
    });
  };

  markStep2AsComplete = () => {
    this.setState({
      isStep2Completed: true,
      step: this.state.step + 1,
    });
  };

  markOrientationAs = (completed: boolean) => {
    this.setState({
      isOrientationCompleted: completed,
    });
  };

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.fetchCriteriaCallId) {
          this.setState({
            criteria: responseJson.data.map(
              (item: { attributes: {}; id: number; type: string }) =>
                item.attributes
            ),
          });
        } else if (apiRequestCallId === this.fetchModeratorOrientationCallId) {
          this.setState({
            moderatorOrientation: responseJson.data.map(
              (item: { attributes: {}; id: number; type: string }) =>
                item.attributes
            ),
          });
        } else if (apiRequestCallId === this.fetchQuestionsCallId) {
          this.setState({
            quiz: {
              ...this.state.quiz,
              questions: responseJson.data.map(
                (item: { attributes: {}; id: number; type: string }) =>
                  item.attributes
              ),
            },
          });
        } else if (apiRequestCallId === this.submitAnswerCallId) {
          this.setState({
            quiz: {
              ...this.state.quiz,
              isLoading: false,
              answerKeys: [
                ...this.state.quiz.answerKeys,
                responseJson.data.attributes.answer_key,
              ],
              answers: [
                ...this.state.quiz.answers,
                responseJson.data.attributes.answers,
              ],
            },
          });
        }
      }
    }
  };

  fetchCriteria = () => {
    const headers = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchCriteriaCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_moderator_orientation/criteria?screen_type=criteria"
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  fetchModeratorOrientation = () => {
    const headers = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchModeratorOrientationCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_moderator_orientation/criteria?screen_type=moderator_orientation"
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  fetchQuestions = () => {
    const headers = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchQuestionsCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/onboarding_questions/get_moderator_quizes"
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  submitAnswer = () => {
    const { currentQuestion } = this.state.quiz;
    const currentQuestionId = this.state.quiz.questions[currentQuestion].id;
    const userId = localStorage.getItem("userId");
    let answers = "";
    this.state.quiz.selectedAnswers[currentQuestion].forEach(
      (answer) => (answers = `${answers}&answer[answer_ids][]=${answer}`)
    );

    const headers = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.submitAnswerCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/onboarding_questions/create_quiz_answer?answer[onboarding_question_id]=${currentQuestionId}&answer[account_id]=${userId}${answers}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  handleSelect = (is_multiple_answer: boolean, answer: number) => {
    const { currentQuestion, selectedAnswers } = this.state.quiz;
    const indexOfAnswer = selectedAnswers[currentQuestion].indexOf(answer);

    if (indexOfAnswer > -1)
      selectedAnswers[currentQuestion] = selectedAnswers[
        currentQuestion
      ].filter((selectedAnswer) => selectedAnswer !== answer);
    else
      selectedAnswers[currentQuestion] = is_multiple_answer
        ? [...this.state.quiz.selectedAnswers[currentQuestion], answer]
        : [answer];

    this.setState({
      quiz: {
        ...this.state.quiz,
        selectedAnswers,
      },
    });
  };

  handleSubmit = () => {
    let correct = true;
    const { currentQuestion, selectedAnswers } = this.state.quiz;
    const currentAnswerArr = this.state.quiz.questions[currentQuestion].answers;
    const correctAnswerArr = currentAnswerArr.filter(
      (currentAnswer) => currentAnswer[2]
    );

    if (correctAnswerArr.length !== selectedAnswers[currentQuestion].length)
      correct = false;

    correctAnswerArr.forEach((correctAnswer) => {
      if (!selectedAnswers[currentQuestion].includes(correctAnswer[0]))
        correct = false;
    });

    this.submitAnswer();
    this.setState({
      quiz: {
        ...this.state.quiz,
        answeredQuestions: [
          ...this.state.quiz.answeredQuestions,
          { questionNo: currentQuestion, correct },
        ],
        answered: [...this.state.quiz.answered, true],
        isLoading: true,
      },
    });
  };

  handleComplete = () => {
    this.setState({
      quiz: {
        ...this.state.quiz,
        completed: true,
      },
    });
  };

  handleNext = () => {
    this.setState({
      quiz: {
        ...this.state.quiz,
        currentQuestion: this.state.quiz.currentQuestion + 1,
        selectedAnswers: [...this.state.quiz.selectedAnswers, []],
      },
    });
  };

  resetQuiz = () => {
    this.setState({
      quiz: {
        ...this.state.quiz,
        currentQuestion: 0,
        selectedAnswers: [[]],
        answeredQuestions: [],
        answered: [],
        completed: false,
        answerKeys: [],
        answers: [],
      },
    });
  };

  setCurrentQuestion = (questionNumber: number) => {
    this.setState({
      quiz: {
        ...this.state.quiz,
        currentQuestion: questionNumber,
      },
    });
  };

  complateModal = (condition: boolean) => {
    this.setState({
      quiz: {
        ...this.state.quiz,
        isComplateModal: condition,
      },
    });
  };

  toggleExpandedItem = (item: number, action: "ADD" | "REMOVE") => {
    const expandedItems = this.state.expandedItems;
    const isPresent = expandedItems.indexOf(item);
    if (action === "ADD" && isPresent === -1) expandedItems.push(item);
    else if (action === "REMOVE" && isPresent > -1)
      expandedItems.splice(isPresent, 1);
    this.setState({ expandedItems });
  };

  step1Redirect = () => {
    this.setState({ step: 0 });
  };

  async componentDidMount() {
    localStorage.setItem("edit-profile-refreshed", JSON.stringify(false));
    const refreshed = JSON.parse(
      localStorage.getItem("moderator-refreshed") as string
    );
    if (!refreshed) {
      localStorage.setItem("moderator-refreshed", JSON.stringify(true));
      window.location.reload();
    }

    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
    ]);

    if (this.props.navigation.getParam("step", 0) === "2") {
      this.setState({
        step: 2,
      });
    }

    this.fetchCriteria();
    this.fetchModeratorOrientation();
    this.fetchQuestions();
  }

  render() {
    const {
      step,
      expandedItems,
      isStep1Completed,
      isOrientationCompleted,
      criteria,
      moderatorOrientation,
      counter,
      quiz,
    } = this.state;

    const { width } = this.props;

    return (
      <>
        <ThemeProvider theme={theme}>
          <ScrollHandler>
            <Box
              style={{
                background: `url(${bg})`,
                minHeight: "100%",
              }}
            >
              <Bottombar
                step={step}
                toggleExpandedItem={this.toggleExpandedItem}
                counter={counter}
                isOrientationCompleted={isOrientationCompleted}
                markOrientationAs={this.markOrientationAs}
                setStep={(step) =>
                  this.setState({
                    step,
                  })
                }
                setCounter={(counter) =>
                  this.setState({
                    counter,
                  })
                }
              />
              <Container
                style={{
                  paddingTop: "104px",
                  paddingBottom: width === "xs" ? "104px" : 0,
                }}
              >
                <Grid container spacing={3} style={{ margin: 0 }}>
                  <Grid item xs={12} md={3}>
                    <Sidebar
                      step={step}
                      toggleExpandedItem={this.toggleExpandedItem}
                      counter={counter}
                      isOrientationCompleted={isOrientationCompleted}
                      markOrientationAs={this.markOrientationAs}
                      setStep={(step) =>
                        this.setState({
                          step,
                        })
                      }
                      setCounter={(counter) =>
                        this.setState({
                          counter,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={9} lg={8}>
                    <Box
                      id="parent"
                      style={{
                        height: "847px",
                        overflow: "hidden auto",
                        paddingRight: "16px",
                      }}
                    >
                      {step === 0 && (
                        <Step1
                          isStep1Completed={isStep1Completed}
                          criteria={criteria}
                          markStep1AsComplete={this.markStep1AsComplete}
                          expandedItems={expandedItems}
                          toggleExpandedItem={this.toggleExpandedItem}
                        />
                      )}
                      {step === 1 && (
                        <Step2
                          counter={counter}
                          quiz={quiz}
                          complateModal={this.complateModal}
                          markStep2AsComplete={this.markStep2AsComplete}
                          incrementCounter={this.incrementCounter}
                          moderatorOrientation={moderatorOrientation}
                          isOrientationCompleted={isOrientationCompleted}
                          markOrientationAs={this.markOrientationAs}
                          submitAnswer={this.submitAnswer}
                          handleSelect={this.handleSelect}
                          handleSubmit={this.handleSubmit}
                          handleComplete={this.handleComplete}
                          handleNext={this.handleNext}
                          resetQuiz={this.resetQuiz}
                          setCurrentQuestion={this.setCurrentQuestion}
                          step1Redirect={this.step1Redirect}
                        />
                      )}
                      <div style={{ display: step === 2 ? "block" : "none" }}>
                        <OnboardingDetails
                          navigation={this.props.navigation}
                          step1Redirect={this.step1Redirect}
                          id={""}
                        />
                      </div>
                      {/* {step === 2 &&
                      } */}
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </ScrollHandler>
        </ThemeProvider>
      </>
    );
  }
}

export default withWidth()(index);
// Customizable Area End
