// Customizable Area Start
import React from 'react';
import { Box, Button, Typography, Container, Grid } from '@material-ui/core';
import { CrossIcon, bgg } from './assets';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Header from '../../Settings5/src/settings/header.web';
const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
      contrastText: '#fff',
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: '20px 0px',
    },
  },
});

import OnboardingguideController, {
  Props as P,
  configJSON,
} from './OnboardingguideController';

type Props = {
  handleOnboardingDialogClose: () => void;
} & P;

export default class ConfirmModerator extends OnboardingguideController<Props> {
  constructor(props: Props) {
    super(props);
  }
  async componentDidMount(): Promise<void> {
    this.getModeratorInfo();
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Grid>
          <div style={{ position: 'relative' }}>
            <img
              src={bgg}
              style={{
                position: 'absolute',
                width: '35%',
                top: '-78px',
                left: '0px'
              }}
            />
            <img
              src={bgg}
              style={{
                position: 'absolute',
                width: '35%',
                right: '0px',
                bottom: '-18%',
              }}
            />

            <Container
              maxWidth='sm'
              style={{
                textAlign: 'center',
                position: 'relative'
              }}
            >
              <Typography
                variant="h6"
                style={{
                  marginTop: '25px',
                  fontWeight: 600,
                  position: 'relative',
                  textAlign: 'center',
                  color: '#27272A',
                  fontSize: '30px',
                }}
              >
                {' '}
                Terms &#38; Condition
              </Typography>
              <p
                style={{
                  textAlign: 'left',
                  fontSize: '14px',
                  color: '#A2ADB8',
                  margin: 'revert',
                }}
              >
                {' '}
                {this.state.moderatorInfoList4?.description}{' '}
              </p>
              <p
                style={{
                  fontSize: '14px',
                  textAlign: 'left',
                  color: '#A2ADB8',
                  margin: 'revert',
                }}
              >
                {' '}
                {this.state.moderatorInfoList5?.description}{' '}
              </p>
              <p
                style={{
                  fontSize: '14px',
                  textAlign: 'left',
                  color: '#A2ADB8',
                  margin: 'revert',
                }}
              >
                {' '}
                {this.state.moderatorInfoList6?.description}
              </p>
              <p
                // onClick={(e) => {
                //     this.props.navigation.navigate('Setting');
                //     sessionStorage.setItem("t&cshow", "Terms and conditions")
                // }}
                style={{
                  fontSize: '14px',
                  textAlign: 'left',
                  color: '#A2ADB8',
                  margin: 'revert',
                }}
              >
                {' '}
                To go through our Moderator Terms and Condition in details
                please{' '}
                <a
                  style={{
                    textDecoration: 'none',
                    color: '#45446E',
                    fontWeight: 600,
                    display: 'inline',
                  }}
                  href={`${location.origin}/ModeratorTerms`}
                  target="_blank"
                >
                  click Here
                </a>{' '}
              </p>
              <Button
                variant="contained"
                style={Styles.myButton}
                onClick={() =>
                  this.props.navigation.navigate('ModuleOrientation', {
                    step: 2,
                  })
                }
              >
                I understand
              </Button>
            </Container>
          </div>
        </Grid>
      </ThemeProvider>
    );
  }
}

const Styles = {
  container: {
    width: '800px',
    marginTop: '40px',
    borderRadius: '20px',
    marginLeft: '50%',
    transform: 'translateX(-50%)',
    display: 'grid',
    height: '90%',
    alignContent: 'center',
  },
  myButton: {
    backgroundColor: '#010039',
    color: 'white',
    width: '75%',
    borderRadius: '8px',
    padding: '15px',
    marginTop: '20px',
  },
};
// Customizable Area End
