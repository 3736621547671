import React, {useState, useEffect} from 'react'
import './EditTake.scss'
import {useResourceMapWrapper, switchInput, warningBox, textAreaBox, basicTextbox} from './CustomFormHooks'

const EditTake = ({roomDetails, hostDetails, userDetails, setIsEdit, updateNewResource}: any) => {
   const [takeText, setTakeText] = useState<string>("");
   const [resources, setResources] = useState<any[]>([])
   const [entryType, setEntryType] = useState<string>("Public Entry")
   const [roomType, setRoomType] = useState<string>("Local Room")
   const [anonName, setAnonName] = useState<string>('')

   useEffect(() => {
      if (hostDetails?.host_details?.your_take) {
         setTakeText(hostDetails.host_details.your_take)
      } 
      if (hostDetails?.host_details?.host_resources) {
         setResources(hostDetails.host_details?.host_resources)
      }
      if (hostDetails?.host_details?.is_anonymously) {
         setEntryType("Anonymous Entry")
      }
      if (hostDetails?.host_details?.anonymously_name) {
         setAnonName(hostDetails?.host_details?.anonymously_name)
      }
      if (roomDetails?.attributes?.is_global) {
         setRoomType("Global Room")
      }
      if (userDetails?.attributes?.anonymously_name) {
         setAnonName(userDetails?.attributes?.anonymously_name)
      }
      if (userDetails?.attributes?.your_take) {
         setTakeText(userDetails?.attributes?.your_take)
      }
      if (userDetails?.attributes?.resources_and_likes && userDetails?.attributes?.resources_and_likes[0]?.url) {
         setResources(userDetails?.attributes?.resources_and_likes)
      }
      if (userDetails?.attributes?.is_anonymously) {
         setEntryType("Anonymous Entry")
      }
   }, [])


   const submitTake = () => {
      let room_resources_attributes:any[] = []
      resources.map((item:any, index:number) => {
         let data: {id?: number, url: string, _destroy?: number} = {url: ''}
         data.url = item.url
         if (item.id) {
            data.id = item.id
            if (item._destroy) data._destroy = item._destroy
         }
         room_resources_attributes.push(data)
      })
      let data:string = '';
      if (roomDetails?.attributes?.is_host) {
         let room = {
            room_id: roomDetails.id,
            your_take: takeText,
            is_anonymously: entryType === 'Anonymous Entry',
            is_global: roomType === 'Global Room',
            anonymously_name: anonName,
            room_resources_attributes: room_resources_attributes
         }
         data = JSON.stringify({ room });
      } else {
         let registered_room = {
            registration_id: userDetails.id,
            your_take: takeText,
            is_anonymously: entryType === 'Anonymous Entry',
            anonymously_name: anonName,
            resources_attributes: room_resources_attributes
         }
         data = JSON.stringify({ registered_room });
      }
      updateNewResource(data, roomDetails?.attributes?.is_host)
      setIsEdit(false)
   }

   return (
      <div className="editTakeOutside">
         <div className="divColum">
            {textAreaBox(takeText, 'Your Take', setTakeText, 300, takeText)}
            {useResourceMapWrapper(setResources, resources,)}
            <div className="publicAnonBox">
               {switchInput('Anonymous Entry', 'Public Entry', setEntryType, entryType, (entryType === 'Public Entry'))}
               {entryType === 'Anonymous Entry' && basicTextbox(anonName, 'Anonymous Name', setAnonName, 50)}
            </div>
            <div className="w-100">
               {warningBox('Public entries can not be changed to anonymous.')}
            </div>
            {roomDetails?.attributes?.is_host &&
               <>
                  <div className="w-100">
                     {switchInput('Local Room', 'Global Room', setRoomType, roomType, (roomType === 'Global Room'))}
                  </div>
                  <div className="w-100">
                     {warningBox('Global rooms cannot be made local.')}
                  </div>
               </>
            }
            <div className="w-100 bottomText">
               <span>All calls are scheduled for 1 hour and extendable to 2 hours at no extra cost</span>
            </div>
            <div className="w-100 saveCancelButtonBox">
                  <div className="button" onClick={submitTake}>
                     Save
                  </div>
                  <div className="button cancel" onClick={() => {setIsEdit(false)}}>
                     Cancel
                  </div>
            </div> 
         </div>
      </div>
   )
}

export default EditTake;