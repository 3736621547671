export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const calendar = require("../assets/calendar.png");
export const fam = require("../assets/fam.png");
export const yellowheart = require("../assets/yellowheart.png");
export const as = require("../assets/as.png");
export const plle = require("../assets/plle.png");
export const sharef = require("../assets/sharef.png");
export const share = require("../assets/share.png");
export const bgg = require("../assets/bgg.png");
export const backarrow = require("../assets/backarrow.png");
export const peeenc = require("../assets/peeenc.png");
export const thumb = require("../assets/thumb.png");
export const clock = require("../assets/clock.png");
export const standard = require("../assets/standard.png");
export const moderated = require("../assets/moderated.png");
export const premium = require("../assets/premium.png");
export const onetoone = require("../assets/onetoone.png");
export const watch = require("../assets/watch.png");
export const hourglass = require("../assets/hourglass.png");
export const attach = require("../assets/attach.png");
export const minusIcon = require('../assets/nimhes.png');
export const plusIcon = require('../assets/ojwl.png');
export const addIcon = require('../assets/addIcon.png');
export const images = require('../assets/images.png');
export const createRoomSuccess = require("../assets/createRoomSuccess.jpeg");
export const backgroundImage = require("../assets/welcome_background_image.png")
export const goldenTick = require('../assets/GoldenTick.svg');
export const closeIcon = require("../assets/closeIcon.png");
export const blueTick = require('../assets/BlueTick.svg');
export const noUserImg = require('../assets/No_Profile_PIc.jpeg');
export const yellowHeart = require('../assets/yellowheart copy.png');
export const cancelIcon = require('../assets/cancel_logo.png');
export const noRoomImg = require('../assets/noRoomImg.png');
export const roomUnavailable = require('../assets/roomUnavailable.svg');
export { ReactComponent as GoogleCalendar } from '../assets/Gcalendar.svg';